import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { LocalStorageService } from 'src/app/local-storage.service';
import { IBurialPermitDto } from '../../models/BurialPermitDto.model';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BurialPermitService } from 'src/app/services/burial-permit.service';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';


@Component({
  selector: 'app-burial-permit-detail-preview',
  templateUrl: './burial-permit-detail-preview.component.html',
  styleUrls: ['./burial-permit-detail-preview.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class BurialPermitDetailPreviewComponent implements OnInit{
  previewFormGroup! : FormGroup;
  originalsBurialPermits!: IBurialPermitDto[];
  currentBurialPermit! : IBurialPermitDto;
  

  displayedColumns: string[] = [     
    'permitNumber', 
    'deceasedIdCardNumber',
    'deceasedName',  
    'deceasedSurName', 
    // 'graveNo',   
    'burialDate',
    'expand',    
  ];
  dataSource!: MatTableDataSource<IBurialPermitDto>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  displayedColumnsWithExpand = [...this.displayedColumns];
  expandedElement: IBurialPermitDto | undefined;
  
  constructor(
    public dialogRef: MatDialogRef<BurialPermitDetailPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IBurialPermitDto,
    public dialog: MatDialog,    
    private datePipe: DatePipe, 
    private burialPermitService : BurialPermitService, 
    private _formBuilder: FormBuilder,)
  {    
  }

  ngOnInit(): void {
    this.currentBurialPermit = this.data;
    this.previewFormGroup = this._formBuilder.group({  
      permitNumberCtrl :[{value:this.currentBurialPermit ? this.currentBurialPermit.permitNumber:  0,disabled : true}],
      cardNumberCtrl :[{value:this.currentBurialPermit ? this.currentBurialPermit.deceasedIdCardNumber:  0,disabled : true}],
      nameCtrl :[{value:this.currentBurialPermit ? this.currentBurialPermit.deceasedName:  '',disabled : true}],
      surnameCtrl :[{value : this.currentBurialPermit ? this.currentBurialPermit.deceasedSurName:  '',disabled : true}],
      statusCtrl :[{value : this.currentBurialPermit ? this.currentBurialPermit.burialPermitStatusName:  '',disabled : true}],     
        
  });
    this.getOriginalBurialPermits(this.currentBurialPermit.burialId);
    
    this.subscribeChanges();
  }
  
  
  subscribeChanges() {
    this.previewFormGroup.get('permitNumberCtrl')?.valueChanges.subscribe((value) => {       
      this.currentBurialPermit.permitNumber = value;
    });
    this.previewFormGroup.get('cardNumberCtrl')?.valueChanges.subscribe((value) => {       
      this.currentBurialPermit.deceasedIdCardNumber = value;
    });
    this.previewFormGroup.get('nameCtrl')?.valueChanges.subscribe((value) => {       
      this.currentBurialPermit.deceasedName = value;
    });
    this.previewFormGroup.get('surnameCtrl')?.valueChanges.subscribe((value) => {       
      this.currentBurialPermit.deceasedSurName = value;
    });
    this.previewFormGroup.get('statusCtrl')?.valueChanges.subscribe((value) => {       
      this.currentBurialPermit.burialPermitStatusName = value;
    });
    
  }
  getOriginalBurialPermits(reissuedBurialId : number){
    this.burialPermitService.getOriginalBurialPermits(     
      1,//this.paginator.pageIndex + 1,
      100,//this.paginator.pageSize,
      reissuedBurialId
    ).subscribe(
      (response : IBurialPermitDto[]) => {
        console.log(response)
        this.originalsBurialPermits = response;
        this.dataSource = new MatTableDataSource(response);  
      }, (error) =>{
        console.error('Something went wrong retrieving Originals Burial Permits :', error)

      })

  }

  formatDate(date: Date): string {
    return this.datePipe.transform(date, 'dd/MM/yyyy') || ''; 
  }
  
  expandCollapse(burialPermitView: any): void {
    this.expandedElement = this.expandedElement === burialPermitView ? null : burialPermitView;
  }

}
