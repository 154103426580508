
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService } from '../auth.service';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { RoleService } from '../services/role.service';


@Component({
  selector: 'app-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.css'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class SiteHeaderComponent implements OnInit {
  @ViewChild('sidenav') sidenav!: MatSidenav; 
  isExpanded = true;
  isShowing = false;
  showSecondmenu: boolean = false;
  showFirstmenu: boolean = false;
  showSubMenu: boolean = false;
  showThirdmenu: boolean = false;
  @Output() sidenavClose = new EventEmitter();
  username: string | null = null;
  constructor(private authService: AuthService,private router: Router,private roleService : RoleService ) { }
 
  ngOnInit(): void {
    this.username = this.authService.getFullName();
    console.log(this.username)
  }
  toggleSidenav(): void {
    this.sidenav.toggle();
  }
  login() {
    this.authService.login();
  }

  logout() {
    this.authService.logout();
    this.roleService.clearUserRoles();
    this.router.navigate(['/']);
  }

  isLoggedIn(): boolean {
    return this.authService.isLoggedIn();
  }
 
  public onSidenavClose = () => {
    this.sidenavClose.emit();
  } 

}
