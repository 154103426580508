import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserrolesComponent } from './userroles/userroles.component';
import { ViewusersComponent } from './viewusers/viewusers.component';
import { ModuleAccessComponent } from './moduleaccess/moduleaccess.component';
import { MsalGuard } from '@azure/msal-angular'; 


const routes: Routes = [
  
  {
    path: 'admin/userroles',
    component: UserrolesComponent,
    title: 'Userroles',
    canActivate: [MsalGuard],  
  },
  {
    path: 'admin/viewusers',
    component: ViewusersComponent,
    title: 'Viewusers',
    canActivate: [MsalGuard],  
  },
  {
    path: 'admin/moduleaccess',
    component: ModuleAccessComponent,
    title: 'Access',
    canActivate: [MsalGuard],  
  },
 
 
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
