import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IMaritalStatusDto } from '../models/MaritalStatusDto.model';
import { Observable, catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class MaritalStatusService {

  private baseUrl = `${environment.apiUrl}marital-status/`;
  constructor(private http: HttpClient, private authService: AuthService) { }
  
  getAllMaritalStatus(): Observable<IMaritalStatusDto[]> {   
    const headers = this.authService.getAuthHeaders();
    return this.http.get<IMaritalStatusDto[]>(this.baseUrl + 'GetAllMaritalStatus',{headers} )
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };

  getMaritalStatusById(id:number): Observable<IMaritalStatusDto> {    
    const headers = this.authService.getAuthHeaders();
    const url = `${this.baseUrl}${id}`;
    return this.http.get<IMaritalStatusDto>(url,{headers})
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };
}
