<div class="dialog-header">
  <button mat-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div> 
  
  <div class="container-popup">
      <div class="form">
        <span class="mat-title" mat-dialog-title><b>   ADD New Grave</b> </span>
        <form [formGroup]="newGraveFormGroup">
           
            <mat-dialog-content>
              <div class="box toggle-button">
                <mat-slide-toggle  formControlName="privateGraveCtrl" labelPosition="before" >Private Grave:</mat-slide-toggle>
              </div>
              <div class="box">
                <mat-form-field>
                  <mat-label>Cemetery Type</mat-label>
                  <mat-select formControlName="cemeteryTypeCtrl"  (selectionChange)="onCemeteryTypeChange()">                    
                    <mat-option *ngFor="let cemeteryType of cemeteryTypes" [value]="cemeteryType.id">
                      {{ cemeteryType.description }}
                    </mat-option> 
                  </mat-select>    
                  <mat-error>
                    <span *ngIf="!newGraveFormGroup.get('cemeteryTypeCtrl')?.value">Field required</span>
                  </mat-error>              
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Cemetery</mat-label>
                  <mat-select formControlName="cemeteryCtrl"  (selectionChange)="onCemeteryChange()">                   
                    <mat-option *ngFor="let cemetery of cemeteries" [value]="cemetery.code">
                      {{ cemetery.name }}
                    </mat-option>
                  </mat-select>  
                  <mat-error>
                    <span *ngIf="!newGraveFormGroup.get('cemeteryCtrl')?.value">Field required</span>
                  </mat-error>              
                </mat-form-field>                
              </div>
            
              <div class="box">
                <mat-form-field>
                  <mat-label>Division</mat-label>
                  <mat-select formControlName="divisionCtrl"  (selectionChange)="onDivisionChange()">                    
                    <mat-option *ngFor="let division of divisions" [value]="division.id">
                      {{ division.description }}
                    </mat-option>
                  </mat-select> 
                  <mat-error>
                    <span *ngIf="!newGraveFormGroup.get('divisionCtrl')?.value">Field required</span>
                  </mat-error>               
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Section</mat-label>
                  <mat-select formControlName="sectionCtrl"  (selectionChange)="onSectionChange()">
                    <mat-option *ngFor="let section of sections" [value]="section.id">
                      {{ section.description }}
                    </mat-option>
                  </mat-select> 
                  <mat-error>
                    <span *ngIf="!newGraveFormGroup.get('sectionCtrl')?.value">Field required</span>
                  </mat-error>                  
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Compartment</mat-label>
                  <mat-select formControlName="compartmentCtrl">
                    <mat-option *ngFor="let compartment of compartments" [value]="compartment.id">
                      {{ compartment.description }}
                    </mat-option>
                  </mat-select>   
                  <mat-error>
                    <span *ngIf="!newGraveFormGroup.get('compartmentCtrl')?.value">Field required</span>
                  </mat-error>                 
                </mat-form-field>
              </div>
              <mat-divider></mat-divider>

              <div class="box">
                <mat-form-field>
                  <mat-label>Grave Size</mat-label>
                  <mat-select formControlName="graveSizeCtrl">
                    <mat-option *ngFor="let graveSize of graveSizes" [value]="graveSize.id">
                      {{ graveSize.description }}
                    </mat-option>
                  </mat-select>  
                   <mat-error>
                    <span *ngIf="!newGraveFormGroup.get('graveSizeCtrl')?.value">Field required</span>
                  </mat-error>                   
                </mat-form-field> 
                <mat-form-field>                    
                  <mat-label>D/H FILE NO</mat-label>
                  <input matInput placeholder="DHFile" formControlName="DHFileCtrl" required>
                  <mat-error>
                    <span *ngIf="newGraveFormGroup.get('DHFileCtrl')?.value && newGraveFormGroup.get('DHFileCtrl')?.invalid && newGraveFormGroup.get('DHFileCtrl')?.errors?.['pattern']">DH File Format Sample DH 121/231/31</span>
                    <span *ngIf="!newGraveFormGroup.get('DHFileCtrl')?.value">Field required</span>
                  </mat-error> 
                </mat-form-field> 
                
              <div class="box">
                <mat-form-field>                    
                  <mat-label>Grave Referenece</mat-label>
                  <input matInput placeholder="Greference" formControlName="graveReferenceCtrl" required>
                  <mat-error>
                    <span *ngIf="newGraveFormGroup.get('graveReferenceCtrl')?.value && newGraveFormGroup.get('graveReferenceCtrl')?.invalid && newGraveFormGroup.get('graveReferenceCtrl')?.errors?.['pattern']">Telephone must contain only numbers </span>
                  </mat-error>
                </mat-form-field>                      
              </div>
                <mat-form-field>                    
                  <mat-label>Grave No</mat-label>
                  <input matInput placeholder="GraveNo" formControlName="graveNoCtrl" required>
                  <mat-error>
                    <span *ngIf="newGraveFormGroup.get('graveNoCtrl')?.value && newGraveFormGroup.get('graveNoCtrl')?.invalid && newGraveFormGroup.get('graveNoCtrl')?.errors?.['pattern']">Grave No must contain only numbers </span>
                  </mat-error>
                </mat-form-field>                
                                                  
              </div>

              <div class="box">
                <mat-form-field>
                  <mat-label>Remarks</mat-label>
                  <textarea matInput placeholder="Remarks" formControlName="remarksCtrl"></textarea>
                </mat-form-field> 
              </div>
              
                <div class="buttonalign">
              <button mat-raised-button color="primary" (click)="graveNewClick()"><mat-icon>check</mat-icon>Save</button>
              <button mat-raised-button style="background-color: rgb(143, 141, 141)" [mat-dialog-close] cdkFocusInitial><mat-icon>close</mat-icon>Close</button></div>
            </mat-dialog-content>
            </form>
    </div>
  </div>
