<div class="dialog-header">
  <button mat-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div id="mainContainer">
  
  <mat-spinner [diameter]="100" *ngIf="isLoading"></mat-spinner>

  <br/>
  
  <iframe id="pdfPreview2"></iframe>

  <div id="contentContainer">
    <div #contentToPrint id="contentToPrint">
      <div class="form_header">
        <table>
          <tr>
            <td id="form_header_img">
              <img src="assets/logo_burial_template2.png" />
            </td>
          </tr>
        </table>
      </div>
      <br/>
      <div class="datediv">
        <table>
          <tr>
            <td colspan="2">{{formattedCurrentDate}}</td>
          </tr>
        </table>
      </div>
      <div class="form_title">
        <span id="form_header_title"><div class ="title-div">ORIGINAL DEATH CERTIFICATE RECEIPT</div></span>
      </div>
      <div class="form_body">
        <p>The Burial Regulatory Unit received the original death certificate of <b>{{burialPermitPreview.name}} {{burialPermitPreview.surname}}</b>,
          ID Card number: <b>{{burialPermitPreview.idCardNumber}}</b> who died on <b>{{formatDate(burialPermitPreview.dateOfDeath)}}</b> on behalf of/from
          <b>{{burialPermitPreview.undertaker}}</b> and verified by <b>{{burialPermitPreview.username}}</b>in charge of Burial Administration Unit.</p>
      </div>
      <div class="receiptsign">
        <div class="datediv">
          <table>
            <tr> <td colspan="4"><b>____________________</b></td> </tr>
            <tr><td colspan="4">Official Burial Regulatory Unit</td> </tr>
          </table>
        </div>
      </div>
      <div class="abovefooter">
        Information Protection: The information collected is processed in accordance
        with the Burial Ordinance and accordance with the 1st Data Protection and
        PrivacyAct 2001. The information is required for the use of a
        processor,statistics and research in the interest of Public Health DEH
        41-00.
      </div>
      <div class="form_footer">
        <p>DEPARTMENT FOR HEALTH REGULATION</p>
        <p>ENVIRONMENTAL HEALTH DIRECTORATE</p>
        <p>BURIALS REGULATORY UNIT</p>
        <p>Cimiterju Sta. Marija Addolorata, Paola PLA9010</p>
        <p>Tel: 356 21693508, 356 21675936 e.mail: burials.ph@gov.mt</p>
      </div>
    </div>
  </div>
</div>
