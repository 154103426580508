<div class="dialog-header">
    <button mat-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
</div>

<div class="container-popup1">
    <div class="form1">
        <span class="mat-title" mat-dialog-title>View Burial Information</span>
            <mat-dialog-content>


                    <div class="form_body1">
                    <mat-accordion>
                        <mat-expansion-panel expanded="true">
                            <mat-expansion-panel-header>
                                <mat-panel-title>Deceased Details -&nbsp; &nbsp;<b>{{burialPermitPreview.name}}&nbsp;{{burialPermitPreview.surname}}&nbsp;({{burialPermitPreview.idCardNumber}})</b></mat-panel-title>
                            </mat-expansion-panel-header>
                            <table>
                                <!-- Deceased Details -->
                                <tr class="tr-align">
                                    <td class="td-align" colspan="3">Address:&nbsp;<br /><b>{{burialPermitPreview.address}}</b></td>
                                    <td class="td-align" colspan="3">Locality:&nbsp;<br /><b>{{burialPermitPreview.locality}}</b></td>
                                    <!-- <td class="td-align" colspan="3">Date of Birth:&nbsp;<br /><b>{{formatDate(burialPermitPreview.dateOfBirth)}}</b></td> -->
                                    <td class="td-align" colspan="3">Age:&nbsp;<br /><b>{{ calculateAge(burialPermitPreview.dateOfBirth, burialPermitPreview.dateOfDeath) }}</b></td>
                                    <td class="td-align" colspan="3">Gender:&nbsp;<br /><b>{{burialPermitPreview.gender}}</b></td>
                                </tr>
                                <tr class="tr-align">
                                    <td class="td-align" colspan="3">Place of Birth: &nbsp;<br /><b>{{ burialPermitPreview.placeOfBirth }}</b></td>
                                    <td class="td-align" colspan="3">Profession: &nbsp;<br /><b>{{ burialPermitPreview.profession }}</b></td>
                                    <td class="td-align" colspan="3">Marital Status:<br /><b>{{ burialPermitPreview.maritalStatus }}</b></td>
                                    <td class="td-align" colspan="3">Husband/Wife Name:<br /><b>{{ burialPermitPreview.spouseName }}&nbsp;{{ burialPermitPreview.spouseSurname }}</b></td>
                                </tr>
                                <tr class="tr-align">
                                    <td class="td-align" colspan="3">Father's Name:<br /><b>{{ burialPermitPreview.fatherName }}&nbsp;{{ burialPermitPreview.fatherSurname }}</b></td>
                                    <td class="td-align" colspan="3">Status:<br /><b>{{ burialPermitPreview.fatherStatus }}</b></td>
                                    <td class="td-align" colspan="3">Mother’s Name:<br /><b>{{ burialPermitPreview.motherName }}&nbsp;{{ burialPermitPreview.motherSurname }}</b></td>
                                    <td class="td-align" colspan="3">Status:<br /><b>{{ burialPermitPreview.motherStatus }}</b></td>
                                </tr>
                            </table>
                        </mat-expansion-panel>
                    </mat-accordion>
                    </div>

                    <div class="form_body1">
                        <mat-accordion>
                            <mat-expansion-panel expanded="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>Burial Details</mat-panel-title>
                                </mat-expansion-panel-header>
                                <table>
                                <!-- Common Burial Details -->
                                <tr class="tr-align">
                                    
                                    <td class="td-align" colspan="3">Date of Death: &nbsp;<br /><b>{{formatDate(burialPermitPreview.dateOfDeath)}}</b></td>
                                    <td class="td-align" colspan="3">Death Time: &nbsp;<br /><b>{{burialPermitPreview.timeOfDeath}}</b></td>
                                    <td class="td-align" colspan="3">Place Of Death:&nbsp;<br /><b>{{burialPermitPreview.placeOfDeath}}</b></td>
                                    <td class="td-align" colspan="3">Cause of Death: &nbsp;<br /><b>{{burialPermitPreview.causeOfDeath}}</b></td>
                                </tr>
                                <tr class="tr-align">
                                    
                                    <td class="td-align" colspan="3">Mass Locality: &nbsp;<br /><b>{{burialPermitPreview.massLocality}}</b></td>
                                    <td class="td-align" colspan="3">Mass Time: &nbsp;<br /><b>{{burialPermitPreview.massTime}}</b></td>
                                    <td class="td-align" colspan="3">Undertaker: &nbsp;<br /><b>{{burialPermitPreview.undertaker}}</b></td>
                                    <td class="td-align" colspan="3">Burial Rights:&nbsp;<br /><b>{{burialPermitPreview.burialRight}}</b></td>
                                </tr>
                            </table>
                            <mat-divider class="matdivider"></mat-divider>
                            <div *ngIf="burialPermitPreview.burialTypeId == 2">
                                <!-- Grave Details -->
                                <table>
                                    <tr class="tr-align">
                                        <td class="td-align" colspan="6"><b><u>GRAVE DETAILS</u></b></td>
                                    </tr>
                                    <tr class="tr-align">
                                        <td class="td-align" colspan="1"><b>TO</b></td>
                                        <td class="td-align" colspan="5">Cemetery Name: <b>{{ burialPermitPreview.cemeteryName }}</b></td>
                                    </tr>
                                    <tr class="tr-align">
                                        <td class="td-align" colspan="1">Division:<br /><b>{{ burialPermitPreview.divisionName }}</b></td>
                                        <td class="td-align" colspan="1">Section:<br /><b>{{ burialPermitPreview.sectionName }}</b></td>
                                        <td class="td-align" colspan="1">Compartment:<br /><b>{{ burialPermitPreview.compartmentName }}</b></td>
                                        <td class="td-align" colspan="1">Number:<br /><b>{{ burialPermitPreview.graveNo}}</b></td>
                                        <td class="td-align" colspan="1">Level:<br /><b>{{ burialPermitPreview.levelName}}</b></td>
                                </table>
                            </div>
                                    
                                <div *ngIf="burialPermitPreview.burialTypeId == 1">
                                    <!-- Cremation Details -->
                                    <table>
                                        <tr class="tr-align">
                                            <td class="td-align" colspan="6"><b><u>CREMATION DETAILS</u></b></td>
                                        </tr>
                                        <tr class="tr-align">
                                            <td class="td-align" colspan="5">Country Name: <b>{{burialPermitPreview.country}}</b></td>
                                        </tr>
                                    </table> 
                                </div>
                                <div *ngIf="burialPermitPreview.burialTypeId == 3">
                                    <!-- Burial at Sea Details -->
                                    <table>
                                        <tr class="tr-align">
                                            <td class="td-align" colspan="6"><b><u>CREMATION DETAILS</u></b></td>
                                        </tr>
                                        <tr class="tr-align">
                                            <td class="td-align" colspan="5">Burial Location: <b>{{burialPermitPreview.burialLocation}}</b></td>
                                        </tr>
                                    </table> 
                                </div>
                                <div *ngIf="burialPermitPreview.burialTypeId == 4">
                                    <!-- Repatriation Details -->
                                    <table>
                                        <tr class="tr-align">
                                            <td class="td-align" colspan="6"><b><u>REPATRIATION DETAILS</u></b></td>
                                        </tr>
                                        <tr class="tr-align">
                                            <td class="td-align" colspan="5">Country Name: <b>{{burialPermitPreview.country}}</b></td>
                                        </tr>
                                    </table>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>

                    <div class="form_body1">
                        <mat-accordion>
                            <mat-expansion-panel expanded="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>Remarks</mat-panel-title>
                                </mat-expansion-panel-header>
                                <table>
                                    <tr class="tr-align">
                                        <td class="td-align" colspan="3">{{ burialPermitPreview.remarks }}</td>
                                    </tr>
                                </table>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>

                    <div class="form_body1">
                        <mat-accordion>
                            <mat-expansion-panel expanded="true">

                                <mat-expansion-panel-header>
                                    <mat-panel-title>Cleaning & Transport Details</mat-panel-title>
                                </mat-expansion-panel-header>

                                <table>

                                    <tr class="tr-align">                                        
                                        <td class="td-align" colspan="2">Date of Cleaning: &nbsp;<br /><b>{{formatDate(burialPermitPreview.dateCleaned)}}</b></td>
                                        <td class="td-align" colspan="2">Date of Transport: &nbsp;<br /><b>{{formatDate(burialPermitPreview.dateTransported)}}</b></td>
                                    </tr>

                                    <tr class="tr-align">                                        
                                        <td class="td-align" colspan="3">Transported from: &nbsp;<br /><b>{{burialPermitPreview.transportedFrom}}</b></td>
                                    </tr>

                                    <tr class="tr-align">
                                        <td class="td-align" colspan="3">Transported to: &nbsp;<br /><b>{{burialPermitPreview.transportedTo}}</b></td>
                                    </tr>

                                </table>
                            
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>

           

            </mat-dialog-content>

        <div class="buttonalign">
            <button mat-raised-button style="background-color: rgb(143, 141, 141)"  [mat-dialog-close] cdkFocusInitial><mat-icon>close</mat-icon> Close </button>
        </div>

    </div>
</div>
