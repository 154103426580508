<div class="container">
  <h1>Compartment Details</h1>
</div>
<div class="container">
  <div class="form">
    <div class="box">
      <app-menu-bar fxShow="true" fxHide.lt-md></app-menu-bar>
    </div>
  </div>
  <div class="form">
    <form [formGroup]="CompartmentDetailsForm">
      <div class="box">
        <mat-form-field>
          <mat-label>Cemetery</mat-label>
          <mat-select formControlName="cemeteryCtrl" (selectionChange)="onCemeteryChange()">
            <mat-option *ngFor="let cemetery of cemeteries" [value]="cemetery.code">
              {{ cemetery.name }} 
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Division</mat-label>
          <mat-select formControlName="divisionCtrl" (selectionChange)="onDivisionChange()">
            <mat-option *ngFor="let division of divisions" [value]="division.id">
              {{ division.description }}
            </mat-option>
          </mat-select>
        </mat-form-field>
          <mat-form-field>
            <mat-label>Section</mat-label>
            <mat-select formControlName="sectionCtrl" (selectionChange)="onSectionChange()">
              <mat-option *ngFor="let section of sections" [value]="section.id">
                {{ section.description }}
              </mat-option>
            </mat-select>
          </mat-form-field>
      </div>
      <mat-divider></mat-divider>
      <div class="box">
        <mat-form-field class="mat-form-field">
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Paola" #input/>
        </mat-form-field>
      </div>
      <div class="box">
        <div class="table-table-container mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>
          <!-- ID Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef >ID</th>
            <td mat-cell *matCellDef="let compartmentmain"> {{ compartmentmain.id }} </td>
          </ng-container>
          <!-- Compartment Name Column -->
          <ng-container matColumnDef="compartment_name">
            <th mat-header-cell *matHeaderCellDef >Compartment</th>
            <td mat-cell *matCellDef="let compartmentmain">{{ compartmentmain.description }}</td>
          </ng-container>
          <!-- Action Column -->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let compartmentmain">
              <button class="editbutton" (click)=" editCompartmentDialog(compartmentmain)" mat-icon-button color="primary">
                <mat-icon>mode</mat-icon>
              </button>
              <button mat-icon-button color="warn">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
      </div>
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of graves" showFirstLastButtons></mat-paginator>
      <div class="buttonnew">
        <button mat-raised-button (click)="NewCompartmentDialog()" color="primary"> New</button>
      </div>
    </form>
  </div>
</div>
