import { Component, ViewChild, ElementRef, Input, AfterViewInit, Renderer2, Inject, Optional, ChangeDetectorRef, NgZone } from '@angular/core';
import { jsPDF } from 'jspdf';
import { DatePipe } from '@angular/common';
import { LocalStorageService } from 'src/app/local-storage.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IPreViewBurialPermit } from 'src/app/models/PreViewBurialPermit.model';

@Component({
  selector: 'app-burials',
  templateUrl: './burials.component.html',
  styleUrls: ['./burials.component.css']
})

export class BurialsReportComponent implements AfterViewInit {
  
  @Input() burialPermitPreview!: IPreViewBurialPermit[]; 
  @Input() burial_dateFrom!: any;
  @Input() burial_dateTo!: any;

  @ViewChild('contentToPrint', { static: false }) el!: ElementRef;
  title = 'angular-pdf-print';
  isLoading: boolean = false;

  constructor(private datePipe: DatePipe,
              private localStorageService: LocalStorageService,
              private renderer: Renderer2,
              private cdr: ChangeDetectorRef, // Inject ChangeDetectorRef
              private ngZone: NgZone, // Inject NgZone
              @Optional() @Inject(MAT_DIALOG_DATA) public data: any)
              {
                if (data) {
                  this.burialPermitPreview = data.burialPermits;
                  this.burial_dateFrom = data.burial_dateFrom;
                  this.burial_dateTo = data.burial_dateTo;
                }
              }

  ngAfterViewInit() {
    this.ngZone.run(() => {
      this.isLoading = true;
      this.cdr.detectChanges(); // Trigger change detection manually

      setTimeout(() => {
        this.makePDF().then(() => {
          this.isLoading = false;
          this.cdr.detectChanges(); // Trigger change detection manually
        });
      });
    });
  }

  makePDF(): Promise<void> {
    return new Promise((resolve, reject) => {
      let pdf = new jsPDF({
        orientation: 'l', // or 'landscape'
        unit: 'pt',
        format: 'a4' // or specify custom dimensions { width: number, height: number }
      });

      pdf.html(this.el.nativeElement, {
        callback: (pdf) => {        
          const pdfDataUri = pdf.output('datauristring');
          this.fillIframe(pdfDataUri);
          resolve(); // Resolve the promise when done
        },
        x: 0,
        y: 0,
        margin: 20
      });
    });
  }

  fillIframe(dataUri: string) {
    const iframe = document.getElementById('pdfPreview') as HTMLIFrameElement;
    if (iframe) {
      iframe.src = dataUri;
    }
  }

  formatDate(date: Date): string {
    return this.datePipe.transform(date, 'dd/MM/yyyy') || ''; 
  }

  storeObject(key: string, object: any) {
    const objectToStore = object;
    this.localStorageService.setObject(key, objectToStore);
  }

  getCurrentDate(): string {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = currentDate.getFullYear();

    return `${day}/${month}/${year}`;
  }

  formattedCurrentDate = this.getCurrentDate();

  getFormattedBurialPermitDate(dateOfDeath: Date): string {
    const burialpermitdate = new Date(dateOfDeath);
    burialpermitdate.setDate(burialpermitdate.getDate() + 1);
    const day = String(burialpermitdate.getDate()).padStart(2, '0');
    const month = String(burialpermitdate.getMonth() + 1).padStart(2, '0'); 
    const year = String(burialpermitdate.getFullYear());

    return `${day}/${month}/${year}`;
  }
}
