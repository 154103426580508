import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';
import { GraveFile } from '../models/GraveFile.model';
import { IResponseModal } from '../models/ResponseModal.model';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  private baseUrl = `${environment.apiUrl}upload/`; 


  constructor(private http: HttpClient, private authService: AuthService) { }

  uploadFile(formData: FormData, graveId: number): Observable<any> {
    const headers = this.authService.getAuthHeaders();
    const endpoint = 'add';

    let params = new HttpParams().set('graveId', graveId.toString());
    const url = `${this.baseUrl}${endpoint}`;

    return this.http.post<HttpResponse<IResponseModal>>(url, formData, { observe: 'response', params, headers }).pipe(
        map(response => {          
            let modal: IResponseModal = {
                id: 0,
                title: 'Upload File',
                message:''
            };
            if (response.status === 200) {  
          
              modal.message = 'File Uploaded';
    
            } else if (response.status === 409) {    
              
              modal.message = 'The specified file already exists.';
    
            }
            else if (response.status === 500) {    
              
              modal.message = 'An internal server error occurred. Please try again later.';
    
            }
            else {
              modal.message =`Unexpected status code: ${response.status}`;
              
              throw new Error(`Unexpected status code: ${response.status}`);
            }
            return modal;
        }),
        catchError((error) => {
            const modal: IResponseModal = {
                id: 0,
                title: 'File Upload',
                message: ''
            };
            if (error.status === 409) {
                modal.message = 'The specified file already exists.';
            } else if (error.status === 500) {
                modal.message = 'An internal server error occurred. Please try again later.';
            } else {
                modal.message = 'Failed to upload file. Please try again later.';
            }
            console.error('File Upload Error:', error);
            return of(modal); 
        })
    );
  }


  deleteFile(fileName: string,graveId : number): Observable<any> {    
    const headers = this.authService.getAuthHeaders();
    const endpoint = 'delete/';     
    const url = `${this.baseUrl}${endpoint}${fileName}/${graveId}`;  
    return this.http.delete<HttpResponse<IResponseModal>>(url,  { observe: 'response',headers }).pipe(
      map(response => {
        let modal: IResponseModal = {
          id: 0,
          title: 'Delete File',
          message: ''
        };
        if (response.status === 200) {  
          
          modal.message = 'File Deleted';

        }
        else if (response.status === 404) {  
          
          modal.message = 'File Not Found';

        }
        else {
          modal.message =`Unexpected status code: ${response.status}`;
          
          throw new Error(`Unexpected status code: ${response.status}`);
        }
        return modal;
      }),
      catchError((error) => {
        let modal: IResponseModal = {
          id: 0,
          title: 'Delete File',
          message: ''
        };        
        if (error.status === 500) {
          modal.message = error.error?.message || 'An internal server error occurred. Please try again later.';
        } else {
          modal.message = 'Failed to delete file. Please try again later.';
        }        
        console.error('File Delete Error:', error);        
        return throwError(() => modal);
      })
    )
    //return this.http.delete(url,  {headers});
  }
  
  getAllFilesPerGrave(graveId : number){
    const headers = this.authService.getAuthHeaders();
    let params = new HttpParams();
    
    params = params.append('graveId', graveId.toString());
    
    return this.http.get<GraveFile[]>(this.baseUrl + 'GetAllFilesPerGrave', { params, headers })
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  }

  downloadFile(fileName: string): Observable<Blob> {
    const headers = this.authService.getAuthHeaders();
    return this.http.get(`${this.baseUrl}download/${fileName}`, { responseType: 'blob',headers });
  }

}
