import { CUSTOM_ELEMENTS_SCHEMA, Component, NgModule,ChangeDetectorRef } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { IMenuNode } from 'src/app/models/MenuNode.model';
import { IFlatNode } from 'src/app/models/FlatNode.model';
import { Router } from '@angular/router';

const TREE_DATA: IMenuNode[] = [
  {
    name: 'Burial',
    children: [
      {
        name: 'Burial Permit Generation',
        route: '/burial/burial-permit-generation',
      },
      {
        name: 'Burial Details',
        route: '/burial/burial-permit-details',
      },
      {
        name: 'Burial Permits Pre Save',
        route: '/burial/burial-permit-presave',
      },
    ],
  },
  
  {
    name: 'Maintenance',
    children: [
      {
        name: 'Cemetery Maintenance',
        route: '/cemetery/cemetery-details',
      },
      {
        name: 'Grave Maintenance',
        route: '/cemetery/grave/gravedetails',
      },
      {
        name: 'Deceased Maintenance',
        route: '/maintenance/deceased',
      },
      {
        name: 'Reference Data Maintenance',
        route: '/maintenance/reference',
      },
    ],
  },
  {
    name: 'Undertaker',
    children: [
      {
        name: 'Undertaker Details',
        route: '/undertaker',
      },
    ],
  },
  {
    name: 'Reports',
    children: [
      {
        name: 'Report Details',
        route: '/reporting',
      },

      
    ],
  },


  // {
  //   name: 'Admin',
  //   children: [
  //     {
  //       name: 'Userroles',
  //       route: '/admin/userroles',
  //     },
  //     {
  //       name: 'View Users',
  //       route: '/admin/viewusers',
  //     },

  //     {
  //       name: 'Access',
  //       route: '/admin/moduleaccess',
  //     },

      
  //   ],
  // },
  // {
  //   name: 'CDR',
  //   children: [
  //     {
  //       name: 'CDR Details',
  //       route: '/cdr',
  //     },
  //   ],
  // },

  
  
 












  
  



];

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.css'],
})
export class MenuBarComponent {
  private _transformer = (node: IMenuNode, level: number): IFlatNode => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      route: node.route,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<IFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(private router: Router,private cdr: ChangeDetectorRef) {
    this.dataSource.data = TREE_DATA;
  }
  isCurrentRoute(route: string): boolean {
    return this.router.url.includes(route);
  }

  hasChild = (_: number, node: IFlatNode) => node.expandable;

  expandAll() {
    this.treeControl.dataNodes.forEach(node => {
      this.treeControl.expand(node);
    });
  }

  ngAfterViewInit(){
    this.expandAll();
    this.cdr.detectChanges();
  }
  
}
