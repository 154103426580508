import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/User.model';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private baseUrl = `${environment.apiUrl}user`; 
  
  constructor(private http: HttpClient, private authService: AuthService) { }


  getUsers(): Observable<User[]> {
    const headers = this.authService.getAuthHeaders();
    return this.http.get<User[]>(this.baseUrl + '/GetUsers', { headers });
  }

  getUserById(id: number): Observable<User> {
    const headers = this.authService.getAuthHeaders();
    return this.http.get<User>(`${this.baseUrl}/GetUser/${id}`,{headers});
  }

  createUser(user: User): Observable<User> {
    const headers = this.authService.getAuthHeaders();
    return this.http.post<User>(this.baseUrl, user,{headers});
  }

  updateUser(user: User): Observable<User> {
    const headers = this.authService.getAuthHeaders();
    return this.http.put<User>(`${this.baseUrl}/${user.id}`, user,{headers});
  }

  deleteUser(id: number): Observable<void> {
    const headers = this.authService.getAuthHeaders();
    return this.http.delete<void>(`${this.baseUrl}/${id}`,{headers});
  }
}
