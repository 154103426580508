import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ITableCountDto } from 'src/app/burials/models/ITableCountDto';
import { ICemeteryDto } from 'src/app/models/CemeteryDto.model';
import { IDivisionDto } from 'src/app/models/DivisionDto.model';
import { CemeteryService } from 'src/app/services/cemetery.service';
import { DivisionService } from 'src/app/services/division.service';
import { InternalCommunicationService } from 'src/app/services/internal-communication.service';

@Component({
  selector: 'app-division-edit',
  templateUrl: './division-edit.component.html',
  styleUrls: ['./division-edit.component.css']
})
export class DivisionEditComponent  implements OnInit{
  divisions: IDivisionDto[] = [];
  cemeteries : ICemeteryDto[] = [];
  DivisionEditForm !: FormGroup;
  selectedCemetery: ITableCountDto | null = null;

  divisionmain: IDivisionDto =
  {
      id : 0,
      description: '',
      cemeteryId :0,
      sections:[],
  };
 
 constructor(
  private cemeteryService : CemeteryService,
  private divisionService : DivisionService,
  private _formBuilder: FormBuilder,
  @Inject(MAT_DIALOG_DATA) public data: any,
 )
  {
  this.divisionmain = data;
  }
  ngOnInit() {
    
    this.DivisionEditForm  = this._formBuilder.group({
      divisionCtrl: [this.divisionmain.id],
      divisionNameCtrl: [this.divisionmain.description],
    });
    this.subscribeChanges();
}

subscribeChanges() {
  this.DivisionEditForm.get('divisionCtrl')?.valueChanges.subscribe((value) => {     
    this.divisionmain.id= value;
  });
  this.DivisionEditForm .get('divisionNameCtrl')?.valueChanges.subscribe((value) => {     
    this.divisionmain.description= value;
  });
}

onDivisionUpdateClick(): void {
  
  const id = +this.divisionmain.id;
  this.divisionService.updateDivisionDetails(id, this.divisionmain).subscribe(
    () => {       
      
      console.log('Division details');   
    },
    (error) => {
      console.error('Error occurred while updating division details:', error);        
    }
)

 }
 
}

