<div class="dialog-header">
  <button mat-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="container-popup">
    <div class="form">
      <span class="mat-title" mat-dialog-title><b>Update Transport Details</b> </span>
      <form [formGroup]="transportFormGroup">  
       
        <mat-dialog-content>  
        <!--<mat-form-field>
            <mat-label>Burial</mat-label>
            <mat-select formControlName="burialCtrl">                      
              <mat-option *ngFor="let burial of burials" [value]="burial.burialId">
                {{ burial.deceasedIdCardNumber }} | {{burial.deceasedName}}, {{burial.deceasedSurName}}
              </mat-option>
            </mat-select>                    
          </mat-form-field>-->

          <mat-form-field>
            <mat-label>Transport Type</mat-label>
            <mat-select formControlName="transportTypeCtrl" (selectionChange)="onTransportTypeChange($event.value)">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let transportType of transportTypes" [value]="transportType.id">
                {{ transportType.description }} 
              </mat-option>
            </mat-select>
          </mat-form-field>
       
        <div class="box">
          <mat-form-field>
            <mat-label>Transport Permit</mat-label>              
            <input matInput placeholder="Transport Permit No" formControlName="transportPermitCtrl" />            
          </mat-form-field>
          <mat-form-field>
            <mat-label>Transport Date</mat-label>
            <input matInput readonly [matDatepickerFilter]="dateFilterOnlyFuture" [matDatepicker]="pickertransportDate" formControlName="transportDateCtrl"/>
            <mat-datepicker-toggle matIconSuffix [for]="pickertransportDate">
            </mat-datepicker-toggle>
            <mat-datepicker #pickertransportDate></mat-datepicker>            
          </mat-form-field>
        </div>    
        <div class="box">          
  
          <mat-form-field>
            <mat-label>Supervisor</mat-label>
            <mat-select formControlName="supervisorCtrl" (selectionChange)="supervisorChange($event.value)">                
              <mat-option *ngFor="let supervisor of supervisors" [value]="supervisor.id">
                {{ supervisor.name }} {{ supervisor.surname }}
              </mat-option>
            </mat-select>           
            <mat-error>
              <span *ngIf="!transportFormGroup.get('supervisorCtrl')?.value">Field required</span>
            </mat-error>   
          </mat-form-field>

          <!--<mat-form-field>
            <mat-label>Providers</mat-label>
              <mat-select formControlName="providerIdCtrl" (selectionChange)="providerChange($event.value)" >
                <mat-option *ngFor="let provider of providers" [value]="provider.id">
                  {{ provider.name }}
                </mat-option>
              </mat-select>               
          </mat-form-field> -->

          <mat-form-field>
            <mat-label>Undertakers</mat-label>
              <mat-select formControlName="undertakerCtrl" (selectionChange)="undertakersChange($event.value)" >
                <mat-option *ngFor="let undertaker of undertakers" [value]="undertaker.id">
                  {{ undertaker.name }} , {{undertaker.surname}}
                </mat-option>
              </mat-select>               
          </mat-form-field>
             
            
        </div> 
        <!--<div class="box">
          <mat-form-field>
            <mat-label>New Auth</mat-label>              
            <input matInput placeholder="New Auth" formControlName="newAuthCtrl" />            
          </mat-form-field>
          <mat-form-field>
            <mat-label>New Auth Id</mat-label>              
            <input matInput placeholder="New Auth Id" formControlName="newAuthIdCtrl" />            
          </mat-form-field>
          <mat-form-field>
            <mat-label>Remove Auth</mat-label>              
            <input matInput placeholder="Remove Auth" formControlName="removeAuthCtrl" />            
          </mat-form-field>
          <mat-form-field>
            <mat-label>Remove Auth id</mat-label>              
            <input matInput placeholder="Remove Auth id" formControlName="removeAuthIdCtrl" />            
          </mat-form-field>
          
        </div>-->
        
        <mat-divider></mat-divider>
        <mat-label>From:</mat-label>
  
          <div class="box">
            <mat-form-field>
                <mat-label>Cemetery</mat-label>
                <mat-select formControlName="fromCemeteryCtrl"  >
                  <mat-option>None</mat-option>
                  <mat-option *ngFor="let cemetery of fromCemeteries" [value]="cemetery.code">
                    {{ cemetery.name }}
                  </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Division</mat-label>
                <mat-select formControlName="fromDivisionCtrl" >
                  <mat-option>None</mat-option>
                  <mat-option *ngFor="let division of fromDivisions" [value]="division.id">
                    {{ division.description }}
                  </mat-option>
                </mat-select>                
            </mat-form-field>
            <mat-form-field>
                <mat-label>Section</mat-label>                
                <mat-select formControlName="fromSectionCtrl"  >
                  <mat-option>None</mat-option>
                  <mat-option *ngFor="let section of fromSections" [value]="section.id">
                    {{ section.description }}
                  </mat-option>
                </mat-select>                
            </mat-form-field>
            
          </div>
          <div class="box">
            <mat-form-field>
              <mat-label>Compartment</mat-label>              
              <mat-select formControlName="fromCompartmentCtrl" >
                <mat-option>None</mat-option>
                <mat-option *ngFor="let compartment of fromCompartments" [value]="compartment.id">
                  {{ compartment.description }}
                </mat-option>
              </mat-select>               
          </mat-form-field>
          
          <mat-form-field class="example-full-width">
            <mat-label>Grave No</mat-label>
            <input type="text"
                   placeholder="Grave No"
                   aria-label="Number"
                   matInput formControlName="fromGraveCtrl" [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" >
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{ option }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field>
            <mat-label>From Level</mat-label>              
            <mat-select formControlName="fromLevelCtrl" >
              <mat-option>None</mat-option>
              <mat-option *ngFor="let level of fromLevels" [value]="level.levelId">
                {{ level.levelDescription }} {{level.graveLevelStatusDescription}}
              </mat-option>
            </mat-select>               
          </mat-form-field>
          </div>
          <mat-divider></mat-divider>
          <mat-label>To:</mat-label>

          <div class="box"  *ngIf="transportType === 1">
            <mat-form-field>
                <mat-label>Cemetery</mat-label>
                <mat-select formControlName="toCemeteryCtrl"  (selectionChange)="onCemeteryToChange($event.value)">
                  <mat-option>None</mat-option>
                  <mat-option *ngFor="let cemetery of toCemeteries" [value]="cemetery.code">
                    {{ cemetery.name }}
                  </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Division</mat-label>
                <mat-select formControlName="toDivisionCtrl"  (selectionChange)="onDivisionToChange($event.value)">
                  <mat-option>None</mat-option>
                  <mat-option *ngFor="let division of toDivisions" [value]="division.id">
                    {{ division.description }}
                  </mat-option>
                </mat-select>                
            </mat-form-field>
            <mat-form-field>
                <mat-label>Section</mat-label>
                <mat-select formControlName="toSectionCtrl"  (selectionChange)="onSectionToChange($event.value)">
                  <mat-option>None</mat-option>
                  <mat-option *ngFor="let section of toSections" [value]="section.id">
                    {{ section.description }}
                  </mat-option>
                </mat-select>                
            </mat-form-field>         
            
          </div>
          <div class="box" *ngIf="transportType=== 1">
            <mat-form-field>
              <mat-label>Compartment</mat-label>
              <mat-select formControlName="toCompartmentCtrl" (selectionChange)="onCompartmentToChange($event.value)">
                <mat-option>None</mat-option>
                <mat-option *ngFor="let compartment of toCompartments" [value]="compartment.id">
                  {{ compartment.description }}
                </mat-option>
              </mat-select>               
            </mat-form-field>

            <mat-form-field class="example-full-width">
              <mat-label>Grave No</mat-label>
              <input type="text"
                     placeholder="Grave No"
                     aria-label="Number"
                     matInput formControlName="toGraveCtrl" [matAutocomplete]="auto">
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="toGraveNoSelected($event)">
                <mat-option *ngFor="let option of filteredOptionsTo | async" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <mat-form-field>
              <mat-label>To Level</mat-label>              
              <mat-select formControlName="toLevelCtrl" (selectionChange)="onLevelToChange($event.value)">               
                <mat-option *ngFor="let level of toLevels" [value]="level.levelId">
                  {{ level.levelDescription }} {{level.graveLevelStatusDescription}}
                </mat-option>
              </mat-select>               
            </mat-form-field>
            
          </div>
          <div class="box" *ngIf="transportType === 2">
            <mat-form-field>
              <mat-label>Destination</mat-label>
              <mat-select formControlName="toDestinationCtrl" (selectionChange)="onDestinationChange($event.value)">
                <mat-option>None</mat-option>
                <mat-option *ngFor="let country of countries" [value]="country.id">
                  {{ country.name }} 
                </mat-option>
              </mat-select>
            </mat-form-field>
            
            <mat-form-field>
              <mat-label>Location</mat-label>              
              <input matInput placeholder="Location" formControlName="addressCtrl"/>            
            </mat-form-field>
          </div>
         
          <mat-checkbox class="example-margin" formControlName="feePaidCtrl">Fee Paid</mat-checkbox>
        </mat-dialog-content>
         
        <mat-dialog-actions>
          <div class="buttonalign">
            <button mat-raised-button class="main_button" color="primary" 
              (click)="addTransport(transport)" 
              [disabled]="transportExist" >
              <mat-icon>add</mat-icon>Save 
            </button>
            <button mat-raised-button class="main_button" color="primary" 
              (click)="updateClick(transport)"
              [disabled]="transport.cleaningTransportStatusId === 7 || !transportExist" >
              <mat-icon>update</mat-icon>Update 
            </button>

            <button mat-raised-button class="main_button" color="warn" 
              (click)="deleteTransport(transport)"
              [disabled]="transport.cleaningTransportStatusId === 7 || !transportExist" >
              <mat-icon>delete</mat-icon>Delete 
            </button>
            <button 
              mat-raised-button 
              style="background-color: rgb(143, 141, 141)" 
              (click)="closeModal()" 
              cdkFocusInitial>
              <mat-icon>close</mat-icon>Close
            </button>

          </div>
        </mat-dialog-actions>
      </form>
  
    </div>
    
  </div>