<div class="container-popup1">

  <div class="form1">

    <div *ngIf="isLoading" class="spinner-overlay">
      <mat-spinner></mat-spinner>
    </div>

    <form [formGroup]="cleaningFormGroup">    

      <mat-dialog-content> 
        <mat-form-field>
          <mat-label>Cleaning Permit</mat-label>              
          <input matInput placeholder="Cleaning Permit No" formControlName="cleaningPermitCtrl" />          
      </mat-form-field>
        
      <mat-form-field>
        <mat-label>Cleaning Date</mat-label>
        <input matInput readonly [matDatepickerFilter]="dateFilterOnlyFuture" [matDatepicker]="pickercleanindDate" formControlName="cleaningDateCtrl"/>
        <mat-datepicker-toggle matIconSuffix [for]="pickercleanindDate">
        </mat-datepicker-toggle>
        <mat-datepicker #pickercleanindDate></mat-datepicker>
        <mat-error>
          <span *ngIf="!cleaningFormGroup.get('cleaningDateCtrl')?.value">Field required</span>
        </mat-error>   
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Undertaker</mat-label>
        <input matInput formControlName="undertakerCtrl" [matAutocomplete]="autoundertaker"  (input)="filterUndertakers($event)"/>
        <mat-autocomplete #autoundertaker="matAutocomplete" [displayWith]="displayUndertaker" (optionSelected)="onUndertakerSelectionChange($event.option.value)">
          <mat-option *ngFor="let undertaker of filteredUndertakers" [value]="undertaker.id">
            Code: {{ undertaker.code }} | {{ undertaker.surname }} {{ undertaker.name }}
          </mat-option>
        </mat-autocomplete>
        <mat-error>
          <span *ngIf="!cleaningFormGroup.get('undertakerCtrl')?.value">Field required</span>
        </mat-error>
      </mat-form-field>
      

      <mat-form-field>
        <mat-label>Burial Rights</mat-label>
        <!-- <input matInput placeholder="Burial Rights" formControlName="burialRightsCtrl" maxlength="100"/> -->
        <input matInput placeholder="Burial Rights" formControlName="burialRightsCtrl" [matAutocomplete]="auto1">
        <mat-autocomplete #auto1="matAutocomplete" (optionSelected)="onBurialRightSelected($event)">
          <mat-option *ngFor="let rightOptions of burialRightOptions | async" [value]="rightOptions">
            {{ rightOptions }}
          </mat-option>
          </mat-autocomplete>
        <mat-error>
          <span *ngIf="!cleaningFormGroup.get('burialRightsCtrl')?.value">Field required</span>
        </mat-error>              
      </mat-form-field>
       
      
      <mat-divider></mat-divider>
      <!--TABLE -->
      <div class="box">
       
        <mat-form-field>
          <mat-label>Filter By status</mat-label>
          <mat-select formControlName="cleaningStatusIdCtrl" (selectionChange)="statusChange($event.value)">  
            <mat-option [value]= 0>All</mat-option>              
            <mat-option *ngFor="let cleaningStatus of cleaningStatuses" [value]="cleaningStatus.id">
              {{ cleaningStatus.description }} 
            </mat-option>
          </mat-select>            
        </mat-form-field>
        <mat-form-field>
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="applyFilter($event)"placeholder="" #input/>
        </mat-form-field>
      </div>
      <mat-label><b>Cleaning Records:</b></mat-label>
      <div class="table-table-container mat-elevation-z2" id="tablecontent" tabindex="0">
        
          <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>

             <!-- cleaningPermitNumber Column -->
             <ng-container matColumnDef="id" sticky>
              <th mat-header-cell *matHeaderCellDef>Id</th>
              <td mat-cell *matCellDef="let cleaning">{{ cleaning.id }}</td>
            </ng-container>
            
            <!-- cleaningPermitNumber Column -->
            <ng-container matColumnDef="cleaningPermitNumber" sticky>
              <th mat-header-cell *matHeaderCellDef>Burial Id</th>
              <td mat-cell *matCellDef="let cleaning">{{ cleaning.burialId }}</td>
            </ng-container>

            <!-- deceasedName Column -->
            <ng-container matColumnDef="deceasedName" sticky>
              <th mat-header-cell *matHeaderCellDef>Name</th>
              <td mat-cell *matCellDef="let cleaning">{{ cleaning.deceasedName }} {{ cleaning.deceasedSurname }}</td>
            </ng-container>

             <!-- deceasedSurname Column -->
             <!-- <ng-container matColumnDef="deceasedSurname" sticky>
              <th mat-header-cell *matHeaderCellDef>Surname</th>
              <td mat-cell *matCellDef="let cleaning">{{ cleaning.deceasedSurname }}</td>
            </ng-container> -->

            <!-- idCardNumber ref no Column -->
            <ng-container matColumnDef="cleaningDate">
              <th mat-header-cell *matHeaderCellDef> Cleaning Date </th>
              <td mat-cell *matCellDef="let cleaning">{{ cleaning.cleaningDate | date: 'dd/MM/yyyy' }} </td>
            </ng-container>

            <!-- timeForCleaning -->
            <!-- <ng-container matColumnDef="timeForCleaning">
              <th mat-header-cell *matHeaderCellDef fxShow="true" fxHide.lt-md> Time</th>
              <td mat-cell *matCellDef="let cleaning" fxShow="true" fxHide.lt-md>{{ cleaning.timeForCleaning }} </td>
            </ng-container>  -->
            
             <!--LevelId -->
             <ng-container matColumnDef="levelId">
              <th mat-header-cell *matHeaderCellDef fxShow="true" fxHide.lt-md>Grave level</th>
              <td mat-cell *matCellDef="let cleaning" fxShow="true" fxHide.lt-md>{{cleaning.levelDescription}} {{cleaning.levelStatus }}</td>
            </ng-container>

            <!--Undertaker -->
            <ng-container matColumnDef="undertakerName">
              <th mat-header-cell *matHeaderCellDef> Undertaker </th>
              <td mat-cell *matCellDef="let cleaning">
                <!-- {{ cleaning.undertakerName }} {{ cleaning.undertakerSurname }} -->
                {{ cleaning.undertakerName || '' }} {{ cleaning.undertakerSurname || '' }}
              </td>
            </ng-container>

            <!--applicationDate -->
            <ng-container matColumnDef="marbleslab">
              <th mat-header-cell *matHeaderCellDef fxShow="true" fxHide.lt-md> Marble Slab</th>
              <td mat-cell *matCellDef="let cleaning" fxShow="true" fxHide.lt-md>{{ cleaning.marbleSlab ? 'Yes' : 'No' }}</td>
            </ng-container>
            
            <!-- status -->
            <ng-container matColumnDef="cleaningStatusDescription" stickyEnd>              
              <th mat-header-cell *matHeaderCellDef fxShow="true" fxHide.lt-md>Cleaning Status</th>              
              <td mat-cell *matCellDef="let cleaning" fxShow="true" fxHide.lt-md 
              [ngClass]="{
                'status_pending': cleaning.cleaningStatusDescription === 'Pending',
                'status_overdue': cleaning.cleaningStatusDescription === 'Overdue'
              }"> 
                <b>{{cleaning.cleaningStatusDescription}}</b>
              </td>
            </ng-container>  

            <ng-container matColumnDef="expand" stickyEnd>
              <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
              <td mat-cell *matCellDef="let cleaning">
                <button mat-icon-button aria-label="expand row"  (click)="expandCollapse(cleaning); $event.stopPropagation()" >
                  <ng-container *ngIf="expandedElement === cleaning">
                      <mat-icon>keyboard_arrow_up</mat-icon>
                  </ng-container>
                  <ng-container *ngIf="expandedElement !== cleaning">
                      <mat-icon>keyboard_arrow_down</mat-icon>
                  </ng-container>
              </button>
              </td>
            </ng-container>

             <!-- TRANSPORT -->

            <ng-container matColumnDef="transport" stickyEnd>
              <th mat-header-cell *matHeaderCellDef fxShow="true" fxHide.lt-md>Transport</th>
              <td mat-cell *matCellDef="let cleaning" fxShow="true" fxHide.lt-md>      
                
                <!-- TRANSPORT BUTTON -->
                <mat-slide-toggle
                  class="custom-slide-toggle"
                  color="primary"
                  [checked]="cleaning.transport ? true : false"                  
                  (change)="onToggleChange($event)"               
                  >
                 
                </mat-slide-toggle>
                <button mat-icon-button color="primary" (click)="openTransportComponent(cleaning)" matTooltip="Transport">
                  <mat-icon>transfer_within_a_station</mat-icon>
                </button>
              </td>
             
            </ng-container>

              <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail" stickyEnd>
              <td mat-cell *matCellDef="let cleaning" [attr.colspan]="displayedColumnsWithExpand.length">
                <div class="expand-detail" [@detailExpand] = "cleaning == expandedElement ? 'expanded' : 'collapsed' ">
                  <div class="expand-category">
                    <div class="expand-field">Supervisor Name : &nbsp;<span>{{cleaning.supervisorName}}</span>
                    Supervisor Surname : &nbsp;<span>{{cleaning.supervisorSurname}}</span>
                    Provider Name : &nbsp;<span>{{cleaning.providerName}}</span>
                    Marble Slab :  &nbsp;<span>{{ cleaning.marbleSlab ? 'Yes' : 'No' }}</span>
                    Family Present :&nbsp;<span>{{ cleaning.isFamilyPresent }}</span>
                    
                  </div>
                    <div class="expand-field"> 
                      Application Date : &nbsp;<span>{{cleaning.applicationDate | date: 'dd/MM/yyyy'}}</span>
                      Authorising person &nbsp;<span>{{cleaning.authPerson}}</span>
                    </div>
                    
                  </div>
                </div>
              </td>
            </ng-container>            

            <ng-container matColumnDef="action" stickyEnd>
              <th mat-header-cell *matHeaderCellDef>Action</th>
              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox
                    (change)="toggleAllCheckboxes($event.checked)"
                    [checked]="areAllSelected()">
                  </mat-checkbox> Select All
                </th>
                  <td mat-cell *matCellDef="let cleaning">
                    <button mat-icon-button color="primary" [disabled] = "cleaning.transport == null"
                      (click)="printBurialPermitTransport(cleaning)" matTooltip="Print transport burial permit">
                      <mat-icon>print</mat-icon> 
                    </button>                

                    <mat-checkbox  class="example-margin"
                      (change)="onCheckboxChange(cleaning)" 
                      [checked]="isSelected(cleaning)">
                    </mat-checkbox>

                    <button class="editbutton"  mat-icon-button  color="primary" 
                      (click)="updateRowCleaning(cleaning)" matTooltip="Edit cleaning"
                      [disabled]="cleaning.cleaningTransportStatusId === 6 || cleaning.cleaningTransportStatusId === 7">
                      <mat-icon>mode</mat-icon>
                    </button> 
                    <button mat-icon-button color="warn" 
                      (click)="deleteCleaning(cleaning.id)"  matTooltip="Delete Cleaning"
                      [disabled]="cleaning.cleaningTransportStatusId === 6 || cleaning.cleaningTransportStatusId === 7">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </ng-container>              
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsWithExpand"></tr>
            <tr mat-row *matRowDef="let grave; columns: displayedColumnsWithExpand;" class="expand-Inside"
              [class.example-expanded-row]="expandedElement != grave" ></tr>
              <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" matRipple class="expand-Rowdetails"></tr>        

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">No data matching the filter "{{ input.value }}"</td>
            </tr>
          </table>
      </div>                          
        
      <mat-paginator class="paginator" [pageSizeOptions]="[25, 100,200]" [length]="resultsLength" aria-label="Select page of burial permits"></mat-paginator>
    </mat-dialog-content> 
      <div class="buttonalign"> 
        
        <button mat-raised-button class="first-button" color="primary"
        (click)="newCleaning()" matTooltip="Add Cleaning">
          <mat-icon>add</mat-icon>Add
        </button>
                  
          <button mat-raised-button class="first-button" color="primary"
            (click)="carryOut()"
            [disabled]="(
              cleaningFormGroup.get('undertakerCtrl')?.value === 0 ||  
              !cleaningFormGroup.get('burialRightsCtrl')?.value  ||
              !anyCheckboxChecked) ||
              disableProcessButton ">Process 
          </button>

          
          <button mat-raised-button class="first-button" color="warn"
            (click)="updateAllCleaningClick()"
            [disabled]="cleaningFormGroup.get('undertakerCtrl')?.value === 0 || !areAllSelected() || hasInvalidStatus()" >Update
          </button> 
          
          <button mat-raised-button  class="second-button" color="primary" (click)="printWorkOrder()" [disabled]="!anyCheckboxChecked"><mat-icon>summarize</mat-icon>Print Work Order 
          </button>
            
      </div>
  
       <!--END OF TABLE-->
     
    </form>

  </div>
  
</div>