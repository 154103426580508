import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IResponseModal } from '../models/ResponseModal.model';

@Component({
  selector: 'app-responsemodal',
  templateUrl: './responsemodal.component.html',
  styleUrls: ['./responsemodal.component.css']
})
export class ResponsemodalComponent {

constructor(@Inject(MAT_DIALOG_DATA) public data: IResponseModal) {}
}
