import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { RoleDto } from 'src/app/models/RoleDto.model';
import { RoleService } from 'src/app/services/role.service';
import { EditroleDialogComponent } from './editroledialog/editroledialog.component';
import { AddRoleDialogComponent } from './add-roledialog/add-roledialog.component';
import { RoleModuleDto } from 'src/app/models/RoleModuleDto.model';
import { LinkmoduleComponent } from '../linkmodule/linkmodule.component';

@Component({
  selector: 'app-userroles',
  templateUrl: './userroles.component.html',
  styleUrls: ['./userroles.component.css']
})
export class UserrolesComponent implements OnInit, AfterViewInit {
  roles: RoleDto[] = []; 
  displayedColumns: string[] = [
    'roleId',
    'roleName',
    'description',
    //'createdAt',
    'actions',
  ];
  resultsLength = 0;
  dataSource = new MatTableDataSource<RoleDto>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(private roleService: RoleService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.loadRoles();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }
  loadRoles(): void {
    this.roleService.getAllRoles().subscribe(
      (response: RoleDto[]) => {
        this.roles = response;
       
        this.dataSource = new MatTableDataSource(this.roles); 
        this.resultsLength = this.roles.length;
      },
      (error) => {
        console.error('Error fetching roles:', error);
      }
    );
  }
  
  openEditDialog(role: RoleDto): void {
    const dialogRef = this.dialog.open(EditroleDialogComponent, {
      width: '300px',
      data: { ...role }  // Pass a copy of the role to avoid direct mutations
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.roleService.updateRole(result.id, result).subscribe(
          () => {
            const index = this.roles.findIndex(r => r.id === result.id);
            if (index >= 0) {
              this.roles[index] = result;
              this.roles = [...this.roles];  // Reassign the updated array to create a new reference
              this.dataSource.data = this.roles;  // Update the table's data source
              this.resultsLength = this.roles.length;  // Update the results length
            }
          },
          (error) => {
            console.error('Error updating role:', error);
          }
        );
      }
    });
  }



  
  openAddRoleDialog(): void {
    const dialogRef = this.dialog.open(AddRoleDialogComponent, {
      width: '300px',
      data: { roleName: '', description: '', createdAt: new Date() }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.roleService.addRole(result).subscribe(
          (newRole) => {
            this.roles = [...this.roles, newRole];  
            this.dataSource.data = this.roles;  
            this.resultsLength = this.roles.length;  
          },
          (error) => {
            console.error('Error adding new role:', error);
          }
        );
      }
    });
  }

  deleteRole(roleId: number): void {
    this.roleService.deleteRole(roleId).subscribe(
      () => {
       
        this.roles = this.roles.filter(role => role.id !== roleId);
        this.dataSource.data = [...this.roles];
        this.resultsLength = this.roles.length; 
      },
      (error) => {
        console.error('Error deleting role:', error);
      }
    );
  }
 
  addModuleToRole(role:RoleDto){
    const dialogRef = this.dialog.open(LinkmoduleComponent, {
      data: role        
    });

    dialogRef.afterClosed().subscribe(result => {
      //this.internalService.emitRefreshPermitTable();
      console.log('The dialog was closed');          
    });

  }
}
