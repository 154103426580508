import { Injectable } from '@angular/core';
import { HttpClient,HttpParams  } from '@angular/common/http';
import { Observable, of, catchError } from 'rxjs';
import { IDisintermentDto } from '../models/DisintermentDto.model';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';
import { ITableCountDto } from '../burials/models/ITableCountDto';
import { IBurialPermitDto } from '../burials/models/BurialPermitDto.model';
import { ICleaningDto } from '../models/CleaningDto.model';
import { IBurial } from '../models/Burial.model';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {
  private baseUrl = `${environment.apiUrl}reports/`;  

  constructor(private http: HttpClient, private authService: AuthService) { }

  getDisintermentReport(compartmentId: number,startRange: number, endRange: number): Observable<IDisintermentDto[]> {    
    let params = new HttpParams();
    const headers = this.authService.getAuthHeaders();
    params = params.append('compartmentId', compartmentId.toString());
    params = params.append('startRange', startRange.toString());
    params = params.append('endRange', endRange.toString());

    return this.http.get<IDisintermentDto[]>(this.baseUrl + 'GetDisintermentReport', { params,headers })
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );  
  };

  getBurialPermitsByOdcReceived(dateFrom: Date | null, dateTo: Date | null): Observable<IBurialPermitDto[]> {
    const headers = this.authService.getAuthHeaders();
    let params = new HttpParams();
    if (dateFrom) {
        params = params.append('dateFrom', dateFrom.toDateString());
    }
    if (dateTo) {
        params = params.append('dateTo', dateTo.toDateString());
    }

    return this.http.get<IBurialPermitDto[]>(this.baseUrl + 'GetBurialPermitsByOdcReceived', { params, headers })
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };

  getCleaningByDate(dateFrom: Date, dateTo: Date): Observable<ICleaningDto[]> {    
    let params = new HttpParams();
    const headers = this.authService.getAuthHeaders();

        params = params.append('dateFrom', dateFrom.toDateString());
        params = params.append('dateTo', dateTo.toDateString());

    return this.http.get<ICleaningDto[]>(this.baseUrl + 'GetCleaningByDate', { params,headers })
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };

  getBurialPermitsIssued(issueDateFrom: Date, issueDateTo: Date): Observable<IBurialPermitDto[]> {
    const headers = this.authService.getAuthHeaders();
    let params = new HttpParams();
        params = params.append('issuedateFrom', issueDateFrom.toDateString());
        params = params.append('issuedateTo', issueDateTo.toDateString());
    return this.http.get<IBurialPermitDto[]>(this.baseUrl + 'GetBurialPermitsByIssued', { params, headers })
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };
  
}