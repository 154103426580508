import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IDivisionDto } from '../models/DivisionDto.model';
import { Observable, catchError, map, tap } from 'rxjs';
import { IAddNewDivisionDto } from '../models/AddNewDivisionDto.model';
import { IResponseModal } from '../models/ResponseModal.model';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class DivisionService {

  private baseUrl = `${environment.apiUrl}division/`; 

  constructor(private http: HttpClient, private authService: AuthService) { }

  getDivisionsByCementeryId(id:number): Observable<IDivisionDto[]> {  
    const headers = this.authService.getAuthHeaders();  
    const endpoint = 'GetDivisionsByCementeryId'; 
    const url = `${this.baseUrl}${endpoint}?cemeteryId=${id}`;
    return this.http.get<IDivisionDto[]>(url,{headers})
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };
  getAllDivisions(pageNumber: number = 1, pageSize: number = 100): Observable<IDivisionDto[]> {    
    let params = new HttpParams();
    const headers = this.authService.getAuthHeaders();
    params = params.append('pageNumber', pageNumber.toString());
    params = params.append('pageSize', pageSize.toString());

    return this.http.get<IDivisionDto[]>(this.baseUrl + 'GetAllDivisions', { params,headers })
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };

  updateDivisionDetails(id: number, DivisionForUpdateDto: IDivisionDto): Observable<IResponseModal> {
    const url = `${this.baseUrl}${id}`;
    const headers = this.authService.getAuthHeaders();
    return this.http.put<HttpResponse<IResponseModal>>(url , DivisionForUpdateDto, { observe: 'response',headers }).pipe(
      map(response => {
        
        let modal: IResponseModal = {
          id: 0,
          title: 'Update Division',
          message: ''
        };
        if (response.status === 204) {  
          
          modal.message = 'Record Updated';

        } else if (response.status === 200) {    
          
          modal.message = 'To update a division at least one field needs to change, currently not changes have been registered.';

        } else {
          modal.message =`Unexpected status code: ${response.status}`;
          
          throw new Error(`Unexpected status code: ${response.status}`);
        }
        return modal;
      })
    );

  }
  addNewDivision(addNewDivisionDto:  IAddNewDivisionDto): Observable<IResponseModal> {
    const headers = this.authService.getAuthHeaders();
    return this.http.post<IResponseModal>(this.baseUrl + 'AddDivision', addNewDivisionDto,{ observe: 'response',headers }).pipe(
      tap(response => {
        console.log('Add Division' + response.status);
      }),
      map(response => {
        let modal: IResponseModal = {
          id: 0,
          title: 'Add Division',
          message: '',
          entity : response.body
        };
  
        if (response.status === 201) {
          modal.message = 'Division added';
        } else {
          modal.message = `Unexpected status code: ${response.status}`;
          throw new Error(`Unexpected status code: ${response.status}`);
        }
        
        return modal;
      }),
        catchError(error => {
            console.error('Error:', error);
            throw error;
        })
    );
  }
  
  deleteDivision(id : number) : Observable<IResponseModal>{
    const headers = this.authService.getAuthHeaders();
    const url = `${this.baseUrl}${id}`;
    return this.http.delete<IResponseModal>(url,{headers})
    .pipe(
      catchError(error => {
          console.error('Error:', error);
          throw error;
      })
  );
  }
}
