
<div class="container">
    <div class="form">
        <form>
<span class="mat-title" mat-dialog-title><b>Edit Grave Owner Details</b> </span>
<mat-dialog-content>

      <div class="box">
        <mat-form-field>
            <mat-label>D/H File No</mat-label>
            <input matInput placeholder="D/H File No" required />
          </mat-form-field>
        <mat-form-field>
          <mat-label> Grave Application No</mat-label>
          <input matInput placeholder="Grave Application No" required />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Owner Type </mat-label>
          <mat-select>
            <mat-option>Others</mat-option>
            <mat-option value="1">Main Owner</mat-option>
            <mat-option value="2">Co-owner</mat-option>
          </mat-select>
        </mat-form-field>
        <!-- <mat-form-field>
          <mat-label>Application Date</mat-label>
          <input matInput [matDatepicker]="pickerDateOfApplication" placeholder="Application Date" />
      <mat-datepicker-toggle matIconSuffix [for]="pickerDateOfApplication">
      </mat-datepicker-toggle>
      <mat-datepicker #pickerDateOfApplication></mat-datepicker>
        </mat-form-field> -->
      </div>
      <div class="box">
        <mat-form-field>
            <mat-label>Id Card Number</mat-label>
            <input matInput placeholder="Id Card Number" required />
          </mat-form-field>
        <mat-form-field>
          <mat-label>Grave Owner Name </mat-label>
          <input matInput placeholder="First Name" required/>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Grave Owner Surname </mat-label>
          <input matInput placeholder="Surname" required/>
          </mat-form-field>
        </div>
        <div class="box">
          <mat-form-field>
            <mat-label>Address </mat-label>
            <input matInput placeholder="Address" required/>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Locality </mat-label>
            <mat-select [(value)]="localityOption">
              <mat-option>None</mat-option>
              <mat-option value="option1">Option 1</mat-option>
              <mat-option value="option2">Option 2</mat-option>
              <mat-option value="option3">Option 3</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Telephone</mat-label>
            <input matInput placeholder="Telephone" required/>
          </mat-form-field>
            </div>
 
  <div class="box">
    <mat-form-field>
        <mat-label>Cemetery Name </mat-label>
        <mat-select [(value)]="cemeteryOption">
          <mat-option>None</mat-option>
          <mat-option value="option1">Option 1</mat-option>
          <mat-option value="option2">Option 2</mat-option>
          <mat-option value="option3">Option 3</mat-option>
        </mat-select>
      </mat-form-field>
    <mat-form-field>
        <mat-label>Division</mat-label>
        <mat-select [(value)]="divisionOption">
          <mat-option>None</mat-option>
          <mat-option value="option1">Option 1</mat-option>
          <mat-option value="option2">Option 2</mat-option>
          <mat-option value="option3">Option 3</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Section </mat-label>
        <mat-select [(value)]="sectionOption">
          <mat-option>None</mat-option>
          <mat-option value="option1">Option 1</mat-option>
          <mat-option value="option2">Option 2</mat-option>
          <mat-option value="option3">Option 3</mat-option>
        </mat-select>
      </mat-form-field>
  </div>
  <div class="box">
    <mat-form-field>
        <mat-label>Compartment </mat-label>
        <mat-select [(value)]="compartmentOption">
          <mat-option>None</mat-option>
          <mat-option value="option1">Option 1</mat-option>
          <mat-option value="option2">Option 2</mat-option>
          <mat-option value="option3">Option 3</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
      <mat-label>Grave number</mat-label>
      <mat-select [(value)]="graveNO">
        <mat-option>None</mat-option>
        <mat-option value="option1">Option 1</mat-option>
        <mat-option value="option2">Option 2</mat-option>
        <mat-option value="option3">Option 3</mat-option>
      </mat-select>
    </mat-form-field>
   </div>
 
<mat-dialog-actions>
    <div class="divspace">
  <button mat-raised-button color="primary" (click)="onGraveownerUpdate()">Update</button>
  <button mat-raised-button style="color:dimgray;" [mat-dialog-close] cdkFocusInitial>Close</button></div>
</mat-dialog-actions>

</mat-dialog-content>
</form>
</div>
</div>



