<div class="dialog-header">
  <button mat-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="container-popup">
  <form [formGroup]="previewFormGroup" class="form">
    <span class="mat-title" mat-dialog-title>Burial Permit Historic Records</span>
    <mat-dialog-content>
      
      <div class="box">
        <!-- <h4>Current Burial permit</h4> -->
        <mat-form-field>
          <mat-label>Burial Permit Id</mat-label>
          <input matInput placeholder="Name" formControlName="permitNumberCtrl" maxlength="50"/>            
        </mat-form-field>
        <mat-form-field>
          <mat-label>Id Card Number</mat-label>              
          <input matInput placeholder="Card Number" formControlName="cardNumberCtrl" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input matInput placeholder="Name" formControlName="nameCtrl" maxlength="50"/>            
        </mat-form-field>
        <mat-form-field>
          <mat-label>Surname</mat-label>
          <input matInput placeholder="Surname" formControlName="surnameCtrl" maxlength="50"/>            
        </mat-form-field>
      </div>
      <div class="box header-alignment">
        <h4>ReIssued Burial Permits</h4>
        <div class="table-container mat-elevation-z2">
      <table mat-table [dataSource]="dataSource"  multiTemplateDataRows matSort>
        <!-- Define your table columns -->
          <ng-container matColumnDef="permitNumber">
            <th mat-header-cell *matHeaderCellDef >Burial Permit Id</th>
            <td mat-cell *matCellDef="let originalsBurialPermits">{{originalsBurialPermits.permitNumber}}</td>
          </ng-container>

          <ng-container matColumnDef="deceasedIdCardNumber">
            <th mat-header-cell *matHeaderCellDef >Id Card</th>
            <td mat-cell *matCellDef="let originalsBurialPermits">{{originalsBurialPermits.deceasedIdCardNumber}}</td>
          </ng-container>

          <ng-container matColumnDef="deceasedName">
            <th mat-header-cell *matHeaderCellDef  fxShow="true" fxHide.lt-md>Name</th>
            <td mat-cell *matCellDef="let originalsBurialPermits" fxShow="true" fxHide.lt-md>{{originalsBurialPermits.deceasedName}}</td>
          </ng-container>

          <ng-container matColumnDef="deceasedSurName">
            <th mat-header-cell *matHeaderCellDef  fxShow="true" fxHide.lt-md>Surname</th>
            <td mat-cell *matCellDef="let originalsBurialPermits" fxShow="true" fxHide.lt-md>{{originalsBurialPermits.deceasedSurName}}</td>
          </ng-container>

          <!-- <ng-container matColumnDef="graveNo">
            <th mat-header-cell *matHeaderCellDef  fxShow="true" fxHide.lt-md>Grave No</th>
            <td mat-cell *matCellDef="let originalsBurialPermits" fxShow="true" fxHide.lt-md>{{originalsBurialPermits.graveNo}}</td>
          </ng-container> -->

          <ng-container matColumnDef="burialDate">
            <th mat-header-cell *matHeaderCellDef  fxShow="true" fxHide.lt-md>Date Of Burial</th>
            <td mat-cell *matCellDef="let originalsBurialPermits" fxShow="true" fxHide.lt-md>{{formatDate(originalsBurialPermits.burialDate)}}</td>
          </ng-container>

          <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
            <td mat-cell *matCellDef="let originalsBurialPermits">
              <button mat-icon-button aria-label="expand row" (click)="expandCollapse(originalsBurialPermits); $event.stopPropagation()">
                <ng-container *ngIf="expandedElement === originalsBurialPermits">
                    <mat-icon>keyboard_arrow_up</mat-icon>
                </ng-container>
                <ng-container *ngIf="expandedElement !== originalsBurialPermits">
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </ng-container>
            </button>
            </td>
          </ng-container>
          <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let burialPermitView" [attr.colspan]="displayedColumnsWithExpand.length">
              <div class="expand-detail" [@detailExpand] = "burialPermitView == expandedElement ? 'expanded' : 'collapsed' ">
                <div class="expand-category">
                  <div class="expand-field">
                    Cemetery: &nbsp;<span>{{burialPermitView.cemeteryName}}</span>
                    Division: &nbsp;<span>{{burialPermitView.divisionName}}</span>
                    Section:  &nbsp;<span>{{burialPermitView.sectionName}}</span>
                    Compartment:&nbsp;<span>{{burialPermitView.compartmentName}}</span>
                    Grave Number : &nbsp; <span>{{burialPermitView.graveNumber}}</span>
                    Grave Level : &nbsp; <span>{{burialPermitView.graveNo}}</span>
                  </div>
                  <div class="expand-field">
                    <!--Mass Time :&nbsp;<span>{{burialPermitView.massTime}}</span>
                    Mass Locality:&nbsp;<span>{{burialPermitView.massLocalityId}}</span>
                    Undertaker:&nbsp;<span>{{burialPermitView.undertakerId}}</span>-->
                    Cause Of Death : &nbsp;<span>{{burialPermitView.causeOfDeath}}</span>
                    Place Of Death : &nbsp;<span>{{burialPermitView.placeOfDeath}}</span>
                  </div>
                  <!-- For Mobile view -->
                  <div class="expand-field"  fxShow="true" fxHide.gt-sm>
                    Place Of Death :&nbsp;<span>{{burialPermitView.placeOfDeath}}</span>
                    Date of Burial : &nbsp; <span>{{burialPermitView.burialDate}}</span>
                  </div>
                  <div class="expand-field" fxShow="true" fxHide.gt-sm>
                    Cause Of Death : &nbsp;<span>{{burialPermitView.causeOfDeath}}</span>
                  </div> 
                </div>
              </div>
            </td>
          </ng-container>
        <!-- Table rows -->
        <tr mat-header-row *matHeaderRowDef="displayedColumnsWithExpand"></tr>
        <tr mat-row *matRowDef="let burialPermitView; columns: displayedColumnsWithExpand;" class="expand-Inside"
          [class.example-expanded-row]="expandedElement != burialPermitView" ></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" matRipple class="expand-Rowdetails"></tr>
        <tr class="mat-row" *matNoDataRow></tr>
        </table>
        </div>
      </div>
      
    </mat-dialog-content>
    <div class="buttonalign">
      <button mat-raised-button style="background-color: rgb(143, 141, 141)"  [mat-dialog-close] cdkFocusInitial><mat-icon>close</mat-icon> Close </button>
  </div>
  </form>
</div>
