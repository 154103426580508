
<div class="container">
  <div class="form">
    <form  [formGroup]="DivisionNewForm">
<span class="mat-title" mat-dialog-title><b>Add New Division</b> </span>
<mat-dialog-content> 
  <div class="box">
      <mat-form-field>
        <mat-label>Cemetery Type</mat-label>
          <mat-select formControlName="cemeteryTypeCtrl" >
            <mat-option *ngFor="let cemtype of cemeteryTypes" [value]="cemtype.id">
              {{ cemtype.description }}
            </mat-option>
          </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Cemetery</mat-label>
        <mat-select formControlName="cemeteryCtrl">
          <mat-option *ngFor="let cemetery of cemeteries" [value]="cemetery.code">
            {{ cemetery.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
  </div>
  <div class="box">
    <mat-form-field>
      <mat-label>Locality</mat-label>
      <mat-select formControlName="localityCtrl">
        <mat-option *ngFor="let locality of localities" [value]="locality.id">
          {{ locality.description }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Division</mat-label>
      <input matInput placeholder="Division Name" formControlName="divisionCtrl" required/>
    </mat-form-field>
   </div>
  
<mat-dialog-actions>
    <div class="divspace">
  <button mat-raised-button color="primary" (click)="addNewDivision()">Save</button>
  <button mat-raised-button style="color:dimgray;" [mat-dialog-close] cdkFocusInitial>Close</button></div>
</mat-dialog-actions>

</mat-dialog-content>
</form>
</div>
</div>
