import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ITableCountDto } from 'src/app/burials/models/ITableCountDto';
import { ICleaningDto } from 'src/app/models/CleaningDto.model';
import { ICleaningTransportStatusDto } from 'src/app/models/CleaningTransportStatusDto.model';
import { IResponseModal } from 'src/app/models/ResponseModal.model';
import { ISupervisorsDto } from 'src/app/models/SupervisorsDto.model';
import { IUndertakerDto } from 'src/app/models/UndertakerDto.model';
import { IUpdateCleaningDto } from 'src/app/models/UpdateCleaningDto.model';
import { ResponsemodalComponent } from 'src/app/responsemodal/responsemodal.component';
import { CleaningService } from 'src/app/services/cleaning.service';
import { CommonService } from 'src/app/services/common.service';
import { InternalCommunicationService } from 'src/app/services/internal-communication.service';

@Component({
  selector: 'app-cleaning-edit',
  templateUrl: './cleaning-edit.component.html',
  styleUrls: ['./cleaning-edit.component.css']
})
export class CleaningEditComponent implements OnInit {
  cleaningEditFormGroup!: FormGroup;  
  cleaningStatuses! : ICleaningTransportStatusDto[];

  cleaning: ICleaningDto = {
    id : 0,
    cleaningDate : new Date,
    authPerson : '',
    supervisorId : 0,
    marbleSlab : false,
    isFamilyPresent : false,
    timeForCleaning : '',
    applicationDate : new Date,
    authoriseBy : '',
    authoriseId : '',
    //employeeId : 0,
    graveId : 0,
    levelId : 0,
    //providerId :0,
    feePaid : false,
    burialId : 0,
    cleaningTransportStatusId : 0,
    cleaningStatusDescription : '',
    supervisorName : '',
    supervisorSurname : '',
    employeeName : '',
    employeeSurname : '',
    providerName : '',
    //cleaningRate : 0,
    feeId : 0.,
    undertakerId : 0,
    
  };

  
  supervisors! : ISupervisorsDto[];
  //allEmployees! : IEmployeDto[];
  //employees! : IEmployeDto[];
  originalCleanings! :ICleaningDto[]; 
  cleanings! :ICleaningDto[];
  currentsCleanings! : ICleaningDto[];
  selectedClean: ICleaningDto | null = null;
  undertakers : IUndertakerDto[]  = [];

  constructor(
    public dialogRef: MatDialogRef<CleaningEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ICleaningDto,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private commonService : CommonService,
    private internalService : InternalCommunicationService,
    private cleaningService : CleaningService)
     {      
      this.cleaning = data;
     }
     ngOnInit(): void {     
         
      this.getAllSupervisors();      
      this.internalService.cleaningUpdated$.subscribe(() => {          
        //this.refreshTableData();
      });
    
        this.cleaningEditFormGroup = this._formBuilder.group({     
          cleaningPermitCtrl: [{value: this.cleaning ? this.cleaning.cleaningPermitNumber : '',disabled : true}],
          cleaningDateCtrl: [this.cleaning ? this.cleaning.cleaningDate : new Date, [Validators.required,]], 
          authorisingPersonCtrl: [this.cleaning ? this.cleaning.authPerson : '',],
          supervisorCtrl: [this.cleaning ? this.cleaning.supervisorId : 0, [Validators.required,Validators.min(1)]], 
          marbleSlabCtrl: [this.cleaning.marbleSlab === true ? true : this.cleaning.marbleSlab === false ? false : null],
          isFamilyPresentCtrl: [this.cleaning ? this.cleaning.isFamilyPresent : ''],
          timeForCleaningCtrl: [this.cleaning ? this.cleaning.timeForCleaning : ''],
          applicationDateCtrl: [this.cleaning ? this.cleaning.applicationDate : new Date],
          authoriseByCtrl: [this.cleaning ? this.cleaning.authoriseBy : '', ],
          authoriseIdCtrl: [this.cleaning ? this.cleaning.authoriseId : '', ],
          //cleanedByIdCtrl: [this.cleaning ? this.cleaning.employeeId : 0, ], 
          undertakerCtrl: [{value: this.cleaning.undertakerId ? this.cleaning.undertakerName?? '' + '' + this.cleaning.undertakerSurname?? '' : '', disabled: true}], 
          feePaidCtrl :  [this.cleaning ? this.cleaning.feePaid : false],           
          burialCtrl : [this.cleaning ? this.cleaning.burialId : 0, ], 
        });
        this.subscribeChanges();
      }

      subscribeChanges() {
        this.cleaningEditFormGroup.get('cleaningPermitCtrl')?.valueChanges.subscribe((value) => {          
          this.cleaning.cleaningPermitNumber = value;
        });
        this.cleaningEditFormGroup.get('undertakerIdCtrl')?.valueChanges.subscribe((value) => {          
          this.cleaning.undertakerId = value;
        });
        this.cleaningEditFormGroup.get('cleaningDateCtrl')?.valueChanges.subscribe((value) => {          
          this.cleaning.cleaningDate = new Date(value) ;
        });
        this.cleaningEditFormGroup.get('authorisingPersonCtrl')?.valueChanges.subscribe((value) => {          
          this.cleaning.authPerson = value;
        });    
    
        this.cleaningEditFormGroup.get('marbleSlabCtrl')?.valueChanges.subscribe((value) => {          
          this.cleaning.marbleSlab = value === 0 ? false : true;
        });
        this.cleaningEditFormGroup.get('isFamilyPresentCtrl')?.valueChanges.subscribe((value) => {          
          this.cleaning.isFamilyPresent = value;
        });
        this.cleaningEditFormGroup.get('timeForCleaningCtrl')?.valueChanges.subscribe((value) => {          
          this.cleaning.timeForCleaning = value;
        });
        this.cleaningEditFormGroup.get('applicationDateCtrl')?.valueChanges.subscribe((value) => {          
          this.cleaning.applicationDate = new Date(value);
        });
        this.cleaningEditFormGroup.get('authoriseByCtrl')?.valueChanges.subscribe((value) => {          
          this.cleaning.authoriseBy = value;
        });
        this.cleaningEditFormGroup.get('authoriseIdCtrl')?.valueChanges.subscribe((value) => {          
          this.cleaning.authoriseId = value;
        });    
        this.cleaningEditFormGroup.get('feePaidCtrl')?.valueChanges.subscribe((value) => {          
          this.cleaning.feePaid = value;
        });  
        this.cleaningEditFormGroup.get('undertakerIdCtrl')?.valueChanges.subscribe((value) => {          
          this.cleaning.undertakerId = value;
        });      
      }

  //resources
  getAllSupervisors(){
    this.commonService.getAllSupervisors(null,null,1,100).subscribe(
      (response : ITableCountDto) =>{
        this.supervisors = response.results;
  
      }, (error) =>{
        console.error('An error happens retrieving supervisors data')
  
      })
   }
   /*getAllEmployees(){
    this.commonService.getAllEmployees(null,null,1,100).subscribe(
      (response : ITableCountDto) =>{
        this.employees = response.results;
        this.allEmployees = response.results;
  
      }, (error) =>{
        console.error('An error happens retrieving employees data')
  
      })
    }*/
    

      //DROP DOWN
   
     /*employeeChange(employeeId : number){
       this.cleaning.employeeId = employeeId;
     }*/
     supervisorChange(supervisorId : number){
       this.cleaning.supervisorId = supervisorId;
     }
    
     updateClick(cleaning : ICleaningDto){
      const update : IUpdateCleaningDto = this.convertCleaningToUpdateCleaningDto(cleaning);
      this.cleaningService.updateCleaning(cleaning.id, update).subscribe(
        (response :IResponseModal ) => {
          this.openResponseModal(response);
          this.internalService.emitRefreshCleaningTable();    
        }, (error) =>{
          console.error('error updateing cleaning : ', error)
        })
    }

    convertCleaningToUpdateCleaningDto(cleaning : ICleaningDto) : IUpdateCleaningDto{      
      const updateCleaning : IUpdateCleaningDto = {
        cleaningPermitNumber : cleaning.cleaningPermitNumber,
        cleaningDate : this.utcDate( new Date(cleaning.cleaningDate)),
        authPerson : cleaning.authPerson,
        supervisorId : cleaning.supervisorId ?? 0,
        marbleSlab : cleaning.marbleSlab,
        isFamilyPresent : cleaning.isFamilyPresent,
        timeForCleaning : cleaning.timeForCleaning,
        applicationDate : cleaning.applicationDate,
        authoriseBy : cleaning.authoriseBy,
        authoriseId : cleaning.authoriseId,        
        graveId : cleaning.graveId,
        levelId : cleaning.levelId,        
        burialId : cleaning.burialId,
        cleaningTransportStatusId : cleaning.cleaningTransportStatusId,
        undertakerId : cleaning.undertakerId,
        feePaid : cleaning.feePaid,
      }
      return updateCleaning;
    }
    utcDate(localDate : Date) : Date{
      const utcDate: Date = new Date(Date.UTC(
        localDate.getFullYear(), 
        localDate.getMonth(), 
        localDate.getDate(), 
        localDate.getHours(), 
        localDate.getMinutes(), 
        localDate.getSeconds()
      ));
      return utcDate;
    }

    openResponseModal(response : IResponseModal): void {
      const dialogRef = this.dialog.open(ResponsemodalComponent, {
        width: '250px',
        data : response,
      });
    
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
    }
    
}
