import { Injectable } from '@angular/core';
import { ISectionDto } from '../models/SectionDto.model';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, catchError, map, tap } from 'rxjs';
import { IAddNewSectionDto } from '../models/AddNewSectionDto.model';
import { IResponseModal } from '../models/ResponseModal.model';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class SectionService {

  private baseUrl = `${environment.apiUrl}section/`;
  constructor(private http: HttpClient, private authService: AuthService) { }
  
  GetSectionByDivisionId(id:number): Observable<ISectionDto[]> {   
    const headers = this.authService.getAuthHeaders(); 
    const endpoint = 'GetSectionByDivisionId'; 
    const url = `${this.baseUrl}${endpoint}?divisionId=${id}`;
    return this.http.get<ISectionDto[]>(url,{headers})
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };

  getSectionByDivisionIdAndCemeteryId(divisionId:number, cemeteryId : number ): Observable<ISectionDto[]> {   
    const headers = this.authService.getAuthHeaders(); 
    const endpoint = 'GetSectionByDivisionIdAndCemeteryId'; 
    const url = `${this.baseUrl}${endpoint}?divisionId=${divisionId}&cemeteryId=${cemeteryId}`;
    return this.http.get<ISectionDto[]>(url,{headers})
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };

  getAllSections(pageNumber: number = 1, pageSize: number = 1000): Observable<ISectionDto[]> {    
    let params = new HttpParams();
    const headers = this.authService.getAuthHeaders();
    params = params.append('pageNumber', pageNumber.toString());
    params = params.append('pageSize', pageSize.toString());

    return this.http.get<ISectionDto[]>(this.baseUrl+ 'GetAllSections', { params,headers })
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };

  updateSectionDetails(id: number, SectionForUpdateDto: ISectionDto): Observable<IResponseModal> {
    const url = `${this.baseUrl}${id}`;
    const headers = this.authService.getAuthHeaders();
    return this.http.put<HttpResponse<IResponseModal>>(url , SectionForUpdateDto, { observe: 'response',headers }).pipe(
      map(response => {
        
        let modal: IResponseModal = {
          id: 0,
          title: 'Update Section',
          message: ''
        };
        if (response.status === 204) {  
          
          modal.message = 'Record Updated';

        } else if (response.status === 200) {    
          
          modal.message = 'To update a section at least one field needs to change, currently not changes have been registered.';

        } else {
          modal.message =`Unexpected status code: ${response.status}`;
          
          throw new Error(`Unexpected status code: ${response.status}`);
        }
        return modal;
      })
    );
  }
  addNewSection(addNewSectionDto:  IAddNewSectionDto): Observable<IResponseModal> {
    const headers = this.authService.getAuthHeaders();
    return this.http.post<IResponseModal>(this.baseUrl + 'AddSection', addNewSectionDto,{ observe: 'response',headers }).pipe(
      tap(response => {
        console.log('Add Section' + response.status);
      }),
      map(response => {
        let modal: IResponseModal = {
          id: 0,
          title: 'Add Section',
          message: '',
          entity : response.body
        };
  
        if (response.status === 201) {
          modal.message = 'Section added';
        } else {
          modal.message = `Unexpected status code: ${response.status}`;
          throw new Error(`Unexpected status code: ${response.status}`);
        }
        
        return modal;
      }),
        catchError(error => {
            console.error('Error:', error);
            throw error;
        })
    );
  }
  
  deleteSection(id : number) : Observable<IResponseModal>{
    const headers = this.authService.getAuthHeaders();
    const url = `${this.baseUrl}${id}`;
    return this.http.delete<IResponseModal>(url,{headers})
    .pipe(
      catchError(error => {
          console.error('Error:', error);
          throw error;
      })
  );
  }
}