<div class="dialog-header">
    <button mat-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
</div>

<div id="mainContainer">
    
    <mat-spinner [diameter]="100" *ngIf="isLoading"></mat-spinner>

    <br />

    <iframe id='pdfPreview'></iframe>

    <div id="contentContainer">

        <div #contentToPrint id="contentToPrint">

            <div class="form_header">
                
                <h1>BURIAL REPORT</h1>
                <h3>{{formatDate(burial_dateFrom)}} - {{formatDate(burial_dateTo)}}</h3>

            </div>

            <div class="form_body">

                <table>

                    <thead>
                        <th>PERSON</th>
                        <th>MASS TIME</th>
                        <th>MASS LOCALITY</th>
                        <th>GRAVE</th>
                        <th>REFERENCE</th>
                        <th>RIGHTS</th>
                        <th>UNDERTAKER</th>
                    </thead>

                    <tbody>
                        <tr *ngFor="let burial of burialPermitPreview">
                            <td>{{ burial.name }} {{ burial.surname }}</td>
                            <td>{{ burial.massTime }}</td>
                            <td>{{ burial.massLocality }}</td>
                            <td>{{ burial.cemeteryName }}, {{ burial.divisionName }}, {{ burial.sectionName }}, {{ burial.compartmentName }}, {{ burial.graveNo }}, /{{burial.levelName}}</td>
                            <td>{{ burial.refNo }}</td>
                            <td>{{ burial.burialRight }}</td>
                            <td>{{ burial.undertaker}}</td>
                        </tr>
                    </tbody>

                </table>

            </div> 

        </div>

    </div>

</div>