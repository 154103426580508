import { AfterViewInit, Component, Input, OnInit, ViewChild, inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LocalStorageService } from 'src/app/local-storage.service';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { IBurialPermitDto } from '../../models/BurialPermitDto.model';
import { BurialPermitService } from 'src/app/services/burial-permit.service';
import { catchError, map, merge, Observable, of as observableOf, startWith, switchMap} from 'rxjs';
import { ITableCountDto } from '../../models/ITableCountDto';
import { BurialPermitDetailEditComponent } from '../burial-permit-detail-edit/burial-permit-detail-edit.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { InternalCommunicationService } from 'src/app/services/internal-communication.service';
import { BurialPermitReissueComponent } from '../burial-permit-reissue/burial-permit-reissue.component';
import { BurialPermitDetailPreviewComponent } from '../burial-permit-detail-preview/burial-permit-detail-preview.component';
import { IPreViewBurialPermit } from 'src/app/models/PreViewBurialPermit.model';
import { GenderService } from 'src/app/services/gender.service';
import { LocalityService } from 'src/app/services/locality.service';
import { MaritalStatusService } from 'src/app/services/marital-status.service';
import { BurialTypeService } from 'src/app/services/burial-type.service';
import { IGenderDto } from 'src/app/models/Gender.model';
import { ILocalityDto } from 'src/app/models/LocalityDto.model';
import { IMaritalStatusDto } from 'src/app/models/MaritalStatusDto.model';
import { IBurialTypeDto } from 'src/app/models/BurialTypeDto.model';
import { BurialTemplateComponent } from '../../burial-template/burial-template.component';
import { MatSlideToggleModule, MatSlideToggleChange } from '@angular/material/slide-toggle';
import { BurialService } from 'src/app/services/burial.service';
import { BurialStatus } from 'src/app/models/burial-status.enum';
import { ConfirmationModalComponent } from 'src/app/confirmation-modal/confirmation-modal.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IGraveLevelsDto } from 'src/app/models/GraveLevelsDto.model';
import { BurialAcknowledgeTemplateComponent } from '../../burial-acknowledge-template/burial-acknowledge-template.component';
import { IResponseModal } from 'src/app/models/ResponseModal.model';
import { ResponsemodalComponent } from 'src/app/responsemodal/responsemodal.component';
import { UndertakerService } from 'src/app/services/undertaker.service';
import { IUndertakerDto } from 'src/app/models/UndertakerDto.model';
import { IDivisionDto } from 'src/app/models/DivisionDto.model';
import { DivisionService } from 'src/app/services/division.service';
import { SectionService } from 'src/app/services/section.service';
import { CompartmentService } from 'src/app/services/compartment.service';
import { CemeteryService } from 'src/app/services/cemetery.service';
import { ISectionDto } from 'src/app/models/SectionDto.model';
import { ICompartmentDto } from 'src/app/models/CompartmentDto.model';
import { GraveService } from 'src/app/services/grave.service';
import { IGraveTypeDto } from 'src/app/models/GraveTypeDto.model';
import { BurialPermitDetailsInfoComponent } from '../burial-permit-details-info/burial-permit-details-info.component';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { TransportService } from 'src/app/services/transport.service';
import { IPreViewTransportDto } from 'src/app/models/PreViewTransportDto.model';
import { RoleService } from 'src/app/services/role.service';

export const MY_FORMATS = {
parse: {
  dateInput: 'L',
},
display: {
  dateInput: 'L',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
},
};

@Component({
  selector: 'app-burial-permit-details-view',
  templateUrl: './burial-permit-details-view.component.html',
  styleUrls: ['./burial-permit-details-view.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-GB'
    },
    {
      provide: DateAdapter, useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_FORMATS
    },
  ],
})
export class BurialPermitDetailsViewComponent implements AfterViewInit, OnInit {
  @Input() graveId! : number;
  dcrChekbox! : boolean;
 
  burialDetailsFormGroup!: FormGroup;
  burialPermits!: IBurialPermitDto[];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  disabled = false;

  confirm_check = false;

  displayedColumns: string[] = [     
    'permitNumber', 
    'deceasedIdCardNumber',
    'deceasedName',  
    // 'deceasedSurName',  
    'deathDate',
    'burialDate',
    'burialPermitStatusName',
    'levelId',
    'dateCleaned',  
    'dateTransported',
    'action',
    'confirm',
    'dcr'
  ];
  dataSource!: MatTableDataSource<IBurialPermitDto>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSlideToggleModule) slidetoggle!: MatSlideToggleModule;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(BurialTemplateComponent) burialTemplateComponent!: BurialTemplateComponent;

  graveTypes! : IGraveLevelsDto[];
  localities : ILocalityDto[] = [];
  burialTypes : IBurialTypeDto[] = [];
  massLocalities :  ILocalityDto[] = [];
  genders : IGenderDto[] = [];
  statuses : IMaritalStatusDto[] = [];
  undertakers: IUndertakerDto[] = [];
  divisions:IDivisionDto[] = [];
  sections: ISectionDto[] = [];
  compartments: ICompartmentDto[] = [];
  search! : string;
  dateFrom!: Date;
  dateTo! : Date;

  transportDetails?: any[] = [];

  selectedBurialPermit: ITableCountDto | null = null;
  
  displayedColumnsWithExpand = [...this.displayedColumns];
  expandedElement: ITableCountDto | undefined;

  burialPermitPreview: IPreViewBurialPermit = { 

    // STEP 1
    idCardNumber:'',
    name: '',
    surname: '',
    locality:'',
    address : '',
    profession : '',
    dateOfBirth: new Date(),
    age: 0,
    placeOfBirth: '',
    maritalStatus : '',
    gender:'',
    spouseName: '',
    spouseSurname: '',
    fatherName: '',
    fatherSurname: '',
    fatherStatus: '',
    motherName: '',
    motherSurname: '',
    motherStatus : '',    
    
    // STEP 2
    burialType: '',
    burialTypeId: 0,
    dateOfDeath: new Date(),
    timeOfDeath: '',
    placeOfDeath: '',
    causeOfDeath: '',
    massTime: '',
    massLocality: '', 
    dateOfBurial: new Date(), 
    burialRight : '',
    undertaker : '',    
    issuedDate: new Date(new Date().setDate(new Date().getDate() + 1)),   
  
    // STEP 3
    cemeteryName: '',
    divisionName: '',
    sectionName: '',
    compartmentName: '',    
    graveNo: '',
    levelName:'',
    username:'',
    country:'',
    burialLocation:'',
    healthOfficer:'',
  
    //PERMITNO
    permitNo: 'preview',
    remarks:'',
  
    //CLEANING AND TRANSPORT DETAILS
    dateCleaned: new Date(),
    dateTransported: new Date(),
    transportedFrom: '',
    transportedTo: '',
    cemeteryNameTo: '',
    divisionNameTo: '',
    sectionNameTo: '',
    compartmentNameTo: '',
    graveNoTo: '',
    graveLevelTo: '',  
  
    //REFNO
    refNo: '',

    //SOURCE SYSTEM
    sourceSystemId: 0
      
  };

  constructor(
    private roleService : RoleService,
    private dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private datePipe: DatePipe, 
    private burialPermitService : BurialPermitService,
    private localityService : LocalityService,
    private genderService : GenderService,
    private maritalStatusService : MaritalStatusService,
    private burialTypesService : BurialTypeService,
    private undertakerService: UndertakerService,
    private burialService : BurialService,
    private internalService : InternalCommunicationService,
    private divisionService : DivisionService,
    private sectionService : SectionService,
    private compartmentService : CompartmentService,
    private cemeteryService :CemeteryService,
    private graveService:GraveService,
    private _formBuilder: FormBuilder,
    private transportService: TransportService
  )
    {}
    ngOnInit(): void {
      
      this.internalService.permitUpdated$.subscribe(() => {
        this.refreshTableData();
      });
      this.burialDetailsFormGroup = this._formBuilder.group({
        searchCtrl:  [this.search ? this.search: '',],  
        dateFromCtrl:  [this.dateFrom ? this.dateFrom: '',],  
        dateToCtrl:  [this.dateTo ? this.dateTo: '',],  
  
      });
  
      this.getAllLocalities();
      this.getAllGender();
      this.getAllMaritalStatus();
      this.GetAllBurialTypes();
      this.suscribesChanges();
      this.getAllUndertakers();
      this.getAllDivisions();
      this.getAllSections();
      this.getAllCompartments();

    }

    suscribesChanges(){
      this.burialDetailsFormGroup.get('searchCtrl')?.valueChanges.subscribe((value) => {     
        this.search= value;
      });
  
      this.burialDetailsFormGroup.get('dateFromCtrl')?.valueChanges.subscribe((value) => {     
        this.dateFrom= value._d;
      });
  
      this.burialDetailsFormGroup.get('dateToCtrl')?.valueChanges.subscribe((value) => {     
        this.dateTo= value._d;
      });
     
    }

    ngAfterViewInit(): void {
    
      this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
      merge(this.sort.sortChange, this.paginator.page)
      merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.fetchBurialPermits();
        }),
        map(data => {
         
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;
          
          if (data === null) {
            return [];
          }
          this.burialPermits = data.results; 
         
          this.dataSource = new MatTableDataSource(this.burialPermits);        
          this.dataSource.sort = this.sort;
          this.resultsLength = data.totalItems;
          
          return data;
        }),
      )
      .subscribe();
  
    }

    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
    
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
      this.selectedBurialPermit = null;
    }
    reissueBurialPermitClick(burialPermit: IBurialPermitDto) {
      let permitId: number = +burialPermit.permitNumber;
      this.burialPermitService.getBurialPermitByPermitNumber(permitId).subscribe(
        (permit: IBurialPermitDto) => {
          const dialogRef = this.dialog.open(BurialPermitReissueComponent, {
            data: permit          
          });
  
          dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');          
          });
        },
        error => {
          console.error('Error:', error);        
        }
      );
    }
    editBurialPermitClick(burialPermit: IBurialPermitDto) {
      let permitId: number = +burialPermit.permitNumber;
      this.burialPermitService.getBurialPermitByPermitNumber(permitId).subscribe(
        (permit: IBurialPermitDto) => {
          const dialogRef = this.dialog.open(BurialPermitDetailEditComponent, {
            data: permit ,         
          });
  
          dialogRef.afterClosed().subscribe(result => {
            this.internalService.emitRefreshPermitTable();
            console.log('The dialog was closed');          
          });
        },
        error => {
          console.error('Error:', error);        
        }
      );
    }
    previewBurialPermitClick(burialPermit:IBurialPermitDto){
      const dialogRef = this.dialog.open(BurialPermitDetailPreviewComponent, {     
        data : burialPermit,
      });
    }

    viewBurialPermitClick(burialPermit: IBurialPermitDto) {
      let permitId: number = +burialPermit.permitNumber;
  
      this.burialPermitService.getBurialPermitByPermitNumber(permitId).subscribe(
        (permit: IBurialPermitDto) => {

          this.burialPermitPreview.sourceSystemId = this.burialPermits.find(id => id.permitNumber == permitId.toString())?.sourceSystemId
  
          this.burialPermitPreview.cemeteryName = permit.cemeteryName;
          this.burialPermitPreview.timeOfDeath = permit.timeOfDeath;
          this.burialPermitPreview.dateOfDeath = new Date(permit.deathDate);  
          this.burialPermitPreview.address = permit.deceased.address ?? '';
          this.burialPermitPreview.burialRight = permit.burialRight;  
          this.burialPermitPreview.causeOfDeath = permit.causeOfDeath;  
          this.burialPermitPreview.levelName = permit.levelName;  
          this.burialPermitPreview.graveNo = permit.graveNo;  
          this.burialPermitPreview.levelName = permit.graveLevel;  
          this.burialPermitPreview.dateOfBirth = new Date(permit.deceased.dateOfBirth); 
          this.burialPermitPreview.age = permit.deceased.age;
          this.burialPermitPreview.dateOfBurial = permit.burialDate;  
          this.burialPermitPreview.issuedDate = permit.issuedDate;  
          this.burialPermitPreview.fatherName = permit.deceased.fatherName
          this.burialPermitPreview.fatherSurname = permit.deceased.fatherSurname
          // this.burialPermitPreview.fatherStatus = permit.deceased.fatherStatus
          if(permit.deceased.fatherStatus == ''){
            this.burialPermitPreview.fatherStatus = 'N/A'
          }else{
            this.burialPermitPreview.fatherStatus = permit.deceased.fatherStatus;
          }
          this.burialPermitPreview.idCardNumber = permit.deceased.idCardNumber; 
          this.burialPermitPreview.massTime = permit.massTime; 
          this.burialPermitPreview.motherName = permit.deceased.motherName; 
          this.burialPermitPreview.motherSurname = permit.deceased.motherSurname; 
          // this.burialPermitPreview.motherStatus = permit.deceased.motherStatus; 
          if(permit.deceased.motherStatus == ''){
            this.burialPermitPreview.motherStatus = 'N/A'
          }else{
            this.burialPermitPreview.motherStatus =  permit.deceased.motherStatus;
          }
          this.burialPermitPreview.name = permit.deceased.name; 
          this.burialPermitPreview.surname = permit.deceased.surname; 
          this.burialPermitPreview.placeOfBirth = permit.deceased.placeOfBirth; 
          this.burialPermitPreview.placeOfDeath = permit.placeOfDeath; 
          this.burialPermitPreview.profession = permit.deceased.profession; 
          this.burialPermitPreview.spouseName = permit.deceased.spouseName ?? ''; 
          this.burialPermitPreview.spouseSurname = permit.deceased.spouseSurname ?? '';
          this.burialPermitPreview.permitNo = permit.permitNumber;
          // this.burialPermitPreview.massTime = permit.massTime;
          this.burialPermitPreview.burialRight =permit.burialRight;
          this.burialPermitPreview.burialTypeId = permit.burialTypeId;
          this.burialPermitPreview.country =permit.country;
          this.burialPermitPreview.burialLocation= permit.burialLocation;
          this.burialPermitPreview.remarks =permit.remarks;
          this.burialPermitPreview.dateCleaned = permit.dateCleaned;
          this.burialPermitPreview.dateTransported = permit.dateTrasnported;
          this.burialPermitPreview.transportedFrom = permit.transportedFrom;
          this.burialPermitPreview.transportedTo = permit.transportedTo;
  
          const selectedLocality = this.localities.find(loc => loc.id === permit.deceased.localityId);    
          if(selectedLocality){
            this.burialPermitPreview.locality = selectedLocality.description
          }
  
          const selectedMassLocality = this.massLocalities.find(loc => loc.id === permit.massLocalityId);    
          if(selectedMassLocality){
            this.burialPermitPreview.massLocality = selectedMassLocality.description
          }
      
          const selectedGender = this.genders.find(g => g.id === permit.deceased.genderId);    
          if(selectedGender){
            this.burialPermitPreview.gender = selectedGender.description
          }
          const selectedUndertaker = this.undertakers.find(undertaker => undertaker.id === permit.undertakerId);    
          if(selectedUndertaker){
            this.burialPermitPreview.undertaker = selectedUndertaker.name +' '+ selectedUndertaker.surname
          }
      
          const selectedMaritalStatus = this.statuses.find(g => g.id === permit.deceased.maritalStatusId);
          if(selectedMaritalStatus){
            this.burialPermitPreview.maritalStatus = selectedMaritalStatus.description
          }
  
          const selectedBurialType = this.burialTypes.find(bur => bur.id === permit.burialTypeId);
          if(selectedBurialType){
            this.burialPermitPreview.burialType = selectedBurialType.description
            this.burialPermitPreview.burialTypeId = selectedBurialType.id
          }

          const selectedDivision = this.divisions.find(div => div.id=== permit.divisionId);
          if(selectedDivision){
            this.burialPermitPreview.divisionName =  selectedDivision.description 
        }
        
        const selectedSection = this.sections.find(sec => sec.id=== permit.sectionId);
          if(selectedSection){
            this.burialPermitPreview.sectionName =  selectedSection.description 
        }
      
        const selectedCompartment = this.compartments.find(comp => comp.id=== permit.compartmentId);
          if(selectedCompartment){
            this.burialPermitPreview.compartmentName =  selectedCompartment.description 
        }
        const dialogRef = this.dialog.open(BurialPermitDetailsInfoComponent, {
            data: this.burialPermitPreview
          });
  
        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');          
          });
        },
        error => {
          console.error('Error:', error);        
        }
      );
    }
    
    
    
    fetchBurialPermits() {
      
      if(this.graveId !== 0 && this.graveId !== null){
        
        return this.burialPermitService!.getBurialPermits(
          this.sort.active,
          this.sort.direction,
          null,
          null,          
          this.paginator.pageIndex + 1,
          this.paginator.pageSize,
          this.graveId,
        ).pipe(catchError(() => observableOf(null)));
      }else{
        
        return this.burialPermitService!.getBurialPermits(
          this.sort.active,
          this.sort.direction,
          null,
          null,
          this.paginator.pageIndex + 1,
          this.paginator.pageSize
          
        ).pipe(catchError(() => observableOf(null)));

      }
      
    }
    refreshTableData() {
      this.isLoadingResults = true;
      this.fetchBurialPermits().subscribe(data => {
        this.isLoadingResults = false;
        if (data === null) {
          return;
        }
       
        this.burialPermits = data.results;
        this.dataSource = new MatTableDataSource(this.burialPermits);        
        this.dataSource.sort = this.sort;
        this.resultsLength = data.totalItems;
      });
    } 
    isSelected(burialPermit: ITableCountDto): boolean {
      return this.selectedBurialPermit === burialPermit;
    } 
    formatDate(date: Date): string {

      return this.datePipe.transform(date, 'dd/MM/yyyy') || ''; 
    }
    expandCollapse(burialPermit: any): void {
      this.expandedElement = this.expandedElement === burialPermit ? null : burialPermit;
    }
    getAllLocalities(){
      this.localityService.getAllLocalities().subscribe(
        (response: ILocalityDto[]) => {
          this.localities = response;  
          this.massLocalities = response;          
        },
        (error) => {        
          console.error('Error adding localities: ', error);        
        }
        );
    }
    getAllGender(){
      this.genderService.getAllGender().subscribe(
        (response: IGenderDto[]) => {
          this.genders = response;        
        },
        (error) => {        
          console.error('Error retrieving genders: ', error);        
        }
        );
    }
   
    getAllMaritalStatus(){
      this.maritalStatusService.getAllMaritalStatus().subscribe(
        (response: IMaritalStatusDto[]) => {
          this.statuses = response;        
        },
        (error) => {        
          console.error('Error retrieving marital status: ', error);        
        }
        );
    }
    getAllUndertakers(){
      this.undertakerService.getAllUndertakers().subscribe(
        (response : IUndertakerDto[]) => {
          this.undertakers = response;
        }, (error) =>{
          console.error('Error retrieving undertakers');
        } 
        );
    }
    
    GetAllBurialTypes(){
      this.burialTypesService.getAllBurialTypes().subscribe(
        (response: IBurialTypeDto[]) => {
          this.burialTypes = response;  
        },
        (error) => {        
          console.error('Error adding localities: ', error);        
        }
        );
    }
    getAllDivisions(){
      this.divisionService.getAllDivisions().subscribe( 
        (response : IDivisionDto[])=>{
          this.divisions = response;         
        }, (error) =>{
          console.error('error retieving divisions service:', error);
        }) 
    }
    getAllSections(){
      this.sectionService.getAllSections().subscribe( 
        (response : ISectionDto[])=>{
          this.sections = response;
        }, (error) =>{
          console.error('error retieving sections service:', error);
        }) 
    }
    getAllCompartments(){
      this.compartmentService.getAllCompartments().subscribe( 
        (response : ICompartmentDto[])=>{
          this.compartments = response;
        }, (error) =>{
          console.error('error retieving compartment service:', error);
        }) 
    }
    
    onDivisionChange(divisionId : number): string {
      const selectedDivision = this.divisions.find(div => div.id=== divisionId);
      return selectedDivision ? selectedDivision.description : '';
    }
    onSectionChange(sectionId : number): string {
      const selectedSection = this.sections.find(sec => sec.id=== sectionId);
      return selectedSection ? selectedSection.description : '';
    }
    onCompartmentChange(compartementId : number): string {
      const selectedCompartment = this.compartments.find(comp => comp.id=== compartementId);
      return selectedCompartment ? selectedCompartment.description : '';
    }
    onMassLocalitySelectionChange(massLocalityId:number) {
      const selectedMassLocality = this.massLocalities.find(loc => loc.id === massLocalityId);    
      return selectedMassLocality  ? selectedMassLocality.description : '';
      
    }
    onUndertakerSelectionChange(undertakerId:number) {
      const selectedUndertaker = this.undertakers.find(undertaker => undertaker.id === undertakerId);    
      return selectedUndertaker  ? selectedUndertaker.name +" "+ selectedUndertaker.surname : '';
    }

    printBurialPermitClick(burialPermit: IBurialPermitDto) {
      let permitId: number = +burialPermit.permitNumber;

      if(burialPermit.graveNumber != 0){
    
        this.transportService.getAllTransport('', '', 1, 100, burialPermit.graveNumber).pipe(

          switchMap((data: any) => {
            if (data.totalItems == 0) {
              return this.burialPermitService.getBurialPermitByPermitNumber(permitId);
            }else{
      
            this.transportDetails = data.results; 
            const transportItem = this.transportDetails?.find(transport => transport?.burialId === burialPermit.burialId);
      
            this.burialPermitPreview.cemeteryNameTo = transportItem?.toCemetery ?? '';
            this.burialPermitPreview.divisionNameTo = transportItem?.toDivision ?? '';
            this.burialPermitPreview.sectionNameTo = transportItem?.toSection ?? '';
            this.burialPermitPreview.compartmentNameTo = transportItem?.toCompartment ?? '';
            this.burialPermitPreview.graveNoTo = transportItem?.toGraveNo ?? '';

            return this.burialPermitService.getBurialPermitByPermitNumber(permitId);
            }
          })
        ).subscribe(
          (permit: IBurialPermitDto) => {

            this.burialPermitPreview.sourceSystemId = this.burialPermits.find(id => id.permitNumber == permitId.toString())?.sourceSystemId

            this.burialPermitPreview.dateOfBirth = new Date(permit.deceased.dateOfBirth);
            this.burialPermitPreview.age = permit.deceased.age;
            this.burialPermitPreview.timeOfDeath = permit.timeOfDeath;
            this.burialPermitPreview.dateOfDeath = new Date(permit.deathDate);
            this.burialPermitPreview.address = permit.deceased.address ?? '';
            this.burialPermitPreview.burialRight = permit.burialRight;
            this.burialPermitPreview.causeOfDeath = permit.causeOfDeath;
            this.burialPermitPreview.levelName = permit.levelName;
            this.burialPermitPreview.levelName = permit.graveLevel;
            this.burialPermitPreview.dateOfBurial = new Date(permit.burialDate);
            this.burialPermitPreview.issuedDate = new Date(permit.issuedDate);
            this.burialPermitPreview.fatherName = permit.deceased.fatherName;
            this.burialPermitPreview.fatherSurname = permit.deceased.fatherSurname;
            this.burialPermitPreview.fatherStatus = permit.deceased.fatherStatus || 'N/A';
            this.burialPermitPreview.idCardNumber = permit.deceased.idCardNumber;
            this.burialPermitPreview.massTime = permit.massTime;
            this.burialPermitPreview.motherName = permit.deceased.motherName;
            this.burialPermitPreview.motherSurname = permit.deceased.motherSurname;
            this.burialPermitPreview.motherStatus = permit.deceased.motherStatus || 'N/A';
            this.burialPermitPreview.name = permit.deceased.name;
            this.burialPermitPreview.surname = permit.deceased.surname;
            this.burialPermitPreview.placeOfBirth = permit.deceased.placeOfBirth;
            this.burialPermitPreview.placeOfDeath = permit.placeOfDeath;
            this.burialPermitPreview.profession = permit.deceased.profession;
            this.burialPermitPreview.spouseName = permit.deceased.spouseName ?? '';
            this.burialPermitPreview.spouseSurname = permit.deceased.spouseName ?? '';
            this.burialPermitPreview.permitNo = permit.permitNumber;
            this.burialPermitPreview.burialLocation =permit.burialLocation;
            this.burialPermitPreview.healthOfficer =permit.healthOfficer;
      
            const selectedLocality = this.localities.find(loc => loc.id === permit.deceased.localityId);
            if (selectedLocality) {
              this.burialPermitPreview.locality = selectedLocality.description;
            }
      
            const selectedMassLocality = this.massLocalities.find(loc => loc.id === permit.massLocalityId);
            if (selectedMassLocality) {
              this.burialPermitPreview.massLocality = selectedMassLocality.description;
            }
      
            const selectedGender = this.genders.find(g => g.id === permit.deceased.genderId);
            if (selectedGender) {
              this.burialPermitPreview.gender = selectedGender.description;
            }
      
            const selectedMaritalStatus = this.statuses.find(g => g.id === permit.deceased.maritalStatusId);
            if (selectedMaritalStatus) {
              this.burialPermitPreview.maritalStatus = selectedMaritalStatus.description;
            }
      
            const selectedBurialType = this.burialTypes.find(bur => bur.id === permit.burialTypeId);
            if (selectedBurialType) {
              this.burialPermitPreview.burialType = selectedBurialType.description;
              if (permit.transportedTo == null) {
                this.burialPermitPreview.burialTypeId = selectedBurialType.id;
                this.burialPermitPreview.cemeteryName = permit.cemeteryName;
                this.burialPermitPreview.graveNo = permit.graveNo;
              } else {
                this.burialPermitPreview.burialTypeId = 5;
              }
            }
      
            const selectedDivision = this.divisions.find(div => div.id === permit.divisionId);
            if (selectedDivision) {
              this.burialPermitPreview.divisionName = selectedDivision.description;
            }
      
            const selectedSection = this.sections.find(sec => sec.id === permit.sectionId);
            if (selectedSection) {
              this.burialPermitPreview.sectionName = selectedSection.description;
            }
      
            const selectedCompartment = this.compartments.find(comp => comp.id === permit.compartmentId);
            if (selectedCompartment) {
              this.burialPermitPreview.compartmentName = selectedCompartment.description;
            }
      
            // Open dialog with burial permit preview
            const dialogRef = this.dialog.open(BurialTemplateComponent, {
              data: this.burialPermitPreview,
            });
      
            dialogRef.afterClosed().subscribe(result => {
              console.log('The dialog was closed');
            });
          },
          error => {
            console.error('Error:', error);
          }
        );

      }else{

        this.burialPermitService.getBurialPermitByPermitNumber(permitId).subscribe(
          (permit: IBurialPermitDto) => {

            this.burialPermitPreview.sourceSystemId = this.burialPermits.find(id => id.permitNumber == permitId.toString())?.sourceSystemId

            this.burialPermitPreview.dateOfBirth = new Date(permit.deceased.dateOfBirth);
            this.burialPermitPreview.age = permit.deceased.age;
            this.burialPermitPreview.timeOfDeath = permit.timeOfDeath;
            this.burialPermitPreview.dateOfDeath = new Date(permit.deathDate);
            this.burialPermitPreview.address = permit.deceased.address ?? '';
            this.burialPermitPreview.burialRight = permit.burialRight;
            this.burialPermitPreview.causeOfDeath = permit.causeOfDeath;
            this.burialPermitPreview.levelName = permit.levelName;
            this.burialPermitPreview.levelName = permit.graveLevel;
            this.burialPermitPreview.dateOfBurial = new Date(permit.burialDate);
            this.burialPermitPreview.issuedDate = new Date(permit.issuedDate);
            this.burialPermitPreview.fatherName = permit.deceased.fatherName;
            this.burialPermitPreview.fatherSurname = permit.deceased.fatherSurname;
            this.burialPermitPreview.fatherStatus = permit.deceased.fatherStatus || 'N/A';
            this.burialPermitPreview.idCardNumber = permit.deceased.idCardNumber;
            this.burialPermitPreview.massTime = permit.massTime;
            this.burialPermitPreview.motherName = permit.deceased.motherName;
            this.burialPermitPreview.motherSurname = permit.deceased.motherSurname;
            this.burialPermitPreview.motherStatus = permit.deceased.motherStatus || 'N/A';
            this.burialPermitPreview.name = permit.deceased.name;
            this.burialPermitPreview.surname = permit.deceased.surname;
            this.burialPermitPreview.placeOfBirth = permit.deceased.placeOfBirth;
            this.burialPermitPreview.placeOfDeath = permit.placeOfDeath;
            this.burialPermitPreview.profession = permit.deceased.profession;
            this.burialPermitPreview.spouseName = permit.deceased.spouseName ?? '';
            this.burialPermitPreview.spouseSurname = permit.deceased.spouseName ?? '';
            this.burialPermitPreview.permitNo = permit.permitNumber;
            this.burialPermitPreview.country =permit.country;
            this.burialPermitPreview.burialLocation =permit.burialLocation;
            this.burialPermitPreview.healthOfficer =permit.healthOfficer;
      
            const selectedLocality = this.localities.find(loc => loc.id === permit.deceased.localityId);
            if (selectedLocality) {
              this.burialPermitPreview.locality = selectedLocality.description;
            }
      
            const selectedMassLocality = this.massLocalities.find(loc => loc.id === permit.massLocalityId);
            if (selectedMassLocality) {
              this.burialPermitPreview.massLocality = selectedMassLocality.description;
            }
      
            const selectedGender = this.genders.find(g => g.id === permit.deceased.genderId);
            if (selectedGender) {
              this.burialPermitPreview.gender = selectedGender.description;
            }
      
            const selectedMaritalStatus = this.statuses.find(g => g.id === permit.deceased.maritalStatusId);
            if (selectedMaritalStatus) {
              this.burialPermitPreview.maritalStatus = selectedMaritalStatus.description;
            }
      
            const selectedBurialType = this.burialTypes.find(bur => bur.id === permit.burialTypeId);
            if (selectedBurialType) {
              this.burialPermitPreview.burialType = selectedBurialType.description;
              if (permit.transportedTo == null) {
                this.burialPermitPreview.burialTypeId = selectedBurialType.id;
                this.burialPermitPreview.cemeteryName = permit.cemeteryName;
                this.burialPermitPreview.graveNo = permit.graveNo;
              } else {
                this.burialPermitPreview.burialTypeId = 5;
              }
            }
      
            const selectedDivision = this.divisions.find(div => div.id === permit.divisionId);
            if (selectedDivision) {
              this.burialPermitPreview.divisionName = selectedDivision.description;
            }
      
            const selectedSection = this.sections.find(sec => sec.id === permit.sectionId);
            if (selectedSection) {
              this.burialPermitPreview.sectionName = selectedSection.description;
            }
      
            const selectedCompartment = this.compartments.find(comp => comp.id === permit.compartmentId);
            if (selectedCompartment) {
              this.burialPermitPreview.compartmentName = selectedCompartment.description;
            }
      
            // Open dialog with burial permit preview
            const dialogRef = this.dialog.open(BurialTemplateComponent, {
              data: this.burialPermitPreview,
            });
      
            dialogRef.afterClosed().subscribe(result => {
              console.log('The dialog was closed');
            });
          },
          error => {
            console.error('Error:', error);
          }
        );

      }
    }
    

    printAcknowledgementReceipt(burialPermit: IBurialPermitDto) {
      let permitId: number = +burialPermit.permitNumber;
  
      this.burialPermitService.getBurialPermitByPermitNumber(permitId).subscribe(
        (permit: IBurialPermitDto) => {
  
          this.burialPermitPreview.dateOfDeath = permit.deathDate;  
          this.burialPermitPreview.idCardNumber = permit.deceased.idCardNumber;    
          this.burialPermitPreview.name = permit.deceased.name; 
          this.burialPermitPreview.surname = permit.deceased.surname; 
          this.burialPermitPreview.permitNo = permit.permitNumber;

          const selectedUndertaker = this.undertakers.find(und => und.id === permit.undertakerId);    
          if(selectedUndertaker){
            this.burialPermitPreview.undertaker = selectedUndertaker.name+` `+selectedUndertaker.surname
          }
  
          const dialogRef = this.dialog.open(BurialAcknowledgeTemplateComponent, {
            data: this.burialPermitPreview
          });
  
          dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');          
          });
          },
          error => {
            console.error('Error:', error);        
          }
      );
    }
    
    isToggleDisabled(burialStatusId: number): boolean {
  
      return burialStatusId === BurialStatus.Confirmed;
    }

    confirmBurialPermitClick(event: MatSlideToggleChange, burialPermit: IBurialPermitDto) {
      const isChecked = event.checked;
      const burialStatusValue = isChecked ? BurialStatus.Confirmed : BurialStatus.UnConfirmed;
      if (burialStatusValue === BurialStatus.Confirmed) {
        this.openConfirmationModal().afterClosed().subscribe(result => {
          if (result) {
            this.proceedWithConfirmation(burialPermit, burialStatusValue);
          } else {          
            event.source.checked = !isChecked;
          }
        });
      } else {
        this.proceedWithConfirmation(burialPermit, burialStatusValue);
      }
    }

    burialStatusUpdate(burialId:any,newStatus:any):void{      
      this.burialService.updateBurialStatus(burialId, newStatus).subscribe(
        () => {        
          this.internalService.emitRefreshPermitTable(); 
          console.log('Burial status updated successfully');   
        },
        (error) => {
          console.error('Error occurred while updating burial status:', error);        
        }
      );
    }

    openConfirmationModal() {
      return this.dialog.open(ConfirmationModalComponent, {
        // width: '500px'
      });
    }
    proceedWithConfirmation(burialPermit: IBurialPermitDto, burialStatusValue: number) {
      let burialId: number = burialPermit.burialId;
      this.burialStatusUpdate(burialId, burialStatusValue);
    }

    searchClick(){
      const searchValue = this.burialDetailsFormGroup.get('searchCtrl')?.value;
  
      this.isLoadingResults = true;
      if(this.graveId === 0){
        this.burialPermitService
        .getBurialPermits("",searchValue, this.dateFrom,this.dateTo, 1, 100,this.graveId)
        .subscribe((response :ITableCountDto) => {
    
          this.burialPermits = response.results;
          this.dataSource = new MatTableDataSource(this.burialPermits); 
          this.isLoadingResults = false;
    
        }, (error) => {
          console.error(error);
              this.isLoadingResults = false;  
        })

      }else{
        this.burialPermitService
      .getBurialPermits("",searchValue, this.dateFrom,this.dateTo, 1,100,this.graveId)
      .subscribe((response :ITableCountDto) => {
  
        this.burialPermits = response.results;
        this.dataSource = new MatTableDataSource(this.burialPermits); 
        this.isLoadingResults = false;
  
      }, (error) => {
        console.error(error);
            this.isLoadingResults = false;  
      })
      }
      
    }

    onOdcCheckboxChange(event: any, burialPermit: IBurialPermitDto) {
      const userRoleId = this.roleService.getUser().role.id; 
    
      if (event.checked) {       
        const dialogRef = this.dialog.open(ConfirmationModalComponent, {
          width: '350px',
          data: {
            title: 'Confirm Action',
            message: 'Are you sure you want to lock the DCR? Once confirmed, this option cannot be unchecked!',
            closeOnly: false 
          },
        });
    
        dialogRef.afterClosed().subscribe((confirmed) => {
          if (confirmed) {
            this.dcrChekbox = true;
            this.burialService.updateBurialODC(burialPermit.burialId, this.dcrChekbox).subscribe(
              (response: IResponseModal) => {
                this.internalService.emitRefreshPermitTable();
              },
              (error) => {
                console.error('DCR locked!');
              }
            );
          } else {
            event.source.checked = false;
          }
        });
      } else {
       
        if (userRoleId === 1) {
          this.dcrChekbox = false;
          this.burialService.updateBurialODC(burialPermit.burialId, this.dcrChekbox).subscribe(
            (response: IResponseModal) => {
              this.internalService.emitRefreshPermitTable();
            },
            (error) => {
              console.error('Error updating DCR.');
            }
          );
        } else {
          // Prevent unchecking for users with roleId other than 1
          event.source.checked = true;
        }
      }
    }
    

    
    openResponseModal(reissue: IResponseModal): void {
      const dialogRef = this.dialog.open(ResponsemodalComponent, {
        width: '250px',
        data: reissue
      });
    
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
    }
    
}
