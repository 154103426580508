import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms'; // Import FormBuilder
import { GraveService } from '../services/grave.service';
import { ITableCountDto } from '../burials/models/ITableCountDto';
import { DeceasedService } from '../services/deceased.service';

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.css']
})
export class SearchbarComponent implements OnInit {
  @Input() methodName!: string;  
 
  @Input() pageSizeSearchBar!: number;
  isLoading = false;

  search : string ='';

  @Output() responseSearch: EventEmitter<any[]> = new EventEmitter(); 
  @Output() resetParentForm: EventEmitter<void> = new EventEmitter<void>();
  searchFormGroup!: FormGroup;

  
  constructor(
    private formBuilder: FormBuilder, 
    private graveService: GraveService,
    private deceasedService : DeceasedService) {}

  ngOnInit(): void {
   
    this.searchFormGroup = this.formBuilder.group({
      searchCtrl: [''],  
    });
    this.suscribesChanges();
  }

  suscribesChanges(){
    this.searchFormGroup.get('searchCtrl')?.valueChanges.subscribe((value) => {     
      this.search= value;
    });
  }

  searchClick() {

    const searchValue = this.searchFormGroup.get('searchCtrl')?.value;
    console.log(this.methodName)

    if (this.methodName === 'GetGraves') {     
      this.getGraves();
      this.triggerReset();
      
    } else {
      this.getAllDeceasedBySearch();
    }
    
  }

  //For Graves grid
  getGraves() {
    this.isLoading = true;
    this.graveService.searchAllGraves(null, this.search, 1, this.pageSizeSearchBar).subscribe(
      (response: ITableCountDto) => {
        console.log(response.results)
        this.responseSearch.emit(response.results);
        
        this.isLoading = false;
      },
      (error) => {
       console.error('something wrong happens:', error)
      }
    );
  }
  // For Deceased grid
  getAllDeceasedBySearch(){
    this.isLoading = true;
    this.deceasedService.getAllDeceased(null,this.search,1,this.pageSizeSearchBar).subscribe(
      (response : ITableCountDto) => {
        this.responseSearch.emit(response.results);
        this.isLoading = false;
  
      }, (error) => {
        console.error('something wrong happens:', error)
  
      })
  }

  resetSearch() {
    this.searchFormGroup.get('searchCtrl')?.reset();
  }

  triggerReset() {
    this.resetParentForm.emit(); 
  }
  
}
