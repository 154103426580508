import { ValidatorFn, AbstractControl } from '@angular/forms';

export const MY_FORMATS = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export function dateRangeValidator(): ValidatorFn {
  return (formGroup: AbstractControl): { [key: string]: any } | null => {
    const startDate = formGroup.get('dateFromCtrl')?.value;
    const endDate = formGroup.get('dateToCtrl')?.value;

    // If either of the dates is null or undefined, don't validate
    if (!startDate || !endDate) {
      return null;
    }

    // Check if the end date is before the start date
    return endDate < startDate ? { 'dateRangeInvalid': true } : null;
  };
}
