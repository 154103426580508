import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuBarComponent } from '../common/menu-bar/menu-bar.component';
import { MaterialModule } from './material.module';
import { Router, RouterModule } from '@angular/router';
import { BurialPermitDetailsComponent } from '../burials/burial-permit-details/burial-permit-details.component';
import { BurialPermitDetailsViewComponent } from '../burials/burial-permit-details/burial-permit-details-view/burial-permit-details-view.component';
import { CleaningComponent } from '../maintenance/cleaning/cleaning.component';
import { TransportComponent } from '../maintenance/transport/transport.component';
import { GraveLevelUpdateComponent } from '../cemetery/grave/grave-level-update/grave-level-update.component';
import { BurialLastburialComponent } from '../burials/burial-lastburial/burial-lastburial.component';
import { FileuploadComponent } from '../fileupload/fileupload.component';
import { SearchbarComponent } from '../searchbar/searchbar.component';
import { RemarksPerCompartmentComponent } from '../remarks-per-compartment/remarks-per-compartment.component';
import { FormsModule } from '@angular/forms';
import { OwnershipComponent } from '../ownership/ownership.component';

@NgModule({
    declarations: [
      MenuBarComponent,
      BurialPermitDetailsComponent,
      BurialPermitDetailsViewComponent,
      CleaningComponent,
      TransportComponent,
      GraveLevelUpdateComponent,
      BurialLastburialComponent,
      SearchbarComponent,
      RemarksPerCompartmentComponent,
      OwnershipComponent,
      FileuploadComponent
      
      ],
    imports: [CommonModule,MaterialModule,RouterModule,FormsModule],
    exports: [
      MenuBarComponent,
      BurialPermitDetailsComponent,
      BurialPermitDetailsViewComponent,
      CleaningComponent,
      TransportComponent,
      GraveLevelUpdateComponent,
      BurialLastburialComponent,
      SearchbarComponent,
      RemarksPerCompartmentComponent,
      OwnershipComponent,
      FileuploadComponent
      ] 
  })
  export class SharedModule { }