import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CleaningComponent } from './cleaning/cleaning.component';
import { DeceasedComponent } from './deceased/deceased.component';
import { ReferenceDataComponent } from './reference-data/reference-data.component';
import { RoleGuard } from '../guards/roles.guard';



const routes: Routes = [
 
  { path: 'maintenance/cleaning', 
    component: CleaningComponent, 
    title: 'Cleaning & Tranport',
    canActivate: [RoleGuard], 
    data: { 
      moduleName: 'CleaningComponent', 
      accessLevel: 'Editor' }
  },
  { path: 'maintenance/deceased', 
    component: DeceasedComponent, 
    title: 'Deceased Maintenance',
    canActivate: [RoleGuard], 
    data: { 
      moduleName: 'DeceasedComponent', 
      accessLevel: 'Editor' } 
  },
  { path: 'maintenance/reference', 
    component: ReferenceDataComponent, 
    title: 'Reference Excel',
    canActivate: [RoleGuard], 
    data: { 
      moduleName: 'ReferenceDataComponent', 
      accessLevel: 'Read' } 
  },
 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MaintenanceRoutingModule {}
