import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IDivisionDto } from 'src/app/models/DivisionDto.model';
import { catchError, map, merge, Observable, of as observableOf, startWith, switchMap} from 'rxjs';
import { DivisionEditComponent } from './division-edit/division-edit.component';
import { DivisionNewComponent } from './division-new/division-new.component';
import { ICemeteryDto } from 'src/app/models/CemeteryDto.model';
import { CemeteryService } from 'src/app/services/cemetery.service';
import { InternalCommunicationService } from 'src/app/services/internal-communication.service';
import { DivisionService } from 'src/app/services/division.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ITableCountDto } from 'src/app/burials/models/ITableCountDto';

@Component({
  selector: 'app-division',
  templateUrl: './division.component.html',
  styleUrls: ['./division.component.css'],
})
export class DivisionComponent implements AfterViewInit, OnInit {
  divisions: IDivisionDto[] = [];
  cemeteries : ICemeteryDto[] = [];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  DivisionDetailsForm !: FormGroup;
  selectedDivision: IDivisionDto | null = null;
  selectedCemetery: ITableCountDto | null = null;
  selectedDivisionDescription !: string;
  divisionmain: IDivisionDto =
  {
      id : 0,
      description: '',
      cemeteryId :0,
      sections:[],
  };

  displayedColumns: string[] = [
      'id',
      'name',
      'action',
   
  ];
  dataSource: MatTableDataSource<IDivisionDto>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  
  
  constructor(public dialog: MatDialog,
    private cemeteryService : CemeteryService,
    private _formBuilder: FormBuilder,    
    private divisionService : DivisionService,
  ) {
    this.dataSource = new MatTableDataSource(this.divisions);
  }

  ngOnInit():void { 
    // remove this comment after setting the getAllDivisions() endpoint 
    // this.divisionService!.getAllDivisions(this.divisionmain.id).subscribe(
    //   (response : IDivisionDto[]) => {
    //     this.divisions = response;
    //     this.dataSource = new MatTableDataSource(this.divisions);
    //   });
    this.cemeteryService.getAllCemeteries().subscribe(
      (response : ICemeteryDto[]) => {
        this.cemeteries = response;
      })
     
    this.DivisionDetailsForm = this._formBuilder.group({
      cemeteryCtrl: [this.divisionmain.cemeteryId],  
    });
    this.subscribeChanges();
  }
  subscribeChanges() {
    this. DivisionDetailsForm.get('cemeteryCtrl')?.valueChanges.subscribe((value) => {     
      this.divisionmain.cemeteryId= value;
    });
  }
  ngAfterViewInit():void {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
    merge(this.sort.sortChange, this.paginator.page)
    .pipe(
      startWith({}),
      switchMap(() => {
        this.isLoadingResults = true;
        return this.fetchDivisions();
      }),
      map(data => {
        this.isLoadingResults = false;
        this.isRateLimitReached = data === null;
        if (data === null) {
          return [];
        }
        this.dataSource = new MatTableDataSource(this.divisions);        
        this.dataSource.sort = this.sort;
        return data;
      }),
    )
    .subscribe();
}
fetchDivisions() {
  return this.divisionService.getDivisionsByCementeryId(this.divisionmain.id).pipe(
    catchError(() => observableOf(null))
  );
}
  onCemeteryChange(){
    const selectedCemetery = this.cemeteries.find(cem => cem.code === this.divisionmain.cemeteryId);
    if(selectedCemetery){ 

      this.divisionService.getDivisionsByCementeryId(selectedCemetery.code).subscribe(
        (response : IDivisionDto[]) => {
          this.divisions = response;
          this.divisionmain.description = selectedCemetery.name;
				  this.dataSource = new MatTableDataSource(this.divisions);																																		
        },
        (error) => {        
          console.error('Error retrieving divisions by cemetery id: ', error);
          this.divisions = [];
          this.dataSource = new MatTableDataSource(this.divisions);
        }
      );
    } else {
      this.divisions = [];
      this.dataSource = new MatTableDataSource(this.divisions);
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.selectedDivision = null;
  }
  editDivisionDialog(divisionmain: IDivisionDto) {
    let divisionName: number = +divisionmain.id;
    // instead of GetDivisionByCemeteryId --> use getDivisionsById(after setting the endpoints)
    this.divisionService.getDivisionsByCementeryId(divisionName).subscribe(
      (divisionObj: IDivisionDto[]) => {
        const dialogRef = this.dialog.open(DivisionEditComponent, {
          data: divisionObj 
        
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');          
        });
      },
      error => {
        console.error('Error:', error);        
      }
    );
  }
  NewDivisionDialog() {
    const dialogRef = this.dialog.open(DivisionNewComponent, {
      restoreFocus: false,
      
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
     
    });
  }
}
