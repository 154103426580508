<div class="body-container">
  <div class="header_container">
    <mat-divider></mat-divider>
    <h1>User Roles</h1>
    <mat-divider></mat-divider>
  </div>
  <div class="container">
    <div class="form">
      <div class="box">
        <app-menu-bar fxShow="true" fxHide.lt-md></app-menu-bar>
      </div>
    </div>
    <div class="form">
      <!-- Roles Table and Paginator -->
      <div class="table-container">
        <button mat-raised-button color="primary" (click)=" openAddRoleDialog()">
          Add New Role
        </button>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <ng-container matColumnDef="roleId">
            <th mat-header-cell *matHeaderCellDef> Role ID </th>
            <td mat-cell *matCellDef="let role"> {{ role.id }} </td>
          </ng-container>
        
          <ng-container matColumnDef="roleName">
            <th mat-header-cell *matHeaderCellDef> Role Name </th>
            <td mat-cell *matCellDef="let role"> {{ role.name }} </td>
          </ng-container>
        
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let role"> {{ role.description }} </td>
          </ng-container>
        
         <!-- <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef> Created At </th>
            <td mat-cell *matCellDef="let role"> {{ role.createdAt | date: 'yyyy-MM-dd HH:mm:ss' }} </td> 
            <td mat-cell *matCellDef="let role"> 
              {{ role.createdAt ? (role.createdAt | date: 'yyyy-MM-dd HH:mm:ss') : 'N/A' }} 
            </td>
 
          </ng-container>-->
        
          <!-- Actions Column -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let role">
              <button mat-button color="primary" (click)="addModuleToRole(role)">
                <mat-icon>link</mat-icon>
              </button>
              <button mat-button color="primary" (click)="openEditDialog(role)">
                <mat-icon>mode</mat-icon> 
              </button>
              <button mat-button color="warn" (click)="deleteRole(role.id)">
                <mat-icon>delete</mat-icon> 
              </button>
            </td>
          </ng-container>
        
          <!-- Header and Row Definitions -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        
        <mat-paginator [length]="resultsLength" [pageSizeOptions]="[25, 100, 200]" aria-label="Select page of users"></mat-paginator>
        
    </div>
  </div>
</div>
