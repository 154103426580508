<div class="body-container  fixed-left">
  <app-menu-bar fxShow="true" fxHide.lt-md></app-menu-bar>
</div>

<div class="body-container">
  <div class="header_container">
    <mat-divider></mat-divider>
    <h1>Deceased Maintenance</h1>
    <mat-divider></mat-divider>
  </div>
<div class="container">
  <!-- <div class="form">
    <div class="box">
      <app-menu-bar fxShow="true" fxHide.lt-md></app-menu-bar>
    </div>
  </div> -->
  
  <div class="form">
    <form >
      <app-searchbar
        [methodName]="methodName"           
        [pageSizeSearchBar]="pageSize"
      (responseSearch)="handleResponseSearch($event)">
    </app-searchbar>
   <!--TABLE FILTER-->
   <div id="searchandfilter_box" class="box">          
    <mat-form-field class="mat-form-field">
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Id Card Number" #input>
    </mat-form-field>
  </div>
<mat-divider></mat-divider>
  <!--TABLE-->
  <div class="box">
    
    <div class="table-table-container" id="tablecontent">
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>
        
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef >ID</th>
            <td mat-cell *matCellDef="let deceasedObj">{{ deceasedObj.idCardNumber }}</td>
          </ng-container>

          <!-- <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef >ID Type</th>
            <td mat-cell *matCellDef="let deceasedObj">{{ getDocumentTypeDescription(deceasedObj.documentTypeId) }}</td>
          </ng-container> -->

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef >Name</th>
            <td mat-cell *matCellDef="let deceasedObj">{{ deceasedObj.name}}</td>
          </ng-container>

          <ng-container matColumnDef="surname">
            <th mat-header-cell *matHeaderCellDef >Surname</th>
            <td mat-cell *matCellDef="let deceasedObj">{{ deceasedObj.surname}}</td>
          </ng-container>

          <ng-container matColumnDef="locality">
            <th mat-header-cell *matHeaderCellDef >Locality</th>
            <td mat-cell *matCellDef="let deceasedObj">{{getLocalityDescription(deceasedObj.localityId)}}</td>
          </ng-container>

        

          <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
            <td mat-cell *matCellDef="let deceasedObj">
              <button mat-icon-button aria-label="expand row"  (click)="expandCollapse(deceasedObj); $event.stopPropagation()" >
                <ng-container *ngIf="expandedElement === deceasedObj">
                    <mat-icon>keyboard_arrow_up</mat-icon>
                </ng-container>
                <ng-container *ngIf="expandedElement !== deceasedObj">
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </ng-container>
            </button>
            </td>
          </ng-container>
          <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let deceasedObj" [attr.colspan]="displayedColumnsWithExpand.length">
              <div class="expand-detail" [@detailExpand] = "deceasedObj == expandedElement ? 'expanded' : 'collapsed' ">
                <div class="expand-category">
                  <div class="expand-field">Date of Birth : &nbsp;<span>{{formatDate(deceasedObj.dateOfBirth)}}</span>
                    Place of Birth : &nbsp;<span>{{deceasedObj.placeOfBirth}}</span>
                    Profession : &nbsp;<span>{{deceasedObj.profession}}</span>
                    Gender :&nbsp;<span>{{ getGenderDescription(deceasedObj.genderId)}}</span></div>
                  <div class="expand-field">Martial Status: &nbsp;<span>{{getMaritalStatusDescription(deceasedObj.maritalStatusId)}}</span>
                    Spouse Name :&nbsp;<span>{{deceasedObj.spouseName}}&nbsp;{{deceasedObj.spouseSurname}}</span></div>
                    <div class="expand-field">Father Name: &nbsp;<span>{{deceasedObj.fatherName}}&nbsp;{{deceasedObj.fatherSurname}}</span>
                      Father Vital Status: &nbsp; <span>{{deceasedObj.fatherStatus}}</span></div>
                      <div class="expand-field">Mother Name: &nbsp;<span>{{deceasedObj.motherName}}&nbsp;{{deceasedObj.motherSurname}}</span>
                        Mother Vital Status: &nbsp; <span>{{deceasedObj. motherStatus}}</span></div>
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef fxShow="true" fxHide.lt-md>Action</th>
            <td mat-cell *matCellDef="let deceasedObj" fxShow="true" fxHide.lt-md>
              <button title="EDIT" mat-icon-button color="primary" (click)="editDeceasedClick(deceasedObj)">
                <mat-icon>edit</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsWithExpand"></tr>
          <tr mat-row *matRowDef="let deceasedObj; columns: displayedColumnsWithExpand;" class="expand-Inside"
            [class.example-expanded-row]="expandedElement != deceasedObj" ></tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" matRipple class="expand-Rowdetails"></tr>        
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="5">
              No data matching the filter "{{input.value}}"
            </td>
          </tr>
        </table>
       
    </div>
   

    </div>
    <mat-paginator [length]="resultsLength" [pageSizeOptions]="[25, 100,200]"  (page)="pageEvent($event)"aria-label="Select page of deceased record"></mat-paginator>
    </form>

</div>
