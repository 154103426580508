import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate, query, stagger } from '@angular/animations';
import { MsalService } from '@azure/msal-angular';

export const fadeInGrow = trigger('fadeInGrow', [
  transition(':enter', [
    query(':enter', [
      style({ opacity: 0 }),
      stagger('50ms', [
        animate('500ms', style({ opacity: 1 }))
      ])
    ])
  ])
]);

export const EnterExitLeft = trigger('enterExitLeft', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateX(-200px)' }),
    animate('300ms ease-in', style({ opacity: 1, transform: 'translateX(0)' }))
  ]),
  transition(':leave', [
    animate('300ms ease-in', style({ opacity: 0, transform: 'translateX(-200px)' }))
  ])
]);

export const EnterExitRight = trigger('enterExitRight', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateX(200px)' }),
    animate('300ms ease-in', style({ opacity: 1, transform: 'translateX(0)' }))
  ]),
  transition(':leave', [
    animate('300ms ease-in', style({ opacity: 0, transform: 'translateX(200px)' }))
  ])
]);

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    fadeInGrow,
    EnterExitLeft,
    EnterExitRight,
    trigger('listAnimation', [
      transition('* => *', [
        query(':enter', [
          style({ opacity: 0, transform: 'translateY(-20px)' }),
          stagger(100, [
            animate('0.5s', style({ opacity: 1, transform: 'translateY(0)' }))
          ])
        ], { optional: true })
      ])
    ])
  ]
})
export class HomeComponent implements OnInit {
  constructor(private msalService: MsalService) {}
  async ngOnInit(): Promise<void> {
    try {      
      const result = await this.msalService.instance.handleRedirectPromise();
      if (result !== null && result.account !== null) {
        this.msalService.instance.setActiveAccount(result.account);
      }
    } catch (error) {
      console.error('Error during MSAL initialization:', error);
    }
  }
  
  isCardDisabled: boolean = false;

  cards :any[] =[
    {
      'name': 'Burial Permit Generation ',
      'imageURL': 'assets/icons/permitgeneration.png',
      'colColor': '#5cadd2',
      'route': '../burial/burial-permit-generation',
      'cardColor': 'white',
    },
    {
      'name': 'Burial Details',
      'imageURL':'assets/icons/permitdetails.png',
      'colColor': '#5cadd2',
      'route': '../burial/burial-permit-details',
      'cardColor': 'white',
    },
    {
      'name':'Burial Permit Pre-save',
      'imageURL':'assets/icons/presave.png',
      'colColor': '#5cadd2',
      'route': '../burial/burial-permit-presave',
      'cardColor': 'white',
    },
  ];

  cemcards: any[]= [
    {
      'name': 'Cemetery Maintenance',
      'imageURL':'assets/icons/cemetery.png',
      'colColor':'#a5a6a7',
      'route':'../cemetery/cemetery-details',
      'cardColor':'white',
    },
    {
      'name': 'Grave Maintenance',
      'imageURL':'assets/icons/grave.png',
      'colColor':'#a5a6a7',
      'route':'../cemetery/grave/gravedetails',
      'cardColor':'white',
    },
    {
      'name': 'Deceased Maintenance',
      'imageURL':'assets/icons/deceased.png',
      'colColor':'#a5a6a7',
      'route':'../maintenance/deceased',
      'cardColor':'white',
    },
    
  ];
  reportcards: any[]= [
   
    {
      'name': 'Undertaker Details',
      'imageURL':'assets/icons/undertaker.png',
      'colColor':'#5cadd2',
      'route':'/undertaker',
      'cardColor':'white',
    },
    {
      'name': 'Reports',
      'imageURL':'assets/icons/report.png',
      'colColor':'#5cadd2',
      'route':'/reporting',
      'cardColor':'white',
    },
    // {
    //   'name': 'Admin ',
    //   'imageURL':'assets/icons/administrator.png',
    //   'colColor':'#5cadd2',
    //   'route':'../admin/userroles',
    //   'cardColor':'white',
    // },
    
  ];
}
