import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ITableCountDto } from 'src/app/burials/models/ITableCountDto';
import { IAddNewCompartmentDto } from 'src/app/models/AddNewCompartmentDto.model';
import { ICemeteryDto } from 'src/app/models/CemeteryDto.model';
import { ICompartmentDto } from 'src/app/models/CompartmentDto.model';
import { IDivisionDto } from 'src/app/models/DivisionDto.model';
import { ILocalityDto } from 'src/app/models/LocalityDto.model';
import { IResponseModal } from 'src/app/models/ResponseModal.model';
import { ISectionDto } from 'src/app/models/SectionDto.model';
import { ResponsemodalComponent } from 'src/app/responsemodal/responsemodal.component';
import { CemeteryService } from 'src/app/services/cemetery.service';
import { CompartmentService } from 'src/app/services/compartment.service';
import { DivisionService } from 'src/app/services/division.service';
import { InternalCommunicationService } from 'src/app/services/internal-communication.service';
import { SectionService } from 'src/app/services/section.service';

@Component({
  selector: 'app-compartment-new',
  templateUrl: './compartment-new.component.html',
  styleUrls: ['./compartment-new.component.css']
})
export class CompartmentNewComponent {
  CompartmentNewForm !:FormGroup;
  divisions: IDivisionDto[] = [];
  cemeteries : ICemeteryDto[] = [];
  sections:ISectionDto[]=[];
  cemetery! : ICemeteryDto;
  divisionmain!: IDivisionDto;
  sectionmain !:ISectionDto;
  compartments:ICompartmentDto[] =[];
  compartmentObj: IAddNewCompartmentDto = {
/*     id:0, */
    description : '',
    cemeteryId : 0,
    divisionId:0,
    sectionId  : 0,   
}
 
 constructor(
  private cemeteryService : CemeteryService,
  private divisionService : DivisionService,
  private sectionService : SectionService,
  private compartmentService : CompartmentService,
  public dialog: MatDialog,
  private _formBuilder: FormBuilder,
  private internalService : InternalCommunicationService, 
   @Inject(MAT_DIALOG_DATA) public data: ICompartmentDto){}

   ngOnInit():void {
    this.cemeteryService.getAllCemeteries().subscribe(
      (response : ICemeteryDto[]) => {
        this.cemeteries = response;
      })
  
    this.CompartmentNewForm = this._formBuilder.group({
/*       cemeteryCtrl: [this.compartmentObj.cemId], 
      divisionCtrl :[this.compartmentObj.divId],  */
      sectionCtrl : [this.compartmentObj.sectionId],  
      compartmentCtrl :[this.compartmentObj.description],
  });
  this.subscribeChanges();
}

subscribeChanges() {
/*   this.CompartmentNewForm.get('cemeteryCtrl')?.valueChanges.subscribe((value) => {     
    this.compartmentObj.cemId= value;
  });
  this.CompartmentNewForm .get('divisionCtrl')?.valueChanges.subscribe((value) => {     
    this.compartmentObj.divId= value;
  }); */
  this.CompartmentNewForm.get('sectionCtrl')?.valueChanges.subscribe((value) => {     
    this.compartmentObj.sectionId= value;
  });
  this.CompartmentNewForm.get('compartmentCtrl')?.valueChanges.subscribe((value) => {     
    this.compartmentObj.description= value;
  });
 
}

/*     getDivisions(){
      this.divisionService.GetDivisionsByCementeryId(this.compartmentObj.cemId).subscribe(
        (response : IDivisionDto[]) => {
          this.divisions = response;
        });
    }
    getSections(){
      this.sectionService.GetSectionByDivisionId(this.compartmentObj.divId).subscribe(
        (response : ISectionDto[]) => {
          this.sections = response;
        });
    } */
    openResponseModal(response : IResponseModal): void {
      const dialogRef = this.dialog.open(ResponsemodalComponent, {
        width: '250px',
        data : response,
      });
    
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
    }
/*    onCemeteryChange(){
    console.log('divisions display');
    const selectedCemetery = this.cemeteries.find(cem => cem.code === this.compartmentObj.cemId);
    if(selectedCemetery){      
      this.divisionService.GetDivisionsByCementeryId(selectedCemetery.code).subscribe(
        (response : IDivisionDto[]) => {
          this.divisions = response;
          
        })
    }
  }
  onDivisionChange() {         
    const selectedDivision = this.divisions.find(div => div.id === this.compartmentObj.divId);
    if (selectedDivision) {
      this.sectionService.GetSectionByDivisionId(selectedDivision.id).subscribe(
        (response : ISectionDto[]) =>{
          this.sections = response;
          
        }
      )          
    } else {
      this.sections = []; 
    }
  } */
  onSectionChange() {         
    const selectedSection = this.sections.find(sec => sec.id === this.compartmentObj.sectionId);
    if (selectedSection) {
      this.compartmentService.GetCompartmentBySectionId(selectedSection.id).subscribe(
        (response : ICompartmentDto[]) => {
          this.compartments = response;
        }
      )
    } else {
      this.compartments = [];
    }
  }
  addNewCompartment(){
    const addNewCompartmentDto: IAddNewCompartmentDto = this.compartmentObj;   
    this.compartmentService.addNewCompartment(addNewCompartmentDto).subscribe(
      (response: IResponseModal) => {       
        this.openResponseModal(response);   
      },
      (error) => {            
        console.error('Error adding new compartment:', error);       
      }
      );
    }
  }