<div class="container">
  <div class="form">
    <form>
      <span class="mat-title" mat-dialog-title><b>List of Grave Owners </b></span>
      <mat-divider></mat-divider>
      <mat-dialog-content>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
         <!-- Grave Id Column -->
          <ng-container matColumnDef="grave_id">
            <th mat-header-cell *matHeaderCellDef>Grave Number</th>
            <td mat-cell *matCellDef="let ownerObject">{{ data.grave_id }}</td>
          </ng-container>

          <!-- Owner ID Card Number Column -->
          <ng-container matColumnDef="owner_id">
            <th mat-header-cell *matHeaderCellDef>ID Card Number</th>
            <td mat-cell *matCellDef="let ownerObject">{{ data.owner_id }}</td>
          </ng-container>

          <!-- Owner Name Column -->
          <ng-container matColumnDef="Gname">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let ownerObject">{{ data.Gname }}</td>
          </ng-container>

           <!-- Owner surname Column -->
          <ng-container matColumnDef="Gsurname">
            <th mat-header-cell *matHeaderCellDef>Surname</th>
            <td mat-cell *matCellDef="let ownerObject">{{ data.Gsurname }}</td>
          </ng-container>

           <!-- Owner Address Column -->
          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef>Reference No</th>
            <td mat-cell *matCellDef="let ownerObject"> {{ data.referenceNo }} </td>
          </ng-container>

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>Main Owner</th>
            <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"></mat-checkbox>
            </td>
        </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
        </table>
        <mat-dialog-actions>
          <div class="buttonnew">
            <button mat-raised-button color="primary" (click)="onUpdateViewOwners()">Update</button>
            <button mat-raised-button style="color: dimgray" [mat-dialog-close] cdkFocusInitial> Close </button>
          </div>
        </mat-dialog-actions>
      </mat-dialog-content>
    </form>
  </div>
</div>
