<div class="container">
  <div class="form">
    <form [formGroup]="CompartmentNewForm">
      <span class="mat-title" mat-dialog-title><b>Add New Compartment</b></span>
      <mat-dialog-content>
        <div class="box">
          <mat-form-field>
            <mat-label>Cemetery</mat-label>
            <mat-select formControlName="cemeteryCtrl">
            <!-- (selectionChange)="onCemeteryChange()"-->
              <mat-option *ngFor="let cemetery of cemeteries" [value]="cemetery.code" >
                {{ cemetery.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Division</mat-label>
            <mat-select formControlName="divisionCtrl">
              <!--(selectionChange)="onDivisionChange()"-->
              <mat-option *ngFor="let division of divisions" [value]="division.id" >
                {{ division.description }}
              </mat-option>
            </mat-select>
          </mat-form-field>      
        </div>

        <div class="box">
          <mat-form-field>
            <mat-label>Section</mat-label>
            <mat-select formControlName="sectionCtrl" (selectionChange)="onSectionChange()">
              <mat-option *ngFor="let section of sections" [value]="section.id">
                {{ section.description }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Compartment</mat-label>
            <input matInput placeholder="Compartment Name" formControlName="compartmentCtrl" required/>
          </mat-form-field>
        </div>
        <mat-dialog-actions>
          <div class="divspace">
            <button mat-raised-button color="primary" (click)=" addNewCompartment()"> Save</button>
            <button mat-raised-button style="color: dimgray" [mat-dialog-close] cdkFocusInitial> Close</button>
          </div>
        </mat-dialog-actions>
      </mat-dialog-content>
    </form>
  </div>
</div>
