import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IStatusDto } from '../models/StatusDto.model';
import { Observable, catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class StatusService {
  
  private baseUrl = `${environment.apiUrl}status/`;
  constructor(private http: HttpClient, private authService: AuthService) { }

  getAllStatus(pageNumber: number = 1, pageSize: number = 100): Observable<IStatusDto[]> {    
    let params = new HttpParams();
    const headers = this.authService.getAuthHeaders();
    
    params = params.append('pageNumber', pageNumber.toString());
    params = params.append('pageSize', pageSize.toString());

    return this.http.get<IStatusDto[]>(this.baseUrl+ 'GetAllStatus', { params,headers })
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };

  
}
