import { Injectable } from '@angular/core';
import { HttpClient,HttpParams, HttpResponse  } from '@angular/common/http';
import { catchError,map,Observable } from 'rxjs';
import { ITableCountDto } from '../burials/models/ITableCountDto';
import { environment } from 'src/environments/environment';
import { IResponseModal } from '../models/ResponseModal.model';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class BurialService {
  private baseUrl = `${environment.apiUrl}burial/`;
  constructor(private http: HttpClient, private authService: AuthService) { }

  updateBurialStatus(burialId: number, burialStatusId: number): Observable<any> {
    const url = `${this.baseUrl}${burialId}/${burialStatusId}`;
    const headers = this.authService.getAuthHeaders();
    return this.http.put(url , null,{headers});
  }
  
  updateBurialODC(burialId: number, ocd: boolean): Observable<any> {
    const url = `${this.baseUrl}UpdateBurialODC?burialId=${burialId}&ocd=${ocd}`;
    const headers = this.authService.getAuthHeaders();
    return this.http.put<HttpResponse<IResponseModal>>(url, null, { observe: 'response',headers }).pipe(
      map(response => {
        
        let modal: IResponseModal = {
          id: 0,
          title: 'Update Burial',
          message: ''
        };
        if (response.status === 204) {  
          
          modal.message = 'Record Updated';

        } else if (response.status === 200) {    
          
          modal.message = 'To update at least one field needs to change, currently not changes have been registered.';

        } else {
          modal.message =`Unexpected status code: ${response.status}`;
          
          throw new Error(`Unexpected status code: ${response.status}`);
        }
        return modal;
      })
    );
  }

  getBurialById(burialId : number ): Observable<ITableCountDto> {
    const headers = this.authService.getAuthHeaders();
    let params = new HttpParams();
    params = params.append('burialId', burialId.toString());
    

    return this.http.get<ITableCountDto>(this.baseUrl + 'GetBurialByBurialId', { params, headers })
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };

}
