
<h1 mat-dialog-title><b>Remarks.</b></h1>

<div mat-dialog-content>
    <div *ngIf="remarksDto">
       
        <textarea rows="5" cols="40" [(ngModel)]="remarksDto.remarks"></textarea>
    </div>
    <div *ngIf="!remarksDto">
        <p>No remarks available for this compartment.</p>
    </div>
</div>

<div mat-dialog-actions>
    <button mat-button (click)="saveRemarks()">Save</button>
    <button mat-button mat-dialog-close>Cancel</button>
</div>
