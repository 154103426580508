import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpParams, HttpResponse  } from '@angular/common/http';
import { catchError,map,Observable } from 'rxjs';
import { ITableCountDto } from '../burials/models/ITableCountDto';
import { IAddBurialPermitDto } from '../burials/models/AddBurialPermitDto.model';
import { IBurialPermitDto } from '../burials/models/BurialPermitDto.model';
import { IBurialPermitUpdateDto } from '../models/BurialPermitUpdateDto.model';
import { IReIssueBurialPermitDto } from '../models/ReIssueBurialPermitDto.model';
import { IResponseModal } from '../models/ResponseModal.model';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';


@Injectable({
  providedIn: 'root'
})
export class BurialPermitService {
private baseUrl = `${environment.apiUrl}burialpermit/`;

constructor(private http: HttpClient, private authService: AuthService) { }

  getBurialPermitByPermitNumber(id:number): Observable<IBurialPermitDto> {   
    const headers = this.authService.getAuthHeaders();
    const url = `${this.baseUrl}${id}`;
    return this.http.get<IBurialPermitDto>(url, { headers })
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };

  getLastBurialPermit(graveId:number): Observable<IBurialPermitDto> {   
    const headers = this.authService.getAuthHeaders();
    const endpoint = 'GetLastBurialPermit'; 
    const url = `${this.baseUrl}${endpoint}?graveId=${graveId}`;    
    
    return this.http.get<IBurialPermitDto>(url, { headers })
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };

  getBurialPermits(filterCriteria: string | null, searchQuery: string | null, dateFrom: Date | null, dateTo: Date | null, pageNumber: number, pageSize: number,graveId : number =0 ): Observable<ITableCountDto> {
    const headers = this.authService.getAuthHeaders();
    let params = new HttpParams();
    if (filterCriteria) {
        params = params.append('filterCriteria', filterCriteria);
    }
    if (searchQuery) {
        params = params.append('searchQuery', searchQuery);
    }
    if (dateFrom) {
        params = params.append('dateFrom', dateFrom.toDateString());
    }
    if (dateTo) {
        params = params.append('dateTo', dateTo.toDateString());
    }
    
    params = params.append('pageNumber', pageNumber.toString());
    params = params.append('pageSize', pageSize.toString());
    params = params.append('graveId', graveId.toString());

    return this.http.get<ITableCountDto>(this.baseUrl + 'GetBurialPermits', { params, headers })
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };


updateBurialPermit(id: number, burialPermitForUpdateDto: IBurialPermitUpdateDto): Observable<IResponseModal> {
    const headers = this.authService.getAuthHeaders();
    const url = `${this.baseUrl}${id}`;

    return this.http.put<HttpResponse<IResponseModal>>(url, burialPermitForUpdateDto, {
       headers,
       observe: 'response' 
      }).pipe(
      map(response => {
        
        let modal: IResponseModal = {
          id: 0,
          title: 'Update Burial Permit',
          message: ''
        };
        if (response.status === 204) {  
          
          modal.message = 'Record Updated';

        } else if (response.status === 200) {    
          
          modal.message = 'To update record at least one field needs to change, currently not changes have been registered.';

        } else {
          modal.message =`Unexpected status code: ${response.status}`;
          
          throw new Error(`Unexpected status code: ${response.status}`);
        }
        return modal;
      })
    );
}

addBurialPermit(addBurialPermitDto: IAddBurialPermitDto): Observable<IResponseModal> {
    const headers = this.authService.getAuthHeaders();
    return this.http.post<IResponseModal>(this.baseUrl + 'AddBurialPermit', addBurialPermitDto,{headers})
    .pipe(
        catchError(error => {
            console.error('Error:', error);
            throw error;
        })
    );
  }

  
getBurialPermitByID(burialpermitId: number) {
    const headers = this.authService.getAuthHeaders();
    return this.http.get<IBurialPermitDto>(this.baseUrl+burialpermitId, {headers})
    .pipe(
        catchError(error => {
            console.error('Error:', error);
            throw error;
        })
    );
  }

  reIssueBurialPermit(id: number, reIssueBurialPermitDto: IReIssueBurialPermitDto): Observable<IResponseModal> {
    const headers = this.authService.getAuthHeaders();
    const endpoint = 'ReIssueBurialPermit'; 
    const url = `${this.baseUrl}${endpoint}?burialPermitNumber=${id}`;    
    return this.http.post<IResponseModal>(url, reIssueBurialPermitDto,{headers}); 
}
getOriginalBurialPermits(pageNumber: number = 1, pageSize: number = 100, reissuedBurialId : number): Observable<IBurialPermitDto[]> {    
    let params = new HttpParams();    
    const headers = this.authService.getAuthHeaders();
    params = params.append('pageNumber', pageNumber.toString());
    params = params.append('pageSize', pageSize.toString());
    params = params.append('reissuedBurialId', reissuedBurialId.toString());

    return this.http.get<IBurialPermitDto[]>(this.baseUrl+ 'GetOriginalBurialPermits', { params, headers })
        .pipe(
            catchError(error => {   
                console.error('Error:', error);
                throw error;
            })
        );
  };

  getBurialPermitByBurialId(burialId : number ): Observable<IBurialPermitDto> {
    const headers = this.authService.getAuthHeaders();
    let params = new HttpParams();
    params = params.append('burialId', burialId.toString());
    

    return this.http.get<IBurialPermitDto>(this.baseUrl + 'GetBurialPermitByBurialId', { params, headers })
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };


}
