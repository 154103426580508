<h1 mat-dialog-title>Edit Role</h1>
<div mat-dialog-content>
  <mat-form-field>
    <input matInput [(ngModel)]="data.roleName" placeholder="Role name">
  </mat-form-field>
  <!-- Add fields for description or other data if needed -->
</div>
<div mat-dialog-actions>
  <button mat-raised-button class="cancel-button" (click)="onNoClick()">Cancel</button>
  <button mat-raised-button color="primary" class="save-button" [mat-dialog-close]="data" cdkFocusInitial (click)="onSaveClick()">Save</button>
</div>
