import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ITableCountDto } from 'src/app/burials/models/ITableCountDto';
import { ICemeteryDto } from 'src/app/models/CemeteryDto.model';
import { IDivisionDto } from 'src/app/models/DivisionDto.model';
import { ISectionDto } from 'src/app/models/SectionDto.model';
import { CemeteryService } from 'src/app/services/cemetery.service';
import { DivisionService } from 'src/app/services/division.service';

import { SectionService } from 'src/app/services/section.service';

@Component({
  selector: 'app-section-edit',
  templateUrl: './section-edit.component.html',
  styleUrls: ['./section-edit.component.css']
})
export class SectionEditComponent  implements OnInit{
  SectionEditForm!: FormGroup;
  divisions: IDivisionDto[] = [];
  cemeteries : ICemeteryDto[] = [];
  sections: ISectionDto[] = [];
 
  sectionObject:ISectionDto ={
    id :0,
    description: '',
    cemeteryId :0  ,
    divisionId :0,
    compartments:[]
}


 constructor(
  private cemeteryService : CemeteryService,
  private divisionService : DivisionService,
  private sectionService : SectionService,
  private _formBuilder: FormBuilder,
   @Inject(MAT_DIALOG_DATA) public data: any
  
  ){
    this.sectionObject = data;
  }
  ngOnInit() {
    
    this.SectionEditForm  = this._formBuilder.group({
      sectionCtrl: [this.sectionObject.id],
      sectionNameCtrl: [this.sectionObject.description],
    });
    this.subscribeChanges();
}
subscribeChanges() {
  this.SectionEditForm.get('sectionCtrl')?.valueChanges.subscribe((value) => {     
    this.sectionObject.id= value;
  });
  this.SectionEditForm .get('sectionNameCtrl')?.valueChanges.subscribe((value) => {     
    this.sectionObject.description= value;
  });
}
 
onSectionUpdate(): void {
    
  const id = +this.sectionObject.id;
  this.sectionService.updateSectionDetails(id, this.sectionObject).subscribe(
    () => {        
      
      console.log('Section details');   
    },
    (error) => {
      console.error('Error occurred while updating section details:', error);        
    }
  )
  }
}