<form [formGroup]="levelUpdateFormGroup">
  <div *ngIf="isLoading" class="spinner-overlay">
    <mat-spinner></mat-spinner>
  </div>
  <div class="table-table-container">

      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="level">
          <th mat-header-cell *matHeaderCellDef> Level </th>
          <td mat-cell *matCellDef="let item"> {{ item.levelDescription }} </td>
        </ng-container>
      
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> Current Status </th>
          <td mat-cell *matCellDef="let item"> {{ item.graveLevelStatusDescription }} </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      </table>
  </div>

  <h3>Update level status:</h3>

  <div class="box">

    <mat-form-field>
      <mat-label>Select the level</mat-label>
      <mat-select formControlName="levelCtrl" (selectionChange)="getLevelStatusId($event.value)">
        <mat-option *ngFor="let option of graveLevels" [value]="option.levelId">
          {{ option.levelDescription }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Level status</mat-label>
      <mat-select formControlName="statusCtrl">
        <mat-option *ngFor="let option of levelDescriptions" [value]="option.value">
          {{ option.description }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    </div>

    <div class="buttonalign">

      <button id="updateBtn" mat-raised-button color="primary" (click)="updateLevelStatus()"><mat-icon>update</mat-icon> Update </button>

    </div>

  </form>
