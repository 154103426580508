import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { MaterialModule } from '../shared/material.module';
import { FormsModule  } from '@angular/forms';
import { NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { SharedModule } from '../shared/menubar.module';
import { DatePipe } from '@angular/common';
import { LayoutModule } from '@angular/cdk/layout';
import { NgxPrintElementModule } from 'ngx-print-element';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AdminRoutingModule } from './admin-routing.modules';
import { UserrolesComponent } from './userroles/userroles.component';
import { EditroleDialogComponent } from'src/app/admin/userroles/editroledialog/editroledialog.component';
import { ViewusersComponent } from './viewusers/viewusers.component';
import { EditusersComponent } from './viewusers/editusers/editusers.component';
import { AddusersComponent } from './viewusers/addusers/addusers.component';
import { ModuleAccessComponent } from './moduleaccess/moduleaccess.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { AddRoleDialogComponent } from './userroles/add-roledialog/add-roledialog.component';
import { LinkmoduleComponent } from './linkmodule/linkmodule.component';
// import { ModuleAccess1Component } from './moduleaccess1/moduleaccess1.component';



@NgModule({
  declarations: [
    UserrolesComponent,
     EditroleDialogComponent,
     ViewusersComponent,
     EditusersComponent,
     AddusersComponent,
     ModuleAccessComponent,
     UnauthorizedComponent,
     AddRoleDialogComponent,
     LinkmoduleComponent,
    //  ModuleAccess1Component ,   
  ],
   
  imports: [
    CommonModule,
    AdminRoutingModule ,    
    FormsModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    SharedModule,   
    LayoutModule,    
    MatSnackBarModule,
    LayoutModule,
    NgxPrintElementModule,    
    MaterialModule,
    MatSnackBarModule,   
    
  ],
  providers: [
    DatePipe,
  ],

})
export class AdminModule { }
