import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IGenderDto } from '../models/Gender.model';
import { Observable, catchError, firstValueFrom, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class GenderService {

  private baseUrl = `${environment.apiUrl}gender/`; 
  
  constructor(private http: HttpClient, private authService: AuthService) { }

  getAllGender(): Observable<IGenderDto[]> {
    const headers = this.authService.getAuthHeaders();
    
    
    return this.http.get<IGenderDto[]>(this.baseUrl + 'GetAllGender', { headers });
  }
  

  getGenderById(id: number): Observable<IGenderDto> {
    const headers = this.authService.getAuthHeaders();   
    
    const url = `${this.baseUrl}${id}`;
    return this.http.get<IGenderDto>(url, { headers });
  }
}
