<div class="body-container  fixed-left">
  <app-menu-bar fxShow="true" fxHide.lt-md></app-menu-bar>
</div>

<div class="body-container">
  <div class="header_container">
    <mat-divider></mat-divider>
    <h1>Cemetery Maintenance</h1>
    <mat-divider></mat-divider>
  </div>
<div class="container">
  <div class="form">
    <form>
      <div class="box">
        <mat-form-field class="mat-form-field">
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Paola" #input/>
        </mat-form-field>
      </div>
      <mat-divider></mat-divider>
      <!--TABLE-->
      <div class="box" >
        <div class="table-container">
          <div *ngIf="isLoadingResults || isRateLimitReached" class="table-loading-shade">
            <div *ngIf="isLoadingResults">
              <mat-spinner></mat-spinner>
            </div>
            <div *ngIf="isRateLimitReached" class="table-rate-limit-reached">
              Loading...
            </div>
          </div>
        </div>
        <div class="table-table-container mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>
          <!-- code Column -->
          <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef>Code</th>
            <td mat-cell *matCellDef="let cemetery"> {{ cemetery.code }} </td>
          </ng-container>

          <!-- Cemetery Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Cemetery</th>
            <td mat-cell *matCellDef="let cemetery"> {{ cemetery.name }}</td>
          </ng-container>
          
          <!-- Cemetery Type Column -->
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef fxShow="true" fxHide.lt-md>Cemetery Type</th>
            <td mat-cell *matCellDef="let cemetery" fxShow="true" fxHide.lt-md> {{ getCemTypeDescription(cemetery.cemeteryTypeId) }} </td>
          </ng-container>

          <!-- cemetery locality Column -->
          <ng-container matColumnDef="locality">
            <th mat-header-cell *matHeaderCellDef>Locality</th>
            <td mat-cell *matCellDef="let cemetery"> {{ getLocalityDescription(cemetery.localityId) }}</td>
          </ng-container>

          <!-- Contact person Column -->
          <ng-container matColumnDef="contact_person">
            <th mat-header-cell *matHeaderCellDef fxShow="true" fxHide.lt-md>Contact Person</th>
            <td mat-cell *matCellDef="let cemetery" fxShow="true" fxHide.lt-md>{{ cemetery.contactPerson }}</td>
          </ng-container>

          <!-- telephone Column -->
          <ng-container matColumnDef="telephone">
            <th mat-header-cell *matHeaderCellDef fxShow="true" fxHide.lt-md> Telephone </th>
            <td mat-cell *matCellDef="let cemetery" fxShow="true" fxHide.lt-md>{{ cemetery.telephone }}</td>
          </ng-container>

          <!-- Action Column -->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let cemetery">
              <button title="CEMETERY DETAILS" mat-icon-button color="primary" (click)="cemeteryView(cemetery)">
                <mat-icon>loupe</mat-icon>
              </button>
              <button title="EDIT" class="editbutton" (click)= "editCemeteryDialog(cemetery)" mat-icon-button  color="primary">
              <mat-icon>mode</mat-icon>
              </button>
              <button title="DELETE" mat-icon-button color="warn" (click)="deleteCemetery(cemetery.code)"><mat-icon>delete</mat-icon></button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>  
      </div>
      <div>
    </div>    
      </div>
    
    <mat-paginator [length]="resultsLength" [pageSizeOptions]="[10, 25, 50]" aria-label="Select page of cemeteries"></mat-paginator>

    <div class="buttonnew">
      <button mat-raised-button (click)="NewCemeteryDialog()" color="primary"><mat-icon>add</mat-icon>New</button>
    </div>
    </form>
  </div>
</div>
</div>