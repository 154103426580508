import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-csv-export',
  templateUrl: './csv-export.component.html',
  styleUrls: ['./csv-export.component.css']
})
export class CsvExportComponent {
  @Input() columns: string[] = [];
  @Input() data: any[] = [];

  constructor() { }

  exportCsv() {
    if (!this.columns.length || !this.data.length) {
      console.error('No columns or data provided');
      return;
    }

    console.log('Columns:', this.columns);
    console.log('Data:', this.data);

    const separator = ',';
    const keys = this.columns;
    const csvContent = keys.join(separator) + '\n' +
      this.data.map(row => {
        return keys.map(k => {
          let cell = row[k] === null || row[k] === undefined ? '' : row[k];
          cell = cell instanceof Date ? cell.toLocaleString() : cell.toString().replace(/"/g, '""');
          if (cell.search(/("|,|\n)/g) >= 0) cell = `"${cell}"`;
          return cell;
        }).join(separator);
      }).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'data.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
