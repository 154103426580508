import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ICemetery } from 'src/app/models/Cemetery.model';
import { catchError, map, merge, Observable, of as observableOf, startWith, switchMap} from 'rxjs';
import { CemeteryDetailsNewComponent } from './cemetery-details-new/cemetery-details-new.component';
import { CemeteryDetailsEditComponent } from './cemetery-details-edit/cemetery-details-edit.component';
import { CemeteryService } from 'src/app/services/cemetery.service';
import { ICemeteryDto } from 'src/app/models/CemeteryDto.model';
import { IDivisionDto } from 'src/app/models/DivisionDto.model';
import { LocalityService } from 'src/app/services/locality.service';
import { ILocalityDto } from 'src/app/models/LocalityDto.model';
import { InternalCommunicationService } from 'src/app/services/internal-communication.service';
import { ITableCountDto } from 'src/app/burials/models/ITableCountDto';
import { CemeteryDetailsViewComponent } from './cemetery-details-view/cemetery-details-view.component';
import { IResponseModal } from 'src/app/models/ResponseModal.model';
import { ResponsemodalComponent } from 'src/app/responsemodal/responsemodal.component';
import { ICemeteryTypeDto } from 'src/app/models/CemeteryTypeDto.model';

@Component({
  selector: 'app-cemetery-details',
  templateUrl: './cemetery-details.component.html',
  styleUrls: ['./cemetery-details.component.css']
})
export class CemeteryDetailsComponent implements AfterViewInit, OnInit {

  cemeteryData! : ICemeteryDto[];
  cemeteries : ICemeteryDto[] = [];
  divisions: IDivisionDto[] = [];
  localities : ILocalityDto[] = [];
  cemeteryTypes: ICemeteryTypeDto[] = [];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  cemTypeDesc: string = '';
  localityDesc: string = '';

  cemetery : ICemeteryDto = 
    {
      code: 0,
      name: '',
      address: '',
      localityId: 0,
      cemeteryTypeId : 0,
      contactPerson: '',
      telephone: '',
      telephone2: '',
    };
  
  displayedColumns: string[] = [
    'code',
    'name',
    'type',
    'locality',
    'contact_person',
    'telephone',
    'action',
  ];
  dataSource : MatTableDataSource<ICemeteryDto>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  selectedCemetery: ITableCountDto | null = null;
 

  constructor(public dialog: MatDialog,
    private cemeteryService : CemeteryService,
    private localityService : LocalityService,
    private internalService : InternalCommunicationService, 
  ) {
    this.dataSource = new MatTableDataSource(this.cemeteries);
  }
  ngOnInit() { 
    this.cemeteryService.getAllCemeteries().subscribe(
      (response : ICemeteryDto[]) => {
        this.cemeteries = response;
        this.resultsLength = this.cemeteries.length
        this.dataSource = new MatTableDataSource(this.cemeteries);
      })
    
    this.getAllLocalities();
    this.getAllCemeteryType();

    this.internalService.cemeteryUpdated$.subscribe(() => {
      this.refreshTableData();
    });
  }
  ngAfterViewInit():void {
      this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
      merge(this.sort.sortChange, this.paginator.page)
      merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.fetchCemetery();
        }),
        map(data => {
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;
          if (data === null) {
            return [];
          }
          this.dataSource = new MatTableDataSource(this.cemeteries);        
          this.dataSource.sort = this.sort;
          return data;
        }),
      )
      .subscribe();
  }

  refreshTableData() {
    this.fetchCemetery().subscribe(data => {
      if (data === null) {
        return;
      }
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.sort = this.sort;
    });
  } 
  fetchCemetery() {
    return this.cemeteryService!.getAllCemeteries(
      this.paginator.pageIndex + 1,
      this.paginator.pageSize
    ).pipe(catchError(() => observableOf(null)));
  }
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

    this.selectedCemetery = null;
  }

  editCemeteryDialog(cemetery: ICemeteryDto) {
    let cemeteryCode: number = +cemetery.code;
    this.cemeteryService.getCemeteryById(cemeteryCode).subscribe(
      (cemeteryObj: ICemeteryDto) => {
        const dialogRef = this.dialog.open(CemeteryDetailsEditComponent, {
          data: cemeteryObj, 
          
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');          
        });
      },
      error => {
        console.error('Error:', error);        
      }
    );
  }
  NewCemeteryDialog() {
    const dialogRef = this.dialog.open(CemeteryDetailsNewComponent, {
      restoreFocus: true,      
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.internalService.emitRefreshCemeteryTable();
      console.log('The dialog was closed');     
    });
  }
  cemeteryView(cemetery : ICemeteryDto){
    const dialogRef = this.dialog.open(CemeteryDetailsViewComponent, {  
      data : cemetery,       
    
    });
    dialogRef.afterClosed().subscribe(result => {       
      console.log('The dialog was closed');
     
    });

  }
  deleteCemetery(id: number){       
    this.cemeteryService.deleteCemetery(id).subscribe(
      (response : IResponseModal) => {
        this.openResponseModal(response);
        this.internalService.emitRefreshCemeteryTable();
  
      }, (error) =>{
        console.error('Error deleting Cemetery: ', error);
      })
  } 
  openResponseModal(response : IResponseModal): void {
    const dialogRef = this.dialog.open(ResponsemodalComponent, {
      width: '250px',
      data : response,
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  getAllLocalities(){ 
    this.localityService.getAllLocalities().subscribe(data => {
      this.localities = data;
    });
  }
  getAllCemeteryType(){ 
    this.cemeteryService.getAllCemeteryType().subscribe(data => {
      this.cemeteryTypes = data;
    });
  }
  getLocalityDescription(localityId: number): string {
    const locality = this.localities.find(loc => loc.id === localityId);
    return locality ? locality.description : '';
  }
  getCemTypeDescription(cemeteryTypeId: number): string {
    const cemType = this.cemeteryTypes.find(type => type.id === cemeteryTypeId);
    return cemType ? cemType.description : '';
  }
}
