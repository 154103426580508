<div class="dialog-header">
    <button mat-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
</div>

<div id="mainContainer">

    <mat-spinner [diameter]="100" *ngIf="isLoading"></mat-spinner>

    <br />

    <iframe id='pdfPreview'></iframe>

    <div id="contentContainer">

        <div #contentToPrint id="contentToPrint">
            
            <div class="form_header2">
                <p><b>To Deparment of Health Information & Research<br/>95, G'Mangia Hill<br/>G'Mangia</b></p>
                <p><b>{{getCurrentDate()}}</b></p>  
                <p>Dear ____________________</p>
                <p>Kindly acknowledge receipt of original death certificates as per list below from the Burials Regulatory Unit at Addolarata Cemetery</p>
            </div> 

            <div class="form_body">
                <table id="data_table">
                    <thead>
                      <tr>
                        <th>Burial Permit No</th>
                        <th>Name</th>
                        <th>Surname</th>
                        <th>Id/Passport No</th>
                        <th>Sex</th>
                        <th>Date Of Death</th>
                        <th>Time Of Death</th>
                        <th>Place Of Death</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let burial of burialPermitPreview">
                        <td>{{ burial.permitNo }}</td>
                        <td>{{ burial.name }}</td>
                        <td>{{ burial.surname }}</td>
                        <td>{{ burial.idCardNumber }}</td>
                        <td>{{ burial.gender }}</td>
                        <td>{{ formatDate(burial.dateOfDeath) }}</td>
                        <td>{{ burial.timeOfDeath }}</td>
                        <td>{{ burial.placeOfDeath }}</td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
            </div> 

            <div class="form_footer">
                <p>BURIALS REGULATORY UNIT</p>
                <p>Ċimiterju Sta. Marija Addolorata, Paola PLA9010</p>
                <p>t + 356 21675936   e burials.ph@gov.mt</p>
            </div>            
        </div>
        
    </div>   

</div>