import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IRemarksPerCompartmentDto } from '../models/RemarksPerCompartmentDto.model';

@Injectable({
  providedIn: 'root'
})
export class InternalCommunicationService {
  private permitUpdatedSource = new Subject<void>(); 
  permitUpdated$ = this.permitUpdatedSource.asObservable();

  private graveUpdatedSource = new Subject<void>();
  graveUpdated$ = this.graveUpdatedSource.asObservable();

  private ownerUpdatedSource = new Subject<void>();
  ownerUpdated$ = this.ownerUpdatedSource.asObservable();

  private cemeteryUpdatedSource = new Subject<void>();
  cemeteryUpdated$ = this.cemeteryUpdatedSource.asObservable();

  private undertakerUpdatedSource = new Subject<void>();
  undertakerUpdated$ = this.undertakerUpdatedSource.asObservable();

  private divisionUpdatedSource = new Subject<void>();;
  divisionUpdated$ = this.divisionUpdatedSource.asObservable();

  private sectionUpdatedSource = new Subject<void>();;
  sectionUpdatedSource$ = this.sectionUpdatedSource.asObservable();

  private compartmentUpdatedSource = new Subject<void>();;
  compartmentUpdatedSource$ = this.compartmentUpdatedSource.asObservable();

  private preSaveUpdatedSource = new Subject<void>();
  preSaveUpdated$ = this.preSaveUpdatedSource.asObservable();

  private deceasedUpdatedSource = new Subject<void>();
  deceasedUpdated$ = this.deceasedUpdatedSource.asObservable();

  private cleaningUpdatedSource = new Subject<void>();
  cleaningUpdated$ = this.cleaningUpdatedSource.asObservable();

  private transportUpdatedSource = new Subject<void>();
  transportUpdated$ = this.transportUpdatedSource.asObservable();

  private levelStatusUpdatedSource = new Subject<void>();
  levelStatusUpdated$ = this.levelStatusUpdatedSource.asObservable();

  private filesUpdatedSource = new Subject<void>(); 
  filesUpdated$ = this.filesUpdatedSource.asObservable();
  
  private remarksUpdatedSource = new Subject<IRemarksPerCompartmentDto>();
  remarksUpdated$ = this.remarksUpdatedSource.asObservable();

  

  constructor() { }
  emitRefreshPermitTable(): void {
    this.permitUpdatedSource.next();
  }

  emitRefreshGraveTable(): void {
    this.graveUpdatedSource.next();
  }

  emitRefreshOwnerTable(): void {
    this.ownerUpdatedSource.next();
  }

  emitRefreshCemeteryTable(): void {
    this.cemeteryUpdatedSource.next();
  }
  emitRefreshDivisionTable(): void {
    this.divisionUpdatedSource.next();
  }
  emitRefreshSectionTable(): void {
    this.sectionUpdatedSource.next();
  }
  emitRefreshCompartmentTable(): void {
    this.compartmentUpdatedSource.next();
  }
  emitRefreshPreSaveTable(): void {
    this.preSaveUpdatedSource.next();
  }
  emitRefreshDeceasedTable(): void {
    this.deceasedUpdatedSource.next();
  }
  emitRefreshUndertakerTable(): void {
    this.undertakerUpdatedSource.next();
  }
  emitRefreshCleaningTable(): void {
    this.cleaningUpdatedSource.next();
  }
  emitRefreshTransportTable(): void {
    this.transportUpdatedSource.next();
  }
  emitRefreshLevelStatusTable(): void {
    this.levelStatusUpdatedSource.next();
  }

  emitRefreshFileTable(): void {
    this.filesUpdatedSource.next();
  }

  emitRemarksUpdate(remarks: IRemarksPerCompartmentDto): void {
    this.remarksUpdatedSource.next(remarks);
  }

 
}
