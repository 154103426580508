<div class="dialog-header">
  <button mat-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="container-popup">
  <div class="form" >  
    <span class="mat-title" mat-dialog-title><b>Edit Deceased Details</b> </span>
    <form [formGroup]="DeceasedForm">

<mat-dialog-content>
<div class="box">
  <mat-form-field>
    <mat-label>ID Card Type</mat-label>    
    <mat-select formControlName="documentTypeCtrl"  >
      <mat-option *ngFor="let type of documentTypes" [value]="type.id">
        {{ type.description }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
      <mat-label>ID Card Number</mat-label>    
      <input matInput placeholder="Id Card Number" formControlName="deceasedIdCardNumberCtrl" required/>
    </mat-form-field>
  </div>
  <div class="box">
  <mat-form-field>
    <mat-label>Deceased Name </mat-label>
    <input matInput placeholder="First Name" formControlName="nameCtrl" />
  </mat-form-field>
  <mat-form-field>
      <mat-label>Deceased Surname </mat-label>
    <input matInput placeholder="Surname" formControlName="surnameCtrl" />
    </mat-form-field>
  </div>
  <div class="box">
    <mat-form-field>
      <mat-label>Address </mat-label>
      <input matInput placeholder="Address" formControlName="addressCtrl"/>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Locality</mat-label>
      <mat-select formControlName="localityCtrl" >
        <mat-option *ngFor="let locality of localities" [value]="locality.id">
          {{ locality.description }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="box">
    <mat-form-field>
      <mat-label>Gender</mat-label>
      <mat-select formControlName="genderCtrl" >                
        <mat-option *ngFor="let gender of genders" [value]="gender.id">
          {{ gender.description }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Date of Birth </mat-label>
      <input matInput [matDatepicker]="pickerDateOfBirth" formControlName="dateOfBirthCtrl" />
      <mat-datepicker-toggle matIconSuffix [for]="pickerDateOfBirth">
      </mat-datepicker-toggle>
      <mat-datepicker #pickerDateOfBirth></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Place of Birth </mat-label>
      <input matInput placeholder="Place of Birth" formControlName="placeOfBirthCtrl"/>
    </mat-form-field>
  </div>
  <div class="box">
    <mat-form-field>
      <mat-label>Marital Status</mat-label>
      <mat-select formControlName="maritalStatusCtrl" >
        <mat-option *ngFor="let status of statuses" [value]="status.id">
          {{ status.description }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Profession </mat-label>
      <input matInput placeholder="Profession" formControlName="professionCtrl"/>
    </mat-form-field>
  </div>
  <div class="box">
    <mat-form-field>
      <mat-label>Spouse Name </mat-label>
      <input matInput placeholder="Spouse Name" formControlName="spouseNameCtrl" />
    </mat-form-field>
    <mat-form-field>
        <mat-label>Spouse Surname </mat-label>
      <input matInput placeholder="Surname" formControlName="spouseSurnameCtrl" />
      </mat-form-field>
    </div>
    <div class="box">
      <mat-form-field>
        <mat-label>Father Name </mat-label>
        <input matInput placeholder="Father Name" formControlName="fatherNameCtrl" />
      </mat-form-field>
      <mat-form-field>
          <mat-label>Father Surname </mat-label>
        <input matInput placeholder=" Father Surname" formControlName="fatherSurnameCtrl" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Vital Status</mat-label>
          <mat-select formControlName="motherStatusCtrl">
            <mat-option value="1">Alive</mat-option>
            <mat-option value="2">Deceased</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="box">
        <mat-form-field>
          <mat-label>Mother Name </mat-label>
          <input matInput placeholder="Mother Name" formControlName="motherNameCtrl" />
        </mat-form-field>
        <mat-form-field>
            <mat-label>Father Surname </mat-label>
          <input matInput placeholder="Mother Surname" formControlName="motherSurnameCtrl" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Vital Status</mat-label>
            <mat-select formControlName="fatherStatusCtrl" >   
              <mat-option value="1">Alive</mat-option>
              <mat-option value="2">Deceased</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
<div class="buttonalign">
    <button mat-raised-button color="primary" (click)="updateDeceasedDetails()"><mat-icon>update</mat-icon>Update</button>
    <button mat-raised-button style="background-color: rgb(143, 141, 141)" [mat-dialog-close] cdkFocusInitial><mat-icon>close</mat-icon>Close</button>
</div>
</mat-dialog-content>
</form>
  </div>
</div>


