<div class="dialog-header">
  <button mat-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="container-popup">
    <div class="form">
      <span class="mat-title" mat-dialog-title><b>Edit Undertaker Details</b> </span>
      <form [formGroup]="UndertakerEditForm">
       
        <mat-dialog-content>
          <div class="box">
            <mat-form-field>
              <mat-label>Code</mat-label>
              <input matInput placeholder="Code" formControlName="codeCtrl"  />
              <mat-error>
                <span *ngIf="UndertakerEditForm.get('codeCtrl')?.value && UndertakerEditForm.get('codeCtrl')?.invalid && UndertakerEditForm.get('codeCtrl')?.errors?.['pattern']">Code must contain only numbers </span>
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>VAT No</mat-label>
              <input matInput placeholder="Vat Number"  formControlName="vatNoCtrl" />
              <mat-error>
                <span *ngIf="UndertakerEditForm.get('vatNoCtrl')?.value && UndertakerEditForm.get('vatNoCtrl')?.invalid && UndertakerEditForm.get('vatNoCtrl')?.errors?.['pattern']">VAT No must contain only numbers </span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="box">
            <mat-form-field>
              <mat-label>Name </mat-label>
              <input matInput placeholder="First Name"  formControlName="nameCtrl" />
              <mat-error>
                <span *ngIf="UndertakerEditForm.get('nameCtrl')?.value && UndertakerEditForm.get('nameCtrl')?.invalid && UndertakerEditForm.get('nameCtrl')?.errors?.['pattern']">Name must contain only letters</span>
                <span *ngIf="!UndertakerEditForm.get('nameCtrl')?.value">Field required</span>
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Surname </mat-label>
              <input matInput placeholder="Surname" formControlName="surnameCtrl" />
              <mat-error>
                <span *ngIf="UndertakerEditForm.get('surnameCtrl')?.value && UndertakerEditForm.get('surnameCtrl')?.invalid && UndertakerEditForm.get('surnameCtrl')?.errors?.['pattern']">Surname must contain only letters</span>
                <span *ngIf="!UndertakerEditForm.get('surnameCtrl')?.value">Field required</span>
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Locality</mat-label>
              <mat-select formControlName="localityCtrl" >
                <mat-option *ngFor="let locality of localities" [value]="locality.id">
                  {{ locality.description }}
                </mat-option>
              </mat-select>
              <mat-error>
                <span *ngIf="!UndertakerEditForm.get('localityCtrl')?.value">Field required</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="box">
            <mat-form-field>
              <mat-label>Address 1</mat-label>
              <input matInput placeholder="Address1" formControlName="address1Ctrl" />
              <mat-error>
                <span *ngIf="UndertakerEditForm.get('address1Ctrl')?.value && UndertakerEditForm.get('address1Ctrl')?.invalid && UndertakerEditForm.get('address1Ctrl')?.errors?.['pattern']">Address must contain only numbers or letters</span>
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Address 2</mat-label>
              <input matInput placeholder="Address2" formControlName="address2Ctrl" />
              <mat-error>
                <span *ngIf="UndertakerEditForm.get('address2Ctrl')?.value && UndertakerEditForm.get('address2Ctrl')?.invalid && UndertakerEditForm.get('address2Ctrl')?.errors?.['pattern']">Address must contain only numbers or letters</span>
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Telephone</mat-label>
              <input matInput  placeholder="Telephone"  formControlName="telephoneCtrl" />
              <mat-error>
                <span *ngIf="UndertakerEditForm.get('telephoneCtrl')?.value && UndertakerEditForm.get('telephoneCtrl')?.invalid && UndertakerEditForm.get('telephoneCtrl')?.errors?.['pattern']">Telephone must contain only numbers </span>
              </mat-error>
            </mat-form-field>
          </div>
            <div class="buttonalign">
              <button mat-raised-button color="primary" (click)="onUndertakerUpdate()"><mat-icon>update</mat-icon>Update</button>
              <button mat-raised-button style="background-color: rgb(143, 141, 141)"  [mat-dialog-close] cdkFocusInitial><mat-icon>close</mat-icon> Close </button>
            </div>
        </mat-dialog-content>
      </form>
    </div>
  </div>
  
