import { Component, ViewChild, ElementRef, Input, AfterViewInit, Renderer2, Inject, Optional, ChangeDetectorRef, NgZone } from '@angular/core';
import { jsPDF } from 'jspdf';
import { DatePipe } from '@angular/common';
import { LocalStorageService } from 'src/app/local-storage.service';
import { IPreViewBurialPermit } from 'src/app/models/PreViewBurialPermit.model';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-burial-acknowledge-template',
  templateUrl: './burial-acknowledge-template.component.html',
  styleUrls: ['./burial-acknowledge-template.component.css']
})

export class BurialAcknowledgeTemplateComponent {

  @Input() burialPermitPreview!: IPreViewBurialPermit; 
  @ViewChild('contentToPrint',{static:false}) el!: ElementRef
  title = 'angular-pdf-print';
  isLoading: boolean = false;
  
  constructor (private datePipe: DatePipe,
              private localStorageService: LocalStorageService,
              private renderer: Renderer2,
              private cdr: ChangeDetectorRef,
              private ngZone: NgZone, // Inject NgZone
              @Optional() @Inject(MAT_DIALOG_DATA) public data: any)
              {
                if (data) {
                  this.burialPermitPreview = data;
                }
              }

  ngAfterViewInit() {
    this.ngZone.run(() => {
      this.isLoading = true;
      this.cdr.detectChanges(); // Trigger change detection manually

      setTimeout(() => {
        this.makePDF().then(() => {
          this.isLoading = false;
          this.cdr.detectChanges(); // Trigger change detection manually
        });
      });
    });
  }

  makePDF(): Promise<void> {
    return new Promise((resolve, reject) => {
      let pdf = new jsPDF({
        orientation: 'p', // or 'landscape'
        unit: 'pt',
        format: 'a4' // or specify custom dimensions { width: number, height: number }
      });

      pdf.html(this.el.nativeElement, {
        callback: (pdf) => {        
          const pdfDataUri = pdf.output('datauristring');
          this.fillIframe(pdfDataUri);
          resolve(); // Resolve the promise when done
        },
        x: 0,
        y: 0
      });
    });
  }

  fillIframe(dataUri: string) {
    const iframe = document.getElementById('pdfPreview2') as HTMLIFrameElement;
    if (iframe) {
      iframe.src = dataUri;
    }
  }

  formatDate(date: Date): string {
    return this.datePipe.transform(date, 'dd/MM/yyyy') || ''; 
  }

  calculateAge(dateOfBirth: Date): number {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();    
    
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    
    return age;
  }

  storeObject(key: string, object: any) {
    const objectToStore = object;
    this.localStorageService.setObject(key, objectToStore);
  }

  getCurrentDate(): string {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = currentDate.getFullYear();

    return `${day}/${month}/${year}`;
  }

  formattedCurrentDate = this.getCurrentDate();

}