import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { UndertakerNewComponent } from './undertaker-new/undertaker-new.component';
import { UndertakerEditComponent } from './undertaker-edit/undertaker-edit.component';
import { IUndertakerDto } from '../models/UndertakerDto.model';
import { LocalityService } from '../services/locality.service';
import { ILocalityDto } from '../models/LocalityDto.model';
import { UndertakerService } from 'src/app/services/undertaker.service';
import { catchError, map, merge, of as observableOf, startWith, switchMap} from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ITableCountDto } from '../burials/models/ITableCountDto';
import { IResponseModal } from '../models/ResponseModal.model';
import { ResponsemodalComponent } from '../responsemodal/responsemodal.component';
import { InternalCommunicationService } from '../services/internal-communication.service';

@Component({
  selector: 'app-undertaker',
  templateUrl: './undertaker.component.html',
  styleUrls: ['./undertaker.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class UndertakerComponent implements AfterViewInit, OnInit {
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  undertakers :IUndertakerDto[] = [];
  localities :ILocalityDto[]= [];

  undertakerObj :IUndertakerDto ={
  id: 0,
  code : 0,
  name : '',
  surname : '',
  telephone :'',
  address1: '',
  address2  :'',
  localityId :0,
  vatNo : '',
  }

  displayedColumns: string[] = [     
    // 'id',
    'code',
    'vatNo',
    'name',
    'surname',
    'locality',
    'telephone',
    'expand',
    'action',
  ];
  
  dataSource !: MatTableDataSource<IUndertakerDto>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  selectedUndertaker: ITableCountDto | null = null;
  
  displayedColumnsWithExpand = [...this.displayedColumns];
  expandedElement: ITableCountDto | undefined;
  constructor(
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private localityService :LocalityService ,
    private undertakersService :UndertakerService,
    private internalService:InternalCommunicationService
  ){ 
    this.dataSource = new MatTableDataSource(this.undertakers);
  }
  ngOnInit() { 
    this.getAllLocalities();
    this.getAllUndertakers();
    
    this.internalService.undertakerUpdated$.subscribe(() => {
      this.refreshTableData();
    });
  }

  refreshTableData() {
    this.fetchUndertakers().subscribe(data => {
      if (data === null) {
        return;
      }
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.sort = this.sort;
    });
  } 
  
  getAllUndertakers(){
    this.undertakersService.getAllUndertakers().subscribe(
      (response : IUndertakerDto[]) => {
        this.undertakers = response;
      },
    );
  }
  ngAfterViewInit():void {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.fetchUndertakers();
        }),
        map(data => {
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;
  
          if (data === null) {
            return [];
          }
  
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
  
          return data;
        })
      )
      .subscribe();
}
fetchUndertakers() {
  return this.undertakersService!.getAllUndertakers(
  ).pipe(catchError(() => observableOf(null)));
}

applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();

  if (this.dataSource.paginator) {
    this.dataSource.paginator.firstPage();
  }
  this.selectedUndertaker = null;
}
editUndertakerClick( undertakerObj:IUndertakerDto){
  let undertaker: number = +undertakerObj.id;
    this.undertakersService.getUndertakerById(undertaker).subscribe(
      (undertakerObject: IUndertakerDto) => {
        const dialogRef = this.dialog.open(UndertakerEditComponent, {
          data: undertakerObject,
          
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');       
          this.internalService. emitRefreshUndertakerTable();   
        });
      },
      error => {
        console.error('Error:', error);        
      }
    );
  }
 
 
NewUndertakerClick(){
    const dialogRef = this.dialog.open(UndertakerNewComponent, {
      restoreFocus: true,      
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.internalService. emitRefreshUndertakerTable();
    });
  }

   formatDate(date: Date): string {
    return this.datePipe.transform(date, 'dd/MM/yyyy') || ''; 
  }
  expandCollapse(undertakerObj: any): void {
    this.expandedElement = this.expandedElement === undertakerObj? null : undertakerObj;
}
deleteUndertaker(id: number){       
  this.undertakersService.deleteUndertaker(id).subscribe(
    (response : IResponseModal) => {
      this.openResponseModal(response);
      this.internalService. emitRefreshUndertakerTable();

    }, (error) =>{
      console.error('Error deleting undertaker: ', error);
    })
} 

openResponseModal(response : IResponseModal): void {
  const dialogRef = this.dialog.open(ResponsemodalComponent, {
    width: '250px',
    data : response,
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');
  });
}
getAllLocalities(){ 
  this.localityService.getAllLocalities().subscribe(data => {
    this.localities = data;
  });
}

getLocalityDescription(localityId: number): string {
const locality = this.localities.find(loc => loc.id === localityId);
return locality ? locality.description : '';
}
}
