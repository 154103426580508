import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GraveEditComponent } from './grave-edit/grave-edit.component';
import { GraveNewComponent } from './grave-new/grave-new.component';
import { IGraveDto } from 'src/app/models/GraveDto.model';
import { GraveService } from 'src/app/services/grave.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BehaviorSubject, catchError, map, merge, Observable, of as observableOf, of, startWith, switchMap} from 'rxjs';
import { InternalCommunicationService } from 'src/app/services/internal-communication.service';
import { ITableCountDto } from 'src/app/burials/models/ITableCountDto';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CemeteryService } from 'src/app/services/cemetery.service';
import { DivisionService } from 'src/app/services/division.service';
import { SectionService } from 'src/app/services/section.service';
import { CompartmentService } from 'src/app/services/compartment.service';
import { ICemeteryDto } from 'src/app/models/CemeteryDto.model';
import { IDivisionDto } from 'src/app/models/DivisionDto.model';
import { ISectionDto } from 'src/app/models/SectionDto.model';
import { ICompartmentDto } from 'src/app/models/CompartmentDto.model';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { IGraveLevelsDto } from 'src/app/models/GraveLevelsDto.model';
import { GraveExtendedComponent } from 'src/app/burials/burial-permit-generation/grave-extended/grave-extended.component';
import { IResponseModal } from 'src/app/models/ResponseModal.model';
import { ResponsemodalComponent } from 'src/app/responsemodal/responsemodal.component';
import { IGraveOwnerDto } from 'src/app/models/GraveOwnerDto.model';
import { IGraveOwership } from 'src/app/models/GraveOwnershipDto.model';
import { ICemeteryTypeDto } from 'src/app/models/CemeteryTypeDto.model';
import { GraveMaintenanceViewComponent } from './grave-maintenance-view/grave-maintenance-view.component';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { SearchbarComponent } from 'src/app/searchbar/searchbar.component';
import { NewCleaningComponent } from 'src/app/new-cleaning/new-cleaning.component';



@Component({
  selector: 'app-grave',
  templateUrl: './grave.component.html',
  styleUrls: ['./grave.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class GraveComponent implements OnInit {  
  @ViewChild(SearchbarComponent) searchbarComponent!: SearchbarComponent;
  isLoading = false;   
  methodName: string = 'GetGraves';  
  displayedGraves! : IGraveDto[];
  flagToAll =false;

  graveFormGroup! : FormGroup;
  selectedGrave: IGraveDto | null = null;
  originalGraves : IGraveDto[] = [];
  graves : IGraveDto[] = [];
  grave!: IGraveDto;
  resultsLength = 0;
  
  isRateLimitReached = false;
  cemeteries !: ICemeteryDto[];     
  cemeteryTypes!: ICemeteryTypeDto[];
  
  graveLevelStatusTypes = [
    { id: 1, description: 'Empty' },
    { id: 2, description: 'Populated' },
    { id: 4, description: 'Full' }
  ];
  gravesForNo! : IGraveDto[];
  private searchFlag$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  cemetery!: ICemeteryDto;
  cemeteryType!:ICemeteryTypeDto;
  divisions ! : IDivisionDto[];
  sections!: ISectionDto[];
  compartments!: ICompartmentDto[];  
  ownerships!: IGraveOwership[];
  owners!: IGraveOwnerDto[];
  graveLevels!: IGraveLevelsDto[];

  originalCemeteries!:ICemeteryDto[];
  originalDivisions!:IDivisionDto[];
  originalSections! : ISectionDto[];
  originalsCompartments! : ICompartmentDto[];

  displayedColumns: string[] = [
    'id', 
    'cemetery',
    // 'division',
    // 'section',
    // 'compartment',
    'graveNo',        
    'referenceNumber',         
    'dhFileNo',        
    'isPrivateCtrl',        
    'graveStatus',
    'expand',
    'action'
  ];
  pageSize = 25; 
  resetSearchBar = false;
  pageIndex = 0; 

  dataSource!: MatTableDataSource<IGraveDto>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSlideToggleModule) slidetoggle!: MatSlideToggleModule;
  @ViewChild(MatSort) sort!: MatSort;  

  displayedColumnsWithExpand = [...this.displayedColumns];
  expandedElement: ITableCountDto | undefined;

  options!: any[] ;
  filteredOptions: Observable<string[]>= of([]);

  cemeteryColmn!: string;
  divisionColmn!: string;
  sectionColmn!: string;
  compartmentColmn!: string;

constructor(
  private cd: ChangeDetectorRef,
  public dialog: MatDialog, 
  private graveService : GraveService,
  private internalService : InternalCommunicationService,
  private _formBuilder: FormBuilder,
  private cemeteryService : CemeteryService,
  private divisionService : DivisionService,
  private sectionService : SectionService,
  private compartmentService : CompartmentService      
) 
  {     
  }
  ngOnInit(): void {       
    this.flagToAll = true; 
    this.grave = {
        id: 0,
        referenceNumber: '',
        dhFileNo:'',
        isActive : false, 
        cemeteryId: 0,
        divisionId: 0,
        sectionId:0,
        remarks:'',
        compartmentId : 0,
        graveNo:'0',
        gravesize : '',  
        graveSizeId:0,
        graveLevelDto : [],
        graveOwnershipsDto :[],
        firstOwner: '',
        secondOwner: '',
        thirdOwner: '',
        isPrivate: false,
        isSealed : false,
        cemeteryTypeId :0,
        statusId : 0,
        graveTypeId : 0,
        graveTypeDescription : '',
        cemeteryName: '',
        divisionName: '',
        sectionName: '',
        compartmentName: ''               
    }

    this.internalService.graveUpdated$.subscribe(() => {          
       if(
        this.grave.cemeteryId === 0 && 
        this.grave.divisionId === 0 && 
        this.grave.sectionId === 0 && 
        this.grave.compartmentId === 0 )
        {              
          this.fetAllchGraves(this.pageIndex +1 ,this.pageSize); 
        }else{
          
          const searchaFlag = this.searchFlag$.getValue();
          if(!searchaFlag){
            this.refreshTableData(
              this.grave.cemeteryId,
              this.grave.divisionId,
              this.grave.sectionId,
              this.grave.compartmentId,
              this.grave.graveNo);
          }
          
        }
     
    });

    //this.getAllGraves(this.pageIndex + 1, this.pageSize);
    this.getAllCemeteries();
    this.getAllDivisions(); 
    this.getAllSections();
    this.getAllCompartments();       
    this.getCemeteryTypes();
    

    this.graveFormGroup = this._formBuilder.group({     
      cemeteryCtrl : [this.grave ? this.grave.cemeteryId : ''],
      divisionCtrl: [this.grave ? this.grave.divisionId : ''],
      sectionCtrl: [this.grave ? this.grave.sectionId : ''],
      compartmentCtrl: [this.grave ? this.grave.compartmentId : ''],
      isPrivateCtrl : [this.grave ? this.grave.graveTypeDescription :'' ],
      graveCtrl : [this.grave ? this.grave.graveNo : '' ],
      
  });
  this.updateFilteredOptions('graveCtrl');
  this.subscribeChanges();
  this.disableCemeteryFilters();
  
  }
  disableCemeteryFilters(){
    
    this.graveFormGroup.get('divisionCtrl')?.disable();
    this.graveFormGroup.get('sectionCtrl')?.disable();
    this.graveFormGroup.get('compartmentCtrl')?.disable();
  }
  subscribeChanges() {
    this.graveFormGroup.get('cemeteryCtrl')?.valueChanges.subscribe((value) => {            
      this.grave.cemeteryId = value;
    });
    this.graveFormGroup.get('divisionCtrl')?.valueChanges.subscribe((value) => {            
      this.grave.divisionId = value;
    });
    this.graveFormGroup.get('sectionCtrl')?.valueChanges.subscribe((value) => {            
      this.grave.sectionId = value;
    });
    this.graveFormGroup.get('compartmentCtrl')?.valueChanges.subscribe((value) => {            
      this.grave.compartmentId = value;
    });
    this.graveFormGroup.get('graveCtrl')?.valueChanges.subscribe((value) => {  
      if(value === ''){
        this.getGravesByGraveNo(
          this.grave.cemeteryId,
          this.grave.divisionId,
          this.grave.sectionId,
          this.grave.compartmentId,
          '0'
        );
      }
      if (!value) {
        this.updateFilteredOptions('graveCtrl');
        this.handleInvalidOrEmptyInput();
      }else if (!this.graves.some(grave => grave.graveNo === value)) {
        this.handleInvalidOrEmptyInput();
      }
    });
    
  }
  
  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.searchFlag$.pipe(
            switchMap((searchFlag) => {
              if (!searchFlag && this.flagToAll) {
               
                return this.fetAllchGraves(this.pageIndex + 1, this.pageSize);
              } else {
                return of(null);
              }
            })
          );
        }),
        map((data) => {
          //this.delaySpinner(1000);
          this.isRateLimitReached = data === null;

          if (data === null) {
            return [];
          }

          this.graves = data.results;

          this.getOriginalCemeteryFilters();
     
         
          this.dataSource = new MatTableDataSource(this.graves);
          this.dataSource.sort = this.sort;
          this.resultsLength = data.totalItems;

          // Mapping ownerships and owners
          const ownershipsArray = this.graves.map(grave => grave.graveOwnershipsDto).filter(ownership => ownership);
          this.ownerships = ownershipsArray.flatMap(ownership => ownership || []);

          const ownersArray = this.ownerships.map(ownership => ownership.graveOwnerDto).filter(owner => owner);
          this.owners = ownersArray.flatMap(owner => owner || []);

          return data;
        })
      )
      .subscribe();
  }

  fetchGraves(cemeteryId : number, divisionId : number,sectionId : number,compartmentId : number,graveNo : string)  {
    
    return this.graveService.getAllGravesByGraveNo(
      cemeteryId,
      divisionId,
      sectionId,
      compartmentId,
      graveNo,
      this.paginator.pageIndex + 1,
      this.paginator.pageSize
    ).pipe(catchError(() => observableOf(null)));
  }

  fetAllchGraves(pageNumber: number, pageSize: number )  {
    
    return this.graveService.getAllGraves(
      null,null,
      pageNumber,
      pageSize
    ).pipe(catchError(() => observableOf(null)));
  }


  refreshTableData(cemeteryId : number, divisionId : number,sectionId : number,compartmentId : number, graveNo : string) {
       
    this.fetchGraves(cemeteryId,divisionId,sectionId,compartmentId,graveNo).subscribe(data => {
      
      if (data === null) {
        return;
      }         
      
      this.graves = data.results;    
       
      this.fillGraveAddressNames(this.graves); 
      this.dataSource = new MatTableDataSource(this.graves);        
      this.dataSource.sort = this.sort;
      this.resultsLength = data.totalItems;
    });
  }   

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  
    this.selectedGrave = null;
  }

  updateGrave(grave:IGraveDto){      
    
    const dialogRef = this.dialog.open(GraveEditComponent, {          
      data: grave,
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  
  }
  deleteGrave(id: number){       
    this.graveService.deleteGrave(id).subscribe(
      (response : IResponseModal) => {
        this.openResponseModal(response);
        this.internalService.emitRefreshGraveTable();

      }, (error) =>{
        console.error('Error deleting Grave: ', error);
      })
  }
  addGrave(){
    const dialogRef = this.dialog.open(GraveNewComponent, {         
  
    });
    dialogRef.afterClosed().subscribe(result => {
      this.internalService.emitRefreshGraveTable();    
      console.log('The dialog was closed');
     
    });
  }
  graveView(grave : IGraveDto){
    const dialogRef = this.dialog.open(GraveMaintenanceViewComponent, {  
      data : grave,        
    });
    dialogRef.afterClosed().subscribe(result => {
       this.internalService.emitRefreshGraveTable();
      console.log('The dialog was closed');
     
    });

  }
  getAllGraves(pageNumber: number, pageSize: number){
   
    this.isLoading = true;
    this.graveService.getAllGraves(null, null, pageNumber, pageSize).subscribe(
      (response : ITableCountDto) => {
        this.graves = response.results;
        this.originalGraves = response.results;
        this.isLoading = false;
    },
    (error) => {
      console.error('error retrieving graves:', error)
    })
  }
  getCemeteryTypes(){       
    this.cemeteryService.getAllCemeteryType().subscribe(
      (response : ICemeteryTypeDto[]) => {
      this.cemeteryTypes = response;          
    },
    (error) => {
      console.error('error retrieving cemetery types:', error)
    })
   }

  
  getAllCemeteries(){        
    this.cemeteryService.getAllCemeteries().subscribe( 
      (response : ICemeteryDto[])=>{
        this.originalCemeteries = response;
        this.cemeteries = response;            
      }, (error) =>{
        console.error('error retieving cemeteries service:', error);
      }) 
  }

  getAllDivisions(){
    this.divisionService.getAllDivisions().subscribe( 
      (response : IDivisionDto[])=>{
        this.originalDivisions = response;
        this.divisions = response;
      }, (error) =>{
        console.error('error retieving divisions service:', error);
      }) 
  }

      getAllSections(){
        this.sectionService.getAllSections().subscribe( 
          (response : ISectionDto[])=>{
            this.originalSections = response;
            this.sections = response;
          }, (error) =>{
            console.error('error retieving sections service:', error);
          }) 
      }
      getAllCompartments(){
        this.compartmentService.getAllCompartments().subscribe( 
          (response : ICompartmentDto[])=>{
            this.originalsCompartments = response;
            this.compartments = response;
          }, (error) =>{
            console.error('error retieving compartment service:', error);
          }) 
      }
      
      getdivisionsByCemeteryId(cemeteryId : number){
        this.isLoading = true
        this.divisionService.getDivisionsByCementeryId(cemeteryId).subscribe(
          (response :IDivisionDto[]) => {
            this.divisions = response;  
            this.isLoading = false;          
          }, (error) => {
            console.error('Something went wrong getDivisionsByCementeryId :', error)

      })
  }

  getSectionsByCemeteryIdAndDivisionId( divisionId : number, cemeteryId : number){
    this.sectionService.getSectionByDivisionIdAndCemeteryId(divisionId,cemeteryId).subscribe(
      (response :ISectionDto[]) => {
        this.sections = response;
      }, (error) => {
        console.error('Something went wrong getSectionsByCemeteryIdAndDivisionId :', error)

      })
  }

  getCompartmentsByCemeteryIdAndDivisionIdAndSection( cemeteryId : number,divisionId : number,sectionId : number ){
    this.compartmentService.getCompartmentByCemeteryIdAndDivisionIdSectionId(cemeteryId,divisionId,sectionId).subscribe(
      (response :ICompartmentDto[]) => {
        this.compartments = response;
      }, (error) => {
        console.error('Something went wrong compartments :', error)

      })
  }

  onCemeteryTypeChange(){    
    const selectedCemeteryType = this.cemeteryTypes.find(cem => cem.id == this.cemeteryType.id);  
    if(selectedCemeteryType && selectedCemeteryType.id != 0){  
      this.cemeteries = this.originalCemeteries;   
      this.cemeteries = this.cemeteries.filter(cem => cem.cemeteryTypeId === selectedCemeteryType.id);        

    }else{
      this.cemeteries = this.originalCemeteries;
    }
   }

  onCemeteryChange(cemeteryId : number){      
    
    this.searchbarComponent.resetSearch();
    const selectedCemetery = this.originalCemeteries.find(cem => cem.code === cemeteryId);
    if(selectedCemetery && selectedCemetery.code != 0){      
      
      this.graveFormGroup.get('divisionCtrl')?.enable();
      this.graveFormGroup.get('sectionCtrl')?.disable();
      this.graveFormGroup.get('compartmentCtrl')?.disable();
     
      this.getdivisionsByCemeteryId(selectedCemetery.code);
      
      this.getGravesByGraveNo(
        selectedCemetery.code,
        this.grave.divisionId, 
        this.grave.sectionId,
        this.grave.compartmentId,
        this.grave.graveNo
      );   
      

    }else{
      this.graveFormGroup.get('divisionCtrl')?.disable();
      this.graveFormGroup.get('sectionCtrl')?.disable();
      this.graveFormGroup.get('compartmentCtrl')?.disable();
      this.divisions = this.originalDivisions;
      
    }
  }
  onDivisionChange() {

    const selectedDivision = this.divisions.find(div => div.id === this.grave.divisionId);

    if (selectedDivision && selectedDivision.id != 0 ) {
      this.graveFormGroup.get('sectionCtrl')?.enable();
      this.graveFormGroup.get('compartmentCtrl')?.disable();

      this.getSectionsByCemeteryIdAndDivisionId(selectedDivision.id,selectedDivision.cemeteryId);
      
      this.getGravesByGraveNo(
        selectedDivision.cemeteryId,
        selectedDivision.id, 
        this.grave.sectionId,
        this.grave.compartmentId,
        this.grave.graveNo
      );
         
    
    } else {
      this.graveFormGroup.get('sectionCtrl')?.disable();
      this.graveFormGroup.get('compartmentCtrl')?.disable();
      this.sections = this.originalSections;
      
    }
  }
  onSectionChange(sectionId : number) {        
    const selectedSection = this.sections.find(sec => sec.id === sectionId);

    if (selectedSection && selectedSection.id != 0) {
      
      this.graveFormGroup.get('compartmentCtrl')?.enable();                  
      this.getCompartmentsByCemeteryIdAndDivisionIdAndSection(selectedSection.cemeteryId,selectedSection.divisionId,sectionId);
      this.getGravesByGraveNo(
        selectedSection.cemeteryId,
        selectedSection.divisionId, 
        selectedSection.id,
        this.grave.compartmentId,
        this.grave.graveNo
      );
         
      
    } else {
      this.graveFormGroup.get('compartmentCtrl')?.disable();
      this.compartments = this.originalsCompartments;
    
    }
  }
  onCompartmentChange(compartementId : number) {    
   
    const selectedCompartment = this.compartments.find(com => com.id === compartementId);
    
    this.grave.compartmentId = compartementId;
    if (selectedCompartment && selectedCompartment.id != 0) {
      this.graveFormGroup.get('graveCtrl')?.setValue('0'); 

      this.graveFormGroup.get('graveCtrl')?.enable();
      this.updateFilteredOptions('graveCtrl');

      this.getGravesByGraveNo(
        selectedCompartment.cemeteryId,
        selectedCompartment.divisionId,
        selectedCompartment.sectionId,
        selectedCompartment.id,
        '0'
      );

     
      //this.fillGraveAddressNames(this.graves)

      this.graves.forEach(grave => {
        if (grave.graveOwnershipsDto && grave.graveOwnershipsDto.length > 0) {
          grave.firstOwner = `${grave.graveOwnershipsDto[0].graveOwnerDto.ownerName.trim()} ${grave.graveOwnershipsDto[0].graveOwnerDto.ownerSurname.trim()}`;
        }
        if (grave.graveOwnershipsDto && grave.graveOwnershipsDto.length > 1) {
          grave.secondOwner = `${grave.graveOwnershipsDto[1].graveOwnerDto.ownerName.trim()} ${grave.graveOwnershipsDto[1].graveOwnerDto.ownerSurname.trim()}`;
        }
        if (grave.graveOwnershipsDto && grave.graveOwnershipsDto.length > 2) {
          grave.thirdOwner = `${grave.graveOwnershipsDto[2].graveOwnerDto.ownerName.trim()} ${grave.graveOwnershipsDto[2].graveOwnerDto.ownerSurname.trim()}`;
        }
      });        
      
      const graveLevelArrays = this.graves.map(grave => grave.graveLevelDto).filter(dto => dto);           
      this.graveLevels = graveLevelArrays.flatMap(levels => levels || []);
     
    } else {
      this.graveFormGroup.get('graveCtrl')?.disable();
      this.graves = this.originalGraves;
      this.graveLevels = [];
      this.fillTable(this.graves);         
     
    }
  }  

  graveNoSelected(event: MatAutocompleteSelectedEvent){      
    const selectedGraveNo = event.option.value;   
    const selectedGrave = this.gravesForNo.find(grave => grave.graveNo === selectedGraveNo);  
   
  if (selectedGrave && selectedGrave.id != 0) {        
      this.grave.id = selectedGrave.id;
      this.grave.graveNo = selectedGrave.graveNo;          
      
      this.graves = this.gravesForNo.filter( gr => 
        gr.cemeteryId === selectedGrave.cemeteryId && 
        gr.divisionId === selectedGrave.divisionId &&
        gr.sectionId === selectedGrave.sectionId && 
        gr.compartmentId === selectedGrave.compartmentId && 
        gr.graveNo ===selectedGrave.graveNo );
      
    }else if(selectedGrave && selectedGrave.id === 0)
      {            
        this.graves = this.originalGraves.filter( gr => 
        gr.cemeteryId === selectedGrave.cemeteryId && 
        gr.divisionId === selectedGrave.divisionId &&
        gr.sectionId === selectedGrave.sectionId && 
        gr.compartmentId === selectedGrave.compartmentId);    
     
      }
    this.updateFilteredOptions('graveCtrl');
    this.fillTable(this.graves);
  }

  isSelected(grave: IGraveDto): boolean {
    return this.selectedGrave === grave;    
  }
  expandCollapse(grave: any): void {
    this.expandedElement = this.expandedElement === grave ? null : grave;
}
  openResponseModal(response : IResponseModal): void {
    const dialogRef = this.dialog.open(ResponsemodalComponent, {
      width: '250px',
      data : response,
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }


  private updateFilteredOptions(controlName : string) {
  
    const control = this.graveFormGroup.get(controlName);
    if (control) {
      this.filteredOptions = control.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || ''))
      );
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.graves
      .map(gr => gr.graveNo !== null && gr.graveNo !== undefined ? gr.graveNo.toString() : '') 
      .filter(graveNo => graveNo && graveNo.toLowerCase().includes(filterValue));
  }
  handleInvalidOrEmptyInput() {
    this.graves = this.originalGraves.filter(gr =>
      gr.cemeteryId === this.grave.cemeteryId && 
        gr.divisionId === this.grave.divisionId &&
        gr.sectionId === this.grave.sectionId && 
        gr.compartmentId === this.grave.compartmentId 
    );
    this.fillTable(this.graves);
  }

  fillTable(graves : IGraveDto[]){           
    this.dataSource = new MatTableDataSource(graves);        
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.resultsLength = graves.length;
  }

  pageEvent(event: any) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;        
    const searchaFlag = this.searchFlag$.getValue();
    if(searchaFlag){                   
      this.fillTable(this.graves)
    }
  }
  delaySpinner(milliSeconds : number){
    setTimeout(() => {
      this.isLoading = false;
    }, milliSeconds); // 2000ms = 2 seconds
  }

  getGravesByGraveNo(cemeteryId : number,divisionId : number, sectionId: number,compartementId : number,graveNo : string){
    this.flagToAll = false;  
    this.isLoading = true;
    this.graveService.getAllGravesByGraveNo(
      cemeteryId,          
      divisionId,
      sectionId,
      compartementId,
      graveNo, this.pageIndex + 1,this.pageSize).subscribe(
      (response : ITableCountDto) => {

        this.graves = response.results;
      
       
        this.fillGraveAddressNames(this.graves);               
        this.fillTable(this.graves); 
        this.updateFilteredOptions('graveCtrl');   
        this.isLoading = false;   
        this.gravesForNo = response.results;           
    },
    (error) => {
      console.error('error retrieving graves:', error)
    })
  }
getGraveStatusDescription(statusId: number): string {
const graveStatus = this.graveLevelStatusTypes.find(status => status.id === statusId);
return graveStatus ? graveStatus.description : '';
}

// FILL GRAVE ADDRESS NAMES ON TABLE
fillGraveAddressNames(graves: IGraveDto[]){
this.graves.forEach(grave => {
  let cemetery = this.originalCemeteries.find(cem => cem.code === grave.cemeteryId);
  if (cemetery) {
      grave.cemeteryName = cemetery.name;
  }      

  let division = this.originalDivisions.find(div => div.id === grave.divisionId);
  if (division) {
      grave.divisionName = division.description;
  }

  let section = this.originalSections.find(sec => sec.id === grave.sectionId);
  if (section) {
      grave.sectionName = section.description;
  }

  let compartment = this.originalsCompartments.find(com => com.id === grave.compartmentId);
  if (compartment) {
      grave.compartmentName = compartment.description;
  }
});
}

handleResponseSearch(searchResults: any[]) {    
this.graves = searchResults; 
console.log('handle response', this.graves)      
this.searchFlag$.next(true);   
this.fillGraveAddressNames(this.graves);
this.fillTable(this.graves); 
}
clearSearch() {
this.searchFlag$.next(false);

}
resetForm() {
this.graveFormGroup.reset();
}

newCleaning(grave : IGraveDto){
const dialogRef = this.dialog.open(NewCleaningComponent, {       
  data : grave,
});
dialogRef.afterClosed().subscribe(result => {
  
  console.log('The dialog was closed');
 
});

}

getOriginalCemeteryFilters(){
this.cemeteryService.getAllCemeteries().subscribe(
  (response : ICemeteryDto[]) => {
    this.originalCemeteries = response;
    
    this.divisionService.getAllDivisions().subscribe(
      (response : IDivisionDto[]) =>{
        this.originalDivisions = response;
        
        this.sectionService.getAllSections().subscribe(
          (response: ISectionDto[]) =>{
            this.originalSections = response;
           
            this.compartmentService.getAllCompartments().subscribe(
              (response : ICompartmentDto[]) => {
                this.originalsCompartments = response;
                
                this.fillGraveAddressNames(this.graves);

              }, (error) =>{
                console.error('something wrong happens with getOriginalCemeteryFilters compartment resource', error)

              })
          }, (error) =>{
            console.error('something wrong happens with getOriginalCemeteryFilters section resource', error)
          })

      },(error) =>{
         console.error('something wrong happens with getOriginalCemeteryFilters division resource', error)
      })


  }, (error) =>{
    console.error('something wrong happens with getOriginalCemeteryFilters cemetery resource', error)

  })


}
clearGraveSelected() {
  // Reset the form group
  this.graveFormGroup.reset();
  this.internalService.emitRefreshGraveTable();
  window.location.reload();
}

}
