import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IDeceasedDto } from 'src/app/models/Deceased.model';
import { DeceasedEditComponent } from './deceased-edit/deceased-edit.component';
import { BehaviorSubject, catchError,map,merge,of as observableOf, of, startWith, switchMap } from 'rxjs';
import { InternalCommunicationService } from 'src/app/services/internal-communication.service';
import { ITableCountDto } from 'src/app/burials/models/ITableCountDto';
import { DeceasedService } from 'src/app/services/deceased.service';
import { IGenderDto } from 'src/app/models/Gender.model';
import { GenderService } from 'src/app/services/gender.service';
import { MaritalStatusService } from 'src/app/services/marital-status.service';
import { IMaritalStatusDto } from 'src/app/models/MaritalStatusDto.model';
import { LocalityService } from 'src/app/services/locality.service';
import { ILocalityDto } from 'src/app/models/LocalityDto.model';
import { FormGroup } from '@angular/forms';
import { error } from 'pdf-lib';


@Component({
  selector: 'app-deceased',
  templateUrl: './deceased.component.html',
  styleUrls: ['./deceased.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DeceasedComponent implements OnInit, AfterViewInit{
  private searchFlag$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  pageSize = 25; 
  resetSearchBar = false;
  pageIndex = 0; 
  deceasedFormGroup! : FormGroup;
  methodName = 'GetDeceased'
  deceased :IDeceasedDto[]=[];
  isRateLimitReached = false;
  selectedDeceased: ITableCountDto | null = null;
  deceasedRecord!:IDeceasedDto[];
  resultsLength = 0;
  isLoadingResults = true;
  genders:IGenderDto[]=[];
  localities:ILocalityDto[]=[];
  statuses : IMaritalStatusDto[] = [];
  selectedGenderDescription!: string;
  selectedMaritalStatusDescription !: string;
  documentTypes = [
    { id: 1, description: 'Passport' },
    { id: 2, description: 'ID Card' }
  ];

deceasedObj :IDeceasedDto = {
  idCardNumber : '',
  name : '',
  surname : '',
  address : '',
  documentTypeId : 0,
  maritalStatusId : 0,
  genderId:0,
  spouseName : '',
  spouseSurname: '',
  dateOfBirth:new Date(),
  placeOfBirth :'',
  localityId : 0,
  profession : '',
  fatherName : '',
  fatherSurname :'',
  fatherStatus : '',
  motherName : '',
  motherSurname : '',
  motherStatus : '',
  burialId:0,
}
  displayedColumns: string[] = [     
   
    'id', 
    // 'type',
    'name', 
    'surname',    
    'locality',
    'expand',
    'action',
  ];
  
  dataSource!: MatTableDataSource<IDeceasedDto>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  deceaseds! : IDeceasedDto[];

  displayedColumnsWithExpand = [...this.displayedColumns];
  expandedElement: ITableCountDto | undefined;
  private _formBuilder: any;
  constructor(
    private datePipe: DatePipe,
    public  dialog: MatDialog,
    private deceasedService : DeceasedService,
    private localityService : LocalityService,
    private genderService:GenderService,
    private maritalStatusService : MaritalStatusService,
    private internalService : InternalCommunicationService, 
   
    ){
      this.dataSource = new MatTableDataSource(this.deceased);
    }
 
    ngOnInit() { 
      this.getAllGender();
      this.getAllMaritalStatus();
      this.getAllLocalities();
      this.internalService.deceasedUpdated$.subscribe(() => {
        const searchaFlag = this.searchFlag$.getValue();
        if(!searchaFlag){
          this.refreshTableData();
        }
        
      });
      
     
      }   
      ngAfterViewInit():void {    
        this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        merge(this.sort.sortChange, this.paginator.page)
       
        .pipe(
          startWith({}),
          switchMap(() => {
            return this.searchFlag$.pipe(
              switchMap((searchFlag) => {
                if (!searchFlag) {
                  
                  return this.fetchDeceased();
                } else {
                  return of(null);
                }
              })
            );
          }),
          map(data => {
            
            if (data === null) {
              return [];
            }
            this.deceasedRecord = data.results; 
            this.dataSource = new MatTableDataSource(this.deceasedRecord);        
            this.dataSource.sort = this.sort;
            this.resultsLength = data.totalItems;
            
            return data;
          }),
        )
        .subscribe();    
      } 


      fetchDeceased() {
        return this.deceasedService!.getAllDeceased(
          this.sort.active,
          this.sort.direction,
          this.paginator.pageIndex + 1,
          this.paginator.pageSize
        ).pipe(catchError(() => observableOf(null)));
      }
      refreshTableData() {
        this.isLoadingResults = true;
        this.fetchDeceased().subscribe(data => {
          this.isLoadingResults = false;
          if (data === null) {
            return;
          }
          this.deceasedRecord = data.results; 
          this.dataSource = new MatTableDataSource(this.deceasedRecord);        
          this.dataSource.sort = this.sort;
          this.resultsLength = data.totalItems;
        });
      } 
       
      applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
      
        if (this.dataSource.paginator) {
          this.dataSource.paginator.firstPage();
        }
        this.selectedDeceased = null;
      }
    
 
editDeceasedClick(deceasedObj:IDeceasedDto){
  let deceasedDetails:string= deceasedObj.idCardNumber;
  this.deceasedService.getDeceased(deceasedDetails).subscribe(
    (deceasedObject: IDeceasedDto) => {
      const dialogRef = this.dialog.open(DeceasedEditComponent, {
        data: deceasedObject, 
        
      });
  dialogRef.afterClosed().subscribe(result => {
    this.internalService. emitRefreshDeceasedTable();
    console.log('The dialog was closed');
  });
 
},
error => {
  console.error('Error:', error);        
}
);
}
   formatDate(date: Date): string {
    return this.datePipe.transform(date, 'dd/MM/yyyy') || ''; 
  }
  expandCollapse(deceasedObj: any): void {
    this.expandedElement = this.expandedElement === deceasedObj ? null : deceasedObj;
}

getAllMaritalStatus() {
  this.maritalStatusService.getAllMaritalStatus().subscribe(data => {
    this.statuses = data;
  });
}
getAllGender(){ 
    this.genderService.getAllGender().subscribe(data => {
      this.genders = data;
    });
  }
  getAllLocalities(){ 
    this.localityService.getAllLocalities().subscribe(data => {
      this.localities = data;
    });
  }
  getLocalityDescription(localityId: number): string {
    const locality = this.localities.find(loc => loc.id === localityId);
    return locality ? locality.description : '';
  }
getGenderDescription(genderId: number): string {
  const gender = this.genders.find(g => g.id === genderId);
  return gender ? gender.description : '';
}
getMaritalStatusDescription(maritalStatusId: number): string {
  const maritalStatus = this.statuses.find(ms => ms.id === maritalStatusId);
  return maritalStatus ? maritalStatus.description : '';
}
getDocumentTypeDescription(documentTypeId: number): string {
  const documentType = this.documentTypes.find(dt => dt.id === documentTypeId);
  return documentType ? documentType.description : '';
}


  
  handleResponseSearch(searchResults: any[]) {  
    console.log('handle response: ')
    console.log(searchResults)  
    this.deceaseds = searchResults;       
    this.searchFlag$.next(true);   
    //this.fillGraveAddressNames(this.graves);
   this.fillTable(this.deceaseds); 
  }

  pageEvent(event: any) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    const searchaFlag = this.searchFlag$.getValue();
    if(searchaFlag){                   
      this.fillTable(this.deceaseds)
    }else{
      this.pageSize = event.pageSize;
      this.pageIndex = event.pageIndex;
      
    }   
    
  }
  fillTable(deceaseds : IDeceasedDto[]){           
    this.dataSource = new MatTableDataSource(deceaseds);        
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.resultsLength = deceaseds.length;        

  }

}
