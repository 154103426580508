import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IAddNewSectionDto } from 'src/app/models/AddNewSectionDto.model';
import { ICemeteryDto } from 'src/app/models/CemeteryDto.model';
import { IDivisionDto } from 'src/app/models/DivisionDto.model';
import { ILocalityDto } from 'src/app/models/LocalityDto.model';
import { IResponseModal } from 'src/app/models/ResponseModal.model';
import { ISectionDto } from 'src/app/models/SectionDto.model';
import { ResponsemodalComponent } from 'src/app/responsemodal/responsemodal.component';
import { CemeteryService } from 'src/app/services/cemetery.service';
import { DivisionService } from 'src/app/services/division.service';
import { InternalCommunicationService } from 'src/app/services/internal-communication.service';
import { LocalityService } from 'src/app/services/locality.service';
import { SectionService } from 'src/app/services/section.service';

@Component({
  selector: 'app-section-new',
  templateUrl: './section-new.component.html',
  styleUrls: ['./section-new.component.css']
})
export class SectionNewComponent {
  SectionNewForm! :FormGroup;
  divisions: IDivisionDto[] = [];
  cemeteries : ICemeteryDto[] = [];
  localities : ILocalityDto[] = [];
  sections: ISectionDto[] = [];
  cemetery! : ICemeteryDto;
  sectionmain: IAddNewSectionDto = {
    //id :0,
    description: '',
    divisionId: 0,
    cemeteryId :0  ,
    //divId :0,
    //localityId:0,
  }
 
 constructor(
  private cemeteryService : CemeteryService,
  private localityService : LocalityService,
  private divisionService : DivisionService,
  private sectionService : SectionService,
  public dialog: MatDialog,
  private _formBuilder: FormBuilder,
  private internalService : InternalCommunicationService, 
   @Inject(MAT_DIALOG_DATA) public data: ISectionDto){}

   ngOnInit() {
    this.getAllCemeteries();
    this.getAllLocalities();

    this. SectionNewForm = this._formBuilder.group({
      //cemeteryCtrl: [this.sectionmain.cemId],  
      //divisionCtrl: [this.sectionmain.divId], 
      //localityCtrl: [this.sectionmain.localityId], 
      sectionCtrl : [this.sectionmain.description],  
  });
  this.subscribeChanges();
}
subscribeChanges() {
/*   this.SectionNewForm.get('cemeteryCtrl')?.valueChanges.subscribe((value) => {     
    this.sectionmain.cemId= value;
  });
  this.SectionNewForm .get('divisionCtrl')?.valueChanges.subscribe((value) => {     
    this.sectionmain.divId= value;
  });
  this.SectionNewForm .get('localityCtrl')?.valueChanges.subscribe((value) => {     
    this.sectionmain.localityId= value;
  }); */
  this.SectionNewForm .get('sectionCtrl')?.valueChanges.subscribe((value) => {     
    this.sectionmain.description= value;
  });
}
getAllCemeteries(){
  this.cemeteryService.getAllCemeteries().subscribe(
   (response : ICemeteryDto[]) => {
     this.cemeteries = response;
   });
}
 getAllLocalities(){
    this.localityService.getAllLocalities().subscribe(
      (response: ILocalityDto[]) => {
        this.localities = response;     
      },
      (error) => {        
        console.error('Error adding localities: ', error);        
      }
      );
  }
  getDivisions(){
    this.divisionService.getDivisionsByCementeryId(this.cemetery.code).subscribe(
      (response : IDivisionDto[]) => {
        this.divisions = response;
      });
  }
  openResponseModal(response : IResponseModal): void {
    const dialogRef = this.dialog.open(ResponsemodalComponent, {
      width: '250px',
      data : response,
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
/*   onCemeteryChange(){
    const selectedCemetery = this.cemeteries.find(cem => cem.code === this.sectionmain.cemId);
    if(selectedCemetery){      
      this.divisionService.GetDivisionsByCementeryId(selectedCemetery.code).subscribe(
        (response : IDivisionDto[]) => {
          this.divisions = response;
        })
    }
  }
  onDivisionChange() {         
    const selectedDivision = this.divisions.find(div => div.id === this.sectionmain.divId);
    if (selectedDivision) {
      this.sectionService.GetSectionByDivisionId(selectedDivision.id).subscribe(
        (response : ISectionDto[]) =>{
          this.sections = response;
        }
      )          
    } else {
      this.sections = [];
    }
  } */
  addNewSection(){
    const addNewSectionDto: IAddNewSectionDto = this.sectionmain;   
    this.sectionService.addNewSection(addNewSectionDto).subscribe(
      (response: IResponseModal) => {       
        this.openResponseModal(response);   
      },
      (error) => {            
        console.error('Error adding new section:', error);       
      }
      );
    }
}
