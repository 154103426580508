<h1 mat-dialog-title>Add Role</h1>
<div mat-dialog-content>
  <mat-form-field>
    <input 
      matInput 
      id="roleName" 
      name="roleName" 
      [(ngModel)]="data.roleName" 
      placeholder="Role name" 
      required>
  </mat-form-field>
  
  <mat-form-field>
    <textarea 
      matInput 
      id="description" 
      name="description" 
      [(ngModel)]="data.description" 
      placeholder="Description" 
      required>
    </textarea>
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <button mat-raised-button class="cancel-button" (click)="onNoClick()">Cancel</button>
  <button 
    mat-raised-button 
    color="primary" 
    class="save-button" 
    [mat-dialog-close]="data" 
    cdkFocusInitial 
    (click)="onSaveClick()">Save</button>
</div>
