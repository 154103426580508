<div class="dialog-header">
  <button mat-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="container-popup">

  <div class="form">

    <span class="mat-title" mat-dialog-title><b>Update Burials</b> </span>

    <mat-stepper orientation="horizontal" labelPosition="bottom" #stepper (selectionChange)="checksteps()">

      <mat-step [stepControl]="firstFormGroup" errorMessage="Check invalid fields">

        <form fxLayout="column" [formGroup]="firstFormGroup">
          <ng-template matStepLabel>Fill out Deceased <br>Details</ng-template>
          <mat-dialog-content>
          <div class="box">
            <mat-form-field>
              <mat-label>Identity Card Type</mat-label>
              <mat-select formControlName="idCardTypeCtrl">
                <mat-option [value]= "1">ID Card</mat-option>
                <mat-option [value]= "2">Passport</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Id Card Number</mat-label>              
              <input matInput placeholder="Card Number" formControlName="cardNumberCtrl" />
            </mat-form-field>
          </div>

          <div class="box">
            <mat-form-field>
              <mat-label>Name</mat-label>
              <input matInput placeholder="Name" formControlName="nameCtrl" maxlength="50"/>
              <mat-error>
                <span *ngIf="firstFormGroup.get('nameCtrl')?.value && firstFormGroup.get('nameCtrl')?.invalid && firstFormGroup.get('nameCtrl')?.errors?.['pattern']">Name must contain only letters</span>
                <span *ngIf="!firstFormGroup.get('nameCtrl')?.value">Field required</span>
              </mat-error>   
            </mat-form-field>

            <mat-form-field>
              <mat-label>Surname</mat-label>
              <input matInput placeholder="Surname" formControlName="surnameCtrl" maxlength="50"/>
              <mat-error>
                <span *ngIf="firstFormGroup.get('surnameCtrl')?.value && firstFormGroup.get('surnameCtrl')?.invalid && firstFormGroup.get('surnameCtrl')?.errors?.['pattern']">Surname must contain only letters</span>
                <span *ngIf="!firstFormGroup.get('surnameCtrl')?.value">Field required</span>
              </mat-error>   
            </mat-form-field>
          </div>

          <div class="box">
            <mat-form-field>
              <mat-label>Locality</mat-label>
              <mat-select formControlName="localityCtrl" (selectionChange)="onLocalitySelectionChange()" >
                <mat-option *ngFor="let locality of localities" [value]="locality.id">
                  {{ locality.description }}
                </mat-option>
              </mat-select>
              <mat-error>
                <span *ngIf="!firstFormGroup.get('localityCtrl')?.value">Field required</span>
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Address</mat-label>
              <input matInput placeholder="Address" formControlName="addressCtrl" maxlength="200"/>
              <mat-error>
                <span *ngIf="firstFormGroup.get('addressCtrl')?.value && firstFormGroup.get('addressCtrl')?.invalid && firstFormGroup.get('addressCtrl')?.errors?.['pattern']">Address must contain only numbers or letters</span>
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Profession</mat-label>
              <input matInput placeholder="Profession" formControlName="professionCtrl" maxlength="100"/>
              <mat-error>
                <span *ngIf="firstFormGroup.get('professionCtrl')?.value && firstFormGroup.get('professionCtrl')?.invalid && firstFormGroup.get('professionCtrl')?.errors?.['pattern']">Profession must contain only numbers or letters</span>
              </mat-error>  
            </mat-form-field>
          </div>

          <div class="box">
             <!-- new gender service-->
             <mat-form-field>
              <mat-label>Gender</mat-label>
              <mat-select formControlName="genderCtrl" (selectionChange)="onGenderSelectionChange()">                
                <mat-option *ngFor="let gender of genders" [value]="gender.id">
                  {{ gender.description }}
                </mat-option>
              </mat-select>
              <mat-error>
                <span *ngIf="!firstFormGroup.get('genderCtrl')?.value">Field required</span>
              </mat-error>
            </mat-form-field>
            <!-- end -->
            <mat-form-field>
              <mat-label>Date of Birth</mat-label>
              <input matInput [matDatepicker]="pickerDateOfBirth" formControlName="dateOfBirthCtrl"/>
              <mat-datepicker-toggle matIconSuffix [for]="pickerDateOfBirth">
              </mat-datepicker-toggle>
              <mat-datepicker #pickerDateOfBirth></mat-datepicker>
              <mat-error>
                <span *ngIf="!firstFormGroup.get('dateOfBirthCtrl')?.value">Field required</span>
              </mat-error>   
            </mat-form-field>
            <mat-form-field>
              <mat-label>Place of Birth</mat-label>
              <input matInput placeholder="Place of Birth" formControlName="placeOfBirthCtrl" maxlength="100"/>
              <mat-error>
                <span *ngIf="firstFormGroup.get('placeOfBirthCtrl')?.errors?.['pattern']">Place of birth must contain only numbers or letters</span>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="box">
            <!-- new marital status service-->
            <mat-form-field>
              <mat-label>Marital Status</mat-label>
              <mat-select formControlName="maritalStatusCtrl" (selectionChange)="onMaritalStatusSelectionChange()">                
                <mat-option *ngFor="let status of statuses" [value]="status.id">
                  {{ status.description }}
                </mat-option>
              </mat-select>
              <mat-error>
                <span *ngIf="!firstFormGroup.get('maritalStatusCtrl')?.value">Field required</span>
              </mat-error>
            </mat-form-field>
            <!-- end -->
            <mat-form-field>
              <mat-label>Spouse Name</mat-label>
              <input matInput placeholder="Spouse Name" formControlName="spouseNameCtrl" maxlength="50"/>
              <mat-error>
                <span *ngIf="firstFormGroup.get('spouseNameCtrl')?.value && firstFormGroup.get('spouseNameCtrl')?.invalid && firstFormGroup.get('spouseNameCtrl')?.errors?.['pattern']">Spouse Name must contain only letters</span>
                <span *ngIf="!firstFormGroup.get('spouseNameCtrl')?.value">Field required</span>
              </mat-error> 
            </mat-form-field>
            <mat-form-field>
              <mat-label>Spouse Surname</mat-label>
              <input matInput placeholder="Spouse Name" formControlName="spouseSurnameCtrl" maxlength="50"/>
              <mat-error>
                <span *ngIf="firstFormGroup.get('spouseSurnameCtrl')?.value && firstFormGroup.get('spouseSurnameCtrl')?.invalid && firstFormGroup.get('spouseSurnameCtrl')?.errors?.['pattern']">Spouse Surname must contain only letters</span>
                <span *ngIf="!firstFormGroup.get('spouseSurnameCtrl')?.value">Field required</span>
              </mat-error> 
            </mat-form-field>
          </div>

          <div class="box">
            <mat-form-field>
              <mat-label>Father's Name</mat-label>
              <input  matInput placeholder="Father's Name"  formControlName="fatherNameCtrl" maxlength="50"/>
              <mat-error>
                <span *ngIf="firstFormGroup.get('fatherNameCtrl')?.value && firstFormGroup.get('fatherNameCtrl')?.invalid && firstFormGroup.get('fatherNameCtrl')?.errors?.['pattern']">Father's Name must contain only letters</span>
                <span *ngIf="!firstFormGroup.get('fatherNameCtrl')?.value">Field required</span>
              </mat-error> 
            </mat-form-field>
            <mat-form-field>
              <mat-label>Father's Surname</mat-label>
              <input matInput placeholder="Father's Surname" formControlName="fatherSurnameCtrl" maxlength="50"/>
              <mat-error>
                <span *ngIf="firstFormGroup.get('fatherSurnameCtrl')?.value && firstFormGroup.get('fatherSurnameCtrl')?.invalid && firstFormGroup.get('fatherSurnameCtrl')?.errors?.['pattern']">Father's Surname must contain only letters</span>
                <span *ngIf="!firstFormGroup.get('fatherSurnameCtrl')?.value">Field required</span>
              </mat-error> 
            </mat-form-field>
            <mat-form-field>
              <mat-label> Vital Status</mat-label>
              <mat-select formControlName="fatherStatusCtrl" >
              <mat-option value="Alive">Alive</mat-option>
              <mat-option value="Deceased">Deceased</mat-option>
              <mat-option value="">N/A</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="box">
            <mat-form-field>
              <mat-label>Mother's Name</mat-label>
              <input matInput placeholder="Mother's Name" formControlName="motherNameCtrl" maxlength="50"/>
              <mat-error>
                <span *ngIf="firstFormGroup.get('motherNameCtrl')?.value && firstFormGroup.get('motherNameCtrl')?.invalid && firstFormGroup.get('motherNameCtrl')?.errors?.['pattern']">Mother's Name must contain only letters</span>
                <span *ngIf="!firstFormGroup.get('motherNameCtrl')?.value">Field required</span>
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Mother's Surname</mat-label>
              <input matInput placeholder="Mother's Surname" formControlName="motherSurnameCtrl" maxlength="50"/>
              <mat-error>
                <span *ngIf="firstFormGroup.get('motherSurnameCtrl')?.value && firstFormGroup.get('motherSurnameCtrl')?.invalid && firstFormGroup.get('motherSurnameCtrl')?.errors?.['pattern']">Mother's Surname must contain only letters</span>
                <span *ngIf="!firstFormGroup.get('motherSurnameCtrl')?.value">Field required</span>
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label> Vital Status</mat-label>
              <mat-select formControlName="motherStatusCtrl">
              <mat-option value="Alive">Alive</mat-option>
              <mat-option value="Deceased">Deceased</mat-option>
              <mat-option value="">N/A</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="box">
            <mat-form-field>
              <mat-label>Remarks</mat-label>
              <textarea matInput placeholder="Remarks" formControlName="remarksCtrl"></textarea>
            </mat-form-field> 
          </div>
            <mat-checkbox class="example-margin" 
            [checked]="burialPermitObject.ocdIsReceived === true" 
            (change)="onOdcCheckboxChange($event)">Death Certificate Received</mat-checkbox>
          

          <mat-divider></mat-divider>

          <div class="buttons_panel">
            <div class="back"></div>
            <div></div>
            <div class="next">
              <button mat-mini-fab color="secondary" matStepperNext><mat-icon>arrow_forward_ios&nbsp;</mat-icon></button>
            </div>
          </div>

        </mat-dialog-content>
        </form>
      </mat-step>

      <mat-step [stepControl]="secondFormGroup" errorMessage="Check invalid fields">
        <form [formGroup]="secondFormGroup">
          <ng-template matStepLabel>Fill out Burial Details</ng-template>
          <mat-dialog-content>

          <div class="box">
            <mat-form-field>
              <mat-label>Burial Reference No.</mat-label>
              <input matInput placeholder="Burial Reference No." formControlName="burialRefNoCtrl" maxlength="20"/>
              <mat-error>
                <span *ngIf="secondFormGroup.get('burialRefNoCtrl')?.value && secondFormGroup.get('burialRefNoCtrl')?.invalid && secondFormGroup.get('burialRefNoCtrl')?.errors?.['pattern']">Ref No must contain only numbers or letters</span>
              </mat-error> 
            </mat-form-field>

            <mat-form-field>
              <mat-label>Burial type</mat-label>
              <mat-select formControlName="burialTypeCtrl" (selectionChange)="onBurialTypeChange()">
                <mat-option *ngFor="let burialType of burialTypes" [value]="burialType.id">
                  {{ burialType.description }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="!secondFormGroup.get('burialTypeCtrl')?.value">
                <span>Field required</span>
              </mat-error>  
            </mat-form-field>
          </div>  

          <div class="box">
            <mat-form-field>
              <mat-label>Death Date</mat-label>
              <input matInput formControlName="deathDateCtrl" [matDatepicker]="pickerDeathDate"/>
              <mat-datepicker-toggle  matIconSuffix [for]="pickerDeathDate"></mat-datepicker-toggle>
              <mat-datepicker #pickerDeathDate></mat-datepicker>
              <mat-error *ngIf="!secondFormGroup.get('deathDateCtrl')?.value">
                <span>Field required</span>
              </mat-error>  
            </mat-form-field>

            <mat-form-field>
              <mat-label>Time of Death</mat-label>
              <input type="time" matInput placeholder="Time of Death" formControlName="deathTimeCtrl" />
              <mat-error *ngIf="!secondFormGroup.get('deathTimeCtrl')?.value">
                <span>Field required</span>
              </mat-error>  
            </mat-form-field>                 
          </div>
          <div class="box">
            <mat-form-field>
              <mat-label>Place Of Death</mat-label>
              <input matInput placeholder="Place Of Death" formControlName="placeOfDeathCtrl" [matAutocomplete]="auto">
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
                <mat-option *ngFor="let option of placeDeathOptions | async" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
              <mat-error>
                <span *ngIf="secondFormGroup.get('placeOfDeathCtrl')?.value && secondFormGroup.get('placeOfDeathCtrl')?.invalid && secondFormGroup.get('placeOfDeathCtrl')?.errors?.['pattern']">Place Of Death must contain only numbers or letters</span>
                <span *ngIf="!secondFormGroup.get('placeOfDeathCtrl')?.value">Field required</span>
              </mat-error> 
            </mat-form-field>

            <mat-form-field>
              <mat-label>Cause Of Death</mat-label>
              <input matInput placeholder="Cause Of Death" formControlName="causeOfDeathCtrl" maxlength="100"/>              
              <mat-error>
                <span *ngIf="secondFormGroup.get('causeOfDeathCtrl')?.value && secondFormGroup.get('causeOfDeathCtrl')?.invalid && secondFormGroup.get('causeOfDeathCtrl')?.errors?.['pattern']">Cause of Death must contain only letters</span>
                <span *ngIf="!secondFormGroup.get('causeOfDeathCtrl')?.value">Field required</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="box">
            <mat-form-field>
              <mat-label>Mass Time</mat-label>
              <input type="time" matInput placeholder="Mass Time" formControlName="massTimeCtrl"/>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Mass Locality</mat-label>
              <mat-select formControlName="massLocalityCtrl" (selectionChange)="onMassLocalitySelectionChange()" >
                <mat-option *ngFor="let locality of massLocalities" [value]="locality.id">
                  {{ locality.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <mat-divider></mat-divider>
          <div class="box">
            <mat-form-field>
              <mat-label>Burial Permit No</mat-label>
              <input matInput placeholder="Burial Permit No" formControlName="burialPermitNoCtrl" required/>
              <mat-error>
                <span *ngIf="secondFormGroup.get('burialPermitNoCtrl')?.value && secondFormGroup.get('burialPermitNoCtrl')?.invalid && secondFormGroup.get('burialPermitNoCtrl')?.errors?.['pattern']">Burial Permit Number must contain only numbers</span>
                <span *ngIf="!secondFormGroup.get('burialPermitNoCtrl')?.value">Field required</span>
              </mat-error>  
            </mat-form-field>           
          </div>
          <div class="box">
            <mat-form-field>
              <mat-label>Burial Date</mat-label>
              <input matInput [matDatepicker]="pickerburialDate" formControlName="burialDateCtrl"/>
              <mat-datepicker-toggle  matIconSuffix [for]="pickerburialDate"></mat-datepicker-toggle>
              <mat-datepicker #pickerburialDate></mat-datepicker>
              <mat-error>
                <span *ngIf="!secondFormGroup.get('burialDateCtrl')?.value">Field required</span>
              </mat-error> 
            </mat-form-field>

            <mat-form-field>
              <mat-label>Burial Rights</mat-label>
              <!-- <input matInput placeholder="Burial Rights" formControlName="burialRightsCtrl" maxlength="100"/> -->
              <input matInput placeholder="Burial Rights" formControlName="burialRightsCtrl" [matAutocomplete]="auto1">
              <mat-autocomplete #auto1="matAutocomplete" (optionSelected)="onBurialRightSelected($event)">
                <mat-option *ngFor="let rightOptions of burialRightOptions | async" [value]="rightOptions">
                  {{ rightOptions }}
                </mat-option>
                </mat-autocomplete>
              <mat-error>
                <span *ngIf="secondFormGroup.get('burialRightsCtrl')?.value && secondFormGroup.get('burialRightsCtrl')?.invalid && secondFormGroup.get('burialRightsCtrl')?.errors?.['pattern']">Burial Rights must contain only numbers or letters</span>
              </mat-error>              
            </mat-form-field>
            
          </div>

          <div class="box">
       
            <mat-form-field appearance="fill">
              <mat-label>Undertaker</mat-label>
              <input matInput formControlName="undertakerCtrl" [matAutocomplete]="autoundertaker"/>
              <mat-autocomplete #autoundertaker="matAutocomplete" [displayWith]="displayUndertaker" (optionSelected)="onUndertakerSelectionChange($event.option.value)">
                <mat-option *ngFor="let undertaker of filteredUndertakers | async"  [value]="undertaker.id">
                  <!-- Code: {{ undertaker.code }} | {{ undertaker.surname }} {{ undertaker.name }} -->
                  {{ undertaker.surname }} {{ undertaker.name }}
                </mat-option>
              </mat-autocomplete>
              <mat-error>
                <span *ngIf="!secondFormGroup.get('undertakerCtrl')?.value">Field required</span>
              </mat-error>
            </mat-form-field>
            
            <mat-form-field>
              <mat-label>Permit Issued Date</mat-label>
              <input matInput [matDatepicker]="pickerissuedDate" formControlName="issuedDateCtrl" />
              <mat-datepicker-toggle  matIconSuffix [for]="pickerissuedDate"></mat-datepicker-toggle>
              <mat-datepicker #pickerissuedDate></mat-datepicker>
              <mat-error>
                <span *ngIf="!secondFormGroup.get('issuedDateCtrl')?.value">Field required</span>
              </mat-error> 
            </mat-form-field>
          </div>

          <mat-divider></mat-divider>

          <div class="buttons_panel">
            <div class="back">
              <button mat-mini-fab color="secondary" matStepperPrevious><mat-icon>&nbsp;arrow_back_ios</mat-icon></button>
            </div>
            <div></div>
            <div class="next">
              <button mat-mini-fab color="secondary" matStepperNext><mat-icon>arrow_forward_ios&nbsp;</mat-icon></button>
            </div>
          </div>

        </mat-dialog-content>
        </form>
      </mat-step>

      <mat-step [stepControl]="thirdFormGroup" *ngIf="isStepEditable">
        
        <form [formGroup]="thirdFormGroup">
          
          <ng-template matStepLabel>{{this.stepLabelName}}</ng-template>
          
            <mat-dialog-content>

              <div *ngIf="burialPermitObject.burialTypeId == 2">
                <div class="box">
                  <mat-form-field>
                    <mat-label>Cemetery</mat-label>
                    <mat-select formControlName="cemeteryCtrl" (selectionChange)="onCemeteryChange()">
                      <mat-option *ngFor="let cemetery of cemeteries" [value]="cemetery.code">
                        {{ cemetery.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                </div>
                <div class="box">
                  <mat-form-field>
                    <mat-label>Division</mat-label>
                    <mat-select formControlName="divisionCtrl" (selectionChange)="onDivisionChange()">
                      <mat-option>None</mat-option>
                      <mat-option *ngFor="let division of divisions" [value]="division.id">
                        {{ division.description }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field>
                    <mat-label>Section</mat-label>
                    <mat-select formControlName="sectionCtrl" (selectionChange)="onSectionChange()">
                      <mat-option>None</mat-option>
                      <mat-option *ngFor="let section of sections" [value]="section.id">
                        {{ section.description }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>Compartment</mat-label>
                    <mat-select formControlName="compartmentCtrl" (selectionChange)="onCompartmentChange()">
                      <mat-option>None</mat-option>
                      <mat-option *ngFor="let compartment of compartments" [value]="compartment.id">
                        {{ compartment.description }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field class="example-full-width">
                    <mat-label>Grave No</mat-label>
                    <input type="text"
                           placeholder="Grave No"
                           aria-label="Number"
                           matInput formControlName="graveCtrl" [matAutocomplete]="auto">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="graveNoSelected($event)">
                      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                        {{ option }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>

                </div>
              
                <!-- FILTER -->
                <div class="box">
                  <mat-form-field id="filter_table">
                    <mat-label>Filter</mat-label>
                    <input matInput (keyup)="applyFilter($event)"placeholder="Ex. Grave Reference Number" #input/>
                  </mat-form-field>
                </div>

                <!-- TABLE -->
                <div class="table-table-container mat-elevation-z8" id="tablecontent">
                  <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>
                        <!-- ID Column -->
                        <ng-container matColumnDef="id">
                          <th mat-header-cell *matHeaderCellDef >ID</th>
                          <td mat-cell *matCellDef="let grave">{{ grave.id }}</td>
                        </ng-container>

                        <!-- Grave No Column -->
                        <ng-container matColumnDef="graveNo">
                          <th mat-header-cell *matHeaderCellDef >Grave No</th>
                          <td mat-cell *matCellDef="let grave">{{ grave.graveNo }} </td>
                        </ng-container>

                        <!-- Grave ref no Column -->
                        <ng-container matColumnDef="referenceNumber">
                          <th mat-header-cell *matHeaderCellDef >Ref No</th>
                          <td mat-cell *matCellDef="let grave">{{ grave.referenceNumber }} </td>
                        </ng-container>

                        <!-- Grave type Column -->
                        <ng-container matColumnDef="dhFileNo">
                          <th mat-header-cell *matHeaderCellDef  fxShow="true" fxHide.lt-md>Dh File No</th>
                          <td mat-cell *matCellDef="let grave" fxShow="true" fxHide.lt-md>{{ grave.dhFileNo }}</td>
                        </ng-container>

                        <!-- Grave level -->
                        <ng-container matColumnDef="compartment_id">
                          <th mat-header-cell *matHeaderCellDef  fxShow="true" fxHide.lt-md> Compartment </th>
                          <td mat-cell *matCellDef="let grave" fxShow="true" fxHide.lt-md>{{ grave.compartmentName }}</td>
                        </ng-container>

                        <!-- Last burial date -->
                        <ng-container matColumnDef="isActive">
                          <th mat-header-cell *matHeaderCellDef  fxShow="true" fxHide.lt-md> Active </th>
                          <td mat-cell *matCellDef="let grave" fxShow="true" fxHide.lt-md>{{ grave.isActive }} </td>
                        </ng-container>

                        <!-- gravesize-->
                        <ng-container matColumnDef="gravesize">
                          <th mat-header-cell *matHeaderCellDef   fxShow="true" fxHide.lt-md>Size</th>
                          <td mat-cell *matCellDef="let grave" fxShow="true" fxHide.lt-md>{{ grave.gravesize }}</td>
                        </ng-container>

                        <!--OWNERS NAME-->
                    <!-- firstOwner Name & Surname-->
                    <ng-container matColumnDef="firstOwner">
                      <th mat-header-cell *matHeaderCellDef   fxShow="true" fxHide.lt-md>First Owner </th>
                      <td mat-cell *matCellDef="let grave" fxShow="true" fxHide.lt-md>{{ grave.firstOwner }}</td>
                    </ng-container>
                    <ng-container matColumnDef="expand">
                      <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                      <td mat-cell *matCellDef="let grave">
                        <button mat-icon-button aria-label="expand row"  (click)="expandCollapse(grave); $event.stopPropagation()" >
                          <ng-container *ngIf="expandedElement === grave">
                              <mat-icon>keyboard_arrow_up</mat-icon>
                          </ng-container>
                          <ng-container *ngIf="expandedElement !== grave">
                              <mat-icon>keyboard_arrow_down</mat-icon>
                          </ng-container>
                      </button>
                      </td>
                    </ng-container>
                      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                      <ng-container matColumnDef="expandedDetail">
                        <td mat-cell *matCellDef="let grave" [attr.colspan]="displayedColumnsWithExpand.length">
                          <div class="expand-detail" [@detailExpand] = "grave == expandedElement ? 'expanded' : 'collapsed' ">
                            <div class="expand-category">
                              <div class="expand-field">Second Owner : &nbsp;<span>{{grave.secondOwner}}</span>
                              Third Owner : &nbsp;<span>{{grave.thirdOwner}}</span></div>
                            </div>
                          </div>
                        </td>
                      </ng-container>
                        <!-- Action -->
                        <ng-container matColumnDef="select">
                          <th mat-header-cell *matHeaderCellDef>Select</th>
                          <td mat-cell *matCellDef="let grave">
                            <mat-checkbox  class="example-margin"(change)="onCheckboxChange($event, grave)" [checked]="isSelected(grave)">
                            </mat-checkbox>
                          </td>
                        </ng-container>


                        <tr mat-header-row *matHeaderRowDef="displayedColumnsWithExpand"></tr>
                        <tr mat-row *matRowDef="let grave; columns: displayedColumnsWithExpand;" class="expand-Inside"
                          [class.example-expanded-row]="expandedElement != grave" ></tr>
                          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" matRipple class="expand-Rowdetails"></tr>        

                    <!-- Row shown when there is no matching data. -->
                    <tr class="mat-row" *matNoDataRow>
                      <td class="mat-cell" colspan="4">No data matching the filter "{{ input.value }}"</td>
                    </tr>
                  </table>
                </div>
                <mat-paginator class="paginator" [pageSizeOptions]="[50, 100,200]" [length]="resultsLength" (page)="pageEvent($event)" aria-label="Select page"></mat-paginator>
              </div>

              <div *ngIf="burialPermitObject.burialTypeId == 4 ||burialPermitObject.burialTypeId == 1 ">

                <div class="box">
                  <mat-form-field>
                    <mat-label>Country</mat-label>
                    <mat-select formControlName="countryCtrl"  (selectionChange)="onCountrySelectionChange($event.value)" >
                      <mat-option *ngFor="let country of countries" [value]="country.id">
                        {{ country.name }}
                      </mat-option>
                    </mat-select>                  
                  </mat-form-field>
  
                  <mat-form-field>
                    <mat-label>Location of Burial</mat-label>
                    <input matInput placeholder="Location" formControlName="burialLocationCtrl" maxlength="100"/>
                  </mat-form-field>
                 
                </div>
  
              </div>
              
              <div *ngIf="burialPermitObject.burialTypeId == 3">

                <div class="box">
                  <mat-form-field>
                    <mat-label>Location of Burial</mat-label>
                    <input matInput placeholder="Location of Burial" formControlName="burialLocationCtrl" maxlength="100"/>
                  </mat-form-field>
  
                  <mat-form-field>
                    <mat-label>Environmental Health Officer</mat-label>
                    <input matInput placeholder="Environmental Health Officer" formControlName="healthOfficerCtrl" maxlength="50"/>
                  </mat-form-field>
                </div>
  
              </div>
              
              <mat-divider></mat-divider>

              <div class="buttons_panel">
                <div class="back">
                  <button mat-mini-fab color="secondary" matStepperPrevious><mat-icon>&nbsp;arrow_back_ios</mat-icon></button>
                </div>
                <div></div>
                <div class="next">
                  <button mat-mini-fab color="secondary" matStepperNext><mat-icon>arrow_forward_ios&nbsp;</mat-icon></button>
                </div>
              </div>
              
            </mat-dialog-content>
        </form>
      </mat-step>

      <mat-step>
        <ng-template matStepLabel>Done</ng-template>        

        <div *ngIf="!allStepsValid" id="error">
          <p>INVALID FIELDS, CHECK PREVIOUS STEPS.</p>
        </div>
        
        <mat-divider></mat-divider>
          
        <div class="buttons_panel">
          <div class="back">
            <button mat-mini-fab color="secondary" matStepperPrevious><mat-icon>&nbsp;arrow_back_ios</mat-icon></button>
          </div>
          <div></div>
          <div class="generate">
            <button mat-raised-button color="primary" (click)="burialUpdateClick()" [disabled]="!allStepsValid"><mat-icon>done</mat-icon>UPDATE</button>
          </div>
        </div>

      </mat-step>

    </mat-stepper>
  </div>
</div>