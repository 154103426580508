import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GraveOwnersEditComponent } from './grave-owners-edit/grave-owners-edit.component';
import { GraveOwnersViewComponent } from './grave-owners-view/grave-owners-view.component';
import { GraveOwnerAddComponent } from './grave-owner-add/grave-owner-add.component';

export interface IGraveOwnershipSample{
  grave_id? : IGraveOwnerSample[];
  owner_id? : IGraveOwnerSample[];
  is_main_owner:boolean;
  ownership_type_id:number;
  is_active:boolean;
  DHfileNo ? : IGraveOwnerSample[];
  referenceNo:number;
  
  
}
export interface IGraveOwnerSample{
  id:string;
  Gname:string;
  Gsurname:string;
  address:string;
  locality:string;
  telephone:number;
  cemeteryName:string;
  GNo:number;
  DHfileNo :string;
  referenceNo:number;
  dateOfApplication:Date;
  ownershipDate: Date;
  grave_id: number;
  owner_id:string

}

@Component({
  selector: 'app-grave-owners',
  templateUrl: './grave-owners.component.html',
  styleUrls: ['./grave-owners.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class GraveOwnersComponent implements AfterViewInit{
  resultsLength = 0;
  // isLoadingResults = true;
  isRateLimitReached = false;
  ownershipObject!: IGraveOwnershipSample;
  passObj!: IGraveOwnershipSample;
  ownerObj ! : IGraveOwnerSample;

  displayedColumns: string[] = [ 'id', 'Gname','Gsurname','DHfileNo','OwnershipDate','telephone', 'expand','action'];
  dataSource: MatTableDataSource<IGraveOwnerSample>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  displayedColumnsWithExpand = [...this.displayedColumns];
  expandedElement!: IGraveOwnerSample[] | null;
 constructor(
  public dialog: MatDialog,
  private datePipe: DatePipe, 
 ){
  const ownerObj :IGraveOwnerSample[] =[
  {
  id:'12',
  Gname:'abc',
  Gsurname:'def',
  address:'aaaaa',
  locality:'Paola',
  telephone:9792892,
  cemeteryName:'njnb',
  GNo:109,
  DHfileNo :'1222/41',
  referenceNo:1233,
  dateOfApplication:new Date(),
  ownershipDate: new Date(),
  grave_id:133333,
  owner_id :'123A',
},
  ] 
  this.dataSource = new MatTableDataSource(ownerObj);

 }
 ngAfterViewInit() {
  this.dataSource.paginator = this.paginator;
  this.dataSource.sort = this.sort;
  
}
applyFilter(event: Event) {
   const filterValue = (event.target as HTMLInputElement).value;
   this.dataSource.filter = filterValue.trim().toLowerCase();

  if (this.dataSource.paginator) {
    this.dataSource.paginator.firstPage();
  }
}
editGraveOwnerClick(ownerObject:IGraveOwnerSample){
  const dialogRef = this.dialog.open(GraveOwnersEditComponent, {
      
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');
  });

}

viewGraveOwnerClick(ownerObject:IGraveOwnerSample){
  let ownershipObject!:IGraveOwnerSample;
  const dialogRef = this.dialog.open(GraveOwnersViewComponent , {
     data: {grave_id: ownerObject.grave_id,
            owner_id: ownerObject.owner_id,
            Gname: ownerObject.Gname,
            Gsurname: ownerObject.Gsurname,
            referenceNo:ownerObject. referenceNo,
     }  
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');
  });
}
NewGraveOwners(){
  const dialogRef = this.dialog.open(GraveOwnerAddComponent, {});

    dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');
});

}
formatDate(date: Date): string {
  return this.datePipe.transform(date, 'dd/MM/yyyy') || ''; 
}
expandCollapse(ownerObject: any): void {
  this.expandedElement = this.expandedElement === ownerObject ? null : ownerObject;
}
}