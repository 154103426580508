<div class="dialog-header">
  <button mat-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="container-popup1">
    <div class="form1">
        <!-- <mat-tab-group animationDuration="0ms"> -->
          <mat-tab-group class="fixed-size-tab-group" animationDuration="0ms">
            <mat-tab class="fixed-size-tab" label="Owners">
            <!-- <mat-tab label="Owners"> -->
                <app-ownership [grave] = "grave"></app-ownership>
            </mat-tab>

            <mat-tab label="Burials">
                <form [formGroup]="burialsFormGroup">
                  <div class="box">
                    <mat-form-field appearance="outline" class="full-width">
                      <mat-label>Grave Information</mat-label>
                      <input
                        matInput
                        [value]="(grave.cemeteryName?.trim() || '') + ' - ' +
                                  (grave.divisionName?.trim() || '') + ' - ' +
                                  (grave.sectionName?.trim() || '') + ' - ' +
                                  (grave.compartmentName?.trim() || '') + ' - ' +
                                  ((grave.graveNo ? grave.graveNo.toString().trim() : '') || '')"
                        readonly
                      />
                    </mat-form-field>
                  </div>
                    <mat-dialog-content>                           
                      <app-burial-permit-details-view [graveId] = "graveId"></app-burial-permit-details-view>                        
                    </mat-dialog-content>
                </form>
            </mat-tab>

            <mat-tab label="Last Burial">
              <div class="box">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Grave Information</mat-label>
                  <input
                    matInput
                    [value]="(grave.cemeteryName?.trim() || '') + ' - ' +
                                  (grave.divisionName?.trim() || '') + ' - ' +
                                  (grave.sectionName?.trim() || '') + ' - ' +
                                  (grave.compartmentName?.trim() || '') + ' - ' +
                                  ((grave.graveNo ? grave.graveNo.toString().trim() : '') || '')"
                    readonly
                  />
                </mat-form-field>
              </div>
              <mat-dialog-content>
                <app-burial-lastburial [graveId] = "graveId"></app-burial-lastburial>
              </mat-dialog-content>         
            </mat-tab>

            <mat-tab label="Cleaning & Transport">
              <div class="box">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Grave Information</mat-label>
                  <input
                    matInput
                    [value]="(grave.cemeteryName?.trim() || '') + ' - ' +
                                  (grave.divisionName?.trim() || '') + ' - ' +
                                  (grave.sectionName?.trim() || '') + ' - ' +
                                  (grave.compartmentName?.trim() || '') + ' - ' +
                                  ((grave.graveNo ? grave.graveNo.toString().trim() : '') || '')"
                    readonly
                  />
                </mat-form-field>
              </div>
              <form [formGroup]="cleaningFormGroup">
                  <!-- <mat-dialog-content> -->
                     <app-cleaning [graveId] = "graveId"></app-cleaning>
                  <!-- </mat-dialog-content> -->
              </form>
            </mat-tab>

            <mat-tab label="Level Status">
              <div class="box">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Grave Information</mat-label>
                  <input
                    matInput
                    [value]="(grave.cemeteryName?.trim() || '') + ' - ' +
                                  (grave.divisionName?.trim() || '') + ' - ' +
                                  (grave.sectionName?.trim() || '') + ' - ' +
                                  (grave.compartmentName?.trim() || '') + ' - ' +
                                  ((grave.graveNo ? grave.graveNo.toString().trim() : '') || '')"
                    readonly
                  />
                </mat-form-field>
              </div>
                <mat-dialog-content>
                  <app-grave-level-update [graveId] = "graveId"></app-grave-level-update>
                </mat-dialog-content>         
            </mat-tab>

            <mat-tab label="Remarks">
              <div class="box">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Grave Information</mat-label>
                  <input
                    matInput
                    [value]="(grave.cemeteryName?.trim() || '') + ' - ' +
                                  (grave.divisionName?.trim() || '') + ' - ' +
                                  (grave.sectionName?.trim() || '') + ' - ' +
                                  (grave.compartmentName?.trim() || '') + ' - ' +
                                  ((grave.graveNo ? grave.graveNo.toString().trim() : '') || '')"
                    readonly
                  />
                </mat-form-field>
      
              </div>        
              <app-remarks-per-compartment [graveId] = "graveId"></app-remarks-per-compartment>
            </mat-tab>

            <mat-tab label="Files">
              <div class="box">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Grave Information</mat-label>
                  <input
                    matInput
                    [value]="(grave.cemeteryName?.trim() || '') + ' - ' +
                                  (grave.divisionName?.trim() || '') + ' - ' +
                                  (grave.sectionName?.trim() || '') + ' - ' +
                                  (grave.compartmentName?.trim() || '') + ' - ' +
                                  ((grave.graveNo ? grave.graveNo.toString().trim() : '') || '')"
                    readonly
                  />
                </mat-form-field>
              </div>
              <mat-dialog-content>
                <app-fileupload [graveId]="graveId"></app-fileupload>
              </mat-dialog-content>
            </mat-tab>

        </mat-tab-group>
    </div>
</div>
