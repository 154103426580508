import { Component, Inject, OnInit, ChangeDetectionStrategy, signal, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DisintermentComponent } from './disinterment/disinterment.component';
import { IDisintermentDto } from '../models/DisintermentDto.model';
import { MatDialog } from '@angular/material/dialog';
import { ReportingService } from '../services/reporting.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DivisionService } from '../services/division.service';
import { SectionService } from '../services/section.service';
import { CompartmentService } from '../services/compartment.service';
import { IDivisionDto } from '../models/DivisionDto.model';
import { ISectionDto } from '../models/SectionDto.model';
import { ICompartmentDto } from '../models/CompartmentDto.model';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { BurialPermitService } from '../services/burial-permit.service';
import { IBurialPermitDto } from '../burials/models/BurialPermitDto.model';
import { BurialsReportComponent } from './burials/burials.component';
import { IPreViewBurialPermit } from '../models/PreViewBurialPermit.model';
import { LocalityService } from '../services/locality.service';
import { UndertakerService } from '../services/undertaker.service';
import { ILocalityDto } from '../models/LocalityDto.model';
import { IUndertakerDto } from '../models/UndertakerDto.model';
import { OdcComponent } from './odc/odc.component';
import { GenderService } from '../services/gender.service';
import { IGenderDto } from 'src/app/models/Gender.model';
import { ICleaningDto } from '../models/CleaningDto.model';
import { IPreViewCleaningsDto } from '../models/PreViewCleaningDto.model';
import { GraveService } from '../services/grave.service';
import { IGraveDto } from '../models/GraveDto.model';
import { GraveCleanTransTemplateComponent } from '../cemetery/grave/grave-cleantrans-template/grave-cleantrans-template.component';
import { CleaningComponent } from '../maintenance/cleaning/cleaning.component';
import { CemeteryService } from '../services/cemetery.service';
import { ICemeteryDto } from '../models/CemeteryDto.model';
import { BurialsIssuedComponent } from './burials-issued/burials-issued.component';
import { IBurialTypeDto } from '../models/BurialTypeDto.model';
import { BurialTypeService } from '../services/burial-type.service';

export const MY_FORMATS = {
parse: {
  dateInput: 'L',
},
display: {
  dateInput: 'L',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
},
};

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.css'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {showError: true},
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-GB'
    },
    {
      provide: DateAdapter, useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_FORMATS
    },
  ],
})

export class ReportingComponent implements OnInit{

  retrieving_data: boolean | null = null;

  burialFormGroup!: FormGroup;
  showPrintOptions_burial: boolean = false;
  burial_data_csv: any[] = [];
  burial_cemeteryId!: number;
  burial_dateFrom!: Date;
  burial_dateTo!: Date;

  burialsIssuedFormGroup!: FormGroup;
  showPrintOptions_burialsIssued: boolean = false;
  burialsIssued_data_csv: any[] = [];
  burialsIssued_cemeteryId!: number;
  burialsIssued_dateFrom!: Date;
  burialsIssued_dateTo!: Date;

  disintermentFormGroup!: FormGroup;
  
  divisions: IDivisionDto[] = [];
  sections: ISectionDto[] = [];
  compartments: ICompartmentDto[] = [];
  cemeteryId!: number;
  divisionId!: number;
  divisionName!: string;
  sectionId!: number;
  sectionName!: string;
  compartmentId!: number;
  compartmentName!: string;
  graveFrom!: number;
  graveTo!: number;
  dateLimit!: Date;
  uniqueUndertakers: string[] = [];
  undertaker!: string;

  disinterment_list: IDisintermentDto[] = [];
  showPrintOptions_disinterment: boolean = false;

  columns_csv: string[] = [];
  data_csv:  IDisintermentDto[] = [];
  ocd_data_csv: any[] = [];

  cleaningTransportFormGroup!: FormGroup;
  cleaningTransport_data_csv: any[] = [];
  cleaningTransport_cemeteryId!: number;
  cleaningTransport_dateFrom!: Date;
  cleaningTransport_dateTo!: Date;
  showPrintOptions_cleaningTransport: boolean = false;

  odcFormGroup!: FormGroup;
  odc_cemeteryId!: number;
  odc_dateFrom!: Date;
  odc_dateTo!: Date;
  showPrintOptions_odc: boolean = false;

  originalCemeteries!:ICemeteryDto[];
  originalDivisions!:IDivisionDto[];
  originalSections!:ISectionDto[];
  originalComaprtments!:ICompartmentDto[];
  graveDetails!: IGraveDto;
  
  cemeteries : ICemeteryDto[] = [];
  massLocalities :  ILocalityDto[] = [];
  undertakers : IUndertakerDto[]=[];
  genders : IGenderDto[] = [];
  burialTypes: IBurialTypeDto[]=[];
  burialPermits!: IBurialPermitDto[];
  burialPermitPreview: IPreViewBurialPermit[] = [{

    // STEP 1
    idCardNumber:'',
    name: '',
    surname: '',
    locality:'',
    address : '',
    profession : '',
    dateOfBirth: new Date(),
    placeOfBirth: '',
    maritalStatus : '',
    gender:'',
    spouseName: '',
    spouseSurname: '',
    fatherName: '',
    fatherSurname: '',
    fatherStatus: '',
    motherName: '',
    motherSurname: '',
    motherStatus : '',    
    
    // STEP 2
    burialType: '',
    burialTypeId: 0,
    dateOfDeath: new Date(),
    timeOfDeath: '',
    placeOfDeath: '',
    causeOfDeath: '',
    massTime: '',
    massLocality: '', 
    dateOfBurial: new Date(), 
    burialRight : '',
    undertaker : '',    
    issuedDate: new Date(new Date().setDate(new Date().getDate() + 1)), 

    // STEP 3
    cemeteryName: '',
    divisionName: '',
    sectionName: '',
    compartmentName: '',
    graveNo:'',
    levelName: '',      
    username:'',
    country:'',
    burialLocation: '',
    healthOfficer: '',

    //PERMITNO
    permitNo: 'preview',
    remarks:'',
      
  }];
  cleanings :ICleaningDto[] = [];
  cleanTransPreview : IPreViewCleaningsDto[] = [{
    cleaningPermitNumber: '',
    applicationDate : new Date(),
    supervisorName : '',
    supervisorSurname : '',
    //officerBauName: '',
    //officerBauSurname: '',
    cleaningDate : new Date(),
    undertakerName : '',
    undertakerSurname : '',
  
    graveAddressCemetery: '',
    graveAddressDivision: '',
    graveAddressSection: '',
    graveAddressCompartment: '',
    graveAddressNumber: '',
    graveAddressLevel: '',
  
    cleaningDeceasedName : '',
    cleaningDeceasedSurname : '',
    marbleSlab : undefined,
    isFamilyPresent : undefined,
    feePaid : undefined,
  
    transportPreview: {
      transportDeceasedName: '',
      transportDeceasedSurname: '',
      transportToCemetery: '',
      transportToDivision: '',
      transportToSection: '',
      transportToCompartment: '',
      transportToNumber: '',
      transportType: 0,
      transportToCountry: ''
    },
  }];


  @ViewChild(MatExpansionModule) expansionModule!: MatExpansionModule;
  @ViewChild(MatDatepickerModule) datepicker!: MatDatepickerModule;
  @ViewChild(MatButtonModule) buttonModule!: MatButtonModule;
  @ViewChild(MatFormFieldModule) formFieldModule!: MatFormFieldModule;
  @ViewChild(MatIconModule) iconModule!: MatIconModule;
  @ViewChild(MatInputModule) inputModule!: MatInputModule;
  @ViewChild(DisintermentComponent) disintermentComponent!: DisintermentComponent;

  constructor(public dialog: MatDialog,
              private reportingService : ReportingService,
              private _formBuilder: FormBuilder,
              private divisionService : DivisionService,
              private sectionService : SectionService,
              private compartmentService : CompartmentService,
              private burialpermitService: BurialPermitService,
              private localityService: LocalityService,
              private undertakersService: UndertakerService,
              private genderService : GenderService,
              private burialTypeService: BurialTypeService,
              private graveService: GraveService,
              private cemeteryService: CemeteryService 
              ) {}
  
  ngOnInit(): void {

    this.burialFormGroup = this._formBuilder.group({
      burial_cemeteryCtrl: [this.burial_cemeteryId ? this.burial_cemeteryId: '', [Validators.required,Validators.min(1)]],
      burial_dateFromCtrl: [this.burial_dateFrom ? this.burial_dateFrom: '', [Validators.required]],
      burial_dateToCtrl: [this.burial_dateTo ? this.burial_dateTo: '', [Validators.required]]
    });

    this.burialsIssuedFormGroup = this._formBuilder.group({
      burialsIssued_cemeteryCtrl: [this.burialsIssued_cemeteryId ? this.burialsIssued_cemeteryId: '', [Validators.required,Validators.min(1)]],
      burialsIssued_dateFromCtrl: [this.burialsIssued_dateFrom ? this.burialsIssued_dateFrom: '', [Validators.required]],
      burialsIssued_dateToCtrl: [this.burialsIssued_dateTo ? this.burialsIssued_dateTo: '', [Validators.required]]
    });

    this.disintermentFormGroup = this._formBuilder.group({
      cemeteryCtrl: [this.cemeteryId ? this.cemeteryId: '', [Validators.required,Validators.min(1)]],
      divisionCtrl: [this.divisionId ? this.divisionId: '', [Validators.required,Validators.min(1)]],
      sectionCtrl: [this.sectionId ? this.sectionId: '', [Validators.required,Validators.min(1)]],
      compartmentCtrl: [this.compartmentId ? this.compartmentId: '', [Validators.required,Validators.min(1)]],     
      graveFromCtrl: [this.graveFrom ? this.graveFrom: '', [Validators.required,Validators.min(0)]],  
      graveToCtrl: [this.graveFrom ? this.graveFrom: '', [Validators.required,Validators.min(0)]],
      dateLimitCtrl: [this.dateLimit ? this.dateLimit: '', [Validators.required]],
      undertakerCtrl: [this.undertaker ? this.undertaker: '']
    });

    this.cleaningTransportFormGroup = this._formBuilder.group({
      cleaningTransport_cemeteryCtrl: [this.cleaningTransport_cemeteryId ? this.cleaningTransport_cemeteryId: '', [Validators.required,Validators.min(1)]],
      cleaningTransport_dateFromCtrl: [this.cleaningTransport_dateFrom ? this.cleaningTransport_dateFrom: '', [Validators.required]],
      cleaningTransport_dateToCtrl: [this.cleaningTransport_dateTo ? this.cleaningTransport_dateTo: '', [Validators.required]]
    });

    this.odcFormGroup = this._formBuilder.group({
      odc_cemeteryCtrl: [this.odc_cemeteryId ? this.odc_cemeteryId: '', [Validators.required,Validators.min(1)]],
      odc_dateFromCtrl: [this.odc_dateFrom ? this.odc_dateFrom: '', [Validators.required]],
      odc_dateToCtrl: [this.odc_dateTo ? this.odc_dateTo: '', [Validators.required]]
    });

    this.suscribesChanges();
    this.getAllCemeteries();
    this.getAllDivisions(); 
    this.getAllSections();
    this.getAllCompartments(); 
    this.getAllLocalities();
    this.getAllUndertakers();
    this.getAllGender();
    this.getAllBurialTypes();

  }

  suscribesChanges(){

    //BURIAL REPORT
    this.burialFormGroup.get('burial_cemeteryCtrl')?.valueChanges.subscribe((value) => {     
      this.burial_cemeteryId = value;
      this.showPrintOptions_burial = false;
    });
    this.burialFormGroup.get('burial_dateFromCtrl')?.valueChanges.subscribe((value) => {     
      this.burial_dateFrom= value._d;
      this.showPrintOptions_burial = false;
    });
    this.burialFormGroup.get('burial_dateToCtrl')?.valueChanges.subscribe((value) => {     
      this.burial_dateTo= value._d;
      this.showPrintOptions_burial = false;
    });

    //BURIALS ISSUED REPORT
    this.burialsIssuedFormGroup.get('burialsIssued_cemeteryCtrl')?.valueChanges.subscribe((value) => {     
      this.burialsIssued_cemeteryId = value;
      this.showPrintOptions_burialsIssued = false;
    });
    this.burialsIssuedFormGroup.get('burialsIssued_dateFromCtrl')?.valueChanges.subscribe((value) => {     
      this.burialsIssued_dateFrom= value._d;
      this.showPrintOptions_burialsIssued = false;
    });
    this.burialsIssuedFormGroup.get('burialsIssued_dateToCtrl')?.valueChanges.subscribe((value) => {     
      this.burialsIssued_dateTo= value._d;
      this.showPrintOptions_burialsIssued = false;
    });

    //DISINTERMENT REPORT
    this.disintermentFormGroup.get('cemeteryCtrl')?.valueChanges.subscribe((value) => {     
      this.cemeteryId = value;
      this.showPrintOptions_disinterment = false;
    });

    this.disintermentFormGroup.get('divisionCtrl')?.valueChanges.subscribe((value) => {     
      this.divisionId = value;
      this.showPrintOptions_disinterment = false;
    });

    this.disintermentFormGroup.get('sectionCtrl')?.valueChanges.subscribe((value) => {     
      this.sectionId = value;
      this.showPrintOptions_disinterment = false;
    });

    this.disintermentFormGroup.get('compartmentCtrl')?.valueChanges.subscribe((value) => {     
      this.compartmentId = value;
      this.showPrintOptions_disinterment = false;
    });

    this.disintermentFormGroup.get('graveFromCtrl')?.valueChanges.subscribe((value) => {     
      this.graveFrom= value;
      this.showPrintOptions_disinterment = false;
    });

    this.disintermentFormGroup.get('graveToCtrl')?.valueChanges.subscribe((value) => {     
      this.graveTo= value;
      this.showPrintOptions_disinterment = false;
    });

    this.disintermentFormGroup.get('dateLimitCtrl')?.valueChanges.subscribe((value) => {     
      this.dateLimit= value._d;
      this.showPrintOptions_disinterment = false;
    });

    this.disintermentFormGroup.get('undertakerCtrl')?.valueChanges.subscribe((value) => {     
      this.undertaker= value;
    });

    //ODC REPORT
    this.odcFormGroup.get('odc_cemeteryCtrl')?.valueChanges.subscribe((value) => {     
      this.odc_cemeteryId = value;
      this.showPrintOptions_odc = false;
    });
    this.odcFormGroup.get('odc_dateFromCtrl')?.valueChanges.subscribe((value) => {     
      this.odc_dateFrom= value._d;
      this.showPrintOptions_odc = false;
    });
    this.odcFormGroup.get('odc_dateToCtrl')?.valueChanges.subscribe((value) => {     
      this.odc_dateTo= value._d;
      this.showPrintOptions_odc = false;
    });

    //CLEANING & TRANSPORT REPORT
    this.cleaningTransportFormGroup.get('cleaningTransport_cemeteryCtrl')?.valueChanges.subscribe((value) => {     
      this.cleaningTransport_cemeteryId = value;
      this.showPrintOptions_cleaningTransport = false;
    });
    this.cleaningTransportFormGroup.get('cleaningTransport_dateFromCtrl')?.valueChanges.subscribe((value) => {     
      this.cleaningTransport_dateFrom= value._d;  
      this.showPrintOptions_cleaningTransport = false;
    });
    this.cleaningTransportFormGroup.get('cleaningTransport_dateToCtrl')?.valueChanges.subscribe((value) => {     
      this.cleaningTransport_dateTo= value._d;
      this.showPrintOptions_cleaningTransport = false;
    });
  }

  disablePrintOptions(){
    this.showPrintOptions_burial = false;
    //this.showPrintOptions_burialIssued = false;
    this.showPrintOptions_disinterment = false;
    this.showPrintOptions_cleaningTransport = false;
    this.showPrintOptions_odc = false;
  }

  getAllCemeteries(){        
    this.cemeteryService.getAllCemeteries().subscribe( 
      (response : ICemeteryDto[])=>{
        this.originalCemeteries = response;
        this.cemeteries = response;            
      }, (error) =>{
        console.error('error retieving cemeteries service:', error);
      }) 
  }

  getAllDivisions(){
    this.divisionService.getAllDivisions().subscribe( 
      (response : IDivisionDto[])=>{
        this.originalDivisions = response;
        //this.divisions = response;
      }, (error) =>{
        console.error('error retieving divisions service:', error);
      }) 
  }

  getAllSections(){
    this.sectionService.getAllSections().subscribe( 
      (response : ISectionDto[])=>{
        this.originalSections = response;
        //this.sections = response;
      }, (error) =>{
        console.error('error retieving sections service:', error);
      }) 
  }
  
  getAllCompartments(){
    this.compartmentService.getAllCompartments().subscribe( 
      (response : ICompartmentDto[])=>{
        this.originalComaprtments = response;
        //this.compartments = response;
      }, (error) =>{
        console.error('error retieving compartment service:', error);
      }) 
  }

  getAllLocalities(){
    this.localityService.getAllLocalities().subscribe(
      (response: ILocalityDto[]) => {
        this.massLocalities = response; 
      },
      (error) => {        
        console.error('Error adding localities: ', error);        
      }
      );
  }

  getAllUndertakers(){
    this.undertakersService.getAllUndertakers().subscribe(
      (response : IUndertakerDto[]) => {
        this.undertakers = response;
      }, (error) =>{
        console.error('Error retrieving undertakers');
      } 
      );
  }

  getAllGender(){
    this.genderService.getAllGender().subscribe(
      (response: IGenderDto[]) => {
        this.genders = response;           
      },
      (error) => {        
        console.error('Error retrieving genders: ', error);        
      }
      );
  }

  getAllBurialTypes(){
    this.burialTypeService.getAllBurialTypes().subscribe(
      (response: IBurialTypeDto[]) => {
        this.burialTypes = response; 
        },
        (error) => {        
          console.error('Error retrieving burial types: ', error);        
        }
        );
  }

  // DISINTERMENT REPORT

  onCemeteryChange(cemeteryId:number){     
    this.showPrintOptions_disinterment = false;      
    const selectedCemetery = this.originalCemeteries.find(cem => cem.code === cemeteryId);      
    if(selectedCemetery){        
      this.divisionService.getDivisionsByCementeryId(selectedCemetery.code).subscribe(
        (response : IDivisionDto[]) => {
          this.divisions = response;
          this.sections = [];
          this.compartments = [];
        })
    } else {
      this.divisions = [];
      this.sections = [];
      this.compartments = [];
    }
  }

  onDivisionChange() {  
    this.showPrintOptions_disinterment = false;       
    const selectedDivision = this.divisions.find(div => div.id === this.divisionId);
    this.divisionName = selectedDivision ? selectedDivision.description : '';
    if (selectedDivision) {
      this.sectionService.GetSectionByDivisionId(selectedDivision.id).subscribe(
        (response : ISectionDto[]) =>{
          this.sections = response;
        }
      )              
    } else {
      this.sections = [];
    }
  }
  
  onSectionChange() {      
    this.showPrintOptions_disinterment = false;   
    const selectedSection = this.sections.find(sec => sec.id === this.sectionId);
    this.sectionName = selectedSection ? selectedSection.description : '';
    if (selectedSection) {
      this.compartmentService.GetCompartmentBySectionId(selectedSection.id).subscribe(
        (response : ICompartmentDto[]) => {
          this.compartments = response;
        }
      )      
    } else {
      this.compartments = [];
    }
  }
  
  onCompartmentChange() {     
    this.showPrintOptions_disinterment = false;    
    const selectedCompartment = this.compartments.find(com => com.id === this.compartmentId);
    this.compartmentName = selectedCompartment ? selectedCompartment.description : '';
  }

  getUniqueUndertakers(data: IDisintermentDto[]): string[] {
    const undertakerSet = new Set<string>();
    data.forEach(item => {
      undertakerSet.add(item.undertaker);
    });
    return Array.from(undertakerSet);
  }

  printDisinterment() {

        const filteredDisinterment = this.disinterment_list.filter(d => d.undertaker === this.undertaker);
          
        const dialogRef = this.dialog.open(DisintermentComponent, {     
          data:{  disinterment:filteredDisinterment,
                  divisionName: this.divisionName,
                  sectionName: this.sectionName,
                  compartmentName: this.compartmentName,
                  graveFrom: this.graveFrom,
                  graveTo: this.graveTo,
                  dateLimit: this.dateLimit,
                  undertaker: this.undertaker
                }
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');          
        });
  }

  processData_disinterment() {

    this.retrieving_data = null;

    this.reportingService.getDisintermentReport(this.compartmentId,this.graveFrom,this.graveTo).subscribe(
      (result: IDisintermentDto[]) => {

        this.showPrintOptions_disinterment = true; 

        if (!result || (Array.isArray(result) && result.length === 0)) {
          this.retrieving_data = false
        }else{
          this.retrieving_data = true;
    
          this.disinterment_list = result;

          this.uniqueUndertakers = this.getUniqueUndertakers(result);   

          this.columns_csv = ['graveNoAndLevel', 'fullName', 'idCard', 'dod', 'graveAddress', 'undertaker'];      
          this.data_csv = this.disinterment_list;
        }
      },
      error => {
        console.error('Error:', error);        
      } 
    );    
    
  }

  //ODC REPORT

  processData_odc() {

    this.retrieving_data = null;

    this.reportingService.getBurialPermitsByOdcReceived(this.odc_dateFrom,this.odc_dateTo).subscribe(data => {

      const data_filtered = data.filter(permit => permit.burialTypeId === 2 && permit.cemeteryId == this.odc_cemeteryId)

      this.showPrintOptions_odc = true;   
      if (!data_filtered || (Array.isArray(data_filtered) && data_filtered.length === 0)) {
        this.retrieving_data = false
      }else{
        this.retrieving_data = true;
        this.burialPermits = data_filtered;   

        this.columns_csv = ['PermitNo', 'IdCardNumber', 'Name', 'SurName','DeathCertificateReceivedOn'];      
        this.ocd_data_csv = this.burialPermits.filter(permit => permit.ocdIsReceived == true)
          .map(permit => ({
            PermitNo: permit.permitNumber,
            IdCardNumber: permit.deceasedIdCardNumber,
            Name: permit.deceasedName,
            SurName: permit.deceasedSurName,
            DeathCertificateReceivedOn: permit.odcReceived

        }));  

      }
    })
  }

  printOdc() {

    this.fillOdcReport(this.burialPermits);

    const dialogRef = this.dialog.open(OdcComponent, { 
      data: {
        burialPermits: this.burialPermitPreview,
        odc_dateFrom: this.odc_dateFrom,
        odc_dateTo: this.odc_dateTo

      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  fillOdcReport(burialPermits: IBurialPermitDto[]) {
    
    // Clear the burialPermitPreview array
    this.burialPermitPreview = [];
  
    burialPermits.filter(permit => permit.burialTypeId === 2 && permit.cemeteryId == this.odc_cemeteryId).forEach(burial => {
      this.burialPermitPreview.push({
        permitNo: burial.permitNumber,
        name: burial.deceasedName,
        surname: burial.deceasedSurName,
        idCardNumber: burial.deceasedIdCardNumber,
        gender: this.genders.find(gen => gen.id === burial.deceased.genderId)?.description ?? '',
        dateOfDeath: burial.deathDate,
        timeOfDeath: burial.timeOfDeath,
        placeOfDeath: burial.placeOfDeath,
    
        //NOT USED FIELDS
        massLocality: '',
        cemeteryName: '',
        divisionName: '',
        sectionName: '',
        compartmentName: '',
        graveNo: '',
        levelName: '',
        refNo: '',
        burialRight: '',
        undertaker: '',
        massTime: '',
        locality: '',
        address: '',
        profession: '',
        dateOfBirth: new Date(),
        placeOfBirth: '',
        maritalStatus: '',
        spouseName: '',
        spouseSurname: '',
        fatherName: '',
        fatherSurname: '',
        fatherStatus: '',
        motherName: '',
        motherSurname: '',
        motherStatus: '',
        burialType: '',
        burialTypeId: 0,
        causeOfDeath: '',
        dateOfBurial: new Date(),
        username: '',
        country: '',
        burialLocation: '',
        healthOfficer: '',        
        remarks: '',
        dateCleaned: new Date(),
        dateTransported: new Date(),
        transportedFrom: '',
        transportedTo: '',
        issuedDate: new Date(new Date().setDate(new Date().getDate() + 1)), 

      });
    });
    console.log(this.burialPermitPreview)
  }

  //BURIAL REPORT

  processData_burial() {

    this.retrieving_data = null;

    this.burialpermitService.getBurialPermits(null,null,this.burial_dateFrom,this.burial_dateTo,1,99999).subscribe(data => {

      const data_filtered = data.results.filter(permit => permit.burialTypeId === 2 && permit.cemeteryId == this.burial_cemeteryId)

      this.showPrintOptions_burial = true;  

      if (!data_filtered || (Array.isArray(data_filtered) && data_filtered.length === 0)) {
        this.retrieving_data = false
      }else{
        this.retrieving_data = true;
        this.burialPermits = data_filtered;             

        this.columns_csv = ['Burial_Id', 'Burial_Date', 'PermitNo', 'IdCardNumber', 'Name', 'SurName','Cemetery','Division','Section','Compartment','Grave','Level'];      
        this.burial_data_csv = this.burialPermits
          .filter(permit => permit.burialTypeId == 2)
          .map(permit => ({
            Burial_Id: permit.burialId,
            Burial_Date: permit.burialDate,
            PermitNo: permit.permitNumber,
            IdCardNumber: permit.deceasedIdCardNumber,
            Name: permit.deceasedName,
            SurName: permit.deceasedSurName,
            Cemetery: permit.cemeteryName,
            Division: permit.divisionName,
            Section: permit.sectionName,
            Compartment: permit.compartmentName,
            Grave: permit.graveNo,
            Level: permit.levelId
        }));  

      }
    })
  }

  printBurials() {

    this.fillBurialReport(this.burialPermits);

    const dialogRef = this.dialog.open(BurialsReportComponent, { 
      data: {
        burialPermits: this.burialPermitPreview,
        burial_dateFrom: this.burial_dateFrom,
        burial_dateTo: this.burial_dateTo

      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  fillBurialReport(burialPermits: IBurialPermitDto[]) {
    
    // Clear the burialPermitPreview array
    this.burialPermitPreview = [];
  
    burialPermits.filter(permit => permit.burialTypeId === 2 && permit.cemeteryId == this.burial_cemeteryId).forEach(burial => {
      this.burialPermitPreview.push({
        name: burial.deceasedName,
        surname: burial.deceasedSurName,
        massTime: burial.massTime,
        massLocality: this.massLocalities.find(mass => mass.id === burial.massLocalityId)?.description ?? '',
        cemeteryName: burial.cemeteryName,
        divisionName: burial.divisionName,
        sectionName: burial.sectionName,
        compartmentName: burial.compartmentName,
        graveNo: burial.graveNo,
        levelName: String(burial.levelId),
        refNo: burial.refNo,
        burialRight: burial.burialRight,
        undertaker: this.undertakers.find(und => und.id === burial.undertakerId) ? `${this.undertakers.find(und => und.id === burial.undertakerId)?.name} ${this.undertakers.find(und => und.id === burial.undertakerId)?.surname}`: '',
    
        //NOT USED FIELDS
        idCardNumber: '',
        locality: '',
        address: '',
        profession: '',
        dateOfBirth: new Date(),
        placeOfBirth: '',
        maritalStatus: '',
        gender: '',
        spouseName: '',
        spouseSurname: '',
        fatherName: '',
        fatherSurname: '',
        fatherStatus: '',
        motherName: '',
        motherSurname: '',
        motherStatus: '',
        burialType: '',
        burialTypeId: 0,
        dateOfDeath: new Date(),
        timeOfDeath: '',
        placeOfDeath: '',
        causeOfDeath: '',
        dateOfBurial: new Date(),
        username: '',
        country: '',
        burialLocation: '',
        healthOfficer: '',
        permitNo: '',
        remarks: '',
        dateCleaned: new Date(),
        dateTransported: new Date(),
        transportedFrom: '',
        transportedTo: '',
        issuedDate: new Date(new Date().setDate(new Date().getDate() + 1)), 

      });
    });
  }

  //BURIALS ISSUED REPORT

  processData_burialsIssued() {

    this.retrieving_data = null;

    this.reportingService.getBurialPermitsIssued(this.burialsIssued_dateFrom,this.burialsIssued_dateTo).subscribe(data => {

      const data_filtered = data.filter(permit => permit.burialTypeId === 2 && permit.cemeteryId == this.burialsIssued_cemeteryId)

      this.showPrintOptions_burialsIssued = true;   
      if (!data_filtered || (Array.isArray(data_filtered) && data_filtered.length === 0)) {
        this.retrieving_data = false
      }else{
        this.retrieving_data = true;
        this.burialPermits = data_filtered;

        this.columns_csv = ['Burial_Permit', 'Cemetery_Name', 'Burial_Type', 'Burial_Date', 'Name', 'SurName','IdCardNo','Undertaker'];      
        this.burialsIssued_data_csv = this.burialPermits
          .filter(permit => permit.burialTypeId == 2)
          .map(permit => ({
            Burial_Permit: permit.permitNumber,
            Cemetery_Name: permit.cemeteryName,
            Burial_Type: this.burialTypes.find(typ => typ.id === permit.burialTypeId)?.description,
            Burial_Date: permit.burialDate,
            Name: permit.deceasedName,
            SurName: permit.deceasedSurName,
            IdCardNo: permit.deceasedIdCardNumber,
            Undertaker: this.undertakers.find(und => und.id === permit.undertakerId) ? `${this.undertakers.find(und => und.id === permit.undertakerId)?.name} ${this.undertakers.find(und => und.id === permit.undertakerId)?.surname}`: '',
        }));  
      }
    })
  }

  printBurialsIssued() {

    this.fillBurialsIssuedReport(this.burialPermits);

    const dialogRef = this.dialog.open(BurialsIssuedComponent, { 
      data: {
        burialPermits: this.burialPermitPreview,
        burialsIssued_dateFrom: this.burialsIssued_dateFrom,
        burialsIssued_dateTo: this.burialsIssued_dateTo
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  fillBurialsIssuedReport(burialPermits: IBurialPermitDto[]) {
    
    // Clear the burialPermitPreview array
    this.burialPermitPreview = [];
  
    burialPermits.filter(permit => permit.burialTypeId === 2 && permit.cemeteryId == this.burialsIssued_cemeteryId).forEach(burial => {
      this.burialPermitPreview.push({
        permitNo: burial.permitNumber,
        cemeteryName: burial.cemeteryName,
        burialType: this.burialTypes.find(typ => typ.id === burial.burialTypeId)?.description || '',
        dateOfBurial: burial.burialDate,
        name: burial.deceasedName,
        surname: burial.deceasedSurName,
        idCardNumber: burial.deceasedIdCardNumber,
        undertaker: this.undertakers.find(und => und.id === burial.undertakerId) ? `${this.undertakers.find(und => und.id === burial.undertakerId)?.name} ${this.undertakers.find(und => und.id === burial.undertakerId)?.surname}`: '',

        //NOT USED FIELDS
        locality: '',
        address: '',
        profession: '',
        dateOfBirth: new Date(),
        placeOfBirth: '',
        maritalStatus: '',
        gender: '',
        spouseName: '',
        spouseSurname: '',
        fatherName: '',
        fatherSurname: '',
        fatherStatus: '',
        motherName: '',
        motherSurname: '',
        motherStatus: '',
        massTime: '',
        burialTypeId: 0,
        dateOfDeath: new Date(),
        timeOfDeath: '',
        placeOfDeath: '',
        causeOfDeath: '',
        username: '',
        country: '',
        burialLocation: '',
        healthOfficer: '',
        remarks: '',
        dateCleaned: new Date(),
        dateTransported: new Date(),
        transportedFrom: '',
        transportedTo: '',
        massLocality: '',
        divisionName: '',
        sectionName: '',
        compartmentName: '',
        graveNo: '',
        levelName: '',
        burialRight: '',
        issuedDate: new Date(new Date().setDate(new Date().getDate() + 1)), 

      });
    });
  }
  
  // CLEANING AND TRANSPORT REPORT
  processData_cleaningTransport() {

    this.retrieving_data = null;
    
    this.reportingService.getCleaningByDate(this.cleaningTransport_dateFrom,this.cleaningTransport_dateTo).subscribe(data => {
      this.showPrintOptions_cleaningTransport = true; 
      if (!data || (Array.isArray(data) && data.length === 0)) {
        this.retrieving_data = false
      }else{
        this.retrieving_data = true
        this.cleanings = data.filter(item => item.graveId !== 0 && item.cemeteryId == this.cleaningTransport_cemeteryId);

        // CSV EXPORT DISABLED FOR THIS REPORT
        /*this.columns_csv = [];      
        this.cleaningTransport_data_csv = this.cleanings
          .map(permit => ({
        }));*/  

      }
    })
  }

  async printCleaningTransport(graveId: number) {
    // Wait for fillGraveDetails to complete
    await this.fillGraveDetails(graveId);

    // Wait for fillGraveNoInCleanings to complete
    await this.fillGraveNoInCleanings();

    // After the above methods are done, fill the transport report
    this.fillCleaningTransportReport(graveId);

    const dialogRef = this.dialog.open(GraveCleanTransTemplateComponent, {
        data: this.cleanTransPreview
    });

    dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
    });
  }

  fillGraveDetails(graveId: number): Promise<void> {
      return new Promise((resolve, reject) => {
          this.graveService.getGraveById(graveId).subscribe(
              (response: IGraveDto) => {
                  this.graveDetails = response;
                  resolve(); // Resolve when data is successfully fetched
              },
              error => {
                  console.error('Error fetching grave details', error);
                  reject(error); // Reject in case of an error
              }
          );
      });
  }


  fillGraveNoInCleanings(): Promise<void> {
      return new Promise((resolve, reject) => {
          let completedRequests = 0;
          const totalRequests = this.cleanings.length;

          // Check if there are any cleaning requests
          if (totalRequests === 0) {
              resolve();
          }

          this.cleanings.forEach(cleaning => {
              if (cleaning.transport?.toGraveId) {
                  this.graveService.getGraveById(cleaning.transport.toGraveId).subscribe(
                      (response: IGraveDto) => {
                          if (cleaning.transport) {
                              cleaning.transport.toGraveNo = response.graveNo;
                          }

                          // Track completed requests
                          completedRequests++;
                          if (completedRequests === totalRequests) {
                              resolve(); // Resolve the Promise after all requests are done
                          }
                      },
                      error => {
                          console.error('Error fetching grave details', error);
                          reject(error); // Reject in case of any error
                      }
                  );
              } else {
                  // If no transport details, treat it as a completed request
                  completedRequests++;
                  if (completedRequests === totalRequests) {
                      resolve(); // Resolve after processing all cleanings
                  }
              }
          });
      });
  }

  fillCleaningTransportReport(graveId: number) {
      // Clear the burialPermitPreview array
      this.cleanTransPreview = [];

      this.cleanTransPreview = this.cleanings
          .filter(cleaning => cleaning.graveId === graveId)
          .map(item => ({
              cleaningPermitNumber: item.cleaningPermitNumber,
              applicationDate: item.applicationDate,
              supervisorName: item.supervisorName,
              supervisorSurname: item.supervisorSurname,
              cleaningDate: item.cleaningDate,
              undertakerName: item.undertakerName,
              undertakerSurname: item.undertakerSurname,
              graveAddressCemetery: (this.originalCemeteries.find(cem => cem.code === this.graveDetails.cemeteryId))?.name,
              graveAddressDivision: (this.originalDivisions.find(div => div.id === this.graveDetails.divisionId))?.description,
              graveAddressSection: (this.originalSections.find(sec => sec.id === this.graveDetails.sectionId))?.description,
              graveAddressCompartment: (this.originalComaprtments.find(com => com.id === this.graveDetails.compartmentId))?.description,
              graveAddressNumber: this.graveDetails.graveNo,
              graveAddressLevel: item.levelId.toString(),
              cleaningDeceasedName: item.deceasedName,
              cleaningDeceasedSurname: item.deceasedSurname,
              marbleSlab: item.marbleSlab,
              isFamilyPresent: item.isFamilyPresent,
              feePaid: item.feePaid,
              transportDeceasedName: item.deceasedName,
              transportDeceasedSurname: item.deceasedSurname,
              transportPreview: item.transport? {
                transportToCemetery: (this.originalCemeteries.find(cem => cem.code === item.transport?.toCemeteryId))?.name,
                transportToDivision: (this.originalDivisions.find(div => div.id === item.transport?.toDivisionId))?.description,
                transportToSection: (this.originalSections.find(sec => sec.id === item.transport?.toSectionId))?.description,
                transportToCompartment: (this.originalComaprtments.find(com => com.id === item.transport?.toCompartmentId))?.description,
                transportToNumber: item.transport?.toGraveNo,
                transportType: item.transport?.transportTypeId,
                transportToCountry: item.transport?.toDestination
                }: undefined
          }));
  }

  getUniqueGraveIds() {
    const graveIdRow = new Map();
  
    this.cleanings.forEach(item => {
      if (!graveIdRow.has(item.graveId)) {
        // GraveId as key, and the values that we're going to use on the table
        graveIdRow.set(item.graveId, {
          graveId: item.graveId,
          permitNo: item.cleaningPermitNumber
        });
      }
    });

    return Array.from(graveIdRow.values());
  }

}
  


