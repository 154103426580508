import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IRemarksPerCompartmentDto } from '../models/RemarksPerCompartmentDto.model';
import { GraveService } from '../services/grave.service';
import { IResponseModal } from '../models/ResponseModal.model';
import { InternalCommunicationService } from '../services/internal-communication.service';
import { ResponsemodalComponent } from '../responsemodal/responsemodal.component';

@Component({
  selector: 'app-remarks-per-compartment',
  templateUrl: './remarks-per-compartment.component.html',
  styleUrls: ['./remarks-per-compartment.component.css']
})
export class RemarksPerCompartmentComponent implements OnInit {
  @Input() graveId!: number;
  remarksDto!: IRemarksPerCompartmentDto;

  constructor(
    public dialogRef: MatDialogRef<RemarksPerCompartmentComponent>,
    public dialog: MatDialog,
    private internalCommunicationService: InternalCommunicationService,
    private graveService: GraveService,    
    @Inject(MAT_DIALOG_DATA) public data: IRemarksPerCompartmentDto
  ) {
    if (data) {
      this.remarksDto = { ...data, remarks: this.formatRemarks(data.remarks) }; 
    }
  }

  ngOnInit(): void {
    if (!this.remarksDto) {
      if (this.graveId && this.graveId > 0) {
        console.log('remarksDto is null or undefined, calling getRemarks...');
        this.getRemarks(this.graveId);
      } else {
        console.error('Invalid grave ID provided.');
      }
    } else {
      console.log('Remarks data is already loaded.');
    }
  }

  saveRemarks() {
    if (this.remarksDto.remarks) {
      this.remarksDto.remarks = this.remarksDto.remarks.trim(); 
    }
    this.graveService.updateRemarks(this.graveId, this.remarksDto).subscribe(
      (response: IResponseModal) => {
        this.openResponseModal(response);
        console.log('Remarks updated successfully:', response); 
        this.internalCommunicationService.emitRemarksUpdate(this.remarksDto);
      },
      (error) => {
        console.error('Error updating remarks:', error); 
      }
    );
  }

  openResponseModal(response: IResponseModal): void {
    const dialogRef = this.dialog.open(ResponsemodalComponent, {
      width: '250px',
      data: response,
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });    
  }

  getRemarks(graveId: number) {
    this.graveService.getRemarksPerGrave(graveId).subscribe(
      (response: IRemarksPerCompartmentDto) => {
        this.remarksDto = { ...response, remarks: this.formatRemarks(response.remarks) }; 
      },
      (error) => {
        console.error('something went wrong:', error);
      }
    );
  }

  
  formatRemarks(remarks: string | undefined): string {
    return remarks
      ? remarks.split('.').map(sentence => sentence.trim()).join('.\n')
      : '';
  }
  
}
