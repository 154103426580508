<div class="container">
  <div class="form">
    <form [formGroup]="CompartmentEditForm">
      <span class="mat-title" mat-dialog-title><b>Edit Compartment Details</b> </span>
      <mat-dialog-content>
   <div class="box">
          <mat-form-field>
            <mat-label>Compartment Id</mat-label>
            <input matInput placeholder="Compartment Id" formControlName="compartmentCtrl" required/>
          </mat-form-field>
        </div>
        <div class="box">
          <mat-form-field>
            <mat-label>Compartment Name</mat-label>
            <input matInput placeholder="Compartment Name" formControlName="compartmentNameCtrl" required/>
          </mat-form-field>
        </div>
<mat-dialog-actions>
    <div class="divspace">
  <button mat-raised-button color="primary" (click)="onCompartmentUpdate()">Update</button>
  <button mat-raised-button style="color:dimgray;" [mat-dialog-close] cdkFocusInitial>Close</button></div>
</mat-dialog-actions>

</mat-dialog-content>
</form>
</div>
</div>


