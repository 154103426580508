import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IGraveLevelsDto } from 'src/app/models/GraveLevelsDto.model';
import { GraveService } from 'src/app/services/grave.service';
import { MatTableDataSource } from '@angular/material/table';
import { IResponseModal } from 'src/app/models/ResponseModal.model';
import { MatDialog } from '@angular/material/dialog';
import { ResponsemodalComponent } from 'src/app/responsemodal/responsemodal.component';
import { InternalCommunicationService } from 'src/app/services/internal-communication.service';
import { catchError, of as observableOf} from 'rxjs';

@Component({
  selector: 'app-grave-level-update',
  templateUrl: './grave-level-update.component.html',
  styleUrls: ['./grave-level-update.component.css']
})
export class GraveLevelUpdateComponent {

  @Input() graveId! : number;

  levelUpdateFormGroup! : FormGroup;
  isLoading = false;
  levelDescriptions!: any[];

  graveLevel: IGraveLevelsDto = {
    graveId: 0,
    levelId: 0,
    graveLevelStatusId : 0,
    levelDescription : '',
    graveLevelStatusDescription : ''
  };
  graveLevels!: IGraveLevelsDto[];

  updateObj = {
    levelId : 0,
    graveLevelStatusId: 0
  }

  dataSource: MatTableDataSource<IGraveLevelsDto>;
  displayedColumns: string[] = ['level', 'status'];

  constructor(
    private _formBuilder: FormBuilder,
    private graveService : GraveService,
    private dialog: MatDialog,
    private internalService : InternalCommunicationService
  ){
    this.dataSource = new MatTableDataSource(this.graveLevels);
  }  

  ngOnInit(): void{
    this.internalService.levelStatusUpdated$.subscribe(() => {
      this.refreshTableData();
    });

    this.levelUpdateFormGroup = this._formBuilder.group({
      levelCtrl : [this.graveLevel ? this.graveLevel.levelId : 0],
      statusCtrl : [ this.graveLevel ? this.graveLevel.graveLevelStatusId : 0]
    });

    this.getAllGraveLevels();

    this.levelDescriptions = this.getAllLevelStatusDescription();

    this.suscribeChanges();
  }

  refreshTableData() {
    this.fetchGraveLevels().subscribe(data => {
      if (data === null) {
        return;
      }
      this.dataSource = new MatTableDataSource(data);
    });
  } 
  
  fetchGraveLevels() {
    return this.graveService!.getGraveLevelsByGraveId(this.graveId).pipe(catchError(() => observableOf(null)));
  }

  suscribeChanges() {
      this.levelUpdateFormGroup.get('levelCtrl')?.valueChanges.subscribe((value) => {          
        this.graveLevel.levelId = value;
      });
      this.levelUpdateFormGroup.get('statusCtrl')?.valueChanges.subscribe((value) => {          
        this.graveLevel.graveLevelStatusId = value;  
      });
  }

  getAllGraveLevels() {
    this.isLoading = true;
    this.graveService.getGraveLevelsByGraveId(this.graveId).subscribe(
      (response: IGraveLevelsDto[]) => {
        this.graveLevels = response;
        this.dataSource = new MatTableDataSource(this.graveLevels);
        this.isLoading = false
      },
      (error) => {
        console.error('Error fetching grave levels:', error);
      }
    );
  }

  getAllLevelStatusDescription() {
    return [
      { value: 1, description: 'AVAILABLE' },
      { value: 2, description: 'FULL' },
      { value: 3, description: 'EMPTY' }
    ];
  }

  getLevelStatusId(levelId:number){
    this.levelUpdateFormGroup.get('statusCtrl')?.setValue(this.graveLevels[levelId-1].graveLevelStatusId);
  }

  populateUpdateObject(){
    this.updateObj = {
      levelId: this.graveLevel.levelId,
      graveLevelStatusId: this.graveLevel.graveLevelStatusId
    }
  }
  
  updateLevelStatus(){

    this.populateUpdateObject();

    this.graveService.updateLevelStatus(this.graveId,this.updateObj).subscribe(
      (response: IResponseModal) => {

        this.internalService.emitRefreshLevelStatusTable();
        
        const dialogRef = this.dialog.open(ResponsemodalComponent, {
          width: '250px',
          data : response,
        });
        
      }
    );

  }

}
