
<div class="container">

    <div class="form">
        <form>
            <div class="box">
                <mat-form-field class="mat-form-field">
                  <mat-label>Filter</mat-label>
                  <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Module Name" #input/>
                </mat-form-field>
            </div>
              <mat-divider></mat-divider>
            <!--TABLE-->
            <div class="box">
                <div class="table-table-container" id="tablecontent">
                    <mat-label><b>Modules:</b></mat-label>
                    <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>
        
                        <!-- moduleID Column -->
                        <ng-container matColumnDef="moduleID">
                        <th mat-header-cell *matHeaderCellDef>Id</th>
                        <td mat-cell *matCellDef="let module">{{ module.id }}</td>
                        </ng-container>
                        
                        <!-- moduleName Column -->
                        <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>Name</th>
                        <td mat-cell *matCellDef="let module">{{ module.name }}</td>
                        </ng-container>
        
                        <!-- deceasedName Column -->
                        <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef>Description</th>
                        <td mat-cell *matCellDef="let module">{{ module.description }}</td>
                        </ng-container>     
        
                        <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef>Action</th>
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox
                                    (change)="toggleAllCheckboxes($event.checked)"
                                    [checked]="areAllSelected()">
                                </mat-checkbox> Select All
                            </th>
                            <td mat-cell *matCellDef="let cleaning">
                                <mat-checkbox  class="example-margin"
                                (change)="onCheckboxChange(cleaning)" 
                                [checked]="isSelected(cleaning)">
                                </mat-checkbox>                        
                            </td>
                            </ng-container>              
                        </ng-container>
        
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let grave; columns: displayedColumns;" ></tr>
                                
        
                        <!-- Row shown when there is no matching data. -->
                        <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No data matching the filter "{{ input.value }}"</td>
                        </tr>
                    </table>
                </div>
    
            </div>
            <mat-paginator class="paginator" [pageSizeOptions]="[25, 100,200]" [length]="resultsLength" aria-label="Select page of burial permits"></mat-paginator>
            <div class="buttonnew">
                <button mat-raised-button (click)="saveSelection()" color="primary"><mat-icon>add</mat-icon>SAVE</button>
            </div>
        </form>
        
        
    </div>
</div>