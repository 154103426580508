<div class="body-container">
  <div class="header_container">
    <mat-divider></mat-divider>
    <h1>View Users</h1>
    <mat-divider></mat-divider>
  </div>

  <div class="container">
    <div class="form">
      <div class="box">
        <app-menu-bar fxShow="true" fxHide.lt-md></app-menu-bar>
      </div>
    </div>

    <div class="form">
      <div class="button-container">
        <button mat-raised-button color="primary" (click)="addUser()">Add User</button>
        <mat-form-field appearance="outline">
          <mat-label>Search Users</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Search by username, email, etc." />
        </mat-form-field>
      </div>

      <div class="table-container">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> User ID </th>
            <td mat-cell *matCellDef="let user"> {{ user.Id }} </td>
          </ng-container>
       
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let user"> {{ user.name }} </td>
          </ng-container>
  
          <ng-container matColumnDef="surname">
            <th mat-header-cell *matHeaderCellDef> Surname </th>
            <td mat-cell *matCellDef="let user"> {{ user.surname }} </td>
          </ng-container>
  
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> Email </th>
            <td mat-cell *matCellDef="let user"> {{ user.email }} </td>
          </ng-container>

          <ng-container matColumnDef="roleName">
            <th mat-header-cell *matHeaderCellDef> Role </th>
            <td mat-cell *matCellDef="let user"> {{ user.roleName }} </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let user">
              <button mat-button color="primary" (click)="editUser(user.id)">Edit
              <mat-icon>mode</mat-icon>
              </button>
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [length]="resultsLength" [pageSizeOptions]="[25, 100, 200]" aria-label="Select page of users">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
