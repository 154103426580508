import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { RoleService } from '../services/role.service';
import { CurrentUserService } from '../services/current-user.service';
import { catchError, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(
    private roleService: RoleService, 
    private router: Router,
    private currentUserService : CurrentUserService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const moduleName = route.data['moduleName'];
    const accessLevel = route.data['accessLevel'] || 'Full';

    console.log('Module Name:', moduleName);
    console.log('Access Level:', accessLevel);

    // Retrieve user roles from localStorage (if available)
    const storedUser = this.roleService.getUser();
    if (storedUser) {
      const hasAccess = this.roleService.hasAccessToModule(moduleName, accessLevel);
      if (hasAccess) {
        return of(true);
      } else {
        this.router.navigate(['/unauthorized']);
        return of(false);
      }
    }

    // Retrieve the email from UserService
    const email = this.currentUserService.getUserEmail();
    if (!email) {
      console.error('No user email found');
      this.router.navigate(['/unauthorized']);
      return of(false);
    }

    // Fetch roles and check access only after roles are loaded
    return this.roleService.fetchUserRoleByEmail(email).pipe(
      map(userRoles => {
        this.roleService.setUserRoles(userRoles);

        // Now we can check access
        const hasAccess = this.roleService.hasAccessToModule(moduleName, accessLevel);
        if (hasAccess) {
          console.log('inside')
          return true;
        }

        // If no access, redirect to unauthorized page
        this.router.navigate(['/unauthorized']);
        return false;
      }),
      catchError(error => {
        console.error('Error in RoleGuard:', error);
        this.router.navigate(['/unauthorized']);
        return [false];
      })
    );
  }
}
