<div class="dialog-header">
    <button mat-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  
  <div class="container-popup">
    <div class="form">
      <div *ngIf="isLoading" class="spinner-overlay">
        <mat-spinner></mat-spinner>
      </div>
      <mat-tab-group class="fixed-size-tab-group" animationDuration="0ms">
        
        <mat-tab class="fixed-size-tab" label="Levels">
          <span class="mat-title" mat-dialog-title><b>Level selection:</b></span>
          <div class="box">
            <table mat-table [dataSource]="dataSource" matSortclass="mat-elevation-z8">
              <!--<ng-container matColumnDef="grave_id">
                        <th mat-header-cell *matHeaderCellDef> Grave No. </th>
                        <td mat-cell *matCellDef="let element"> {{element.graveId}} </td>
                      </ng-container>-->
  
              <ng-container matColumnDef="levelId">
                <th mat-header-cell *matHeaderCellDef>Level id</th>
                <td mat-cell *matCellDef="let element">{{ element.levelId }}</td>
              </ng-container>
  
              <ng-container matColumnDef="levelDescription">
                <th mat-header-cell *matHeaderCellDef>Level</th>
                <td mat-cell *matCellDef="let element">{{ element.levelDescription }}</td>
              </ng-container>
  
              <ng-container matColumnDef="graveLevelStatusDescription">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.graveLevelStatusDescription ? element.graveLevelStatusDescription : 'Unknown' }}
                </td>
              </ng-container>
              <!-- Action -->
              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>Select</th>
                <td mat-cell *matCellDef="let grave">
                  <mat-checkbox class="example-margin" (change)="onCheckboxChange($event, grave)" [checked]="isSelected(grave)"></mat-checkbox>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
        </mat-tab>
  
       
       
        
      </mat-tab-group>
  
  
    </div>
  
  </div>
  