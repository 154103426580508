import { Injectable } from '@angular/core';
import { ICompartmentDto } from '../models/CompartmentDto.model';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, catchError, map, tap } from 'rxjs';
import { IResponseModal } from '../models/ResponseModal.model';
import { IAddNewCompartmentDto } from '../models/AddNewCompartmentDto.model';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class CompartmentService {
 
  private baseUrl = `${environment.apiUrl}compartment/`; 
  constructor(private http: HttpClient, private authService: AuthService) { }

  GetCompartmentBySectionId(id:number): Observable<ICompartmentDto[]> {    
    const endpoint = 'GetCompartmentBySectionId'; 
    const url = `${this.baseUrl}${endpoint}?sectionId=${id}`;
    const headers = this.authService.getAuthHeaders();
    return this.http.get<ICompartmentDto[]>(url,{headers})
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };

  getCompartmentByCemeteryIdAndDivisionIdSectionId(cemeteryId:number,divisionId:number,sectionId:number): Observable<ICompartmentDto[]> {    
    const endpoint = 'GetCompartmentCemeteryIdAndDivisionIdAndBySectionId'; 
    const url = `${this.baseUrl}${endpoint}?cemeteryId=${cemeteryId}&divisionId=${divisionId}&sectionId=${sectionId}`;
    const headers = this.authService.getAuthHeaders();
    return this.http.get<ICompartmentDto[]>(url,{headers})
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };
  getAllCompartments(pageNumber: number = 1, pageSize: number = 1000): Observable<ICompartmentDto[]> {    
    let params = new HttpParams();
    const headers = this.authService.getAuthHeaders();
    params = params.append('pageNumber', pageNumber.toString());
    params = params.append('pageSize', pageSize.toString());

    return this.http.get<ICompartmentDto[]>(this.baseUrl +'GetAllCompartments', { params,headers })
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };

  updateCompartmentDetails(id: number, CompartmentForUpdateDto: ICompartmentDto): Observable<IResponseModal> {
    const headers = this.authService.getAuthHeaders();
    const url = `${this.baseUrl}${id}`;
    return this.http.put<HttpResponse<IResponseModal>>(url , CompartmentForUpdateDto, { observe: 'response',headers }).pipe(
      map(response => {
        
        let modal: IResponseModal = {
          id: 0,
          title: 'Update Compartment',
          message: ''
        };
        if (response.status === 204) {  
          
          modal.message = 'Record Updated';

        } else if (response.status === 200) {    
          
          modal.message = 'To update a compartment at least one field needs to change, currently not changes have been registered.';

        } else {
          modal.message =`Unexpected status code: ${response.status}`;
          
          throw new Error(`Unexpected status code: ${response.status}`);
        }
        return modal;
      })
    );
  }

  addNewCompartment(addNewCompartmentDto:  IAddNewCompartmentDto): Observable<IResponseModal> {
    const headers = this.authService.getAuthHeaders();
    return this.http.post<IResponseModal>(this.baseUrl + 'AddCompartment', addNewCompartmentDto,{ observe: 'response',headers }).pipe(
      tap(response => {
        console.log('Add Compartment' + response.status);
      }),
      map(response => {
        let modal: IResponseModal = {
          id: 0,
          title: 'Add Compartment',
          message: '',
          entity : response.body
        };
  
        if (response.status === 201) {
          modal.message = 'Compartment added';
        } else {
          modal.message = `Unexpected status code: ${response.status}`;
          throw new Error(`Unexpected status code: ${response.status}`);
        }
        
        return modal;
      }),
        catchError(error => {
            console.error('Error:', error);
            throw error;
        })
    );
  }
  
  deleteCompartment(id : number) : Observable<IResponseModal>{
    const headers = this.authService.getAuthHeaders();
    const url = `${this.baseUrl}${id}`;
    return this.http.delete<IResponseModal>(url,{headers})
    .pipe(
      catchError(error => {
          console.error('Error:', error);
          throw error;
      })
  );
  }
}
