import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IGraveOwnerSample } from '../grave-owners.component';

@Component({
  selector: 'app-grave-owners-edit',
  templateUrl: './grave-owners-edit.component.html',
  styleUrls: ['./grave-owners-edit.component.css']
})
export class GraveOwnersEditComponent {

  cemeteryType ='option1';
  cemeteryOption = 'option1';
  localityOption ='option1';
  divisionOption = 'option1';
  sectionOption=  'option1';
  compartmentOption = 'option2';
  graveNO= 'option2';
  
  constructor(public dialogRef: MatDialogRef<GraveOwnersEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IGraveOwnerSample){}
 
    onGraveownerUpdate(): void {
      this.dialogRef.close();
    }
 }