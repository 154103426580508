import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { catchError, map, merge, Observable, of as observableOf, startWith, switchMap} from 'rxjs';
import { IGraveDto } from 'src/app/models/GraveDto.model';
import { IGraveOwnerDto } from 'src/app/models/GraveOwnerDto.model';
import { IGraveOwership } from 'src/app/models/GraveOwnershipDto.model';
import { ILocalityDto } from 'src/app/models/LocalityDto.model';
import { IOwnerUpdateDto } from 'src/app/models/OwnerUpdateDto.model';
import { IOwnershipTypeDto } from 'src/app/models/OwnershipTypeDto.model';
import { IResponseModal } from 'src/app/models/ResponseModal.model';
import { ResponsemodalComponent } from 'src/app/responsemodal/responsemodal.component';
import { InternalCommunicationService } from 'src/app/services/internal-communication.service';
import { LocalityService } from 'src/app/services/locality.service';
import { OwnerService } from 'src/app/services/owner.service';
import { DATE_PIPE_DEFAULT_OPTIONS, DatePipe } from '@angular/common';
import { IAddOwnerDto } from 'src/app/models/AddOwnerDto.model';
import { BurialPermitDetailsComponent } from 'src/app/burials/burial-permit-details/burial-permit-details.component';
import { BurialLastburialComponent } from 'src/app/burials/burial-lastburial/burial-lastburial.component';
import { GraveService } from 'src/app/services/grave.service';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { IGraveLevelsDto } from 'src/app/models/GraveLevelsDto.model';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ITableCountDto } from 'src/app/burials/models/ITableCountDto';


@Component({
  selector: 'app-grave-maintenance-view',
  templateUrl: './grave-maintenance-view.component.html',
  styleUrls: ['./grave-maintenance-view.component.css']
})
export class GraveMaintenanceViewComponent implements OnInit {
  graveId : number ;
  isLoadingResults = true;
  //FormGroup
  ownersFormGroup! : FormGroup;
  burialsFormGroup! : FormGroup;
  cleaningFormGroup! : FormGroup;
  transportFormGroup! : FormGroup;
  lastBurialFormGroup! : FormGroup;

  //dropdown variables
  ownershipTypes!:IOwnershipTypeDto[];
  localities! :ILocalityDto[];
  locality!:ILocalityDto;
  

  //main object dto 
  grave!: IGraveDto;
  mainOwner!: IGraveOwnerDto;  
  owners! : IGraveOwnerDto[];
  currentsOwners !: IGraveOwnerDto[];
  ownershipts!: IGraveOwership[];

  constructor(

    public dialogRef: MatDialogRef<GraveMaintenanceViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IGraveDto,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    
  ){
    this.graveId = data.id;    
    this.grave = data;
  }
  ngOnInit(): void {      

    this.ownersFormGroup = this._formBuilder.group({     
      
    });
    this.burialsFormGroup = this._formBuilder.group({ 

    });
    this.cleaningFormGroup = this._formBuilder.group({
    
    });
    this.transportFormGroup = this._formBuilder.group({
    
    });
    this.lastBurialFormGroup = this._formBuilder.group({
      
    });

    
  }  
  
}
