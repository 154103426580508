import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {MAT_DIALOG_DATA,MatDialog,MatDialogRef,} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { IAddNewCompartmentDto } from 'src/app/models/AddNewCompartmentDto.model';
import { IAddNewDivisionDto } from 'src/app/models/AddNewDivisionDto.model';
import { IAddNewSectionDto } from 'src/app/models/AddNewSectionDto.model';
import { ICemeteryDto } from 'src/app/models/CemeteryDto.model';
import { ICompartmentDto } from 'src/app/models/CompartmentDto.model';
import { IDivisionDto } from 'src/app/models/DivisionDto.model';
import { IResponseModal } from 'src/app/models/ResponseModal.model';
import { ISectionDto } from 'src/app/models/SectionDto.model';
import { ResponsemodalComponent } from 'src/app/responsemodal/responsemodal.component';
import { CompartmentService } from 'src/app/services/compartment.service';
import { DivisionService } from 'src/app/services/division.service';
import { InternalCommunicationService } from 'src/app/services/internal-communication.service';
import { SectionService } from 'src/app/services/section.service';

@Component({
  selector: 'app-cemetery-details-view',
  templateUrl: './cemetery-details-view.component.html',
  styleUrls: ['./cemetery-details-view.component.css'],
})
export class CemeteryDetailsViewComponent implements OnInit {
  divisionFormGroup!: FormGroup;
  sectionFormGroup!: FormGroup;
  compartmentFormGroup!: FormGroup;
  cemeteries: ICemeteryDto[] = [];
  divisions: IDivisionDto[] = [];
  sections: ISectionDto[] = [];
  compartments: ICompartmentDto[] = [];
  cemetery!: ICemeteryDto;
  division!: IDivisionDto;
  section!: ISectionDto;
  compartment!: ICompartmentDto;
  divisionObj: IAddNewDivisionDto = {
    cemeteryId: 0,
    description: '',
  }
  sectionObj: IAddNewSectionDto  = {
    divisionId: 0,
    cemeteryId: 0,
    description: '',
  }
  compartmentObj: IAddNewCompartmentDto  = {
    divisionId: 0,
    sectionId: 0,
    description: '',
    cemeteryId: 0,
  }
  
  selectedDivision: IDivisionDto | null = null;
  selectedSection: ISectionDto | null = null;
  selectedCompartment: ICompartmentDto | null = null;
  dataSource!: MatTableDataSource<IDivisionDto>;
  dataSource1!: MatTableDataSource<ISectionDto>;
  dataSource2!: MatTableDataSource<ICompartmentDto>;

  displayedColumns: string[] = ['id', 'name', 'action'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ICemeteryDto,
    public dialog: MatDialog,
    private divisionService: DivisionService,
    private sectionService: SectionService,
    private compartmentService: CompartmentService,
    private internalService: InternalCommunicationService,
    private _formBuilder: FormBuilder
  ) {
    this.cemetery = data;
  }
  ngOnInit(): void {

    this.getDivisionsByCemeteryId();

    //division form
    this.divisionFormGroup = this._formBuilder.group({
      cemeteryNameCtrl: [
        { value: this.cemetery ? this.cemetery.name : '', disabled: true },
      ],
      divisionNameCtrl: [this.division ? this.division.description : ''],
    });

    //section form
    this.sectionFormGroup = this._formBuilder.group({
      cemeteryNameCtrl: [
        { value: this.cemetery ? this.cemetery.name : '', disabled: true },
      ],
      divisionNameCtrl: [this.section ? this.section.divisionId : 0],
      sectionNameCtrl: [this.section ? this.section.description : ''],
    });

    //compartment form
    this.compartmentFormGroup = this._formBuilder.group({
      cemeteryNameCtrl: [
        { value: this.cemetery ? this.cemetery.name : '', disabled: true },
      ],
      divisionNameCtrl: [this.compartment ? this.compartment.divisionId : 0],
      sectionNameCtrl: [this.compartment ? this.compartment.sectionId : 0],
      compartmentNameCtrl: [this.compartment ? this.compartment.description : '',],
    });

    this.subscribeChanges();

    this.internalService.divisionUpdated$.subscribe(() => {
      this.refreshDivisionTableData();
    });
    this.internalService.sectionUpdatedSource$.subscribe(() => {
      this.refreshSectionTableData();
    });
    this.internalService.compartmentUpdatedSource$.subscribe(() => {
      this.refreshCompartmentTableData();
    });
  }

  refreshDivisionTableData() {
    this.getDivisionsByCemeteryId();
    this.fetchDivisions().subscribe(data => {
      if (data === null) {
        return;
      }
      this.dataSource = new MatTableDataSource(data);
    });
  } 
  fetchDivisions() {       
    return this.divisionService!.getDivisionsByCementeryId(this.cemetery.code);      
  }

  refreshSectionTableData() {
    this.onDivisionChange()
    this.fetchSection().subscribe(data => {
      if (data === null) {
        return;
      }
      this.dataSource1 = new MatTableDataSource(data);
    });
  } 
  fetchSection() {       
    return this.sectionService!.GetSectionByDivisionId(this.sectionObj.divisionId);      
  }

  refreshCompartmentTableData() {
    this.onSectionChange();
    this.fetchCompartment().subscribe(data => {
      if (data === null) {
        return;
      }
      this.dataSource2 = new MatTableDataSource(data);
    });
  } 
  fetchCompartment() {       
    return this.compartmentService!.GetCompartmentBySectionId(this.compartmentObj.sectionId);      
  }

  subscribeChanges() {
    this.divisionFormGroup.valueChanges.subscribe((value) => {
      this.division = { ...this.division, ...value };
      this.divisionObj.description = value.divisionNameCtrl
    });
    this.sectionFormGroup.valueChanges.subscribe((value) => {
      this.section = { ...this.section, ...value };
      this.sectionObj.divisionId = value.divisionNameCtrl
      this.sectionObj.description = value.sectionNameCtrl
    });
    this.compartmentFormGroup.valueChanges.subscribe((value) => {
      this.compartment = { ...this.compartment, ...value };
      this.compartmentObj.divisionId = value.divisionNameCtrl
      this.compartmentObj.sectionId = value.sectionNameCtrl
      this.compartmentObj.description = value.compartmentNameCtrl
    });
  }
  setDivisionFormGroupValues(division: IDivisionDto) {
    const newValues = {
      cemeteryNameCtrl: this.cemetery.name,
      divisionNameCtrl: division.description,
    };
    this.divisionFormGroup.setValue(newValues);
  }
  setSectionFormGroupValues(section: ISectionDto) {
    const newValues = {
      cemeteryNameCtrl: this.cemetery.name,
      divisionNameCtrl: section.divisionId,
      sectionNameCtrl: section.description,
    };
    this.sectionFormGroup.setValue(newValues);
  }
  setCompartmentFormGroupValues(compartment: ICompartmentDto) {
    const newValues = {
      cemeteryNameCtrl: this.cemetery.name,
      divisionNameCtrl: this.compartmentObj.divisionId,
      sectionNameCtrl: compartment.sectionId,
      compartmentNameCtrl: compartment.description,
    };
    this.compartmentFormGroup.setValue(newValues);
  }
  //table
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

    this.selectedDivision = null;
  }
  applyFilter1(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource1.filter = filterValue.trim().toLowerCase();

    if (this.dataSource1.paginator) {
      this.dataSource1.paginator.firstPage();
    }

    this.selectedSection = null;
  }
  applyFilter2(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource2.filter = filterValue.trim().toLowerCase();
    if (this.dataSource2.paginator) {
      this.dataSource2.paginator.firstPage();
    }
    this.selectedCompartment = null;
  }

  getDivisionsByCemeteryId(): void {
    if (this.cemetery && this.cemetery.code) {
      this.divisionService
        .getDivisionsByCementeryId(this.cemetery.code)
        .subscribe(
          (response: IDivisionDto[]) => {
            this.divisions = response;
            this.dataSource = new MatTableDataSource(this.divisions);
          },
          (error) => {
            console.error('Error retrieving sections by cemetery id: ', error);
            this.divisions = [];
            this.dataSource = new MatTableDataSource(this.divisions);
          }
        );
    } else {
      this.divisions = [];
      this.dataSource = new MatTableDataSource(this.divisions);
    }
  }

  onDivisionChange(): void {
    const selectedDivision =
      this.sectionFormGroup.get('divisionNameCtrl')?.value;
    if (selectedDivision) {
      this.sectionService.GetSectionByDivisionId(selectedDivision).subscribe(
        (response: ISectionDto[]) => {
          this.sections = response;
          this.dataSource1 = new MatTableDataSource(this.sections);
        },
        (error) => {
          console.error('Error retrieving sections by division id: ', error);
          this.sections = [];
          this.dataSource1 = new MatTableDataSource(this.sections);
        }
      );
    } else {
      this.sections = [];
      this.dataSource1 = new MatTableDataSource(this.sections);
    }
  }
  onDivisionSectionChange() {
    const selectedDivision =
      this.compartmentFormGroup.get('divisionNameCtrl')?.value;
    if (selectedDivision) {
      this.sectionService
        .GetSectionByDivisionId(selectedDivision)
        .subscribe((response: ISectionDto[]) => {
          this.sections = response;
        });
    } else {
      this.sections = [];
    }
  }

  onSectionChange(): void {
    const selectedSection =
      this.compartmentFormGroup.get('sectionNameCtrl')?.value;
    if (selectedSection) {
      this.compartmentService
        .GetCompartmentBySectionId(selectedSection)
        .subscribe(
          (response: ICompartmentDto[]) => {
            this.compartments = response;
            this.dataSource2 = new MatTableDataSource(this.compartments);
          },
          (error) => {
            console.error('Error retrieving sections by division id: ', error);
            this.compartments = [];
            this.dataSource2 = new MatTableDataSource(this.compartments);
          }
        );
    } else {
      this.compartments = [];
      this.dataSource2 = new MatTableDataSource(this.compartments);
    }
  }

//checkbox functions
  onCheckboxDivisionChange(division: IDivisionDto) {
    this.selectedDivision = { ...division };
    this.setDivisionFormGroupValues(this.selectedDivision);
    this.division = this.selectedDivision;
    this.dataSource.data = [...this.divisions];
  }

  isSelectedDivision(division: IDivisionDto): boolean {
    return this.selectedDivision?.id === division.id;
  }
  onCheckboxSectionChange(section: ISectionDto) {

    this.selectedSection = { ...section };
    this.setSectionFormGroupValues(this.selectedSection);
    this.section = this.selectedSection;
    this.dataSource1.data = [...this.sections];
  }
  isSelectedSection(section: ISectionDto): boolean {
    return this.selectedSection?.id === section.id;
  }
  onCheckboxCompartmentChange(compartment: ICompartmentDto) {

    this.selectedCompartment = { ...compartment };
    this.setCompartmentFormGroupValues(this.selectedCompartment);
    this.compartment = this.selectedCompartment;
    this.dataSource2.data = [...this.compartments];
  }
  isSelectedCompartment(compartment: ICompartmentDto): boolean {
    return this.selectedCompartment?.id === compartment.id;
  }
  //delete functions
  deleteDivision(id: number) {
    this.divisionService.deleteDivision(id).subscribe(
      (response: IResponseModal) => {
        this.openResponseModal(response);
        this.internalService.emitRefreshDivisionTable();        
      },
      (error) => {
        console.error('Error deleting Division: ', error);
      }
    );
  }
  deleteSection(id: number) {
    this.sectionService.deleteSection(id).subscribe(
      (response: IResponseModal) => {
        this.openResponseModal(response);
        this.internalService.emitRefreshSectionTable();
      },
      (error) => {
        console.error('Error deleting Section: ', error);
      }
    );
  }
  deleteCompartment(id: number) {
    this.compartmentService.deleteCompartment(id).subscribe(
      (response: IResponseModal) => {
        this.openResponseModal(response);
        this.internalService.emitRefreshCompartmentTable();
      },
      (error) => {
        console.error('Error deleting Compartment: ', error);
      }
    );
  }
  openResponseModal(response: IResponseModal): void {
    const dialogRef = this.dialog.open(ResponsemodalComponent, {
      width: '250px',
      data: response,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }
  //clear button function
  clearDivision() {
    this.division.description = '';
    this.setDivisionFormGroupValues(this.division);
  }
  clearSection() {
    this.section.description = '';
    this.setSectionFormGroupValues(this.section);
  }
  clearCompartment() {
    this.compartment.description = '';
    this.setCompartmentFormGroupValues(this.compartment);
  }
  //division - new & Update 
  addDivision() {
    this.divisionObj.cemeteryId=this.cemetery.code
    const newDivision: IAddNewDivisionDto = this.divisionObj;
    this.divisionService.addNewDivision(newDivision).subscribe(
      (response) => {
        this.openResponseModal(response);
        this.internalService.emitRefreshDivisionTable();        
      },
      (error) => {
        console.error('Error adding new division:', error);
      }
    );
  }
  divisionUpdateClick(division: IDivisionDto) {
    const divisionUpdate: IDivisionDto = {
      id: division.id,
      description: this.divisionObj.description,
      cemeteryId: division.cemeteryId,
      sections: division.sections,
    };
    this.divisionService
      .updateDivisionDetails(division.id, divisionUpdate)
      .subscribe(
        (response) => {
          this.openResponseModal(response);
          this.internalService.emitRefreshDivisionTable();  
        },
        (error) => {
          console.error('Error updating division: ', error);
        }
      );
  }
  //section - new & Update 
  addSection() {
    this.sectionObj.cemeteryId = this.cemetery.code
    const newSection: IAddNewSectionDto = this.sectionObj;
    this.sectionService.addNewSection(newSection).subscribe(
      (response) => {
        this.openResponseModal(response);
        this.internalService.emitRefreshSectionTable();        
      },
      (error) => {
        console.error('Error adding new section:', error);
      }
    );
  }
  sectionUpdateClick(section: ISectionDto) {
    const sectionUpdate: ISectionDto = {
      id: section.id,
      description: this.sectionObj.description,
      divisionId: section.divisionId,
      cemeteryId: section.cemeteryId,
      compartments: section.compartments,
    };
    this.sectionService
      .updateSectionDetails(section.id, sectionUpdate)
      .subscribe(
        (response) => {
          this.openResponseModal(response);
          this.internalService.emitRefreshSectionTable(); 
        },
        (error) => {
          console.error('Error updating sections: ', error);
        }
      );
  }
  //compartment - new & Update 
  addCompartment() {
    this.compartmentObj.cemeteryId = this.cemetery.code
    const newCompartment: IAddNewCompartmentDto = this.compartmentObj;
    this.compartmentService.addNewCompartment(newCompartment).subscribe(
      (response) => {
        this.openResponseModal(response);
        this.internalService.emitRefreshCompartmentTable();
      },
      (error) => {
        console.error('Error adding new compartment:', error);
      }
    );
  }
  compartmentUpdateClick(compartment: ICompartmentDto) {
    const compartmentUpdate: ICompartmentDto = {
      id: compartment.id,
      description: this.compartmentObj.description,
      cemeteryId: compartment.cemeteryId,
      divisionId: compartment.divisionId,
      sectionId: compartment.sectionId,
      graves: compartment.graves,
    };
    console.log(compartmentUpdate)
    this.compartmentService
      .updateCompartmentDetails(compartment.id, compartmentUpdate)
      .subscribe(
        (response) => {
          this.openResponseModal(response);
          this.internalService.emitRefreshCompartmentTable();
        },
        (error) => {
          console.error('Error updating compartment: ', error);
        }
      );
  }
}
