import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IGraveOwnerSample, IGraveOwnershipSample } from '../grave-owners.component';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'app-grave-owners-view',
  templateUrl: './grave-owners-view.component.html',
  styleUrls: ['./grave-owners-view.component.css']
})
export class GraveOwnersViewComponent {
  displayedColumns: string[] = ['grave_id' ,'owner_id','Gname','Gsurname','address','select'];
  selection = new SelectionModel<IGraveOwnerSample>(true, []);
  dataSource = new MatTableDataSource<IGraveOwnerSample>(ownerObject);
 
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }
  constructor(public dialogRef: MatDialogRef<GraveOwnersViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IGraveOwnerSample) {}
    onUpdateViewOwners(): void {
      this.dialogRef.close();
    }
  }
  const ownerObject :IGraveOwnerSample[] =[
    
    {
      id:'12',
      Gname:'abc',
      Gsurname:'def',
      address:'aaaaa',
      locality:'Paola',
      telephone:9792892,
      cemeteryName:'njnb',
      GNo:109,
      DHfileNo :'1222/41',
      referenceNo:1233,
      dateOfApplication:new Date(),
      ownershipDate: new Date(),
      grave_id:133333,
      owner_id :'123A',
    },
    {
      id:'12',
      Gname:'abc',
      Gsurname:'def',
      address:'aaaaa',
      locality:'Paola',
      telephone:9792892,
      cemeteryName:'njnb',
      GNo:109,
      DHfileNo :'1222/41',
      referenceNo:1233,
      dateOfApplication:new Date(),
      ownershipDate: new Date(),
      grave_id:133333,
      owner_id :'123A',
    },

  ]