import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ITableCountDto } from 'src/app/burials/models/ITableCountDto';
import { IReferenceData } from 'src/app/models/ReferenceExcelDto.model';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonService } from 'src/app/services/common.service';
import { catchError,map,merge,of as observableOf, startWith, switchMap } from 'rxjs';
import { InternalCommunicationService } from 'src/app/services/internal-communication.service';

@Component({
  selector: 'app-reference-data',
  templateUrl: './reference-data.component.html',
  styleUrls: ['./reference-data.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ReferenceDataComponent implements OnInit,AfterViewInit{
  isLoadingResults = true;
  isRateLimitReached = false;
  resultsLength = 0;
  selectedData: ITableCountDto | null = null;
  referenceRecord!:IReferenceData[];
  referenceDatas:IReferenceData[]=[];
  referenceObj: IReferenceData= {
    id:0,
    permiNumber:'',
    month:'',
    type:'',
    name:'',
    burialPermitNumber:'',
    cemeteryNameCleaning:'',
    divisionNameCleaning:'',
    sectionNameCleaning:'',
    compartmentNameCleaning:'',
    graveNumberCleaning:'',
    cemeteryNameFrom :'',
    divisionNameFrom:'',
    sectionNameFrom:'',
    compartmentNameFrom:'',
    graveNumberFrom:'',
    levelFrom:'',
    cemeteryNameTo :'',
    divisionNameTo:'',
    sectionNameTo:'',
    compartmentNameTo:'',
    graveNumberTo:'',
    levelTo:'',
    bodiesRemainsForCleaning:0,
    bodiesRemainsForTransport:0,
    applicationDate:'',
    time:'',
    officerBau:'',
    supervisorName:'',
    marbleSlab:'',
    familyPresent:'',
	  undertakerName:'',
    cleaningDate:'',
    timeForCleaning:'',
    cleaningCarriedOut:'',
    bodiesRemainsCleaned:0,
    cleaningFee:'',
    transportFee:'',
    feePaid:'',
    receiptNo:'',
    receiptIssuedBy:'',
	  remarks:'',
  }
  displayedColumns: string[] = [
    'id',
    'permitno',
    'month',
    'type',
    'name',
    'burialpermit',
    'expand',
    
  ];
  dataSource !: MatTableDataSource<IReferenceData>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  displayedColumnsWithExpand = [...this.displayedColumns];
  expandedElement: ITableCountDto | undefined;
  
  constructor(
    private referenceService:CommonService,
    private internalService:InternalCommunicationService,
  ){ 
    this.dataSource = new MatTableDataSource(this.referenceDatas);
  }
 
    ngOnInit() {    
      
      this.internalService.permitUpdated$.subscribe(() => {
        this.refreshTableData();
      });
    }
    
    ngAfterViewInit():void {
    
      this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
      merge(this.sort.sortChange, this.paginator.page)
      merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.fetchData();
        }),
        map(data => {
         
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;
          
          if (data === null) {
            return [];
          }
          this.referenceRecord = data.results; 
          this.dataSource = new MatTableDataSource(this.referenceRecord);        
          this.dataSource.sort = this.sort;
          this.resultsLength = data.totalItems;
          
          return data;
        }),
      )
      .subscribe();
  
    } 
    fetchData() {
      return this.referenceService.getAllReferenceData(
        this.sort.active,
        this.sort.direction,
        this.paginator.pageIndex + 1,
        this.paginator.pageSize
      ).pipe(catchError(() => observableOf(null)));
    }
    refreshTableData() {
      this.isLoadingResults = true;
      this.fetchData().subscribe(data => {
        this.isLoadingResults = false;
        if (data === null) {
          return;
        }
        this.referenceRecord = data.results; 
        this.dataSource = new MatTableDataSource(this.referenceRecord);        
        this.dataSource.sort = this.sort;
        this.resultsLength = data.totalItems;
      });
    } 
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.selectedData = null;
  }
 
  expandCollapse(referenceObj: any): void {
    this.expandedElement = this.expandedElement === referenceObj ? null : referenceObj;
}

}
