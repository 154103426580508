<div class="dialog-header">
  <button mat-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="container-popup">
  <div class="form">   
    <span class="mat-title" mat-dialog-title><b>Edit Grave Details</b> </span> 
    <form [formGroup]="editGraveFormGroup">
   
      <mat-dialog-content>
        <div class="box">
          
          <mat-form-field>
            <mat-label>Cemetery Type</mat-label>
            <mat-select formControlName="cemeteryTypeCtrl"  (selectionChange)="onCemeteryTypeChange($event.value)">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let cemeteryType of cemeteryTypes" [value]="cemeteryType.id">
                {{ cemeteryType.description }}
              </mat-option> 
            </mat-select>                  
          </mat-form-field>
          <mat-form-field>
            <mat-label>Cemetery</mat-label>
            <mat-select formControlName="cemeteryCtrl"  (selectionChange)="onCemeteryChange()">                   
              <mat-option *ngFor="let cemetery of cemeteries" [value]="cemetery.code">
                {{ cemetery.name }}
              </mat-option>
            </mat-select>                  
          </mat-form-field>       
        </div>

        <div class="box">
          <mat-form-field>
            <mat-label>Division</mat-label>
            <mat-select formControlName="divisionCtrl"  (selectionChange)="onDivisionChange()">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let division of divisions" [value]="division.id">
                {{ division.description }}
              </mat-option>
            </mat-select>                    
          </mat-form-field>
          <mat-form-field>
            <mat-label>Section</mat-label>
            <mat-select formControlName="sectionCtrl"  (selectionChange)="onSectionChange()">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let section of sections" [value]="section.id">
                {{ section.description }}
              </mat-option>
            </mat-select>                    
          </mat-form-field>
          <mat-form-field>
            <mat-label>Compartment</mat-label>
            <mat-select formControlName="compartmentCtrl">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let compartment of compartments" [value]="compartment.id">
                {{ compartment.description }}
              </mat-option>
            </mat-select>                    
          </mat-form-field>
        </div>
        <mat-divider></mat-divider>
        <div class="box">
        <mat-form-field>
          <mat-label>Grave Type</mat-label>
          <mat-select formControlName="graveTypeCtrl"  (selectionChange)="onGraveTypeChange($event.value)">              
            <mat-option *ngFor="let graveType of graveTypes" [value]="graveType.id">
              {{ graveType.description }}
            </mat-option> 
          </mat-select>                  
        </mat-form-field>        
          <mat-form-field>
            <mat-label>Grave Size</mat-label>
            <mat-select formControlName="graveSizeCtrl">
              <mat-option *ngFor="let graveSize of graveSizes" [value]="graveSize.id">
                {{ graveSize.description }}
              </mat-option>
            </mat-select>                    
          </mat-form-field>
        </div>
        <div class="box">

          <mat-form-field>                    
            <mat-label>D/H FILE NO</mat-label>
            <input matInput placeholder="DHFile" formControlName="DHFileCtrl" required>
          </mat-form-field> 
          <mat-form-field>                    
            <mat-label>Grave Referenece</mat-label>
            <input matInput placeholder="Greference" formControlName="graveReferenceCtrl" required>
          </mat-form-field>                      
        </div>
        <div class="box">
          <mat-form-field>                    
            <mat-label>Grave No</mat-label>
            <input matInput placeholder="GraveNo" formControlName="graveNoCtrl" required>
          </mat-form-field>                
          <mat-form-field>
            <mat-label>Grave Status</mat-label>
            <mat-select formControlName="graveStatusCtrl">
              <mat-option *ngFor="let status of statuses" [value]="status.id">
                {{ status.description }}
              </mat-option>
            </mat-select>                    
          </mat-form-field>  
           <mat-form-field>
            <mat-label>Sealed</mat-label>
            <mat-select formControlName="isSealedCtrl">
              <mat-option [value]= 0>No</mat-option> 
              <mat-option [value]= 1>Yes</mat-option>                             
            </mat-select>  
            </mat-form-field>                            
        </div>
        <div class="box">
          <mat-form-field>
            <mat-label>Remarks</mat-label>
            <textarea matInput placeholder="Remarks" formControlName="remarksCtrl"></textarea>
          </mat-form-field>
        </div>
          <div class="buttonalign">
            <button mat-raised-button color="primary" (click)="graveUpdateClick()"><mat-icon>update</mat-icon> Update </button>
            <button mat-raised-button style="background-color: rgb(143, 141, 141)" [mat-dialog-close] cdkFocusInitial><mat-icon>close</mat-icon> Close</button>
          </div>
      </mat-dialog-content>
    </form>
  </div>
</div>
