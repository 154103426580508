<form [formGroup]="burialDetailsFormGroup">
  <div id="date_filter" class="box">
    <mat-card-title fxShow="true" fxHide.lt-md>Burial Date From</mat-card-title>
    <mat-form-field id="date_filter">
      <input matInput [matDatepicker]="startDatePicker" placeholder="Start date" formControlName="dateFromCtrl" >
      <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #startDatePicker></mat-datepicker>
    </mat-form-field>
    
    <mat-card-title fxShow="true" fxHide.lt-md>Burial Date To</mat-card-title>
    <mat-form-field id="date_filter">
      <input matInput [matDatepicker]="endDatePicker" placeholder="End date" formControlName="dateToCtrl">
      <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #endDatePicker></mat-datepicker>
    </mat-form-field>
  </div>        

  <!--SEARCH BOX AND BUTTON-->
  <div class="box" fxShow="true" fxHide.lt-md>          
    <mat-form-field>
      <mat-label>Search</mat-label>
      <input matInput placeholder="Search by" type="text" formControlName="searchCtrl" >
    </mat-form-field>
    <div class= "buttonpart">
      <button mat-raised-button id="search_btn"  color="primary"(click)="searchClick()">Search</button>
    </div>
  </div>  

</form>


<!--LINE DIVIDER-->
<mat-divider></mat-divider>          

<!--TABLE FILTER-->
<div id="searchandfilter_box" class="box">          
  <mat-form-field class="mat-form-field">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Burial Reference Number" #input>
  </mat-form-field>
</div>

<!--TABLE-->
<div class="box">

    <div class="table-container ">
      <div *ngIf="isLoadingResults || isRateLimitReached" class="table-loading-shade">
        <div *ngIf="isLoadingResults">
          <mat-spinner></mat-spinner>
        </div>
        <div *ngIf="isRateLimitReached" class="table-rate-limit-reached">
          Loading...
        </div>
      </div>
    </div>

  <section class="table-table-container  mat-elevation-z1" tabindex="0">
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort >
      <ng-container matColumnDef="permitNumber" sticky>
        <th mat-header-cell *matHeaderCellDef>No</th>
        <td mat-cell *matCellDef="let burialPermit">{{burialPermit.permitNumber}}</td>
      </ng-container>

      <ng-container matColumnDef="deceasedIdCardNumber" sticky>
        <th mat-header-cell *matHeaderCellDef>Id Card</th>
        <td mat-cell *matCellDef="let burialPermit">{{burialPermit.deceasedIdCardNumber}}</td>
      </ng-container>

      <ng-container matColumnDef="deceasedName" >
        <th mat-header-cell *matHeaderCellDef>Deceased Person</th>
        <td mat-cell *matCellDef="let burialPermit">{{burialPermit.deceasedName + ' &nbsp;'+ burialPermit.deceasedSurName}}</td>
      </ng-container>

      <!-- <ng-container matColumnDef="deceasedSurName" >
        <th mat-header-cell *matHeaderCellDef>Surname</th>
        <td mat-cell *matCellDef="let burialPermit">{{burialPermit.deceasedSurName}}</td>
      </ng-container> -->

      <ng-container matColumnDef="graveNo">
        <th mat-header-cell *matHeaderCellDef >Grave No</th>
        <td mat-cell *matCellDef="let burialPermit">{{burialPermit.graveNo}}</td>
      </ng-container>

      <ng-container matColumnDef="burialDate">
        <th mat-header-cell *matHeaderCellDef fxShow="true" fxHide.lt-md>Burial Date</th>
        <td mat-cell *matCellDef="let burialPermit" fxShow="true" fxHide.lt-md>{{formatDate(burialPermit.burialDate)}}</td>
      </ng-container>

      <ng-container matColumnDef="deathDate">
        <th mat-header-cell *matHeaderCellDef fxShow="true" fxHide.lt-md>Death Date</th>
        <td mat-cell *matCellDef="let burialPermit" fxShow="true" fxHide.lt-md>{{formatDate(burialPermit.deathDate)}}</td>
      </ng-container>

      <ng-container matColumnDef="burialPermitStatusName">
        <th mat-header-cell *matHeaderCellDef >Status</th>
        <td mat-cell *matCellDef="let burialPermit" [ngClass]="{'status-current': burialPermit.burialPermitStatusName === 'Current'}">
         <b> {{burialPermit.burialPermitStatusName}}</b>
        </td>
      </ng-container>

      <ng-container matColumnDef="levelId">
        <th mat-header-cell *matHeaderCellDef fxShow="true" fxHide.lt-md>Level</th>
        <td mat-cell *matCellDef="let burialPermit" fxShow="true" fxHide.lt-md>
          <ng-container *ngIf="burialPermit.burialTypeId == 2">
            /{{burialPermit.levelId}}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="dateCleaned">
        <th mat-header-cell *matHeaderCellDef >Cleaned Date</th>
        <td mat-cell *matCellDef="let burialPermit">
         <b> {{formatDate(burialPermit.dateCleaned)}}</b>
        </td>
      </ng-container>
      <ng-container matColumnDef="dateTransported">
        <th mat-header-cell *matHeaderCellDef >Transported Date</th>
        <td mat-cell *matCellDef="let burialPermit">
         <b> {{formatDate(burialPermit.dateTrasnported)}}</b>
        </td>
      </ng-container>

      <ng-container matColumnDef="dcr" stickyEnd>
        <th mat-header-cell *matHeaderCellDef fxShow="true" fxHide.lt-md>DCR</th>
        <td mat-cell *matCellDef="let burialPermit" fxShow="true" fxHide.lt-md>
          <mat-checkbox class="example-margin" 
          [checked]="burialPermit.ocdIsReceived === true" 
          (change)="onOdcCheckboxChange($event,burialPermit)">
        </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="action" stickyEnd>
        <th mat-header-cell *matHeaderCellDef fxShow="true" fxHide.lt-md>Action</th>
        <td mat-cell *matCellDef="let burialPermit" fxShow="true" fxHide.lt-md>

          <!-- VIEW BUTTON -->
          <button title="VIEW BURIAL INFORMATION" mat-icon-button color="primary" (click)="viewBurialPermitClick(burialPermit)">
            <mat-icon>info</mat-icon>
          </button>
  
          <!-- EDIT BUTTON -->
          <button title="EDIT" mat-icon-button color="primary" (click)="editBurialPermitClick(burialPermit)"
          [disabled]="burialPermit.burialStatusId === 1">
            <mat-icon>edit</mat-icon>
          </button>

          <!-- REISSUE BUTTON -->
          <button title="RE-ISSUE" mat-icon-button color="primary" (click)="reissueBurialPermitClick(burialPermit)">
            <mat-icon>note_add</mat-icon>
          </button>

          <!-- HISTORICAL DATA BUTTON -->
          <button title="HISTORAL DATA" mat-icon-button color="primary" (click)="previewBurialPermitClick(burialPermit)">
            <mat-icon>library_books</mat-icon>
          </button>

            <!-- REPRINT BUTTON -->
            <button title="RE-PRINT DOCUMENTS" mat-icon-button color="primary" [matMenuTriggerFor]="menu" [disabled]="burialPermit.dateCleaned && !burialPermit.dateTrasnported">
              <mat-icon>print</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="printBurialPermitClick(burialPermit)"  >
                <span>Burial Permit</span>
              </button>
              <button mat-menu-item (click)="printAcknowledgementReceipt(burialPermit)" [disabled]="!burialPermit.ocdIsReceived">
                <span>Acknowledgement Receipt</span>
              </button>
            </mat-menu>
        </td>          
      </ng-container>

      <ng-container matColumnDef="confirm" stickyEnd>
        <th mat-header-cell *matHeaderCellDef fxShow="true" fxHide.lt-md>Confirmed</th>
        <td mat-cell *matCellDef="let burialPermit" fxShow="true" fxHide.lt-md>

          <!-- CONFIRM BUTTON -->
          <mat-slide-toggle
            title="CONFIRM"
            color="primary"
            [checked]="burialPermit.burialStatusId === 1"
            [disabled]="isToggleDisabled(burialPermit.burialStatusId)"
            (change)="confirmBurialPermitClick($event, burialPermit)">
          </mat-slide-toggle>
        </td>
        
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
   
  </section>
</div>    
<mat-paginator class="paginator" [pageSizeOptions]="[25,100,200]" [length]="resultsLength" aria-label="Select page of burial permits"></mat-paginator>