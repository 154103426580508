<div class="dialog-header">
    <button mat-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
</div>

<div id="mainContainer">
    
    <mat-spinner [diameter]="100" *ngIf="isLoading"></mat-spinner>

    <br />

    <iframe id='pdfPreview'></iframe>

    <div id="contentContainer">

        <!-- ############ BURIAL ############ -->
        <div #contentToPrint id="contentToPrint" *ngIf="burialPermitPreview.burialTypeId == 2">

            <div class="form_header">
                <table>  
                    <tr> 
                        <td colspan="1" id="form_header_permitno"><p>PERMIT NO<br /><b>{{burialPermitPreview.permitNo}}</b></p></td>
                        <td colspan="1" id="form_header_img"><img src="assets/logo_burial_template2.png"></td> 
                    </tr> 
                    <tr>
                        <td colspan="2" id="form_header_title"><h1>BURIAL PERMIT</h1></td> 
                    </tr>
                </table> 
            </div>
            
            <div class="form_header2">
                <p>In accordance with Chapter 10 – Code of Police Laws – Part VI – Article 141</p> 
                <p>Permit is granted to allow the <b>Burial In Grave</b> of the human remains hereunder <br />described in <b>{{burialPermitPreview.cemeteryName}}</b> at least 24 hours after <b>{{burialPermitPreview.timeOfDeath}}</b> on <b>{{formatDate(this.burialPermitPreview.dateOfDeath)}}</b></p> 
            </div> 

            <div class="form_body">
                <table>
                    <tr>
                        <td colspan="10" ><b><u>PARTICULARS REGARDING THE DECEASED PERSON</u></b></td>
                    </tr>
                    <tr>
                        <td colspan="3">Name:<br /><b>{{ burialPermitPreview.name }}</b></td>
                        <td colspan="3">Surname:<br /><b>{{ burialPermitPreview.surname }}</b></td>
                        <td colspan="2">ID Card / Passport No:<br /><b>{{ burialPermitPreview.idCardNumber }}</b></td>
                        <td colspan="2">Gender:<br /><b>{{ burialPermitPreview.gender }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="3">Place of Birth:<br /><b>{{ burialPermitPreview.placeOfBirth}}</b></td>
                        <!-- <td colspan="1">Age:<br /><b>{{ calculateAge(burialPermitPreview.dateOfBirth) }}</b></td> -->
                        <td colspan="1">Age:<br /><b>{{ calculateAge(burialPermitPreview.dateOfBirth, burialPermitPreview.dateOfDeath) }}</b></td>
                        <td colspan="2">DOB:<br /><b>{{ formatDate(burialPermitPreview.dateOfBirth) }}</b></td>
                        <td colspan="3">Profession:<br /><b>{{ burialPermitPreview.profession }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="10">Last Place of Residence:<br /><b>{{ burialPermitPreview.address ? burialPermitPreview.address : '' }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="4">Father's Name:<br /><b>{{ burialPermitPreview.fatherName }}</b></td>
                        <td colspan="3">Father's Surname:<br /><b>{{ burialPermitPreview.fatherSurname }}</b></td>
                        <td colspan="3">Status:<br /><b>{{ burialPermitPreview.fatherStatus }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="4">Mother’s Name:<br /><b>{{ burialPermitPreview.motherName }}</b></td>
                        <td colspan="3">Maiden Surname:<br /><b>{{ burialPermitPreview.motherSurname }}</b></td>
                        <td colspan="3">Status:<br /><b>{{ burialPermitPreview.motherStatus }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="4">Marital Status:<br /><b>{{ burialPermitPreview.maritalStatus }}</b></td>
                        <td colspan="3">Husband/Wife Name:<br /><b>{{ burialPermitPreview.spouseName }}</b></td>
                        <td colspan="3">Husband/Maiden Surname:<br /><b>{{ burialPermitPreview.spouseSurname }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="2">Date of Death:<br /><b>{{ formatDate(burialPermitPreview.dateOfDeath)  }}</b></td>
                        <td colspan="2">Time of death:<br /><b>{{ burialPermitPreview.timeOfDeath }}</b></td>
                        <td colspan="3">Place of Death:<br /><b>{{ burialPermitPreview.placeOfDeath }}</b></td>
                        <td colspan="3">Cause of Death:<br /><b>{{ burialPermitPreview.causeOfDeath }}</b></td>
                    </tr>
                </table>
            </div> 

            <div class="form_body"> 
                <table>
                    <tr>
                        <td colspan="6"><b><u>GRAVE DETAILS</u></b></td>
                    </tr>
                    <tr>
                        <td colspan="1"><b>TO</b></td>
                        <td colspan="5">Cemetery Name: <b>{{ burialPermitPreview.cemeteryName }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="1">Division:<br /><b>{{ burialPermitPreview.divisionName }}</b></td>
                        <td colspan="1">Section:<br /><b>{{  burialPermitPreview.sectionName }}</b></td>
                        <td colspan="1">Compartment:<br /><b>{{  burialPermitPreview.compartmentName }}</b></td>
                        <td colspan="1">Number:<br /><b>{{ burialPermitPreview.graveNo}}</b></td>
                        <td colspan="1">Level:<br /><b>&nbsp;</b></td> <!-- ALWAYS EMPTY (TO REVERT:   {{ burialPermitPreview.levelName}}   ) -->
                        <td colspan="1">Right of burial:<br /><b>{{ burialPermitPreview.burialRight}}</b></td>
                    </tr>
                    <tr>
                        <td colspan="2">Date of Burial: <b></b></td>
                        <td colspan="4">Cemetery Curator: <b>___________________________</b></td>
                    </tr>
                </table>
            </div>

            <div class="form_body2">
                <table>
                    <tr>
                        <td colspan="4">Authorised by: <b></b></td>
                        <td colspan="2">Official Stamp: <b>____________________</b></td>
                    </tr>
                    <tr>
                        <td colspan="6">Date of issue of Burial Permit: <b>{{formatDate(burialPermitPreview.issuedDate)}}</b></td>
                    </tr>
                </table>
            </div>

            <div class="form_footer">
                <p>DEPARTMENT FOR HEALTH REGULATION</p>
                <p>ENVIRONMENTAL HEALTH DIRECTORATE</p>
                <p>BURIALS REGULATORY UNIT</p>
                <p>Cimiterju Sta. Marija Addolorata, Paola PLA9010</p>
                <p>Tel: 356 21693508,  356 21675936   e.mail: burials.ph&#64;gov.mt</p>
            </div>            
        </div>

        <!-- ############ CREMATION ############ -->
        <div #contentToPrint id="contentToPrint" *ngIf="burialPermitPreview.burialTypeId == 1">

            <div class="form_header">
                <table>  
                    <tr> 
                        <td colspan="1" id="form_header_permitno"><p>PERMIT NO<br /><b>{{burialPermitPreview.permitNo}}</b></p></td>
                        <td colspan="1" id="form_header_img"><img src="assets/logo_burial_template2.png"></td> 
                    </tr> 
                    <tr>
                        <td colspan="2" id="form_header_title"><h1>BURIAL PERMIT</h1></td> 
                    </tr>
                </table>  
            </div>
            
            <div class="form_header2">
                <p>In accordance with Chapter 10 – Code of Police Laws – Part VI – Article 141</p> 
                <p>Permit is granted to allow the <b>CREMATION</b> of the human remains hereunder <br />described to <b>{{burialPermitPreview.country}}</b> at least 24 hours after <b>{{burialPermitPreview.timeOfDeath}}</b> on <b>{{formatDate(this.burialPermitPreview.dateOfDeath)}}</b></p> 
            </div> 

            <div class="form_body">
                <table>
                    <tr>
                        <td colspan="10" ><b><u>PARTICULARS REGARDING THE DECEASED PERSON</u></b></td>
                    </tr>
                    <tr>
                        <td colspan="3">Name:<br /><b>{{ burialPermitPreview.name }}</b></td>
                        <td colspan="3">Surname:<br /><b>{{ burialPermitPreview.surname }}</b></td>
                        <td colspan="2">ID Card / Passport No:<br /><b>{{ burialPermitPreview.idCardNumber }}</b></td>
                        <td colspan="2">Gender:<br /><b>{{ burialPermitPreview.gender }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="3">Place of Birth:<br /><b>{{ burialPermitPreview.placeOfBirth}}</b></td>
                        <!-- <td colspan="1">Age:<br /><b>{{ calculateAge(burialPermitPreview.dateOfBirth) }}</b></td> -->
                        <td colspan="1">Age:<br /><b>{{ calculateAge(burialPermitPreview.dateOfBirth, burialPermitPreview.dateOfDeath) }}</b></td>
                        <td colspan="2">DOB:<br /><b>{{ formatDate(burialPermitPreview.dateOfBirth) }}</b></td>
                        <td colspan="3">Profession:<br /><b>{{ burialPermitPreview.profession }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="10">Last Place of Residence:<br /><b>{{ burialPermitPreview.address ? burialPermitPreview.address  : '' }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="4">Father's Name:<br /><b>{{ burialPermitPreview.fatherName }}</b></td>
                        <td colspan="3">Father's Surname:<br /><b>{{ burialPermitPreview.fatherSurname }}</b></td>
                        <td colspan="3">Status:<br /><b>{{ burialPermitPreview.fatherStatus }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="4">Mother’s Name:<br /><b>{{ burialPermitPreview.motherName }}</b></td>
                        <td colspan="3">Maiden Surname:<br /><b>{{ burialPermitPreview.motherSurname }}</b></td>
                        <td colspan="3">Status:<br /><b>{{ burialPermitPreview.motherStatus }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="4">Marital Status:<br /><b>{{ burialPermitPreview.maritalStatus }}</b></td>
                        <td colspan="3">Husband/Wife Name:<br /><b>{{ burialPermitPreview.spouseName }}</b></td>
                        <td colspan="3">Husband/Maiden Surname:<br /><b>{{ burialPermitPreview.spouseSurname }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="2">Date of Death:<br /><b>{{ formatDate(burialPermitPreview.dateOfDeath)  }}</b></td>
                        <td colspan="2">Time of death:<br /><b>{{ burialPermitPreview.timeOfDeath }}</b></td>
                        <td colspan="3">Place of Death:<br /><b>{{ burialPermitPreview.placeOfDeath }}</b></td>
                        <td colspan="3">Cause of Death:<br /><b>{{ burialPermitPreview.causeOfDeath }}</b></td>
                    </tr>
                </table>
            </div> 

            <div class="form_body">
                <table>
                    <tr>
                        <td colspan="6"><b><u>CREMATION DETAILS</u></b></td>
                    </tr>
                    <tr>
                        <td colspan="1"><b>TO</b></td>
                        <td colspan="5">Country Name: <b>{{burialPermitPreview.country}}</b></td>
                    </tr>
                    <tr>
                        <td colspan="2">Date of Burial: <b></b></td>
                        <td colspan="4">Cemetery Curator: <b>___________________________</b></td>
                    </tr>
                </table>
            </div>

            <div class="form_body">
                <p>No objection for the human remains described above to be cremated, provided cremation occurs in accordance with the laws and regulations of the country receiving the human remains in a duly licensed crematorium.</p>
            </div>

            <div class="form_body2">
                <table>
                    <tr>
                        <td colspan="4">Authorised by: <b></b></td>
                        <td colspan="2">Official Stamp: <b>____________________</b></td>
                    </tr>
                    <tr>
                        <td colspan="6">Date of issue of Burial Permit: <b>{{formattedCurrentDate}}</b></td>
                    </tr>
                </table>
            </div>

            <div class="form_footer">
                <p>DEPARTMENT FOR HEALTH REGULATION</p>
                <p>ENVIRONMENTAL HEALTH DIRECTORATE</p>
                <p>BURIALS REGULATORY UNIT</p>
                <p>Cimiterju Sta. Marija Addolorata, Paola PLA9010</p>
                <p>Tel: 356 21693508,  356 21675936   e.mail: burials.ph&#64;.mt</p>
            </div>            
        </div>

        <!-- ############ BURIAL AT SEA ############ -->
        <div #contentToPrint id="contentToPrint" *ngIf="burialPermitPreview.burialTypeId == 3">

            <div class="form_header">
                <table>  
                    <tr> 
                        <td colspan="1" id="form_header_permitno"><p>PERMIT NO<br /><b>{{burialPermitPreview.permitNo}}</b></p></td>
                        <td colspan="1" id="form_header_img"><img src="assets/logo_burial_template2.png"></td> 
                    </tr> 
                    <tr>
                        <td colspan="2" id="form_header_title"><h1>BURIAL PERMIT</h1></td> 
                    </tr>
                </table> 
            </div>
            
            <div class="form_header2">
                <p>In accordance with Chapter 10 – Code of Police Laws – Part VI – Article 141</p> 
                <p>Permit is granted to allow the <b>BURIAL AT SEA</b> of the human remains hereunder <br />described in <b>{{burialPermitPreview.burialLocation}}</b> at least 24 hours after <b>{{burialPermitPreview.timeOfDeath}}</b> on <b>{{formatDate(this.burialPermitPreview.dateOfDeath)}}</b></p>
            </div> 

            <div class="form_body">
                <table>
                    <tr>
                        <td colspan="10" ><b><u>PARTICULARS REGARDING THE DECEASED PERSON</u></b></td>
                    </tr>
                    <tr>
                        <td colspan="3">Name:<br /><b>{{ burialPermitPreview.name }}</b></td>
                        <td colspan="3">Surname:<br /><b>{{ burialPermitPreview.surname }}</b></td>
                        <td colspan="2">ID Card / Passport No:<br /><b>{{ burialPermitPreview.idCardNumber }}</b></td>
                        <td colspan="2">Gender:<br /><b>{{ burialPermitPreview.gender }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="3">Place of Birth:<br /><b>{{ burialPermitPreview.placeOfBirth}}</b></td>
                        <!-- <td colspan="1">Age:<br /><b>{{ calculateAge(burialPermitPreview.dateOfBirth) }}</b></td> -->
                        <td colspan="1">Age:<br /><b>{{ calculateAge(burialPermitPreview.dateOfBirth, burialPermitPreview.dateOfDeath) }}</b></td>
                        <td colspan="2">DOB:<br /><b>{{ formatDate(burialPermitPreview.dateOfBirth) }}</b></td>
                        <td colspan="3">Profession:<br /><b>{{ burialPermitPreview.profession }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="10">Last Place of Residence:<br /><b>{{ burialPermitPreview.address ? burialPermitPreview.address  : '' }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="4">Father's Name:<br /><b>{{ burialPermitPreview.fatherName }}</b></td>
                        <td colspan="3">Father's Surname:<br /><b>{{ burialPermitPreview.fatherSurname }}</b></td>
                        <td colspan="3">Status:<br /><b>{{ burialPermitPreview.fatherStatus }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="4">Mother’s Name:<br /><b>{{ burialPermitPreview.motherName }}</b></td>
                        <td colspan="3">Maiden Surname:<br /><b>{{ burialPermitPreview.motherSurname }}</b></td>
                        <td colspan="3">Status:<br /><b>{{ burialPermitPreview.motherStatus }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="4">Marital Status:<br /><b>{{ burialPermitPreview.maritalStatus }}</b></td>
                        <td colspan="3">Husband/Wife Name:<br /><b>{{ burialPermitPreview.spouseName }}</b></td>
                        <td colspan="3">Husband/Maiden Surname:<br /><b>{{ burialPermitPreview.spouseSurname }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="2">Date of Death:<br /><b>{{ formatDate(burialPermitPreview.dateOfDeath)  }}</b></td>
                        <td colspan="2">Time of death:<br /><b>{{ burialPermitPreview.timeOfDeath }}</b></td>
                        <td colspan="3">Place of Death:<br /><b>{{ burialPermitPreview.placeOfDeath }}</b></td>
                        <td colspan="3">Cause of Death:<br /><b>{{ burialPermitPreview.causeOfDeath }}</b></td>
                    </tr>
                </table>
            </div> 

            <div class="form_body">
                <table>
                    <tr>
                        <td colspan="6"><b><u>BURIAL AT SEA DETAILS</u></b></td>
                    </tr>
                    <tr>
                        <td colspan="6">Location of Burial: <b>{{burialPermitPreview.burialLocation}}</b></td>
                    </tr>
                    <tr>
                        <td colspan="2">Date of Burial: <b></b></td>
                        <td colspan="4">Environmental Health Officer: <b>{{burialPermitPreview.healthOfficer}}</b></td>
                    </tr>
                </table>
            </div>

            <div class="form_body2">
                <table>
                    <tr>
                        <td colspan="4">Authorised by: <b></b></td>
                        <td colspan="2">Official Stamp: <b>____________________</b></td>
                    </tr>
                    <tr>
                        <td colspan="6">Date of issue of Burial Permit: <b>{{formatDate(burialPermitPreview.issuedDate)}}</b></td>
                    </tr>
                </table>
            </div>

            <div class="form_footer">
                <p>DEPARTMENT FOR HEALTH REGULATION</p>
                <p>ENVIRONMENTAL HEALTH DIRECTORATE</p>
                <p>BURIALS REGULATORY UNIT</p>
                <p>Cimiterju Sta. Marija Addolorata, Paola PLA9010</p>
                <p>Tel: 356 21693508,  356 21675936   e.mail: burials.ph&#64;gov.mt</p>
            </div>            
        </div>

        <!-- ############ REPATRIATION ############ -->
        <div #contentToPrint id="contentToPrint" *ngIf="burialPermitPreview.burialTypeId == 4">

            <div class="form_header">
                <table>  
                    <tr> 
                        <td colspan="1" id="form_header_permitno"><p>PERMIT NO<br /><b>{{burialPermitPreview.permitNo}}</b></p></td>
                        <td colspan="1" id="form_header_img"><img src="assets/logo_burial_template2.png"></td> 
                    </tr> 
                    <tr>
                        <td colspan="2" id="form_header_title"><h1>BURIAL PERMIT</h1></td> 
                    </tr>
                </table> 
            </div>
            
            <div class="form_header2">
                <p>In accordance with Chapter 10 – Code of Police Laws – Part VI – Article 141</p> 
                <p>Permit is granted to allow the Repatriation for Burial of the human remains hereunder <br />described to <b>{{burialPermitPreview.country}}</b> at least 24 hours after <b>{{burialPermitPreview.timeOfDeath}}</b> on <b>{{formatDate(this.burialPermitPreview.dateOfDeath)}}</b></p> 
            </div> 

            <div class="form_body">
                <table>
                    <tr>
                        <td colspan="10" ><b><u>PARTICULARS REGARDING THE DECEASED PERSON</u></b></td>
                    </tr>
                    <tr>
                        <td colspan="3">Name:<br /><b>{{ burialPermitPreview.name }}</b></td>
                        <td colspan="3">Surname:<br /><b>{{ burialPermitPreview.surname }}</b></td>
                        <td colspan="2">ID Card / Passport No:<br /><b>{{ burialPermitPreview.idCardNumber }}</b></td>
                        <td colspan="2">Gender:<br /><b>{{ burialPermitPreview.gender }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="3">Place of Birth:<br /><b>{{ burialPermitPreview.placeOfBirth}}</b></td>
                        <!-- <td colspan="1">Age:<br /><b>{{ calculateAge(burialPermitPreview.dateOfBirth,burialPermitPreview.dateOfDeath) }}</b></td> -->
                        <td colspan="1">Age:<br /><b>{{ calculateAge(burialPermitPreview.dateOfBirth, burialPermitPreview.dateOfDeath) }}</b></td>

                        <td colspan="2">DOB:<br /><b>{{ formatDate(burialPermitPreview.dateOfBirth) }}</b></td>
                        <td colspan="3">Profession:<br /><b>{{ burialPermitPreview.profession }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="10">Last Place of Residence:<br /><b>{{ burialPermitPreview.address ? burialPermitPreview.address : '' }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="4">Father's Name:<br /><b>{{ burialPermitPreview.fatherName }}</b></td>
                        <td colspan="3">Father's Surname:<br /><b>{{ burialPermitPreview.fatherSurname }}</b></td>
                        <td colspan="3">Status:<br /><b>{{ burialPermitPreview.fatherStatus }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="4">Mother’s Name:<br /><b>{{ burialPermitPreview.motherName }}</b></td>
                        <td colspan="3">Maiden Surname:<br /><b>{{ burialPermitPreview.motherSurname }}</b></td>
                        <td colspan="3">Status:<br /><b>{{ burialPermitPreview.motherStatus }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="4">Marital Status:<br /><b>{{ burialPermitPreview.maritalStatus }}</b></td>
                        <td colspan="3">Husband/Wife Name:<br /><b>{{ burialPermitPreview.spouseName }}</b></td>
                        <td colspan="3">Husband/Maiden Surname:<br /><b>{{ burialPermitPreview.spouseSurname }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="2">Date of Death:<br /><b>{{ formatDate(burialPermitPreview.dateOfDeath)  }}</b></td>
                        <td colspan="2">Time of death:<br /><b>{{ burialPermitPreview.timeOfDeath }}</b></td>
                        <td colspan="3">Place of Death:<br /><b>{{ burialPermitPreview.placeOfDeath }}</b></td>
                        <td colspan="3">Cause of Death:<br /><b>{{ burialPermitPreview.causeOfDeath }}</b></td>
                    </tr>
                </table>
            </div>

            <div class="form_body">
                <p>No objection for the human remains described above to be repatriated, provided repatriation occurs in accordance with the laws and regulations of the country receiving the human remains.</p>
            </div>

            <div class="form_body2">
                <table>
                    <tr>
                        <td colspan="4">Authorised by: <b></b></td>
                        <td colspan="2">Official Stamp: <b>____________________</b></td>
                    </tr>
                    <tr>
                        <td colspan="6">Date of issue of Burial Permit: <b>{{formatDate(burialPermitPreview.issuedDate)}}</b></td>
                    </tr>
                </table>
            </div>

            <div class="form_footer">
                <p>DEPARTMENT FOR HEALTH REGULATION</p>
                <p>ENVIRONMENTAL HEALTH DIRECTORATE</p>
                <p>BURIALS REGULATORY UNIT</p>
                <p>Cimiterju Sta. Marija Addolorata, Paola PLA9010</p>
                <p>Tel: 356 21693508,  356 21675936   e.mail: burials.ph&#64;gov.mt</p>
            </div>            
        </div>

        <!-- ############ TRANSPORT (LOCAL) ############ -->
        <div #contentToPrint id="contentToPrint" *ngIf="burialPermitPreview.burialTypeId == 5 && burialPermitPreview.transportTypeId == 1">

            <div class="form_header">
                <table>  
                    <tr> 
                        <td colspan="1" id="form_header_permitno"><p>PERMIT NO<br /><b>{{burialPermitPreview.permitNo}}</b></p></td>
                        <td colspan="1" id="form_header_img"><img src="assets/logo_burial_template2.png"></td> 
                    </tr> 
                    <tr>
                        <td colspan="2" id="form_header_title"><h1>BURIAL PERMIT</h1></td> 
                    </tr>
                </table> 
            </div>
            
            <div class="form_header2">
                <p>In accordance with Chapter 10 – Code of Police Laws – Part VI – Article 141</p> 
                <p>Permit is granted to allow the <b>Transportation</b> of the human remains hereunder <br />described in <b>{{burialPermitPreview.cemeteryNameTo}}</b> at least 24 hours after <b>{{burialPermitPreview.timeOfDeath}}</b> on <b>{{formatDate(this.burialPermitPreview.dateCleaned!)}}</b></p> 
            </div> 

            <div class="form_body">
                <table>
                    <tr>
                        <td colspan="10" ><b><u>PARTICULARS REGARDING THE DECEASED PERSON</u></b></td>
                    </tr>
                    <tr>
                        <td colspan="3">Name:<br /><b>{{ burialPermitPreview.name }}</b></td>
                        <td colspan="3">Surname:<br /><b>{{ burialPermitPreview.surname }}</b></td>
                        <td colspan="2">ID Card / Passport No:<br /><b>{{ burialPermitPreview.idCardNumber }}</b></td>
                        <td colspan="2">Gender:<br /><b>{{ burialPermitPreview.gender }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="3">Place of Birth:<br /><b>{{ burialPermitPreview.placeOfBirth}}</b></td>
                        <!-- <td colspan="1">Age:<br /><b>{{ calculateAge(burialPermitPreview.dateOfBirth) }}</b></td> -->
                        <td colspan="1">Age:<br /><b>{{ calculateAge(burialPermitPreview.dateOfBirth, burialPermitPreview.dateOfDeath) }}</b></td>
                        <td colspan="2">DOB:<br /><b>{{ formatDate(burialPermitPreview.dateOfBirth) }}</b></td>
                        <td colspan="3">Profession:<br /><b>{{ burialPermitPreview.profession }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="10">Last Place of Residence:<br /><b>{{ burialPermitPreview.address ? burialPermitPreview.address  : '' }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="4">Father's Name:<br /><b>{{ burialPermitPreview.fatherName }}</b></td>
                        <td colspan="3">Father's Surname:<br /><b>{{ burialPermitPreview.fatherSurname }}</b></td>
                        <td colspan="3">Status:<br /><b>{{ burialPermitPreview.fatherStatus }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="4">Mother’s Name:<br /><b>{{ burialPermitPreview.motherName }}</b></td>
                        <td colspan="3">Maiden Surname:<br /><b>{{ burialPermitPreview.motherSurname }}</b></td>
                        <td colspan="3">Status:<br /><b>{{ burialPermitPreview.motherStatus }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="4">Marital Status:<br /><b>{{ burialPermitPreview.maritalStatus }}</b></td>
                        <td colspan="3">Husband/Wife Name:<br /><b>{{ burialPermitPreview.spouseName }}</b></td>
                        <td colspan="3">Husband/Maiden Surname:<br /><b>{{ burialPermitPreview.spouseSurname }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="2">Date of Death:<br /><b>{{ formatDate(burialPermitPreview.dateOfDeath)  }}</b></td>
                        <td colspan="2">Time of death:<br /><b>{{ burialPermitPreview.timeOfDeath }}</b></td>
                        <td colspan="3">Place of Death:<br /><b>{{ burialPermitPreview.placeOfDeath }}</b></td>
                        <td colspan="3">Cause of Death:<br /><b>{{ burialPermitPreview.causeOfDeath }}</b></td>
                    </tr>
                </table>
            </div> 

            <div class="form_body"> 
                <table>
                    <tr>
                        <td colspan="6"><b><u>GRAVE DETAILS</u></b></td>
                    </tr>
                    <tr>
                        <td colspan="1"><b>TO</b></td>
                        <td colspan="5">Cemetery Name: <b>{{burialPermitPreview.cemeteryNameTo}}</b></td>
                    </tr>
                    <tr>
                        <td colspan="1">Division:<br /><b>{{burialPermitPreview.divisionNameTo}}</b></td>
                        <td colspan="1">Section:<br /><b>{{burialPermitPreview.sectionNameTo}}</b></td>
                        <td colspan="1">Compartment:<br /><b>{{burialPermitPreview.compartmentNameTo}}</b></td>
                        <td colspan="1">Number:<br /><b>{{burialPermitPreview.graveNoTo}}</b></td>
                        <td colspan="1">Level:<br /><b>{{burialPermitPreview.graveLevelTo}}</b></td>
                        <td colspan="1">Right of burial:<br /><b>{{burialPermitPreview.burialRight}}</b></td>
                    </tr>
                    <tr>
                        <td colspan="2">Date of Burial: <b></b></td>
                        <td colspan="4">Cemetery Curator: <b>___________________________</b></td>
                    </tr>
                </table>
                <br/>
                <mat-divider></mat-divider>
                <br/>
                <table>
                    <tr>
                        <td colspan="1"><b>FROM</b></td>
                        <td colspan="5">Cemetery Name: <b>{{burialPermitPreview.cemeteryName}}</b></td>
                    </tr>
                    <tr>
                        <td colspan="1">Division:<br /><b>{{burialPermitPreview.divisionName}}</b></td>
                        <td colspan="1">Section:<br /><b>{{burialPermitPreview.sectionName}}</b></td>
                        <td colspan="1">Compartment:<br /><b>{{burialPermitPreview.compartmentName}}</b></td>
                        <td colspan="1">Number:<br /><b>{{burialPermitPreview.graveNo}}</b></td>
                        <td colspan="1">Level:<br /><b>{{burialPermitPreview.levelName}}</b></td>
                    </tr>
                </table>
            </div>

            <div class="form_body2">
                <table>
                    <tr>
                        <td colspan="4">Authorised by: <b></b></td>
                        <td colspan="2">Official Stamp: <b>____________________</b></td>
                    </tr>
                    <tr>
                        <td colspan="6">Date of issue of Burial Permit: <b>{{formatDate(burialPermitPreview.issuedDate)}}</b></td>
                    </tr>
                </table>
            </div>

            <div class="form_footer">
                <p>DEPARTMENT FOR HEALTH REGULATION</p>
                <p>ENVIRONMENTAL HEALTH DIRECTORATE</p>
                <p>BURIALS REGULATORY UNIT</p>
                <p>Cimiterju Sta. Marija Addolorata, Paola PLA9010</p>
                <p>Tel: 356 21693508,  356 21675936   e.mail: burials.ph&#64;gov.mt</p>
            </div>            
        </div>

        <!-- ############ TRANSPORT (OTHER) ############ -->
        <div #contentToPrint id="contentToPrint" *ngIf="burialPermitPreview.burialTypeId == 5 && burialPermitPreview.transportTypeId == 2">

            <div class="form_header">
                <table>  
                    <tr> 
                        <td colspan="1" id="form_header_permitno"><p>PERMIT NO<br /><b>{{burialPermitPreview.permitNo}}</b></p></td>
                        <td colspan="1" id="form_header_img"><img src="assets/logo_burial_template2.png"></td> 
                    </tr> 
                    <tr>
                        <td colspan="2" id="form_header_title"><h1>BURIAL PERMIT</h1></td> 
                    </tr>
                </table> 
            </div>
            
            <div class="form_header2">
                <p>In accordance with Chapter 10 – Code of Police Laws – Part VI – Article 141</p> 
                <p>Permit is granted to allow the <b>Repatriation for Cremation</b> of the human remains hereunder <br />described in <b>{{burialPermitPreview.transportedTo}}</b> at least 24 hours after <b>{{burialPermitPreview.timeOfDeath}}</b> on <b>{{formatDate(this.burialPermitPreview.dateCleaned!)}}</b></p> 
            </div> 

            <div class="form_body">
                <table>
                    <tr>
                        <td colspan="10" ><b><u>PARTICULARS REGARDING THE DECEASED PERSON</u></b></td>
                    </tr>
                    <tr>
                        <td colspan="3">Name:<br /><b>{{ burialPermitPreview.name }}</b></td>
                        <td colspan="3">Surname:<br /><b>{{ burialPermitPreview.surname }}</b></td>
                        <td colspan="2">ID Card / Passport No:<br /><b>{{ burialPermitPreview.idCardNumber }}</b></td>
                        <td colspan="2">Gender:<br /><b>{{ burialPermitPreview.gender }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="3">Place of Birth:<br /><b>{{ burialPermitPreview.placeOfBirth}}</b></td>
                        <!-- <td colspan="1">Age:<br /><b>{{ calculateAge(burialPermitPreview.dateOfBirth) }}</b></td> -->
                        <td colspan="1">Age:<br /><b>{{ calculateAge(burialPermitPreview.dateOfBirth, burialPermitPreview.dateOfDeath) }}</b></td>
                        <td colspan="2">DOB:<br /><b>{{ formatDate(burialPermitPreview.dateOfBirth) }}</b></td>
                        <td colspan="3">Profession:<br /><b>{{ burialPermitPreview.profession }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="10">Last Place of Residence:<br /><b>{{ burialPermitPreview.address ? burialPermitPreview.address  : '' }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="4">Father's Name:<br /><b>{{ burialPermitPreview.fatherName }}</b></td>
                        <td colspan="3">Father's Surname:<br /><b>{{ burialPermitPreview.fatherSurname }}</b></td>
                        <td colspan="3">Status:<br /><b>{{ burialPermitPreview.fatherStatus }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="4">Mother’s Name:<br /><b>{{ burialPermitPreview.motherName }}</b></td>
                        <td colspan="3">Maiden Surname:<br /><b>{{ burialPermitPreview.motherSurname }}</b></td>
                        <td colspan="3">Status:<br /><b>{{ burialPermitPreview.motherStatus }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="4">Marital Status:<br /><b>{{ burialPermitPreview.maritalStatus }}</b></td>
                        <td colspan="3">Husband/Wife Name:<br /><b>{{ burialPermitPreview.spouseName }}</b></td>
                        <td colspan="3">Husband/Maiden Surname:<br /><b>{{ burialPermitPreview.spouseSurname }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="2">Date of Death:<br /><b>{{ formatDate(burialPermitPreview.dateOfDeath)  }}</b></td>
                        <td colspan="2">Time of death:<br /><b>{{ burialPermitPreview.timeOfDeath }}</b></td>
                        <td colspan="3">Place of Death:<br /><b>{{ burialPermitPreview.placeOfDeath }}</b></td>
                        <td colspan="3">Cause of Death:<br /><b>{{ burialPermitPreview.causeOfDeath }}</b></td>
                    </tr>
                </table>
            </div> 

            <div class="form_body"> 
                <table>
                    <tr>
                        <td colspan="6"><b><u>COUNTRY AND GRAVE DETAILS</u></b></td>
                    </tr>
                    <tr>
                        <td colspan="1"><b>TO</b></td>
                        <td colspan="5">Country Name: <b>{{burialPermitPreview.transportedTo}}</b></td>
                    </tr>
                    <tr>
                        <td colspan="2">Date of Burial: <b>{{ formatDate(burialPermitPreview.dateOfBurial) }}</b></td>
                        <td colspan="4">Cemetery Curator: <b>___________________________</b></td>
                    </tr>
                </table>
                <br/>
                <mat-divider></mat-divider>
                <br/>
                <table>
                    <tr>
                        <td colspan="1"><b>FROM</b></td>
                        <td colspan="5">Cemetery Name: <b>{{burialPermitPreview.cemeteryName}}</b></td>
                    </tr>
                    <tr>
                        <td colspan="1">Division:<br /><b>{{burialPermitPreview.divisionName}}</b></td>
                        <td colspan="1">Section:<br /><b>{{burialPermitPreview.sectionName}}</b></td>
                        <td colspan="1">Compartment:<br /><b>{{burialPermitPreview.compartmentName}}</b></td>
                        <td colspan="1">Number:<br /><b>{{burialPermitPreview.graveNo}}</b></td>
                        <td colspan="1">Level:<br /><b>{{burialPermitPreview.levelName}}</b></td>
                    </tr>
                </table>
            </div>

            <div class="form_body2">
                <table>
                    <tr>
                        <td colspan="4">Authorised by: <b></b></td>
                        <td colspan="2">Official Stamp: <b>____________________</b></td>
                    </tr>
                    <tr>
                        <td colspan="6">Date of issue of Burial Permit: <b>{{formatDate(burialPermitPreview.issuedDate)}}</b></td>
                    </tr>
                </table>
            </div>

            <div class="form_footer">
                <p>DEPARTMENT FOR HEALTH REGULATION</p>
                <p>ENVIRONMENTAL HEALTH DIRECTORATE</p>
                <p>BURIALS REGULATORY UNIT</p>
                <p>Cimiterju Sta. Marija Addolorata, Paola PLA9010</p>
                <p>Tel: 356 21693508,  356 21675936   e.mail: burials.ph&#64;gov.mt</p>
            </div>            
        </div>

    </div>
</div>