import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { IGraveOwnerDto } from '../models/GraveOwnerDto.model';
import { LocalityService } from '../services/locality.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { IGraveOwership } from '../models/GraveOwnershipDto.model';
import { IGraveDto } from '../models/GraveDto.model';
import { ILocalityDto } from '../models/LocalityDto.model';
import { IOwnershipTypeDto } from '../models/OwnershipTypeDto.model';
import { ITableCountDto } from '../burials/models/ITableCountDto';
import { OwnerService } from '../services/owner.service';
import { IResponseModal } from '../models/ResponseModal.model';
import { ResponsemodalComponent } from '../responsemodal/responsemodal.component';
import { MatDialog } from '@angular/material/dialog';
import { IAddOwnerDto } from '../models/AddOwnerDto.model';
import { IOwnerUpdateDto } from '../models/OwnerUpdateDto.model';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_FORMATS } from '../date-utils';

@Component({
  selector: 'app-ownership',
  templateUrl: './ownership.component.html',
  styleUrls: ['./ownership.component.css'],animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-GB'
    },
    {
      provide: DateAdapter, useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_FORMATS
    },
  ],
})
export class OwnershipComponent implements OnInit {
  @Input() grave! : IGraveDto;
  @Input() onlyActive: boolean = false;
  isLoadingResults = true;
  ownersFormGroup! : FormGroup;

   //dropdown variables
   ownershipTypes!:IOwnershipTypeDto[];
   localities! :ILocalityDto[];
   locality!:ILocalityDto;
   
 
   //main object dto 

   mainOwner!: IGraveOwnerDto;  
   owners! : IGraveOwnerDto[];
   currentsOwners !: IGraveOwnerDto[];
   ownershipts!: IGraveOwership[]
 
   //table
   dataSource!: MatTableDataSource<IGraveOwnerDto>;
   @ViewChild(MatPaginator) paginator!: MatPaginator;
   @ViewChild(MatSlideToggleModule) slidetoggle!: MatSlideToggleModule;
   @ViewChild(MatSort) sort!: MatSort;  
   expandedElement: IGraveOwnerDto | undefined;
   displayedColumns: string[] = [
     'id', 
     'idCardNumber', 
     //'refNo',
     'dhFile',
     'ownerName',
     'ownerSurname',
     'dateFrom',
     'dateTo',
     'expand',
     'action'];
   displayedColumnsWithExpand = [...this.displayedColumns];
   resultsLength = 0;
   selectedOwner: IGraveOwnerDto | null = null;

  constructor(    
    private _formBuilder: FormBuilder,
    private localitiesServices : LocalityService, 
    private ownerService : OwnerService,
    public dialog: MatDialog
  ){}
  ngOnInit(): void {
    const defaultMainOwner: IGraveOwnerDto = {
      id: 0,
      ownerName: '',
      ownerSurname: '',
      address1 : '',
      address2 : '',
      telephone1: '',
      telephone2 : '',
      ownershipTypeId : 0,
      isActive : true,
      localityId : 0,
      idCardNumber : '',
      refNo : '',
      dateFrom : new Date,
      dateTo :null,
      dhFile : '',
      isMainOwner: true,  
      isSelected : false,
    };

    this.getOwnershipType();
    this.getLocalities();
    this.getAllOwnersByGraveId(this.grave.id);
    
     this.mainOwner = this.currentsOwners && this.currentsOwners.length > 0
    ? this.filterMainOwner(this.currentsOwners)
    : defaultMainOwner;

    this.ownersFormGroup = this._formBuilder.group({     
      graveIdCtrl : [{value:this.grave ? this.grave.id : 0, disabled: true}],
      idCardNumberCtrl : [this.mainOwner ? this.mainOwner.idCardNumber : 0,[Validators.required,Validators.pattern('[a-zA-Z0-9]*')]],
      refNoCtrl : [this.mainOwner ? this.mainOwner.refNo : '',[Validators.required, Validators.pattern(/^[0-9,//\\-]*$/)]], 
      ownerNameCtrl : [this.mainOwner ? this.mainOwner.ownerName : '', [Validators.required, Validators.pattern(/^[a-zA-Z,().'`" +&\/\\-]*$/)]], 
      ownerSurNameCtrl : [this.mainOwner ? this.mainOwner.ownerSurname : '', [Validators.required, Validators.pattern(/^[a-zA-Z,().'`" +&\/\\-]*$/)]], 
      ownerAddressCtrl : [this.mainOwner ? this.mainOwner.address1 : '', [Validators.pattern(/^[a-zA-Z0-9,().'`" \/\\-]*$/)]],
      ownerAddress2Ctrl : [this.mainOwner ? this.mainOwner.address2 : '', [Validators.pattern(/^[a-zA-Z0-9,().'`" \/\\-]*$/)]],
      ownerTelephoneCtrl : [this.mainOwner ? this.mainOwner.telephone1 : '',[Validators.pattern('[0-9]*')]],
      ownerMobileCtrl : [this.mainOwner ? this.mainOwner.telephone2 : '',[Validators.pattern('[0-9]*')]],
      ownerShypTypeCtrl : [this.mainOwner ? this.mainOwner.ownershipTypeId : 0, [Validators.required, Validators.min(1)]],
      ownerLocalityCtrl : [this.mainOwner ? this.mainOwner.localityId : 0, [Validators.required, Validators.min(1)]],
      dateFromCtrl : [this.mainOwner ? this.mainOwner.dateFrom : 0],
      dateToCtrl: [this.mainOwner && this.mainOwner.dateTo ? this.mainOwner.dateTo : null],
      ownerDhFileCtrl : [this.mainOwner ? this.mainOwner.dhFile : '',[Validators.required, Validators.pattern(/^[a-zA-Z0-9,().'`" \/\\-]*$/)]],     
    },{validators: this.dateRangeValidator() });

    this.subscribeChanges();
    
  }

  subscribeChanges() {
    //OWNER TAB
    this.ownersFormGroup.get('graveIdCtrl')?.valueChanges.subscribe((value) => {          
      this.grave.id = value;
    });
    this.ownersFormGroup.get('ownerDhFileCtrl')?.valueChanges.subscribe((value) => {          
      this.mainOwner.dhFile = value;
    });
    this.ownersFormGroup.get('idCardNumberCtrl')?.valueChanges.subscribe((value) => { 
      this.mainOwner.idCardNumber = value;
    });
    this.ownersFormGroup.get('refNoCtrl')?.valueChanges.subscribe((value) => {          
      this.mainOwner.refNo = value;
    });
    this.ownersFormGroup.get('ownerNameCtrl')?.valueChanges.subscribe((value) => {          
      this.mainOwner.ownerName = value;
    });
    this.ownersFormGroup.get('ownerSurNameCtrl')?.valueChanges.subscribe((value) => {          
      this.mainOwner.ownerSurname = value;
    });
    this.ownersFormGroup.get('ownerAddressCtrl')?.valueChanges.subscribe((value) => {          
      this.mainOwner.address1 = value;
    });
    this.ownersFormGroup.get('ownerAddress2Ctrl')?.valueChanges.subscribe((value) => {          
      this.mainOwner.address2 = value;
    });
    this.ownersFormGroup.get('ownerTelephoneCtrl')?.valueChanges.subscribe((value) => {          
      this.mainOwner.telephone1 = value;
    });
    this.ownersFormGroup.get('ownerMobileCtrl')?.valueChanges.subscribe((value) => {          
      this.mainOwner.telephone2 = value;
    });
    this.ownersFormGroup.get('ownerShypTypeCtrl')?.valueChanges.subscribe((value) => {          
      this.mainOwner.ownershipTypeId = value;
    });
    this.ownersFormGroup.get('dateFromCtrl')?.valueChanges.subscribe((value) => {     
      this.mainOwner.dateFrom = this.utcDate(value);  
    });
    this.ownersFormGroup.get('dateToCtrl')?.valueChanges.subscribe((value) => {            
      this.mainOwner.dateTo = value ? this.utcDate(value) : null;  
  });

  }
  getOwnershipType(){
    this.ownerService.getOwnershipType().subscribe(
      (response : IOwnershipTypeDto[]) =>{
        this.ownershipTypes = response;      }, 
      (error) =>{})
  }
  getLocalities(){
    this.localitiesServices.getAllLocalities().subscribe(
      (response : ILocalityDto[]) =>{
        this.localities = response;

      }, (error)=>{

      })
  }

  getAllOwnersByGraveId(graveId: number) {
    this.ownerService.getAllOwnersByGraveId(graveId, null, null, 1, 100).subscribe(
      (response: ITableCountDto) => {       
        const defaultMainOwner: IGraveOwnerDto = {
          id: 0,
          ownerName: '',
          ownerSurname: '',
          address1: '',
          address2: '',
          telephone1: '',
          telephone2: '',
          ownershipTypeId: 0,
          isActive: true,
          localityId: 0,
          idCardNumber: '',
          refNo: '',
          dateFrom: new Date(),
          dateTo: null,
          dhFile: '',
          isMainOwner: true,
          isSelected: false,
        };       
        this.ownershipts = response.results;        
        const ownersArray = this.ownershipts.map((o: IGraveOwership) => ({
          ...o.graveOwnerDto,
          isSelected: false 
      }));
  
        if(this.onlyActive){
          this.owners = ownersArray.filter((o) => o != null && o.isActive === this.onlyActive);

        }else{
          this.owners = ownersArray.filter((o) => o != null);
        }

        this.currentsOwners = this.owners;  
        
        this.mainOwner = this.currentsOwners && this.currentsOwners.length > 0
          ? this.filterMainOwner(this.currentsOwners)
          : defaultMainOwner;  
        
        this.dataSource = new MatTableDataSource(this.owners);
      },
      (error) => {
        console.error('something went wrong:', error);
      }
    );
  }

  filterMainOwner(owners: IGraveOwnerDto[]): IGraveOwnerDto  {  
    if (owners.length > 0 ) {
      this.mainOwner = owners[0];
      
      this.selectedOwner = this.mainOwner;
      //this.isSelected(this.selectedOwner);
    }    
    return this.mainOwner;
  }

  dateRangeValidator(): ValidatorFn {
    return (formGroup: AbstractControl): { [key: string]: any } | null => {
      const startDate = formGroup.get('dateFromCtrl')?.value;
      const endDate = formGroup.get('dateToCtrl')?.value;  
      
      if (!startDate || !endDate) {
        return null;
      }  
      
      return endDate < startDate ? { 'dateRangeInvalid': true } : null;
    };
  }
    
    utcDate(localDate: any): Date | null {
      if (!(localDate instanceof Date)) {         
          localDate = new Date(localDate);
      }  
      if (isNaN(localDate.getTime())) {         
          return null;
      }  
      return new Date(Date.UTC(
          localDate.getFullYear(),
          localDate.getMonth(),
          localDate.getDate(),
          localDate.getHours(),
          localDate.getMinutes(),
          localDate.getSeconds()
      ));
    }

  onOwnershypTypeChange(newOwenerShipTypeId : number){
    this.mainOwner.ownershipTypeId = newOwenerShipTypeId

  }
  onWnerLocalityChange(newLocalityId : number) {  
  
    this.mainOwner.localityId = newLocalityId;
  }

  //Table
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }  
  
    this.selectedOwner = null;
  }
  expandCollapse(grave: any): void {
    this.expandedElement = this.expandedElement === grave ? null : grave;
  }

  onCheckboxChange(owner: IGraveOwnerDto) {
    this.owners.forEach((o) =>{
      if(o !== owner){
        o.isSelected = false
      }
    })
    owner.isSelected = !owner.isSelected;

    if (owner.isSelected) {
        this.selectedOwner = owner;
        this.setOwnersFormGroupValues(owner);
        this.mainOwner = owner;
    } else {
        this.selectedOwner = null;
       
    }
    this.dataSource.data = [...this.owners]; // Update data to reflect changes
  }

setOwnersFormGroupValues(mainOwner: IGraveOwnerDto) {
  const newValues = {
    graveIdCtrl: this.grave.id,
    idCardNumberCtrl: mainOwner.idCardNumber,
    refNoCtrl: mainOwner.refNo,
    ownerNameCtrl: mainOwner.ownerName,
    ownerSurNameCtrl: mainOwner.ownerSurname,
    ownerAddressCtrl: mainOwner.address1,
    ownerAddress2Ctrl: mainOwner.address2,
    ownerTelephoneCtrl: mainOwner.telephone1,
    ownerMobileCtrl: mainOwner.telephone2,
    ownerShypTypeCtrl: mainOwner.ownershipTypeId,
    ownerLocalityCtrl: mainOwner.localityId ?? 0,
    dateFromCtrl: mainOwner.dateFrom ? new Date(mainOwner.dateFrom) : new Date,
    dateToCtrl: mainOwner.dateTo ? new Date(mainOwner.dateTo) : null,
    ownerDhFileCtrl: mainOwner.dhFile
  };
  this.ownersFormGroup.setValue(newValues, { emitEvent: false }); 
} 


addOwner(mainOwner : IGraveOwnerDto){
  const newOwner = this.mapGraveOwnerToAddOwner(mainOwner)    
  this.ownerService.addOwner(newOwner).subscribe(
    (response) => {        
      this.openResponseModal(response);
      //this.currentsOwners.push(response.entity);
      this.ownerService.getAllOwnersByGraveId(this.grave.id,null,null,1,100).subscribe((response : ITableCountDto) =>{
        this.ownershipts = response.results;
        const ownersArray = this.ownershipts.map((o: IGraveOwership) => ({
          ...o.graveOwnerDto,
          isSelected: false 
      }));
        this.dataSource = new MatTableDataSource(ownersArray);
      }, (error) =>{
        console.log('something went wrong:', error)
      })      

    },(error) => {

    })
  }

  deleteOwner(id : number){
    this.ownerService.deleteOwner(id).subscribe(
      (response) => {
        this.openResponseModal(response);
        this.currentsOwners = this.currentsOwners.filter(o => o.id !== id )     
        this.dataSource = new MatTableDataSource(this.currentsOwners);
      }, (error) =>{

      })
  }

  clearOwner() {
    this.ownersFormGroup.reset({
      graveIdCtrl: this.grave.id,
      idCardNumberCtrl: '',
      refNoCtrl: '',
      ownerNameCtrl: '',
      ownerSurNameCtrl: '',
      ownerAddressCtrl: '',
      ownerAddress2Ctrl: '',
      ownerTelephoneCtrl: '',
      ownerMobileCtrl: '',
      ownerShypTypeCtrl: null,
      ownerLocalityCtrl: null,
      dateFromCtrl: null,
      dateToCtrl: null,
      ownerDhFileCtrl: ''
    });
    
    this.selectedOwner = null;
    
    const ownersArray = this.ownershipts.map((o: IGraveOwership) => ({
      ...o.graveOwnerDto,
      isSelected: false 
    }));
    this.owners = ownersArray.filter((o) => o != null);    
    this.dataSource = new MatTableDataSource(this.owners);
  }
  

   openResponseModal(response : IResponseModal): void {
    const dialogRef = this.dialog.open(ResponsemodalComponent, {
      width: '250px',
      data : response,
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  isMainOwner(event: MatSlideToggleChange){
    const isChecked = event.checked;
    this.mainOwner.isMainOwner = isChecked ? true: false;    

  }

  mapGraveOwnerToAddOwner(mainOwner: IGraveOwnerDto): IAddOwnerDto {
    return {      
      ownerName: mainOwner.ownerName,
      ownerSurname: mainOwner.ownerSurname,
      address1: mainOwner.address1,
      address2: mainOwner.address2,
      telephone1: mainOwner.telephone1,
      telephone2: mainOwner.telephone2,
      ownershipTypeId: mainOwner.ownershipTypeId,
      isActive: mainOwner.isActive,
      localityId: mainOwner.localityId,
      idCardNumber: mainOwner.idCardNumber,
      ownerShipDto: {
        dateFrom : mainOwner.dateFrom,
        dateTo: mainOwner.dateTo,
        dhFile : mainOwner.dhFile,
        refNo : mainOwner.refNo,
        isMainOwner : mainOwner.isMainOwner,
        ownershipTypeId : mainOwner.ownershipTypeId,
        graveId : this.grave.id,
        ownerId : mainOwner.id
      }
    };
  } 

  ownerUpdateClick(mainOwner : IGraveOwnerDto){
  
    const ownerUpdate : IOwnerUpdateDto = {
      ownerId: mainOwner.id,
      graveId: this.grave.id,
      ownerName : mainOwner.ownerName,
      ownerSurname : mainOwner.ownerSurname,
      address1 : mainOwner.address1,
      address2: mainOwner.address2,
      telephone1 :mainOwner.telephone1,
      telephone2: mainOwner.telephone2,
      ownershipTypeId: mainOwner.ownershipTypeId,
      isActive: mainOwner.isActive,
      localityId : mainOwner.localityId ,
      idCardNumber : mainOwner.idCardNumber,      
      ownerShipUpdateDto :  {
        dateFrom : mainOwner.dateFrom,
        dateTo : mainOwner.dateTo,
        isMainOwner : mainOwner.isMainOwner,
        dhFile : mainOwner.dhFile,
        graveId : this.grave.id,
        ownerId : mainOwner.id,
        ownershipTypeId : mainOwner.ownershipTypeId,       
        refNo : mainOwner.refNo
      }
      
    }         
    
    this.ownerService.updateOwner(this.mainOwner.id,ownerUpdate).subscribe(    
      (response) =>{
        this.openResponseModal(response);
        this.currentsOwners = this.currentsOwners.filter(o => o.id !== mainOwner.id )     

        this.currentsOwners.push(mainOwner);
        this.dataSource = new MatTableDataSource(this.currentsOwners);
      },
      (error)=>{
        console.error('Error updating Owner: ', error);
      })
  
  }

  isSelected(owner: IGraveOwnerDto): boolean {   
    this.mainOwner = owner;
    this.selectedOwner = owner;
    return owner.isSelected;
  }  
  
}
