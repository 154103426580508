import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ILocalityDto } from '../models/LocalityDto.model';
import { Observable, catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class LocalityService {

  private baseUrl = `${environment.apiUrl}locality/`; 
  constructor(private http: HttpClient, private authService: AuthService) { }

  getAllLocalities(pageNumber: number = 1, pageSize: number = 100): Observable<ILocalityDto[]> {    
    let params = new HttpParams();
    const headers = this.authService.getAuthHeaders();
    params = params.append('pageNumber', pageNumber.toString());
    params = params.append('pageSize', pageSize.toString());

    return this.http.get<ILocalityDto[]>(this.baseUrl + 'GetAllLocalities', { params, headers })
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };

  getLocalityById(id:number): Observable<ILocalityDto> {    
    const url = `${this.baseUrl}${id}`;
    const headers = this.authService.getAuthHeaders();
    return this.http.get<ILocalityDto>(url,{headers})
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };
}
