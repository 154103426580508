import { Component, ViewChild, ElementRef, Input, AfterViewInit, Renderer2, Inject, Optional, ChangeDetectorRef, NgZone, ViewEncapsulation } from '@angular/core';
import { jsPDF } from 'jspdf';
import { DatePipe } from '@angular/common';
import { LocalStorageService } from 'src/app/local-storage.service';
import { IPreViewBurialPermit } from 'src/app/models/PreViewBurialPermit.model';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-burial-template',
  templateUrl: './burial-template.component.html',
  styleUrls: ['./burial-template.component.css'],
})

export class BurialTemplateComponent implements AfterViewInit {
  
  @Input() burialPermitPreview!: IPreViewBurialPermit; 
  @ViewChild('contentToPrint', { static: false }) el!: ElementRef;
  title = 'angular-pdf-print';
  isLoading: boolean = false;

  constructor(private datePipe: DatePipe,
              private localStorageService: LocalStorageService,
              private renderer: Renderer2,
              private cdr: ChangeDetectorRef, // Inject ChangeDetectorRef
              private ngZone: NgZone, // Inject NgZone
              @Optional() @Inject(MAT_DIALOG_DATA) public data: any)
              {
                if (data) {
                  this.burialPermitPreview = data;
                  console.log(this.burialPermitPreview)
                }
              }

              ngAfterViewInit() {
                this.ngZone.run(() => {
                  this.isLoading = true;
                  this.cdr.detectChanges();
              
                  setTimeout(() => {
                    if (this.burialPermitPreview) {
                      this.makePDF().then(() => {
                        this.isLoading = false;
                        this.cdr.detectChanges();
                      });
                    } else {
                      console.warn('Burial permit preview data not available.');
                    }
                  });
                });
              }
              

  makePDF(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.el || !this.el.nativeElement) {
        console.error('Element to print is not available.');
        reject(new Error('Element to print is not available.'));
        return;
      }
  
      let pdf = new jsPDF({
        orientation: 'p', 
        unit: 'pt',
        format: 'a4' 
      });
  
      pdf.html(this.el.nativeElement, {
        callback: (pdf) => {
          const pdfDataUri = pdf.output('datauristring');
          this.fillIframe(pdfDataUri);
          resolve();
        },
        x: 0,
        y: 0
      });
    });
  }
  

  fillIframe(dataUri: string) {
    const iframe = document.getElementById('pdfPreview') as HTMLIFrameElement;
    if (iframe) {
      iframe.src = dataUri;
    }
  }

  formatDate(date: Date): string {
    if(date > new Date('1900-01-02 00:00:00.000')){
      return this.datePipe.transform(date, 'dd/MM/yyyy') || ''; 
    }else{
      return '';
    }
  }

  // calculateAge(dateOfBirth: Date): string {

  //   let age_response: string;

  //   if(this.burialPermitPreview.sourceSystemId != 1){ //NEW DATA
  //     if (dateOfBirth) {
        
  //       const today = new Date();
  //       let years = today.getFullYear() - dateOfBirth.getFullYear();
  //       let months = today.getMonth() - dateOfBirth.getMonth();
  //       let days = today.getDate() - dateOfBirth.getDate();   

  //       if (months <= 0 && days < 0) {
  //         years--;
  //         months += 12;
  //       } else if (months < 0) {
  //         years--;
  //         months += 12;
  //       }

  //       if (days < 0) {
  //         months--;
  //         const prevMonthDays = new Date(today.getFullYear(), today.getMonth(), 0).getDate();
  //         days += prevMonthDays;
  //       }

  //       if (years === 0) {
  //         if (months === 0) {
  //           age_response = `${days} days`;  
  //         } else {
  //           age_response = `${months} months and ${days} days`;
  //         }
  //       } else {
  //         age_response = `${years} years`;
  //       }
  //       return age_response;
  //     } else {
  //       return "";
  //     }
  //   }else if (this.burialPermitPreview.sourceSystemId == 1){ //LEGACY DATA
  //     if(this.burialPermitPreview.age){
  //       age_response = this.burialPermitPreview.age.toString();
  //       return age_response    
  //     }else{
  //       return "";
  //     }
  //   }else{
  //     return "";
  //   }
  // }

  
  calculateAge(dateOfBirth: Date, dateOfDeath: Date): string {
    let age_response: string;
  
    if (dateOfBirth >= dateOfDeath) {
      return "0 years";
    }
    if (dateOfBirth && dateOfDeath) {
      let years = dateOfDeath.getFullYear() - dateOfBirth.getFullYear();
      let months = dateOfDeath.getMonth() - dateOfBirth.getMonth();
      let days = dateOfDeath.getDate() - dateOfBirth.getDate();
  
      // Adjust for negative months or days
      if (months < 0 || (months === 0 && days < 0)) {
        years--;
        months += (months < 0 ? 12 : 0);
      }
  
      if (days < 0) {
        months--;
        const prevMonthDays = new Date(dateOfDeath.getFullYear(), dateOfDeath.getMonth(), 0).getDate();
        days += prevMonthDays;
      }
  
      if (years === 0) {
        if (months === 0) {
          age_response = `${days} days`;
        } else {
          age_response = `${months} months and ${days} days`;
        }
      } else {
        age_response = `${years} years`;
      }
  
      return age_response;
    }
  
    return "";
  }
  
  storeObject(key: string, object: any) {
    const objectToStore = object;
    this.localStorageService.setObject(key, objectToStore);
  }

  getCurrentDate(): string {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = currentDate.getFullYear();

    return `${day}/${month}/${year}`;
  }

  formattedCurrentDate = this.getCurrentDate();

  getFormattedBurialPermitDate(dateOfDeath: Date): string {
    const burialpermitdate = new Date(dateOfDeath);
    burialpermitdate.setDate(burialpermitdate.getDate() + 1);
    const day = String(burialpermitdate.getDate()).padStart(2, '0');
    const month = String(burialpermitdate.getMonth() + 1).padStart(2, '0'); 
    const year = String(burialpermitdate.getFullYear());

    return `${day}/${month}/${year}`;
  }
}