import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Role } from '../models/Role.models';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { RoleModuleDto } from '../models/RoleModuleDto.model';
import { UserPermissionsDto } from '../models/UserPermissionsDto.model';
import { RoleDto } from '../models/RoleDto.model';


type AccessLevel = 'Guess'|'Read' | 'Editor' | 'Full';
@Injectable({
  providedIn: 'root'
})

export class RoleService {
 private user! : UserPermissionsDto;
  private usersRoles!: RoleModuleDto[];
  private baseUrl = `${environment.apiUrl}authservice/`;
  private rolesLoaded = false;

  constructor(private http: HttpClient) { }

  fetchUserRoles(): Observable<UserPermissionsDto> {
    //const headers = this.authService.getAuthHeaders();
    return this.http.get<UserPermissionsDto>(this.baseUrl +'GetUserPermissions' );
  }
  fetchUserRoleByEmail(email : string ): Observable<UserPermissionsDto> {
    
    const url = `${this.baseUrl}GetUserPermissionByEmail?email=${email}`;
    return this.http.get<UserPermissionsDto>(url);
  }

  setUserRoles(user: UserPermissionsDto): void {     
     
    if (!user || !user.role || !user.role.rolesModule) {
      console.error('Invalid user data. Cannot set user roles.');
      this.usersRoles = [];
      return;
    }
    
    var roles = user.role.rolesModule.flatMap(x => x);
    this.usersRoles = roles;
    localStorage.setItem('user', JSON.stringify(user)); 
    this.rolesLoaded = true;
}


  getAllRoleModules(): Observable<RoleModuleDto[]> {
    return this.http.get<RoleModuleDto[]>(`${this.baseUrl}GetAllRoleModules`);
  }

  getUser(): UserPermissionsDto {
    const user = localStorage.getItem('user');
    if (user) {
      this.user = JSON.parse(user);
      if (this.user && this.user.role && this.user.role.rolesModule) {
        this.usersRoles = this.user.role.rolesModule.flatMap(x => x);
        this.rolesLoaded = true; // Mark roles as loaded
      }
    }
    return this.user;
}

  clearUserRoles(): void {
    this.usersRoles = [];
    localStorage.removeItem('user');
  }

  addRole(role: RoleDto): Observable<RoleDto> {
    return this.http.post<RoleDto>('/api/roles', role);
  }

  updateRole(roleID: number, updatedRole: Role): Observable<void> {
    return of();
  }

  deleteRole(roleID: number): Observable<void> {
    return of();
  }



  getAllRoles(): Observable<RoleDto[]> {
    return this.http.get<RoleDto[]>(`${this.baseUrl}GetAllRoles`);
  }

  hasAccessToModule(moduleName: string, accessLevel: string = 'Full'): boolean {
    if (!this.rolesLoaded || !this.usersRoles || this.usersRoles.length === 0) {
        console.warn('User roles are not loaded or empty');
        return false;
    }

    return this.usersRoles.some(role => {
        if (this.isAccessLevel(role.accessLevel) && this.isAccessLevel(accessLevel)) {
            return role.moduleName === moduleName && this.hasAccess(role.accessLevel, accessLevel);
        }
        return false;
    });
  }

  hasAccess(userAccessLevel: AccessLevel, requiredAccessLevel: AccessLevel): boolean {
    const accessLevelPriorityMap: Record<AccessLevel, number> = {
        'Guess': 0,
        'Read': 1,
        'Editor': 2,
        'Full': 3
    };

    return accessLevelPriorityMap[userAccessLevel] >= accessLevelPriorityMap[requiredAccessLevel];
  }
  
  isAccessLevel(level: string): level is AccessLevel {
    return ['Guess', 'Read', 'Editor', 'Full'].includes(level);
  }
 
}
