<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <p>
    {{data.message}}
    <span *ngIf="data.id !== 0 && data.id !== -1">{{ data.id }}</span>
  </p>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="true">Close</button>
</div>
