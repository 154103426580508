<div class="dialog-header">
    <button mat-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
</div>

<html lang="mt">
<div id="mainContainer">

    <mat-spinner [diameter]="100" *ngIf="isLoading"></mat-spinner>

    <br />

    <iframe id='pdfPreview'></iframe>

    <div id="contentContainer">

        <div #contentToPrint id="contentToPrint">

            <div class="form_header">
                <table>  
                    <tr> 
                        <td id="form_header_title"><h1></h1></td> 
                        <td id="form_header_img"><img src="assets/logo_burial_template2.png"></td> 
                    </tr > 
                </table > 
            </div>
            
            <div class="form_header2">
                <p>{{getCurrentDate()}}</p>
                <b><p>To Commissioner {{disintermentData.undertaker}},</p></b>
                <p>Through this letter, you are being notified that during the year {{getNextYear()}}, there might be a cleaning of the common graves in Santa Marija Addolorata Cemetery from {{disintermentData.divisionName}}-{{disintermentData.sectionName}}-{{disintermentData.compartmentName}}, graves {{disintermentData.graveFrom}} to {{disintermentData.graveTo}}.</p>
                <p>In these graves, you had the following burials indicated below.</p>  
            </div> 

            <div class="form_body">
                <table id="data_table">
                    <thead>
                      <tr>
                        <th>Grave</th>
                        <th>Full Name</th>
                        <th>ID</th>
                        <th>Date of Death</th>
                        <th>Grave Address</th>
                        <th>Undertaker</th>
                        <th>Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of disintermentData.disinterment">
                        <td>{{ item.graveNoAndLevel }}</td>
                        <td>{{ item.fullName }}</td>
                        <td>{{ item.idCard }}</td>
                        <td>{{ item.dod | date: 'dd-MM-yyyy' }}</td>
                        <td>{{ item.graveAddress }}</td>
                        <td>{{ item.undertaker }}</td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
            </div> 

            <div class="form_header2">
                <p>Therefore, we are asking you to inform the relatives of those buried in these graves by the {{disintermentData.dateLimit | date: 'dd-MM-yyyy'}} that the cleaning of these graves will take place and report back to this office if they are interested in transferring their deceased to other private graves.</p>
                <p>In case this office does not receive any response by the indicated date, it will be understood that the relatives are not interested in transferring their deceased.</p>
                <p>Best regards,</p>
                <p>Paul Spiteri<br/>Higher Env. Health Practitioner<br/>Reg. No. 134</p>
            </div> 

            <div class="form_footer">
                <p>BURIALS REGULATORY UNIT</p>
                <p>Ċimiterju Sta. Marija Addolorata, Paola PLA9010</p>
                <p>t + 356 21675936   e burials.ph@gov.mt</p>
            </div>            
        </div>
        
    </div>   

</div>
</html>