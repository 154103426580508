<div class="body-container  fixed-left">
  <app-menu-bar fxShow="true" fxHide.lt-md></app-menu-bar>
</div>

<div class="body-container">
  <div class="header_container">
    <mat-divider></mat-divider>
    <h1>Reporting</h1>
    <mat-divider></mat-divider>
  </div>

<div class="container">
    
    <!-- <div class="form">
        <div class="box">
          <app-menu-bar fxShow="true" fxHide.lt-md></app-menu-bar>
        </div>
    </div> -->

    <div id ="container" class="form">

      <mat-accordion class="reporting-headers-align" [multi]="false">

        <form [formGroup]="burialFormGroup">

          <mat-expansion-panel hideToggle (opened)="disablePrintOptions()" (closed)="disablePrintOptions()">

            <mat-expansion-panel-header>
                <mat-panel-title><b>BURIALS REPORT</b></mat-panel-title>
                <mat-panel-description>Filter by 'DATE' range<mat-icon>border_color</mat-icon></mat-panel-description>
            </mat-expansion-panel-header>

            <div class="box">
              <p><i>Generates a report with the burials scheduled for a specific date range. Filter and press 'PROCESS' to continue.</i></p>
            </div>

            <div class="box">

              <mat-form-field>
                <mat-label>CEMETERY</mat-label>
                <mat-select formControlName="burial_cemeteryCtrl">
                  <mat-option *ngFor="let cemetery of cemeteries" [value]="cemetery.code">
                    {{ cemetery.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="!burialFormGroup.get('burial_cemeteryCtrl')?.value">
                  <span>Field required</span>
                </mat-error>  
              </mat-form-field>
        
              <mat-form-field>
                <mat-label>DATE FROM</mat-label>
                <input matInput [matDatepicker]="burial_pickerdateFrom" formControlName="burial_dateFromCtrl"  maxlength="10"/>
                <mat-datepicker-toggle matIconSuffix [for]="burial_pickerdateFrom">
                </mat-datepicker-toggle>
                <mat-datepicker #burial_pickerdateFrom></mat-datepicker>
                <mat-error>
                  <span *ngIf="!burialFormGroup.get('burial_dateFromCtrl')?.value">Field required</span>
                </mat-error>   
              </mat-form-field>
      
              <mat-form-field>
                <mat-label>DATE TO</mat-label>
                <input matInput [matDatepicker]="burial_pickerdateTo" formControlName="burial_dateToCtrl"  maxlength="10"/>
                <mat-datepicker-toggle matIconSuffix [for]="burial_pickerdateTo">
                </mat-datepicker-toggle>
                <mat-datepicker #burial_pickerdateTo></mat-datepicker>
                <mat-error>
                  <span *ngIf="!burialFormGroup.get('burial_dateToCtrl')?.value">Field required</span>
                </mat-error>   
              </mat-form-field>

            </div>

            <div class="box">
              <button id="process_btn" mat-flat-button color="primary" (click)="processData_burial()" [disabled]="!burialFormGroup.valid">PROCESS</button>
            </div> 

            <div *ngIf="showPrintOptions_burial" id="print_container4">

              <mat-divider></mat-divider>

              <div *ngIf="retrieving_data === false" id="no_data_found">
                <p>--- DATA NOT FOUND, TRY ANOTHER FILTERS AND PROCESS AGAIN ---</p>
              </div>

              <div *ngIf="retrieving_data === true" id="print_options" class="box">

                <app-csv-export [columns]="this.columns_csv" [data]="this.burial_data_csv"></app-csv-export>    

                <button id="print-btn" mat-fab (click)="printBurials()">
                  <mat-icon>print</mat-icon>
                </button>   
                
                <div class="box">
                  <p>EXPORT TO CSV ALL THE DATA OR PRINT THE REPORT</p>
                </div>
              
              </div>

            </div>

          </mat-expansion-panel>     
        
        </form>

        <form [formGroup]="burialsIssuedFormGroup">

          <mat-expansion-panel hideToggle>

            <mat-expansion-panel-header>
                <mat-panel-title><b>BURIALS ISSUED REPORT</b></mat-panel-title>
                <mat-panel-description>Filter by 'DATE' range<mat-icon>border_color</mat-icon></mat-panel-description>
            </mat-expansion-panel-header>

            <div class="box">
              <p><i>Generates a report with the burials issued for a specific date range. Filter and press 'PROCESS' to continue.</i></p>
            </div>

            <div class="box">

              <mat-form-field>
                <mat-label>CEMETERY</mat-label>
                <mat-select formControlName="burialsIssued_cemeteryCtrl">
                  <mat-option *ngFor="let cemetery of cemeteries" [value]="cemetery.code">
                    {{ cemetery.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="!burialsIssuedFormGroup.get('burialsIssued_cemeteryCtrl')?.value">
                  <span>Field required</span>
                </mat-error>  
              </mat-form-field>
        
              <mat-form-field>
                <mat-label>DATE FROM</mat-label>
                <input matInput [matDatepicker]="burialsIssued_pickerdateFrom" formControlName="burialsIssued_dateFromCtrl"  maxlength="10"/>
                <mat-datepicker-toggle matIconSuffix [for]="burialsIssued_pickerdateFrom">
                </mat-datepicker-toggle>
                <mat-datepicker #burialsIssued_pickerdateFrom></mat-datepicker>
                <mat-error>
                  <span *ngIf="!burialsIssuedFormGroup.get('burialsIssued_dateFromCtrl')?.value">Field required</span>
                </mat-error>   
              </mat-form-field>
      
              <mat-form-field>
                <mat-label>DATE TO</mat-label>
                <input matInput [matDatepicker]="burialsIssued_pickerdateTo" formControlName="burialsIssued_dateToCtrl"  maxlength="10"/>
                <mat-datepicker-toggle matIconSuffix [for]="burialsIssued_pickerdateTo">
                </mat-datepicker-toggle>
                <mat-datepicker #burialsIssued_pickerdateTo></mat-datepicker>
                <mat-error>
                  <span *ngIf="!burialsIssuedFormGroup.get('burialsIssued_dateToCtrl')?.value">Field required</span>
                </mat-error>   
              </mat-form-field>

            </div>

            <div class="box">
              <button id="process_btn" mat-flat-button color="primary" (click)="processData_burialsIssued()" [disabled]="!burialsIssuedFormGroup.valid">PROCESS</button>
            </div> 

            <div *ngIf="showPrintOptions_burialsIssued" id="print_container5">

              <mat-divider></mat-divider>

              <div *ngIf="retrieving_data === false" id="no_data_found">
                <p>--- DATA NOT FOUND, TRY ANOTHER FILTERS AND PROCESS AGAIN ---</p>
              </div>

              <div *ngIf="retrieving_data === true" id="print_options" class="box">

                <app-csv-export [columns]="this.columns_csv" [data]="this.burialsIssued_data_csv"></app-csv-export>    

                <button id="print-btn" mat-fab (click)="printBurialsIssued()">
                    <mat-icon>print</mat-icon>
                </button>             
                
                <div class="box">
                  <p>EXPORT TO CSV ALL THE DATA OR PRINT THE REPORT</p>
                </div>               
              
              </div>

            </div>

          </mat-expansion-panel>     
        
        </form>

        <form [formGroup]="disintermentFormGroup">

            <mat-expansion-panel hideToggle (opened)="disablePrintOptions()" (closed)="disablePrintOptions()">

                <mat-expansion-panel-header>
                    <mat-panel-title><b>DISINTERMENT REPORT</b></mat-panel-title>
                    <mat-panel-description>Filter by 'GRAVE NO' range<mat-icon>border_color</mat-icon></mat-panel-description>
                </mat-expansion-panel-header>

                <div class="box">
                  <p><i>Generates a report with the disinterments scheduled for a specific compartment and grave reange. Filter and press 'PROCESS' to continue.</i></p>
                </div>

                <div class="box" id="report_disinterment_container">
                    
                    <div class="box">

                        <mat-form-field>
                          <mat-label>CEMETERY</mat-label>
                          <mat-select formControlName="cemeteryCtrl" (selectionChange)="onCemeteryChange($event.value)">
                            <mat-option *ngFor="let cemetery of cemeteries" [value]="cemetery.code">
                              {{ cemetery.name }}
                            </mat-option>
                          </mat-select>
                          <mat-error *ngIf="!disintermentFormGroup.get('cemeteryCtrl')?.value">
                            <span>Field required</span>
                          </mat-error>  
                        </mat-form-field>
          
                        <mat-form-field>
                          <mat-label>DIVISION</mat-label>
                          <mat-select formControlName="divisionCtrl" (selectionChange)="onDivisionChange()">
                            <mat-option>None</mat-option>
                            <mat-option *ngFor="let division of divisions" [value]="division.id">
                              {{ division.description }}
                            </mat-option>
                          </mat-select>
                          <mat-error *ngIf="!disintermentFormGroup.get('divisionCtrl')?.value">
                            <span>Field required</span>
                          </mat-error>  
                        </mat-form-field>            
          
                        <mat-form-field>
                          <mat-label>SECTION</mat-label>
                          <mat-select formControlName="sectionCtrl" (selectionChange)="onSectionChange()">
                            <mat-option *ngFor="let section of sections" [value]="section.id">
                              {{ section.description }}
                            </mat-option>
                          </mat-select>
                          <mat-error *ngIf="!disintermentFormGroup.get('sectionCtrl')?.value">
                            <span>Field required</span>
                          </mat-error>  
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>COMPARTMENT</mat-label>
                            <mat-select formControlName="compartmentCtrl" (selectionChange)="onCompartmentChange()">
                              <mat-option *ngFor="let compartment of compartments" [value]="compartment.id">
                                {{ compartment.description }}
                              </mat-option>
                            </mat-select>
                            <mat-error *ngIf="!disintermentFormGroup.get('compartmentCtrl')?.value">
                              <span>Field required</span>
                            </mat-error>  
                        </mat-form-field>
          
                      </div>

                      <div class="box">

                        <mat-form-field>
                            <mat-label>GRAVE NO. FROM</mat-label>
                            <input matInput formControlName="graveFromCtrl" type="number"/>
                            <mat-error>
                                <span *ngIf="!disintermentFormGroup.get('graveFromCtrl')?.value">Field required</span>
                              </mat-error>
                        </mat-form-field>
                
                        <mat-form-field>
                            <mat-label>GRAVE NO. TO</mat-label>
                            <input matInput formControlName="graveToCtrl" type="number"/>
                            <mat-error>
                                <span *ngIf="!disintermentFormGroup.get('graveToCtrl')?.value">Field required</span>
                              </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>START OF CLEANING</mat-label>
                            <input matInput [matDatepicker]="pickerlimitDate" formControlName="dateLimitCtrl"  maxlength="10"/>
                            <mat-datepicker-toggle matIconSuffix [for]="pickerlimitDate">
                            </mat-datepicker-toggle>
                            <mat-datepicker #pickerlimitDate></mat-datepicker>
                            <mat-error>
                              <span *ngIf="!disintermentFormGroup.get('dateLimitCtrl')?.value">Field required</span>
                            </mat-error>   
                          </mat-form-field>

                    </div>

                    <div class="box">
                        <button id="process_btn" mat-flat-button color="primary" (click)="processData_disinterment()" [disabled]="!disintermentFormGroup.valid">PROCESS</button>
                    </div> 

                    <div *ngIf="showPrintOptions_disinterment" id="print_container_disinterment">

                      <mat-divider></mat-divider>

                      <div *ngIf="retrieving_data === false" id="no_data_found">
                        <p>--- DATA NOT FOUND, TRY ANOTHER FILTERS AND PROCESS AGAIN ---</p>
                      </div>
        
                      <div *ngIf="retrieving_data === true"  id="print_options" class="box">

                        <app-csv-export [columns]="this.columns_csv" [data]="this.data_csv"></app-csv-export>

                        <mat-divider id="vertical_divider" [vertical]="true"></mat-divider>
                      
                        <mat-form-field id="undertaker_dropdown">
                            <mat-label>Select an undertaker to print letter</mat-label>
                            <mat-select formControlName="undertakerCtrl">
                              <mat-option *ngFor="let undertaker of uniqueUndertakers" [value]="undertaker">
                                {{ undertaker }}
                              </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <button color="primary" mat-fab (click)="printDisinterment()" [disabled]="!undertaker">
                            <mat-icon>print</mat-icon>
                        </button>
                        
                        <div class="box">
                          <p>EXPORT TO CSV ALL THE DATA OR PRINT THE LETTER FOR EACH UNDERTAKER</p>
                        </div>
                      
                      </div>

                    </div>

              </div>

            </mat-expansion-panel>

        </form>

        <form [formGroup]="cleaningTransportFormGroup">

          <mat-expansion-panel hideToggle (opened)="disablePrintOptions()" (closed)="disablePrintOptions()">

              <mat-expansion-panel-header>
                  <mat-panel-title><b>CLEANING & TRANSPORT REPORT</b></mat-panel-title>
                  <mat-panel-description>Filter by 'DATE' range<mat-icon>border_color</mat-icon></mat-panel-description>
              </mat-expansion-panel-header>

              <div class="box">
                <p><i>Generates a report with the cleanings and transports scheduled for a specific date range. Filter and press 'PROCESS' to continue.</i></p>
              </div>
  
              <div class="box">

                <mat-form-field>
                  <mat-label>CEMETERY</mat-label>
                  <mat-select formControlName="cleaningTransport_cemeteryCtrl">
                    <mat-option *ngFor="let cemetery of cemeteries" [value]="cemetery.code">
                      {{ cemetery.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="!cleaningTransportFormGroup.get('cleaningTransport_cemeteryCtrl')?.value">
                    <span>Field required</span>
                  </mat-error>  
                </mat-form-field>
          
                <mat-form-field>
                  <mat-label>DATE FROM</mat-label>
                  <input matInput [matDatepicker]="cleaningTransport_pickerdateFrom" formControlName="cleaningTransport_dateFromCtrl"  maxlength="10"/>
                  <mat-datepicker-toggle matIconSuffix [for]="cleaningTransport_pickerdateFrom">
                  </mat-datepicker-toggle>
                  <mat-datepicker #cleaningTransport_pickerdateFrom></mat-datepicker>
                  <mat-error>
                    <span *ngIf="!cleaningTransportFormGroup.get('cleaningTransport_dateFromCtrl')?.value">Field required</span>
                  </mat-error>   
                </mat-form-field>
          
                <mat-form-field>
                  <mat-label>DATE TO</mat-label>
                  <input matInput [matDatepicker]="cleaningTransport_pickerdateTo" formControlName="cleaningTransport_dateToCtrl"  maxlength="10"/>
                  <mat-datepicker-toggle matIconSuffix [for]="cleaningTransport_pickerdateTo">
                  </mat-datepicker-toggle>
                  <mat-datepicker #cleaningTransport_pickerdateTo></mat-datepicker>
                  <mat-error>
                    <span *ngIf="!cleaningTransportFormGroup.get('cleaningTransport_dateToCtrl')?.value">Field required</span>
                  </mat-error>   
                </mat-form-field> 

              </div>

              <div class="box">
                <button id="process_btn" mat-flat-button color="primary" (click)="processData_cleaningTransport()" [disabled]="!cleaningTransportFormGroup.valid">PROCESS</button>
              </div> 

              <div *ngIf="showPrintOptions_cleaningTransport" id="print_container_cleaningTransport">

                <mat-divider></mat-divider>

                <div *ngIf="retrieving_data === false" id="no_data_found">
                  <p>--- DATA NOT FOUND, TRY ANOTHER FILTERS AND PROCESS AGAIN ---</p>
                </div>

                <div *ngIf="retrieving_data === true" id="print_options" class="box">

                  <!--<app-csv-export [columns]="this.columns_csv" [data]="this.cleaningTransport_data_csv"></app-csv-export>-->    

                  <table>
                    <thead>
                      <tr>
                        <th colspan="2">ALL GRAVES WITH A CLEANING/TRANSPORT SCHEDULED</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let graveIdRow of getUniqueGraveIds()">
                        <td>PERMIT NO: <b>{{graveIdRow.permitNo}}</b> GRAVE NO: <b>{{graveIdRow.graveId}}</b></td>
                        <td>
                          <button id="print-btn" mat-fab (click)="printCleaningTransport(graveIdRow.graveId)">
                            <mat-icon>print</mat-icon>
                          </button>        
                        </td>
                      </tr>
                    </tbody>
                  </table>                       
                
                </div>

              </div>

          </mat-expansion-panel>     
        
        </form>

        <form [formGroup]="odcFormGroup">

          <mat-expansion-panel hideToggle (opened)="disablePrintOptions()" (closed)="disablePrintOptions()">

            <mat-expansion-panel-header>
                <mat-panel-title><b>DEATH CERTIFICATE REPORT</b></mat-panel-title>
                <mat-panel-description>Filter by 'DATE' range<mat-icon>border_color</mat-icon></mat-panel-description>
            </mat-expansion-panel-header>

            <div class="box">
              <p><i>Generates a report with the permits with the original death certificate received for a specific date range. Filter and press 'PROCESS' to continue.</i></p>
            </div>

            <div class="box">

              <mat-form-field>
                <mat-label>CEMETERY</mat-label>
                <mat-select formControlName="odc_cemeteryCtrl">
                  <mat-option *ngFor="let cemetery of cemeteries" [value]="cemetery.code">
                    {{ cemetery.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="!odcFormGroup.get('odc_cemeteryCtrl')?.value">
                  <span>Field required</span>
                </mat-error>  
              </mat-form-field>
        
              <mat-form-field>
                <mat-label>DATE FROM</mat-label>
                <input matInput [matDatepicker]="odc_pickerdateFrom" formControlName="odc_dateFromCtrl"  maxlength="10"/>
                <mat-datepicker-toggle matIconSuffix [for]="odc_pickerdateFrom">
                </mat-datepicker-toggle>
                <mat-datepicker #odc_pickerdateFrom></mat-datepicker>
                <mat-error>
                  <span *ngIf="!odcFormGroup.get('odc_dateFromCtrl')?.value">Field required</span>
                </mat-error>   
              </mat-form-field>
      
              <mat-form-field>
                <mat-label>DATE TO</mat-label>
                <input matInput [matDatepicker]="odc_pickerdateTo" formControlName="odc_dateToCtrl"  maxlength="10"/>
                <mat-datepicker-toggle matIconSuffix [for]="odc_pickerdateTo">
                </mat-datepicker-toggle>
                <mat-datepicker #odc_pickerdateTo></mat-datepicker>
                <mat-error>
                  <span *ngIf="!odcFormGroup.get('odc_dateToCtrl')?.value">Field required</span>
                </mat-error>   
              </mat-form-field>

            </div>

            <div class="box">
              <button id="process_btn" mat-flat-button color="primary" (click)="processData_odc()" [disabled]="!odcFormGroup.valid">PROCESS</button>
            </div>
            
            <div *ngIf="showPrintOptions_odc" id="print_container_odc">

              <mat-divider></mat-divider>

              <div *ngIf="retrieving_data === false" id="no_data_found">
                <p>--- DATA NOT FOUND, TRY ANOTHER FILTERS AND PROCESS AGAIN ---</p>
              </div>

              <div *ngIf="retrieving_data === true" id="print_options" class="box">

                <app-csv-export [columns]="this.columns_csv" [data]="this.ocd_data_csv"></app-csv-export>    

                <button id="print-btn" mat-fab (click)="printOdc()">
                    <mat-icon>print</mat-icon>
                </button>                            

                <div class="box">
                  <p>EXPORT TO CSV ALL THE DATA OR PRINT THE REPORT</p>
                </div>
              
              </div>

            </div>

          </mat-expansion-panel>     
        
        </form>

      </mat-accordion>

    </div>

  </div>