<div class="container-popup">
    <div class="form">
      <form [formGroup]="cleaningEditFormGroup">    
        <span class="mat-title" mat-dialog-title><b>Edit Cleaning Details</b> </span>
        <mat-dialog-content>           
        <div class="box">
          <mat-form-field>
            <mat-label>Cleaning Permit</mat-label>              
            <input matInput placeholder="Cleaning Permit No" formControlName="cleaningPermitCtrl" />
            <mat-error>
              <span *ngIf="cleaningEditFormGroup.get('cleaningPermitCtrl')?.value && cleaningEditFormGroup.get('cleaningPermitCtrl')?.invalid && cleaningEditFormGroup.get('cleaningPermitCtrl')?.errors?.['pattern']">Invalid field format</span>
            </mat-error> 
          </mat-form-field>

          <!--<mat-form-field>
            <mat-label>Undertaker</mat-label>              
            <input matInput placeholder="Undertaker" formControlName="undertakerCtrl" />            
          </mat-form-field>-->
          
        </div>    
  
          <div class="box">
            <mat-form-field>
              <mat-label>Authorising Person</mat-label>              
              <input matInput placeholder="Authorising Person" formControlName="authorisingPersonCtrl" />
              <mat-error>
                <span *ngIf="cleaningEditFormGroup.get('authorisingPersonCtrl')?.value && cleaningEditFormGroup.get('authorisingPersonCtrl')?.invalid && cleaningEditFormGroup.get('authorisingPersonCtrl')?.errors?.['pattern']">Invalid field format</span>
              </mat-error> 
            </mat-form-field>
  
           <!-- <mat-form-field>
              <mat-label>Authorise By</mat-label>              
              <input matInput placeholder="Authorise By" formControlName="authoriseByCtrl" />
              <mat-error>
                <span *ngIf="cleaningEditFormGroup.get('authorisingPersonCtrl')?.value && cleaningEditFormGroup.get('authorisingPersonCtrl')?.invalid && cleaningEditFormGroup.get('authorisingPersonCtrl')?.errors?.['pattern']">Invalid field format</span>
              </mat-error> 
            </mat-form-field>
  
            <mat-form-field>
              <mat-label>Authorise Id</mat-label>              
              <input matInput placeholder="Authorise Id" formControlName="authoriseIdCtrl" />
              <mat-error>
                <span *ngIf="cleaningEditFormGroup.get('authoriseIdCtrl')?.value && cleaningEditFormGroup.get('authoriseIdCtrl')?.invalid && cleaningEditFormGroup.get('authoriseIdCtrl')?.errors?.['pattern']">Invalid field format</span>
              </mat-error> 
            </mat-form-field>      -->    
            
          </div>
  
          <div class="box">
            <mat-form-field>
              <mat-label>Marble Slab</mat-label>
              <mat-select formControlName="marbleSlabCtrl">
                <mat-option [value]= "0">No</mat-option>
                <mat-option [value]= "1">Yes</mat-option>  
                <mat-option [value]= "2">Unknown</mat-option>               
              </mat-select>
            </mat-form-field>           
  
            <mat-form-field>
              <mat-label>Supervisor</mat-label>
              <mat-select formControlName="supervisorCtrl" (selectionChange)="supervisorChange($event.value)">                
                <mat-option *ngFor="let supervisor of supervisors" [value]="supervisor.id">
                  {{ supervisor.name }} {{ supervisor.surname }}
                </mat-option>
              </mat-select>           
              <mat-error>
                <span *ngIf="!cleaningEditFormGroup.get('supervisorCtrl')?.value">Field required</span>
              </mat-error>   
            </mat-form-field>
          </div> 
          <div class="box">
            <mat-form-field>
              <mat-label>Time of Cleaning</mat-label>
              <input type="time" matInput placeholder= "Time of Cleaning" formControlName="timeForCleaningCtrl">
              <mat-error>
                <span *ngIf="cleaningEditFormGroup.get('timeForCleaningCtrl')?.value && cleaningEditFormGroup.get('timeForCleaningCtrl')?.invalid && cleaningEditFormGroup.get('timeForCleaningCtrl')?.errors?.['pattern']">Invalid field format</span>
              </mat-error> 
            </mat-form-field>
  
            <mat-form-field>
              <mat-label>Application Date</mat-label>
              <input matInput readonly [matDatepicker]="pickerapplicationDate" formControlName="applicationDateCtrl"/>
              <mat-datepicker-toggle matIconSuffix [for]="pickerapplicationDate">
              </mat-datepicker-toggle>
              <mat-datepicker #pickerapplicationDate></mat-datepicker>
              <mat-error>
                <span *ngIf="cleaningEditFormGroup.get('applicationDateCtrl')?.value && cleaningEditFormGroup.get('applicationDateCtrl')?.invalid && cleaningEditFormGroup.get('applicationDateCtrl')?.errors?.['pattern']">Invalid field format</span>
              </mat-error> 
            </mat-form-field>
          </div>
  
          
         
          <mat-checkbox class="example-margin" formControlName="isFamilyPresentCtrl">Family Present</mat-checkbox>
          <mat-checkbox class="example-margin" formControlName="feePaidCtrl">Fee Paid</mat-checkbox>
        
          <div class="buttonalign">
            <button mat-raised-button color="primary"(click)="updateClick(cleaning)"  ><mat-icon>update</mat-icon>Update</button>
            <button mat-raised-button style="background-color: rgb(143, 141, 141)" [mat-dialog-close] cdkFocusInitial><mat-icon>close</mat-icon>Close</button>
        </div>
        </mat-dialog-content>
      </form>
  
    </div>
    
  </div>
