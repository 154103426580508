<div class="container">
  <div class="form">
    <form [formGroup]="DivisionEditForm">
      <span class="mat-title" mat-dialog-title><b>Edit Division Details</b>
      </span>
      <mat-dialog-content>
        <div class="box">
          <mat-form-field>
            <mat-label>Division Id</mat-label>
            <input matInput placeholder="Division Id" formControlName="divisionCtrl" />
          </mat-form-field>
        </div>
        <div class="box">
          <mat-form-field>
            <mat-label>Division Name</mat-label>
            <input matInput placeholder="Division Name" formControlName="divisionNameCtrl" required/>
          </mat-form-field>
        </div>
        <mat-dialog-actions>
          <div class="divspace">
            <button mat-raised-button color="primary" (click)="onDivisionUpdateClick()" >Update</button>
            <button mat-raised-button style="color: dimgray" [mat-dialog-close] cdkFocusInitial>Close</button>
          </div>
        </mat-dialog-actions>
      </mat-dialog-content>
    </form>
  </div>
</div>
