import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IGraveOwnerSample } from '../grave-owners.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-grave-owner-add',
  templateUrl: './grave-owner-add.component.html',
  styleUrls: ['./grave-owner-add.component.css']
})
export class GraveOwnerAddComponent implements OnInit{
 
  cemeteryOption = 'option1';
  divisionOption ='option1';
  sectionOption=  'option1';
  compartmentOption= 'option2';
  ownerOption= 'Main Owner';
  graveOption = 'option1';
  
  NewGraveOwnersForm!: FormGroup;
 constructor(public dialogRef: MatDialogRef<GraveOwnerAddComponent>,
   @Inject(MAT_DIALOG_DATA) public data: IGraveOwnerSample,
   private _formBuilder: FormBuilder,
  ){}

    onUpdateGraveOwners(): void {
     this.dialogRef.close();
   }
   ngOnInit() {
    this. NewGraveOwnersForm = this._formBuilder.group({
        DHfileNo: ['', Validators.required],
        referenceNo : [''],  
        owner_id:['', Validators.required],
        Gname:['', Validators.required],
        Gsurname:['', Validators.required],
        address: ['', Validators.required],
        telephone: ['', Validators.required],
  });
  }
  }