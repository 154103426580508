<div class="dialog-header">
    <button mat-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
</div>

<div id="mainContainer">
    
    <mat-spinner [diameter]="100" *ngIf="isLoading"></mat-spinner>

    <br />

    <iframe id='pdfPreview'></iframe>

    <div id="contentContainer">

        <div #contentToPrint id="contentToPrint">

            <div id="form_header_img">
                <img src="assets/logo_burial_template2.png">
            </div>

            <div class="form_header">
                
                <h3>Burial Permits issued from the Burial Administration Unit from {{formatDate(burialsIssued_dateFrom)}} to {{formatDate(burialsIssued_dateTo)}}</h3>

            </div>

            <div class="form_body">

                <table>

                    <thead>
                        <th>BURIAL PERMIT</th>
                        <th>CEMETERY NAME</th>
                        <th>BURIAL TYPE</th>
                        <th>BURIAL DATE</th>
                        <th>NAME</th>
                        <th>SURNAME</th>
                        <th>ID NO</th>
                        <th>UNDERTAKER</th>
                    </thead>

                    <tbody>
                        <tr *ngFor="let burial of burialPermitPreview">
                            <td>{{ burial.permitNo }}</td>
                            <td>{{ burial.cemeteryName }}</td>
                            <td>{{ burial.burialType }}</td>
                            <td>{{ formatDate(burial.dateOfBurial) }}</td>
                            <td>{{ burial.name }}</td>
                            <td>{{ burial.surname }}</td>
                            <td>{{ burial.idCardNumber }}</td>
                            <td>{{ burial.undertaker}}</td>
                        </tr>
                    </tbody>

                </table>

            </div> 

            <div class="form_footer">
                <p>DEPARTMENT FOR HEALTH REGULATION</p>
                <p>ENVIRONMENTAL HEALTH DIRECTORATE</p>
                <p>BURIALS REGULATORY UNIT</p>
                <p>Cimiterju Sta. Marija Addolorata, Paola PLA9010</p>
                <p>Tel: 356 21693508,  356 21675936   e.mail: burials.ph&#64;gov.mt</p>
            </div>   

        </div>

    </div>

</div>