import {  Component } from '@angular/core';



@Component({
  selector: 'app-burial-permit-details',
  templateUrl: './burial-permit-details.component.html',
  styleUrls: ['./burial-permit-details.component.css'],
  
})

export class BurialPermitDetailsComponent   { 

}
