<div class="dialog-header">
  <button mat-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="container-popup1">
  <div class="form1">
    <mat-tab-group class="fixed-size-tab-group" animationDuration="0ms">
      <mat-tab class="fixed-size-tab" label="Division">
        <form [formGroup]="divisionFormGroup">
          <mat-dialog-content>

            <div class="box">
              <mat-form-field>
                <mat-label>Cemetery</mat-label>
                <input matInput formControlName="cemeteryNameCtrl" />
              </mat-form-field>
              <mat-form-field>
                <mat-label>Division</mat-label>
                <input matInput formControlName="divisionNameCtrl" maxlength="50"/>
              </mat-form-field>
            </div>
            <div  class="box main_button"><button mat-raised-button color="primary" (click)="clearDivision()"><mat-icon>backspace</mat-icon>Clear</button></div>
            <mat-divider></mat-divider>
            <div class="box">
              <mat-form-field>
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Division Name" #input/>
              </mat-form-field>
            </div>
            <mat-label class="matlabel"><b>List of Divisions:</b></mat-label>
            <div class="table1container mat-elevation-z1" id="tablecontent">
              <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>
                <!-- ID Column -->
                <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef >Id</th>
                  <td mat-cell *matCellDef="let division">{{ division.id }}</td>
                </ng-container>
                <!-- Division Name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef > Division </th>
                  <td mat-cell *matCellDef="let division"> {{ division.description }}</td>
                </ng-container>
                <!-- Action Column -->
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef>Action</th>
                  <td mat-cell *matCellDef="let division">
                    <mat-checkbox class="checkbox-margin" (change)="onCheckboxDivisionChange(division)" [checked]="isSelectedDivision(division)" >
                    </mat-checkbox>
                    <button mat-icon-button color="warn" (click)="deleteDivision(division.id)">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              </table>
            </div>
          </mat-dialog-content>
          <div class="buttonalign">
            <button mat-flat-button color="primary" (click)="addDivision()" [disabled]="!divisionObj.description"><mat-icon>add</mat-icon> Add</button>
            <button mat-flat-button color="primary" (click)="divisionUpdateClick(division)" [disabled]="!divisionObj.description"><mat-icon>update</mat-icon>Update</button>
          </div>
        </form>
      </mat-tab>
      <mat-tab label="Section">
        <form [formGroup]="sectionFormGroup">
          <mat-dialog-content>

            <div class="box">
              <mat-form-field>
                <mat-label>Cemetery</mat-label>
                <input matInput formControlName="cemeteryNameCtrl" />
              </mat-form-field>
              <mat-form-field>
                <mat-label>Division</mat-label>
                <mat-select formControlName="divisionNameCtrl" (selectionChange)="onDivisionChange()">
                  <mat-option>None</mat-option>
                  <mat-option *ngFor="let division of divisions" [value]="division.id"> {{ division.description }} </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <mat-label> Section</mat-label>
                <input matInput formControlName="sectionNameCtrl" maxlength="50"/>
              </mat-form-field>
            </div>
            <div  class="box main_button"><button mat-raised-button color="primary" (click)="clearSection()"><mat-icon>backspace</mat-icon>Clear</button></div>
            <mat-divider></mat-divider>
            <div class="box">
              <mat-form-field>
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter1($event)" placeholder="Ex. Section Name" #input />
              </mat-form-field>
            </div>
            <mat-label class="matlabel"><b>List of Sections:</b></mat-label>
            <div class="table1container mat-elevation-z1" id="tablecontent">
              <table mat-table [dataSource]="dataSource1"  multiTemplateDataRows matSort>
                <!-- ID Column -->
                <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef >Id</th>
                  <td mat-cell *matCellDef="let section">{{ section.id }}</td>
                </ng-container>
                <!-- Division Name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef > Section </th>
                  <td mat-cell *matCellDef="let section">{{ section.description }} </td>
                </ng-container>

                <!-- Action Column -->
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef>Action</th>
                  <td mat-cell *matCellDef="let section">
                    <mat-checkbox  class="checkbox-margin" (change)="onCheckboxSectionChange(section)" [checked]="isSelectedSection(section)" >
                    </mat-checkbox>
                    <button mat-icon-button color="warn" (click)="deleteSection(section.id)">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row  *matRowDef="let row; columns: displayedColumns" ></tr>
              </table>
            </div>
          </mat-dialog-content>
          <div class="buttonalign">
            <button mat-flat-button color="primary" (click)="addSection()" [disabled]="!sectionObj.description"><mat-icon>add</mat-icon>Add</button>
            <button mat-flat-button color="primary" (click)="sectionUpdateClick(section)" [disabled]="!sectionObj.description"><mat-icon>update</mat-icon> Update</button>
          </div>
        </form>
      </mat-tab>
      <mat-tab label="Compartment">
        <form [formGroup]="compartmentFormGroup">
          <mat-dialog-content>
            <div class="box">
              <mat-form-field>
                <mat-label>Cemetery</mat-label>
                <input matInput formControlName="cemeteryNameCtrl" />
              </mat-form-field>
              <mat-form-field>
                <mat-label>Division</mat-label>
                <mat-select formControlName="divisionNameCtrl"  (selectionChange)="onDivisionSectionChange()" >
                  <mat-option>None</mat-option>
                  <mat-option *ngFor="let division of divisions" [value]="division.id">{{ division.description }} </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Section</mat-label>
                <mat-select formControlName="sectionNameCtrl"  (selectionChange)="onSectionChange()" >
                  <mat-option *ngFor="let section of sections" [value]="section.id"> {{ section.description }} </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <mat-label> Compartment</mat-label>
                <input matInput formControlName="compartmentNameCtrl" maxlength="50"/>
              </mat-form-field>
            </div>
            <div  class="box main_button"><button mat-raised-button color="primary" (click)="clearCompartment()"><mat-icon>backspace</mat-icon>Clear</button></div>
            <mat-divider></mat-divider>
            <div class="box">
              <mat-form-field>
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter2($event)" placeholder="Ex. Compartment Name" #input/>
              </mat-form-field>
            </div>
            <mat-label class="matlabel"><b>List of Compartments:</b></mat-label>
            <div class="table1container mat-elevation-z1" id="tablecontent">
              <table mat-table [dataSource]="dataSource2" multiTemplateDataRows matSort>
                <!-- ID Column -->
                <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef >Id</th>
                  <td mat-cell *matCellDef="let compartment">{{ compartment.id }}</td>
                </ng-container>
                <!-- Division Name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef > Section</th>
                  <td mat-cell *matCellDef="let compartment"> {{ compartment.description }}</td>
                </ng-container>

                <!-- Action Column -->
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef>Action</th>
                  <td mat-cell *matCellDef="let compartment">
                    <mat-checkbox class="checkbox-margin" (change)="onCheckboxCompartmentChange(compartment)" [checked]="isSelectedCompartment(compartment)" >
                    </mat-checkbox>
                    <button  mat-icon-button color="warn"  (click)="deleteCompartment(compartment.id)">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row  *matRowDef="let row; columns: displayedColumns"></tr>
              </table>
            </div>
 
          </mat-dialog-content>
          <div class="buttonalign">
            <button mat-flat-button color="primary" (click)="addCompartment()" [disabled]="!compartmentObj.description"><mat-icon>add</mat-icon> Add</button>
            <button mat-flat-button color="primary" (click)="compartmentUpdateClick(compartment)" [disabled]="!compartmentObj.description"><mat-icon>update</mat-icon> Update</button>
          </div>
        </form>
      </mat-tab>
    </mat-tab-group>

  </div>
</div>
