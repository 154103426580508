import { Component, Input, OnInit } from '@angular/core';
import { IGraveDto } from '../models/GraveDto.model';
import { FileUploadService } from '../services/file-upload.service';
import { MatTableDataSource } from '@angular/material/table';
import { GraveFile } from '../models/GraveFile.model';
import { InternalCommunicationService } from '../services/internal-communication.service';
import { IResponseModal } from '../models/ResponseModal.model';
import { ResponsemodalComponent } from '../responsemodal/responsemodal.component';
import { MatDialog } from '@angular/material/dialog';
import { FilePreviewModalComponent } from '../file-preview-modal/file-preview-modal.component';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-fileupload',
  templateUrl: './fileupload.component.html',
  styleUrls: ['./fileupload.component.css']
})
export class FileuploadComponent implements OnInit {
  @Input() graveId!: number;
  isLoading = false;
  files! : GraveFile[];
  file! : GraveFile;
  dataSource! : MatTableDataSource<GraveFile>
  selectedFile: File | null = null; 
  selectedGraveId!: number ; 
  graves! : IGraveDto[];
  displayedColumns: string[] = [    
    'fileName',
    'uploadedDate',    
    'action'
  ];

  constructor(
    private fileUploadService: FileUploadService, 
    private internalService : InternalCommunicationService,
    public dialog: MatDialog) {}
  ngOnInit(): void {
    this.file = {
      id : 0,
      fileUrl : '',
      fileName: '',
      graveId : 0,
      uploadedDate : new Date,
    }
    this.internalService.filesUpdated$.subscribe(() =>{
      this.getAllFilesPerGrave(this.graveId);

    })

    this.getAllFilesPerGrave(this.graveId);
  }
  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  }
  
  uploadFile() {
    this.isLoading = true;
    if (this.selectedFile && this.graveId !== null) {
      const formData = new FormData();

      formData.append('file', this.selectedFile);
      formData.append('graveId', this.graveId.toString()); 

      this.fileUploadService.uploadFile(formData,this.graveId).subscribe(
        (response : IResponseModal) => {
         console.log('response uploadFile:',response);
          this.openResponseModal(response);
          this.internalService.emitRefreshFileTable();
          this.isLoading = false;
          this.selectedFile = null; 
          console.log('File uploaded successfully');
        },
        (error) => {
          console.error('Error uploading file', error);
        }
      );
    } else {
      console.error('File or Grave ID is not selected');
    }
  }  
  

  deleteFile(fileName : string) {
    this.isLoading = true;
    if (fileName) { 
     
      this.openConfirmationModal('Delete').afterClosed().subscribe(
        (result => {
          if(result){
            this.fileUploadService.deleteFile(fileName,this.graveId).subscribe(
              (response : IResponseModal) => {
                console.log('File deleted successfully', response);
                this.openResponseModal(response);
                this.internalService.emitRefreshFileTable();
                this.isLoading = false;
              },
              (error) => {
                console.error('Error deleting file', error);
              }
            );
          } else {
            console.error('No file uploaded to delete.');
          }
        }));
    }
  }  

  getAllFilesPerGrave(graveId : number){
    this.isLoading = true;
    this.fileUploadService.getAllFilesPerGrave(graveId).subscribe(
      (response : GraveFile[]) => {
        this.files = response;
        this.dataSource = new MatTableDataSource(this.files);
        this.isLoading = false;

      }, (error)=> {

      })

  }

  viewFileContent(fileName: string) {
    this.isLoading = true;
    this.fileUploadService.downloadFile(fileName).subscribe(
      (fileBlob) => {
        const fileType = fileBlob.type;
        const fileURL = window.URL.createObjectURL(fileBlob);

        // Open a modal to display the file content
        this.dialog.open(FilePreviewModalComponent, {
          width: '80%',
          height: '80%',
          data: { fileBlob,fileURL, fileType, fileName }
        });
        this.isLoading = false;
      },
      (error) => {
        console.error('Error downloading file', error);
      }
    );
  }
  
  openResponseModal(response : IResponseModal): void {
    const dialogRef = this.dialog.open(ResponsemodalComponent, {
      width: '250px',
      data : response,
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  downloadFile(fileName: string) {
    this.fileUploadService.downloadFile(fileName).subscribe(
      (fileBlob) => {
        const url = window.URL.createObjectURL(fileBlob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = fileName;
        anchor.click();
        window.URL.revokeObjectURL(url);
      },
      (error) => {
        console.error('Error downloading file', error);
      }
    );
  }

  openConfirmationModal(message : string) {
    return this.dialog.open(ConfirmationModalComponent, {
      data : message,
      // width: '500px'
    });
  }
}
