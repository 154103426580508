import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  id?: number; 
  roleName: string;
  description: string;
  createdAt: Date;
}

@Component({
  selector: 'app-add-roledialog',
  templateUrl: './add-roledialog.component.html',
})
export class AddRoleDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<AddRoleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSaveClick(): void {
    // Check if data is valid or perform additional logic here if necessary
    this.dialogRef.close(this.data);
  }
}
