import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { UndertakerComponent } from './undertaker/undertaker.component';
import { ReportingComponent } from './reporting/reporting.component';
import { MsalGuard } from '@azure/msal-angular';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { UnauthorizedComponent } from './admin/unauthorized/unauthorized.component';
import { RoleGuard } from './guards/roles.guard';
import { FilePreviewModalComponent } from './file-preview-modal/file-preview-modal.component';



const routes : Routes = [
  { path: '', component: LandingPageComponent, title: 'Landing Page' }, 
  {
    path: 'home',
    component: HomeComponent,
    title: 'Home',
    canActivate: [MsalGuard],  
  },
  {
    path: 'burial',
    loadChildren: () =>
      import('./burials/burials.module').then((m) => m.BurialsModule),
    canActivate: [MsalGuard],  
  },
  {
    path: 'cemetery',
    loadChildren: () =>
      import('./cemetery/cemetery.module').then((m) => m.CemeteryModule),
    canActivate: [MsalGuard],  
  },
  {
    path: 'maintenance',
    loadChildren: () =>
      import('./maintenance/maintenance.module').then((m) => m.MaintenanceModule),
    canActivate: [MsalGuard],
  },
  {
    path: 'reporting',
    component: ReportingComponent,
    title: 'Reporting',
    canActivate: [MsalGuard, RoleGuard], 
    data: { 
      moduleName: 'ReportingComponent', 
      accessLevel: 'Read'  }
  },
  {
    path: 'undertaker',
    component: UndertakerComponent,
    title: 'Undertaker Details',
    canActivate: [MsalGuard,RoleGuard ],
    data: { 
      moduleName: 'UndertakerComponent', 
      accessLevel: 'Editor' }  
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [MsalGuard, RoleGuard],  
  },  

  { path: 'unauthorized', component: UnauthorizedComponent, title: 'Unauthorized' },
  { path: '', redirectTo: '/home', pathMatch: 'full' }, // Default route

];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports:[RouterModule]
})
export class AppRoutingModule { }
