import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ITableCountDto } from '../burials/models/ITableCountDto';
import { Observable, catchError, map, tap, throwError } from 'rxjs';
import { IUpdateTransportDto } from '../models/UpdateTransportDto.model';
import { IResponseModal } from '../models/ResponseModal.model';
import { ITransportDto } from '../models/TransportDto.model';
import { IAddTransportDto } from '../models/AddTransportDto.model';
import {  ITransportTypeDto } from '../models/TransportTypeDto.model';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class TransportService {
  private baseUrl = `${environment.apiUrl}transport/`;
  constructor(private http: HttpClient, private authService: AuthService) { }

  getAllTransport(filterCriteria: string | null, searchQuery: string | null, pageNumber: number, pageSize: number,graveId : number = 0 ): Observable<ITableCountDto> {    
    let params = new HttpParams();
    const headers = this.authService.getAuthHeaders();
    if (filterCriteria) {
        params = params.append('filterCriteria', filterCriteria);
    }
    if (searchQuery) {
        params = params.append('searchQuery', searchQuery);
    }
   
    params = params.append('pageNumber', pageNumber.toString());
    params = params.append('pageSize', pageSize.toString());
    if (graveId) {
      params = params.append('graveId', graveId);
  }

    return this.http.get<ITableCountDto>(this.baseUrl + 'GetAllTransport', { params,headers })
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };

  updateTransport(id: number, cleaningDto: IUpdateTransportDto): Observable<IResponseModal> {
    const url = `${this.baseUrl}${id}`;
    const headers = this.authService.getAuthHeaders();

    return this.http.put<HttpResponse<IResponseModal>>(url, cleaningDto, { observe: 'response',headers }).pipe(
      map(response => {
        
        let modal: IResponseModal = {
          id: 0,
          title: 'Update Transport',
          message: ''
        };
        if (response.status === 204) {  
          
          modal.message = 'Record Updated';

        } else if (response.status === 200) {    
          
          modal.message = 'To update an owner at least one field needs to change, currently not changes have been registered.';

        } else {
          modal.message =`Unexpected status code: ${response.status}`;
          
          throw new Error(`Unexpected status code: ${response.status}`);
        }
        return modal;
      })
    );
  }
  carryOut(cleaningDto: ITransportDto): Observable<IResponseModal> {
    const headers = this.authService.getAuthHeaders();
    return this.http.post<ITransportDto>(this.baseUrl + 'CarryoutTransport', cleaningDto,{ observe: 'response',headers }).pipe(
      tap(response => {
        console.log('Carryout' + response.status);
      }),
      map(response => {
        let modal: IResponseModal = {
          id: 0,
          title: 'Carryout Transport',
          message: '',
          entity : response.body
        };
  
        if (response.status === 201) {
          modal.message = 'Done !';
        } else {
          modal.message = `Unexpected status code: ${response.status}`;
          throw new Error(`Unexpected status code: ${response.status}`);
        }
        
        return modal;
      }),
      catchError(error => {
        console.error('Error occurred:', error);
        return throwError(error);
      })
    );
  }

  addTransport(addCleaningDto: IAddTransportDto): Observable<IResponseModal> {
    const headers = this.authService.getAuthHeaders();
    return this.http.post<ITransportDto >(this.baseUrl + 'AddTransport', addCleaningDto,{ observe: 'response',headers }).pipe(
      tap(response => {
        
      }),
      map(response => {
        let modal: IResponseModal = {
          id: 0,
          title: 'Add Transport',
          message: '',
          entity : response.body
        };
  
        if (response.status === 201) {
          modal.message = 'Transport Scheduled';
        } else {
          modal.message = `Unexpected status code: ${response.status}`;
          throw new Error(`Unexpected status code: ${response.status}`);
        }
        
        return modal;
      }),
      catchError(error => {
        console.error('Error occurred:', error);
        return throwError(error);
      })
    );
  }
  
  getAllStatus(filterCriteria: string | null, searchQuery: string | null, pageNumber: number, pageSize: number ): Observable<ITableCountDto> {    
    let params = new HttpParams();
    const headers = this.authService.getAuthHeaders();
    
    if (filterCriteria) {
        params = params.append('filterCriteria', filterCriteria);
    }
    if (searchQuery) {
        params = params.append('searchQuery', searchQuery);
    }
   
    params = params.append('pageNumber', pageNumber.toString());
    params = params.append('pageSize', pageSize.toString());

    return this.http.get<ITableCountDto>(this.baseUrl + 'GetAllTransportStatus', { params,headers })
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };

  deleteTransport(id: number): Observable<IResponseModal> {
    const headers = this.authService.getAuthHeaders();
    const url = `${this.baseUrl}${id}`;
    return this.http.delete<HttpResponse<IResponseModal>>(url, { observe: 'response',headers }).pipe(
      tap(response => {
        console.log('Delete Transport' + response.status);
      }),
      map(response => {
        let modal: IResponseModal = {
          id: 0,
          title: 'Delete Transport',
          message: ''
        };
  
        if (response.status === 204) {
          modal.message = 'Transport deleted';
        } else {
          modal.message = `Unexpected status code: ${response.status}`;
          throw new Error(`Unexpected status code: ${response.status}`);
        }
        
        return modal;
      }),
      catchError(error => {
        console.error('Error occurred:', error);
        return throwError(error);
      })
    );
  }

  getAllTransportTypes( ): Observable<ITransportTypeDto[]> {    
    const headers = this.authService.getAuthHeaders();
    return this.http.get<ITransportTypeDto[]>(this.baseUrl + 'GetAllTransportTypes',{headers})
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );

      }

  transportExist(id:number): Observable<boolean> {  
        const headers = this.authService.getAuthHeaders(); 
        const endpoint = 'TransportExist'; 
        const url = `${this.baseUrl}${endpoint}?id=${id}`;
        return this.http.get<boolean>(url,{headers})
            .pipe(
                catchError(error => {
                    console.error('Error:', error);
                    throw error;
                })
            );
  };


  getTransportByCleaningId(id:number): Observable<ITransportDto> {    
        const endpoint = 'GetTransportByCleaningId'; 
        const url = `${this.baseUrl}${endpoint}?id=${id}`;
        const headers = this.authService.getAuthHeaders();
        return this.http.get<ITransportDto>(url,{headers})
            .pipe(
                catchError(error => {
                    console.error('Error:', error);
                    throw error;
                })
            );
  };

  getTransportById(id:number): Observable<ITransportDto> {    
    const endpoint = 'GetTransportById'; 
    const url = `${this.baseUrl}${endpoint}?id=${id}`;
    const headers = this.authService.getAuthHeaders();
    return this.http.get<ITransportDto>(url,{headers})
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
};



}
