import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ISectionDto } from 'src/app/models/SectionDto.model';
import { SectionEditComponent } from './section-edit/section-edit.component';
import { SectionNewComponent } from './section-new/section-new.component';
import { ICemeteryDto } from 'src/app/models/CemeteryDto.model';
import { IDivisionDto } from 'src/app/models/DivisionDto.model';
import { CemeteryService } from 'src/app/services/cemetery.service';
import { DivisionService } from 'src/app/services/division.service';
import { SectionService } from 'src/app/services/section.service';
import { ITableCountDto } from 'src/app/burials/models/ITableCountDto';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.css']
})
export class SectionComponent implements AfterViewInit, OnInit{

  sections: ISectionDto[]=[];
  selectedSection:  ITableCountDto | null = null;
  divisions: IDivisionDto[] = [];
  cemeteries : ICemeteryDto[] = [];
  SectionDetailsForm!: FormGroup;

  sectionmain:ISectionDto = {
    id :0,
    description: '',
    cemeteryId :0  ,
    divisionId :0,
    compartments:[]
  }

  displayedColumns: string[] = [
    'id',
    'name',
    'action',
  ];
  dataSource: MatTableDataSource<ISectionDto>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  

  constructor(public dialog: MatDialog,
    private cemeteryService : CemeteryService,
    private divisionService : DivisionService,
    private sectionService : SectionService,
    private _formBuilder: FormBuilder,
     

  ) {
    this.dataSource = new MatTableDataSource(this.sections);
  }
  ngOnInit():void { 
    this.cemeteryService.getAllCemeteries().subscribe(
      (response : ICemeteryDto[]) => {
        this.cemeteries = response;
      })
    this.SectionDetailsForm = this._formBuilder.group({
      cemeteryCtrl: [this.sectionmain.cemeteryId],  
      divisionCtrl: [this.sectionmain.divisionId], 
    });
    this.subscribeChanges();
  }
  subscribeChanges() {
    this. SectionDetailsForm.get('cemeteryCtrl')?.valueChanges.subscribe((value) => {     
      this.sectionmain.cemeteryId= value;
    });
    this. SectionDetailsForm .get('divisionCtrl')?.valueChanges.subscribe((value) => {     
      this.sectionmain.divisionId= value;
    });
   
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  onCemeteryChange(){
    const selectedCemetery = this.cemeteries.find(cem => cem.code === this.sectionmain.cemeteryId);
    if(selectedCemetery){      
      this.divisionService.getDivisionsByCementeryId(selectedCemetery.code).subscribe(
        (response : IDivisionDto[]) => {
          this.divisions = response;
        })
    }
  }
  onDivisionChange() {         
    const selectedDivision = this.divisions.find(div => div.id === this.sectionmain.divisionId);
    if (selectedDivision) {
      this.sectionService.GetSectionByDivisionId(selectedDivision.id).subscribe(
        (response : ISectionDto[]) =>{
          this.sections = response;
          this.dataSource = new MatTableDataSource(this.sections);	
        }
      )          
    } else {
      this.sections = [];
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

    this.selectedSection = null;
  }
  
  editSectionDialog(sectionmain: ISectionDto) {
    let sectionName: number = +sectionmain.id;
     // instead of GetSectionByDivisionId --> use getSectionsById(after setting the endpoints)
     this.sectionService.GetSectionByDivisionId(sectionName).subscribe(
       (sectionObj: ISectionDto[]) => {
         const dialogRef = this.dialog.open(SectionEditComponent, {
           data: sectionObj
         
         });
       dialogRef.afterClosed().subscribe(result => {
           console.log('The dialog was closed');          
         });
       },
       error => {
         console.error('Error:', error);        
       }
     );
   }
  NewSectionDialog() {
    const dialogRef = this.dialog.open(SectionNewComponent, {
      // restoreFocus: false,
      
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
     
    });
  }
}