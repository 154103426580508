import { Component, ViewChild, ElementRef, Input, AfterViewInit, Renderer2, Inject, Optional, ChangeDetectorRef, NgZone } from '@angular/core';
import { jsPDF } from 'jspdf';
import { DatePipe } from '@angular/common';
import { LocalStorageService } from 'src/app/local-storage.service';
import { IPreViewCleaningsDto } from 'src/app/models/PreViewCleaningDto.model';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-grave-cleantrans-template',
  templateUrl: './grave-cleantrans-template.component.html',
  styleUrls: ['./grave-cleantrans-template.component.css']
})
export class GraveCleanTransTemplateComponent {

  @Input() cleanTransPreview!: IPreViewCleaningsDto[]; 
 
  @ViewChild('contentToPrint',{static:false}) el!: ElementRef
  title = 'angular-pdf-print';
  isLoading: boolean = false;

  constructor (private datePipe: DatePipe,
              private localStorageService: LocalStorageService,
              private renderer: Renderer2,
              private cdr: ChangeDetectorRef, // Inject ChangeDetectorRef
              private ngZone: NgZone, // Inject NgZone
              @Optional() @Inject(MAT_DIALOG_DATA) public data: IPreViewCleaningsDto[])
              {
                if (data) {
                  this.cleanTransPreview = data;
                  console.log(this.cleanTransPreview)
                }
              }

  ngAfterViewInit() {
    this.ngZone.run(() => {
      this.isLoading = true;
      this.cdr.detectChanges(); // Trigger change detection manually

      setTimeout(() => {
        this.makePDF().then(() => {
          this.isLoading = false;
          this.cdr.detectChanges(); // Trigger change detection manually
        });
      });
    });
  }

  makePDF(): Promise<void> {
    return new Promise((resolve, reject) => {
      let pdf = new jsPDF({
        orientation: 'p', // or 'landscape'
        unit: 'pt',
        format: 'a4' // or specify custom dimensions { width: number, height: number }
      });
  
      pdf.html(this.el.nativeElement, {
        html2canvas: {
          scale: 1,    // Adjust scale to improve rendering quality
          scrollX: 0,
          scrollY: 0
        },
        callback: (pdf) => {
          const pdfDataUri = pdf.output('datauristring');
          this.fillIframe(pdfDataUri);
          resolve();
        },
        x: 0,
        y: 0,
        autoPaging: 'text',
        margin:[30,0,40,0]
      });
      
    });
  }

  fillIframe(dataUri: string) {
    const iframe = document.getElementById('pdfPreview') as HTMLIFrameElement;
    if (iframe) {
      iframe.src = dataUri;
    }
  }

  formatDate(date: Date | undefined): string {
    return this.datePipe.transform(date, 'dd/MM/yyyy') || ''; 
  }

  formatTime(date: Date | undefined): string {
    return this.datePipe.transform(date, 'HH:mm') || '';
  }

  storeObject(key: string, object: any) {
    const objectToStore = object;
    this.localStorageService.setObject(key, objectToStore);
  }

  getCurrentDate(): string {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = currentDate.getFullYear();

    return `${day}/${month}/${year}`;
  }

  formattedCurrentDate = this.getCurrentDate();

  getFormattedBurialPermitDate(dateOfDeath: Date): string {
    const burialpermitdate = new Date(dateOfDeath);
    burialpermitdate.setDate(burialpermitdate.getDate() + 1);
    const day = String(burialpermitdate.getDate()).padStart(2, '0');
    const month = String(burialpermitdate.getMonth() + 1).padStart(2, '0'); 
    const year = String(burialpermitdate.getFullYear());

    return `${day}/${month}/${year}`;
  }

  hasTransport(): boolean {
    return this.cleanTransPreview && this.cleanTransPreview.some(person => person.transportPreview);
  }
}


