<h1 mat-dialog-title>Add New User</h1>
<div mat-dialog-content>
  <form [formGroup]="addUserForm" class="form-row">
    <div fxLayout="row" fxLayoutGap="20px" class="box">
      <!--<mat-form-field appearance="fill" fxFlex>
        <mat-label>UserName</mat-label>
        <input matInput formControlName="userName" readonly>
      </mat-form-field>-->

     

    <div fxLayout="row" fxLayoutGap="20px" class="box">
      <mat-form-field appearance="fill" fxFlex>
        <mat-label>Corp Account</mat-label>
        <input matInput formControlName="username" required>
        <mat-error *ngIf="addUserForm.get('username')?.hasError('required')">
          Username is required
        </mat-error>
      </mat-form-field>
    </div>
      <mat-form-field appearance="fill" fxFlex>
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" required>
      </mat-form-field>

      <mat-form-field appearance="fill" fxFlex>
        <mat-label>Surname</mat-label>
        <input matInput formControlName="surname" required>
      </mat-form-field>

      <mat-form-field appearance="fill" fxFlex>
        <mat-label>ID Number</mat-label>
        <input matInput formControlName="idNo" required>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="20px" class="box">
      <mat-form-field appearance="fill" fxFlex>
        <mat-label>Email</mat-label>
        <input matInput type="email" formControlName="email" required>
        <mat-error *ngIf="addUserForm.get('email')?.hasError('required')">
          Email is required
        </mat-error>
        <mat-error *ngIf="addUserForm.get('email')?.hasError('email')">
          Enter a valid email address
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" fxFlex>
        <mat-label>Role</mat-label>
        <mat-select formControlName="roleID" required>
          <mat-option *ngFor="let role of roles" [value]="role.id">{{ role.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="20px" class="box">
      <mat-form-field appearance="fill" fxFlex>
        <mat-label>Active Status</mat-label>
        <mat-select formControlName="isActive" required>
          <mat-option [value]="true">Yes</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>

      <!--<mat-form-field appearance="fill" fxFlex>
        <mat-label>Created On</mat-label>
        <input matInput formControlName="createdOn" readonly>
      </mat-form-field>-->
    </div>
  </form>

  <div mat-dialog-actions>
    <button mat-raised-button class="cancel-button" (click)="onCancel()">Cancel</button>
    <button mat-raised-button color="primary" class="save-button" [disabled]="addUserForm.invalid" (click)="onSave()">Save</button>
  </div>
</div>
