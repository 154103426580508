<div id="mainContainer">
    
    <mat-spinner [diameter]="100" *ngIf="isLoading"></mat-spinner>

    <br />

    <iframe id='pdfPreview'></iframe>

    <div id="contentContainer">

        <div #contentToPrint id="contentToPrint">

            <div class="form_body">

                <h1>WORK ORDER INFORMATION</h1>

                <table id="details">  
                    <tr>
                        <td>PERMIT NUMBER:<br/><p><b>{{cleanTransPreview[0].cleaningPermitNumber}}</b></p></td>
                        <td>HUMAN &nbsp;REMAINS &nbsp;FOR &nbsp;CLEANING:<br/><p><b>{{cleanTransPreview.length}}</b></p></td>
                        <td>APPLICATION &nbsp; DATE&nbsp;:<br/><p><b>{{formatDate(cleanTransPreview[0].applicationDate)}}</b></p></td>
                        <td>APPLICATION &nbsp; TIME&nbsp;:<br/><p><b>{{formatTime(cleanTransPreview[0].applicationDate)}}</b></p></td>
                    </tr>
                    <tr>
                        <td>SUPERVISOR:<br/><p><b>{{cleanTransPreview[0].supervisorName}}</b></p></td>
                        <td>OFFICER &nbsp;BAU&nbsp;:<br/><p><b></b></p></td>
                        <td>CLEAN/TRANS &nbsp; DATE&nbsp;:<br/><p><b>{{formatDate(cleanTransPreview[0].cleaningDate)}}</b></p></td>
                        <td>CLEAN/TRANS &nbsp; TIME&nbsp;:<br/><p><b>{{formatTime(cleanTransPreview[0].cleaningDate)}}</b></p></td>

                    </tr>                                                
                    <tr>
                        <td>UNDERTAKER&nbsp;:<br/><p><b>{{ cleanTransPreview[0].undertakerName+' '+cleanTransPreview[0].undertakerSurname }}</b></p></td>
                        <td>SIGNATURE&nbsp;:<br/><br/><br/><b>__________________</b></td>
                        <td colspan="2">SIGNATURE &nbsp; CAMPOSANTO &nbsp; OFFICIAL:<br/><br/><br/><b>__________________</b></td>
                    </tr>
                    
                </table>

                <h1>CLEANING</h1>

                <table id="cleaning">
                    <tr>
                        <td><p>DIVISION<br/><b>{{cleanTransPreview[0].graveAddressDivision}}</b></p></td>
                        <td><p>SECTION<br/><b>{{cleanTransPreview[0].graveAddressSection}}</b></p></td>
                        <td><p>COMPARTMENT<br/><b>{{cleanTransPreview[0].graveAddressCompartment}}</b></p></td>
                        <td><p>NUMBER<br/><b>{{cleanTransPreview[0].graveAddressNumber}}</b></p></td>
                    </tr>

                </table>

                <table id="cleaning_persons">
                    <thead>
                        <th><p>NAME & SURNAME</p></th>
                        <th><p> BURIAL DATE </p></th>
                        <th><p>GRAVE LEVEL</p></th>
                        
                    </thead>
                    <tr *ngFor="let person of cleanTransPreview">
                        <td><p>{{ person.cleaningDeceasedName &nbsp; + '  ' + &nbsp; person.cleaningDeceasedSurname }}</p></td>
                        <td><p>{{ formatDate(person.burialDate)}}</p></td>
                        <td><p>{{ person.graveAddressLevel}}</p></td>
                        <!-- <td><p>{{ person.isFamilyPresent ? 'YES' : 'NO' }}</p></td> -->
                        <!-- <td><p>{{ person.feePaid ? 'YES' : 'NO' }}</p></td> -->
                    </tr>
                </table>

                <div *ngIf="hasTransport()">

                    <h1>TRANSPORT</h1>
                  
                    <table id="transport">  
                        <tr> 
                            <td colspan="1"><p>FROM:</p></td>
                            <td colspan="5"><p><b>{{cleanTransPreview[0].graveAddressCemetery}}</b></p></td> 
                        </tr>
                        <tr>
                            <td><p>DIVISION<br/><b>{{cleanTransPreview[0].graveAddressDivision}}</b></p></td>
                            <td><p>SECTION<br/><b>{{cleanTransPreview[0].graveAddressSection}}</b></p></td>
                            <td><p>COMPARTMENT<br/><b>{{cleanTransPreview[0].graveAddressCompartment}}</b></p></td>
                            <td><p>NUMBER<br/><b>{{cleanTransPreview[0].graveAddressNumber}}</b></p></td>
                        </tr>
                    </table> 
                  
                    <table id="transport_persons">
                        <tr *ngFor="let person of cleanTransPreview"> 
                            <td *ngIf="person.transportPreview && person.transportPreview.transportType == 1"><p><b>- {{person.cleaningDeceasedName + ' ' + person.cleaningDeceasedSurname}}</b> FROM &nbsp; LEVEL&nbsp;  {{person.graveAddressLevel}} <b>TO: {{person.transportPreview.transportToCemetery}}- {{person.transportPreview.transportToDivision}}- {{person.transportPreview.transportToSection}}- {{person.transportPreview.transportToCompartment}}- {{person.transportPreview.transportToNumber}}</b></p></td> 
                            <td *ngIf="person.transportPreview && person.transportPreview.transportType == 2"><p><b>- {{person.cleaningDeceasedName + ' ' + person.cleaningDeceasedSurname}}</b> FROM &nbsp; LEVEL&nbsp;  {{person.graveAddressLevel}} <b>TO: {{person.transportPreview.transportToCountry}}</b></p></td> 
                        </tr>
                    </table>
                  
                </div>

            </div>

            <div class="form_body2">
                <p>FORM CT001</p>
                <p>Information Protection: The information collected is processed in accordance with the Burial Ordinance and in accordance with the Data Protection and Privacy Act 2001. The information is required for processing, statistical and research use in the interest of Public Health.</p>
                <p>DEH 40-00</p>
            </div>  
    </div>

</div>