import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ICompartmentDto } from 'src/app/models/CompartmentDto.model';
import { CompartmentEditComponent } from './compartment-edit/compartment-edit.component';
import { CompartmentNewComponent } from './compartment-new/compartment-new.component';
import { ICemeteryDto } from 'src/app/models/CemeteryDto.model';
import { IDivisionDto } from 'src/app/models/DivisionDto.model';
import { ISectionDto } from 'src/app/models/SectionDto.model';
import { ITableCountDto } from 'src/app/burials/models/ITableCountDto';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CemeteryService } from 'src/app/services/cemetery.service';
import { InternalCommunicationService } from 'src/app/services/internal-communication.service';
import { DivisionService } from 'src/app/services/division.service';
import { SectionService } from 'src/app/services/section.service';
import { CompartmentService } from 'src/app/services/compartment.service';

@Component({
  selector: 'app-compartment',
  templateUrl: './compartment.component.html',
  styleUrls: ['./compartment.component.css']
})
export class CompartmentComponent implements AfterViewInit,OnInit {
 
  selectedCompartment: ITableCountDto | null = null;
  cemeteries : ICemeteryDto[] = [];
  divisions: IDivisionDto[] = [];
  sections: ISectionDto[] = [];
  compartments: ICompartmentDto[] = [];
  CompartmentDetailsForm!:FormGroup;

compartmentmain: ICompartmentDto ={
    id:0,
    description : '',
    cemeteryId : 0,
    divisionId:0,
    sectionId  : 0,   
    graves: [],
}
  displayedColumns: string[] = [
    'id',
    'compartment_name',
    'action',
  ];
  dataSource: MatTableDataSource<ICompartmentDto>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    public dialog: MatDialog,
    private cemeteryService : CemeteryService,
    private _formBuilder: FormBuilder,
    private internalService : InternalCommunicationService, 
    private divisionService : DivisionService,
    private sectionService :SectionService,
    private compartmentService :CompartmentService,
  ) { 
    this.dataSource = new MatTableDataSource(this.compartments);
  }

  ngOnInit() { 
    // remove this comment after setting the getAllCompartment() endpoint 
    // this.compartmentService!.getAllDivisions(this.compartmentmain.id).subscribe(
    //   (response : ICompartmentDto[]) => {
    //     this.divisions = response;
    //     this.dataSource = new MatTableDataSource(this.compartments);
    //   });
    this.cemeteryService.getAllCemeteries().subscribe(
      (response : ICemeteryDto[]) => {
        this.cemeteries = response;
      })
     
    this.CompartmentDetailsForm = this._formBuilder.group({
      cemeteryCtrl: [this.compartmentmain.cemeteryId], 
      divisionCtrl :[this.compartmentmain.divisionId], 
      sectionCtrl : [this.compartmentmain.sectionId],  
  });
  this.subscribeChanges();
}
subscribeChanges() {
  this.CompartmentDetailsForm.get('cemeteryCtrl')?.valueChanges.subscribe((value) => {     
    this.compartmentmain.cemeteryId= value;
  });
  this.CompartmentDetailsForm .get('divisionCtrl')?.valueChanges.subscribe((value) => {     
    this.compartmentmain.divisionId= value;
  });
  this.CompartmentDetailsForm.get('sectionCtrl')?.valueChanges.subscribe((value) => {     
    this.compartmentmain.sectionId= value;
  });
}
  
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  onCemeteryChange(){
    const selectedCemetery = this.cemeteries.find(cem => cem.code === this.compartmentmain.cemeteryId);
    if(selectedCemetery){      
      this.divisionService.getDivisionsByCementeryId(selectedCemetery.code).subscribe(
        (response : IDivisionDto[]) => {
          this.divisions = response;
        })
    }
  }
  onDivisionChange() {         
    const selectedDivision = this.divisions.find(div => div.id === this.compartmentmain.divisionId);
    if (selectedDivision) {
      this.sectionService.GetSectionByDivisionId(selectedDivision.id).subscribe(
        (response : ISectionDto[]) =>{
          this.sections = response;
        }
      )          
    } else {
      this.sections = [];
    }
  }
  onSectionChange() {         
    const selectedSection = this.sections.find(sec => sec.id === this.compartmentmain.sectionId);
    if (selectedSection) {
      this.compartmentService.GetCompartmentBySectionId(selectedSection.id).subscribe(
        (response : ICompartmentDto[]) => {
          this.compartments = response;
           this.dataSource = new MatTableDataSource(this.compartments);	
        }
      )
    } else {
      this.compartments = [];
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

    this.selectedCompartment = null;
  }

  editCompartmentDialog(compartmentmain: ICompartmentDto) {
    let compartmentName: number = +compartmentmain.id;
     // instead of GetCompartmentBySectionId --> use getCompartmentsById(after setting the endpoints)
     this.compartmentService.GetCompartmentBySectionId(compartmentmain.sectionId).subscribe(
       (compartmentObj: ICompartmentDto[]) => {
         const dialogRef = this.dialog.open(CompartmentEditComponent, {
           data: compartmentObj
         
         });
       dialogRef.afterClosed().subscribe(result => {
           console.log('The dialog was closed');          
         });
       },
       error => {
         console.error('Error:', error);        
       }
     );
   }
  NewCompartmentDialog() {
    const dialogRef = this.dialog.open(CompartmentNewComponent, {
      restoreFocus: false,
      
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
     
    });
  }
}