import { Component, Inject, Input, Optional, Renderer2 } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IBurialPermitDto } from '../../models/BurialPermitDto.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { IPreViewBurialPermit } from 'src/app/models/PreViewBurialPermit.model';
import { IDeceasedDto } from 'src/app/models/Deceased.model';
import { LocalStorageService } from 'src/app/local-storage.service';

@Component({
  selector: 'app-burial-permit-details-info',
  templateUrl: './burial-permit-details-info.component.html',
  styleUrls: ['./burial-permit-details-info.component.css']
})
export class BurialPermitDetailsInfoComponent {
  @Input() burialPermitPreview!: IPreViewBurialPermit; 
  deceased: IDeceasedDto[]=[];
  panelOpenState = true;
  constructor (private datePipe: DatePipe,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any)
    {
      if (data) {
        this.burialPermitPreview = data;
      }
    }

    calculateAge(dateOfBirth: Date, dateOfDeath: Date): string {
      let age_response: string;
    
      if (dateOfBirth && dateOfDeath) {
        let years = dateOfDeath.getFullYear() - dateOfBirth.getFullYear();
        let months = dateOfDeath.getMonth() - dateOfBirth.getMonth();
        let days = dateOfDeath.getDate() - dateOfBirth.getDate();
    
        // Adjust for negative months or days
        if (months < 0 || (months === 0 && days < 0)) {
          years--;
          months += (months < 0 ? 12 : 0);
        }
    
        if (days < 0) {
          months--;
          const prevMonthDays = new Date(dateOfDeath.getFullYear(), dateOfDeath.getMonth(), 0).getDate();
          days += prevMonthDays;
        }
    
        if (years === 0) {
          if (months === 0) {
            age_response = `${days} days`;
          } else {
            age_response = `${months} months and ${days} days`;
          }
        } else {
          age_response = `${years} years`;
        }
    
        return age_response;
      }
    
      return "";
    }
  
  formatDate(date?: Date): string {
    if (!date) {
      return '';
    }
    return this.datePipe.transform(date, 'dd/MM/yyyy') || '';
  }


  // closeDialog() {
  //   this.dialogRef.close();
  // }
}

