import { Component, OnInit } from '@angular/core';
import { RoleModuleDto } from 'src/app/models/RoleModuleDto.model';
import { RoleDto } from 'src/app/models/RoleDto.model';
import { RoleService } from 'src/app/services/role.service'; 
import { RoleModuleService } from 'src/app/services/role-module.service';  

@Component({
  selector: 'app-moduleaccess',
  templateUrl: './moduleaccess.component.html',
  styleUrls: ['./moduleaccess.component.css']
})
export class ModuleAccessComponent implements OnInit {
  roles: RoleDto[] = [];  
  modules: RoleModuleDto[] = [];  // Will contain all modules by default
  selectedRole: number | null = null;  
  selectedAccessLevels: { [moduleId: number]: string } = {};  

  constructor(private roleService: RoleService, private moduleService: RoleModuleService) {}

  ngOnInit(): void {
    this.loadRoles();  
    this.loadAllModules();  // Load all modules by default
  }

  // Load all roles and set default role as Admin if it exists
  loadRoles(): void {
    this.roleService.getAllRoles().subscribe(
      (roles: RoleDto[]) => {
        this.roles = roles;

        // Check if Admin role exists and set it as the default selected role
        const adminRole = roles.find(role => role.name === 'Admin');
        if (adminRole) {
          this.selectedRole = adminRole.id; // Assuming id is a number
          this.onRoleChange(this.selectedRole); // Load modules for Admin
        }
      },
      (error) => {
        console.error('Error loading roles:', error);
      }
    );
  }

  // Load all modules without role selection
  loadAllModules(): void {
    this.moduleService.getAllModules().subscribe(
      (modules: RoleModuleDto[]) => {
        this.modules = modules;  // Store all modules
      },
      (error) => {
        console.error('Error loading modules:', error);
      }
    );
  }

  
  onRoleChange(roleId: number): void {
    this.selectedRole = roleId;
  
    if (roleId) {
      this.moduleService.getModulesByRole(roleId).subscribe(
        (roleModules: RoleModuleDto[]) => {
          this.modules = roleModules;

          
          roleModules.forEach(roleModule => {
            this.selectedAccessLevels[roleModule.moduleId] = roleModule.accessLevel;
          });

         
          this.modules.forEach(module => {
            if (!this.selectedAccessLevels[module.moduleId]) {
              this.selectedAccessLevels[module.moduleId] = 'Full';  
            }
          });
        },
        (error) => {
          console.error('Error loading role modules:', error);  
        }
      );
    } else {
      // Reset modules if no role is selected
      this.modules = [];
    }
  }

  // Handle access level changes
  onAccessLevelChange(moduleId: number, newAccessLevel: string): void {
    this.selectedAccessLevels[moduleId] = newAccessLevel;
  }

  // Save the updated module access levels for the selected role
  saveModuleAssignments(): void {
    if (!this.selectedRole) {
      console.error('No role selected');
      return;
    }

    const updatedAssignments: RoleModuleDto[] = this.modules.map(module => ({
      roleId: this.selectedRole as number,
      moduleId: module.moduleId,
      accessLevel: this.selectedAccessLevels[module.moduleId],  // Updated access level
      moduleName: module.moduleName,
      roleName: module.roleName,
      createdAt: module.createdAt,
      accessLevelPriority: module.accessLevelPriority
    }));

    this.moduleService.updateRoleModules(this.selectedRole, updatedAssignments).subscribe({
      next: (response) => {
        console.log('Modules updated successfully:', response);
      },
      error: (err) => {
        console.error('Error updating modules:', err);
      }
    });
  }
}
