<div class="body-container  fixed-left">
    <app-menu-bar fxShow="true" fxHide.lt-md></app-menu-bar>
  </div>
<div class="body-container">
  <div class="header_container">
    <mat-divider></mat-divider>
    <h1>Burial Details</h1>
    <mat-divider></mat-divider>
  </div>
  
<div class="container">
  <div class="form">
    <app-burial-permit-details-view [graveId] = "0"></app-burial-permit-details-view> 
</div>
</div>