import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IGraveDto } from '../models/GraveDto.model';
import { Observable, catchError, map, of, tap, throwError } from 'rxjs';
import { ITableCountDto } from '../burials/models/ITableCountDto';
import { IGraveSizeDto } from '../models/GraveSizeDto.model';
import { IAddGraveDto } from '../models/AddGraveDto.model';
import { IResponseModal } from '../models/ResponseModal.model';
import { IGraveForUpdateDto } from '../models/GraveForUpdateDto.model';
import { environment } from 'src/environments/environment';
import { IGraveTypeDto } from '../models/GraveTypeDto.model';
import { IGraveLevelsDto } from '../models/GraveLevelsDto.model';
import { AuthService } from '../auth.service';
import { IRemarksPerCompartmentDto } from '../models/RemarksPerCompartmentDto.model';

@Injectable({
  providedIn: 'root'
})
export class GraveService {
  

  private baseUrl = `${environment.apiUrl}grave/`; 
  constructor(private http: HttpClient, private authService: AuthService) { }

  getGraveById(id:number): Observable<IGraveDto> {
    const headers = this.authService.getAuthHeaders();    
    const url = `${this.baseUrl}${id}`;
    return this.http.get<IGraveDto>(url,{headers})
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };

  getGraveByCompartmentId(id:number): Observable<IGraveDto[]> {    
    const headers = this.authService.getAuthHeaders();
    const endpoint = 'GetGravesByCompartmentId'; 
    const url = `${this.baseUrl}${endpoint}?id=${id}`;
    return this.http.get<IGraveDto[]>(url,{headers})
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };

  getRemarksPerGrave(graveId: number): Observable<IRemarksPerCompartmentDto> {    
    const headers = this.authService.getAuthHeaders();
    const endpoint = 'GetRemarksPerCompartment'; 
    const url = `${this.baseUrl}${endpoint}?graveId=${graveId}`;
    return this.http.get<IRemarksPerCompartmentDto>(url, { headers })
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
}

  getAllGraves(
    filterCriteria: string | null, 
    searchQuery: string | null, 
    pageNumber: number = 1, 
    pageSize: number = 25 ): Observable<ITableCountDto> {
    
    const headers = this.authService.getAuthHeaders();
    let params = new HttpParams();
    if (filterCriteria) {
        params = params.append('filterCriteria', filterCriteria);
    }
    if (searchQuery) {
        params = params.append('searchQuery', searchQuery);
    }
    
    params = params.append('pageNumber', pageNumber.toString());
    params = params.append('pageSize', pageSize.toString());

    return this.http.get<ITableCountDto>(this.baseUrl + 'GetAllGraves', { params,headers })
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };

  searchAllGraves(
    filterCriteria: string | null, 
    searchQuery: string | null, 
    pageNumber: number = 1, 
    pageSize: number = 25 ): Observable<ITableCountDto> {
    
    const headers = this.authService.getAuthHeaders();
    let params = new HttpParams();
    if (filterCriteria) {
        params = params.append('filterCriteria', filterCriteria);
    }
    if (searchQuery) {
        params = params.append('searchQuery', searchQuery);
    }
    
    params = params.append('pageNumber', pageNumber.toString());
    params = params.append('pageSize', pageSize.toString());

    return this.http.get<ITableCountDto>(this.baseUrl + 'SearchAllGraves', { params,headers })
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };



  getAllGravesByGraveNo(
    cemeteryId : number = 0, 
    divisionId : number = 0, 
    sectionId : number = 0 , 
    compartementId : number = 0, 
    graveNo: string, 
    pageNumber: number, 
    pageSize: number ): Observable<ITableCountDto> {
    
    const headers = this.authService.getAuthHeaders();
    
    let params = new HttpParams();
    
    params = params.append('cemeteryId', (cemeteryId || 0).toString());
    params = params.append('divisionId', (divisionId || 0).toString());
    params = params.append('sectionId', (sectionId || 0).toString());
    params = params.append('compartementId', (compartementId || 0).toString());
    params = params.append('graveNo', graveNo ? graveNo : '0');

    params = params.append('pageNumber', pageNumber.toString());
    params = params.append('pageSize', pageSize.toString());

    return this.http.get<ITableCountDto>(this.baseUrl + 'GetGraveByGraveNo', { params,headers })
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
};


getGravesSizes(pageNumber: number = 1, pageSize: number = 100): Observable<IGraveSizeDto[]> {    
    let params = new HttpParams();
    const headers = this.authService.getAuthHeaders();
    params = params.append('pageNumber', pageNumber.toString());
    params = params.append('pageSize', pageSize.toString());

    return this.http.get<IGraveSizeDto[]>(this.baseUrl+ 'GetGravesSizes', { params,headers })
        .pipe(
            catchError(error => {   
                console.error('Error:', error);
                throw error;
            })
        );
  };

  addAGrave(newGraveDto: IAddGraveDto): Observable<IResponseModal> {
    const headers = this.authService.getAuthHeaders();
    return this.http.post<IResponseModal>(this.baseUrl + 'AddGrave', newGraveDto,{headers})
    .pipe(
        catchError(error => {
            console.error('Error:', error);
            throw error;
        })
    );
  }

  updateGrave(id: number, graveForUpdateDto: IGraveForUpdateDto): Observable<IResponseModal> {
    const url = `${this.baseUrl}${id}`;
    const headers = this.authService.getAuthHeaders();
    return this.http.put<HttpResponse<IResponseModal>>(url, graveForUpdateDto, { observe: 'response',headers }).pipe(
      map(response => {
        // Create a default modal object
        let modal: IResponseModal = {
          id: 0,
          title: 'Update Grave',
          message: ''
        };

        // Handle different response status codes
        if (response.status === 204) {
          modal.message = 'Grave updated successfully.';
        } else if (response.status === 200) {
          modal.message = 'To update a grave, at least one field needs to change. Currently, no changes have been registered.';
        } else {
          modal.message = `Unexpected status code: ${response.status}`;
        }

        // Return the modal object to the subscriber
        return modal;
      }),
      catchError((error) => {
        let modal: IResponseModal = {
          id: 0,
          title: 'Update Grave',
          message: ''
        };        
        if (error.status === 422) {
          modal.message = error.error || 'Cannot update grave due to validation issues.';
        } else if (error.status === 500) {
          modal.message = error.error?.message || 'An internal server error occurred. Please try again later.';
        } else {
          modal.message = 'Failed to update grave. Please try again later.';
        }        
        console.error('Update Grave Error:', error);        
        return throwError(() => modal);
      })
    );
}



  deleteGrave(id : number) : Observable<IResponseModal>{
    const headers = this.authService.getAuthHeaders();
    const url = `${this.baseUrl}${id}`;
    return this.http.delete<IResponseModal>(url,{headers})
    .pipe(
      catchError(error => {
          console.error('Error:', error);
          throw error;
      })
  );
  }

  getGravesTypes(pageNumber: number = 1, pageSize: number = 100): Observable<IGraveTypeDto[]> {    
    let params = new HttpParams();
    const headers = this.authService.getAuthHeaders();
    params = params.append('pageNumber', pageNumber.toString());
    params = params.append('pageSize', pageSize.toString());

    return this.http.get<IGraveTypeDto[]>(this.baseUrl+ 'GetGravesTypes', { params,headers })
        .pipe(
            catchError(error => {   
                console.error('Error:', error);
                throw error;
            })
        );
  };


  getGraveLevelsByGraveId(graveId:number): Observable<IGraveLevelsDto[]> { 
    const headers = this.authService.getAuthHeaders();   
    const endpoint = 'GetGraveLevelsByGraveId'; 
    const url = `${this.baseUrl}${endpoint}?graveId=${graveId}`;
    return this.http.get<IGraveLevelsDto[]>(url,{headers})
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  }

  updateLevelStatus(graveId: number, updateObj : any): Observable<any> {
    const headers = this.authService.getAuthHeaders();
    const url = `${this.baseUrl}UpdateGraveLevel?graveId=${graveId}`;
    return this.http.put<HttpResponse<IResponseModal>>(url, updateObj, { observe: 'response',headers }).pipe(
      map(response => {
        
        let modal: IResponseModal = {
          id: 0,
          title: 'Update Level Status',
          message: ''
        };
        if (response.status === 204) {  
          
          modal.message = 'Record Updated';

        } else if (response.status === 200) {    
          
          modal.message = 'To update at least one field needs to change, currently not changes have been registered.';

        } else {
          modal.message =`Unexpected status code: ${response.status}`;
          
          throw new Error(`Unexpected status code: ${response.status}`);
        }
        return modal;
      }),
      catchError((error) => {
        let modal: IResponseModal = {
          id: 0,
          title: 'Update Level Status',
          message: ''
        };        
        if (error.status === 422) {
          modal.message = error.error || 'Cannot update grave due to validation issues.';
        } else if (error.status === 500) {
          modal.message = error.error?.message || 'An internal server error occurred. Please try again later.';
        } else {
          modal.message = 'Failed to update grave. Please try again later.';
        }        
        console.error('Update Grave Error:', error);        
        return throwError(() => modal);
      })
    );
  }

  updateRemarks(graveId: number, updateObj : any): Observable<any> {
    const headers = this.authService.getAuthHeaders();
    const url = `${this.baseUrl}UpdateGraveRemarks?graveId=${graveId}`;
    return this.http.put<HttpResponse<IResponseModal>>(url, updateObj, { observe: 'response',headers }).pipe(
      map(response => {
        
        let modal: IResponseModal = {
          id: 0,
          title: 'Update Remarks',
          message: ''
        };
        if (response.status === 204) {  
          
          modal.message = 'Record Updated';

        } else if (response.status === 200) {    
          
          modal.message = 'To update at least one field needs to change, currently not changes have been registered.';

        } else {
          modal.message =`Unexpected status code: ${response.status}`;
          
          throw new Error(`Unexpected status code: ${response.status}`);
        }
        return modal;
      }),
      catchError((error) => {
        let modal: IResponseModal = {
          id: 0,
          title: 'Update Level Status',
          message: ''
        };        
        if (error.status === 422) {
          modal.message = error.error || 'Cannot update grave due to validation issues.';
        } else if (error.status === 500) {
          modal.message = error.error?.message || 'An internal server error occurred. Please try again later.';
        } else {
          modal.message = 'Failed to update grave. Please try again later.';
        }        
        console.error('Update Grave Error:', error);        
        return throwError(() => modal);
      })
    );
  }

}
