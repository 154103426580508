<div class="body-container  fixed-left">
  <app-menu-bar fxShow="true" fxHide.lt-md></app-menu-bar>
</div>

<div class="body-container">
  <div class="header_container">
    <mat-divider></mat-divider>
    <h1>Undertaker Details</h1>
    <mat-divider></mat-divider>
  </div>
  <div class="container">
   
    <div class="form">
        <!--TABLE FILTER-->
      <div id="searchandfilter_box" class="box">          
        <mat-form-field class="mat-form-field">
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Id Card Number" #input>
        </mat-form-field>
      </div>
      <mat-divider></mat-divider>
          <!--TABLE-->
      <div class="box">
        <div class="table-container mat-elevation-z6">
          <div *ngIf="isLoadingResults || isRateLimitReached" class="table-loading-shade">
            <div *ngIf="isLoadingResults">
              <mat-spinner></mat-spinner>
            </div>
            <div *ngIf="isRateLimitReached" class="table-rate-limit-reached">
              Loading...
            </div>
          </div>
        </div>
        <div class="table-table-container ">
         <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>
            <!-- Id Column -->
          <!-- <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef >ID</th>
            <td mat-cell *matCellDef="let undertakerObj">{{ undertakerObj.id}}</td>
          </ng-container> -->
          
          <!-- code Column -->
            <ng-container matColumnDef="code">
              <th mat-header-cell *matHeaderCellDef >Code</th>
              <td mat-cell *matCellDef="let undertakerObj">{{ undertakerObj.code}}</td>
            </ng-container>
            <!-- Vat No -->
            <ng-container matColumnDef="vatNo">
                <th mat-header-cell *matHeaderCellDef >Vat No</th>
                <td mat-cell *matCellDef="let undertakerObj"> {{ undertakerObj.vatNo }} </td>
            </ng-container>

             <!-- Locality -->
             <ng-container matColumnDef="locality">
              <th mat-header-cell *matHeaderCellDef >Locality</th>
              <td mat-cell *matCellDef="let undertakerObj"> {{ getLocalityDescription(undertakerObj.localityId)}} </td>
          </ng-container>

            <!-- Undertaker Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef  > Name</th>  
              <td mat-cell *matCellDef="let undertakerObj" >{{ undertakerObj.name}} </td>
            </ng-container>
  
            <!-- Undertaker Surname Column -->
            <ng-container matColumnDef="surname">
              <th mat-header-cell *matHeaderCellDef > Surname</th>
              <td mat-cell *matCellDef="let undertakerObj"> {{ undertakerObj.surname}}</td> 
            </ng-container>
            <!-- Undertaker Telephone Column -->
            <ng-container matColumnDef="telephone">
              <th mat-header-cell *matHeaderCellDef > Telephone</th>
              <td mat-cell *matCellDef="let undertakerObj"> {{ undertakerObj.telephone}}</td> 
            </ng-container>
            <ng-container matColumnDef="expand">
              <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
              <td mat-cell *matCellDef="let undertakerObj">
                <button mat-icon-button aria-label="expand row"  (click)="expandCollapse(undertakerObj); $event.stopPropagation()" >
                  <ng-container *ngIf="expandedElement === undertakerObj">
                      <mat-icon>keyboard_arrow_up</mat-icon>
                  </ng-container>
                  <ng-container *ngIf="expandedElement !== undertakerObj">
                      <mat-icon>keyboard_arrow_down</mat-icon>
                  </ng-container>
              </button>
              </td>
            </ng-container>
            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let undertakerObj" [attr.colspan]="displayedColumnsWithExpand.length">
                <div class="expand-detail" [@detailExpand] = "undertakerObj == expandedElement ? 'expanded' : 'collapsed' ">
                  <div class="expand-category">
                    <div class="expand-field">Address1 : &nbsp;<span>{{ undertakerObj.address1}}</span>
                      Address2 :&nbsp;<span>{{undertakerObj.address2}}</span>
                      <!-- Telephone : &nbsp;<span>{{undertakerObj.telephone}}</span> -->
                      </div>
                  </div>
                </div>
              </td>
            </ng-container>
            
            <!-- Action Column -->
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>Action</th>
              <td mat-cell *matCellDef="let undertakerObj">
                <button title="EDIT" class="editbutton" mat-icon-button (click)="editUndertakerClick(undertakerObj)"color="primary">
                  <mat-icon>mode</mat-icon>
                </button>
                <button title="DELETE" mat-icon-button color="warn"  (click)="deleteUndertaker(undertakerObj.id)">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsWithExpand"></tr>
          <tr mat-row *matRowDef="let undertakerObj; columns: displayedColumnsWithExpand;" class="expand-Inside"
            [class.example-expanded-row]="expandedElement != undertakerObj" ></tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" matRipple class="expand-Rowdetails"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="5">
              No data matching the filter "{{input.value}}"
            </td>
          </tr>
        </table>
     
      </div>
      </div>
      
      <mat-paginator [length]="resultsLength"
               [pageSize]="10"
               [pageSizeOptions]="[10, 25, 100]"
               aria-label="Select page">
      </mat-paginator>

      <div class="buttonnew">
        <button mat-raised-button (click)="NewUndertakerClick()" color="primary"><mat-icon>add</mat-icon>New</button>
      </div>
  
  </div>
 