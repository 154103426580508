import { Component, Inject } from '@angular/core';
import { FormBuilder,  FormGroup,  Validators } from '@angular/forms';
import { ICleaningTransportStatusDto } from '../models/CleaningTransportStatusDto.model';
import { ICleaningDto } from '../models/CleaningDto.model';
import { ISupervisorsDto } from '../models/SupervisorsDto.model';
import { IUndertakerDto } from '../models/UndertakerDto.model';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from '../services/common.service';
import { InternalCommunicationService } from '../services/internal-communication.service';
import { CleaningService } from '../services/cleaning.service';
import { IAddCleaningDto } from '../models/AddCleaningDto.model';
import { IResponseModal } from '../models/ResponseModal.model';
import { ResponsemodalComponent } from '../responsemodal/responsemodal.component';
import { ITableCountDto } from '../burials/models/ITableCountDto';
import { IGraveDto } from '../models/GraveDto.model';
import { UndertakerService } from '../services/undertaker.service';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { map, Observable, startWith } from 'rxjs';
import { RoleService } from '../services/role.service';
import { BurialPermitService } from '../services/burial-permit.service';
import { IBurialPermitDto } from '../burials/models/BurialPermitDto.model';

export const MY_FORMATS = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-new-cleaning',
  templateUrl: './new-cleaning.component.html',
  styleUrls: ['./new-cleaning.component.css'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {showError: true},
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-GB'
    },
    {
      provide: DateAdapter, useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_FORMATS
    },
  ],
})

export class NewCleaningComponent {
  undertakerSelected! :IUndertakerDto;
  cleaningNewFormGroup!: FormGroup;  
  cleaningStatuses! : ICleaningTransportStatusDto[];
  cleaningPermitNumber! : string;
  burialRightOptions:Observable<string[]> | undefined;
  lastBurial! : IBurialPermitDto;
  cleaning: ICleaningDto = {
    id : 0,
    cleaningDate : new Date,
    authPerson : '',
    supervisorId : 0,
    marbleSlab : false,
    isFamilyPresent : false,
    timeForCleaning : '',
    applicationDate : new Date,
    authoriseBy : '',
    authoriseId : '',    
    graveId : 0,
    levelId : 0,   
    feePaid : false,
    burialId : 0,
    cleaningTransportStatusId : 0,
    cleaningStatusDescription : '',
    supervisorName : '',
    supervisorSurname : '',
    employeeName : '',
    employeeSurname : '',
    providerName : '',    
    feeId : 0.,
    undertakerId : 0,
    
    
  };
  rightOptions:string[] = [
    'Common Grave',
    'Private Grave',
    'Owner',
    'Owners Consent',
    'Co-Owners Consent',
    'One of the Heirs',
    'Heirs Consent',
  ];

  
  supervisors! : ISupervisorsDto[]; 
  originalCleanings! :ICleaningDto[]; 
  cleanings! :ICleaningDto[];
  currentsCleanings! : ICleaningDto[];
  selectedClean: ICleaningDto | null = null;
  undertakers : IUndertakerDto[]  = [];
  grave : IGraveDto;

  constructor(
    public dialogRef: MatDialogRef<NewCleaningComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IGraveDto,   
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private commonService : CommonService,    
    private cleaningService : CleaningService,
    private roleService : RoleService,
    private undertakerService : UndertakerService,
    private burialPermitService : BurialPermitService )
     {      
      this.grave = data;
     }

     ngOnInit(): void {
      const loggedInUser = this.roleService.getUser();
      if (loggedInUser) {
        this.cleaning.authPerson = loggedInUser.name + ' ' + loggedInUser.surname ;
      }
      this.getAllSupervisors()
      this.getCleaningPermitNumber('');   
      this.getAllSupervisors();
      this.getAllUndertakers();      
      
    
        this.cleaningNewFormGroup = this._formBuilder.group({     
          cleaningPermitCtrl: [{value: this.cleaning ? this.cleaning.cleaningPermitNumber : '',disabled : true}],
          cleaningDateCtrl: [this.cleaning ? this.cleaning.cleaningDate : new Date, [Validators.required,]], 
          authorisingPersonCtrl: [{value :this.cleaning ? this.cleaning.authPerson : '',disabled : true}],
          supervisorCtrl: [this.cleaning ? this.cleaning.supervisorId : 0, [Validators.required,Validators.min(1)]], 
          marbleSlabCtrl: [this.cleaning.marbleSlab === true ? true : this.cleaning.marbleSlab === false ? false : null],
          isFamilyPresentCtrl: [this.cleaning ? this.cleaning.isFamilyPresent : ''],
          timeForCleaningCtrl: [this.cleaning ? this.cleaning.timeForCleaning : ''],
          applicationDateCtrl: [this.cleaning ? this.cleaning.applicationDate : new Date],
          authoriseByCtrl: [this.cleaning ? this.cleaning.authoriseBy : '', ],
          authoriseIdCtrl: [this.cleaning ? this.cleaning.authoriseId : '', ],
          //cleanedByIdCtrl: [this.cleaning ? this.cleaning.employeeId : 0, ], 
          undertakerCtrl: [{value: this.cleaning.undertakerId ? this.cleaning.undertakerName?? '' + '' + this.cleaning.undertakerSurname?? '' : '', disabled: false}], 
          feePaidCtrl :  [this.cleaning ? this.cleaning.feePaid : false],           
          burialCtrl : [this.cleaning ? this.cleaning.burialId : 0, ], 
          burialRightsCtrl : [this.cleaning ? this.cleaning.burialRights: '', [Validators.required,Validators.pattern(/^[a-zA-Z0-9,().'`" \/\\-]*$/)]],
        });
        this.subscribeChanges();

        this.burialRightOptions = this.cleaningNewFormGroup.get('burialRightsCtrl')?.valueChanges.pipe(startWith(''),
          map(value => this._filter2(value || ''))
        );
      }

      subscribeChanges() {
        this.cleaningNewFormGroup.get('cleaningPermitCtrl')?.valueChanges.subscribe((value) => {          
          this.cleaning.cleaningPermitNumber = value;
        });
        this.cleaningNewFormGroup.get('undertakerIdCtrl')?.valueChanges.subscribe((value) => {          
          this.cleaning.undertakerId = value;
        });
        this.cleaningNewFormGroup.get('cleaningDateCtrl')?.valueChanges.subscribe((value) => {          
          this.cleaning.cleaningDate = new Date(value._d) ;
        });
        this.cleaningNewFormGroup.get('authorisingPersonCtrl')?.valueChanges.subscribe((value) => {          
          this.cleaning.authPerson = value;
        });    
    
        this.cleaningNewFormGroup.get('marbleSlabCtrl')?.valueChanges.subscribe((value) => {
          if (value === true) {
            this.cleaning.marbleSlab = true;
          } else if (value === false) {
            this.cleaning.marbleSlab = false;
          } else {
            this.cleaning.marbleSlab = null; 
          }
        });
        this.cleaningNewFormGroup.get('isFamilyPresentCtrl')?.valueChanges.subscribe((value) => {          
          this.cleaning.isFamilyPresent = value;
        });
        this.cleaningNewFormGroup.get('timeForCleaningCtrl')?.valueChanges.subscribe((value) => {          
          this.cleaning.timeForCleaning = value;
        });
        this.cleaningNewFormGroup.get('applicationDateCtrl')?.valueChanges.subscribe((value) => {          
          this.cleaning.applicationDate = new Date(value._d);
        });
        this.cleaningNewFormGroup.get('authoriseByCtrl')?.valueChanges.subscribe((value) => {          
          this.cleaning.authoriseBy = value;
        });
        this.cleaningNewFormGroup.get('authoriseIdCtrl')?.valueChanges.subscribe((value) => {          
          this.cleaning.authoriseId = value;
        });    
        this.cleaningNewFormGroup.get('feePaidCtrl')?.valueChanges.subscribe((value) => {          
          this.cleaning.feePaid = value;
        });  
        this.cleaningNewFormGroup.get('undertakerIdCtrl')?.valueChanges.subscribe((value) => {          
          this.cleaning.undertakerId = value;
        });      
      }

  //resources
 
  getAllSupervisors(){
    this.commonService.getAllSupervisors(null,null,1,100).subscribe(
      (response : ITableCountDto) =>{
        this.supervisors = response.results;
  
      }, (error) =>{
        console.error('An error happens retrieving supervisors data',error)
  
      })
   }

     supervisorChange(supervisorId : number){
       this.cleaning.supervisorId = supervisorId;
     }
    
     

     convertCleaningToAddCleaning(cleaning : ICleaningDto) : IAddCleaningDto{
     
      const addCleaning : IAddCleaningDto = {
        burialId : cleaning.burialId,
        cleanindDate : cleaning.cleaningDate,
        supervisorId : cleaning.supervisorId,
        marbleSlab : cleaning.marbleSlab,
        isFamilyPresent : cleaning.isFamilyPresent,
        timeForCleaning : cleaning.timeForCleaning,
        applicationDate : cleaning.applicationDate,
        authPerson : cleaning.authPerson,
        authoriseBy : cleaning.authoriseBy,
        authoriseId : cleaning.authoriseId,
        cleaningPermitNumber : cleaning.cleaningPermitNumber,        
        graveId :this.grave.id,
        levelId : 0,
        feePaid : cleaning.feePaid,
        feeId : cleaning.feeId, 
        undertakerId : cleaning.undertakerId,
        burialRights : this.cleaningNewFormGroup.get('burialRightsCtrl')?.value,
      }
      
      return addCleaning;
    }
    utcDate(localDate : Date) : Date{
      const utcDate: Date = new Date(Date.UTC(
        localDate.getFullYear(), 
        localDate.getMonth(), 
        localDate.getDate(), 
        localDate.getHours(), 
        localDate.getMinutes(), 
        localDate.getSeconds()
      ));
      return utcDate;
    }

    openResponseModal(response : IResponseModal): void {
      const dialogRef = this.dialog.open(ResponsemodalComponent, {
        width: '250px',
        data : response,
      });
    
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
    }

    addCleaning(cleaning: ICleaningDto) {
      let addCleaning: IAddCleaningDto = this.convertCleaningToAddCleaning(cleaning);
    
      this.getLastBurial(this.grave.id).then((isOlderThanTwoYears: boolean) => {
        if (!isOlderThanTwoYears) {
          
          const dialogRef = this.dialog.open(ConfirmationModalComponent, {
            width: '350px',
            data: {
              title: '2-Year Requirement',
              message: 'The burial date does not meet the 2-year requirement. Are you sure you want to proceed?',
              closeOnly: false
            }
          });
    
          dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
              this.addCleaningProcess(addCleaning);
            }
          });
        } else {
          // Proceed directly without showing notification
          this.addCleaningProcess(addCleaning);
        }
      }).catch((error) => {
        console.error('Error retrieving the last burial:', error);
      });
    }
    
    addCleaningProcess(addCleaning: IAddCleaningDto) {
      this.cleaningService.existsBurialInCleaningAsync(this.grave.id).subscribe(
        (exists: boolean) => {
          if (exists) {
            this.openConfirmationModal(addCleaning);
          } else {
            this.cleaningService.addCleaning(addCleaning).subscribe(
              (response: IResponseModal) => {
                this.openResponseModal(response);
              },
              (error) => {
                console.error('Error adding a cleaning: ', error);
              }
            );
          }
        },
        (error) => {
          console.error('Error checking burial existence:', error);
        }
      );
    }

    getLastBurial(graveId: number): Promise<boolean> {
      return new Promise((resolve, reject) => {
        this.burialPermitService.getLastBurialPermit(graveId).subscribe(
          (response: IBurialPermitDto) => {
            if (response && response.burialDate) {
              const burialDate = new Date(response.burialDate);
              const currentDate = new Date();
              const twoYearsAgo = new Date();
              twoYearsAgo.setFullYear(currentDate.getFullYear() - 2);
    
              
              resolve(burialDate < twoYearsAgo);
            } else {
              resolve(false); 
            }
          },
          (error) => {
            console.error('Something went wrong:', error);
            reject(error);
          }
        );
      });
    }
    

    dateFilterOnlyFuture = (d: Date | null): boolean => {
      if (!d) {
        return false; 
      }  
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return !(d < today);
    };

    undertakerChange(undertakerId : number){
      const selectedUndertaker = this.undertakers.find(x => x.id === undertakerId);
      if(selectedUndertaker){
        this.undertakerSelected = selectedUndertaker;
        this.cleanings.forEach(cleaning => {
          cleaning.undertakerId = undertakerId; 
      });    
        this.cleaning.undertakerId = selectedUndertaker.id;  
        this.cleaning.undertakerName = selectedUndertaker.name;
        this.cleaning.undertakerSurname = selectedUndertaker.surname;      
      }else{      
        this.cleaning.undertakerId = 0;
      }
      
      
     }

     getAllUndertakers(){
      this.undertakerService.getAllUndertakers().subscribe(
        (response : IUndertakerDto[]) =>{
          this.undertakers = response;
    
        }, (error) =>{
          console.error('An error happens retrieving undertakers data: ',error)
    
        })
      }

      getCleaningPermitNumber(cleaningPermit :string){
        if(cleaningPermit.length === 0){
          this.commonService.getCleaningPermitNumber().subscribe(
            (response : string) => {
              this.cleaningPermitNumber = response;       
              this.cleaningNewFormGroup.get('cleaningPermitCtrl')?.setValue(response);
            })
  
        }else{
          this.cleaningNewFormGroup.get('cleaningPermitCtrl')?.setValue(cleaningPermit);
        }
        
      }


      openConfirmationModal(addCleaning : IAddCleaningDto): void {
        const dialogRef = this.dialog.open(ConfirmationModalComponent, {
          width: '350px',
          data: {
            title: 'Add new Cleaning',
            message: 'This grave already has an existing cleaning schedule. Would you like to proceed and attempt to create a cleaning for any other burials associated with this grave?',
            closeOnly: false
          }
        });
      
        dialogRef.afterClosed().subscribe(result => {
          if (result === true) {
            this.cleaningService.addCleaning(addCleaning).subscribe(
              (response: IResponseModal) => {
                this.openResponseModal(response);
              },
              (error) => {
                console.error('Error adding a cleaning: ', error);
              }
            );
            
          } else {
            
            
          }
        });
      }

      onBurialRightSelected(event: any): void {
        const selectedOption = event.option?.value; 
        const rightBurialControl = this.cleaningNewFormGroup.get('burialRightsCtrl');
        if (rightBurialControl) {
          rightBurialControl.setValue(selectedOption);
        }
      }

      private _filter2(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.rightOptions.filter(rightoption => rightoption.toLowerCase().includes(filterValue));
      }

      

}
