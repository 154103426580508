import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { User } from 'src/app/models/User.model';
import { RoleDto } from 'src/app/models/RoleDto.model';  
import { RoleService } from 'src/app/services/role.service';
import { UserService } from 'src/app/services/user.service';
import { IResponseModal } from 'src/app/models/ResponseModal.model';
import { ResponsemodalComponent } from 'src/app/responsemodal/responsemodal.component';

@Component({
  selector: 'app-addusers',
  templateUrl: './addusers.component.html',
})
export class AddusersComponent implements OnInit {
  addUserForm: FormGroup;
  roles: RoleDto[] = [];
  newUser : User = {
    id: 0,
    userName : '',
    name : '',
    surname: '',
    email : '',
    roleId: 0,
         
  
  };

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddusersComponent>,
    private roleService: RoleService,
    private userService: UserService,
    public dialog: MatDialog,
  ) {
    this.addUserForm = this.fb.group({
      //userID: [{ value: '', disabled: true }],  
      username: ['', Validators.required],
      name: ['', Validators.required],
      surname: ['', Validators.required],
      idNo: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      roleID: [null, Validators.required],
      isActive: [true, Validators.required],  
      //createdOn: [{ value: new Date(), disabled: true }]  
    });
  }

  ngOnInit(): void {
    this.loadRoles();  
    this.subscribeChanges();
  }

  subscribeChanges() {    
      this.addUserForm .get('username')?.valueChanges.subscribe((value) => {     
        this.newUser.userName = value;
      });
      this.addUserForm .get('name')?.valueChanges.subscribe((value) => {     
        this.newUser.name = value;
      });
      this.addUserForm .get('surname')?.valueChanges.subscribe((value) => {     
        this.newUser.surname = value;
      });
      this.addUserForm .get('idNo')?.valueChanges.subscribe((value) => {     
        this.newUser.idNo = value;
      });
      this.addUserForm .get('email')?.valueChanges.subscribe((value) => {     
        this.newUser.email = value;
      });
      this.addUserForm .get('roleID')?.valueChanges.subscribe((value) => {     
        this.newUser.roleId = value;
      });
      this.addUserForm .get('isActive')?.valueChanges.subscribe((value) => {     
        this.newUser.isActive = value;
      });
    }

  loadRoles(): void {
    this.roleService.getAllRoles().subscribe({
      next: (roles: RoleDto[]) => {
        this.roles = roles;  
      },
      error: (err) => {
        console.error('Error loading roles:', err);
      }
    });
  }

  onCancel(): void {
    this.dialogRef.close();  
  }

  onSave(): void {
    this.userService.createUser(this.newUser).subscribe(
      (response : any) =>{
        response.title = 'User';
        response.message = 'User Added : ';
        this.openResponseModal(response);

      }, (error) =>{
        error.title = 'User';
        
        this.openResponseModal(error);
      })
  }

  openResponseModal(response : IResponseModal): void {
    const dialogRef = this.dialog.open(ResponsemodalComponent, {
      width: '250px',
      data : response,
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}
