
<div class="dialog-header">
  <button mat-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="container-popup">
    <div class="form">
      <span class="mat-title" mat-dialog-title><b> Add New Cleaning</b> </span>
      <form [formGroup]="cleaningNewFormGroup">    
      
        <mat-dialog-content>           
        <div class="box">
          <mat-form-field>
            <mat-label>Cleaning Permit</mat-label>              
            <input matInput placeholder="Cleaning Permit No" formControlName="cleaningPermitCtrl" />
            <mat-error>
              <span *ngIf="cleaningNewFormGroup.get('cleaningPermitCtrl')?.value && cleaningNewFormGroup.get('cleaningPermitCtrl')?.invalid && cleaningNewFormGroup.get('cleaningPermitCtrl')?.errors?.['pattern']">Invalid field format</span>
            </mat-error> 
          </mat-form-field>

          <mat-form-field>
            <mat-label>Cleaning Date</mat-label>
            <input matInput readonly [matDatepickerFilter]="dateFilterOnlyFuture" [matDatepicker]="pickercleanindDate" formControlName="cleaningDateCtrl"/>
            <mat-datepicker-toggle matIconSuffix [for]="pickercleanindDate">
            </mat-datepicker-toggle>
            <mat-datepicker #pickercleanindDate></mat-datepicker>
            <mat-error>
              <span *ngIf="!cleaningNewFormGroup.get('cleaningDateCtrl')?.value">Field required</span>
            </mat-error>   
          </mat-form-field>

          <!--<mat-form-field>
            <mat-label>Undertakers</mat-label>
              <mat-select formControlName="undertakerCtrl" (selectionChange)="undertakerChange($event.value)" >
                <mat-option *ngFor="let undertaker of undertakers" [value]="undertaker.id">
                  {{ undertaker.code }} | {{ undertaker.name }} | {{ undertaker.surname }}
                </mat-option>
              </mat-select>
              <mat-error>
                <span *ngIf="!cleaningNewFormGroup.get('undertakerCtrl')?.value">Field required</span>
              </mat-error>  
          </mat-form-field>-->
          
        </div>    
  
          <div class="box">
            <mat-form-field>
              <mat-label>Authorising Person</mat-label>              
              <input matInput placeholder="Authorising Person" formControlName="authorisingPersonCtrl" />
              <mat-error>
                <span *ngIf="cleaningNewFormGroup.get('authorisingPersonCtrl')?.value && cleaningNewFormGroup.get('authorisingPersonCtrl')?.invalid && cleaningNewFormGroup.get('authorisingPersonCtrl')?.errors?.['pattern']">Invalid field format</span>
              </mat-error> 
            </mat-form-field>

            <mat-form-field>
              <mat-label>Burial Rights</mat-label>
              <!-- <input matInput placeholder="Burial Rights" formControlName="burialRightsCtrl" maxlength="100"/> -->
              <input matInput placeholder="Burial Rights" formControlName="burialRightsCtrl" [matAutocomplete]="auto1">
              <mat-autocomplete #auto1="matAutocomplete" (optionSelected)="onBurialRightSelected($event)">
                <mat-option *ngFor="let rightOptions of burialRightOptions | async" [value]="rightOptions">
                  {{ rightOptions }}
                </mat-option>
                </mat-autocomplete>
              <mat-error>
                <span *ngIf="cleaningNewFormGroup.get('burialRightsCtrl')?.value && cleaningNewFormGroup.get('burialRightsCtrl')?.invalid && cleaningNewFormGroup.get('burialRightsCtrl')?.errors?.['pattern']">Burial Rights must contain only numbers or letters</span>
              </mat-error>              
            </mat-form-field>
  
            <!--<mat-form-field>
              <mat-label>Authorise By</mat-label>              
              <input matInput placeholder="Authorise By" formControlName="authoriseByCtrl" />
              <mat-error>
                <span *ngIf="cleaningNewFormGroup.get('authorisingPersonCtrl')?.value && cleaningNewFormGroup.get('authorisingPersonCtrl')?.invalid && cleaningNewFormGroup.get('authorisingPersonCtrl')?.errors?.['pattern']">Invalid field format</span>
              </mat-error> 
            </mat-form-field>-->
  
           <!-- <mat-form-field>
              <mat-label>Authorise Id</mat-label>              
              <input matInput placeholder="Authorise Id" formControlName="authoriseIdCtrl" />
              <mat-error>
                <span *ngIf="cleaningNewFormGroup.get('authoriseIdCtrl')?.value && cleaningNewFormGroup.get('authoriseIdCtrl')?.invalid && cleaningNewFormGroup.get('authoriseIdCtrl')?.errors?.['pattern']">Invalid field format</span>
              </mat-error> 
            </mat-form-field>  -->        
            
          </div>
  
          <div class="box">
            <mat-form-field>
              <mat-label>Marble Slab</mat-label>
              <mat-select formControlName="marbleSlabCtrl">
                <mat-option [value]="false">No</mat-option>
                <mat-option [value]="true">Yes</mat-option>
                <mat-option [value]="null">Unknown</mat-option>     
              </mat-select>
            </mat-form-field>           
  
            <mat-form-field>
              <mat-label>Supervisor</mat-label>
              <mat-select formControlName="supervisorCtrl" (selectionChange)="supervisorChange($event.value)">                
                <mat-option *ngFor="let supervisor of supervisors" [value]="supervisor.id">
                  {{ supervisor.name }} {{ supervisor.surname }}
                </mat-option>
              </mat-select>           
              <mat-error>
                <span *ngIf="!cleaningNewFormGroup.get('supervisorCtrl')?.value">Field required</span>
              </mat-error>   
            </mat-form-field>
          </div> 
          <div class="box">
            <mat-form-field>
              <mat-label>Time of Cleaning</mat-label>
              <input type="time" matInput placeholder= "Time of Cleaning" formControlName="timeForCleaningCtrl">
              <mat-error>
                <span *ngIf="cleaningNewFormGroup.get('timeForCleaningCtrl')?.value && cleaningNewFormGroup.get('timeForCleaningCtrl')?.invalid && cleaningNewFormGroup.get('timeForCleaningCtrl')?.errors?.['pattern']">Invalid field format</span>
              </mat-error> 
            </mat-form-field>
  
            <mat-form-field>
              <mat-label>Application Date</mat-label>
              <input matInput readonly [matDatepicker]="pickerapplicationDate" formControlName="applicationDateCtrl"/>
              <mat-datepicker-toggle matIconSuffix [for]="pickerapplicationDate">
              </mat-datepicker-toggle>
              <mat-datepicker #pickerapplicationDate></mat-datepicker>
              <mat-error>
                <span *ngIf="cleaningNewFormGroup.get('applicationDateCtrl')?.value && cleaningNewFormGroup.get('applicationDateCtrl')?.invalid && cleaningNewFormGroup.get('applicationDateCtrl')?.errors?.['pattern']">Invalid field format</span>
              </mat-error> 
            </mat-form-field>
          </div>
  
          
         
          <mat-checkbox class="example-margin" formControlName="isFamilyPresentCtrl">Family Present</mat-checkbox>
          <mat-checkbox class="example-margin" formControlName="feePaidCtrl">Fee Paid</mat-checkbox>
        
          <div class="buttonalign">
            <button mat-raised-button color="primary"
              (click)="addCleaning(cleaning)"
              [disabled]="!cleaningNewFormGroup.get('burialRightsCtrl')?.value ||
              !cleaningNewFormGroup.get('supervisorCtrl')?.value || 
              !cleaningNewFormGroup.get('cleaningDateCtrl')?.value ">
              <mat-icon>check</mat-icon>SAVE
            </button>
            <button mat-raised-button style="background-color: rgb(143, 141, 141)" [mat-dialog-close] cdkFocusInitial><mat-icon>close</mat-icon>Close</button>
        </div>
        </mat-dialog-content>
      </form>
  
    </div>
    
  </div>
