import { Component, Inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-file-preview-modal',
  templateUrl: './file-preview-modal.component.html',
  styleUrls: ['./file-preview-modal.component.css']
})
export class FilePreviewModalComponent {
  fileURL: SafeResourceUrl | null = null; 
  fileDownloadURL: string | null = null; 
  fileType: string;
  fileName: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer 
  ) {
    this.fileName = data.fileName;
    this.fileType = this.getFileType(this.fileName);

   
    this.convertFileToBase64(data.fileBlob);
  }

  // Function to get the file type based on the file extension
  getFileType(fileName: string): string {
    const extension = fileName.split('.').pop()?.toLowerCase();
    switch (extension) {
      case 'pdf':
        return 'application/pdf';
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'gif':
        return `image/${extension}`;
      case 'docx':
      case 'doc':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      default:
        return 'application/octet-stream';
    }
  }

  
  convertFileToBase64(fileBlob: Blob) {
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64data = reader.result as string;

      if (this.fileType.startsWith('image/')) {
        
        this.fileURL = this.sanitizer.bypassSecurityTrustResourceUrl(base64data);
        
        const blob = new Blob([fileBlob], { type: this.fileType });
        this.fileDownloadURL = window.URL.createObjectURL(blob); 
      } else if (this.fileType === 'application/pdf') {
        // For PDFs, prepend 'data:application/pdf;base64,' to the Base64 string
        const base64PDF = `data:application/pdf;base64,${base64data.split(',')[1]}`;
        this.fileURL = this.sanitizer.bypassSecurityTrustResourceUrl(base64PDF);
      } else {        
        const blob = new Blob([fileBlob], { type: this.fileType });
        this.fileDownloadURL = window.URL.createObjectURL(blob);
      }
    };
    
    reader.readAsDataURL(fileBlob);
  }
}
