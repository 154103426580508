export enum BurialPermitStatus {
    New = 1,
    Reissued = 2,
    Completed = 3,
    Current = 4,
    PreSaved = 5
  }
  
  export enum BurialStatus {
    Confirmed = 1,
    UnConfirmed = 2
  }
  