import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RoleModuleDto } from 'src/app/models/RoleModuleDto.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RoleModuleService {
  private baseUrl = `${environment.apiUrl}authservice`;

  constructor(private http: HttpClient) {}


  getAllModules(): Observable<RoleModuleDto[]> {
    return this.http.get<RoleModuleDto[]>(`${this.baseUrl}/GetAllModules`);
  }

  
  getModulesByRole(roleId: number): Observable<RoleModuleDto[]> {
    return this.http.get<RoleModuleDto[]>(`${this.baseUrl}/GetAllRoleModulesByRoleId?roleId=${roleId}`);
  }

  updateRoleModules(roleId: number, updatedAssignments: RoleModuleDto[]): Observable<any> {
   
    return this.http.put(`${this.baseUrl}/UpdateRoleModules`, { roleId, updatedAssignments });
  }
}
