<div class="container">
    <div class="form">
    <form [formGroup]="NewGraveOwnersForm">
    <span class="mat-title" mat-dialog-title><b>Add New Grave Owners</b> </span>
    <mat-dialog-content>
    <div class="box">
        <mat-form-field>
            <mat-label>D/H File No </mat-label>
            <input matInput placeholder="DHfileNo" formControlName="DHfileNo" required/>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Grave Reference No</mat-label>    
            <input matInput placeholder="Grave Reference No" formControlName="referenceNo"/>
        </mat-form-field>
    </div>
    <div class="box">
        <mat-form-field>
          <mat-label>ID Card Type</mat-label>    
          <mat-select >
          <mat-option [value]= "1">ID Card</mat-option>
          <mat-option [value]= "2">Passport</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
        <mat-label>Id Card Number</mat-label>
        <input matInput placeholder="Id Card Number" formControlName="owner_id" required/>
        </mat-form-field>
    </div>
    <div class="box">
        <mat-form-field>
        <mat-label>Owner Name </mat-label>
        <input matInput placeholder="First Name" formControlName="Gname" required/>
        </mat-form-field>
        <mat-form-field>
        <mat-label>Owner Surname </mat-label>
        <input matInput placeholder="Surname" formControlName="Gsurname" required/>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Owner Type </mat-label>
            <mat-select [(value)]="ownerOption">
              <mat-option>Others</mat-option>
              <mat-option value="1">Main Owner</mat-option>
              <mat-option value="2">Co-owner</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="box">
          <mat-form-field>
            <mat-label>Address </mat-label>
            <input matInput placeholder="Address" formControlName="address"required/>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Locality </mat-label>
            <mat-select>
              <mat-option>None</mat-option>
              <mat-option value="1">Option 1</mat-option>
              <mat-option value="2">Option 2</mat-option>
              <mat-option value="3">Option 3</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Telephone </mat-label>
            <input matInput placeholder="Telephone" formControlName="telephone"required/>
          </mat-form-field>
        </div>
       
  <div class="box">
    <mat-form-field>
        <mat-label>Cemetery </mat-label>
        <mat-select [(value)]="cemeteryOption">
          <mat-option>None</mat-option>
          <mat-option value="option1">Option 1</mat-option>
          <mat-option value="option2">Option 2</mat-option>
          <mat-option value="option3">Option 3</mat-option>
        </mat-select>
      </mat-form-field>
    <mat-form-field>
        <mat-label>Division</mat-label>
        <mat-select [(value)]="divisionOption">
          <mat-option>None</mat-option>
          <mat-option value="option1">Option 1</mat-option>
          <mat-option value="option2">Option 2</mat-option>
          <mat-option value="option3">Option 3</mat-option>
        </mat-select>
      </mat-form-field>
   </div>
   <div class="box">
    <mat-form-field>
        <mat-label>Section </mat-label>
        <mat-select [(value)]="sectionOption">
          <mat-option>None</mat-option>
          <mat-option value="option1">Option 1</mat-option>
          <mat-option value="option2">Option 2</mat-option>
          <mat-option value="option3">Option 3</mat-option>
        </mat-select>
      </mat-form-field>
       <mat-form-field>
        <mat-label>Compartment </mat-label>
        <mat-select [(value)]="compartmentOption">
          <mat-option>None</mat-option>
          <mat-option value="option1">Option 1</mat-option>
          <mat-option value="option2">Option 2</mat-option>
          <mat-option value="option3">Option 3</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Grave No </mat-label>
        <mat-select [(value)]="graveOption">
          <mat-option>None</mat-option>
          <mat-option value="option1">Option 1</mat-option>
          <mat-option value="option2">Option 2</mat-option>
          <mat-option value="option3">Option 3</mat-option>
        </mat-select>
      </mat-form-field>
      
  </div>

<div class="divspace">
  <button mat-raised-button color="primary" (click)="onUpdateGraveOwners()">Save</button>
  <button mat-raised-button style="color:dimgray;" [mat-dialog-close] cdkFocusInitial>Close</button></div>
</mat-dialog-content>
</form>
</div>
</div>

