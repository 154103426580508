<form [formGroup]="searchFormGroup">
    <div *ngIf="isLoading" class="spinner-overlay">
        <mat-spinner></mat-spinner>
      </div>
    <!-- SEARCH BOX AND BUTTON -->
    <div class="search-container">
      <mat-form-field class="search-input">
        <mat-label>Search</mat-label>
        <input matInput placeholder="Search by" type="text" formControlName="searchCtrl">
      </mat-form-field>
      <button mat-raised-button id="search_btn" color="primary" (click)="searchClick()" 
      [disabled]="searchFormGroup.get('searchCtrl')?.value === ''">Search</button>
    </div>
  </form>  