import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IDeceasedDto } from 'src/app/models/Deceased.model';
import { IGenderDto } from 'src/app/models/Gender.model';
import { ILocalityDto } from 'src/app/models/LocalityDto.model';
import { IMaritalStatusDto } from 'src/app/models/MaritalStatusDto.model';
import { IResponseModal } from 'src/app/models/ResponseModal.model';
import { ResponsemodalComponent } from 'src/app/responsemodal/responsemodal.component';
import { DeceasedService } from 'src/app/services/deceased.service';
import { GenderService } from 'src/app/services/gender.service';
import { LocalityService } from 'src/app/services/locality.service';
import { MaritalStatusService } from 'src/app/services/marital-status.service';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';

export const MY_FORMATS = {
parse: {
  dateInput: 'L',
},
display: {
  dateInput: 'L',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
},
};

@Component({
  selector: 'app-deceased-edit',
  templateUrl: './deceased-edit.component.html',
  styleUrls: ['./deceased-edit.component.css'],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-GB'
    },
    {
      provide: DateAdapter, useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_FORMATS
    },
  ],
})
export class DeceasedEditComponent implements OnInit{

  
 DeceasedForm!: FormGroup;
 genders : IGenderDto[] = [];
  statuses : IMaritalStatusDto[] = [];
  localities: ILocalityDto[] = [];
  documentTypes = [
    { id: 1, description: 'Passport' },
    { id: 2, description: 'ID Card' }
  ];
 deceasedObj : IDeceasedDto = {
  idCardNumber: '',
    name: '',
    surname: '',
    address: '',
    documentTypeId: 0,
    maritalStatusId: 0,
    genderId: 0,
    spouseName: '',
    spouseSurname: '',
    dateOfBirth: new Date(),
    placeOfBirth: '',
    localityId: 0,
    profession: '',
    fatherName: '',
    fatherSurname: '',
    fatherStatus: '',
    motherName: '',
    motherSurname: '',
    motherStatus: ''
  }
 
 constructor(
 
   private _formBuilder: FormBuilder,
   public dialog: MatDialog,
   private deceasedService : DeceasedService,
   private localityService :LocalityService,
   private genderService :GenderService,
   private maritalStatusService :MaritalStatusService,
   public dialogRef: MatDialogRef<DeceasedEditComponent>,
   @Inject(MAT_DIALOG_DATA) public data: any)
   {
   this.deceasedObj=data;
   }
  

  ngOnInit() {
    this.getAllLocalities();
    this.getAllGender();
    this.getAllMaritalStatus();

    this.DeceasedForm = this._formBuilder.group({
      documentTypeCtrl: [this.deceasedObj.documentTypeId],
      deceasedIdCardNumberCtrl: [this.deceasedObj.idCardNumber, Validators.required],
      nameCtrl:[this.deceasedObj.name],
      surnameCtrl:[this.deceasedObj.surname],
      addressCtrl: [this.deceasedObj.address],
      dateOfBirthCtrl : [this.deceasedObj.dateOfBirth],
      placeOfBirthCtrl:[this.deceasedObj.placeOfBirth],
      professionCtrl:[this.deceasedObj.profession],
      spouseNameCtrl :[this.deceasedObj.spouseName],
      spouseSurnameCtrl:[this.deceasedObj.spouseSurname],
      fatherNameCtrl: [this.deceasedObj.fatherName],
      fatherSurnameCtrl: [this.deceasedObj.fatherSurname],
      fatherStatusCtrl: [this.deceasedObj.fatherStatus],
      motherNameCtrl: [this.deceasedObj.motherName],
      motherSurnameCtrl: [this.deceasedObj.motherSurname],
      motherStatusCtrl: [this.deceasedObj.motherStatus],
      genderCtrl: [this.deceasedObj.genderId],
      maritalStatusCtrl: [this.deceasedObj.maritalStatusId],
      localityCtrl: [this.deceasedObj.localityId],
  });
  this.subscribeChanges();
  }
  subscribeChanges() {

    this.DeceasedForm.get('documentTypeCtrl')?.valueChanges.subscribe((value) => {     
      const documentType = this.documentTypes.find(dt => dt.description === value);
    this.deceasedObj.documentTypeId = documentType ? documentType.id : 0;
  
    });
    this.DeceasedForm.get('deceasedIdCardNumberCtrl')?.valueChanges.subscribe((value) => {     
      this.deceasedObj.idCardNumber= value;
    });
    this.DeceasedForm.get('nameCtrl')?.valueChanges.subscribe((value) => {     
      this.deceasedObj.name= value;
    });
    this.DeceasedForm.get('surnameCtrl')?.valueChanges.subscribe((value) => {     
      this.deceasedObj.surname= value;
    });
    this.DeceasedForm.get('addressCtrl')?.valueChanges.subscribe((value) => {     
      this.deceasedObj.address= value;
    });
    this.DeceasedForm.get('dateOfBirthCtrl')?.valueChanges.subscribe((value) => {     
      this.deceasedObj.dateOfBirth= value._d;
    });
    this.DeceasedForm.get('professionCtrl')?.valueChanges.subscribe((value) => {     
      this.deceasedObj.profession= value;
    });
    this.DeceasedForm.get('spouseNameCtrl')?.valueChanges.subscribe((value) => {     
      this.deceasedObj.spouseName= value;
    });
    this.DeceasedForm.get('spouseSurnameCtrl')?.valueChanges.subscribe((value) => {     
      this.deceasedObj.spouseSurname= value;
    });
    this.DeceasedForm.get('fatherNameCtrl')?.valueChanges.subscribe((value) => {     
      this.deceasedObj.fatherName= value;
    });
    this.DeceasedForm.get('fatherSurnameCtrl')?.valueChanges.subscribe((value) => {     
      this.deceasedObj.fatherSurname= value;
    });
    this.DeceasedForm.get('fatherStatusCtrl')?.valueChanges.subscribe((value) => {     
      this.deceasedObj.fatherStatus= value;
    });
    this.DeceasedForm.get('motherNameCtrl')?.valueChanges.subscribe((value) => {     
      this.deceasedObj.motherName= value;
    });
    this.DeceasedForm.get('motherSurnameCtrl')?.valueChanges.subscribe((value) => {     
      this.deceasedObj.motherSurname= value;
    });
    this.DeceasedForm.get('motherStatusCtrl')?.valueChanges.subscribe((value) => {     
      this.deceasedObj.motherStatus= value;
    });
    this.DeceasedForm.get('genderCtrl')?.valueChanges.subscribe((value) => {     
      this.deceasedObj.genderId= value;
    });
    this.DeceasedForm.get('maritalStatusCtrl')?.valueChanges.subscribe((value) => {     
      this.deceasedObj.maritalStatusId= value;
    });
    this.DeceasedForm.get('localityCtrl')?.valueChanges.subscribe((value) => {     
      this.deceasedObj.localityId= value;
    });
   
  }
  getAllLocalities(){
    this.localityService.getAllLocalities().subscribe(
      (response: ILocalityDto[]) => {
        this.localities = response;  
            
      },
      (error) => {        
        console.error('Error adding localities: ', error);        
      }
      );
  }
  getAllGender(){
    this.genderService.getAllGender().subscribe(
      (response: IGenderDto[]) => {
        this.genders = response;        
      },
      (error) => {        
        console.error('Error retrieving genders: ', error);        
      }
      );
  }
  getAllMaritalStatus(){
    this.maritalStatusService.getAllMaritalStatus().subscribe(
      (response: IMaritalStatusDto[]) => {
        this.statuses = response;        
      },
      (error) => {        
        console.error('Error retrieving marital status: ', error);        
      }
      );
  }  
  
  updateDeceasedDetails(): void {
    this.populateUpdateObject();
    const id =this.deceasedObj.idCardNumber;
    this.deceasedService.updateDeceasedDetails(id, this.deceasedObj).subscribe(
      (response : IResponseModal) => {        
        this.openResponseModal(response);
        console.log('Deceased Details updated successfully');   
        this.dialogRef.close();
      },
      (error) => {
        console.error('Error occurred while updating deceased details:', error);        
      }
  )
   }
   
   
   openResponseModal(response : IResponseModal): void {
    const dialogRef = this.dialog.open(ResponsemodalComponent, {
      width: '250px',
      data : response,
      
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
populateUpdateObject(){
  
  this.deceasedObj = {
    idCardNumber : this.deceasedObj.idCardNumber,
    name : this.deceasedObj.name,
    surname : this.deceasedObj.surname,
    address : this.deceasedObj.address,
    documentTypeId : this.deceasedObj.documentTypeId,
    maritalStatusId : this.deceasedObj.maritalStatusId,
    genderId: this.deceasedObj.genderId,
    spouseName :this.deceasedObj.spouseName,
    spouseSurname: this.deceasedObj.spouseSurname,
    dateOfBirth: this.deceasedObj.dateOfBirth,
    placeOfBirth : this.deceasedObj.placeOfBirth,
    localityId : this.deceasedObj.localityId,
    profession :this.deceasedObj.profession,
    fatherName : this.deceasedObj.fatherName,
    fatherSurname : this.deceasedObj.fatherSurname,
    fatherStatus : this.deceasedObj.fatherStatus,
    motherName : this.deceasedObj.motherName,
    motherSurname : this.deceasedObj.motherSurname,
    motherStatus :this.deceasedObj.motherStatus,
    burialId :this.deceasedObj.burialId
  };    
}
getDocumentType(documentTypeId: number): string {
  const typedoc = this.genders.find(g => g.id === documentTypeId);
  return typedoc ? typedoc.description : '';
}
}
