<form [formGroup]="ownersFormGroup">
    <div class="box">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Grave Information</mat-label>
        <input
          matInput
          [value]="(grave.cemeteryName?.trim() || '') + ' - ' +
                    (grave.divisionName?.trim() || '') + ' - ' +
                    (grave.sectionName?.trim() || '') + ' - ' +
                    (grave.compartmentName?.trim() || '') + ' - ' +
                    ((grave.graveNo ? grave.graveNo.toString().trim() : '') || '')"


          readonly
        />
      </mat-form-field>
    </div>
      <mat-dialog-content >
          <div class="box">
              <mat-form-field>
                  <mat-label>Grave Id</mat-label>
                  <input matInput placeholder="Grave Id" formControlName="graveIdCtrl">
              </mat-form-field>                    
              
               <p><mat-slide-toggle labelPosition="before" color="primary" [checked]="mainOwner.isMainOwner" (change)="isMainOwner($event)">Main Owner</mat-slide-toggle></p>
          </div>
          <div class="box"> 
              <mat-form-field>
                  <mat-label>Dh File</mat-label>
                  <input matInput placeholder="Dh File" formControlName="ownerDhFileCtrl">
                  <mat-error>
                    <span *ngIf="ownersFormGroup.get('ownerDhFileCtrl')?.value && ownersFormGroup.get('ownerDhFileCtrl')?.invalid && ownersFormGroup.get('ownerDhFileCtrl')?.errors?.['pattern']">DH File Format Sample DH 121/231/31</span>
                  </mat-error> 
              </mat-form-field>                             
              <mat-form-field>
                  <mat-label>Id Card</mat-label>
                  <input matInput placeholder="Id Card" formControlName="idCardNumberCtrl">
                  <mat-error>
                  <span *ngIf="ownersFormGroup.get('idCardNumberCtrl')?.value && ownersFormGroup.get('idCardNumberCtrl')?.errors?.['pattern']">ID Card Number must contain only numbers or letters</span>
                  <span *ngIf="!ownersFormGroup.get('idCardNumberCtrl')?.value">Field required</span>                
                </mat-error>
              </mat-form-field> 
          
          </div>
          <div class="box">                            
              <mat-form-field>
                  <mat-label>Name</mat-label>
                  <input matInput placeholder="Name" formControlName="ownerNameCtrl">
                  <mat-error>
                    <span *ngIf="ownersFormGroup.get('ownerNameCtrl')?.value && ownersFormGroup.get('ownerNameCtrl')?.invalid && ownersFormGroup.get('ownerNameCtrl')?.errors?.['pattern']">Name must contain only letters</span>
                    <span *ngIf="!ownersFormGroup.get('ownerNameCtrl')?.value">Field required</span>
                  </mat-error>
              </mat-form-field>
              <mat-form-field>
                  <mat-label>Surname</mat-label>
                  <input matInput placeholder="Surname" formControlName="ownerSurNameCtrl">
                  <mat-error>
                    <span *ngIf="ownersFormGroup.get('ownerSurNameCtrl')?.value && ownersFormGroup.get('ownerSurNameCtrl')?.invalid && ownersFormGroup.get('ownerSurNameCtrl')?.errors?.['pattern']">Surname must contain only letters</span>
                    <span *ngIf="!ownersFormGroup.get('ownerSurNameCtrl')?.value">Field required</span>
                  </mat-error> 
              </mat-form-field>
          </div>
          <div class="box">
              <mat-form-field>
                  <mat-label>Address</mat-label>
                  <input matInput placeholder="Adress" formControlName="ownerAddressCtrl">
                  <mat-error>
                    <span *ngIf="ownersFormGroup.get('ownerAddressCtrl')?.value && ownersFormGroup.get('ownerAddressCtrl')?.invalid && ownersFormGroup.get('ownerAddressCtrl')?.errors?.['pattern']">Address must contain only numbers or letters</span>
                  </mat-error>
              </mat-form-field>
              <mat-form-field>
                  <mat-label>Address 2</mat-label>
                  <input matInput placeholder="Mobile" formControlName="ownerAddress2Ctrl">
                  <mat-error>
                    <span *ngIf="ownersFormGroup.get('ownerAddress2Ctrl')?.value && ownersFormGroup.get('ownerAddress2Ctrl')?.invalid && ownersFormGroup.get('ownerAddress2Ctrl')?.errors?.['pattern']">Address must contain only numbers or letters</span>
                  </mat-error>
              </mat-form-field>
          </div>
          <div class="box">
              <mat-form-field>
                  <mat-label>Telephone</mat-label>
                  <input matInput placeholder="Telephone" formControlName="ownerTelephoneCtrl">
                  <mat-error>
                    <span *ngIf="ownersFormGroup.get('ownerTelephoneCtrl')?.value && ownersFormGroup.get('ownerTelephoneCtrl')?.invalid && ownersFormGroup.get('ownerTelephoneCtrl')?.errors?.['pattern']">Telephone must contain only numbers </span>
                  </mat-error>
              </mat-form-field>
              <mat-form-field>
                  <mat-label>Mobile</mat-label>
                  <input matInput placeholder="Mobile" formControlName="ownerMobileCtrl">
                  <mat-error>
                    <span *ngIf="ownersFormGroup.get('ownerMobileCtrl')?.value && ownersFormGroup.get('ownerMobileCtrl')?.invalid && ownersFormGroup.get('ownerMobileCtrl')?.errors?.['pattern']">Telephone must contain only numbers </span>
                  </mat-error>
              </mat-form-field>
          </div>
          <div class="box">
              <mat-form-field>
                  <mat-label>Ownership Type</mat-label>
                  <mat-select formControlName="ownerShypTypeCtrl"  (selectionChange)="onOwnershypTypeChange($event.value)">                   
                    <mat-option *ngFor="let ownershipType of ownershipTypes" [value]="ownershipType.id">
                      {{ ownershipType.description }}
                    </mat-option>
                  </mat-select>   
                  <mat-error>
                    <span *ngIf="!ownersFormGroup.get('ownerShypTypeCtrl')?.value">Field required</span>
                  </mat-error>               
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Locality</mat-label>
                  <mat-select formControlName="ownerLocalityCtrl"  (selectionChange)="onWnerLocalityChange($event.value)">                   
                    <mat-option *ngFor="let locality of localities" [value]="locality.id">
                      {{ locality.description }}
                    </mat-option>
                  </mat-select>      
                  <mat-error>
                    <span *ngIf="!ownersFormGroup.get('ownerLocalityCtrl')?.value">Field required</span>
                  </mat-error>            
                </mat-form-field>
          </div>
          <div class="box">                           
              <mat-form-field>
                <mat-label>Date From</mat-label>
                <input matInput readonly  [matDatepicker]="pickerDateFrom" formControlName="dateFromCtrl"  maxlength="10"/>
                <mat-datepicker-toggle matIconSuffix [for]="pickerDateFrom">
                </mat-datepicker-toggle>
                <mat-datepicker #pickerDateFrom></mat-datepicker>    
                <!-- <mat-error>
                  <span *ngIf="!ownersFormGroup.get('dateFromCtrl')?.value">Field required</span>
                </mat-error> -->
              </mat-form-field>
            
              <mat-form-field>
                <mat-label>Date To</mat-label>
                <input matInput readonly  [matDatepicker]="pickerDateTo" formControlName="dateToCtrl"  maxlength="10"/>
                <mat-datepicker-toggle matIconSuffix [for]="pickerDateTo">
                </mat-datepicker-toggle>
                <mat-datepicker #pickerDateTo></mat-datepicker>    
                <!-- <mat-error>
                  <span *ngIf="!ownersFormGroup.get('dateToCtrl')?.value">Field required</span>
                </mat-error>                                     -->
              </mat-form-field>
          </div>   
          <div *ngIf="ownersFormGroup.hasError('dateRangeInvalid')" style="color: red;">
            End date cannot be before the start date.
          </div>
          <!-- <div class="divspace"> -->
          <!-- <button mat-raised-button class="right_button" color="primary" (click)="clearOwner()" >Clear </button></div>  -->
          <div  class="box main_button"><button mat-raised-button color="primary"(click)="clearOwner()"><mat-icon>backspace</mat-icon>Clear</button></div>
          <mat-divider></mat-divider>
          <div class="box">
              <mat-form-field>
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)"placeholder="Ex. Grave Reference Number" #input/>
              </mat-form-field>
            </div>
            <div class="table-table-container" id="tablecontent">
              <mat-label><b>Owners:</b></mat-label>
              <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>
                
                <!-- ID Column -->
                <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef >Id</th>
                  <td mat-cell *matCellDef="let owner">{{ owner.id }}</td>
                </ng-container>
  
                <!-- idCardNumber ref no Column -->
                <ng-container matColumnDef="idCardNumber">
                  <th mat-header-cell *matHeaderCellDef > Id Card </th>
                  <td mat-cell *matCellDef="let owner">{{ owner.idCardNumber }} </td>
                </ng-container>
                <!-- refNo type Column -->
                <!--<ng-container matColumnDef="refNo">
                  <th mat-header-cell *matHeaderCellDef  fxShow="true" fxHide.lt-md>Ref No</th>
                  <td mat-cell *matCellDef="let owner" fxShow="true" fxHide.lt-md>{{ owner.refNo }}</td>
                </ng-container>-->
  
                <!-- dhFile type Column -->
                <ng-container matColumnDef="dhFile">
                  <th mat-header-cell *matHeaderCellDef  fxShow="true" fxHide.lt-md>Dh File No</th>
                  <td mat-cell *matCellDef="let owner" fxShow="true" fxHide.lt-md>{{ owner.dhFile }}</td>
                </ng-container>
  
                <!-- Owner Name -->
                <ng-container matColumnDef="ownerName">
                  <th mat-header-cell *matHeaderCellDef  fxShow="true" fxHide.lt-md> Name </th>
                  <td mat-cell *matCellDef="let owner" fxShow="true" fxHide.lt-md>{{ owner.ownerName }}</td>
                </ng-container>
  
                <!-- Owner Surname -->
                <ng-container matColumnDef="ownerSurname">
                  <th mat-header-cell *matHeaderCellDef  fxShow="true" fxHide.lt-md> Surname</th>
                  <td mat-cell *matCellDef="let owner" fxShow="true" fxHide.lt-md>{{ owner.ownerSurname }} </td>
                </ng-container> 
                <!-- Date From date -->
                <ng-container matColumnDef="dateFrom">
                  <th mat-header-cell *matHeaderCellDef  fxShow="true" fxHide.lt-md> Date From</th>
                  <td mat-cell *matCellDef="let owner" fxShow="true" fxHide.lt-md>{{ owner.dateFrom | date: 'dd/MM/yyyy' }} </td>
                </ng-container>  
                <!-- Date To date -->
                <ng-container matColumnDef="dateTo">
                  <th mat-header-cell *matHeaderCellDef  fxShow="true" fxHide.lt-md> Date To</th>
                  <td mat-cell *matCellDef="let owner" fxShow="true" fxHide.lt-md>{{ owner.dateTo | date: 'dd/MM/yyyy'}} </td>
                </ng-container>   
  
                <ng-container matColumnDef="expand">
                  <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                  <td mat-cell *matCellDef="let grave">
                    <button mat-icon-button aria-label="expand row"  (click)="expandCollapse(grave); $event.stopPropagation()" >
                      <ng-container *ngIf="expandedElement === grave">
                          <mat-icon>keyboard_arrow_up</mat-icon>
                      </ng-container>
                      <ng-container *ngIf="expandedElement !== grave">
                          <mat-icon>keyboard_arrow_down</mat-icon>
                      </ng-container>
                  </button>
                  </td>
                </ng-container>
  
                  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail">
                  <td mat-cell *matCellDef="let grave" [attr.colspan]="displayedColumnsWithExpand.length">
                    <div class="expand-detail" [@detailExpand] = "grave == expandedElement ? 'expanded' : 'collapsed' ">
                      <div class="expand-category">
                        <div class="expand-field">
                        Address1 : &nbsp;<span>{{grave.address1}}</span>
                        Address2 : &nbsp;<span>{{grave.address2}}</span>
                        Telephone : &nbsp;<span>{{grave.telephone1}}</span>
                        Mobile : &nbsp;<span>{{grave.telephone2}}</span>
                       <!--  Locality:&nbsp;<span>{{getLocalityDescription(grave.localityId)}}</span>
                        Ownership Type:&nbsp;<span>{{getOwnershipTypeDescription(grave.ownershipTypeId)}}</span>--></div>
                      </div>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef>Action</th>
                  <ng-container matColumnDef="select">
                      <th mat-header-cell *matHeaderCellDef>Select</th>
                      <td mat-cell *matCellDef="let owner">
                        <mat-checkbox  class="example-margin"(change)="onCheckboxChange(owner)" [checked]="isSelected(owner)">
                        </mat-checkbox>
                        <button mat-icon-button color="warn" (click)="deleteOwner(owner.id)">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </td>
                    </ng-container>
                  
                </ng-container>
  
                <tr mat-header-row *matHeaderRowDef="displayedColumnsWithExpand"></tr>
                <tr mat-row *matRowDef="let grave; columns: displayedColumnsWithExpand;" class="expand-Inside"
                  [class.example-expanded-row]="expandedElement != grave" ></tr>
                  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" matRipple class="expand-Rowdetails"></tr>        
  
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No data matching the filter "{{ input.value }}"</td>
                </tr>
              </table>
            </div>                          
            <mat-paginator class="paginator" [pageSizeOptions]="[25, 100,200]" [length]="resultsLength" aria-label="Select page of burial permits"></mat-paginator>
      </mat-dialog-content>
      <div class="buttonalign">
          <button mat-raised-button color="primary" (click)="addOwner(mainOwner)"  ><mat-icon>add</mat-icon>Add </button>
          <button mat-raised-button color="primary" (click)="ownerUpdateClick(mainOwner)" ><mat-icon>update</mat-icon> Update </button>
      </div>                  
  </form>