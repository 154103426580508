import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { error } from 'pdf-lib';
import { IAddGraveDto } from 'src/app/models/AddGraveDto.model';
import { ICemeteryDto } from 'src/app/models/CemeteryDto.model';
import { ICemeteryTypeDto } from 'src/app/models/CemeteryTypeDto.model';
import { ICompartmentDto } from 'src/app/models/CompartmentDto.model';
import { IDivisionDto } from 'src/app/models/DivisionDto.model';
import { IGraveDto } from 'src/app/models/GraveDto.model';
import { IGraveSizeDto } from 'src/app/models/GraveSizeDto.model';
import { IResponseModal } from 'src/app/models/ResponseModal.model';
import { ISectionDto } from 'src/app/models/SectionDto.model';
import { CemeteryService } from 'src/app/services/cemetery.service';
import { CompartmentService } from 'src/app/services/compartment.service';
import { DivisionService } from 'src/app/services/division.service';
import { GraveService } from 'src/app/services/grave.service';
import { SectionService } from 'src/app/services/section.service';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ResponsemodalComponent } from 'src/app/responsemodal/responsemodal.component';

@Component({
  selector: 'app-grave-new',
  templateUrl: './grave-new.component.html',
  styleUrls: ['./grave-new.component.css']
})
export class GraveNewComponent implements OnInit {
  newGraveFormGroup!: FormGroup;
  cemeteryTypes! : ICemeteryTypeDto[];
  cemeteryType! : ICemeteryTypeDto;
  graveSizes! : IGraveSizeDto[];

  
 
  //variables to hold the initial value of each service
  originalCemeteries!: ICemeteryDto[];
  originalDivisions!: IDivisionDto[];
  originalSections! : ISectionDto[];
  originalCompartments! : ICompartmentDto[];
  //variables to display the data after filtering
  cemeteries! : ICemeteryDto[];
  divisions! : IDivisionDto[];
  sections!: ISectionDto[];
  compartments!: ICompartmentDto[];  
  newGrave : IAddGraveDto;
  pageSize = 50; 
  pageIndex = 1; 
  
 constructor(
  public dialogRef: MatDialogRef<GraveNewComponent>,
   @Inject(MAT_DIALOG_DATA) public data: IGraveDto,
   public dialog: MatDialog,
   private cemeteryService : CemeteryService,
   private divisionService : DivisionService,
   private sectionService : SectionService,
   private compartmentService : CompartmentService,
   private _formBuilder: FormBuilder,
   private graveService : GraveService
   ){
    
    this.cemeteryType ={
      id : 0,
      description : ''

    }     
    
    this.newGrave = {      
      referenceNumber: '',
      dhFileNo:'',
      isPrivate : false, 
      cemeteryId: 0,
      divisionId: 0,
      sectionId:0,      
      compartmentId : 0,
      graveNo:'',        
      graveSizeId:0,
      remarks : '',
      graveTypeId : 1     
  }
   }

  ngOnInit(): void {
    this.getCemeteryTypes();
    this.getCemeteries();
    this.getDivisions();
    this.getSections();
    this.getCompartments();
    this.getGraveSizes();

    this.newGraveFormGroup = this._formBuilder.group({  
      cemeteryTypeCtrl :[this.cemeteryType ? this.cemeteryType.id: '', [Validators.required, Validators.min(1)]],
      cemeteryCtrl : [this.newGrave ? this.newGrave.cemeteryId:'', [Validators.required, Validators.min(1)]],
      divisionCtrl: [{value:this.newGrave ? this.newGrave.divisionId: '',disabled: true } ,[Validators.required, Validators.min(1)]],
      sectionCtrl: [{values:this.newGrave ? this.newGrave.sectionId:'', disabled: true } ,[Validators.required, Validators.min(1)]],
      compartmentCtrl: [{value:this.newGrave ? this.newGrave.compartmentId: '',disabled: true } ,[Validators.required, Validators.min(1)]],
      graveSizeCtrl:[{value:this.newGrave ? this.newGrave.graveSizeId: '',disabled: false } ,[Validators.required, Validators.min(1)]],
      DHFileCtrl : [{value:this.newGrave ? this.newGrave.dhFileNo: '', disabled: false },[Validators.pattern(/^[a-zA-Z0-9,().'`" \/\\-]*$/)]],
      graveReferenceCtrl : [{value:this.newGrave ? this.newGrave.referenceNumber: '',disabled: false } ,[Validators.pattern('[0-9]*')]],
      graveNoCtrl: [{value:this.newGrave ? this.newGrave.graveNo:  '',disabled: false } ,[Validators.pattern('[0-9]*')]],
      privateGraveCtrl: [{value:this.newGrave ? this.newGrave.isPrivate: '',disabled:false}],
      remarksCtrl: [{value:this.newGrave ? this.newGrave.remarks: '',disabled:false}],
        
  });
  this.subscribeChanges();
  }
  subscribeChanges() {
    this.newGraveFormGroup.get('cemeteryTypeCtrl')?.valueChanges.subscribe((value) => {       
      this.cemeteryType.id = value;
    });
    this.newGraveFormGroup.get('cemeteryCtrl')?.valueChanges.subscribe((value) => {            
      this.newGrave.cemeteryId = value;
    });
    this.newGraveFormGroup.get('divisionCtrl')?.valueChanges.subscribe((value) => {            
      this.newGrave.divisionId = value;
    });
    this.newGraveFormGroup.get('sectionCtrl')?.valueChanges.subscribe((value) => {            
      this.newGrave.sectionId = value;
    });
    this.newGraveFormGroup.get('compartmentCtrl')?.valueChanges.subscribe((value) => {            
      this.newGrave.compartmentId = value;
    });

    this.newGraveFormGroup.get('graveSizeCtrl')?.valueChanges.subscribe((value) => {            
      this.newGrave.graveSizeId = value;
    });
    this.newGraveFormGroup.get('DHFileCtrl')?.valueChanges.subscribe((value) => {            
      this.newGrave.dhFileNo = value;
    });
    this.newGraveFormGroup.get('graveReferenceCtrl')?.valueChanges.subscribe((value) => {            
      this.newGrave.referenceNumber = value;
    });
    this.newGraveFormGroup.get('graveNoCtrl')?.valueChanges.subscribe((value) => {            
      this.newGrave.graveNo = value;
    });
    this.newGraveFormGroup.get('privateGraveCtrl')?.valueChanges.subscribe((value) => {            
      this.newGrave.isPrivate = value;
      this.newGrave.graveTypeId = value;
    });
    this.newGraveFormGroup.get('remarksCtrl')?.valueChanges.subscribe((value) => {            
      this.newGrave.remarks = value;
    });    
    
  }   

   getCemeteryTypes(){
    this.cemeteryService.getAllCemeteryType().subscribe(
      (response : ICemeteryTypeDto[]) => {
      this.cemeteryTypes = response;
    },
    (error) => {
      console.error('error retrieving cemetery types:', error)
    })
   }
   getCemeteries(){
    this.cemeteryService.getAllCemeteries().subscribe( 
      (response : ICemeteryDto[])=>{
        this.originalCemeteries = response;
        this.cemeteries = response;
      }, (error) =>{
        console.error('error retieving cemeteries service:', error);
      }) 
   }
   getDivisions(){
    this.divisionService.getAllDivisions().subscribe(
      (response: IDivisionDto[])=>{
        this.divisions = response;
        this.originalDivisions = response;

      },(error) =>{
        console.error('error retieving divisions service:', error);
      })
   }
   getSections(){
    this.sectionService.getAllSections().subscribe(
      (response : ISectionDto[] )=>{
        this.sections = response;
        this.originalSections = response;

      },(error) =>{
        console.error('error retieving sections service:', error);
      })
   }
   getCompartments(){
    this.compartmentService.getAllCompartments().subscribe(
      (response :ICompartmentDto[]) => {
        this.originalCompartments = response;
        this.compartments = response;

      }, (error) =>{
        console.error('error retieving compartments service:', error);
      })
   }
   getGraveSizes(){
    this.graveService.getGravesSizes().subscribe(
      (response : IGraveSizeDto[]) =>{        
        this.graveSizes = response;        
      },(error) =>{
        console.error('error retieving Grave sizes service:', error);
      })
   }
   getdivisionsByCemeteryId(cemeteryId : number){
    this.divisionService.getDivisionsByCementeryId(cemeteryId).subscribe(
      (response :IDivisionDto[]) => {
        this.divisions = response;       
      }, (error) => {
        console.error('Something went wrong getDivisionsByCementeryId :', error)

      })
  }

   onCemeteryTypeChange(){    
    const selectedCemeteryType = this.cemeteryTypes.find(cem => cem.id == this.cemeteryType.id);    
    if(selectedCemeteryType && selectedCemeteryType.id != 0){  
      this.cemeteries = this.originalCemeteries;   
      this.cemeteries = this.cemeteries.filter(cem => cem.cemeteryTypeId === selectedCemeteryType.id);      
    }else{
      this.cemeteries = this.originalCemeteries;
    }
   }

   onCemeteryChange(){ 
    const selectedCemetery = this.cemeteries.find(cem => cem.code == this.newGrave.cemeteryId);  
    
    if(selectedCemetery && selectedCemetery.code != 0){ 
        this.newGraveFormGroup.get('divisionCtrl')?.enable();
        this.newGraveFormGroup.get('sectionCtrl')?.disable();
        this.newGraveFormGroup.get('compartmentCtrl')?.disable();        
        this.getdivisionsByCemeteryId(selectedCemetery.code);
        
        
    }else{
      this.newGraveFormGroup.get('divisionCtrl')?.disable();
      this.newGraveFormGroup.get('sectionCtrl')?.disable();
      this.newGraveFormGroup.get('compartmentCtrl')?.disable();
      this.divisions = this.originalDivisions;
    }
  }
  onDivisionChange() {         
    const selectedDivision = this.divisions.find(div => div.id === this.newGrave.divisionId);    
    if (selectedDivision  && selectedDivision.id != 0) {
      this.newGraveFormGroup.get('sectionCtrl')?.enable();
      this.newGraveFormGroup.get('compartmentCtrl')?.disable();
      
      this.getSectionsByCemeteryIdAndDivisionId(selectedDivision.id,selectedDivision.cemeteryId);
    } else {
      this.newGraveFormGroup.get('sectionCtrl')?.disable();
      this.newGraveFormGroup.get('compartmentCtrl')?.disable();
      this.sections = this.originalSections;
    }
  }
  onSectionChange(){
    const selectedSection = this.sections.find(sec => sec.id === this.newGrave.sectionId);    
    if (selectedSection  && selectedSection.id != 0) {
      this.newGraveFormGroup.get('compartmentCtrl')?.enable();
      
      this.getCompartmentsByCemeteryIdAndDivisionIdAndSection(selectedSection.cemeteryId,selectedSection.divisionId,selectedSection.id);
    } else {
      this.newGraveFormGroup.get('compartmentCtrl')?.disable();
      this.sections = this.originalSections;
    }
    
  }
  graveNewClick(): void {
    if (this.newGraveFormGroup.invalid) {
      this.newGraveFormGroup.markAllAsTouched(); // Show all errors
      return;
    }
    this.graveService.addAGrave(this.newGrave).subscribe((response : IResponseModal) =>{
      this.openResponseModal(response);
    },(error) =>{
      console.error('Error pre saving burial permit:', error);
    })
    this.dialogRef.close();
  }
  
  openResponseModal(response : IResponseModal): void {
    const dialogRef = this.dialog.open(ResponsemodalComponent, {
      width: '250px',
      data : response,
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  getSectionsByCemeteryIdAndDivisionId( divisionId : number, cemeteryId : number){
    this.sectionService.getSectionByDivisionIdAndCemeteryId(divisionId,cemeteryId).subscribe(
      (response :ISectionDto[]) => {
        this.sections = response;
      }, (error) => {
        console.error('Something went wrong getSectionsByCemeteryIdAndDivisionId :', error)

      })
  }
  getCompartmentsByCemeteryIdAndDivisionIdAndSection( cemeteryId : number,divisionId : number,sectionId : number ){
    this.compartmentService.getCompartmentByCemeteryIdAndDivisionIdSectionId(cemeteryId,divisionId,sectionId).subscribe(
      (response :ICompartmentDto[]) => {
        this.compartments = response;
      }, (error) => {
        console.error('Something went wrong compartments :', error)

      })
  }
  
}
