<div class="box">

<mat-form-field>
    <mat-label>Ref No</mat-label>
    <input matInput readonly [value]="lastBurial?.refNo"/>
</mat-form-field>  

<mat-form-field>
    <mat-label>Permit No</mat-label>
    <input matInput readonly [value]="lastBurial?.permitNumber"/>
</mat-form-field>  

</div>

<div class="box">

<mat-form-field>
    <mat-label>Name</mat-label>
    <input matInput readonly [value]="lastBurial?.deceasedName"/>  
</mat-form-field>

<mat-form-field>
    <mat-label>Surname</mat-label>
    <input matInput readonly [value]="lastBurial?.deceasedSurName"/>
</mat-form-field>

<mat-form-field>
    <mat-label>Gender</mat-label>
    <input matInput readonly [value]="selectedGenderDescription"/>  
</mat-form-field>

<mat-form-field>
    <mat-label>Place of Birth</mat-label>
    <input matInput readonly [value]="lastBurial?.deceased?.placeOfBirth"/>
</mat-form-field>

</div>

<div class="box">

<mat-form-field>
    <mat-label>Id No</mat-label>
    <input matInput readonly [value]="lastBurial?.deceasedIdCardNumber"/>  
</mat-form-field>

<mat-form-field>
    <mat-label>Profession</mat-label>
    <input matInput readonly [value]="lastBurial?.deceased?.profession"/>
</mat-form-field>

<mat-form-field>
    <mat-label>Marital Status</mat-label>
    <input matInput readonly [value]="selectedMaritalStatusDescription"/>  
</mat-form-field>

<mat-form-field>
    <mat-label>Husband/Wife</mat-label>
    <input matInput readonly [value]="(lastBurial?.deceased?.spouseName ?? '') + ' ' + (lastBurial?.deceased?.spouseSurname ?? '')"/>
</mat-form-field>

</div>

<div class="box">

<mat-form-field>
    <mat-label>Father's Name</mat-label>
    <input matInput readonly [value]="lastBurial?.deceased?.fatherName"/>  
</mat-form-field>

<mat-form-field>
    <mat-label>Father's Surname</mat-label>
    <input matInput readonly [value]="lastBurial?.deceased?.fatherSurname"/>
</mat-form-field>

<mat-form-field>
    <mat-label>Mother's Name</mat-label>
    <input matInput readonly [value]="lastBurial?.deceased?.motherName"/>  
</mat-form-field>

<mat-form-field>
    <mat-label>Mother's Surname</mat-label>
    <input matInput readonly [value]="lastBurial?.deceased?.motherSurname"/>
</mat-form-field>

</div>

<div class="box">

<mat-form-field>
    <mat-label>Death Date</mat-label>
    <input matInput readonly [value]="formatDate(lastBurial?.deathDate)"/>
</mat-form-field>

<mat-form-field>
    <mat-label>Burial Date</mat-label>
    <input matInput readonly [value]="formatDate(lastBurial?.burialDate)"/>
</mat-form-field>

</div>

<div class="box">

<mat-form-field>
    <mat-label>Place of Death</mat-label>
    <input matInput readonly [value]="lastBurial?.placeOfDeath"/>  
</mat-form-field>

<mat-form-field>
    <mat-label>Death Time</mat-label>
    <input matInput readonly [value]="lastBurial?.timeOfDeath"/>
</mat-form-field>

<mat-form-field>
    <mat-label>Cause of Death</mat-label>
    <input matInput readonly [value]="lastBurial?.causeOfDeath"/>
</mat-form-field>

</div>

<div class="box">

<mat-form-field>
    <mat-label>Mass Locality</mat-label>
    <input matInput readonly [value]="selectedMassLocalityDescription"/>  
</mat-form-field>

<mat-form-field>
    <mat-label>Mass Time</mat-label>
    <input matInput readonly [value]="lastBurial?.massTime"/>
</mat-form-field>

<mat-form-field>
    <mat-label>Burial Rights</mat-label>
    <input matInput readonly [value]="lastBurial?.burialRight"/>
</mat-form-field>

</div>

<div class="box">

<mat-form-field>
    <mat-label>Undertaker</mat-label>
    <input matInput readonly [value]="selectedUndertakerDescription"/>  
</mat-form-field>

<!-- <mat-form-field>
    <mat-label>Supervisor</mat-label>
    <input matInput readonly [value]="lastBurial?."/>
</mat-form-field>

<mat-form-field>
    <mat-label>Charge Net</mat-label>
    <input matInput readonly [value]="lastBurial?"/>
</mat-form-field>

<mat-form-field>
    <mat-label>VAT</mat-label>
    <input matInput readonly [value]="lastBurial?"/>
</mat-form-field>

<mat-form-field>
    <mat-label>Gross</mat-label>
    <input matInput readonly [value]="lastBurial?"/>
</mat-form-field> -->

</div>
