import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IAddNewUndertakerDto } from 'src/app/models/AddNewUndertakerDto.model';
import { ILocalityDto } from 'src/app/models/LocalityDto.model';
import { IResponseModal } from 'src/app/models/ResponseModal.model';
import { IUndertakerDto } from 'src/app/models/UndertakerDto.model';
import { ResponsemodalComponent } from 'src/app/responsemodal/responsemodal.component';
import { LocalityService } from 'src/app/services/locality.service';
import { UndertakerService } from 'src/app/services/undertaker.service';


@Component({
  selector: 'app-undertaker-new',
  templateUrl: './undertaker-new.component.html',
  styleUrls: ['./undertaker-new.component.css']
})
export class UndertakerNewComponent implements OnInit {
  UndertakerNewForm!: FormGroup;
  localities: ILocalityDto[]=[];
  
  undertakerObject : IAddNewUndertakerDto = {
    id: 0,
    code : 0,
    name : '',
    surname : '',
    telephone :'',
    address1: '',
    address2 :'',
    localityid :0,
    vatno : '',
 }
  constructor(
    private _formBuilder: FormBuilder,
    private localityService :LocalityService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<UndertakerNewComponent>,
    private undertakerService: UndertakerService,
    @Inject(MAT_DIALOG_DATA) public data:any
   ){}

   ngOnInit():void {
    
    this.getAllLocalities();

    this.UndertakerNewForm = this._formBuilder.group({
      codeCtrl:[ this.undertakerObject ? this.undertakerObject.code: '',[Validators.pattern('[0-9]*')]],
      vatNoCtrl: [this.undertakerObject ? this.undertakerObject.vatno: '',[Validators.pattern('[0-9]*')]],
      nameCtrl: [this.undertakerObject ? this.undertakerObject.name: '', [Validators.required, Validators.pattern(/^[a-zA-Z,().'`" &\/\\-]*$/)]], 
      surnameCtrl: [this.undertakerObject ? this.undertakerObject.surname: '', [Validators.required, Validators.pattern(/^[a-zA-Z,().'`" &\/\\-]*$/)]], 
      localityCtrl: [this.undertakerObject ? this.undertakerObject.localityid: '', [Validators.required, Validators.min(1)]],
      address1Ctrl:[this.undertakerObject ? this.undertakerObject.address1: '', [Validators.pattern(/^[a-zA-Z0-9,().'`" \/\\-]*$/)]],
      address2Ctrl:[this.undertakerObject ? this.undertakerObject.address2: '', [Validators.pattern(/^[a-zA-Z0-9,().'`" \/\\-]*$/)]],
      telephoneCtrl : [this.undertakerObject ? this.undertakerObject.telephone: '',[Validators.pattern('[0-9]*')]],
  });
  this.subscribeChanges();
  }
  subscribeChanges() {
    this.UndertakerNewForm.get('codeCtrl')?.valueChanges.subscribe((value) => {     
      this.undertakerObject.code= value;
    });
    this.UndertakerNewForm.get('vatNoCtrl')?.valueChanges.subscribe((value) => {     
      this.undertakerObject.vatno= value;
    });
    this.UndertakerNewForm.get('nameCtrl')?.valueChanges.subscribe((value) => {     
      this.undertakerObject.name= value;
    });
    this.UndertakerNewForm.get('surnameCtrl')?.valueChanges.subscribe((value) => {     
      this.undertakerObject.surname= value;
    });
    this.UndertakerNewForm.get('localityCtrl')?.valueChanges.subscribe((value) => {     
      this.undertakerObject.localityid= value;
    });
    this.UndertakerNewForm.get('address1Ctrl')?.valueChanges.subscribe((value) => {     
      this.undertakerObject.address1 = value;
    });
    this.UndertakerNewForm.get('address2Ctrl')?.valueChanges.subscribe((value) => {     
      this.undertakerObject.address2= value;
    });
    this.UndertakerNewForm.get('telephoneCtrl')?.valueChanges.subscribe((value) => {     
      this.undertakerObject.telephone = value;
    });
  
  }

  getAllLocalities(){
    this.localityService.getAllLocalities().subscribe(
      (response: ILocalityDto[]) => {
        this.localities = response;  
      },
      (error) => {        
        console.error('Error adding localities: ', error);        
      }
      );
  }
  openResponseModal(response : IResponseModal): void {
    const dialogRef = this.dialog.open(ResponsemodalComponent, {
      width: '250px',
      data : response,
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  addNewUndertaker(){
    if (this.UndertakerNewForm.invalid) {
      this.UndertakerNewForm.markAllAsTouched(); // Show all errors
      return;
    }
    const addNewUndertakerDto:IAddNewUndertakerDto= this.undertakerObject;   
    this.undertakerService.addNewUndertaker( addNewUndertakerDto).subscribe(
      (response: IResponseModal) => {       
        this.openResponseModal(response); 
        this.dialogRef.close();  
      },
      (error) => {            
        console.error('Error adding new undertaker:', error);       
      }
      );
    }
  }
   

