


<h1 mat-dialog-title>Edit User</h1>
<div mat-dialog-content>
  <form [formGroup]="editUserForm" class="form-row">
    <div fxLayout="row" fxLayoutGap="20px" class="box">
      <mat-form-field appearance="fill" fxFlex>
        <mat-label>User ID</mat-label>
        <input matInput formControlName="userID" readonly>
      </mat-form-field>

      <mat-form-field appearance="fill" fxFlex>
        <mat-label>Username</mat-label>
        <input matInput formControlName="username" readonly>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="20px" class="box">
      <mat-form-field appearance="fill" fxFlex>
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" readonly>
      </mat-form-field>

      <mat-form-field appearance="fill" fxFlex>
        <mat-label>Surname</mat-label>
        <input matInput formControlName="surname" readonly>
      </mat-form-field>

      <mat-form-field appearance="fill" fxFlex>
        <mat-label>ID Number</mat-label>
        <input matInput formControlName="idNo" readonly>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="20px" class="box">
      <mat-form-field appearance="fill" fxFlex>
        <mat-label>Email</mat-label>
        <input matInput type="email" formControlName="email" readonly>
      </mat-form-field>

      <mat-form-field appearance="fill" fxFlex>
        <mat-label>Role</mat-label>
        <mat-select formControlName="roleID" required>
          <mat-option *ngFor="let role of roles" [value]="role.id">{{ role.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="20px" class="box">
      <mat-form-field appearance="fill" fxFlex>
        <mat-label>Active Status</mat-label>
        <mat-select formControlName="isActive" required>
          <mat-option [value]="1">Yes</mat-option>
          <mat-option [value]="2">No</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" fxFlex>
        <mat-label>Created On</mat-label>
        <input matInput formControlName="createdOn" readonly>
      </mat-form-field>
    </div>
  </form>
  <div mat-dialog-actions>
    <button mat-raised-button style="color: dimgray" [mat-dialog-close] cdkFocusInitial>Cancel</button>
    <button mat-raised-button color="primary" class="save-button" [disabled]="editUserForm.invalid" (click)="onSave()">Save Changes</button>
  </div>
</div>

