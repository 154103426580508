import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IBurialPermitDto } from 'src/app/burials/models/BurialPermitDto.model';
import { ITableCountDto } from 'src/app/burials/models/ITableCountDto';
import { IAddTransportDto } from 'src/app/models/AddTransportDto.model';
import { ICemeteryDto } from 'src/app/models/CemeteryDto.model';
import { ICleaningTransportStatusDto } from 'src/app/models/CleaningTransportStatusDto.model';
import { catchError, merge, of as observableOf, } from 'rxjs';
import { ICompartmentDto } from 'src/app/models/CompartmentDto.model';
import { IDivisionDto } from 'src/app/models/DivisionDto.model';
import { IEmployeDto } from 'src/app/models/EmployeDto.model';
import { IProviderDto } from 'src/app/models/ProviderDto.model';
import { IResponseModal } from 'src/app/models/ResponseModal.model';
import { ISectionDto } from 'src/app/models/SectionDto.model';
import { ISupervisorsDto } from 'src/app/models/SupervisorsDto.model';
import { ITransportDto } from 'src/app/models/TransportDto.model';
import { IUndertakerDto } from 'src/app/models/UndertakerDto.model';
import { IUpdateTransportDto } from 'src/app/models/UpdateTransportDto.model';
import { CleaningTransportStatus } from 'src/app/models/cleaning-status.enum';
import { ResponsemodalComponent } from 'src/app/responsemodal/responsemodal.component';
import { BurialPermitService } from 'src/app/services/burial-permit.service';
import { CemeteryService } from 'src/app/services/cemetery.service';
import { CommonService } from 'src/app/services/common.service';
import { CompartmentService } from 'src/app/services/compartment.service';
import { DivisionService } from 'src/app/services/division.service';
import { SectionService } from 'src/app/services/section.service';
import { TransportService } from 'src/app/services/transport.service';
import { UndertakerService } from 'src/app/services/undertaker.service';
import { Observable, map, of, startWith } from 'rxjs';
import { GraveService } from 'src/app/services/grave.service';
import { IGraveDto } from 'src/app/models/GraveDto.model';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ITransportTypeDto } from 'src/app/models/TransportTypeDto.model';
import { ICountryDto } from 'src/app/models/CountryDto.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { InternalCommunicationService } from 'src/app/services/internal-communication.service';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';  
import { ICleaningDto } from 'src/app/models/CleaningDto.model';
import { IGraveLevelsDto } from 'src/app/models/GraveLevelsDto.model';
import { BurialService } from 'src/app/services/burial.service';
import { IBurial } from 'src/app/models/Burial.model';

export const MY_FORMATS = {
parse: {
  dateInput: 'L',
},
display: {
  dateInput: 'L',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
},
};


@Component({
  selector: 'app-transport',
  templateUrl: './transport.component.html',
  styleUrls: ['./transport.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-GB'
    },
    {
      provide: DateAdapter, useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_FORMATS
    },
  ],
})
export class TransportComponent implements OnInit, AfterViewInit {
@Input() graveId! : number;
@Output() transportExistChange = new EventEmitter<boolean>();

transportExist = false;
currentTransport!: ITransportDto | undefined;
pageSize = 50; 
pageIndex = 0; 
options!: any[] ;
filteredOptions: Observable<string[]>= of([]);
filteredOptionsTo: Observable<string[]>= of([]);
transportPermitNumber! : string;
fromLevels! : IGraveLevelsDto[];
toLevels! : IGraveLevelsDto[];
cleaning! : ICleaningDto;
isLoadingResults = true;
isRateLimitReached = false;
transportTypes!: ITransportTypeDto[];
transportType : number = 0;
countries! : ICountryDto[];
isUpdateButtonDisabled = true;
isCarryOutDisable: boolean = true;
formDisabled: boolean = false;
transportFormGroup!: FormGroup;
supervisors! : ISupervisorsDto[];
burials! : IBurialPermitDto[];
burialPermitSeleted! : IBurialPermitDto;
fromBurial! : IBurialPermitDto | undefined;
transportStatuses! : ICleaningTransportStatusDto[];
transport: ITransportDto = {
  id: 0,
    transportId: 0,
    transportDate: new Date(),
    cleaningTransportStatusId: 0,
    reference: '',
    transportPermit: '',
    burialId: 0,
    fromCemeteryId: 0,
    fromDivisionId: 0,
    fromSectionId: 0,
    fromCompartmentId: 0,
    fromGraveId: 0,
    fromLevelId : 0,
    toCemeteryId: 0,
    toDivisionId: 0,
    toSectionId: 0,
    toCompartmentId: 0,
    toGraveId: 0,
    toGraveReference: '',
    toLevelId: 0,
    toDestination: '',
    burialDate: new Date(),
    removeAuth: '',
    removeAuthId: '',
    newAuth: '',
    newAuthId: '',
    supervisorId: 0,
    undertakerId: 0,
    providerId: 0,
    feePaid: false,
    address: '',    
    transportStatusDescription: '',
    supervisorName: '',
    supervisorSurname: '',   
    providerName: '',
   // transportRate: 0,
    feeId: 0,
    transportTypeId : 0,
    istransportExist : false,
};
providers! : IProviderDto[];
originalProviders! : IProviderDto[];
originalTransports! :ITransportDto[]; 
transports! :ITransportDto[];
currentsTransport! : ITransportDto[];
add! : ITransportDto;

//grave address
originalCemeteries! : ICemeteryDto[];
fromCemeteries! : ICemeteryDto[];
toCemeteries! : ICemeteryDto[];

fromDivisions ! : IDivisionDto[];
toDivisions ! : IDivisionDto[];
originalDivisions! : IDivisionDto[];

fromSections! : ISectionDto[];
toSections! : ISectionDto[];
originalSections! : ISectionDto[];

fromCompartments! : ICompartmentDto[];
toCompartments! : ICompartmentDto[];
originalCompartments! : ICompartmentDto[];
undertakers! : IUndertakerDto[];

fromGraves : IGraveDto[] = [];
toGraves : IGraveDto[] =[];
originalGraves : IGraveDto[] = [];

originalUndertakers! : IUndertakerDto[];
employees! : IEmployeDto[];
originalEmployees! : IEmployeDto[];




@ViewChild(MatSlideToggleModule) slidetoggle!: MatSlideToggleModule;
@ViewChild(MatSort) sort!: MatSort;


expandedElement: ITransportDto | undefined;
displayedColumns: string[] = [
  'id',
  'transportPermit', 
  'transportDate', 
  'burialId',
  'fromCemetery',
  'fromDivision',
  'fromSection',
  'fromCompartment',
  'fromGrave',
  'transportStatusDescription',   
  'expand',
  'action'];
displayedColumnsWithExpand = [...this.displayedColumns];
resultsLength = 0;
selectedTransport: ITransportDto | null = null;
constructor(
  private cdr: ChangeDetectorRef,
  @Inject(MAT_DIALOG_DATA) public data: ICleaningDto,
  public dialog: MatDialog,
  private _formBuilder: FormBuilder,
  private transportService : TransportService,
  private commonService : CommonService,
  private burialsService : BurialPermitService,
  private cemeteryService : CemeteryService,
  private divisionService : DivisionService,
  private sectionService : SectionService,
  private compartmentService : CompartmentService,
  private undertakersService : UndertakerService,
  private graveService : GraveService,
  private burialService : BurialService,
  private internalService : InternalCommunicationService,
  public dialogRef: MatDialogRef<TransportComponent>,
){
  
   

}
  closeModal() {    
    this.dialogRef.close(this.transportExist);
  }
  ngAfterViewInit(): void {
    this.getTransportPermitNumber(this.cleaning.transport?.transportPermit ?? '');
    
    if(this.cleaning.transport){
      this.onTransportTypeChange(this.cleaning.transport.transportTypeId);
      //this.setTransportFormGroupValues(this.cleaning.transport);
      //this.transportExist = true;   
      console.log('this.cleaning.transport.id :' + this.cleaning.transport.id)   
      //this.isTransportExist(this.cleaning.id);
    }    
    this.cdr.detectChanges();
  }
  
ngOnInit(): void {  

  this.cleaning = this.data;  
  if(this.cleaning.transport){
    this.transport = this.cleaning.transport;
  }

  this.isTransportExist(this.cleaning.id);

  this.getTransportTypes();
  this.getAllCountries();  
  this.getBurialByBurialId(this.cleaning.burialId);
  this.getCleaningStatus();
  this.getAllSupervisors();
  this.getAllUndertakers();  
  this.getAllCemeteries();
  this.getAllDivisions();
  this.getAllSections();
  this.getAllCompartments();
  this.getAllGraves();
  this.getAllLevels(this.cleaning.graveId);
 
  

    this.transportFormGroup = this._formBuilder.group({     
      transportPermitCtrl: [{value: this.cleaning.transport ? this.cleaning.transport.transportPermit : '',disabled : true}],
      transportDateCtrl: [this.transport ? this.transport.transportDate : new Date], 
      referenceCtrl: [this.transport ? this.transport.reference : ''],
      burialCtrl : [this.transport ? this.transport.burialId : 0], 
      fromCemeteryCtrl: [this.transport ? this.transport.fromCemeteryId : 0], 
      fromDivisionCtrl: [this.transport ? this.transport.fromDivisionId : 0], 
      fromSectionCtrl: [this.transport ? this.transport.fromSectionId : 0], 
      fromCompartmentCtrl: [this.transport ? this.transport.fromCompartmentId : 0], 
      fromLevelCtrl: [{value: this.transport ? this.transport.fromLevelId : 0, disabled : true}], 
      fromGraveCtrl: [this.transport ? this.transport.fromGraveId : 0], 
      toCemeteryCtrl: [this.transport ? this.transport.toCemeteryId : 0],
      toDivisionCtrl: [this.transport ? this.transport.toDivisionId : 0],
      toSectionCtrl: [this.transport ? this.transport.toSectionId : 0],
      toCompartmentCtrl: [this.transport ? this.transport.toCompartmentId : 0],
      toGraveCtrl: [this.transport ? this.transport.toGraveNo : 0],
      toGraveReferenceCtrl: [this.transport ? this.transport.toGraveReference : ''],
      toLevelCtrl: [this.transport ? this.transport.toLevelId : ''],
      toDestinationCtrl: [this.transport ? this.transport.toDestination : ''],
      burialDateCtrl: [this.transport ? this.transport.burialDate : new Date],
      removeAuthCtrl: [this.transport ? this.transport.removeAuth : ''],
      removeAuthIdCtrl: [this.transport ? this.transport.removeAuthId : ''],
      newAuthCtrl: [this.transport ? this.transport.newAuth : ''],
      newAuthIdCtrl: [this.transport ? this.transport.newAuthId : ''],

      supervisorCtrl: [this.transport ? this.transport.supervisorId : 0], 
      feePaidCtrl :  [this.transport ? this.transport.feePaid : false],   
      addressCtrl: [this.transport ? this.transport.address : ''],      
      transportStatusIdCtrl :  [this.transport ? this.transport.cleaningTransportStatusId : 0], 
      providerIdCtrl : [this.transport ? this.transport.providerId : 0], 
      undertakerCtrl : [this.transport ? this.transport.undertakerId : 0], 
      transportTypeCtrl : [this.transport ? this.transport.transportTypeId : 0], 
    });
    this.updateFilteredOptions('fromGraveCtrl');
    this.updateFilteredOptions('toGraveCtrl');
    this.subscribeChanges();
    
   this.disableCemeteryFromFilters();
   this.disableCemeteryToFilters();
  }

  subscribeChanges() {
    this.transportFormGroup.get('transportPermitCtrl')?.valueChanges.subscribe((value) => {          
      this.transport.transportPermit = value;        
    });
    this.transportFormGroup.get('transportDateCtrl')?.valueChanges.subscribe((value) => {          
      this.transport.transportDate = value;
    });
    this.transportFormGroup.get('referencePersonCtrl')?.valueChanges.subscribe((value) => {          
      this.transport.reference = value;
    });   
    this.transportFormGroup.get('toDestinationCtrl')?.valueChanges.subscribe((value) => {          
      this.transport.toDestination = value;
    });
    this.transportFormGroup.get('burialDateCtrl')?.valueChanges.subscribe((value) => {          
      this.transport.burialDate = value;
    });
    this.transportFormGroup.get('removeAuthCtrl')?.valueChanges.subscribe((value) => {          
      this.transport.removeAuth = value;
    });

    this.transportFormGroup.get('removeAuthIdCtrl')?.valueChanges.subscribe((value) => {          
      this.transport.removeAuthId = value;
    });
    this.transportFormGroup.get('newAuthCtrl')?.valueChanges.subscribe((value) => {          
      this.transport.newAuth = value;
    });    
    this.transportFormGroup.get('newAuthIdCtrl')?.valueChanges.subscribe((value) => {          
      this.transport.newAuthId = value;
    });
    
    this.transportFormGroup.get('feePaidCtrl')?.valueChanges.subscribe((value) => {          
      this.transport.feePaid = value;
    });    
    this.transportFormGroup.get('addressCtrl')?.valueChanges.subscribe((value) => {          
      this.transport.address = value;
    });  
    this.transportFormGroup.get('fromGraveCtrl')?.valueChanges.subscribe((value) => {          
      if (!value) {
        this.updateFilteredOptions('fromGraveCtrl');
      }
    });
    this.transportFormGroup.get('toGraveCtrl')?.valueChanges.subscribe((value) => {          
      if (!value) {
        this.updateFilteredOptions('toGraveCtrl');
      }
    });
  }

  dateFilterOnlyFuture = (d: Date | null): boolean => {
    if (!d) {
      return false; 
    }  
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return !(d < today);
  };

  disableCemeteryFromFilters(){
    this.transportFormGroup.get('fromDivisionCtrl')?.disable();
    this.transportFormGroup.get('fromSectionCtrl')?.disable();
    this.transportFormGroup.get('fromCompartmentCtrl')?.disable();
    this.transportFormGroup.get('fromGraveCtrl')?.disable();
  }
  disableCemeteryToFilters(){  
    
    this.transportFormGroup.get('toDivisionCtrl')?.disable();
    this.transportFormGroup.get('toSectionCtrl')?.disable();
    this.transportFormGroup.get('toCompartmentCtrl')?.disable();
    this.transportFormGroup.get('toGraveCtrl')?.disable();
  }



  deleteTransport(transport: ITransportDto) {
    this.transportService.deleteTransport(transport.id).subscribe((response: IResponseModal) => {
      this.openResponseModal(response);
      this.transportExist = false;
      if(this.cleaning.transport){
        this.cleaning.transport.istransportExist = false;

      }
     
      this.transportExistChange.emit(this.transportExist);
      
      this.dialogRef.close(this.transportExist);
    }, (error) => {
      console.error('Error deleting transport: ', error);
    });
  }
  
  triggerFromCemeteryFilterChanges(
    fromCmeteryId : number,
    fromDivisionId : number,
    fromSectionId : number, 
    fromCompartmentId : number){
    /*this.onCemeteryFromChange(fromCmeteryId);
    this.onDivisionFromChange(fromDivisionId);
    this.onSectionFromChange(fromSectionId);
    this.onCompartmentFromChange(fromCompartmentId);*/
  }

  triggerToCemeteryFilterChanges(
    toCmeteryId : number,
    toDivisionId : number,
    toSectionId : number, 
    toCompartmentId : number){
    this.onCemeteryToChange(toCmeteryId);
    this.onDivisionToChange(toDivisionId);
    this.onSectionToChange(toSectionId);
    this.onCompartmentToChange(toCompartmentId);
  }


  setTransportFormGroupValues(current: ITransportDto) {
    console.log(' setTransportFormGroupValues Current')
    console.log(current)
    const newValues = {
      transportPermitCtrl: current.transportPermit ?? '',
      transportDateCtrl: current.transportDate,
      referenceCtrl: current.reference,
      burialCtrl : current.burialId,
      
      fromCemeteryCtrl : current.fromCemeteryId,
      fromDivisionCtrl : current.fromDivisionId,
      fromSectionCtrl : current.fromSectionId,
      fromCompartmentCtrl : current.fromCompartmentId,
      fromGraveCtrl : current.fromGraveNo,
      fromLevelCtrl : current.fromLevelId,
      
      toCemeteryCtrl : current.toCemeteryId,
      toDivisionCtrl : current.toDivisionId,
      toSectionCtrl : current.toSectionId,
      toCompartmentCtrl : current.toCompartmentId,
      toGraveCtrl : current.toGraveNo,
      toLevelCtrl : current.toLevelId,
    
      toGraveReferenceCtrl : current.toGraveReference,
      
      toDestinationCtrl : current.toDestination,
      burialDateCtrl : current.burialDate,
      removeAuthCtrl : current.removeAuth,
      removeAuthIdCtrl : current.removeAuthId,
      newAuthCtrl : current.newAuth,
      newAuthIdCtrl : current.newAuthId,
      supervisorCtrl: current.supervisorId,
      feePaidCtrl: current.feePaid ,
      addressCtrl: current.address,
      transportStatusIdCtrl: current.cleaningTransportStatusId,   
      providerIdCtrl : current.providerId ?? 1,   
      undertakerCtrl : current.undertakerId,
      transportTypeCtrl : current.transportTypeId      
    };
    
    this.transportFormGroup.setValue(newValues, { emitEvent: false }); 
    
  } 
  toggleFormControls(disable: boolean) {
    this.formDisabled = disable;
    if (disable) {
      this.transportFormGroup.disable();

    } else {
      this.transportFormGroup.enable();
    }
  }
 //end table

 //resources
 getAllCemeteries(){
  this.cemeteryService.getAllCemeteries().subscribe(
    (response : ICemeteryDto[]) => {
      this.originalCemeteries = response;   
      this.fromCemeteries = response; 
      this.toCemeteries = response;   

    })
}

getAllDivisions(){
  
  this.divisionService.getAllDivisions().subscribe(
    (response : IDivisionDto[]) => {
      this.fromDivisions = response;     
      this.toDivisions = response;    
      this.originalDivisions = response;

    })
    
}
getAllSections(){
  this.sectionService.getAllSections().subscribe(
    (response : ISectionDto[]) => {
      this.originalSections = response;    
      this.fromSections = response;    
      this.toSections = response;     

    })
}
getAllCompartments(){
  this.compartmentService.getAllCompartments().subscribe(
    (response : ICompartmentDto[]) => {
      this.originalCompartments = response;  
      this.fromCompartments = response; 
      this.toCompartments = response;  
    })
}

getAllGraves(){
  this.graveService.getAllGraves(null,null,1,1000).subscribe(
    (response : ITableCountDto) => {
      this.fromGraves = response.results;  
      this.toGraves = response.results; 
      this.originalGraves = response.results;  
    },(error) =>{
      console.error('Error retrieving graves : ', error);
    })
}


getAllUndertakers(){
  this.undertakersService.getAllUndertakers().subscribe(
    (response : IUndertakerDto[]) => {
      this.undertakers = response;
    }, (error) =>{
      console.error('Error retrieving undertakers');
    } 
    );
}

getTransportTypes(){
  this.transportService.getAllTransportTypes().subscribe(
    (response : ITransportTypeDto[]) =>{
      this.transportTypes = response;

    }, (error) => {
      console.error('something wrong happens retrieving transport types: ', error)

    });
}

getAllCountries(){
  this.commonService.getAllCountries().subscribe(
    (response : ICountryDto[]) =>{
      this.countries = response;

    }, (error) => {
      console.error('something wrong happens retrieving countries : ', error)

    });
}
 
getAllTransport(graveId : number){
  if(graveId !== 0 && graveId !== null){    
    this.transportService.getAllTransport(null,null,1,100,graveId).subscribe(
    
      (response : ITableCountDto) => {
        this.originalTransports = response.results;        
        this.transports = response.results;        
        
        this.resultsLength = response.totalItems;  
      }, (error) =>{
  
      })

  }else{
    this.transportService.getAllTransport(null,null,1,100).subscribe(
    
      (response : ITableCountDto) => {
        this.originalTransports = response.results;
        this.transports = response.results;
                  
      }, (error) =>{
  
      })    
  }
  
 }
 getAllSupervisors(){
  this.commonService.getAllSupervisors(null,null,1,100).subscribe(
    (response : ITableCountDto) =>{
      this.supervisors = response.results;

    }, (error) =>{
      console.error('An error happens retrieving supervisors data')

    })
 }
 getAllEmployees(){
  this.commonService.getAllEmployees(null,null,1,100).subscribe(
    (response : ITableCountDto) =>{
      this.employees = response.results;
      this.originalEmployees = response.results;

    }, (error) =>{
      console.error('An error happens retrieving employees data')

    })
  }
  getAllProviders(){
    this.commonService.getAllProviders(null,null,1,100).subscribe(
      (response : ITableCountDto) =>{
        this.providers = response.results;
  
      }, (error) =>{
        console.error('An error happens retrieving providers data')
  
      })
    }

    getCleaningStatus(){
      this.transportService.getAllStatus(null,null,1,100).subscribe(
        (response : ITableCountDto) => {
          this.transportStatuses = response.results;       

        }, (error) => {

        })
    }

    //DROP DOWN
    providerChange(providerId : number){  
     const seelectedProvider = this.providers.find(x => x.id === providerId);
     this.transport.providerId = providerId;  
     this.transport.feeId = seelectedProvider?.feeId ?? 0; 
     this.employees = this.originalEmployees.filter(x => x.providerId === providerId);
    }

    onDestinationChange(countryId : number){
      const seelectedCountry = this.countries.find(x => x.id === countryId);
      this.transport.toDestination = seelectedCountry?.name;
    }
    supervisorChange(supervisorId : number){
      this.transport.supervisorId = supervisorId;
    }

    undertakersChange(undertakerId : number){
      this.transport.undertakerId = undertakerId;
    }

    selectedBurial(selectedBurial : IBurialPermitDto){
      console.log('burial selected: ');         
      
      console.log(selectedBurial) 

      this.transport.burialId = selectedBurial.burialId;
      this.transport.fromCemeteryId = selectedBurial?.cemeteryId ?? 0;
      this.transport.fromDivisionId = selectedBurial?.divisionId ?? 0;
      this.transport.fromSectionId = selectedBurial?.sectionId ?? 0 ; 
      this.transport.fromCompartmentId = selectedBurial?.compartmentId ?? 0;
      this.transport.fromGraveId = selectedBurial?.graveNumber ?? 0;  
      this.transport.fromGraveNo = selectedBurial?.graveNo;    
      this.transport.fromLevelId = selectedBurial?.levelId ?? 0;

      this.transportFormGroup.get('fromCemeteryCtrl')?.setValue(selectedBurial?.cemeteryId);
      this.transportFormGroup.get('fromDivisionCtrl')?.setValue(selectedBurial?.divisionId);
      this.transportFormGroup.get('fromSectionCtrl')?.setValue(selectedBurial?.sectionId);
      this.transportFormGroup.get('fromCompartmentCtrl')?.setValue(selectedBurial?.compartmentId);      
      this.transportFormGroup.get('fromGraveCtrl')?.setValue(selectedBurial?.graveNo);     
      this.transportFormGroup.get('fromLevelCtrl')?.setValue(selectedBurial?.levelId);  
      
      this.triggerFromCemeteryFilterChanges(
        selectedBurial?.cemeteryId ?? 0,
        selectedBurial?.divisionId ?? 0,
        selectedBurial?.sectionId ??0 ,
        selectedBurial?.compartmentId ?? 0);

        this.transportFormGroup.get('fromCemeteryCtrl')?.disable();
        this.disableCemeteryFromFilters();
     
    }

    statusChange(statusId : number){      
      if(statusId !== 0){        
        this.transports = this.originalTransports.filter(x => x.cleaningTransportStatusId === statusId);
      }else{        
        this.transports = this.originalTransports;
      }      
      
      this.resultsLength = this.transports.length;
    }
   
    addTransport(transport : ITransportDto){
      let addTransport : IAddTransportDto = this.convertTransportToAddTransport(transport)
      this.transportService.addTransport(addTransport).subscribe(
        (response : IResponseModal) =>{
          this.openResponseModal(response);
          this.getAllTransport(this.graveId); 
          this.transportExist = true;
          this.transportExistChange.emit(this.transportExist);
        }, (error) =>{
          console.error('something wrong happens adding a transport: ', error)

        })
    }

    updateClick(transport : ITransportDto){
      const update : IUpdateTransportDto = this.convertTransportToUpdateTransportDto(transport);
      this.transportService.updateTransport(transport.id, update).subscribe(
        (response :IResponseModal ) => {
          this.openResponseModal(response);
          this.internalService.emitRefreshTransportTable();
        }, (error) =>{
          console.error('error updateing transport : ', error)
        })
    }
    carryOut(transport : ITransportDto){      
      this.transportService.carryOut(transport).subscribe(
        (response : IResponseModal) =>{
          this.openResponseModal(response);
          this.internalService.emitRefreshTransportTable();   

        }, (error) => {
          console.error('error trying to store a receipt : ' , error)

        })
    }
    openResponseModal(response : IResponseModal): void {
      const dialogRef = this.dialog.open(ResponsemodalComponent, {
        width: '250px',
        data : response,
      });
    
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
    }
    convertTransportToUpdateTransportDto(transport : ITransportDto) : IUpdateTransportDto{
      
      console.log('transport : ');
      console.log(transport);
      const update : IUpdateTransportDto = {
       // transportId : transport.transportId,
        transportDate : transport.transportDate,
        cleaningTransportStatusId : transport.cleaningTransportStatusId,        
        transportPermit : transport.transportPermit,
        burialId : transport.burialId,

        toCemeteryId : transport.toCemeteryId,
        toDivisionId : transport.toDivisionId,        
        toSectionId : transport.toSectionId,               
        toCompartmentId : transport.toCompartmentId,
        toGraveId : transport.toGraveId,
        toGraveNo : transport.toGraveNo,        
        
        toLevelId : transport.toLevelId,
        feePaid : transport.feePaid,
        toDestination : transport.toDestination ?? '',
        burialDate : transport.burialDate,
        removeAuth : transport.removeAuth,
        removeAuthId : transport.removeAuthId,
        newAuth : transport.newAuth,
        newAuthId : transport.newAuthId,
        supervisorId : transport.supervisorId,
        undertakerId : transport.undertakerId,        
        //feeId : transport.feeId,   
        address : transport.address ?? '',
        transportTypeId : transport.transportTypeId,
      }
      console.log('update : ');
      console.log(update);
      return update;
    }

    convertTransportToAddTransport(transport : ITransportDto) : IAddTransportDto{      
      const addCleaning : IAddTransportDto = {
        cleaningId : this.cleaning.id,
        transportId : transport.transportId,
        transportDate : transport.transportDate,
        cleaningTransportStatusId : transport.cleaningTransportStatusId,       
        reference : transport.reference,
        transportPermit : transport.transportPermit,
        burialId : transport.burialId,
        fromCemeteryId : transport.fromCemeteryId,
        fromDivisionId : transport.fromDivisionId,
        fromSectionId : transport.fromSectionId,
        fromCompartmentId : transport.fromCompartmentId,        
        fromLevelId : transport.fromLevelId,
        fromGraveId : transport.fromGraveId,
        fromGraveNo : transport.fromGraveNo,       
        toCemeteryId : transport.toCemeteryId,
        toDivisionId : transport.toDivisionId,        
        toSectionId : transport.toSectionId,
        toCompartmentId : transport.toCompartmentId,
        toGraveId : transport.toGraveId,
        toGraveNo : transport.toGraveNo,
        toLevelId : transport.toLevelId,
        providerId : transport.providerId,        
        toGraveReference : transport.toGraveReference,       
        feePaid : transport.feePaid,
        toDestination : transport.toDestination,
        burialDate : transport.burialDate,
        removeAuth : transport.removeAuth,
        removeAuthId : transport.removeAuthId,
        newAuth : transport.newAuth,
        newAuthId : transport.newAuthId,
        supervisorId : transport.supervisorId,
        undertakerId : transport.undertakerId,        
        feeId : transport.feeId,   
        address : transport.address,     
        transportTypeId : transport.transportTypeId,
      }
      
      return addCleaning;
    }
    utcDate(localDate : Date) : Date{
      const utcDate: Date = new Date(Date.UTC(
        localDate.getFullYear(), 
        localDate.getMonth(), 
        localDate.getDate(), 
        localDate.getHours(), 
        localDate.getMinutes(), 
        localDate.getSeconds()
      ));
      return utcDate;
    }

    onTransportTypeChange(transportTypeId : number ){
     this.transportType = transportTypeId;
     this.transport.transportTypeId = transportTypeId;
      if(transportTypeId === 2){
        this.transportFormGroup.get('toCemeteryCtrl')?.setValue(null);
        this.transportFormGroup.get('toDivisionCtrl')?.setValue(null);
        this.transportFormGroup.get('toSectionCtrl')?.setValue(null);
        this.transportFormGroup.get('toCompartmentCtrl')?.setValue(null);
        this.transportFormGroup.get('toCompartmentCtrl')?.setValue(null);
        this.transportFormGroup.get('toGraveCtrl')?.setValue(null);  
      }else{
        this.transportFormGroup.get('toDestinationCtrl')?.setValue(null);
        this.transportFormGroup.get('addressCtrl')?.setValue(null);
      }
    }
    
    onCemeteryToChange(cemeteryToId : number){
      const selectedToCemetery = this.toCemeteries.find(cem => cem.code == cemeteryToId);  
      
      if(selectedToCemetery && selectedToCemetery.code != 0){ 
          this.transport.toCemeteryId = selectedToCemetery.code;
          this.transportFormGroup.get('toDivisionCtrl')?.enable();

          this.transportFormGroup.get('toDivisionCtrl')?.enable();
          this.transportFormGroup.get('toSectionCtrl')?.disable();
          this.transportFormGroup.get('toCompartmentCtrl')?.disable();
          
          this.getdivisionsByCemeteryId(selectedToCemetery.code);
          
          this.getGravesByGraveNo(selectedToCemetery.code, this.transport.toDivisionId ?? 0 ,this.transport.toSectionId ?? 0,this.transport.toCompartmentId ?? 0);
      }else{
        this.transportFormGroup.get('toDivisionCtrl')?.disable();
        this.transportFormGroup.get('toSectionCtrl')?.disable();
        this.transportFormGroup.get('toCompartmentCtrl')?.disable();
        this.toDivisions = this.originalDivisions;
      }
    }
    onDivisionToChange(divisionToId : number){
      const selectedToDivision = this.fromDivisions.find(div => div.id === divisionToId);    
      if (selectedToDivision  && selectedToDivision.id != 0) {
       
        this.transport.toDivisionId = selectedToDivision.id;
        
        this.transportFormGroup.get('toSectionCtrl')?.enable();
        this.transportFormGroup.get('toCompartmentCtrl')?.disable(); 
        
        
        this.getSectionsByCemeteryIdAndDivisionId(selectedToDivision.id,selectedToDivision.cemeteryId);  
        this.getGravesByGraveNo(selectedToDivision.cemeteryId,selectedToDivision.id, this.transport.toSectionId ?? 0,this.transport.toCompartmentId ?? 0);


      } else {       
        this.transportFormGroup.get('toSectionCtrl')?.disable();
        this.transportFormGroup.get('toCompartmentCtrl')?.disable();
        this.toSections = this.originalSections;
      }
    }
    onSectionToChange(sectionToId : number){
      const selectedToSection = this.toSections.find(sec => sec.id === sectionToId);    
      if (selectedToSection  && selectedToSection.id != 0) {
        
        this.transport.toSectionId = selectedToSection.id;
        this.transportFormGroup.get('toCompartmentCtrl')?.enable();  
        
        this.getCompartmentsByCemeteryIdAndDivisionIdAndSection(selectedToSection.cemeteryId,selectedToSection.divisionId,sectionToId);
        this.getGravesByGraveNo(selectedToSection.cemeteryId,selectedToSection.divisionId, selectedToSection.id,this.transport.toCompartmentId ?? 0);
      } else {
        this.transportFormGroup.get('toCompartmentCtrl')?.disable();
        this.toSections = this.originalSections;
      }
    }
    onCompartmentToChange(compartmentId : number){
      const selectedToCompartment = this.toCompartments.find(comp => comp.id === compartmentId);    
      if (selectedToCompartment  && selectedToCompartment.id != 0) {       
        
        this.transport.toCompartmentId = selectedToCompartment.id;
        this.transportFormGroup.get('toGraveCtrl')?.enable();
        
        this.getGravesByGraveNo(selectedToCompartment.cemeteryId,selectedToCompartment.divisionId,selectedToCompartment.sectionId,compartmentId);
          console.log('to graves : ')
          console.log(this.toGraves)
      } else {
        this.transportFormGroup.get('toGraveCtrl')?.disable();
        this.toGraves = this.originalGraves;
      }
      
      this.updateFilteredOptions('toGraveCtrl');

    }

    toGraveNoSelected(event: MatAutocompleteSelectedEvent){
      const selectedToGraveNo = event.option.value;
      
      const selectedGrave = this.toGraves.find(grave =>  grave.graveNo === selectedToGraveNo);     
    if (selectedGrave) {
      
      this.transport.toGraveId = selectedGrave.id;
      this.transport.toGraveNo = selectedGrave.graveNo
      this.getAllLevels(selectedGrave.id);
      }

    }

    private updateFilteredOptions(controlName : string) {
      
      const control = this.transportFormGroup.get(controlName);
      if (control) {
        if(controlName === 'fromGraveCtrl' ){
         
          this.filteredOptions = control.valueChanges.pipe(
            startWith(''),          
            map(value => this._filter(value || ''))
          );
          
        }else{
       
          this.filteredOptionsTo = control.valueChanges.pipe(
            startWith(''),          
            map(value => this._filterTo(value || ''))
          );
        }
        
      }
    }

    private _filter(value: string): string[] {
      const filterValue = value.toLowerCase();
     
        return this.fromGraves
        .map(gr => gr.graveNo !== null && gr.graveNo !== undefined ? gr.graveNo.toString() : '') 
        .filter(graveNo => graveNo && graveNo.toLowerCase().includes(filterValue));
     
    }
    private _filterTo(value: string): string[] {
      const filterValue = value.toLowerCase();     
        return this.toGraves
        .map(gr => gr.graveNo !== null && gr.graveNo !== undefined ? gr.graveNo.toString() : '') 
        .filter(graveNo => graveNo && graveNo.toLowerCase().includes(filterValue));
     
    }
    
    getTransportPermitNumber(transportPermit :string){

      if(transportPermit.length === 0){
        this.commonService.getTransportPermitNumber().subscribe(
          (response : string) => {
            this.transportPermitNumber = response;       
            this.transportFormGroup.get('transportPermitCtrl')?.setValue(response);
          })

      }else{
        this.transportFormGroup.get('transportPermitCtrl')?.setValue(transportPermit);
      }
      
    }

    getAllLevels(graveId : number){
      this.graveService.getGraveLevelsByGraveId(graveId).subscribe(
        (response : IGraveLevelsDto[]) =>{
            this.fromLevels = response;
            this.toLevels = response;

        }, (error) => {

        })
    }
    onLevelToChange(levelId : number){
      const seelectedLevel = this.toLevels.find(x => x.levelId === levelId);
      this.transport.toLevelId = seelectedLevel?.levelId;

    }

    getdivisionsByCemeteryId(cemeteryId : number){
      this.divisionService.getDivisionsByCementeryId(cemeteryId).subscribe(
        (response :IDivisionDto[]) => {
          this.toDivisions = response;        
        }, (error) => {
          console.error('Something went wrong getDivisionsByCementeryId :', error)
  
        })
    }

    getGravesByGraveNo(cemeteryId : number,divisionId : number, sectionId: number,compartementId : number){
      this.graveService.getAllGravesByGraveNo(
        cemeteryId,          
        divisionId,
        sectionId,
        compartementId,
        '0', this.pageIndex + 1,this.pageSize).subscribe(
        (response : ITableCountDto) => {
          this.toGraves = response.results;          
          this.originalGraves = response.results;
          this.updateFilteredOptions('graveCtrl');                     
      },
      (error) => {
        console.error('error retrieving graves:', error)
      })
    }

    getSectionsByCemeteryIdAndDivisionId( divisionId : number, cemeteryId : number){
      this.sectionService.getSectionByDivisionIdAndCemeteryId(divisionId,cemeteryId).subscribe(
        (response :ISectionDto[]) => {
          this.toSections = response;
        }, (error) => {
          console.error('Something went wrong getSectionsByCemeteryIdAndDivisionId :', error)
  
        })
    }

    getCompartmentsByCemeteryIdAndDivisionIdAndSection( cemeteryId : number,divisionId : number,sectionId : number ){
      this.compartmentService.getCompartmentByCemeteryIdAndDivisionIdSectionId(cemeteryId,divisionId,sectionId).subscribe(
        (response :ICompartmentDto[]) => {
          this.toCompartments = response;
        }, (error) => {
          console.error('Something went wrong compartments :', error)
  
        })
    }
    resetTransport(): ITransportDto {
      return {
        id: 0,
        transportId: 0,
        transportDate: new Date(),
        cleaningTransportStatusId: 0,
        reference: '',
        transportPermit: '',
        burialId: 0,
        fromCemeteryId: 0,
        fromDivisionId: 0,
        fromSectionId: 0,
        fromCompartmentId: 0,
        fromGraveId: 0,
        fromLevelId : 0,
        toCemeteryId: 0,
        toDivisionId: 0,
        toSectionId: 0,
        toCompartmentId: 0,
        toGraveId: 0,
        toGraveReference: '',
        toLevelId: 0,
        toDestination: '',
        burialDate: new Date(),
        removeAuth: '',
        removeAuthId: '',
        newAuth: '',
        newAuthId: '',
        supervisorId: 0,
        undertakerId: 0,
        providerId: 0,
        feePaid: false,
        address: '',    
        transportStatusDescription: '',
        supervisorName: '',
        supervisorSurname: '',   
        providerName: '',
       // transportRate: 0,
        feeId: 0,
        transportTypeId : 0,
      };

    

    }

    getBurialByBurialId(burialId : number){
      this.burialsService.getBurialPermitByBurialId(burialId).subscribe(
        (response : IBurialPermitDto) => {
          this.burialPermitSeleted = response;
          this.selectedBurial(this.burialPermitSeleted);
        }, (error) =>{
            console.error('something went wrong with getBurialByBurialId',error,)
        })
  
    }

    isTransportExist(cleaningId : number) { 
      this.transportService.getTransportByCleaningId(cleaningId).subscribe(
        (response : ITransportDto) => {
          if(this.cleaning.transport){       
            this.cleaning.transport.id = response.id;
            if(response){
              this.transportExist = true
              this.transportExistChange.emit(this.transportExist);
              //console.log('isTransportExist :' + this.transportExist)

            }else{
              this.transportExist =false;
              this.transportExistChange.emit(this.transportExist);
              //console.log('isTransportExist : ' + this.transportExist)
            }
           
          }
        }, (error) =>{
          console.error('something went wrong with getTransportByCleaningId ',error)
    
        })
    }

}
