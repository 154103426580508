
<div class="container">
  <div class="form">
    <form [formGroup]="SectionNewForm">
<span class="mat-title" mat-dialog-title><b>Add New Section</b> </span>
<mat-dialog-content>
    
  <div class="box">
    <mat-form-field>
      <mat-label>Cemetery</mat-label>
      <mat-select formControlName="cemeteryCtrl"><!-- (selectionChange)="onCemeteryChange()" -->
        <mat-option *ngFor="let cemetery of cemeteries" [value]="cemetery.code" >
          {{ cemetery.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Locality</mat-label>
      <mat-select formControlName="localityCtrl">
        <mat-option *ngFor="let locality of localities" [value]="locality.id">
          {{ locality.description }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="box">
    <mat-form-field>
      <mat-label>Division</mat-label>
      <mat-select formControlName="divisionCtrl"><!-- (selectionChange)="onDivisionChange() -->
      
        <mat-option *ngFor="let division of divisions" [value]="division.id" >
          {{ division.description }}
        </mat-option>
      </mat-select>
    </mat-form-field>      
      <mat-form-field>
        <mat-label>Section</mat-label>
        <input matInput placeholder="Section Name" formControlName="sectionCtrl" required/>
      </mat-form-field>
   </div>
  
<mat-dialog-actions>
    <div class="divspace">
  <button mat-raised-button color="primary" (click)="addNewSection()">Save</button>
  <button mat-raised-button style="color:dimgray;" [mat-dialog-close] cdkFocusInitial>Close</button></div>
</mat-dialog-actions>

</mat-dialog-content>
</form>
</div>
</div>
