import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, catchError, map, merge, Observable, of as observableOf, of, startWith, switchMap} from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IBurialPermitDto } from 'src/app/burials/models/BurialPermitDto.model';
import { ITableCountDto } from 'src/app/burials/models/ITableCountDto';
import { IAddCleaningDto } from 'src/app/models/AddCleaningDto.model';
import { ICleaningDto } from 'src/app/models/CleaningDto.model';
import { ICleaningTransportStatusDto } from 'src/app/models/CleaningTransportStatusDto.model';
import { IResponseModal } from 'src/app/models/ResponseModal.model';
import { ISupervisorsDto } from 'src/app/models/SupervisorsDto.model';
import { IUpdateCleaningDto } from 'src/app/models/UpdateCleaningDto.model';
import { CleaningTransportStatus } from 'src/app/models/cleaning-status.enum';
import { ResponsemodalComponent } from 'src/app/responsemodal/responsemodal.component';
import { BurialPermitService } from 'src/app/services/burial-permit.service';
import { BurialService } from 'src/app/services/burial.service';
import { CleaningService } from 'src/app/services/cleaning.service';
import { CommonService } from 'src/app/services/common.service';
import { InternalCommunicationService } from 'src/app/services/internal-communication.service';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';  
import { CleaningEditComponent } from './cleaning-edit/cleaning-edit.component';
import { TransportComponent } from '../transport/transport.component';
import { TransportTemplateComponent } from '../transport/transport-template/transport-template.component';
import { IUndertakerDto } from 'src/app/models/UndertakerDto.model';
import { UndertakerService } from 'src/app/services/undertaker.service';
import { IGraveLevelsDto } from 'src/app/models/GraveLevelsDto.model';
import { GraveService } from 'src/app/services/grave.service';
import { GraveCleanTransTemplateComponent } from 'src/app/cemetery/grave/grave-cleantrans-template/grave-cleantrans-template.component';
import { IPreViewCleaningsDto } from 'src/app/models/PreViewCleaningDto.model';
import { IPreViewTransportDto } from 'src/app/models/PreViewTransportDto.model';
import { ITransportDto } from 'src/app/models/TransportDto.model';
import { CemeteryService } from 'src/app/services/cemetery.service';
import { DivisionService } from 'src/app/services/division.service';
import { SectionService } from 'src/app/services/section.service';
import { CompartmentService } from 'src/app/services/compartment.service';
import { ICemeteryDto } from 'src/app/models/CemeteryDto.model';
import { IDivisionDto } from 'src/app/models/DivisionDto.model';
import { ISectionDto } from 'src/app/models/SectionDto.model';
import { ICompartmentDto } from 'src/app/models/CompartmentDto.model';
import { IGraveDto } from 'src/app/models/GraveDto.model';
import { BurialTemplateComponent } from 'src/app/burials/burial-template/burial-template.component';
import { IPreViewBurialPermit } from 'src/app/models/PreViewBurialPermit.model';
import { LocalityService } from 'src/app/services/locality.service';
import { GenderService } from 'src/app/services/gender.service';
import { MaritalStatusService } from 'src/app/services/marital-status.service';
import { BurialTypeService } from 'src/app/services/burial-type.service';
import { IGenderDto } from 'src/app/models/Gender.model';
import { ILocalityDto } from 'src/app/models/LocalityDto.model';
import { IMaritalStatusDto } from 'src/app/models/MaritalStatusDto.model';
import { IBurialTypeDto } from 'src/app/models/BurialTypeDto.model';
import { TransportService } from 'src/app/services/transport.service';
import { NewCleaningComponent } from 'src/app/new-cleaning/new-cleaning.component';

export const MY_FORMATS = {
parse: {
  dateInput: 'L',
},
display: {
  dateInput: 'L',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
},
};

const PATTERNS = {
  alphanumeric: /^[a-zA-Z0-9]*$/,
  numeric: /^[0-9]*$/,
  alphabeticWithNumbersAndSpecial: /^[a-zA-Z0-9,()-.'`" \/]*$/
};

@Component({
  selector: 'app-cleaning',
  templateUrl: './cleaning.component.html',
  styleUrls: ['./cleaning.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-GB'
    },
    {
      provide: DateAdapter, useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_FORMATS
    },
  ],
})

export class CleaningComponent implements OnInit, AfterViewInit {
disableProcessButton: boolean = false;
curentValueTransport! : boolean;
@Input() graveId! : number;
transportExist$: BehaviorSubject<boolean> = new BehaviorSubject(false);
transportExist = false;
isLoading = false;   
cleaningDate! : Date;
cleaningPermitNumber! : string;
levelsPerGrave! : IGraveLevelsDto[];
isToggleActive: boolean = false
anyCheckboxChecked = false;

isRateLimitReached = false;
formDisabled: boolean = false;
cleaningFormGroup!: FormGroup;
undertakers: IUndertakerDto[] = [];
undertakerSelected! :IUndertakerDto;
originalUndertakers! :IUndertakerDto[];
burials! : IBurialPermitDto[];
cleaningStatuses! : ICleaningTransportStatusDto[];
filteredUndertakers: IUndertakerDto[] = [];
selectedDUndertakerDescription!:string;
transport!: ITransportDto ;

private defaultUndertaker: IUndertakerDto = {
  id: 0,
  name: '',
  surname: '',
  code: 0, 
  telephone : '',
  address1: '',
  address2: '',
  localityId : 0,
  vatNo: ''
};

cleaning: ICleaningDto = {
  id : 0,
  cleaningDate : new Date,
  authPerson : '',
  supervisorId : 0,
  marbleSlab : false,
  isFamilyPresent : false,
  timeForCleaning : '',
  applicationDate : new Date,
  authoriseBy : '',
  authoriseId : '', 
  graveId : 0,
  levelId : 0, 
  feePaid : false,
  burialId : 0,
  cleaningTransportStatusId : 0,
  cleaningStatusDescription : '',
  supervisorName : '',
  supervisorSurname : '',
  employeeName : '',
  employeeSurname : '',
  providerName : '',  
  feeId : 0,
  undertakerId : 0,
  
};

transportPreview!: IPreViewTransportDto;
cleanTransPreview : IPreViewCleaningsDto[] = [{
  cleaningPermitNumber: '',
  applicationDate : new Date(),
  supervisorName : '',
  supervisorSurname : '',
  //officerBauName: '',
  //officerBauSurname: '',
  cleaningDate : new Date(),
  undertakerName : '',
  undertakerSurname : '',

  graveAddressCemetery: '',
  graveAddressDivision: '',
  graveAddressSection: '',
  graveAddressCompartment: '',
  graveAddressNumber: '',
  graveAddressLevel: '',

  cleaningDeceasedName : '',
  cleaningDeceasedSurname : '',
  marbleSlab : undefined,
  isFamilyPresent : undefined,
  feePaid : undefined,
  burialDate : new Date(),

  transportPreview: {
    transportDeceasedName: '',
    transportDeceasedSurname: '',
    transportToCemetery: '',
    transportToDivision: '',
    transportToSection: '',
    transportToCompartment: '',
    transportToNumber: '',
    transportType: 0,
    transportToCountry: ''
  },
}];

cemeteries : ICemeteryDto[] = [];
divisions: IDivisionDto[] = [];
sections: ISectionDto[] = [];
compartments: ICompartmentDto[] = [];
graveNo!: IGraveDto | undefined;

originalCleanings! :ICleaningDto[]; 
cleanings :ICleaningDto[] = [];
currentsCleanings! : ICleaningDto[];
localities : ILocalityDto[] = [];
burialTypes : IBurialTypeDto[] = [];
massLocalities :  ILocalityDto[] = [];
genders : IGenderDto[] = [];
statuses : IMaritalStatusDto[] = [];
add! : ICleaningDto;
burialRightOptions:Observable<string[]> | undefined;
@ViewChild(GraveCleanTransTemplateComponent) graveCleanTransTemplateComponent!: GraveCleanTransTemplateComponent

//table 
dataSource!: MatTableDataSource<ICleaningDto>;
@ViewChild(MatPaginator) paginator!: MatPaginator;
@ViewChild(MatSlideToggleModule) slidetoggle!: MatSlideToggleModule;
@ViewChild(MatSort) sort!: MatSort;

burialPermitPreview: IPreViewBurialPermit = { 

  // STEP 1
  idCardNumber:'',
  name: '',
  surname: '',
  locality:'',
  address : '',
  profession : '',
  dateOfBirth: new Date(),
  placeOfBirth: '',
  maritalStatus : '',
  gender:'',
  spouseName: '',
  spouseSurname: '',
  fatherName: '',
  fatherSurname: '',
  fatherStatus: '',
  motherName: '',
  motherSurname: '',
  motherStatus : '',    
  
  // STEP 2
  burialType: '',
  burialTypeId: 0,
  dateOfDeath: new Date(),
  timeOfDeath: '',
  placeOfDeath: '',
  causeOfDeath: '',
  massTime: '',
  massLocality: '', 
  dateOfBurial: new Date(), 
  burialRight : '',
  undertaker : '',  
  issuedDate: new Date(new Date().setDate(new Date().getDate() + 1)),     

  // STEP 3
  cemeteryName: '',
  divisionName: '',
  sectionName: '',
  compartmentName: '',    
  graveNo: '',
  levelName:'',
  username:'',
  country:'',
  burialLocation:'',
  healthOfficer:'',

  //PERMITNO
  permitNo: 'preview',
  remarks:'',

  //CLEANING AND TRANSPORT DETAILS
  dateCleaned: new Date(),
  dateTransported: new Date(),
  transportedFrom: '',
  transportedTo: '',
  cemeteryNameTo: '',
  divisionNameTo: '',
  sectionNameTo: '',
  compartmentNameTo: '',
  graveNoTo: '',
  graveLevelTo: '',  

  //REFNO
  refNo: ''
    
};

expandedElement: ICleaningDto | undefined;
displayedColumns: string[] = [
  'id',
  'cleaningPermitNumber',
  'deceasedName',
  // 'deceasedSurname',
  // 'marbleslab',
  'cleaningDate', 
  // 'timeForCleaning',  
  'levelId',
  'undertakerName',  
  'cleaningStatusDescription', 
  'transport',
  'expand',  
  'select',
  ];

  rightOptions:string[] = [
    'Common Grave',
    'Private Grave',
    'Owner',
    'Owners Consent',
    'Co-Owners Consent',
    'One of the Heirs',
    'Heirs Consent',
  ];
displayedColumnsWithExpand = [...this.displayedColumns];
resultsLength = 0;
selectedClean: ICleaningDto | null = null;
constructor(
  public dialog: MatDialog,
  private _formBuilder: FormBuilder,
  private cleaningService : CleaningService,
  private commonService : CommonService,
  private burialsService : BurialPermitService,
  private internalService : InternalCommunicationService,
  private undertakerService : UndertakerService,
  private graveService : GraveService,
  public dialogRef: MatDialogRef<CleaningComponent>,  
  private cemeteryService: CemeteryService,
  private divisionService : DivisionService,
  private sectionService : SectionService,
  private compartmentService : CompartmentService,
  private burialTypeService : BurialTypeService,
  private localityService: LocalityService,
  private genderService: GenderService,
  private maritalStatusService: MaritalStatusService,
  private cdr: ChangeDetectorRef,
  private transportService : TransportService
){

}
  ngAfterViewInit(): void {
    this.sort.active = 'levelId';
    this.sort.direction = 'asc'; 
    this.dataSource.sort = this.sort;    
  }
  
ngOnInit(): void {    
    this.getBurialsPerGrave(this.graveId);
    this.getAllCleanings(this.graveId);
    this.getCleaningStatus();
    this.getAllUndertakers();

    this.internalService.cleaningUpdated$.subscribe(() => {          
      this.refreshTableData();
    });   
    this.cleaningFormGroup = this._formBuilder.group({    
      cleaningPermitCtrl: [{value: this.cleaning.cleaningPermitNumber || '',disabled : true}],
      cleaningStatusIdCtrl :  [ this.cleaning.cleaningTransportStatusId || 0],  
      cleaningDateCtrl: [ this.cleaning.cleaningDate || new Date, [Validators.required,]], 
      undertakerCtrl: [this.cleaning.undertakerId  || 0, [Validators.required,  Validators.min(1)]],
      burialRightsCtrl : [this.cleaning ? this.cleaning.burialRights: '', [Validators.required,]],
    });    
    this.subscribeChanges();
    this.checkIfAnyCheckboxChecked(); 
    this.dataSource = new MatTableDataSource(this.cleanings);
    
    this.getAllDivisions();
    this.getAllSections();
    this.getAllCompartments();
    this.getAllCemeteries(); 
    this.getAllBurialTypes();
    this.getAllGender();
    this.getAllLocalities();
    this.getAllMaritalStatus();
    this.burialRightOptions = this.cleaningFormGroup.get('burialRightsCtrl')?.valueChanges.pipe(startWith(''),
      map(value => this._filter2(value || ''))
    );
  }

  getAllLocalities(){
    this.localityService.getAllLocalities().subscribe(
      (response: ILocalityDto[]) => {
        this.localities = response;  
        this.massLocalities = response;      
      },
      (error) => {        
        console.error('Error adding localities: ', error);        
      }
      );
  }
  getAllGender(){
    this.genderService.getAllGender().subscribe(
      (response: IGenderDto[]) => {
        this.genders = response; 
      },
      (error) => {        
        console.error('Error retrieving genders: ', error);        
      }
      );
  }
  getAllMaritalStatus(){
    this.maritalStatusService.getAllMaritalStatus().subscribe(
      (response: IMaritalStatusDto[]) => {
        this.statuses = response; 
      },
      (error) => {        
        console.error('Error retrieving marital status: ', error);        
      }
      );
  }
  getAllBurialTypes(){
    this.burialTypeService.getAllBurialTypes().subscribe(
      (response: IBurialTypeDto[]) => {
        this.burialTypes = response;  
        },
        (error) => {        
          console.error('Error retrieving burial types: ', error);        
        }
        );
  }

  dateFilterOnlyFuture = (d: Date | null): boolean => {
    if (!d) {
      return false; 
    }  
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return !(d < today);
  };

  subscribeChanges() {
    this.cleaningFormGroup.get('cleaningPermitCtrl')?.valueChanges.subscribe((value) => {
      if (this.cleaning) {
        this.cleaning.cleaningPermitNumber = value;
      }
    });
    
    this.cleaningFormGroup.get('cleaningDateCtrl')?.valueChanges.subscribe((value) => {          
      this.cleaning.cleaningDate = new Date(value) ;  
      this.cleaningDate = new Date(value);    
    });
    this.cleaningFormGroup.get('undertakerCtrl')?.valueChanges.subscribe((value) => {          
      this.cleaning.undertakerId = value;
      // this.filterUndertakers(value);
    });
    this.cleaningFormGroup.get('burialRightsCtrl')?.valueChanges.subscribe((value) => {     
      this.cleaning.burialRights = value;       
    })
  }
  //table
  expandCollapse(cleaning: any): void {
    this.expandedElement = this.expandedElement === cleaning ? null : cleaning;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }  
  
    this.selectedClean = null;
  }
  fetchCleaningRecords()  {    
    return this.cleaningService.getAllCleaning(
      null,
      null,
      this.paginator.pageIndex + 1,
      this.paginator.pageSize,
      this.graveId
    ).pipe(catchError(() => observableOf(null)));
  }

  refreshTableData() {
    this.isLoading = true;        
    this.fetchCleaningRecords().subscribe(data => {
     
      if (data === null) {
        return;
      }  
      
      this.cleanings = data.results;  
      this.getGraveLevelsByGraveId(this.graveId);
      this.populateCleaningUndertaker();     
     
      this.dataSource = new MatTableDataSource(this.cleanings);        
      this.dataSource.sort = this.sort;
      this.resultsLength = data.totalItems;
      this.isLoading = false;
    });
  }   
  onCheckboxChange(cleaning: ICleaningDto){
    cleaning.selected = !cleaning.selected;
    this.checkIfAnyCheckboxChecked();
  }

  isSelected(cleaning: ICleaningDto): boolean {     
    return cleaning.selected || false;
  }

  deleteCleaning(id : number){
    this.cleaningService.deleteCleaning(id).subscribe((response : IResponseModal) =>{
      this.openResponseModal(response);
      this.internalService.emitRefreshCleaningTable();
    }, (error) => {
      console.error('error deleting cleaning : ', error);

    })

  }
 
 //end table

  //resources
  getBurialsPerGrave(graveId : number){
    this.burialsService.getBurialPermits(null,null,null,null,1,100,graveId).subscribe(
      (response : ITableCountDto) => {
        this.burials = response.results;  
      }, (error) => {

      })
  }

 getAllCleanings(graveId : number){
  if(graveId !== 0 && graveId !== null){    
    this.cleaningService.getAllCleaning(null,null,1,100,graveId).subscribe(
    
      (response : ITableCountDto) => {
        this.originalCleanings = response.results;          
        this.cleanings = response.results; 
        
        console.log('cleanings :',this.cleanings)      
        if(this.cleanings[0]){
          this.cleaning = this.cleanings[0];
        }
        if (this.cleaning && this.cleaning.cleaningDate) {
          this.cleaningFormGroup.get('cleaningDateCtrl')?.setValue(this.cleaning.cleaningDate);
       }
       if (this.cleaning && this.cleaning.burialRights) {
        this.cleaningFormGroup.get('burialRightsCtrl')?.setValue(this.cleaning.burialRights);
       }
       if(this.cleanings.length > 0){
        this.getCleaningPermitNumber(this.cleanings[0].cleaningPermitNumber ?? '');
       }
        
        
        this.undertakerService.getAllUndertakers().subscribe(
          (response : IUndertakerDto[]) =>{
            this.originalUndertakers = response;
            this.cleanings.forEach(cleaning => {
              const undertaker = this.originalUndertakers.find(u => u.id === cleaning.undertakerId);
              
              if (undertaker) {
                cleaning.undertakerName = undertaker.name;
                cleaning.undertakerSurname = undertaker.surname;
              }
            });
            this.getGraveLevelsByGraveId(this.graveId);
      
          }, (error) =>{
            console.error('An error happens retrieving undertakers data');
      
          })

        this.dataSource = new MatTableDataSource(this.cleanings);   
        this.dataSource.sort = this.sort;
        this.resultsLength = response.totalItems;

        

      }, (error) =>{
        console.warn('Some error happens while retrieving cleanings: ', error)
  
      })

  }else{
    this.cleaningService.getAllCleaning(null,null,1,100).subscribe(
    
      (response : ITableCountDto) => {
        this.originalCleanings = response.results;
        this.cleanings = response.results;
        this.dataSource = new MatTableDataSource(this.cleanings);   
        this.dataSource.sort = this.sort;
        this.resultsLength = response.totalItems; 
  
      }, (error) =>{
        console.warn('Some error happens while retrieving cleanings: ', error)
      })     
  } 
  
 }  

    getCleaningStatus(){
      this.cleaningService.getAllCleaningStatus(null,null,1,100).subscribe(
        (response : ITableCountDto) => {
          this.cleaningStatuses = response.results;       

        }, (error) => {
          console.warn('Some error happens while retrieving CleaningStatus: ', error)

        })
    }

    //DROP DOWN
    

    statusChange(statusId : number){      
      if(statusId !== 0){        
        this.cleanings = this.originalCleanings.filter(x => x.cleaningTransportStatusId === statusId);
      }else{        
        this.cleanings = this.originalCleanings;
      }
      
      this.dataSource = new MatTableDataSource(this.cleanings);   
      this.dataSource.sort = this.sort;
      this.resultsLength = this.cleanings.length;

    }
    addCleaning(cleaning : ICleaningDto){
      let addCleaning : IAddCleaningDto = this.convertCleaningToAddCleaning(cleaning)
      this.cleaningService.addCleaning(addCleaning).subscribe(
        (response : IResponseModal) =>{
          this.openResponseModal(response);
          this.getAllCleanings(this.graveId); 

        }, (error) =>{
          console.error('something wrong happens adding a cleaning: ', error)

        })
    }

   
    carryOut(){      
      this.isLoading = true;
      this.cleaningService.carryOut(this.cleanings).subscribe(
        
        (response : IResponseModal) =>{
          
          this.openResponseModal(response);
          this.internalService.emitRefreshCleaningTable();
          this.isLoading = false;
        }, (error) => {
          console.error('error trying to store a receipt : ' , error)
        })
    }
    
    updateAllCleaningClick() {
      if (!this.undertakerSelected || this.undertakerSelected.id === 0) {
        console.error("No undertaker selected.");
        return;
      }
    
      const cleaningsToUpdate: IUpdateCleaningDto[] = [];
    
      this.cleanings.forEach(cleaning => {
        const updatedCleaning = this.convertCleaningToUpdateCleaningDto(cleaning);
        updatedCleaning.undertakerId = this.undertakerSelected.id;
        updatedCleaning.cleaningDate = this.utcDate(this.cleaningDate);
    
        cleaningsToUpdate.push(updatedCleaning);
      });
    
      this.cleaningService.updateAllCleanings(cleaningsToUpdate).subscribe(
        (response: IResponseModal) => {
          this.openResponseModal(response);
          this.internalService.emitRefreshCleaningTable();
        },
        error => {
          console.error('Error updating cleaning:', error);
        }
      );
    }
    
    

    convertCleaningToUpdateCleaningDto(cleaning : ICleaningDto) : IUpdateCleaningDto{     
      const updateCleaning : IUpdateCleaningDto = {
        cleaningPermitNumber : cleaning.cleaningPermitNumber,
        cleaningDate : this.utcDate( new Date(cleaning.cleaningDate)),
        authPerson : cleaning.authPerson,
        supervisorId : cleaning.supervisorId ?? 0,
        marbleSlab : cleaning.marbleSlab,
        isFamilyPresent : cleaning.isFamilyPresent,
        timeForCleaning : cleaning.timeForCleaning,
        applicationDate : cleaning.applicationDate,
        authoriseBy : cleaning.authoriseBy,
        authoriseId : cleaning.authoriseId,        
        graveId : cleaning.graveId,
        levelId : cleaning.levelId,        
        burialId : cleaning.burialId,
        cleaningTransportStatusId : cleaning.cleaningTransportStatusId,
        undertakerId : cleaning.undertakerId,
        feePaid : cleaning.feePaid,
        burialRights: this.cleaningFormGroup.get('burialRightsCtrl')?.value,
      }
      return updateCleaning;
    }
    openResponseModal(response : IResponseModal): void {
      const dialogRef = this.dialog.open(ResponsemodalComponent, {
        width: '250px',
        data : response,
      });
    
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
    }
    

    convertCleaningToAddCleaning(cleaning : ICleaningDto) : IAddCleaningDto{
      
      const addCleaning : IAddCleaningDto = {
        burialId : cleaning.burialId,
        cleanindDate : this.utcDate(cleaning.cleaningDate),
        supervisorId : cleaning.supervisorId,
        marbleSlab : cleaning.marbleSlab ,
        isFamilyPresent : cleaning.isFamilyPresent,
        timeForCleaning : cleaning.timeForCleaning,
        applicationDate : cleaning.applicationDate,
        authPerson : cleaning.authPerson,
        authoriseBy : cleaning.authoriseBy,
        authoriseId : cleaning.authoriseId,
        cleaningPermitNumber : cleaning.cleaningPermitNumber,        
        graveId : this.graveId,
        levelId : cleaning.levelId,
        feePaid : cleaning.feePaid,
        feeId : cleaning.feeId, 
        undertakerId : cleaning.undertakerId       
      }
      
      return addCleaning;
    }
    utcDate(localDate : Date) : Date{
      const utcDate: Date = new Date(Date.UTC(
        localDate.getFullYear(), 
        localDate.getMonth(), 
        localDate.getDate(), 
        localDate.getHours(), 
        localDate.getMinutes(), 
        localDate.getSeconds()
      ));
      return utcDate;
    }

    updateRowCleaning(cleaning : ICleaningDto){     
      cleaning.cleaningPermitNumber = this.cleaning.cleaningPermitNumber;       
      
      const dialogRef = this.dialog.open(CleaningEditComponent, {          
        data: cleaning,
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
    }
   
    areAllSelected(): boolean {    
      return (
        this.cleanings.length > 0 &&
        this.cleanings
          .filter(item => item.cleaningTransportStatusId !== 6 && item.cleaningTransportStatusId !== 7)
          .every(item => item.selected)
      );
    }
    
  hasInvalidStatus(): boolean {
    return this.cleanings.some(item => item.selected && (item.cleaningTransportStatusId === 6 || item.cleaningTransportStatusId === 7));
  }
  checkIfAnyCheckboxChecked(): void {
    this.anyCheckboxChecked = this.cleanings.some(cleaning => cleaning.selected);   
    this.disableProcessButton = this.cleanings.some(cleaning =>  cleaning.selected && cleaning.cleaningTransportStatusId === 7); 
  }

  toggleAllCheckboxes(checked: boolean) {
      this.cleanings.forEach(c => c.selected = checked);
      this.checkIfAnyCheckboxChecked();
  }
  
  onUndertakerSelectionChange(selectedId: number) {
    const selectedUndertaker = this.undertakers.find(undertaker => undertaker.id === selectedId);
    this.undertakerSelected = selectedUndertaker || this.defaultUndertaker; // Assign the default if not found
  
    this.selectedDUndertakerDescription = selectedUndertaker
      ? `${selectedUndertaker.name} ${selectedUndertaker.surname}`
      : '';
  
    if (selectedUndertaker) {
      this.cleaning.undertakerId = selectedId;
      this.cleanings.forEach(cleaning => {
        cleaning.undertakerId = selectedId;
        cleaning.undertakerName = selectedUndertaker.name;
        cleaning.undertakerSurname = selectedUndertaker.surname;
      });
    } else {
      this.cleanings.forEach(cleaning => {
        cleaning.undertakerId = 0;
        cleaning.undertakerName = '';
        cleaning.undertakerSurname = '';
      });
    }
  }
  
  
  
  getAllUndertakers(){
      this.undertakerService.getAllUndertakers().subscribe(
        (response : IUndertakerDto[]) => {
          this.undertakers = response;
          this.filteredUndertakers = response;
          

        }, (error) =>{
          console.error('Error retrieving undertakers');
        } 
        );
    }


    onToggleChange(event: MatSlideToggleChange) {
      this.isToggleActive = event.checked  ; 
      
    }

    openTransportComponent(cleaning: ICleaningDto) {
      
      if (cleaning.transport?.id && cleaning.transport.id !== 0) {
        
        this.transportService.getTransportByCleaningId(cleaning.id).subscribe(
          (response: ITransportDto) => {           
            cleaning.transport = response;
            const dialogRef = this.dialog.open(TransportComponent, {          
              data: cleaning,
            });
    
            dialogRef.afterClosed().subscribe(result => {            
              if (result !== undefined) {              
                if (this.cleaning.transport) {               
                  this.cleaning.transport.istransportExist = result;
                  this.transportExist$.next(result);    
                }
              }
              this.internalService.emitRefreshCleaningTable();
              console.log('The dialog was closed');      
            });
          }, 
          (error) => {
           console.error('something when wrong with openTransportComponent : ',error)
          }
        );
      } else {
       
        const dialogRef = this.dialog.open(TransportComponent, {          
          data: cleaning,
        });
    
        dialogRef.afterClosed().subscribe(result => {            
          if (result !== undefined) {              
            if (this.cleaning.transport) {               
              this.cleaning.transport.istransportExist = result;
              this.transportExist$.next(result);    
            }
          }
          this.internalService.emitRefreshCleaningTable();
          console.log('The dialog was closed');      
        });
      }
    }
    

    getGraveLevelsByGraveId(graveId : number){
      this.graveService.getGraveLevelsByGraveId(graveId).subscribe(
        (response : IGraveLevelsDto[]) =>{
          this.levelsPerGrave = response;
          this.cleanings.forEach(cleaning => {
            const level = this.levelsPerGrave.find(l => l.levelId === cleaning.levelId);
            
            if (level) {              
              cleaning.levelDescription = level.levelDescription;
              cleaning.levelStatus = level.graveLevelStatusDescription;
            }
          });
         
        }, (error) =>{
          console.error('An error happens retrieving grave levels data')
    
        })
      }

    populateCleaningUndertaker(){
      this.undertakerService.getAllUndertakers().subscribe(
        (response : IUndertakerDto[]) =>{
          this.originalUndertakers = response;
          this.cleanings.forEach(cleaning => {
            const undertaker = this.originalUndertakers.find(u => u.id === cleaning.undertakerId);
            
            if (undertaker) {
              cleaning.undertakerName = undertaker.name;
              cleaning.undertakerSurname = undertaker.surname;
            }
          });
    
        }, (error) =>{
          console.error('An error happens retrieving undertakers data')
    
        })
    }

    getCleaningPermitNumber(cleaningPermit :string){
      

      if(cleaningPermit.length === 0){
        this.commonService.getCleaningPermitNumber().subscribe(
          (response : string) => {
            this.cleaningPermitNumber = response;       
            this.cleaningFormGroup.get('cleaningPermitCtrl')?.setValue(response);
          })

      }else{
        this.cleaningFormGroup.get('cleaningPermitCtrl')?.setValue(cleaningPermit);
      }
      
    }

    printWorkOrder() {

      this.fillGraveNoInCleanings();
      
      this.fillWorkOrder();

        const dialogRef = this.dialog.open(GraveCleanTransTemplateComponent, {
          data: this.cleanTransPreview
          });
        
        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');          
        }); 
    }

    fillWorkOrder() {
      this.cleanTransPreview = this.cleanings.filter(item => item.selected).map(item => {
        const relatedBurial = this.burials.find(burial => burial.burialId === item.burialId);
        console.log(relatedBurial)
        
        return {
          cleaningPermitNumber: item.cleaningPermitNumber,
          applicationDate : item.applicationDate,
          supervisorName : item.supervisorName,
          supervisorSurname : item.supervisorSurname,
          //officerBauName: '',
          //officerBauSurname: '',
          cleaningDate : item.cleaningDate,
          undertakerName : item.undertakerName ?? '',
          undertakerSurname : item.undertakerSurname ?? '',

          graveAddressCemetery: this.cemeteries.find(cem => cem.code === relatedBurial?.cemeteryId)?.name ?? '',
          graveAddressDivision: this.divisions.find(div => div.id === relatedBurial?.divisionId)?.description ?? '',
          graveAddressSection: this.sections.find(sec => sec.id === relatedBurial?.sectionId)?.description ?? '',
          graveAddressCompartment: this.compartments.find(com => com.id === relatedBurial?.compartmentId)?.description ?? '',         

          graveAddressNumber: relatedBurial?.graveNo ?? '',
          graveAddressLevel: item.levelId.toString(),
    
          cleaningDeceasedName : item.deceasedName,
          cleaningDeceasedSurname : item.deceasedSurname,
          marbleSlab : item.marbleSlab,
          isFamilyPresent : item.isFamilyPresent,
          feePaid : item.feePaid,
          burialDate: relatedBurial ? new Date(relatedBurial.burialDate) : undefined,
          
          transportDeceasedName: item.deceasedName,
          transportDeceasedSurname: item.deceasedSurname,
    
          transportPreview: item.transport ? {
            transportToCemetery: (this.cemeteries.find(cem => cem.code === item.transport?.toCemeteryId))?.name,
            transportToDivision: (this.divisions.find(div => div.id === item.transport?.toDivisionId))?.description,
            transportToSection: (this.sections.find(sec => sec.id === item.transport?.toSectionId))?.description,
            transportToCompartment: (this.compartments.find(com => com.id === item.transport?.toCompartmentId))?.description,
            transportToNumber: item.transport?.toGraveNo,
            transportType: item.transport?.transportTypeId,
            transportToCountry: item.transport?.toDestination
          } : undefined
        };
      });
    }
    getAllCemeteries(){
      this.cemeteryService.getAllCemeteries().subscribe(
        (response : ICemeteryDto[]) => {         
          this.cemeteries = response;
        })
    }
    getAllDivisions(){
      this.divisionService.getAllDivisions().subscribe( 
        (response : IDivisionDto[])=>{
          this.divisions = response;           
        })
    }
    getAllSections(){
      this.sectionService.getAllSections().subscribe( 
        (response : ISectionDto[])=>{
          this.sections = response;
        }) 
    }
    getAllCompartments(){
      this.compartmentService.getAllCompartments().subscribe( 
        (response : ICompartmentDto[])=>{
          this.compartments = response;
        }) 
    }

    fillGraveNoInCleanings(): void {
      this.cleanings.forEach(cleaning => {
        if (cleaning.transport?.toGraveId) {
          this.graveService.getGraveById(cleaning.transport.toGraveId).subscribe(
            (response: IGraveDto) => {
              if(cleaning.transport){
                cleaning.transport.toGraveNo = response.graveNo;
              }
            },
            error => {
              console.error('Error fetching grave details', error);
            }
          );
        }
      });
    }

    printBurialPermitTransport(cleaning: ICleaningDto){

      this.fillBurialPermitPreview(cleaning);

      const dialogRef = this.dialog.open(BurialTemplateComponent, {      
        data: this.burialPermitPreview,
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });

    }

    fillBurialPermitPreview(cleaning: ICleaningDto) {

      const burial = this.burials.find(burial => burial.burialId === cleaning.transport?.burialId);
      console.log(burial)
    
      const transportItem = this.cleanings.find(item => item.burialId === cleaning.burialId);
      console.log(transportItem)
    
      // If a burial and transport are found, populate burialPermitPreview
      if (burial && transportItem) {
        this.burialPermitPreview = {
          // STEP 1
          idCardNumber: burial?.deceased?.idCardNumber ?? '',
          name: burial?.deceased?.name ?? '',
          surname: burial?.deceased?.surname ?? '',
          locality: this.localities.find(loc => loc.id === burial?.localityId)?.description ?? '',
          address: burial?.deceased.address ?? '',
          profession: burial?.deceased?.profession ?? '',
          dateOfBirth: new Date(burial?.deceased?.dateOfBirth) ?? new Date(),
          placeOfBirth: burial?.deceased?.placeOfBirth ?? '',
          maritalStatus: this.statuses.find(mar => mar.id === burial?.deceased.maritalStatusId)?.description ?? '',
          gender: this.genders.find(gen => gen.id === burial?.deceased.genderId)?.description ?? '',
          spouseName: burial?.deceased?.spouseName ?? '',
          spouseSurname: burial?.deceased?.spouseSurname ?? '',
          fatherName: burial?.deceased?.fatherName ?? '',
          fatherSurname: burial?.deceased?.fatherSurname ?? '',
          fatherStatus: burial?.deceased?.fatherStatus ? burial.deceased.fatherStatus : 'N/A',
          motherName: burial?.deceased?.motherName ?? '',
          motherSurname: burial?.deceased?.motherSurname ?? '',
          motherStatus: burial?.deceased?.motherStatus ? burial.deceased.fatherStatus : 'N/A',
    
          // STEP 2
          burialType: this.burialTypes.find(bur => bur.id === burial?.burialTypeId)?.description ?? '',
          burialTypeId: 5,
          dateOfDeath: new Date(burial?.deathDate) ?? new Date(),
          timeOfDeath: burial?.timeOfDeath ?? '',
          placeOfDeath: burial?.placeOfDeath ?? '',
          causeOfDeath: burial?.causeOfDeath ?? '',
          massTime: burial?.massTime ?? '',
          massLocality: this.massLocalities.find(mass => mass.id === burial?.massLocalityId)?.description ?? '',
          dateOfBurial: new Date(burial?.burialDate) ?? new Date(),
          burialRight: transportItem?.burialRights ?? '',
          undertaker: '',
          issuedDate: new Date(burial?.issuedDate) ?? new Date(),
    
          // STEP 3
          cemeteryName: this.cemeteries.find(cem => cem.code === burial?.cemeteryId)?.name ?? '',
          divisionName: this.divisions.find(div => div.id === burial?.divisionId)?.description ?? '',
          sectionName: this.sections.find(sec => sec.id === burial?.sectionId)?.description ?? '',
          compartmentName: this.compartments.find(com => com.id === burial?.compartmentId)?.description ?? '',
          graveNo: burial?.graveNo ?? '',
          levelName: burial?.levelId.toString() ?? '',
          username: '',
          country: burial?.country ?? '',
          burialLocation: burial?.burialLocation ?? '',
          healthOfficer: burial?.healthOfficer ?? '',
    
          // PERMIT NO
          permitNo: burial?.permitNumber ?? '',
          remarks: burial?.remarks ?? '',
    
          // CLEANING AND TRANSPORT DETAILS
          dateCleaned: new Date(transportItem?.cleaningDate)?? new Date(),
          dateTransported: new Date(),
          transportedFrom: '',
          transportedTo: transportItem.transport?.toDestination ?? '',
          cemeteryNameTo: this.cemeteries.find(cem => cem.code === transportItem.transport?.toCemeteryId)?.name ?? '',
          divisionNameTo: this.divisions.find(div => div.id === transportItem.transport?.toDivisionId)?.description ?? '',
          sectionNameTo: this.sections.find(sec => sec.id === transportItem.transport?.toSectionId)?.description ?? '',
          compartmentNameTo: this.compartments.find(com => com.id === transportItem.transport?.toCompartmentId)?.description ?? '',
          graveNoTo: transportItem.transport?.toGraveNo ?? '',
          graveLevelTo: transportItem.transport?.toLevelId?.toString() ?? '',
          transportTypeId: transportItem.transport?.transportTypeId ?? 0,
    
          // REFNO
          refNo: burial?.refNo ?? ''

        };
      } else {
        console.error('Transport doesnt found');
      }
    }

    resetTransport(): ITransportDto {
      return {
        id: 0,
        transportId: 0,
        transportDate: new Date(),
        cleaningTransportStatusId: 0,
        reference: '',
        transportPermit: '',
        burialId: 0,
        fromCemeteryId: 0,
        fromDivisionId: 0,
        fromSectionId: 0,
        fromCompartmentId: 0,
        fromGraveId: 0,
        fromLevelId : 0,
        toCemeteryId: 0,
        toDivisionId: 0,
        toSectionId: 0,
        toCompartmentId: 0,
        toGraveId: 0,
        toGraveReference: '',
        toLevelId: 0,
        toDestination: '',
        burialDate: new Date(),
        removeAuth: '',
        removeAuthId: '',
        newAuth: '',
        newAuthId: '',
        supervisorId: 0,
        undertakerId: 0,
        providerId: 0,
        feePaid: false,
        address: '',    
        transportStatusDescription: '',
        supervisorName: '',
        supervisorSurname: '',   
        providerName: '',
       // transportRate: 0,
        feeId: 0,
        transportTypeId : 0,
      };

    }
    
    filterUndertakers(event: Event) {
      const inputValue = (event.target as HTMLInputElement).value.toLowerCase();
      
      this.filteredUndertakers = this.undertakers.filter(undertaker => 
        (undertaker.name?.toLowerCase().includes(inputValue) || false) || 
        (undertaker.surname?.toLowerCase().includes(inputValue) || false)
      );
    }
     // Display the selected undertaker's name in the input field
     displayUndertaker = (id: number) => {
      if (!this.undertakers || this.undertakers.length === 0) return '';
      const undertaker = this.undertakers.find(u => u.id === id);
      return undertaker ? `${undertaker.name} ${undertaker.surname}` : '';
    };

  onBurialRightSelected(event: any): void {
    const selectedOption = event.option?.value;
    const rightBurialControl = this.cleaningFormGroup.get('burialRightsCtrl');
    if (rightBurialControl) {
      rightBurialControl.setValue(selectedOption);
    }
  }

  private _filter2(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.rightOptions.filter(rightoption => rightoption.toLowerCase().includes(filterValue));
  }

  newCleaning(){
    this.graveService.getGraveById(this.graveId).subscribe((response : IGraveDto) => {

      const dialogRef = this.dialog.open(NewCleaningComponent, {            
        data : response
      });
      dialogRef.afterClosed().subscribe(result => {        
        console.log('The dialog was closed');       
        this.internalService.emitRefreshCleaningTable();
      });
    }, (error) => {
      console.error('something went wrong: ',error)

    })
  }
}


