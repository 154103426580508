import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DivisionComponent } from './division/division.component';
import { SectionComponent } from './section/section.component';
import { CompartmentComponent } from './compartment/compartment.component';
import { CemeteryDetailsComponent } from './cemetery-details/cemetery-details.component';
import { GraveComponent } from './grave/grave.component';
import { GraveOwnersComponent } from './grave/grave-owners/grave-owners.component';
import { RoleGuard } from '../guards/roles.guard';


const routes: Routes = [
 
  { path: 'cemetery/cemetery-details', 
    component: CemeteryDetailsComponent, 
    title: 'Cemetery Details',
    canActivate: [RoleGuard], 
    data: { 
      moduleName: 'CemeteryDetailsComponent', 
      accessLevel: 'Editor' }
  },
  { path: 'cemetery/division/divisiondetails', 
    component: DivisionComponent, 
    title: 'Division Details',
    canActivate: [RoleGuard], 
    data: { 
      moduleName: 'DivisionComponent', 
      accessLevel: 'Editor' }

  },
  { path: 'cemetery/section/sectiondetails', 
    component: SectionComponent, 
    title: 'Section Details',
    canActivate: [RoleGuard], 
    data: { 
      moduleName: 'SectionComponent', 
      accessLevel: 'Editor' }

  },
  { path: 'cemetery/compartment/compartmentdetails', 
    component: CompartmentComponent, 
    title: 'Compartment Details',
    canActivate: [RoleGuard], 
    data: { 
      moduleName: 'CompartmentComponent', 
      accessLevel: 'Editor' }

  },
  { path: 'cemetery/grave/gravedetails', 
    component: GraveComponent, 
    title: 'Grave Details',
    canActivate: [RoleGuard], 
    data: { 
      moduleName: 'GraveComponent', 
      accessLevel: 'Editor',
    }
  },
  { path: 'cemetery/grave/graveowners', 
    component: GraveOwnersComponent, 
    title: 'Grave Owners' ,
    canActivate: [RoleGuard], 
    data: { 
      moduleName: 'GraveOwnersComponent', 
      accessLevel: 'Editor' }
  },
//   { path: '**', redirectTo: 'Division Details' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CemeteryRoutingModule {}
