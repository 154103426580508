import { Injectable } from '@angular/core';
import { UserPermissionsDto } from '../models/UserPermissionsDto.model';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {
  private userEmail: string = '';

  constructor() { }

  setUserEmail(email: string): void {    
    this.userEmail = email;
  }

  
  getUserEmail(): string | null {

    return this.userEmail;
  }
  
}
