 import { Injectable } from '@angular/core';
 import { HttpClient, HttpResponse } from '@angular/common/http';
 import { catchError, map, Observable, tap, throwError } from 'rxjs';
 import { Module } from 'src/app/models/module.model';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';
import { RoleModule } from '../models/role-module.model';
import { IResponseModal } from '../models/ResponseModal.model';
import { RoleModuleDto } from '../models/RoleModuleDto.model';

 @Injectable({
   providedIn: 'root'
 })
 export class ModuleService {
private baseUrl = `${environment.apiUrl}modules`; 
   constructor(private http: HttpClient, private authService: AuthService) {}

   getModules(): Observable<Module[]> {
    const headers = this.authService.getAuthHeaders();
     return this.http.get<Module[]>(this.baseUrl,{headers});
   }

   getModule(id: number): Observable<Module> {
    const headers = this.authService.getAuthHeaders();
     return this.http.get<Module>(`${this.baseUrl}/${id}`,{headers});
   }

   createModule(module: Module): Observable<Module> {
     const headers = this.authService.getAuthHeaders();
     return this.http.post<Module>(this.baseUrl, module,{headers});
   }

   updateModule(module: Module): Observable<Module> {
     const headers = this.authService.getAuthHeaders();
     return this.http.put<Module>(`${this.baseUrl}/${module.id}`, module,{headers});
   }

   deleteModule(id: number): Observable<void> {
     const headers = this.authService.getAuthHeaders();
     return this.http.delete<void>(`${this.baseUrl}/${id}`,{headers});
   }


   addModuleToRole(cleaningsDto: RoleModuleDto[]): Observable<IResponseModal> {
    const url = `${environment.apiUrl}roles`; 
    const headers = this.authService.getAuthHeaders();
    return this.http.post<HttpResponse<IResponseModal>>(url+ '/AddModules', cleaningsDto,{ observe: 'response',headers }).pipe(
      tap(response => {
        console.log('status code addModuleToRole :' +response.status)
       
      }),
      map(response => {
        let modal: IResponseModal = {
          id: 0,
          title: 'Role-Modules',
          message: '',
          
        };
  
        if (response.status === 200) {
          modal.message = 'Modules added to role';
        } else {
          modal.message = `Unexpected status code: ${response.status}`;
          throw new Error(`Unexpected status code: ${response.status}`);
        }
        
        return modal;
      }),
      catchError(error => {
        console.error('Error occurred:', error);
        return throwError(error);
      })
    );
   }
 }