<div class="body-container">
  <div class="header_container">
    <mat-divider></mat-divider>
    <h1>Access Modules</h1>
    <mat-divider></mat-divider>
  </div>

  <!-- Left Menu Bar -->
  <div class="container">
    <div class="form">
      <div class="box">
        <app-menu-bar fxShow="true" fxHide.lt-md></app-menu-bar>
      </div>
    </div>

    <!-- Main Content Section -->
    <div class="form">
      <div class="button-container">
        <!-- Role Selection Dropdown -->
        <mat-form-field appearance="fill">
          <mat-label>Select Role</mat-label>
          <mat-select [(ngModel)]="selectedRole" (selectionChange)="onRoleChange($event.value)">
            <mat-option *ngFor="let role of roles" [value]="role.id">{{ role.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Table Container: Modules -->
      <div class="table-container">
        <table mat-table *ngIf="selectedRole" [dataSource]="modules" class="mat-elevation-z8">
          
          <!-- Module Name Column -->
          <ng-container matColumnDef="moduleName">
            <th mat-header-cell *matHeaderCellDef> Module Name </th>
            <td mat-cell *matCellDef="let module"> {{ module.moduleName }} </td>
          </ng-container>

          <!-- Access Level Column -->
          <ng-container matColumnDef="accessLevel">
            <th mat-header-cell *matHeaderCellDef> Access Level </th>
            <td mat-cell *matCellDef="let module">
              <mat-radio-group [(ngModel)]="selectedAccessLevels[module.moduleId]" (ngModelChange)="onAccessLevelChange(module.moduleId, $event)">
                <mat-radio-button value="Read">Read</mat-radio-button>
                <mat-radio-button value="Write">Write</mat-radio-button>
                <mat-radio-button value="Full">Full</mat-radio-button>
              </mat-radio-group>
            </td>
          </ng-container>

          <!-- Header and Row Definitions -->
          <tr mat-header-row *matHeaderRowDef="['moduleName', 'accessLevel']"></tr>
          <tr mat-row *matRowDef="let row; columns: ['moduleName', 'accessLevel']"></tr>
        </table>
      

      <!-- Save Button -->
      <div class="button-container">
        <button mat-raised-button color="primary" (click)="saveModuleAssignments()">Save Changes</button>
      </div>
    </div>
  </div>
</div>
</div>