<div class="container">
    <h1>Grave Owner Details</h1>
  </div>
  <div class="container">
    <div class="form">
      <div class="box">
        <app-menu-bar fxShow="true" fxHide.lt-md></app-menu-bar>
      </div>
    </div>
    <div class="form">
      <form>
         <!--TABLE FILTER-->
    <div id="searchandfilter_box" class="box">          
      <mat-form-field class="mat-form-field">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Grave Number" #input>
      </mat-form-field>
    </div>
        <mat-divider></mat-divider>
        <div class="box">
          <div class="table-container mat-elevation-z8">
            <!-- <div *ngIf="isLoadingResults || isRateLimitReached" class="table-loading-shade">
              <div *ngIf="isLoadingResults">
                <mat-spinner></mat-spinner>
              </div>
              <div *ngIf="isRateLimitReached" class="table-rate-limit-reached">
                Loading...
              </div>
            </div> -->
          </div>
        <div class="table-table-container mat-elevation-z8">
            <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef >ID Card</th>
                <td mat-cell *matCellDef="let ownerObject">{{ownerObject.id}}</td>
              </ng-container>
    
              <ng-container matColumnDef="Gname">
                <th mat-header-cell *matHeaderCellDef >Name</th>
                <td mat-cell *matCellDef="let ownerObject">{{ownerObject.Gname}}</td>
              </ng-container>
    
              <ng-container matColumnDef="Gsurname">
                <th mat-header-cell *matHeaderCellDef >Surname</th>
                <td mat-cell *matCellDef="let ownerObject">{{ownerObject.Gsurname}}</td>
              </ng-container>

              <ng-container matColumnDef="DHfileNo">
                <th mat-header-cell *matHeaderCellDef  fxShow="true" fxHide.lt-md>D/H FileNo</th>
                <td mat-cell *matCellDef="let ownerObject" fxShow="true" fxHide.lt-md>{{ownerObject.DHfileNo}}</td>
              </ng-container>

              <ng-container matColumnDef="OwnershipDate">
                <th mat-header-cell *matHeaderCellDef fxShow="true" fxHide.lt-md>Ownership Date</th>
                <td mat-cell *matCellDef="let ownerObject" fxShow="true" fxHide.lt-md>{{formatDate(ownerObject.ownershipDate)}}</td>
              </ng-container>

              <ng-container matColumnDef="telephone">
                <th mat-header-cell *matHeaderCellDef  fxShow="true" fxHide.lt-md>Telephone</th>
                <td mat-cell *matCellDef="let ownerObject" fxShow="true" fxHide.lt-md>{{ownerObject.telephone}}</td>
              </ng-container>
    
              <ng-container matColumnDef="expand">
                <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                <td mat-cell *matCellDef="let ownerObject">
                  <button mat-icon-button aria-label="expand row"  (click)="expandCollapse(ownerObject); $event.stopPropagation()" >
                    <ng-container *ngIf="expandedElement === ownerObject">
                        <mat-icon>keyboard_arrow_up</mat-icon>
                    </ng-container>
                    <ng-container *ngIf="expandedElement !== ownerObject">
                        <mat-icon>keyboard_arrow_down</mat-icon>
                    </ng-container>
                </button>
                </td>
              </ng-container>
              <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
              <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let ownerObject" [attr.colspan]="displayedColumnsWithExpand.length">
                  <div class="expand-detail" [@detailExpand] = "ownerObject == expandedElement ? 'expanded' : 'collapsed' ">
                    <div class="expand-category">
                      <div class="expand-field">Grave Reference No : &nbsp;<span>{{ownerObject.referenceNo}}</span>
                        Date Of Application : &nbsp;<span>{{formatDate(ownerObject. dateOfApplication)}}</span></div>
                      <div class="expand-field">Address :&nbsp;<span>{{ownerObject.address}}</span>
                       Locality: &nbsp; <span>{{ownerObject.locality}}</span></div>
                       <div class="expand-field">Cemetery Name : &nbsp;<span>{{ownerObject.cemeteryName}}</span></div>
                      <div class="expand-field">Grave Number : &nbsp; <span>{{ownerObject.GNo}}</span></div>
                    </div>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef >Action</th>
                <td mat-cell *matCellDef="let ownerObject">
                  <button mat-icon-button color="primary" (click)="editGraveOwnerClick(ownerObject)">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button mat-icon-button color="primary" (click)="viewGraveOwnerClick(ownerObject)">
                    <mat-icon>view_list</mat-icon>
                  </button>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumnsWithExpand"></tr>
              <tr mat-row *matRowDef="let ownerObject; columns: displayedColumnsWithExpand;" class="expand-Inside"
                [class.example-expanded-row]="expandedElement != ownerObject" ></tr>
              <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" matRipple class="expand-Rowdetails"></tr>           
            </table>
        <mat-paginator [length]="resultsLength" [pageSize]="25" aria-label="Select page of burial permits"></mat-paginator>
      </div>
        </div>
        <div class="buttonnew">
          <button mat-raised-button  color="primary" (click)="NewGraveOwners()">
            New Grave Owners
          </button>
        </div>
    </form>
  </div>
</div>



     

