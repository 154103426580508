<div class="dialog-header">
  <button mat-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="container-popup">
  <div class="form">
    <span class="mat-title" mat-dialog-title><b>Add New Cemetery </b> </span>
    <form [formGroup]="CemeteryNewForm">
     
      <mat-dialog-content>
        <div class="box">
          <mat-form-field>
            <mat-label>Cemetery</mat-label>
            <input matInput placeholder="Cemetery" formControlName="cemeteryCtrl"/>
            <mat-error>
              <span *ngIf="CemeteryNewForm.get('cemeteryCtrl')?.value && CemeteryNewForm.get('cemeteryCtrl')?.invalid && CemeteryNewForm.get('cemeteryCtrl')?.errors?.['pattern']">Cemetery name must contain only letters</span>
              <span *ngIf="!CemeteryNewForm.get('cemeteryCtrl')?.value">Field required</span>
            </mat-error>     
          </mat-form-field>
          <mat-form-field>
            <mat-label>Cemetery Type</mat-label>
              <mat-select formControlName="cemeteryTypeCtrl" >
                <mat-option *ngFor="let cemtype of cemeteryTypes" [value]="cemtype.id">
                  {{ cemtype.description }}
                </mat-option>
              </mat-select>
              <mat-error>
                <span *ngIf="!CemeteryNewForm.get('cemeteryTypeCtrl')?.value">Field required</span>
              </mat-error>
          </mat-form-field>
        </div>
        <div class="box">
          <mat-form-field>
              <mat-label>Locality</mat-label>
              <mat-select formControlName="localityCtrl">
                <mat-option *ngFor="let locality of localities" [value]="locality.id">
                  {{ locality.description }}
                </mat-option>
              </mat-select>
              <mat-error>
                <span *ngIf="!CemeteryNewForm.get('localityCtrl')?.value">Field required</span>
              </mat-error>
            </mat-form-field>
        <mat-form-field>
          <mat-label> Contact Person</mat-label>
          <input matInput placeholder="Person Name" formControlName="contactPersonCtrl"/>
          <mat-error>
            <span *ngIf="CemeteryNewForm.get('contactPersonCtrl')?.value && CemeteryNewForm.get('contactPersonCtrl')?.invalid && CemeteryNewForm.get('contactPersonCtrl')?.errors?.['pattern']">Contact Person must contain only letters</span>
            <span *ngIf="!CemeteryNewForm.get('contactPersonCtrl')?.value">Field required</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="box">
        <mat-form-field>
          <mat-label> Telephone No.1</mat-label>
          <input matInput placeholder="Telephone No.1" formControlName="telephoneCtrl" />
          <mat-error>
            <span *ngIf="CemeteryNewForm.get('telephoneCtrl')?.value && CemeteryNewForm.get('telephoneCtrl')?.invalid && CemeteryNewForm.get('telephoneCtrl')?.errors?.['pattern']">Telephone must contain only numbers</span>
          </mat-error> 
        </mat-form-field>
        <mat-form-field>
          <mat-label> Telephone No.2</mat-label>
          <input matInput placeholder="Telephone No.2" formControlName="telephone2Ctrl"/>
          <mat-error>
            <span *ngIf="CemeteryNewForm.get('telephone2Ctrl')?.value && CemeteryNewForm.get('telephone2Ctrl')?.invalid && CemeteryNewForm.get('telephone2Ctrl')?.errors?.['pattern']">Telephone must contain only numbers </span>
          </mat-error> 
        </mat-form-field>  
      </div>
        <!-- <mat-dialog-actions> -->
          <div class="buttonalign">
            <button mat-raised-button color="primary" (click)="addNewCemetery()"><mat-icon>check</mat-icon> Save</button>
            <button mat-raised-button style="background-color: rgb(143, 141, 141)" [mat-dialog-close] cdkFocusInitial><mat-icon>close</mat-icon> Close</button>
          </div>
        <!-- </mat-dialog-actions> -->
      </mat-dialog-content>
    </form>
  </div>
</div>
