<div class="container">
  <div class="form">
    <form [formGroup]="SectionEditForm">
      <span class="mat-title" mat-dialog-title><b>Edit Section Details</b></span>
      <mat-dialog-content>
        <div class="box">
          <mat-form-field>
            <mat-label>Section Id</mat-label>
            <input matInput placeholder="Section Id" formControlName="sectionCtrl" />
          </mat-form-field>
        </div>
        <div class="box">
          <mat-form-field>
            <mat-label>Section Name</mat-label>
            <input matInput placeholder="Section Name" formControlName="sectionNameCtrl" />
          </mat-form-field>
        </div>

        <mat-dialog-actions>
          <div class="divspace">
            <button mat-raised-button color="primary" (click)="onSectionUpdate()">Update</button>
            <button mat-raised-button style="color: dimgray" [mat-dialog-close] cdkFocusInitial> Close </button>
          </div>
        </mat-dialog-actions>
      </mat-dialog-content>
    </form>
  </div>
</div>
