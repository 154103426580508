import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ITableCountDto } from '../burials/models/ITableCountDto';
import { Observable, catchError, map, tap, throwError } from 'rxjs';
import { ICleaningDto } from '../models/CleaningDto.model';
import { IResponseModal } from '../models/ResponseModal.model';
import { IAddCleaningDto } from '../models/AddCleaningDto.model';
import { IUpdateCleaningDto } from '../models/UpdateCleaningDto.model';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class CleaningService {

  private baseUrl = `${environment.apiUrl}cleaning/`;
  constructor(private http: HttpClient, private authService: AuthService) { }

  getAllCleaning(filterCriteria: string | null, searchQuery: string | null, pageNumber: number, pageSize: number,graveId : number = 0 ): Observable<ITableCountDto> {    
    let params = new HttpParams();
    const headers = this.authService.getAuthHeaders();
    
    if (filterCriteria) {
        params = params.append('filterCriteria', filterCriteria);
    }
    if (searchQuery) {
        params = params.append('searchQuery', searchQuery);
    }
   
    params = params.append('pageNumber', pageNumber.toString());
    params = params.append('pageSize', pageSize.toString());
    if (graveId) {
      params = params.append('graveId', graveId);
  }

    return this.http.get<ITableCountDto>(this.baseUrl + 'GetAllCleaning', { params,headers })
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };

  getAllCleaningStatus(filterCriteria: string | null, searchQuery: string | null, pageNumber: number, pageSize: number ): Observable<ITableCountDto> {    
    let params = new HttpParams();
    const headers = this.authService.getAuthHeaders();
    
    if (filterCriteria) {
        params = params.append('filterCriteria', filterCriteria);
    }
    if (searchQuery) {
        params = params.append('searchQuery', searchQuery);
    }
   
    params = params.append('pageNumber', pageNumber.toString());
    params = params.append('pageSize', pageSize.toString());

    return this.http.get<ITableCountDto>(this.baseUrl + 'GetAllCleaningStatus', { params, headers })
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };

  updateCleaning(id: number, cleaningDto: IUpdateCleaningDto): Observable<IResponseModal> {
    const url = `${this.baseUrl}${id}`;
    const headers = this.authService.getAuthHeaders();

    return this.http.put<HttpResponse<IResponseModal>>(url, cleaningDto, { observe: 'response',headers }).pipe(
      map(response => {
        
        let modal: IResponseModal = {
          id: 0,
          title: 'Update Cleaning',
          message: ''
        };
        if (response.status === 204) {  
          
          modal.message = 'Record Updated';

        } else if (response.status === 200) {    
          
          modal.message = 'To update an owner at least one field needs to change, currently not changes have been registered.';

        } else {
          modal.message =`Unexpected status code: ${response.status}`;
          
          throw new Error(`Unexpected status code: ${response.status}`);
        }
        return modal;
      })
    );
  }
  updateAllCleanings(cleaningsDto: IUpdateCleaningDto[]): Observable<IResponseModal> {
    const url = `${this.baseUrl}` + 'UpdateAllCleanings';
    const headers = this.authService.getAuthHeaders();

    return this.http.put<HttpResponse<IResponseModal>>(url, cleaningsDto, { observe: 'response',headers }).pipe(
      map(response => {
        
        let modal: IResponseModal = {
          id: 0,
          title: 'Update Cleaning',
          message: ''
        };
        if (response.status === 204) {  
          
          modal.message = 'Record Updated';

        } else if (response.status === 200) {    
          
          modal.message = 'To update an owner at least one field needs to change, currently not changes have been registered.';

        } else {
          modal.message =`Unexpected status code: ${response.status}`;
          
          throw new Error(`Unexpected status code: ${response.status}`);
        }
        return modal;
      })
    );
  }
  carryOut(cleaningsDto: ICleaningDto[]): Observable<IResponseModal> {
    const headers = this.authService.getAuthHeaders();
    return this.http.post<ICleaningDto[]>(this.baseUrl + 'CarryoutCleaning', cleaningsDto,{ observe: 'response',headers }).pipe(
      tap(response => {
       
      }),
      map(response => {
        let modal: IResponseModal = {
          id: 0,
          title: 'Cleeanings/Transport',
          message: '',
          entity : response.body
        };
  
        if (response.status === 200) {
          modal.message = 'Processed !';
        } else {
          modal.message = `Unexpected status code: ${response.status}`;
          throw new Error(`Unexpected status code: ${response.status}`);
        }
        
        return modal;
      }),
      catchError(error => {
        console.error('Error occurred:', error);
        return throwError(error);
      })
    );
  }
  addCleaning(addCleaningDto: IAddCleaningDto): Observable<IResponseModal> {
    const headers = this.authService.getAuthHeaders();
    return this.http.post<ICleaningDto >(this.baseUrl + 'AddCleaning', addCleaningDto,{ observe: 'response',headers }).pipe(
      tap(response => {
        
      }),
      map(response => {
        let modal: IResponseModal = {
          id: 0,
          title: 'Add Cleaning',
          message: '',
          entity : response.body
        };
  
        if (response.status === 201) {
          modal.message = 'All Cleanings Scheduled';
        } else {
          modal.message = `Unexpected status code: ${response.status}`;
          throw new Error(`Unexpected status code: ${response.status}`);
        }
        
        return modal;
      }),
      catchError(error => {
        console.error('Error occurred:', error);
        return throwError(error);
      })
    );
  }
  deleteCleaning(id: number): Observable<IResponseModal> {
    const headers = this.authService.getAuthHeaders();
    const url = `${this.baseUrl}${id}`;
    return this.http.delete<HttpResponse<IResponseModal>>(url, { observe: 'response',headers }).pipe(
      tap(response => {
        console.log('Delete Cleaning' + response.status);
      }),
      map(response => {
        let modal: IResponseModal = {
          id: 0,
          title: 'Delete Cleaning',
          message: ''
        };
  
        if (response.status === 204) {
          modal.message = 'Cleaning deleted';
        } else {
          modal.message = `Unexpected status code: ${response.status}`;
          throw new Error(`Unexpected status code: ${response.status}`);
        }
        
        return modal;
      }),
      catchError(error => {
        console.error('Error occurred:', error);
        return throwError(error);
      })
    );
  }

  existsBurialInCleaningAsync(id:number): Observable<boolean> {  
    const headers = this.authService.getAuthHeaders(); 
    const endpoint = 'ExistsBurialInCleaningAsync'; 
    const url = `${this.baseUrl}${endpoint}?graveId=${id}`;
    return this.http.get<boolean>(url,{headers})
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };

}
