import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { IUndertakerDto } from '../models/UndertakerDto.model';
import { IResponseModal } from '../models/ResponseModal.model';
import { IAddNewUndertakerDto } from '../models/AddNewUndertakerDto.model';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';


@Injectable({
  providedIn: 'root'
})
export class UndertakerService {

  
  private baseUrl = `${environment.apiUrl}undertaker/`;

  constructor(private http: HttpClient, private authService: AuthService) { }
  
    getAllUndertakers(): Observable<IUndertakerDto[]> { 
    const headers = this.authService.getAuthHeaders();
    return this.http.get<IUndertakerDto[]>(this.baseUrl + 'GetAllUndertakers',{headers})
    .pipe(
      map((undertakers: IUndertakerDto[]) => 
        undertakers.sort((a, b) => {
            const surnameComparison = (a.surname ?? "").localeCompare(b.surname ?? "");
            return surnameComparison !== 0 ? surnameComparison : (a.name ?? "").localeCompare(b.name ?? "");
        })
    ),
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };

  getUndertakerById(id:number): Observable<IUndertakerDto> { 
    const headers = this.authService.getAuthHeaders();   
    const url = `${this.baseUrl}${id}`;
    return this.http.get<IUndertakerDto>(url,{headers})
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };

  updateUndertakerDetails(id: number, UndertakerUpdateDto: IUndertakerDto): Observable<IResponseModal> {
    const url = `${this.baseUrl}${id}`;
    const headers = this.authService.getAuthHeaders();
    return this.http.put< IResponseModal>(url , UndertakerUpdateDto,{ observe: 'response',headers }).pipe(
        map(response => {
          
          let modal: IResponseModal = {
            id: 0,
            title: 'Record Updated',
            message: ''
          };
          if (response.status === 204) {  
            
            modal.message = 'Updated Successfully';
  
          } else {
            modal.message =`Unexpected status code: ${response.status}`;
            
            throw new Error(`Unexpected status code: ${response.status}`);
          }
          return modal;
        })
      );
    }


  addNewUndertaker(addNewUndertakerDto: IAddNewUndertakerDto): Observable<IResponseModal> {
    const headers = this.authService.getAuthHeaders();
    return this.http.post<IResponseModal>(this.baseUrl + 'AddUndertaker', addNewUndertakerDto,{headers})
        .pipe(
            map(response => {
                let modal: IResponseModal = {
                    id:0,
                    title: 'Record Added', 
                    message: 'Added Successfully'
                };
                return modal;
            }),
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
}
  deleteUndertaker(id : number) : Observable<IResponseModal>{
    const url = `${this.baseUrl}${id}`;
    const headers = this.authService.getAuthHeaders();
    return this.http.delete<IResponseModal>(url,{headers})
    .pipe(
      map(response => {
          let modal: IResponseModal = {
              id:0,
              title: 'Record Deleted', 
              message: 'Deleted Successfully '
          };
          return modal;
      }),
      catchError(error => {
          console.error('Error:', error);
          throw error;
      })
  );
}
}
