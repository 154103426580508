import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { IBurialPermitDto } from '../models/BurialPermitDto.model';
import { BurialPermitService } from 'src/app/services/burial-permit.service';
import { ITableCountDto } from '../models/ITableCountDto';
import { DatePipe } from '@angular/common';
import { LocalityService } from 'src/app/services/locality.service';
import { GenderService } from 'src/app/services/gender.service';
import { MaritalStatusService } from 'src/app/services/marital-status.service';
import { UndertakerService } from 'src/app/services/undertaker.service';
import { ILocalityDto } from 'src/app/models/LocalityDto.model';
import { IGenderDto } from 'src/app/models/Gender.model';
import { IUndertakerDto } from 'src/app/models/UndertakerDto.model';
import { IMaritalStatusDto } from 'src/app/models/MaritalStatusDto.model';

@Component({
  selector: 'app-burial-lastburial',
  templateUrl: './burial-lastburial.component.html',
  styleUrls: ['./burial-lastburial.component.css']
})
export class BurialLastburialComponent {

  @Input() graveId!: number;

  burialPermits! : IBurialPermitDto[];
  lastBurial : IBurialPermitDto | null = null;;
  lastBurialFormGroup! : FormGroup;

  massLocalities : ILocalityDto[] = [];
  genders : IGenderDto[] = [];
  statuses : IMaritalStatusDto[] = [];
  undertakers : IUndertakerDto[]=[];

  selectedMassLocalityDescription!: string;
  selectedGenderDescription!: string;
  selectedMaritalStatusDescription !: string;
  selectedUndertakerDescription!: string;

  constructor(

    private _formBuilder: FormBuilder,
    private burialPermitService : BurialPermitService,
    private datePipe: DatePipe,
    private localityService : LocalityService,
    private genderService : GenderService,
    private maritalStatusService : MaritalStatusService,
    private undertakersService : UndertakerService,

  ){}

  ngOnInit(): void{
    this.getLastBurial();
  }

  formatDate(date: string | null | undefined | Date): string {
    if (date) {
      const parsedDate = new Date(date);
      return this.datePipe.transform(parsedDate, 'dd/MM/yyyy') || '';
    }
    return ''; // Return empty string if date is null or undefined
  }

  getLastBurial(){

    this.burialPermitService.getBurialPermits("","",null,null, 1, 100,this.graveId)
        .subscribe((response :ITableCountDto) => {
    
          this.burialPermits = response.results;

          this.lastBurial = this.getMostRecentBurialPermit(this.burialPermits)
          
          this.getAllMassLocalities();
          this.getAllGender();
          this.getAllMaritalStatus();
          this.getAllUndertakers();

        }, (error) => {
          console.error(error);
        })

  }

  getMostRecentBurialPermit(burialPermits: IBurialPermitDto[]) {
    if (burialPermits.length === 0) {
      return null;
    }
  
    const elementWithMostRecentDate = burialPermits.reduce((mostRecent, current) => {
      return new Date(current.burialDate) > new Date(mostRecent.burialDate) ? current : mostRecent;
    }, burialPermits[0]);
  
    return elementWithMostRecentDate;
  }

  getAllMassLocalities(){
    this.localityService.getAllLocalities().subscribe(
      (response: ILocalityDto[]) => {  
        this.massLocalities = response;
        
        const selectedMassLocalityId = this.lastBurial?.massLocalityId;
        const selectedMassLocality = this.massLocalities.find(m => m.id === selectedMassLocalityId);    
        this.selectedMassLocalityDescription = selectedMassLocality ? selectedMassLocality.description : '';
      },
      (error) => {        
        console.error('Error adding localities: ', error);        
      }
      );
  }
  
  getAllGender(){
    this.genderService.getAllGender().subscribe(
      (response: IGenderDto[]) => {
        this.genders = response;
        
        const selectedGenderId = this.lastBurial?.deceased.genderId;
        const selectedGender = this.genders.find(g => g.id === selectedGenderId);    
        this.selectedGenderDescription = selectedGender ? selectedGender.description : '';

      },
      (error) => {        
        console.error('Error retrieving genders: ', error);        
      }
      );
  }
  getAllMaritalStatus(){
    this.maritalStatusService.getAllMaritalStatus().subscribe(
      (response: IMaritalStatusDto[]) => {
        this.statuses = response;   
        
        const selectedMaritalId = this.lastBurial?.deceased.maritalStatusId;
        const selectedMarital = this.statuses.find(s => s.id === selectedMaritalId);    
        this.selectedMaritalStatusDescription = selectedMarital ? selectedMarital.description : '';
      },
      (error) => {        
        console.error('Error retrieving marital status: ', error);        
      }
      );
  }

  getAllUndertakers(){
    this.undertakersService.getAllUndertakers().subscribe(
      (response : IUndertakerDto[]) => {
        this.undertakers = response;

        const selectedUndertakerId = this.lastBurial?.undertakerId;
        const selectedUndertaker = this.undertakers.find(u => u.id === selectedUndertakerId);    
        this.selectedUndertakerDescription = selectedUndertaker ? selectedUndertaker.name : '';
      }, (error) =>{
        console.error('Error retrieving undertakers');
      } 
      );
  }

}

