import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ICemetery } from 'src/app/models/Cemetery.model';
import { ICemeteryDto } from 'src/app/models/CemeteryDto.model';
import { ICemeteryTypeDto } from 'src/app/models/CemeteryTypeDto.model';
import { ILocalityDto } from 'src/app/models/LocalityDto.model';
import { IResponseModal } from 'src/app/models/ResponseModal.model';
import { ResponsemodalComponent } from 'src/app/responsemodal/responsemodal.component';
import { CemeteryService } from 'src/app/services/cemetery.service';
import { InternalCommunicationService } from 'src/app/services/internal-communication.service';
import { LocalityService } from 'src/app/services/locality.service';

@Component({
  selector: 'app-cemetery-details-edit',
  templateUrl: './cemetery-details-edit.component.html',
  styleUrls: ['./cemetery-details-edit.component.css']
})
export class CemeteryDetailsEditComponent  implements OnInit{

  selectedCemeteryTypeDescription!: string;
  cemeteryData! : ICemeteryDto;
  localities : ILocalityDto[] = [];
  cemTypeObj !:ICemeteryTypeDto;
  CemeteryEditForm!: FormGroup;
  cemeteryTypes: ICemeteryTypeDto[] = [];
  cemeteryDetails:ICemeteryDto[] = [];
  cemetery : ICemeteryDto = 
    {
      code: 0,
      name: '',
      address: '',
      localityId: 0,
      cemeteryTypeId : 0,
      contactPerson: '',
      telephone: '',
      telephone2: '',
    };
 constructor(public dialog: MatDialog,
  private cemeteryService : CemeteryService,
  private localityService : LocalityService,
  private _formBuilder: FormBuilder,
  private internalService : InternalCommunicationService, 
   
   @Inject(MAT_DIALOG_DATA) public data: any)
   {
   this.cemetery =data;
   }
   ngOnInit() {
    this.getAllLocalities();
    this.getAllCemeteryType();

    this.CemeteryEditForm = this._formBuilder.group({
       cemeteryCtrl: [this.cemetery ? this.cemetery.name: '', [Validators.required, Validators.pattern(/^[a-zA-Z0-9,().'`" \/\\-]*$/)]],
        cemeteryTypeCtrl: [this.cemetery ? this.cemetery.cemeteryTypeId: '', [Validators.required, Validators.min(1)]],  
        localityCtrl: [this.cemetery ? this.cemetery.localityId: '', [Validators.required, Validators.min(1)]],  
        contactPersonCtrl : [this.cemetery ? this.cemetery.contactPerson: '', [Validators.required, Validators.pattern(/^[a-zA-Z,().'`" \/\\-]*$/)]],
        telephoneCtrl: [this.cemetery ? this.cemetery.telephone: '',[Validators.pattern('[0-9]*')]],
        telephone2Ctrl: [this.cemetery ? this.cemetery.telephone: '',[Validators.pattern('[0-9]*')]],
  });
  this.subscribeChanges();
  }
  subscribeChanges() {
    this.CemeteryEditForm.get('cemeteryCtrl')?.valueChanges.subscribe((value) => {     
      this.cemetery.name= value;
    });
    this.CemeteryEditForm.get('cemeteryTypeCtrl')?.valueChanges.subscribe((value) => {     
      this.cemetery.cemeteryTypeId= value;
    });
    this.CemeteryEditForm.get('localityCtrl')?.valueChanges.subscribe((value) => {     
      this.cemetery.localityId= value;
    });
    this.CemeteryEditForm.get('contactPersonCtrl')?.valueChanges.subscribe((value) => {     
      this.cemetery.contactPerson= value;
    });
    this.CemeteryEditForm.get('telephoneCtrl')?.valueChanges.subscribe((value) => {     
      this.cemetery.telephone= value;
    });
    this.CemeteryEditForm.get('telephone2Ctrl')?.valueChanges.subscribe((value) => {     
      this.cemetery.telephone2 = value;
    });
  
  }

 getAllLocalities(){
  this.localityService.getAllLocalities().subscribe(
    (response: ILocalityDto[]) => {
      this.localities = response;  
    },
    (error) => {        
      console.error('Error adding localities: ', error);        
    }
    );
}
getAllCemeteryType(){
  this.cemeteryService.getAllCemeteryType().subscribe(
    (response: ICemeteryTypeDto[]) => {
      this.cemeteryTypes = response;        
    },
    (error) => {        
      console.error('Error retrieving cemeteryTypes: ', error);        
    }
    );
}

   onCemeteryUpdateClick(): void {
    if (this.CemeteryEditForm.invalid) {
      this.CemeteryEditForm.markAllAsTouched(); // Show all errors
      return;
    }
  
    const id =+ this.cemetery.code;
    this.cemeteryService.updateCemeteryDetails(id, this.cemetery).subscribe(
      (response : IResponseModal) => {  
        this.openResponseModal(response);      
        this.internalService.emitRefreshCemeteryTable();   
      },
      (error) => {
        console.error('Error occurred while updating cemetery details:', error);        
      }
  )
  }

  openResponseModal(response : IResponseModal): void {
    const dialogRef = this.dialog.open(ResponsemodalComponent, {
      width: '250px',
      data : response,
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
   
}
