import { Component, ViewChild, ElementRef,Input, OnInit, AfterViewInit, EventEmitter, Output, Renderer2, Inject, Optional } from '@angular/core';
import { jsPDF } from 'jspdf';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDisintermentDto } from 'src/app/models/DisintermentDto.model';


@Component({
  selector: 'app-disinterment',
  templateUrl: './disinterment.component.html',
  styleUrls: ['./disinterment.component.css']
})
export class DisintermentComponent {

  disintermentData: any;
  
  isLoading: boolean = false;

  @ViewChild('contentToPrint',{static:false}) el!: ElementRef
  title = 'angular-pdf-print';

  graveNoAndLevel!: string;
  fullName!: string;
  idCard!: string;
  dod!: Date;
  graveAddress!: string;
  undertaker!: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any){
              this.disintermentData = data;
  }

  ngAfterViewInit() {
    this.makePDF();
  }

  makePDF() {  
    let pdf=new jsPDF({
      orientation: 'p', // or 'landscape'
      unit: 'pt',
      format: 'a4' // or specify custom dimensions { width: number, height: number }      
      });

    pdf.html(this.el.nativeElement, {
      autoPaging: 'text',
      callback: (pdf) => {        
        const pdfDataUri = pdf.output('datauristring');
        this.fillIframe(pdfDataUri);        
      }
    });
   this.loadData()
  }

  fillIframe(dataUri: string) {
    const iframe = document.getElementById('pdfPreview') as HTMLIFrameElement;
    if (iframe) {
      iframe.src = dataUri;
    }
  }

  loadData() {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 2000); 
  }

  getCurrentDate(): string {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const month = monthNames[currentDate.getMonth()];
    const year = currentDate.getFullYear();
  
    return `${day} ${month} ${year},`;
  }

  getNextYear(): number {
    const currentDate = new Date();
    const year = currentDate.getFullYear()+1;
  
    return year;
  }

}
