<div class="confirmation">
  <button mat-icon-button class="close-icon" tabindex="0" (click)="onClose()">
    <mat-icon>close</mat-icon>
  </button>
  <h1 class="h1-title" mat-dialog-title>{{ title }}</h1>
  <div mat-dialog-content>
    <p>{{ message }}</p>
  </div>
  <div mat-dialog-actions>
    <button *ngIf="!closeOnly" mat-raised-button class="confirm-button custom-padding" (click)="onConfirm()">Yes</button>
    <button *ngIf="!closeOnly" mat-raised-button class="cancel-button custom-padding" (click)="onCancel()">No</button>
    <button *ngIf="closeOnly" mat-raised-button class="close-button custom-padding" (click)="onClose()">Close</button>
  </div>
</div>
