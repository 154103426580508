import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  id: number;
  roleName: string;
  description: string;
  createdAt: Date;
}

@Component({
  selector: 'app-editroledialog',
  templateUrl: './editroledialog.component.html',
})
export class EditroleDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<EditroleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSaveClick(): void {
    this.dialogRef.close(this.data);
  }
}
