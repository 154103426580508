import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IAddNewCemeteryDto } from 'src/app/models/AddNewCemeteryDto.model';
import { ICemetery } from 'src/app/models/Cemetery.model';
import { ICemeteryDto } from 'src/app/models/CemeteryDto.model';
import { ICemeteryTypeDto } from 'src/app/models/CemeteryTypeDto.model';
import { ILocalityDto } from 'src/app/models/LocalityDto.model';
import { IResponseModal } from 'src/app/models/ResponseModal.model';
import { ResponsemodalComponent } from 'src/app/responsemodal/responsemodal.component';
import { CemeteryService } from 'src/app/services/cemetery.service';
import { InternalCommunicationService } from 'src/app/services/internal-communication.service';
import { LocalityService } from 'src/app/services/locality.service';

@Component({
  selector: 'app-cemetery-details-new',
  templateUrl: './cemetery-details-new.component.html',
  styleUrls: ['./cemetery-details-new.component.css']
})
export class CemeteryDetailsNewComponent {
  CemeteryNewForm !:FormGroup;
  cemeteryTypes: ICemeteryTypeDto[] = [];
  localities : ILocalityDto[] = [];

 
  cemetery : ICemeteryDto = 
  {
    code: 0,
    name: '',
    address: '',
    localityId: 0,
    cemeteryTypeId : 0,
    contactPerson: '',
    telephone: '',
    telephone2: '',
  };
  constructor(
    private cemeteryService : CemeteryService,
    public dialog: MatDialog,
    private localityService : LocalityService,
    private _formBuilder: FormBuilder,
    private internalService : InternalCommunicationService, 
    @Inject(MAT_DIALOG_DATA) public data: ICemetery){}
 
    ngOnInit() {
      
      this.getAllLocalities();
      this.getAllCemeteryType();
      this.CemeteryNewForm = this._formBuilder.group({
        cemeteryCtrl: [this.cemetery ? this.cemetery.name: '', [Validators.required, Validators.pattern(/^[a-zA-Z0-9,().'`" \/\\-]*$/)]],
        cemeteryTypeCtrl: [this.cemetery ? this.cemetery.cemeteryTypeId: '', [Validators.required, Validators.min(1)]],  
        localityCtrl: [this.cemetery ? this.cemetery.localityId: '', [Validators.required, Validators.min(1)]],  
        contactPersonCtrl : [this.cemetery ? this.cemetery.contactPerson: '', [Validators.required, Validators.pattern(/^[a-zA-Z,().'`" \/\\-]*$/)]],
        telephoneCtrl: [this.cemetery ? this.cemetery.telephone: '',[Validators.pattern('[0-9]*')]],
        telephone2Ctrl: [this.cemetery ? this.cemetery.telephone: '',[Validators.pattern('[0-9]*')]],
          
    });
    this.subscribeChanges();
  }
  subscribeChanges() {
    this.CemeteryNewForm.get('cemeteryCtrl')?.valueChanges.subscribe((value) => {     
      this.cemetery.name= value;
    });
    this.CemeteryNewForm.get('cemeteryTypeCtrl')?.valueChanges.subscribe((value) => {     
      this.cemetery.cemeteryTypeId= value;
    });
    this.CemeteryNewForm.get('localityCtrl')?.valueChanges.subscribe((value) => {     
      this.cemetery.localityId= value;
    });
    this.CemeteryNewForm.get('contactPersonCtrl')?.valueChanges.subscribe((value) => {     
      this.cemetery.contactPerson= value;
    });
    this.CemeteryNewForm.get('telephoneCtrl')?.valueChanges.subscribe((value) => {     
      this.cemetery.telephone= value;
    });
    this.CemeteryNewForm.get('telephone2Ctrl')?.valueChanges.subscribe((value) => {     
      this.cemetery.telephone2 = value;
    });
  
  }
  getAllLocalities(){
    this.localityService.getAllLocalities().subscribe(
      (response: ILocalityDto[]) => {
        this.localities = response;     
      },
      (error) => {        
        console.error('Error adding localities: ', error);        
      }
      );
  }
  getAllCemeteryType(){
    this.cemeteryService.getAllCemeteryType().subscribe(
      (response: ICemeteryTypeDto[]) => {
        this.cemeteryTypes = response;        
      },
      (error) => {        
        console.error('Error retrieving cemeteryTypes: ', error);        
      }
      );
  }
  openResponseModal(response : IResponseModal): void {
    const dialogRef = this.dialog.open(ResponsemodalComponent, {
      width: '250px',
      data : response,
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  addNewCemetery(){
    const addNewCemeteryDto: IAddNewCemeteryDto = this.cemetery;   
    console.log(addNewCemeteryDto)
    this.cemeteryService.addNewCemetery(addNewCemeteryDto).subscribe(
      (response: IResponseModal) => {       
        this.openResponseModal(response);
        this.internalService.emitRefreshCemeteryTable();   
      },
      (error) => {            
        console.error('Error adding new cemetery:', error);       
      }
      );
    }

}
