import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IDeceasedDto } from '../models/Deceased.model';
import { Observable, catchError, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ITableCountDto } from '../burials/models/ITableCountDto';
import { IResponseModal } from '../models/ResponseModal.model';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class DeceasedService {

  private baseUrl = `${environment.apiUrl}deceased/`; 
  constructor(private http: HttpClient, private authService: AuthService) { }

  getAllDeceased (filterCriteria: string | null, searchQuery: string | null, pageNumber: number, pageSize: number ): Observable<ITableCountDto>{ 
    const headers = this.authService.getAuthHeaders();
    let params = new HttpParams();
    if (filterCriteria) {
        params = params.append('filterCriteria', filterCriteria);
    }
    if (searchQuery) {
        params = params.append('searchQuery', searchQuery);
    }
    params = params.append('pageNumber', pageNumber.toString());
    params = params.append('pageSize', pageSize.toString());

    return this.http.get<ITableCountDto>(this.baseUrl + 'GetAllDeceased', { params,headers })
        .pipe(
           
            catchError(error => {
                console.error('Error:', error);
                throw error;
                
            })
        );
  };
  
 
  getDeceased(id:string): Observable<IDeceasedDto> {   
    const headers = this.authService.getAuthHeaders();
    const endpoint = 'GetDeceased'; 
    const url = `${this.baseUrl}${endpoint}?idCardNumber=${id}`;
    return this.http.get<IDeceasedDto>(url,{headers})
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };
  updateDeceasedDetails(id: string, DeceasedUpdateDto: IDeceasedDto): Observable<IResponseModal> {
    const headers = this.authService.getAuthHeaders();
    const url = `${this.baseUrl}${id}`;
    return this.http.put< IResponseModal>(url , DeceasedUpdateDto,{ observe: 'response',headers }).pipe(
        map(response => {
          
          let modal: IResponseModal = {
            id: 0,
            title: 'Record Updated',
            message: ''
          };
          if (response.status === 204) {  
            
            modal.message = 'Updated Successfully';
  
          } else {
            modal.message =`Unexpected status code: ${response.status}`;
            
            throw new Error(`Unexpected status code: ${response.status}`);
          }
          return modal;
        })
      );
    }
  
  
    deceasedExist(id: string): Observable<boolean> {
      if (!id) {
         
          return of(false);
      }
  
      const headers = this.authService.getAuthHeaders();
      const endpoint = 'DeceasedExist';
      const url = `${this.baseUrl}${endpoint}?idCardNumber=${id}`;
  
      return this.http.get<boolean>(url, { headers }).pipe(
          catchError(error => {
              console.error('Error:', error);
              throw error;
          })
      );
  }
  
}
