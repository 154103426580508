<div *ngIf="!isCardDisabled" @fadeInGrow>
  <div class="tabcontainer">
    <div class="mat-class first">
      <span class="maintitle">
        <mat-divider></mat-divider>BURIAL MODULE<mat-divider></mat-divider></span>
          <ul class="card" >
            <li *ngFor="let number of cards">
              <mat-card [routerLink]="number.route" class="card-class">
                <div class="avatarimage">
                  <img [src]="number.imageURL" />
                </div>
                <mat-card-title>
                  <div class="content-part"><span>{{ number.name }}</span></div>
                </mat-card-title>
              </mat-card>
            </li>
          </ul>
    </div>

    <div class="mat-class ">
     
      <span class="maintitle"> 
      <mat-divider></mat-divider>MAINTENANCE<mat-divider></mat-divider></span>
      <ul class="items">
        <li *ngFor="let cemitems of cemcards">
          <mat-card [routerLink]="cemitems.route" class="card-class">
            <div class="avatarimage">
              <img [src]="cemitems.imageURL" />
            </div>
            <mat-card-title>
              <div class="content-part"><span>{{cemitems.name}}</span></div>
            </mat-card-title>
          </mat-card>
        </li>
      </ul>
    </div>
    <div class="mat-class ">
      <span class="maintitle">
      <mat-divider></mat-divider>OTHER OPTIONS<mat-divider></mat-divider></span>
      <ul class="report" >
        <li *ngFor="let reportitems of reportcards">
          <mat-card [routerLink]="reportitems.route" class="card-class">
            <div class="avatarimage">
              <img [src]="reportitems.imageURL" />
            </div>
            <mat-card-title>
              <div class="content-part"><span>{{reportitems.name}}</span></div>
            </mat-card-title>
          </mat-card>
        </li>
      </ul>
    </div>
  </div>
</div>
