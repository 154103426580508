import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ITableCountDto } from '../burials/models/ITableCountDto';
import { IReceiptDto } from '../models/ReceiptDto.model';
import { IResponseModal } from '../models/ResponseModal.model';
import { ICountryDto } from '../models/CountryDto.model';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private baseUrl = `${environment.apiUrl}common/`;
  constructor(private http: HttpClient, private authService: AuthService) { }

  getAllSupervisors(filterCriteria: string | null, searchQuery: string | null, pageNumber: number, pageSize: number ): Observable<ITableCountDto> {    
    let params = new HttpParams();
    const headers = this.authService.getAuthHeaders();
    
    if (filterCriteria) {
        params = params.append('filterCriteria', filterCriteria);
    }
    if (searchQuery) {
        params = params.append('searchQuery', searchQuery);
    }
   
    params = params.append('pageNumber', pageNumber.toString());
    params = params.append('pageSize', pageSize.toString());

    return this.http.get<ITableCountDto>(this.baseUrl + 'GetAllSupervisors', { params,headers })
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };

  getAllEmployees(filterCriteria: string | null, searchQuery: string | null, pageNumber: number, pageSize: number ): Observable<ITableCountDto> {    
    let params = new HttpParams();
    const headers = this.authService.getAuthHeaders();
    
    if (filterCriteria) {
        params = params.append('filterCriteria', filterCriteria);
    }
    if (searchQuery) {
        params = params.append('searchQuery', searchQuery);
    }
   
    params = params.append('pageNumber', pageNumber.toString());
    params = params.append('pageSize', pageSize.toString());

    return this.http.get<ITableCountDto>(this.baseUrl + 'GetAllEmployees', { params, headers})
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };

  getAllProviders(filterCriteria: string | null, searchQuery: string | null, pageNumber: number, pageSize: number ): Observable<ITableCountDto> {    
    let params = new HttpParams();
    const headers = this.authService.getAuthHeaders();
    
    if (filterCriteria) {
        params = params.append('filterCriteria', filterCriteria);
    }
    if (searchQuery) {
        params = params.append('searchQuery', searchQuery);
    }
   
    params = params.append('pageNumber', pageNumber.toString());
    params = params.append('pageSize', pageSize.toString());

    return this.http.get<ITableCountDto>(this.baseUrl + 'GetAllProviders', { params,headers })
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };

  getAllCountries(): Observable<ICountryDto[]> {   
    const headers = this.authService.getAuthHeaders(); 

    return this.http.get<ICountryDto[]>(this.baseUrl + 'GetAllCountries',{headers})
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };

  getUserIdentifier(): Observable<string> {
    const headers = this.authService.getAuthHeaders();
    return this.http.get(this.baseUrl + 'GetUserIdentifier', { responseType: 'text',headers })
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
    }

    getCleaningPermitNumber(): Observable<string> {
        const headers = this.authService.getAuthHeaders();
        return this.http.get(this.baseUrl + 'GetCleaningPermitNumber', { responseType: 'text', headers })
            .pipe(
                catchError(error => {
                    console.error('Error:', error);
                    throw error;
                })
            );
    }
    getTransportPermitNumber(): Observable<string> {
        const headers = this.authService.getAuthHeaders();
        return this.http.get(this.baseUrl + 'GetTransportPermitNumber', { responseType: 'text',headers })
            .pipe(
                catchError(error => {
                    console.error('Error:', error);
                    throw error;
                })
            );
    }
    

  


  getAllReferenceData( filterCriteria: string | null, searchQuery: string | null, pageNumber: number, pageSize: number ): Observable<ITableCountDto> {    
    let params = new HttpParams();
    const headers = this.authService.getAuthHeaders();
    if (filterCriteria) {
        params = params.append('filterCriteria', filterCriteria);
    }
    if (searchQuery) {
        params = params.append('searchQuery', searchQuery);
    }
    
    params = params.append('pageNumber', pageNumber.toString());
    params = params.append('pageSize', pageSize.toString());

    return this.http.get<ITableCountDto>(this.baseUrl + 'GetAllReferenceData', { params,headers })
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
};


  
}