<mat-toolbar color="primary" class="header">
  <mat-toolbar-row>
<!--<button
      mat-icon-button
      (click)="sidenav.toggle()"
      fxShow="true"
      fxHide.gt-sm>
      <div class="togglespace">
        <mat-icon>menu</mat-icon>
      </div>
    </button> -->

    <div class="logo">
      <img src="assets/logomdh.png">
      <h6><span>CEMETERIES & BURIAL</span></h6>
    </div>
    <span class="menu-spacer"></span>
    
    <div class="user-info" *ngIf="isLoggedIn()">
      <span class="welcome-text">Welcome, {{ username }}</span>
      
    </div>
    <div class="sample" fxShow="true" fxHide.lt-md>
      
      <button *ngIf="isLoggedIn()" routerLink="/home" mat-button>Dashboard</button>
      <button *ngIf="isLoggedIn()" routerLink="burial/burial-permit-generation" mat-button>Burials</button>
      <button *ngIf="isLoggedIn()" routerLink="cemetery/cemetery-details" mat-button>Cemetery</button>
      <button *ngIf="isLoggedIn()" routerLink="cemetery/grave/gravedetails" mat-button>Maintenance</button>
      <button *ngIf="isLoggedIn()" routerLink="/reporting" mat-button>Reports</button>
     
      <button *ngIf="!isLoggedIn()" mat-raised-button (click)="login()">Login</button>
      <button *ngIf="isLoggedIn()" mat-raised-button (click)="logout()">Logout</button>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container fxFlexFill>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>

  <mat-sidenav #sidenav>
    <mat-nav-list>
      <div class="sideheader-row">
        <a mat-list-item href="/home" (click)="onSidenavClose()" *ngIf="isLoggedIn()">
          <mat-icon class="iconspacer"><span class="material-symbols-outlined">dashboard</span></mat-icon>
          <span class="full-width">Dashboard</span>
        </a>
      </div>

      <mat-list-item (click)="showFirstmenu = !showFirstmenu" class="parent" *ngIf="isLoggedIn()">
        <mat-icon class="iconspacer" mat-list-icon>add_location</mat-icon>
        <span class="full-width">Burial</span>
        <mat-icon class="menu-button" [ngClass]="{'rotated' : showFirstmenu}">expand_more</mat-icon>
      </mat-list-item>

      <div class="submenu" [ngClass]="{'expanded' : showFirstmenu}" *ngIf="isLoggedIn()">
        <a mat-list-item href="burial/burial-permit-generation">Burial Permit Generation</a>
        <a mat-list-item href="burial/burial-permit-details">Burial Permit Details</a>
      </div>

      <mat-list-item (click)="showSecondmenu = !showSecondmenu" class="parent" *ngIf="isLoggedIn()">
        <mat-icon class="iconspacer" mat-list-icon>church</mat-icon>
        <span class="full-width">Cemetery</span>
        <mat-icon class="menu-button" [ngClass]="{'rotated' : showSecondmenu}">expand_more</mat-icon>
      </mat-list-item>

      <div class="submenu" [ngClass]="{'expanded' : showSecondmenu}" *ngIf="isLoggedIn()">
        <a mat-list-item href="">Cemetery</a>
        <a mat-list-item href="">Division</a>
        <a mat-list-item href="">Section</a>
        <a mat-list-item href="">Compartment</a>

        <mat-list-item (click)="showSubMenu = !showSubMenu" class="parent">
          <span class="full-width">Grave</span>
          <mat-icon class="menu-button" [ngClass]="{'rotated' : showSubMenu}">expand_more</mat-icon>
        </mat-list-item>

        <div class="submenu spacealign" [ngClass]="{'expanded' : showSubMenu}">
          <a mat-list-item href="">Grave Details</a>
          <a mat-list-item href="">Grave Owners</a>
        </div>
      </div>

      <mat-list-item (click)="showThirdmenu = !showThirdmenu" class="parent" *ngIf="isLoggedIn()">
        <mat-icon class="iconspacer" mat-list-icon>settings_applications</mat-icon>
        <span class="full-width">Maintenance</span>
        <mat-icon class="menu-button" [ngClass]="{'rotated' : showThirdmenu}">expand_more</mat-icon>
      </mat-list-item>

      <div class="submenu" [ngClass]="{'expanded' : showThirdmenu}" *ngIf="isLoggedIn()">
        <a mat-list-item href="">Cleaning & Transport</a>
        <a mat-list-item href="">Deceased Maintenance</a>
      </div>
    </mat-nav-list>
  </mat-sidenav>
</mat-sidenav-container>
