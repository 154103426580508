<div class="body-container  fixed-left">
  <app-menu-bar fxShow="true" fxHide.lt-md></app-menu-bar>
</div>


<div class="body-container">
  <div class="header_container">
    <mat-divider></mat-divider>
    <h1>Cleaning & Transport - Reference Data</h1>
    <mat-divider></mat-divider>
  </div>
  <div class="container">
    <div class="form">
        <!--TABLE FILTER-->
   <div id="searchandfilter_box" class="box">          
    <mat-form-field class="mat-form-field">
      <mat-label>Search</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Id Card Number" #input>
    </mat-form-field>
  </div>
<mat-divider></mat-divider>
  <!--TABLE-->
  <div class="box">
    <div class="table-container mat-elevation-z8">
      <div *ngIf="isLoadingResults || isRateLimitReached" class="table-loading-shade">
        <div *ngIf="isLoadingResults">
          <mat-spinner></mat-spinner>
        </div>
        <div *ngIf="isRateLimitReached" class="table-rate-limit-reached">
          Loading...
        </div>
      </div>
    </div>
    <div class="table-table-container" id="tablecontent">
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>
        
            <!-- Id Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef >ID</th>
            <td mat-cell *matCellDef="let referenceObj">{{ referenceObj.id}}</td>
          </ng-container>

          <!-- Permit No Column -->
            <ng-container matColumnDef="permitno">
              <th mat-header-cell *matHeaderCellDef >Permit No</th>
              <td mat-cell *matCellDef="let referenceObj">{{ referenceObj.permitNumber}}</td>
            </ng-container>

            <!-- month Column -->
            <ng-container matColumnDef="month">
              <th mat-header-cell *matHeaderCellDef >Month</th>
              <td mat-cell *matCellDef="let referenceObj">{{ referenceObj.month}}</td>
            </ng-container>

            <!-- Type Column -->
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef  > Type </th>  
                <td mat-cell *matCellDef="let referenceObj" >{{ referenceObj.type}} </td>
              </ng-container>

            <!-- Corpse Name -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef >Corpse Name</th>
                <td mat-cell *matCellDef="let referenceObj"> {{ referenceObj.name }} </td>
            </ng-container>

            <!-- Burial permit No Column -->
            <ng-container matColumnDef="burialpermit">
              <th mat-header-cell *matHeaderCellDef > Burial Permit No</th>
              <td mat-cell *matCellDef="let referenceObj"> {{ referenceObj.burialPermitNumber}}</td> 
            </ng-container>

            <ng-container matColumnDef="expand">
              <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
              <td mat-cell *matCellDef="let referenceObj">
                <button mat-icon-button aria-label="expand row"  (click)="expandCollapse(referenceObj); $event.stopPropagation()" >
                  <ng-container *ngIf="expandedElement === referenceObj">
                      <mat-icon>keyboard_arrow_up</mat-icon>
                  </ng-container>
                  <ng-container *ngIf="expandedElement !== referenceObj">
                      <mat-icon>keyboard_arrow_down</mat-icon>
                  </ng-container>
              </button>
              </td>
            </ng-container> 
            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
             <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let referenceObj" [attr.colspan]="displayedColumnsWithExpand.length">
                <div class="expand-detail" [@detailExpand] = "referenceObj == expandedElement ? 'expanded' : 'collapsed' ">
                  <div class="expand-category">
                    <div class="expand-field"><p class="titleheader">Cleaning Details</p><table class ="table-row">
                        <tr class="table-tr"><td class="table-td">Cemetery(cleaning) : &nbsp;<span>{{ referenceObj.cemeteryNameCleaning}}</span></td>
                            <td class="table-td">Division :&nbsp;<span>{{referenceObj.divisionNameCleaning}}</span></td>
                            <td class="table-td">Section :&nbsp;<span>{{referenceObj.sectionNameCleaning}}</span></td>
                            <td class="table-td">Compartment :&nbsp;<span>{{referenceObj.compartmentNameCleaning}}</span></td>
                            <td class="table-td">Grave :&nbsp;<span>{{referenceObj.graveNumberCleaning}}</span></td> 
                        </tr>
                        <tr class="table-tr"><td class="table-td">No.of Human Remains(For Cleaning) : &nbsp;<span>{{ referenceObj.bodiesRemainsForCleaning}}</span></td>
                            <td class="table-td">No.of Human Remains Cleaned :&nbsp;<span>{{referenceObj.bodiesRemainsCleaned}}</span></td>
                        </tr>
                    </table></div>
                        <mat-divider class="matdiv"></mat-divider>
                        <div class="expand-field"><p class="titleheader">Transport Details</p><table class ="table-row">
                        <tr class="table-tr"><td class="table-td">Cemetery From : &nbsp;<span>{{ referenceObj.cemeteryNameFrom}}</span></td>
                            <td class="table-td">Division :&nbsp;<span>{{referenceObj.divisionNameFrom}}</span></td>
                            <td class="table-td">Section :&nbsp;<span>{{referenceObj.sectionNameFrom}}</span></td>
                            <td class="table-td">Compartment :&nbsp;<span>{{referenceObj.compartmentNameFrom}}</span></td>
                            <td class="table-td">Grave :&nbsp;<span>{{referenceObj.graveNumberFrom}}</span></td>
                            <td class="table-td">Level :&nbsp;<span>{{referenceObj.levelFrom}}</span></td>
                        </tr>
                       
                        <tr class="table-tr"><td class="table-td">Cemetery To : &nbsp;<span>{{ referenceObj.cemeteryNameTo}}</span></td>
                            <td class="table-td">Division :&nbsp;<span>{{referenceObj.divisionNameTo}}</span></td>
                            <td class="table-td">Section :&nbsp;<span>{{referenceObj.sectionNameTo}}</span></td>
                            <td class="table-td">Compartment :&nbsp;<span>{{referenceObj.compartmentNameTo}}</span></td>
                            <td class="table-td">Grave :&nbsp;<span>{{referenceObj.graveNumberTo}}</span></td>
                            <td class="table-td">Level :&nbsp;<span>{{referenceObj.levelTo}}</span></td>
                        </tr></table></div>
                        <mat-divider class="matdiv"></mat-divider>
                    <div class="expand-field"><table class ="table-row">
                        <tr class="table-tr">
                            <td class="table-td">No.of Human Remains(For Transport) :&nbsp;<span>{{referenceObj.bodiesRemainsForTransport}}</span></td>
                            <td class="table-td">Date Of Application :&nbsp;<span>{{referenceObj.applicationDate}}</span></td>
                            <td class="table-td">Time of Application :&nbsp;<span>{{referenceObj.time}}</span></td>
                        </tr>
                        <tr class="table-tr"><td class="table-td">BAU Officer: &nbsp;<span>{{ referenceObj.officerBau}}</span></td>
                            <td class="table-td">Supervisor :&nbsp;<span>{{referenceObj.time}}</span></td>
                            <td class="table-td">Undertaker :&nbsp;<span>{{referenceObj.undertakerName}}</span></td>
                            <td class="table-td">Marble Slab :&nbsp;<span>{{referenceObj.marbleSlab}}</span></td>
                         </tr>   
                           
                        <tr class="table-tr"><td class="table-td">Date of CT: &nbsp;<span>{{ referenceObj.cleaningDate}}</span></td>
                            <td class="table-td">Time of CT :&nbsp;<span>{{referenceObj.timeForCleaning}}</span></td>
                            <td class="table-td">Carried CT :&nbsp;<span>{{referenceObj.cleaningCarriedOut}}</span></td>
                            <td class="table-td">Family Present :&nbsp;<span>{{referenceObj.familyPresent}}</span></td>
                            </tr>
                    </table></div>
                     <mat-divider class="matdiv"></mat-divider>
                    <div class="expand-field"><table class ="table-row"> 
                        <tr class="table-tr"><td class="table-td">Cleaning Fees: &nbsp;<span>{{ referenceObj.cleaningFee}}</span></td>
                            <td class="table-td">Transport Fees :&nbsp;<span>{{referenceObj.transportFee}}</span></td>
                            <td class="table-td">Paid Fees :&nbsp;<span>{{referenceObj.feePaid}}</span></td>
                            <td class="table-td">Receipt No :&nbsp;<span>{{referenceObj.receiptNo}}</span></td>
                            <td class="table-td">Receipt Issued By:&nbsp;<span>{{referenceObj.receiptIssuedBy}}</span></td>
                        </tr></table></div>
                        <mat-divider class="matdiv"></mat-divider>
                        <div class="expand-field">Remarks: &nbsp;<span>{{ referenceObj.remarks}}</span>
                        </div>
                  </div>
                </div>
              </td>
            </ng-container>
            
           
             <tr mat-header-row *matHeaderRowDef="displayedColumnsWithExpand"></tr>
          <tr mat-row *matRowDef="let referenceObj; columns: displayedColumnsWithExpand;" class="expand-Inside"
            [class.example-expanded-row]="expandedElement != referenceObj" ></tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" matRipple class="expand-Rowdetails"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="5">
              No data matching the filter "{{input.value}}"
            </td>
          </tr>
        </table>
     
      </div> 
      </div>
      <mat-paginator class="paginator" [pageSizeOptions]="[25, 100,200]" [length]="resultsLength" aria-label="Select page of records"></mat-paginator>
     
      </div>
  </div>
</div>
  