import { Injectable } from '@angular/core';
import { HttpClient,HttpParams, HttpResponse  } from '@angular/common/http';
import { catchError,map,Observable, tap } from 'rxjs';
import { ICemeteryDto } from '../models/CemeteryDto.model';
import { ICemeteryTypeDto } from '../models/CemeteryTypeDto.model';
import { IAddNewCemeteryDto } from '../models/AddNewCemeteryDto.model';
import { IResponseModal } from '../models/ResponseModal.model';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class CemeteryService {
  private baseUrl = `${environment.apiUrl}burial/burial-type/cemetery/`;  

  constructor(private http: HttpClient, private authService: AuthService) { }

  getAllCemeteryType(): Observable<ICemeteryTypeDto[]> {   
    const headers = this.authService.getAuthHeaders();
    return this.http.get<ICemeteryTypeDto[]>(this.baseUrl + 'GetCemeteryType',{headers} )
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };

  getAllCemeteries(pageNumber: number = 1, pageSize: number = 100): Observable<ICemeteryDto[]> {    
    let params = new HttpParams();
    const headers = this.authService.getAuthHeaders();
    
    params = params.append('pageNumber', pageNumber.toString());
    params = params.append('pageSize', pageSize.toString());

    return this.http.get<ICemeteryDto[]>(this.baseUrl, { params, headers })
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };
  getCemeteryById(id:number): Observable<ICemeteryDto> {    
    const url = `${this.baseUrl}${id}`;
    const headers = this.authService.getAuthHeaders();
    return this.http.get<ICemeteryDto>(url,{headers})
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };
  updateCemeteryDetails(id: number, CemeteryForUpdateDto: ICemeteryDto): Observable<IResponseModal> {
    const url = `${this.baseUrl}${id}`;
    const headers = this.authService.getAuthHeaders();
    return this.http.put<HttpResponse<IResponseModal>>(url , CemeteryForUpdateDto, { observe: 'response',headers }).pipe(
      map(response => {
        
        let modal: IResponseModal = {
          id: 0,
          title: 'Update Cemetery',
          message: ''
        };
        if (response.status === 204) {  
          
          modal.message = 'Record Updated';

        } else if (response.status === 200) {    
          
          modal.message = 'To update an cemetery at least one field needs to change, currently not changes have been registered.';

        } else {
          modal.message =`Unexpected status code: ${response.status}`;
          
          throw new Error(`Unexpected status code: ${response.status}`);
        }
        return modal;
      })
    );
  }

  addNewCemetery(addNewCemeteryDto:  IAddNewCemeteryDto): Observable<IResponseModal> {
    const headers = this.authService.getAuthHeaders();
    return this.http.post<IResponseModal>(this.baseUrl + 'AddCemetery', addNewCemeteryDto,{ observe: 'response',headers }).pipe(
      tap(response => {
        console.log('Add Cemetery' + response.status);
      }),
      map(response => {
        let modal: IResponseModal = {
          id: 0,
          title: 'Add Cemetery',
          message: '',
          entity : response.body
        };
  
        if (response.status === 201) {
          modal.message = 'Cemetery added';
        } else {
          modal.message = `Unexpected status code: ${response.status}`;
          throw new Error(`Unexpected status code: ${response.status}`);
        }
        
        return modal;
      }),
        catchError(error => {
            console.error('Error:', error);
            throw error;
        })
    );
  }
 
  deleteCemetery(id : number) : Observable<IResponseModal>{
    const headers = this.authService.getAuthHeaders();
    const url = `${this.baseUrl}${id}`;
    return this.http.delete<IResponseModal>(url,{headers})
    .pipe(
      catchError(error => {
          console.error('Error:', error);
          throw error;
      })
  );
  }
}