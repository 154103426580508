import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBurialTypeDto } from '../models/BurialTypeDto.model';
import { Observable, catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class BurialTypeService {

  private baseUrl = `${environment.apiUrl}burialtype/`;
  constructor(private http: HttpClient, private authService: AuthService) { }

  getAllBurialTypes(pageNumber: number = 1, pageSize: number = 100): Observable<IBurialTypeDto[]> {    
    let params = new HttpParams();
    const headers = this.authService.getAuthHeaders();

    return this.http.get<IBurialTypeDto[]>(this.baseUrl + 'GetAllBurialTypes', { params, headers })
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };

}
