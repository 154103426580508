import { HttpHeaders } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { RoleService } from './services/role.service';
import { jwtDecode } from "jwt-decode";
import { Router } from '@angular/router';



@Injectable({
  providedIn: 'root'
})
export class AuthService  implements OnInit{

  constructor(private msalService: MsalService, private roleService : RoleService,private router: Router) { }
  
  ngOnInit(): void {
    //this.roleService.fetchUserRoles().subscribe((response) => {
      
     // this.roleService.setUserRoles(response.role);
   // });
  }

  isLoggedIn(): boolean {
    return this.msalService.instance.getActiveAccount() != null;
  }

  login(): void {
    this.msalService.loginRedirect();
  }

  logout(): void {
    this.msalService.logout();
    this.roleService.clearUserRoles();   

    localStorage.clear();
    sessionStorage.clear();
    this.msalService.instance.logoutRedirect({
      postLogoutRedirectUri: '/'
    });
  }

  getActiveAccount(): AccountInfo | null {
    return this.msalService.instance.getActiveAccount();
  }

  getIdToken(): string | null {
    const account = this.msalService.instance.getActiveAccount();
    if (account) {
      return account.idToken || null;
    }
    return null;
  }
  
  getAuthHeaders(): HttpHeaders {
    const token = this.getIdToken();
    if (!token) {
      throw new Error('ID token is missing. User might not be authenticated.');
    }

    return new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
  }

  checkTokenExpiration(): boolean {
    const token = this.getIdToken();
    if (token) {
      const decodedToken: any = jwtDecode(token);
      const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds
      const currentTime = new Date().getTime();
      const timeUntilExpiration = expirationTime - currentTime;
      const minutesLeft = timeUntilExpiration / 60000; 
      

      // for testing purpose
      //const simulatedExpirationTime = expirationTime - 58 * 60000; // Subtract 58 minutes from the expiration time
      //const timeUntilExpiration = simulatedExpirationTime - currentTime;
      //const minutesLeft = timeUntilExpiration / 60000;




  
      console.log(`Token expiration time: ${new Date(expirationTime)}`);
      console.log(`Current time: ${new Date(currentTime)}`);
      console.log(`Token will expire in ${minutesLeft.toFixed(2)} minutes.`);
  
      // If less than 5 minutes (300,000 ms), return true
      if (timeUntilExpiration < 300000) {
        console.log('Token is about to expire!');
        return true;
      }
    } else {
      console.log('No token found.');
    }
    return false;
  }
  

  refreshToken(): void {
    this.msalService.acquireTokenSilent({
      scopes: ['https://b2cdevgovmt.onmicrosoft.com/Health-CemetryBurialSystem/user_impersonation']
    }).subscribe({
      next: (response) => {
        console.log('Token refreshed successfully', response);
  
       
        const account = this.getActiveAccount();
        if (account) {
          const idTokenClaims = account.idTokenClaims as { [key: string]: any };
          const email = idTokenClaims['email'] || account.username;
  
          
          this.roleService.fetchUserRoleByEmail(email).subscribe((role => {
            if (role) {
              this.roleService.setUserRoles(role);
            }
          }));
        }
      },
      error: (error) => {
        console.error('Token refresh failed:', error);
        this.logout(); 
      }
    });
  }


  getUsername(): string | null {
    const account = this.getActiveAccount();
    if (account && account.idTokenClaims) {
      const idTokenClaims = account.idTokenClaims as { [key: string]: any };
      return idTokenClaims['name'] || idTokenClaims['email'] || account.username;
    }
    return null;
  }
  getFullName(): string | null {
    const account = this.getActiveAccount();
    if (account && account.idTokenClaims) {
      const idTokenClaims = account.idTokenClaims as { [key: string]: any };
      const givenName = idTokenClaims['given_name'];
      const familyName = idTokenClaims['family_name'];
      
      
      if (givenName && familyName) {
        return `${givenName} ${familyName}`;
      }
    }
    return null;
  }
  

  
}
