import { HttpClient, HttpParams,HttpHeaders, HttpResponse  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IGraveOwnerDto } from '../models/GraveOwnerDto.model';
import { Observable, catchError, map, tap, throwError } from 'rxjs';
import { IOwnershipTypeDto } from '../models/OwnershipTypeDto.model';
import { IOwnerUpdateDto } from '../models/OwnerUpdateDto.model';
import { ITableCountDto } from '../burials/models/ITableCountDto';
import { IResponseModal } from '../models/ResponseModal.model';
import { IAddOwnerDto } from '../models/AddOwnerDto.model';
import { IBurialPermitDto } from '../burials/models/BurialPermitDto.model';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class OwnerService {

  private baseUrl = `${environment.apiUrl}owner/`;
  constructor(private http: HttpClient, private authService: AuthService) { }

  getOwnerById(id:number): Observable<IGraveOwnerDto[]> {   
    const headers = this.authService.getAuthHeaders(); 
    const endpoint = 'GetOwnerById'; 
    const url = `${this.baseUrl}${endpoint}?id=${id}`;
    return this.http.get<IGraveOwnerDto[]>(url,{headers})
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };
  getAllOwnersByGraveId(graveId : number, filterCriteria: string | null, searchQuery: string | null, pageNumber: number, pageSize: number ): Observable<ITableCountDto> {    
    let params = new HttpParams();
    const headers = this.authService.getAuthHeaders();
    if (filterCriteria) {
        params = params.append('filterCriteria', filterCriteria);
    }
    if (searchQuery) {
        params = params.append('searchQuery', searchQuery);
    }
    params = params.append('graveId', graveId.toString());    
    params = params.append('pageNumber', pageNumber.toString());
    params = params.append('pageSize', pageSize.toString());

    return this.http.get<ITableCountDto>(this.baseUrl + 'GetAllOwnersByGraveId', { params,headers })
        .pipe(
            catchError(error => {
                console.error('Error:', error);
                throw error;
            })
        );
  };


  getOwnershipType(pageNumber: number = 1, pageSize: number = 100): Observable<IOwnershipTypeDto[]> {    
    let params = new HttpParams();   
    const headers = this.authService.getAuthHeaders(); 
    params = params.append('pageNumber', pageNumber.toString());
    params = params.append('pageSize', pageSize.toString());

    return this.http.get<IOwnershipTypeDto[]>(this.baseUrl+ 'GetOwnershipType', { params,headers })
        .pipe(
            catchError(error => {   
                console.error('Error:', error);
                throw error;
            })
        );
  };

  updateOwner(id: number, ownerForUpdateDto: IOwnerUpdateDto): Observable<IResponseModal> {
    const url = `${this.baseUrl}${id}`;
    const headers = this.authService.getAuthHeaders();

    return this.http.put<HttpResponse<IResponseModal>>(url, ownerForUpdateDto, { observe: 'response',headers }).pipe(
      map(response => {
        
        let modal: IResponseModal = {
          id: 0,
          title: 'Update Owner',
          message: ''
        };
        if (response.status === 204) {  
          
          modal.message = 'Record Updated';

        } else if (response.status === 200) {    
          
          modal.message = 'To update an owner at least one field needs to change, currently not changes have been registered.';

        } else {
          modal.message =`Unexpected status code: ${response.status}`;
          
          throw new Error(`Unexpected status code: ${response.status}`);
        }
        return modal;
      })
    );
  }

 
  addOwner(newOwnerDto: IAddOwnerDto): Observable<IResponseModal> {
    const headers = this.authService.getAuthHeaders();
    return this.http.post<IGraveOwnerDto>(this.baseUrl + 'AddOwner', newOwnerDto,{ observe: 'response',headers }).pipe(
      tap(response => {
        console.log('Add Owner' + response.status);
      }),
      map(response => {
        let modal: IResponseModal = {
          id: 0,
          title: 'Add Owner',
          message: '',
          entity : response.body
        };
  
        if (response.status === 201) {
          modal.message = 'Owner added';
        } else {
          modal.message = `Unexpected status code: ${response.status}`;
          throw new Error(`Unexpected status code: ${response.status}`);
        }
        
        return modal;
      }),
      catchError(error => {
        console.error('Error occurred:', error);
        return throwError(error);
      })
    );
  }

  deleteOwner(id: number): Observable<IResponseModal> {
    const headers = this.authService.getAuthHeaders();
    const url = `${this.baseUrl}${id}`;
    return this.http.delete<HttpResponse<IResponseModal>>(url, { observe: 'response',headers }).pipe(
      tap(response => {
        console.log('Delete Owner' + response.status);
      }),
      map(response => {
        let modal: IResponseModal = {
          id: 0,
          title: 'Delete Owner',
          message: ''
        };
  
        if (response.status === 204) {
          modal.message = 'Owner deleted';
        } else {
          modal.message = `Unexpected status code: ${response.status}`;
          throw new Error(`Unexpected status code: ${response.status}`);
        }
        
        return modal;
      }),
      catchError(error => {
        console.error('Error occurred:', error);
        return throwError(error);
      })
    );
  }

  

}
