import { Component, OnDestroy, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { firstValueFrom, Subscription } from 'rxjs';
import { RoleService } from './services/role.service';
import { CurrentUserService } from './services/current-user.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy  {  
  title = 'cemetery-burial-client';
  private tokenCheckInterval: any;
  private checkIntervalTime = 60 * 1000; // Check every 1 minute
  private subscription: Subscription = new Subscription();
  
  constructor(
    private msalService: MsalService, 
    private authService: AuthService, 
    private router: Router, 
    private roleService : RoleService,
    private currentUserService : CurrentUserService,
    public dialog: MatDialog
  ) {}
  ngOnDestroy(): void {
    if (this.tokenCheckInterval) {
      clearInterval(this.tokenCheckInterval);
    }
    this.subscription.unsubscribe();
  }

  async ngOnInit(): Promise<void> {
    try {
      // Handle the MSAL redirect promise first
      const result = await this.msalService.instance.handleRedirectPromise();

      if (result !== null && result.account !== null) {
        this.msalService.instance.setActiveAccount(result.account);  

        console.log('account: ')
        console.log(result.account) 
        const idTokenClaims = result.account.idTokenClaims as { [key: string]: any };
        const email = idTokenClaims['email'] || result.account.username;
        console.log('email :' + email)
        this.currentUserService.setUserEmail(email);        
        
        this.fetchUserRoleByEmail(email);
       
        const tokenResult: AuthenticationResult = await firstValueFrom(
          this.msalService.acquireTokenSilent({
            scopes: ['https://b2cdevgovmt.onmicrosoft.com/Health-CemetryBurialSystem/user_impersonation']
          })
        );
        console.log('ID Token:', tokenResult.idToken);
        // Manually check token expiration right after token acquisition
        const tokenExpired = this.authService.checkTokenExpiration();
        if (tokenExpired) {
          this.fetchUserRoleByEmail(email);
        }

       // Start interval to check token expiration periodically
       this.startTokenCheckInterval();

       // Redirect to /home after acquiring the token
       this.router.navigate(['/home']);
      }
    } catch (error) {
      console.error('Error handling MSAL redirect promise or acquiring token:', error);

      // If there was an issue acquiring a token, prompt login
      this.msalService.loginRedirect({
        scopes: ['https://b2cdevgovmt.onmicrosoft.com/Health-CemetryBurialSystem/user_impersonation']
      });
    }
  }

  openConfirmationModal(): void {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '350px',
      data: {
        title: 'Session Expiration',
        message: 'Your session is about to expire. Do you want to stay logged in?',
        closeOnly: false
      }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        // User wants to stay logged in, refresh the token
        this.authService.refreshToken();
      } else {
        // User doesn't want to stay logged in, log them out
        this.authService.logout();
      }
    });
  }
  

  isLoggedIn(): boolean {
    return this.authService.isLoggedIn();
  }

  login(): void {
    
    this.authService.login();
  }

  logout(): void {
    this.authService.logout();
    
  }

  startTokenCheckInterval(): void {
    console.log('Starting token expiration check interval.');
    this.tokenCheckInterval = setInterval(() => {
      console.log('Checking token expiration...');
      const tokenExpired = this.authService.checkTokenExpiration();
      if (tokenExpired) {
        this.openConfirmationModal();
      }
    }, this.checkIntervalTime);
  }

  fetchUserRoleByEmail(email: string): void {
    this.roleService.fetchUserRoleByEmail(email).subscribe(role => {          
      if (role) {
        this.roleService.setUserRoles(role);            
      }
    });
  }
  
}
