import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ICemeteryDto } from 'src/app/models/CemeteryDto.model';
import { ICemeteryTypeDto } from 'src/app/models/CemeteryTypeDto.model';
import { ICompartmentDto } from 'src/app/models/CompartmentDto.model';
import { IDivisionDto } from 'src/app/models/DivisionDto.model';
import { IGraveDto } from 'src/app/models/GraveDto.model';
import { IGraveForUpdateDto } from 'src/app/models/GraveForUpdateDto.model';
import { IGraveSizeDto } from 'src/app/models/GraveSizeDto.model';
import { IGraveTypeDto } from 'src/app/models/GraveTypeDto.model';
import { IResponseModal } from 'src/app/models/ResponseModal.model';
import { ISectionDto } from 'src/app/models/SectionDto.model';
import { IStatusDto } from 'src/app/models/StatusDto.model';
import { ResponsemodalComponent } from 'src/app/responsemodal/responsemodal.component';
import { CemeteryService } from 'src/app/services/cemetery.service';
import { CompartmentService } from 'src/app/services/compartment.service';
import { DivisionService } from 'src/app/services/division.service';
import { GraveService } from 'src/app/services/grave.service';
import { InternalCommunicationService } from 'src/app/services/internal-communication.service';
import { SectionService } from 'src/app/services/section.service';
import { StatusService } from 'src/app/services/status.service';

@Component({
  selector: 'app-grave-edit',
  templateUrl: './grave-edit.component.html',
  styleUrls: ['./grave-edit.component.css']
})
export class GraveEditComponent implements OnInit {
  editGraveFormGroup! : FormGroup;
  graveForUpdate : IGraveForUpdateDto;
 

 //variables to hold the initial value of each service
 graveTypes! : IGraveTypeDto[];
 originalCemeteries!: ICemeteryDto[];
 originalDivisions!: IDivisionDto[];
 originalSections! : ISectionDto[];
 originalCompartments! : ICompartmentDto[];
 originalTypes! :ICemeteryTypeDto[];
 originalSizes! : IGraveSizeDto[];
 originalStatuses! : IStatusDto[];
  //variables to display the data after filtering
 cemeteryTypes! : ICemeteryTypeDto[];
 cemeteryType : ICemeteryTypeDto;
 cemeteries!: ICemeteryDto[];
 divisions!: IDivisionDto[];
 sections!: ISectionDto[];
 compartments!: ICompartmentDto[];
 graveSizes!: IGraveSizeDto[];
 statuses! : IStatusDto[];
 graveId !: number;
 
 constructor(
  public dialogRef: MatDialogRef<GraveEditComponent>,
  @Inject(MAT_DIALOG_DATA) public data: IGraveDto,
   public dialog: MatDialog,
   private cemeteryService : CemeteryService,
   private divisionService : DivisionService,
   private sectionService : SectionService,
   private compartmentService : CompartmentService,
   private _formBuilder: FormBuilder,
   private graveService : GraveService,
   private statusService : StatusService,
   private internalService : InternalCommunicationService
  ){
    
    this.graveId = data.id;
    
    this.graveForUpdate = {
      referenceNumber : data.referenceNumber,
      dhFileNo : data.dhFileNo,
      isPrivate : data.isPrivate  ,
      cemeteryId : data.cemeteryId,
      divisionId : data.divisionId,
      sectionId : data.sectionId,
      compartmentId : data.compartmentId,
      graveNo : data.graveNo,
      graveSizeId : data.graveSizeId,
      remarks : data.remarks,
      IsSealed : data.isSealed,
      cemeteryTypeId : data.cemeteryTypeId,
      statusId : data.statusId,
      graveTypeId : data.graveTypeId,
    }
    this.cemeteryType = {
      id : 0,
      description : ''
    }

   }
  ngOnInit(): void { 

    this.getCemeteryTypes();
    this.getCemeteries();
    this.getDivisions();
    this.getSections();
    this.getCompartments();
    this.getGraveSizes();
    this.getAllStatus();
    this.getGraveTypes();

    this.editGraveFormGroup = this._formBuilder.group({  
      cemeteryTypeCtrl :[ this.graveForUpdate.cemeteryTypeId],
      cemeteryCtrl : [ this.graveForUpdate.cemeteryId],
      divisionCtrl: [ this.graveForUpdate.divisionId],
      sectionCtrl: [this.graveForUpdate.sectionId],
      compartmentCtrl: [this.graveForUpdate.compartmentId],
      graveSizeCtrl:[ this.graveForUpdate.graveSizeId],
      DHFileCtrl : [this.graveForUpdate.dhFileNo],
      graveReferenceCtrl : [this.graveForUpdate.referenceNumber],
      graveNoCtrl: [this.graveForUpdate.graveNo],
      privateGraveCtrl: [ this.graveForUpdate.isPrivate],
      graveStatusCtrl : [ this.graveForUpdate.statusId],
      remarksCtrl : [ this.graveForUpdate.remarks],
      isSealedCtrl:[this.graveForUpdate.IsSealed === true ? 1 : 0],
      graveTypeCtrl : [ this.graveForUpdate.graveTypeId],
        
  });
  this.subscribeChanges();
  }
  subscribeChanges() {
    this.editGraveFormGroup.get('cemeteryTypeCtrl')?.valueChanges.subscribe((value) => {          
      this.graveForUpdate.cemeteryTypeId = value;
    });
    this.editGraveFormGroup.get('cemeteryCtrl')?.valueChanges.subscribe((value) => {            
      this.graveForUpdate.cemeteryId = value;
    });
    this.editGraveFormGroup.get('divisionCtrl')?.valueChanges.subscribe((value) => {            
      this.graveForUpdate.divisionId = value;
    });
    this.editGraveFormGroup.get('sectionCtrl')?.valueChanges.subscribe((value) => {            
      this.graveForUpdate.sectionId = value;
    });
    this.editGraveFormGroup.get('compartmentCtrl')?.valueChanges.subscribe((value) => {            
      this.graveForUpdate.compartmentId = value;
    });

    this.editGraveFormGroup.get('graveSizeCtrl')?.valueChanges.subscribe((value) => {            
      this.graveForUpdate.graveSizeId = value;
    });
    this.editGraveFormGroup.get('DHFileCtrl')?.valueChanges.subscribe((value) => {            
      this.graveForUpdate.dhFileNo = value;
    });
    this.editGraveFormGroup.get('graveReferenceCtrl')?.valueChanges.subscribe((value) => {            
      this.graveForUpdate.referenceNumber = value;
    });
    this.editGraveFormGroup.get('graveNoCtrl')?.valueChanges.subscribe((value) => {            
      this.graveForUpdate.graveNo = value;
    });
    this.editGraveFormGroup.get('privateGraveCtrl')?.valueChanges.subscribe((value) => {            
      this.graveForUpdate.isPrivate = value;
    });
    this.editGraveFormGroup.get('graveStatusCtrl')?.valueChanges.subscribe((value) => {            
      this.graveForUpdate.statusId = value;
    });
     this.editGraveFormGroup.get('isSealedCtrl')?.valueChanges.subscribe((value) => {            
       this.graveForUpdate.IsSealed = value === 1 ? true : false;
    });
    
    this.editGraveFormGroup.get('remarksCtrl')?.valueChanges.subscribe((value) => {            
      this.graveForUpdate.remarks = value;
    });
    
    
  }
  getCemeteryTypes(){
    this.cemeteryService.getAllCemeteryType().subscribe(
      (response : ICemeteryTypeDto[]) =>{
        this.cemeteryTypes = response;
        this.originalTypes = response;
    }, (error) =>{
      console.error('error retrieving cemetery types:', error)
    })
  }
  getCemeteries(){
    this.cemeteryService.getAllCemeteries().subscribe((response : ICemeteryDto[]) => {
      this.cemeteries = response;
      this.originalCemeteries = response;
    }, (error) =>{
      console.error('error retrieving cemeteries:', error)
    })
  }
  getDivisions(){
    this.divisionService.getAllDivisions().subscribe(
      (response: IDivisionDto[])=>{
        this.divisions = response;
        this.originalDivisions = response;

      },(error) =>{
        console.error('error retieving divisions service:', error);
      })
   }
   getSections(){
    this.sectionService.getAllSections().subscribe(
      (response : ISectionDto[] )=>{
        this.sections = response;
        this.originalSections = response;

      },(error) =>{
        console.error('error retieving sections service:', error);
      })
   }
   getCompartments(){
    this.compartmentService.getAllCompartments().subscribe(
      (response :ICompartmentDto[]) => {
        this.originalCompartments = response;
        this.compartments = response;

      }, (error) =>{
        console.error('error retieving compartments service:', error);
      })
   }
   getGraveSizes(){
    this.graveService.getGravesSizes().subscribe(
      (response : IGraveSizeDto[]) =>{        
        this.graveSizes = response;
        this.originalSizes = response;        
      },(error) =>{
        console.error('error retieving Grave sizes service:', error);
      })
   }

   getAllStatus(){
    this.statusService.getAllStatus().subscribe(
      (response : IStatusDto[]) =>{        
        this.statuses = response;
        this.originalStatuses = response;        
      },(error) =>{
        console.error('error retieving Status  service:', error);
      })
   }
   getGraveTypes(){
    this.graveService.getGravesTypes().subscribe(
      (response : IGraveTypeDto[]) => {
        this.graveTypes = response;

      }, (error) => {
        console.error('error retieving grave types  service: ', error);
      })
   }

   onGraveTypeChange(graveTypeId : number){
    this.graveForUpdate.graveTypeId = graveTypeId;
   }
  
  
   onCemeteryTypeChange(cemeteryTypeId : number){    
    const selectedCemeteryType = this.cemeteryTypes.find(cem => cem.id == cemeteryTypeId);    
    if(selectedCemeteryType && selectedCemeteryType.id != 0){  
      
      this.cemeteries = this.originalCemeteries.filter(cem => cem.cemeteryTypeId === selectedCemeteryType.id);      
    }else{
      this.cemeteries = this.originalCemeteries;
    }
   }

   onCemeteryChange(){ 
    const selectedCemetery = this.cemeteries.find(cem => cem.code == this.graveForUpdate.cemeteryId);  
    
    if(selectedCemetery && selectedCemetery.code != 0){ 
        this.editGraveFormGroup.get('divisionCtrl')?.enable();
        this.editGraveFormGroup.get('sectionCtrl')?.disable();
        this.editGraveFormGroup.get('compartmentCtrl')?.disable();
        
        this.getdivisionsByCemeteryId(selectedCemetery.code);  
        
    }else{
      this.editGraveFormGroup.get('divisionCtrl')?.disable();
      this.editGraveFormGroup.get('sectionCtrl')?.disable();
      this.editGraveFormGroup.get('compartmentCtrl')?.disable();
      this.divisions = this.divisions;
    }
  }
  onDivisionChange() {         
    const selectedDivision = this.divisions.find(div => div.id === this.graveForUpdate.divisionId);    
    if (selectedDivision  && selectedDivision.id != 0) {
      this.editGraveFormGroup.get('sectionCtrl')?.enable();
      this.editGraveFormGroup.get('compartmentCtrl')?.disable();

      this.getSectionsByCemeteryIdAndDivisionId(selectedDivision.id,selectedDivision.cemeteryId);
    } else {
      this.editGraveFormGroup.get('divisionCtrl')?.disable();
      this.editGraveFormGroup.get('sectionCtrl')?.disable();
      this.editGraveFormGroup.get('compartmentCtrl')?.disable();
      this.sections = this.sections;
    }
  }
  onSectionChange(){
    const selectedSection = this.sections.find(sec => sec.id === this.graveForUpdate.sectionId);    
    if (selectedSection && selectedSection.id != 0) {
      this.editGraveFormGroup.get('compartmentCtrl')?.enable();
      this.getCompartmentsByCemeteryIdAndDivisionIdAndSection(selectedSection.cemeteryId,selectedSection.divisionId,this.graveForUpdate.sectionId);
    } else {
      this.editGraveFormGroup.get('compartmentCtrl')?.disable();
      this.sections = this.originalSections;
    }
    
  }
  

  graveUpdateClick(): void {
    

    this.graveService.updateGrave(this.graveId,this.graveForUpdate).subscribe(
      (response: IResponseModal) => {
        this.openResponseModal(response);
        this.internalService.emitRefreshGraveTable();
    }, (error) =>{
      this.openResponseModal(error);
    })
     
   }
   openResponseModal(response : IResponseModal): void {
    const dialogRef = this.dialog.open(ResponsemodalComponent, {
      width: '250px',
      data : response,
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

   getdivisionsByCemeteryId(cemeteryId : number){
    this.divisionService.getDivisionsByCementeryId(cemeteryId).subscribe(
      (response :IDivisionDto[]) => {
        this.divisions = response;       
      }, (error) => {
        console.error('Something went wrong getDivisionsByCementeryId :', error)

      })
  }
  getSectionsByCemeteryIdAndDivisionId( divisionId : number, cemeteryId : number){
    this.sectionService.getSectionByDivisionIdAndCemeteryId(divisionId,cemeteryId).subscribe(
      (response :ISectionDto[]) => {
        this.sections = response;
      }, (error) => {
        console.error('Something went wrong getSectionsByCemeteryIdAndDivisionId :', error)

      })
  }

  getCompartmentsByCemeteryIdAndDivisionIdAndSection( cemeteryId : number,divisionId : number,sectionId : number ){
    this.compartmentService.getCompartmentByCemeteryIdAndDivisionIdSectionId(cemeteryId,divisionId,sectionId).subscribe(
      (response :ICompartmentDto[]) => {
        this.compartments = response;
      }, (error) => {
        console.error('Something went wrong compartments :', error)

      })
  }

  
}
