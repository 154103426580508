import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IAddNewDivisionDto } from 'src/app/models/AddNewDivisionDto.model';
import { ICemetery } from 'src/app/models/Cemetery.model';
import { ICemeteryDto } from 'src/app/models/CemeteryDto.model';
import { ICemeteryTypeDto } from 'src/app/models/CemeteryTypeDto.model';
import { IDivisionDto } from 'src/app/models/DivisionDto.model';
import { ILocalityDto } from 'src/app/models/LocalityDto.model';
import { IResponseModal } from 'src/app/models/ResponseModal.model';
import { ResponsemodalComponent } from 'src/app/responsemodal/responsemodal.component';
import { CemeteryService } from 'src/app/services/cemetery.service';
import { DivisionService } from 'src/app/services/division.service';
import { InternalCommunicationService } from 'src/app/services/internal-communication.service';
import { LocalityService } from 'src/app/services/locality.service';

@Component({
  selector: 'app-division-new',
  templateUrl: './division-new.component.html',
  styleUrls: ['./division-new.component.css']
})
export class DivisionNewComponent {
  DivisionNewForm !: FormGroup;
  cemeteryTypes: ICemeteryTypeDto[] = [];
  localities : ILocalityDto[] = [];
  cemeteries :ICemeteryDto[] =[];
  

  divisionmain: IAddNewDivisionDto =
  {
/*       id : 0, */
      description: '',
      cemeteryId :0,
/*       cemType:'',
      localityId:0, */
     
  };
 
 constructor(
  private cemeteryService : CemeteryService,
  public dialog: MatDialog,
  private localityService : LocalityService,
  private divisionService : DivisionService,
  private _formBuilder: FormBuilder,
  private internalService : InternalCommunicationService, 
  @Inject(MAT_DIALOG_DATA) public data: ICemetery){}


  ngOnInit() {
    this.getAllCemeteries();
    this.getAllLocalities();
    // this.getAllCemeteryType();    ( uncomment it after adding the service)
    this.DivisionNewForm = this._formBuilder.group({
      cemeteryCtrl: [this.divisionmain.cemeteryId],
/*       cemeteryTypeCtrl: [this.divisionmain.cemType], 
      localityCtrl: [this.divisionmain.localityId], */
      divisionCtrl :[this.divisionmain.description],
        
  });
this.subscribeChanges();
}
subscribeChanges() {
this. DivisionNewForm.get('cemeteryCtrl')?.valueChanges.subscribe((value) => {     
  this.divisionmain.cemeteryId= value;
});
/* this. DivisionNewForm.get('cemeteryTypeCtrl')?.valueChanges.subscribe((value) => {     
  this.divisionmain.cemType= value;
});
this.DivisionNewForm.get('localityCtrl')?.valueChanges.subscribe((value) => {     
  this.divisionmain.localityId= value;
}); */
this.DivisionNewForm.get('divisionCtrl')?.valueChanges.subscribe((value) => {     
  this.divisionmain.description= value;
});


}
getAllCemeteries(){
  this.cemeteryService.getAllCemeteries().subscribe(
   (response : ICemeteryDto[]) => {
     this.cemeteries = response;
   });
}
 getAllLocalities(){
    this.localityService.getAllLocalities().subscribe(
      (response: ILocalityDto[]) => {
        this.localities = response;     
      },
      (error) => {        
        console.error('Error adding localities: ', error);        
      }
      );
  }
  getAllCemeteryType(){
    this.cemeteryService.getAllCemeteryType().subscribe(
      (response: ICemeteryTypeDto[]) => {
        this.cemeteryTypes = response;        
      },
      (error) => {        
        console.error('Error retrieving cemeteryTypes: ', error);        
      }
      );
  }
  openResponseModal(response : IResponseModal): void {
    const dialogRef = this.dialog.open(ResponsemodalComponent, {
      width: '250px',
      data : response,
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  addNewDivision(){
    const addNewDivisionDto: IAddNewDivisionDto = this.divisionmain;   
    this.divisionService.addNewDivision(addNewDivisionDto).subscribe(
      (response: IResponseModal) => {       
        this.openResponseModal(response);   
      },
      (error) => {            
        console.error('Error adding new division:', error);       
      }
      );
    }

}
