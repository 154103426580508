import {Component,OnInit,ViewChild,Inject,  OnDestroy} from '@angular/core';
import {FormBuilder, Validators, FormGroup} from '@angular/forms';
import {MatPaginator } from '@angular/material/paginator';
import {MatSort } from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { IGraveDto } from 'src/app/models/GraveDto.model';
import { Observable, of, Subscription } from 'rxjs';
import { StepperOrientation } from '@angular/material/stepper';
import {map, startWith} from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IBurialPermitDto } from '../../models/BurialPermitDto.model';
import { BurialPermitService } from 'src/app/services/burial-permit.service';
import { CemeteryService } from 'src/app/services/cemetery.service';
import { LocalityService } from 'src/app/services/locality.service';
import { GenderService } from 'src/app/services/gender.service';
import { MaritalStatusService } from 'src/app/services/marital-status.service';
import { ICemeteryDto } from 'src/app/models/CemeteryDto.model';
import { IDivisionDto } from 'src/app/models/DivisionDto.model';
import { ISectionDto } from 'src/app/models/SectionDto.model';
import { ICompartmentDto } from 'src/app/models/CompartmentDto.model';
import { IGraveLevelsDto } from 'src/app/models/GraveLevelsDto.model';
import { ILocalityDto } from 'src/app/models/LocalityDto.model';
import { IGenderDto } from 'src/app/models/Gender.model';
import { IMaritalStatusDto } from 'src/app/models/MaritalStatusDto.model';
import { GraveExtendedComponent } from '../../burial-permit-generation/grave-extended/grave-extended.component';
import { MatSnackBar,MatSnackBarRef  } from '@angular/material/snack-bar';
import { IBurialPermitUpdateDto } from 'src/app/models/BurialPermitUpdateDto.model';
import { InternalCommunicationService } from 'src/app/services/internal-communication.service';
import { GraveService } from 'src/app/services/grave.service';
import { DivisionService } from 'src/app/services/division.service';
import { SectionService } from 'src/app/services/section.service';
import { CompartmentService } from 'src/app/services/compartment.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { UndertakerService } from 'src/app/services/undertaker.service';
import { IUndertakerDto } from 'src/app/models/UndertakerDto.model';
import { BurialTypeService } from 'src/app/services/burial-type.service';
import { IBurialTypeDto } from 'src/app/models/BurialTypeDto.model';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { IResponseModal } from 'src/app/models/ResponseModal.model';
import { ResponsemodalComponent } from 'src/app/responsemodal/responsemodal.component';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { ICountryDto } from 'src/app/models/CountryDto.model';
import { CommonService } from 'src/app/services/common.service';
import { ITableCountDto } from '../../models/ITableCountDto';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { GraveLevelsComponent } from 'src/app/grave-levels/grave-levels.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-burial-permit-detail-edit',
  templateUrl: './burial-permit-detail-edit.component.html',
  styleUrls: ['./burial-permit-detail-edit.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {showError: true},
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-GB'
    },
    {
      provide: DateAdapter, useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_FORMATS
    },
  ],
})
export class BurialPermitDetailEditComponent implements  OnInit,OnDestroy{ 
  pageSize = 50; 
  pageIndex = 0;
  options!: any[] ;
  isLoading = false;
  filteredOptions: Observable<string[]>= of([]);
  dcrChekbox! :boolean;
  private cardNumberSubscription!: Subscription |undefined;
  resultsLength = 0;
  selectedCemeteryDescription!: string;
  selectedLocalityDescription!: string;
  selectedMassLocalityDescription!: string;
  selectedGenderDescription!: string;
  selectedMaritalStatusDescription !: string;
  selectedDivisionDescription !: string;
  selectedDSectionDescription!: string;
  selectedDCompartmentDescription!: string;
  selectedDUndertakerDescription!: string;
  selectedBurialTypeDescription!: string;

  countries : ICountryDto[] = [];
  cemeteryData! : ICemeteryDto;
  cemeteries : ICemeteryDto[] = [];
  divisions: IDivisionDto[] = [];
  sections: ISectionDto[] = [];
  compartments: ICompartmentDto[] = [];
  graves: IGraveDto[] = [];
  originalGraves: IGraveDto[] = [];
  graveLevels : IGraveLevelsDto[] =[];
  localities : ILocalityDto[] = [];
  massLocalities : ILocalityDto[] = [];
  genders : IGenderDto[] = [];
  statuses : IMaritalStatusDto[] = [];
  undertakers : IUndertakerDto[]=[];
  burialTypes : IBurialTypeDto[] = [];
  originalsCompartments! : ICompartmentDto[];
  
  isStepEditable: boolean = false;
  stepLabelName!: string;
  allStepsValid: boolean = false;
  burialType_firstchange: boolean = true;

  firstFormGroup!: FormGroup;
  secondFormGroup!: FormGroup;
  thirdFormGroup!: FormGroup;

  selectedGrave: IGraveDto | null = null;
  filteredUndertakers!: Observable<IUndertakerDto[]>;
  placeDeathOptions: Observable<string[]> | undefined;
    burialRightOptions:Observable<string[]> | undefined;
    placeoptions: string[] = [
      'Mater Dei Hospital, Msida',
      'St. Vincent de Paule Residence, Luqa',
      'Sir Anthony Mamo Oncology Centre, Msida',
      'Karin Grech Hospital, Pieta',
      'St.Thomas Community Living, Marsaskala',
      'Good Samaritan Hospital, Sir Luigi Preziozi Square, St. Paul\'s Bay',
      'Casa Antonia, Triq il-Papa Alessandru VII, Balzan',
      'Casa Arkati Home for the Elderly, Constitution Street, Mosta'
    ];
    rightOptions:string[] = [
      'Common Grave',
      'Private Grave',
      'Owner',
      'Owners Consent',
      'Co-Owners Consent',
      'One of the Heirs',
      'Heirs Consent',
    ];
  updatedObj : IBurialPermitUpdateDto = {
    burialPermit : {
      burialPermitStatusId: 0, 
      deathDate: new Date(), 
      burialDate: new Date(), 
      issuedDate: new Date(new Date().setDate(new Date().getDate() + 1)), 
      placeOfDeath: '', 
      causeOfDeath: '', 
      deceasedIdCard: '', 
      deceasedName: '', 
      deceasedSurname: '', 
      deceasedAddress : '',
      burialRight: '', 
      cemeteryName: '', 
      divisionName: '', 
      sectionName: '',
      compartmentName: '', 
      graveNumber: 0, 
      graveLevel: '', 
      graveNo: '',
    },
    burial:{
      refNo : '',
      deathDate : new Date(),
      burialDate : new Date(),
      placeOfDeath : '',
      causeOfDeath : '',
      burialStatusId: 0,
      burialTypeId : 0,
      deceasedIdCardNumber : '',
      timeOfDeath : '',
      massTime : '',
      massLocalityId: 0,
      undertakerId : 0,
      odcIsreceived : false,
    },
    graveDetails : {
      burialRight : '', 
      cemeteryId : 0,
      divisionId : 0,
      sectionId : 0,
      graveId : 0,
      levelId : 0,
      compartmentId : 0,
    },
    deceased:{
      idCardNumber: '', 
      name: '', 
      surname: '', 
      address: '', 
      documentTypeId: 0, 
      maritalStatusId: 0, 
      genderId: 0, 
      spouseName: '', 
      spouseSurname: '', 
      dateOfBirth: new Date(), 
      placeOfBirth: '', 
      localityId: 0, 
      profession: '', 
      fatherName: '', 
      fatherSurname: '', 
      fatherStatus: '', 
      motherName: '',
      motherSurname: '', 
      motherStatus: '' 
    }
  };
  burialPermitObject: IBurialPermitDto = { 
    burialId: 0,
    refNo: '',
    permitNumber: '',
    deceasedIdCardNumber:'',
    deceasedName: '',
    deceasedSurName: '',
    deceasedDateOfBirth : new Date,    
    placeOfDeath: '',
    causeOfDeath: '',
    cemeteryId: 0,
    cemeteryName:'',
    divisionId: 0,
    divisionName:'',
    sectionId: 0,
    sectionName:'',
    compartmentId: 0,
    compartmentName:'',
    graveNumber : 0,  
    graveNo : '',
    graveLevel :'',
    deathDate: new Date(),
    burialDate: new Date(), 
    burialStatusId : 2,
    burialTypeId : 2,
    burialPermitStatusId : 1,
    burialPermitStatusName : '',
    burialRight : '',    
    documentTypeId:0,
    deceasedAdress : '',
    deceasedProfession : '',    
    deceasedPlaceOfBirth: '',
    deceasedMaritalStatusId : 0,
    genderId:0,
    deceasedSpouseName: '',
    deceasedSpouseSurname:'',
    deceasedFatherName: '',
    deceasedFatherSurname: '',
    deceasedFatherStatus: '',
    deceasedMotherName: '',
    deceasedMotherSurname: '',
    deceasedMotherStatus : '',
    levelName:'',
    levelId :0,
    graveTypeId : 0,
    localityId:0,
    massTime :"",
    massLocalityId : 0,
    undertakerId : 0,
    issuedDate: new Date(new Date().setDate(new Date().getDate() + 1)), 
    timeOfDeath :"",
    country:'',
    burialLocation:'',
    healthOfficer:'',
    ocdIsReceived : false,
    odcReceived: new Date(),
    sourceSystemId: 0,
    deceased: {
      idCardNumber: '',
        name: '',
        surname: '',
        address: '',
        documentTypeId: 0,
        maritalStatusId: 0,
        genderId: 0,
        spouseName: '',
        spouseSurname: '',
        dateOfBirth: new Date(),
        placeOfBirth: '',
        localityId: 0,
        profession: '',
        fatherName: '',
        fatherSurname: '',
        fatherStatus: '',
        motherName: '',
        motherSurname: '',
        motherStatus: '',
    }   

   };

  myFilter = (d: Date | null): boolean => {
    if (!d) {
      return false; 
    }  
    const today = new Date();
    today.setDate(today.getDate() );  
    return !(d > today);
  };

  myFilter2 = (d: Date | null): boolean => {
    if (!d) {
      return false; 
    }  
    const today = new Date();
    today.setDate(today.getDate() );  
    return !(d < today);
  };
  
  displayedColumns: string[] = [
    'id', 
    'graveNo', 
    'referenceNumber', 
    'dhFileNo',  
    'compartment_id',
    'isActive',
    'gravesize',
    'firstOwner',
    'expand',
    'select'];
    
  dataSource: MatTableDataSource<IGraveDto>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  displayedColumnsWithExpand = [...this.displayedColumns];
  expandedElement: IGraveDto | undefined;
  
  
  stepperOrientation: Observable<StepperOrientation>;
  snackBarRef: MatSnackBarRef<any> | null = null;
  constructor(
    private _formBuilder: FormBuilder,    
    private breakpointObserver: BreakpointObserver, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private burialPermitService : BurialPermitService,
    private cemeteryService : CemeteryService,
    private localityService : LocalityService,
    private genderService : GenderService,
    private maritalStatusService : MaritalStatusService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private internalService : InternalCommunicationService,    
    private divisionService : DivisionService,
    private sectionService : SectionService,
    private compartmentService : CompartmentService,
    private graveService : GraveService,
    private undertakersService : UndertakerService,
    private burialTypeService : BurialTypeService,
    private commonService : CommonService
    ){
    this.burialPermitObject = data;    
    this.stepperOrientation = breakpointObserver
    .observe('(min-width: 800px)')
    .pipe(map(({matches}) => (matches ? 'horizontal' : 'vertical')));
    

     const grave : IGraveDto[] = []; 
     this.dataSource = new MatTableDataSource(grave);
  }
  ngOnDestroy(): void {
    this.cardNumberSubscription?.unsubscribe();
  }
 
  expandCollapse(deceasedObject: any): void {
    this.expandedElement = this.expandedElement === deceasedObject ? null : deceasedObject;
}
  ngOnInit() {
    
    this.getAllCemeteries();
    this.getAllCompartments();
    this.getDivisions();
    this.getSections();
    this.getCompartment();
    this.getGraves();
    this.getAllLocalities();
    this.getAllGender();
    this.getAllMaritalStatus();
    this.getAllUndertakers();
    this.GetAllBurialTypes();
    this.getAllCountries();
    this.filterSelectedGrave();
    this.getfilterSelectedGraves();


    this.firstFormGroup = this._formBuilder.group({
      idCardTypeCtrl: [this.burialPermitObject.deceased.documentTypeId, [Validators.required, Validators.min(1)]],  
      cardNumberCtrl: [{value: this.burialPermitObject.deceasedIdCardNumber,disabled: true}, [Validators.required,Validators.pattern('[a-zA-Z0-9]*')]],
      nameCtrl: [this.burialPermitObject.deceasedName, [Validators.required,Validators.pattern(/^[a-zA-Z,().'`" \/\\-]*$/)]],
      surnameCtrl: [this.burialPermitObject.deceasedSurName, [Validators.required,Validators.pattern(/^[a-zA-Z,().'`" \/\\-]*$/)]],
      localityCtrl: [this.burialPermitObject.deceased.localityId, [Validators.required, Validators.min(1)]],
      addressCtrl: [this.burialPermitObject.deceased.address, [Validators.pattern(/^[a-zA-Z0-9,().'`" \/\\-]*$/)]],
      professionCtrl: [this.burialPermitObject.deceased.profession, [Validators.pattern(/^[a-zA-Z0-9,().'`" \/\\-]*$/)]],
      dateOfBirthCtrl : [this.burialPermitObject.deceased.dateOfBirth, Validators.required],
      placeOfBirthCtrl : [this.burialPermitObject.deceased.placeOfBirth, [Validators.pattern(/^[a-zA-Z0-9,().'`" \/\\-]*$/)]],
      maritalStatusCtrl : [this.burialPermitObject.deceased.maritalStatusId, [Validators.required, Validators.min(1)]],
      genderCtrl : [this.burialPermitObject.deceased.genderId, [Validators.required, Validators.min(1)]],
      spouseNameCtrl: [this.burialPermitObject.deceased.spouseName, [Validators.pattern(/^[a-zA-Z,().'`" \/\\-]*$/)]],
      spouseSurnameCtrl: [this.burialPermitObject.deceased.spouseSurname, [Validators.pattern(/^[a-zA-Z,().'`" \/]*$/)]],
      fatherNameCtrl: [this.burialPermitObject.deceased.fatherName, [Validators.required,Validators.pattern(/^[a-zA-Z,().'`" \/\\-]*$/)]],
      fatherSurnameCtrl: [this.burialPermitObject.deceased.fatherSurname, [Validators.required,Validators.pattern(/^[a-zA-Z,().'`" \/\\-]*$/)]],
      fatherStatusCtrl : [this.burialPermitObject.deceased.fatherStatus],
      motherNameCtrl: [this.burialPermitObject.deceased.motherName, [Validators.required,Validators.pattern(/^[a-zA-Z,().'`" \/\\-]*$/)]],
      motherSurnameCtrl: [this.burialPermitObject.deceased.motherSurname, [Validators.required,Validators.pattern(/^[a-zA-Z,().'`" \/\\-]*$/)]],
      motherStatusCtrl : [this.burialPermitObject.deceased.motherStatus],
      remarksCtrl : [this.burialPermitObject ? this.burialPermitObject.remarks: '']
     
    });

    this.secondFormGroup = this._formBuilder.group({
      burialTypeCtrl : [this.burialPermitObject.burialTypeId, [Validators.required, Validators.min(1)]],
      burialRefNoCtrl: [this.burialPermitObject.refNo, [Validators.pattern('[a-zA-Z0-9]*')]],
      deathDateCtrl : [this.burialPermitObject.deathDate, Validators.required],
      deathTimeCtrl : [this.burialPermitObject.timeOfDeath, [Validators.required, Validators.pattern(/^(?:[01]\d|2[0-3]):[0-5]\d$/)]],
      placeOfDeathCtrl: [this.burialPermitObject.placeOfDeath,  Validators.required],
      causeOfDeathCtrl : [this.burialPermitObject.causeOfDeath, [Validators.required,  Validators.pattern(/^[a-zA-Z0-9,().'`" \/\\-]*$/)]],
      massTimeCtrl : [this.burialPermitObject.massTime, [Validators.pattern(/^(?:[01]\d|2[0-3]):[0-5]\d$/)]],
      massLocalityCtrl : [this.burialPermitObject.massLocalityId],            
      burialPermitNoCtrl: [{value: this.burialPermitObject.permitNumber, disabled: true}, [Validators.required, Validators.pattern('[0-9]*')]],
      burialDateCtrl : [this.burialPermitObject.burialDate, Validators.required],
      burialRightsCtrl : [this.burialPermitObject.burialRight, [Validators.pattern(/^[a-zA-Z0-9,().'`" \/\\-]*$/)]],
      undertakerCtrl : [this.burialPermitObject.undertakerId, [Validators.required, Validators.min(1)]],  
      issuedDateCtrl : [this.burialPermitObject.issuedDate, Validators.required],            
      
    });

    this.thirdFormGroup = this._formBuilder.group({
      cemeteryCtrl : [this.burialPermitObject.cemeteryId],
      divisionCtrl : [this.burialPermitObject.divisionId],
      sectionCtrl : [this.burialPermitObject.sectionId],
      compartmentCtrl : [this.burialPermitObject.compartmentId],
      countryCtrl : [this.burialPermitObject ? this.burialPermitObject.country: ''],     
      burialLocationCtrl : [this.burialPermitObject ? this.burialPermitObject.burialLocation: ''],     
      healthOfficerCtrl : [this.burialPermitObject ? this.burialPermitObject.healthOfficer: ''],  
      graveCtrl : [this.burialPermitObject ? this.burialPermitObject.graveNo : '' ],   

    });
  
    this.subscribeChanges();

    this.onBurialTypeChange();

    this.checkMaritalStatus();
    this.placeDeathOptions = this.secondFormGroup.get('placeOfDeathCtrl')?.valueChanges.pipe(startWith(''),
    map(value => this._filter1(value || ''))
  );
  this.burialRightOptions = this.secondFormGroup.get('burialRightsCtrl')?.valueChanges.pipe(startWith(''),
  map(value => this._filter2(value || ''))
);
this.filteredUndertakers = this.secondFormGroup.get('undertakerCtrl')!.valueChanges.pipe(
  startWith(''),
  map(value => {
    const undertakerName = typeof value === 'string' ? value : this.displayUndertaker(value);
    return this.filterUndertakerList(undertakerName);
  })
);
  }
  subscribeChanges() {
    // First Form Group
   
    this.firstFormGroup.get('idCardTypeCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.deceased.documentTypeId = value;
    });
    this.firstFormGroup.get('cardNumberCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.deceased.idCardNumber = value;
      this.burialPermitObject.deceasedIdCardNumber = value;
      
    });
    this.firstFormGroup.get('nameCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.deceased.name = value;
      this.burialPermitObject.deceasedName =value
      
    });
    this.firstFormGroup.get('surnameCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.deceased.surname = value;
      this.burialPermitObject.deceasedSurName = value;
      
    });
    this.firstFormGroup.get('localityCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.deceased.localityId = value;
      this.burialPermitObject.localityId = value;
    });
    this.firstFormGroup.get('addressCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.deceased.address = value;
      this.burialPermitObject.deceasedAdress = value;
    });
    this.firstFormGroup.get('professionCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.deceased.profession = value;
      this.burialPermitObject.deceasedProfession =value ;
    });
    this.firstFormGroup.get('dateOfBirthCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.deceased.dateOfBirth = this.utcDate(value._d);
      this.burialPermitObject.deceasedDateOfBirth = this.utcDate(value._d);
    });
    this.firstFormGroup.get('placeOfBirthCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.deceased.placeOfBirth = value;
      this.burialPermitObject.deceasedPlaceOfBirth =value ;
    });
    this.firstFormGroup.get('maritalStatusCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.deceased.maritalStatusId = value; 
    });
    this.firstFormGroup.get('genderCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.deceased.genderId = value;
    });
    this.firstFormGroup.get('spouseNameCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.deceased.spouseName = value;
      this.burialPermitObject.deceasedSpouseName = value;
    });
    this.firstFormGroup.get('spouseSurnameCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.deceased.spouseSurname = value;
      this.burialPermitObject.deceasedSpouseSurname = value;
    });
    this.firstFormGroup.get('fatherNameCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.deceased.fatherName = value;
      this.burialPermitObject.deceasedFatherName = value;
    });
    this.firstFormGroup.get('fatherSurnameCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.deceased.fatherSurname = value;
      this.burialPermitObject.deceasedFatherSurname = value;
    });
    this.firstFormGroup.get('fatherStatusCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.deceased.fatherStatus = value;
      this.burialPermitObject.deceasedFatherStatus = value;
    });
    this.firstFormGroup.get('motherNameCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.deceased.motherName = value;
      this.burialPermitObject.deceasedMotherName = value;
    });
    this.firstFormGroup.get('motherSurnameCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.deceased.motherSurname = value;
      this.burialPermitObject.deceasedMotherSurname = value;
    });
    this.firstFormGroup.get('motherStatusCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.deceased.motherStatus = value;
      this.burialPermitObject.deceasedMotherStatus = value;
    });
    this.firstFormGroup.get('remarksCtrl')?.valueChanges.subscribe((value) => {         
      this.burialPermitObject.remarks = value;
    });
  
    // Second Form Group
    this.secondFormGroup.get('burialTypeCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.burialTypeId = value;      
    });
    this.secondFormGroup.get('burialRefNoCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.refNo = value;      
    });
    this.secondFormGroup.get('deathDateCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.deathDate = this.utcDate(value._d);      
    });
    this.secondFormGroup.get('burialDateCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.burialDate = this.utcDate(value._d);
      
    });
    this.secondFormGroup.get('placeOfDeathCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.placeOfDeath = value;
      
    });
    this.secondFormGroup.get('causeOfDeathCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.causeOfDeath = value;
      
    });
    this.secondFormGroup.get('deathTimeCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.timeOfDeath = value;
    });
   
    
    this.secondFormGroup.get('massTimeCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.massTime = value;
    });
    this.secondFormGroup.get('massLocalityCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.massLocalityId = value;
    });
   
    this.secondFormGroup.get('undertakerCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.undertakerId = value;
    });
    this.secondFormGroup.get('burialRightsCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.burialRight = value;       
    });
    this.secondFormGroup.get('issuedDateCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.issuedDate = this.utcDate(value._d);
    });   
  
    // Third Form Group
    this.thirdFormGroup.get('cemeteryCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.cemeteryId = value; 
      
    });
    this.thirdFormGroup.get('divisionCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.divisionId = value;
    });
    this.thirdFormGroup.get('divisionCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.divisionId = value;
    });
    this.thirdFormGroup.get('sectionCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.sectionId = value;
    });
    this.thirdFormGroup.get('compartmentCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.compartmentId = value;
    });
    this.thirdFormGroup.get('countryCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.country = value;
    });
    this.thirdFormGroup.get('burialLocationCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.burialLocation = value;
    });
    this.thirdFormGroup.get('healthOfficerCtrl')?.valueChanges.subscribe((value) => {     
      this.burialPermitObject.healthOfficer = value;
    });
    this.thirdFormGroup.get('graveCtrl')?.valueChanges.subscribe((value) => {          
      if (!value) {
        this.updateFilteredOptions('graveCtrl');
        this.handleInvalidOrEmptyInput();
      }else if (!this.originalGraves.some(grave => grave.graveNo === value)) {
        this.handleInvalidOrEmptyInput();
      }
    });    
    
  }

  getAllCompartments(){
    this.compartmentService.getAllCompartments().subscribe( 
      (response : ICompartmentDto[])=>{
        this.originalsCompartments = response;
      }, (error) =>{
        console.error('error retieving compartment service:', error);
      }) 
  }

  checksteps(){
    this.checkBurialTypeForSteps()
  } 
  
  checkBurialTypeForSteps(){
    const burialTypeCtrl = this.secondFormGroup.get('burialTypeCtrl');
    if (burialTypeCtrl) {
      const selectedBurialType = burialTypeCtrl.value;
      if ([1, 2, 3, 4].includes(selectedBurialType)){
        if(this.firstFormGroup.valid && this.secondFormGroup.valid && this.thirdFormGroup.valid){
          return this.allStepsValid = true;
        }else{
          return this.allStepsValid = false;          
        }
      } else {
        if(this.firstFormGroup.valid && this.secondFormGroup.valid){
          return this.allStepsValid = true;
        }else{
          return this.allStepsValid = false;
        }
      }
    }else{
      return this.allStepsValid = false;
    }
  }

  getAllCemeteries(){
     this.cemeteryService.getAllCemeteries().subscribe(
      (response : ICemeteryDto[]) => {
        this.cemeteries = response;
      });
  }
  getDivisions(){
    this.divisionService.getDivisionsByCementeryId(this.burialPermitObject.cemeteryId).subscribe(
      (response : IDivisionDto[]) => {
        this.divisions = response;
      });
  }
  getSections(){
    this.sectionService.GetSectionByDivisionId(this.burialPermitObject.divisionId).subscribe(
      (response : ISectionDto[]) => {
        this.sections = response;
      });
  }
  getCompartment(){
    this.compartmentService.GetCompartmentBySectionId(this.burialPermitObject.sectionId).subscribe(
      (response : ICompartmentDto[]) =>{
        this.compartments = response;
      })
  }
  getGraves(){
    this.graveService.getGraveByCompartmentId(this.burialPermitObject.compartmentId).subscribe( 
      (response : IGraveDto[]) => {
        this.graves = response;
        this.graves.forEach(grave => {

          if ( grave.compartmentId ) {
          grave.compartmentName = this.originalsCompartments.find(com => com.id === grave.compartmentId)?.description;
          }          
          if (grave.graveOwnershipsDto && grave.graveOwnershipsDto.length > 0) {
            grave.firstOwner = `${grave.graveOwnershipsDto[0].graveOwnerDto.ownerName.trim()} ${grave.graveOwnershipsDto[0].graveOwnerDto.ownerName.trim()}`;
          }
          if (grave.graveOwnershipsDto && grave.graveOwnershipsDto.length > 1) {
            grave.secondOwner = `${grave.graveOwnershipsDto[1].graveOwnerDto.ownerName.trim()} ${grave.graveOwnershipsDto[1].graveOwnerDto.ownerName.trim()}`;
          }
          if (grave.graveOwnershipsDto && grave.graveOwnershipsDto.length > 2) {
            grave.thirdOwner = `${grave.graveOwnershipsDto[2].graveOwnerDto.ownerName.trim()} ${grave.graveOwnershipsDto[2].graveOwnerDto.ownerName.trim()}`;
          }
        });
        
        this.dataSource = new MatTableDataSource(this.graves);       
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        const currentGrave = this.graves.find(g => g.id === this.burialPermitObject.graveNumber);
        if (currentGrave){            
          this.selectedGrave = currentGrave;
          const graveLevelArrays = this.graves.map(grave => grave.graveLevelDto).filter(level => level);
          this.graveLevels = graveLevelArrays.flatMap(level => level || []);  
                      
        }else{
          console.warn('No currentGrave found.'); 
        }
      }
      )
  }
 
  getAllLocalities(){
    this.localityService.getAllLocalities().subscribe(
      (response: ILocalityDto[]) => {
        this.localities = response;  
        this.massLocalities = response;      
      },
      (error) => {        
        console.error('Error adding localities: ', error);        
      }
      );
  }
  getAllGender(){
    this.genderService.getAllGender().subscribe(
      (response: IGenderDto[]) => {
        this.genders = response;        
      },
      (error) => {        
        console.error('Error retrieving genders: ', error);        
      }
      );
  }
  getAllMaritalStatus(){
    this.maritalStatusService.getAllMaritalStatus().subscribe(
      (response: IMaritalStatusDto[]) => {
        this.statuses = response;        
      },
      (error) => {        
        console.error('Error retrieving marital status: ', error);        
      }
      );
  }

  // getAllUndertakers(){
  //   this.undertakersService.getAllUndertakers().subscribe(
  //     (response : IUndertakerDto[]) => {
  //       this.undertakers = response;
  //     }, (error) =>{
  //       console.error('Error retrieving undertakers');
  //     } 
  //     );
  // }

  getAllUndertakers() {
    this.undertakersService.getAllUndertakers().subscribe(
      (response: IUndertakerDto[]) => {
        this.undertakers = response;
        // Set the initial value for pre-population if needed
        const preselectedId = this.burialPermitObject.undertakerId;
        const selectedUndertaker = this.undertakers.find(u => u.id === preselectedId);
        this.secondFormGroup.get('undertakerCtrl')!.setValue(selectedUndertaker ? selectedUndertaker.id : '');
      },
      (error) => {
        console.error('Error retrieving undertakers');
      }
    );
  }

  GetAllBurialTypes(){
    this.burialTypeService.getAllBurialTypes().subscribe(
      (response: IBurialTypeDto[]) => {
        this.burialTypes = response;  
      },
      (error) => {        
        console.error('Error retrieving burial types: ', error);        
      }
      );
  }

  getAllCountries(){
    this.commonService.getAllCountries().subscribe(
      (response : ICountryDto[]) => {
        this.countries = response;

        
        const countrySelected = this.countries.find(c => c.name === this.burialPermitObject.country);   
         
        if( countrySelected ){ 
         const id = countrySelected.id;            
         this.thirdFormGroup.get('countryCtrl')!.setValue(id);
         this.onCountrySelectionChange(id);
        }else{
          this.thirdFormGroup.get('countryCtrl')!.setValue('');
          this.burialPermitObject.country = '';
        }
      }, (error) =>{
        console.error('Error retrieving countries');
      } 
      );

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  
    if (this.dataSource.paginator) {      
      this.dataSource.paginator.firstPage();
    }
    this.selectedGrave = null;
  }
  onCemeteryChange(){
    const selectedCemetery = this.cemeteries.find(cem => cem.code === this.burialPermitObject.cemeteryId);    
    if (selectedCemetery && selectedCemetery.code != 0) {
      this.thirdFormGroup.get('divisionCtrl')?.enable();
      this.thirdFormGroup.get('sectionCtrl')?.disable();
      this.thirdFormGroup.get('compartmentCtrl')?.disable();

      this.burialPermitObject.cemeteryName = selectedCemetery.name;
      this.getdivisionsByCemeteryId(selectedCemetery.code);
      this.getGravesByGraveNo(selectedCemetery.code,this.burialPermitObject.divisionId, this.burialPermitObject.sectionId,this.burialPermitObject.compartmentId);  
    } else {
      this.thirdFormGroup.get('divisionCtrl')?.disable();
      this.thirdFormGroup.get('sectionCtrl')?.disable();
      this.thirdFormGroup.get('compartmentCtrl')?.disable();
      this.divisions = this.divisions;
    }
  }

  onDivisionChange() {         
    const selectedDivision = this.divisions.find(div => div.id === this.burialPermitObject.divisionId);
    if (selectedDivision && selectedDivision.id != 0) {
      this.thirdFormGroup.get('sectionCtrl')?.enable();
      this.thirdFormGroup.get('compartmentCtrl')?.disable();
      
      this.getSectionsByCemeteryIdAndDivisionId(selectedDivision.id,selectedDivision.cemeteryId);
          
      this.getGravesByGraveNo(selectedDivision.cemeteryId,selectedDivision.id, this.burialPermitObject.sectionId,this.burialPermitObject.compartmentId);
      
    } else {
      this.thirdFormGroup.get('sectionCtrl')?.disable();
      this.thirdFormGroup.get('compartmentCtrl')?.disable();
      this.sections = this.sections;
    }
  }
  
  onSectionChange() {         
    const selectedSection = this.sections.find(sec => sec.id === this.burialPermitObject.sectionId);
    if (selectedSection && selectedSection.id != 0) {  
      this.thirdFormGroup.get('compartmentCtrl')?.enable();     
      
      this.getCompartmentsByCemeteryIdAndDivisionIdAndSection(selectedSection.cemeteryId,selectedSection.divisionId,this.burialPermitObject.sectionId);
      this.getGravesByGraveNo(selectedSection.cemeteryId,selectedSection.divisionId, selectedSection.id,this.burialPermitObject.compartmentId);
    
      
    } else {
      this.thirdFormGroup.get('compartmentCtrl')?.disable();
      this.compartments = this.compartments;
    }
  }
  onCompartmentChange() {         
    const selectedCompartment = this.compartments.find(com => com.id === this.burialPermitObject.compartmentId);
    if (selectedCompartment && selectedCompartment.id != 0) {
      this.thirdFormGroup.get('graveCtrl')?.enable();
      this.getGravesByGraveNo(selectedCompartment.cemeteryId,selectedCompartment.divisionId,selectedCompartment.sectionId,selectedCompartment.id);
      
      this.graves.forEach(grave => {

        if ( grave.compartmentId ) {
          grave.compartmentName = this.originalsCompartments.find(com => com.id === grave.compartmentId)?.description;
        }   
        if (grave.graveOwnershipsDto && grave.graveOwnershipsDto.length > 0) {
          grave.firstOwner = `${grave.graveOwnershipsDto[0].graveOwnerDto.ownerName.trim()} ${grave.graveOwnershipsDto[0].graveOwnerDto.ownerSurname.trim()}`;
        }
        if (grave.graveOwnershipsDto && grave.graveOwnershipsDto.length > 1) {
          grave.secondOwner = `${grave.graveOwnershipsDto[1].graveOwnerDto.ownerName.trim()} ${grave.graveOwnershipsDto[1].graveOwnerDto.ownerSurname.trim()}`;
        }
        if (grave.graveOwnershipsDto && grave.graveOwnershipsDto.length > 2) {
          grave.thirdOwner = `${grave.graveOwnershipsDto[2].graveOwnerDto.ownerName.trim()} ${grave.graveOwnershipsDto[2].graveOwnerDto.ownerSurname.trim()}`;
        }
      });
      this.selectedDCompartmentDescription = selectedCompartment.description;
      const graveLevelArrays = this.graves.map(grave => grave.graveLevelDto).filter(dto => dto);
      this.graveLevels = graveLevelArrays.flatMap(levels => levels || []);
          
      this.burialPermitObject.compartmentName = selectedCompartment.description;
    
    } else {
      this.thirdFormGroup.get('graveCtrl')?.disable();     
      this.graveLevels = [];
      this.fillTable(this.graves);
    }
  }
  onLocalitySelectionChange() {
    const selectedLocalityId = this.burialPermitObject.localityId;
    const selectedLocality = this.localities.find(loc => loc.id === selectedLocalityId);    
    this.selectedLocalityDescription = selectedLocality ? selectedLocality.description : '';
    
  }
  onMassLocalitySelectionChange() {
    const selectedmassLocalityId = this.burialPermitObject.massLocalityId;
    const selectedmassLocality = this.massLocalities.find(loc => loc.id === selectedmassLocalityId);    
    this.selectedMassLocalityDescription = selectedmassLocality ? selectedmassLocality.description : '';    
  }
  onGenderSelectionChange() {
    const selectedGenderId = this.burialPermitObject.genderId;
    const selectedGender = this.genders.find(g => g.id === selectedGenderId);    
    this.selectedGenderDescription = selectedGender ? selectedGender.description : '';
    
  }
  onMaritalStatusSelectionChange() {
    const selectedMaritalStatusId = this.burialPermitObject.deceasedMaritalStatusId;
    const selectedMaritalStatus = this.statuses.find(g => g.id === selectedMaritalStatusId);
    this.selectedMaritalStatusDescription = selectedMaritalStatus ? selectedMaritalStatus.description : '';    

    this.checkMaritalStatus();
  }

  checkMaritalStatus(){
    const maritalStatusCtrl = this.firstFormGroup.get('maritalStatusCtrl');
    if (maritalStatusCtrl) {
      const selectedMaritalStatusCtrl = maritalStatusCtrl.value;
    
      if ([1, 3, 4].includes(selectedMaritalStatusCtrl)) {
        // Disable spouseNameCtrl and spouseSurnameCtrl
        this.firstFormGroup.get('spouseNameCtrl')!.reset();
        this.firstFormGroup.get('spouseNameCtrl')!.disable();
        this.firstFormGroup.get('spouseSurnameCtrl')!.reset();
        this.firstFormGroup.get('spouseSurnameCtrl')!.disable();
      } else if ([6, 9].includes(selectedMaritalStatusCtrl)){
        // Disable spouseNameCtrl and spouseSurnameCtrl
        this.firstFormGroup.get('spouseNameCtrl')!.setValue('N/A');
        this.firstFormGroup.get('spouseNameCtrl')!.disable();
        this.firstFormGroup.get('spouseSurnameCtrl')!.setValue('N/A');
        this.firstFormGroup.get('spouseSurnameCtrl')!.disable();
      }else{
        // Enable spouseNameCtrl and spouseSurnameCtrl
        this.firstFormGroup.get('spouseNameCtrl')!.enable();
        this.firstFormGroup.get('spouseSurnameCtrl')!.enable();
      }
    }
  }
  
  onUndertakerSelectionChange(selectedId: number) {
    // Update burialPermitObject or handle selection change
    this.burialPermitObject.undertakerId = selectedId;
    const selectedUndertaker = this.undertakers.find(u => u.id === selectedId);
    this.selectedDUndertakerDescription = selectedUndertaker ? selectedUndertaker.name : '';
  }
  filterSelectedGrave() {
    // Ensure the graves are loaded before filtering
    if (this.graves.length > 0 && this.burialPermitObject.graveNumber) {
        const filteredGraves = this.graves.filter(
            (grave) => grave.id === this.burialPermitObject.graveNumber
        );
        
        // Set the filtered graves to the table's data source
        this.dataSource = new MatTableDataSource(filteredGraves);

        // Initialize paginator and sorting for the table if necessary
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }
}
getfilterSelectedGraves() {
  this.graveService.getGraveByCompartmentId(this.burialPermitObject.compartmentId).subscribe(
      (response: IGraveDto[]) => {
          this.graves = response;
          this.graveLevelsRemap();
          
          // Once graves are loaded, filter for the selected grave
          this.filterSelectedGrave();
          this.onCheckboxChange;
      },
      (error) => {
          console.error('error retrieving graves:', error);
      }
  );
}


  onCheckboxChange(event: any, grave: IGraveDto) {
    if (event.checked) {
      
      this.selectedGrave = grave; 
      this.burialPermitObject.graveNumber = this.selectedGrave.id
      this.burialPermitObject.graveNo = this.selectedGrave.graveNo;
      this.graveLevelsRemap();
      const matchingGraveLevels = this.graveLevels.filter(x => x.graveId === this.selectedGrave?.id);
    
      this.openGraveLevelComponent(grave);
    } 
    else {
      this.selectedGrave = null; 
    }
  }
  graveLevelsRemap(){
    const graveLevelArrays = this.graves.map(grave => grave.graveLevelDto).filter(level => level);
    this.graveLevels = graveLevelArrays.flatMap(level => level || []);  

  }

  onOdcCheckboxChange(event: any) {
    this.dcrChekbox = event.checked;
  }

  isSelected(grave: IGraveDto): boolean {
    return this.selectedGrave === grave;
  }

  openGraveLevelComponent(grave: IGraveDto) {
    const dialogRef = this.dialog.open(GraveLevelsComponent, {
      data: grave,
      width:'50%'
    });

    dialogRef.afterClosed().subscribe(selectedLevel => {       
      if (selectedLevel) {
        this.burialPermitObject.levelId = selectedLevel.levelId;
        this.burialPermitObject.levelName = selectedLevel.levelDescription;
        this._snackBar.open(`Selected Level: ${selectedLevel.levelId} - ${selectedLevel.levelDescription}`, 'Close', {
          duration: 10000 
      });        
      }
  });
  }
  openResponseModal(reissue: IResponseModal): void {
    const dialogRef = this.dialog.open(ResponsemodalComponent, {
      width: '250px',
      data: reissue
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  burialUpdateClick():void{    
    this.populateUpdateObject();
    const id =+ this.burialPermitObject.permitNumber;
    this.burialPermitService.updateBurialPermit(id, this.updatedObj).subscribe(
      (response : IResponseModal) => {        
        this.openResponseModal(response);
        this.internalService.emitRefreshPermitTable();
        
        console.log('Burial Permit updated successfully');   
      },
      (error) => {
        console.error('Error occurred while updating burial permit:', error);        
      }
  )
}  
  
populateUpdateObject(){
  
  this.updatedObj.burialPermit = {
    burialPermitStatusId: this.burialPermitObject.burialPermitStatusId,
    deathDate: this.burialPermitObject.deathDate,
    burialDate: this.burialPermitObject.burialDate,
    issuedDate: this.burialPermitObject.issuedDate,
    placeOfDeath: this.burialPermitObject.placeOfDeath,
    causeOfDeath: this.burialPermitObject.causeOfDeath,
    deceasedIdCard: this.burialPermitObject.deceasedIdCardNumber,
    deceasedName: this.burialPermitObject.deceasedName, 
    deceasedSurname: this.burialPermitObject.deceasedSurName,
    deceasedAddress : this.burialPermitObject.deceased.address,
    burialRight: this.burialPermitObject.burialRight,
    cemeteryName: this.burialPermitObject.cemeteryName,
    divisionName: this.burialPermitObject.divisionName,
    sectionName: this.burialPermitObject.sectionName,
    compartmentName: this.burialPermitObject.compartmentName,
    graveNumber: this.burialPermitObject.graveNumber,
    graveLevel: this.burialPermitObject.graveLevel,
    graveNo : this.burialPermitObject.graveNo,
    country : this.burialPermitObject.country,
    burialLocation : this.burialPermitObject.burialLocation,
    healthOfficer : this.burialPermitObject.healthOfficer,
    remarks : this.burialPermitObject.remarks,
  };    

  this.updatedObj.burial = {
    refNo: this.burialPermitObject.refNo,
    deathDate: this.burialPermitObject.deathDate,
    burialDate: this.burialPermitObject.burialDate,
    placeOfDeath: this.burialPermitObject.placeOfDeath,
    causeOfDeath: this.burialPermitObject.causeOfDeath,    
    burialStatusId: 2 ,//in review
    burialTypeId: this.burialPermitObject.burialTypeId, 
    timeOfDeath : this.burialPermitObject.timeOfDeath,
    massTime : this.burialPermitObject.massTime,
    massLocalityId : this.burialPermitObject.massLocalityId,
    undertakerId : this.burialPermitObject.undertakerId,
    deceasedIdCardNumber: this.burialPermitObject.deceasedIdCardNumber,
    odcIsreceived : this.dcrChekbox,
    
  };    

  this.updatedObj.graveDetails = {
    burialRight: this.burialPermitObject.burialRight,
    cemeteryId: this.burialPermitObject.cemeteryId,
    divisionId: this.burialPermitObject.divisionId,
    sectionId: this.burialPermitObject.sectionId,
    compartmentId: this.burialPermitObject.compartmentId,
    graveId: this.burialPermitObject.graveNumber,
    levelId: this.burialPermitObject.levelId
    
  };       

  this.updatedObj.deceased = {
    idCardNumber : this.burialPermitObject.deceasedIdCardNumber, 
    name :  this.burialPermitObject.deceasedName,
    surname : this.burialPermitObject.deceasedSurName,      
    address : this.burialPermitObject.deceased.address,
    documentTypeId : this.burialPermitObject.deceased.documentTypeId,
    maritalStatusId : this.burialPermitObject.deceased.maritalStatusId,
    genderId : this.burialPermitObject.deceased.genderId,
    spouseName : this.burialPermitObject.deceased.spouseName,
    spouseSurname : this.burialPermitObject.deceased.spouseSurname,
    dateOfBirth : this.burialPermitObject.deceased.dateOfBirth,
    placeOfBirth : this.burialPermitObject.deceased.placeOfBirth,
    localityId : this.burialPermitObject.deceased.localityId,
    profession : this.burialPermitObject.deceased.profession,
    fatherName : this.burialPermitObject.deceased.fatherName,
    fatherSurname : this.burialPermitObject.deceased.fatherSurname,
    fatherStatus : this.burialPermitObject.deceased.fatherStatus,
    motherName : this.burialPermitObject.deceased.motherName,
    motherSurname : this.burialPermitObject.deceased.motherSurname,
    motherStatus : this.burialPermitObject.deceased.motherStatus
  } 
}

onBurialTypeChange(){

  //RESET STEP3 IF IS NOT COMING FROM PRESAVE
  if(this.burialType_firstchange == false){
    this.resetStep3() 
  }
  this.burialType_firstchange = false;

  //GET THE DESCRIPTION TO FILL THE PREVIEW VARIABLES
  const selectedburialTypeId = this.burialPermitObject.burialTypeId;    
  const selectedburialType = this.burialTypes.find(bur => bur.id === selectedburialTypeId);    
  this.selectedBurialTypeDescription = selectedburialType ? selectedburialType.description : '';

  //FILL THE STEP 3 LABEL NAME
  if ([1, 2, 3, 4].includes(selectedburialTypeId)) {
    switch(selectedburialTypeId){
      case 1:
        this.stepLabelName = 'Fill out Cremation Details'
        break;
      case 2:
        this.stepLabelName = 'Fill out Grave Details'
        break;
      case 3:
        this.stepLabelName = 'Fill out Burial at Sea Details'
        break;
      case 4:
        this.stepLabelName = 'Fill out Repatriation Details'
        break;
    }
    this.isStepEditable = true;
  } else{       
    this.resetStep3();
    this.isStepEditable = false;
  }      

}

resetStep3(){
      
  //RESET GRAVE DETAILS
  this.burialPermitObject.cemeteryName = '';
  this.thirdFormGroup.get('cemeteryCtrl')!.setValue(0);
  
  this.burialPermitObject.divisionName = '';
  this.thirdFormGroup.get('divisionCtrl')!.setValue(0);
  
  this.burialPermitObject.sectionName = '';
  this.thirdFormGroup.get('sectionCtrl')!.setValue(0);
  
  this.burialPermitObject.compartmentName = '';
  this.thirdFormGroup.get('compartmentCtrl')!.setValue(0);
  
  //this.thirdFormGroup.get('graveCtrl')!.setValue('');
  this.burialPermitObject.graveNo = '0';
  this.burialPermitObject.graveNumber = 0;

  this.burialPermitObject.levelId = 0;
  this.burialPermitObject.levelName = '';

  this.onCompartmentChange() //POPULATE GRAVE TABLE EMPTY

  //RESET BURIAL AT SEA DETAILS

  this.burialPermitObject.burialLocation = '';
  this.thirdFormGroup.get('burialLocationCtrl')!.setValue('');

  this.burialPermitObject.healthOfficer = '';
  this.thirdFormGroup.get('healthOfficerCtrl')!.setValue(''); 

  //RESET REPATRIATION AND CREMATION DETAILS

  this.burialPermitObject.country = '';
  this.thirdFormGroup.get('countryCtrl')!.setValue('');

}

utcDate(localDate : Date) : Date{
  const utcDate: Date = new Date(Date.UTC(
    localDate.getFullYear(), 
    localDate.getMonth(), 
    localDate.getDate(), 
    localDate.getHours(), 
    localDate.getMinutes(), 
    localDate.getSeconds()
  ));
  return utcDate;
}
onCountrySelectionChange(countryId : number){
  const countrySelected = this.countries.find(c => c.id === countryId);
  this.burialPermitObject.country = countrySelected?.name;
}
getdivisionsByCemeteryId(cemeteryId : number){
  this.divisionService.getDivisionsByCementeryId(cemeteryId).subscribe(
    (response :IDivisionDto[]) => {
      this.divisions = response;      
    }, (error) => {
      console.error('Something went wrong getDivisionsByCementeryId :', error)

    })
  }

  getGravesByGraveNo(cemeteryId : number,divisionId : number, sectionId: number,compartementId : number){
    this.graveService.getAllGravesByGraveNo(
      cemeteryId,          
      divisionId,
      sectionId,
      compartementId,
      '0', this.pageIndex + 1,this.pageSize).subscribe(
      (response : ITableCountDto) => {

        this.graves = response.results;               
        this.fillTable(this.graves); 
        this.updateFilteredOptions('graveCtrl');                 
    },
    (error) => {
      console.error('error retrieving graves:', error)
    })
  }

  getSectionsByCemeteryIdAndDivisionId( divisionId : number, cemeteryId : number){
    this.sectionService.getSectionByDivisionIdAndCemeteryId(divisionId,cemeteryId).subscribe(
      (response :ISectionDto[]) => {
        this.sections = response;
      }, (error) => {
        console.error('Something went wrong getSectionsByCemeteryIdAndDivisionId :', error)

      })
  }
  getCompartmentsByCemeteryIdAndDivisionIdAndSection( cemeteryId : number,divisionId : number,sectionId : number ){
    this.compartmentService.getCompartmentByCemeteryIdAndDivisionIdSectionId(cemeteryId,divisionId,sectionId).subscribe(
      (response :ICompartmentDto[]) => {
        this.compartments = response;
      }, (error) => {
        console.error('Something went wrong compartments :', error);
      })
  }
  fillTable(graves : IGraveDto[]){
    this.originalGraves = graves;
    this.graves.forEach(grave => {
      if ( grave.compartmentId ) {
        grave.compartmentName = this.originalsCompartments.find(com => com.id === grave.compartmentId)?.description;
      }   
    })
    this.dataSource = new MatTableDataSource(graves);        
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.resultsLength = graves.length;
  }

  private updateFilteredOptions(controlName : string) {      
    const control = this.thirdFormGroup.get(controlName);
    if (control) {
      this.filteredOptions = control.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || ''))
      );
    }
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.graves
      .map(gr => gr.graveNo !== null && gr.graveNo !== undefined ? gr.graveNo.toString() : '') 
      .filter(graveNo => graveNo && graveNo.toLowerCase().includes(filterValue));
  }
  handleInvalidOrEmptyInput() {
    this.graves = this.graves.filter(gr =>
      gr.cemeteryId === this.burialPermitObject.cemeteryId && 
        gr.divisionId === this.burialPermitObject.divisionId &&
        gr.sectionId === this.burialPermitObject.sectionId && 
        gr.compartmentId === this.burialPermitObject.compartmentId 
    );
    this.fillTable(this.graves);
  }
  pageEvent(event: any) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getAllGraves(this.pageIndex + 1, this.pageSize);
  }
  getAllGraves(pageNumber: number, pageSize: number){
    this.isLoading = true;
    this.graveService.getAllGraves(null, null, pageNumber, pageSize).subscribe(
      (response : ITableCountDto) => {
        this.graves = response.results;       
        this.isLoading = false;
    },
    (error) => {
      console.error('error retrieving graves:', error)
    })
  }
  graveNoSelected(event: MatAutocompleteSelectedEvent){
    const selectedGraveNo = event.option.value;       
   
    const selectedGrave = this.originalGraves.find(grave => grave.graveNo === selectedGraveNo);     
  if (selectedGrave && selectedGrave.id != 0) {
     
      this.burialPermitObject.graveNumber = selectedGrave.id;
      this.burialPermitObject.graveNo = selectedGrave.graveNo
      
      this.graves = this.graves.filter( gr => 
        gr.cemeteryId === selectedGrave.cemeteryId && 
        gr.divisionId === selectedGrave.divisionId &&
        gr.sectionId === selectedGrave.sectionId && 
        gr.compartmentId === selectedGrave.compartmentId && 
        gr.graveNo ===selectedGrave.graveNo )

        
        this.fillTable(this.graves);
      
    }else if(selectedGrave && selectedGrave.id === 0)
      {
        this.graves = this.originalGraves.filter( gr => 
        gr.cemeteryId === selectedGrave.cemeteryId && 
        gr.divisionId === selectedGrave.divisionId &&
        gr.sectionId === selectedGrave.sectionId && 
        gr.compartmentId === selectedGrave.compartmentId 
         )
        
      this.fillTable(this.graves);
    }
  }
  private _filter1(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.placeoptions.filter(placeoptions => placeoptions.toLowerCase().includes(filterValue));
  }
  // Handle option selected event
  onOptionSelected(event: any): void {
    const selectedOption = event.option.value;
    const placeOfDeathControl = this.secondFormGroup.get('placeOfDeathCtrl');
    if (placeOfDeathControl) {
      placeOfDeathControl.setValue(selectedOption);
    }
  }
  private _filter2(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.rightOptions.filter(rightoption => rightoption.toLowerCase().includes(filterValue));
  }
  // Handle option selected event
  onBurialRightSelected(event: any): void {
    const selectedOption = event.option.value; 
    const rightBurialControl = this.secondFormGroup.get('burialRightsCtrl');
    if (rightBurialControl) {
      rightBurialControl.setValue(selectedOption);
    }
  }
  private filterUndertakerList(name: string): IUndertakerDto[] {
    const filterValue = name.toLowerCase();
    return this.undertakers.filter(undertaker =>
      `${undertaker.name} ${undertaker.surname}`.toLowerCase().includes(filterValue)
    );
  }

  // Display the selected undertaker's name in the input field
  displayUndertaker = (id: number) => {
    const undertaker = this.undertakers.find(u => u.id === id);
    return undertaker ? `${undertaker.name} ${undertaker.surname}` : '';
  };
}