<div class="body-container  fixed-left">
  <app-menu-bar fxShow="true" fxHide.lt-md></app-menu-bar>
</div>

<div class="body-container">
  <div class="header_container">
    <mat-divider></mat-divider>
    <h1>Grave Maintenance</h1>
    <mat-divider></mat-divider>
  </div>
    <div class="container">
      <!-- <div class="form"> -->
        <!-- <div class="box">
          <app-menu-bar fxShow="true" fxHide.lt-md></app-menu-bar>
        </div> -->
      <!-- </div> -->
      <div class="form">
        
        <form [formGroup]="graveFormGroup">
          <div class="box"> 
          </div>
            <div class="box">
                <mat-form-field>
                  <mat-label>Cemetery</mat-label>
                  <mat-select formControlName="cemeteryCtrl"  (selectionChange)="onCemeteryChange($event.value)">
                    <mat-option *ngFor="let cemetery of cemeteries" [value]="cemetery.code">
                      {{ cemetery.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
          
                <mat-form-field>
                  <mat-label>Division</mat-label>
                  <mat-select formControlName="divisionCtrl"  (selectionChange)="onDivisionChange()">
                    <mat-option>None</mat-option>
                    <mat-option *ngFor="let division of divisions" [value]="division.id">
                      {{ division.description }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="!graveFormGroup.get('divisionCtrl')?.value">
                    <span>Field required</span>
                  </mat-error>  
                </mat-form-field>
            </div>
                <div class="box">
                  <mat-form-field>
                    <mat-label>Section</mat-label>
                    <mat-select formControlName="sectionCtrl"  (selectionChange)="onSectionChange($event.value)">
                      <mat-option>None</mat-option>
                      <mat-option *ngFor="let section of sections" [value]="section.id">
                        {{ section.description }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="!graveFormGroup.get('sectionCtrl')?.value">
                      <span>Field required</span>
                    </mat-error>  
                  </mat-form-field>
        
                  <mat-form-field>
                    <mat-label>Compartment</mat-label>
                    <mat-select formControlName="compartmentCtrl" (selectionChange)="onCompartmentChange($event.value)">
                      <mat-option>None</mat-option>
                      <mat-option *ngFor="let compartment of compartments" [value]="compartment.id">
                        {{ compartment.description }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="!graveFormGroup.get('compartmentCtrl')?.value">
                      <span>Field required</span>
                    </mat-error>  
                  </mat-form-field>  
                  
                  <mat-form-field class="example-full-width">
                    <mat-label>Grave No</mat-label>
                    <input type="text"
                           placeholder="Grave No"
                           aria-label="Number"
                           matInput formControlName="graveCtrl" [matAutocomplete]="auto">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="graveNoSelected($event)">
                      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                        {{ option }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
              </div>
          <div  class="box main_button_clear"><button mat-raised-button color="primary" (click)="clearGraveSelected()"><mat-icon>backspace</mat-icon>Clear</button></div>
          <mat-divider></mat-divider>
          <div *ngIf="isLoading" class="spinner-overlay">
            <mat-spinner></mat-spinner>
          </div>

          <!--SEARCH COMPONENT-->
          <app-searchbar (resetParentForm)="resetForm()"
            [methodName]="methodName"                 
            [pageSizeSearchBar]="pageSize"
            (responseSearch)="handleResponseSearch($event)">
          </app-searchbar>
          
          <!--TABLE FILTER-->
          <div class="box">
            <mat-form-field>
              <mat-label>Filter</mat-label>
              <input matInput (keyup)="applyFilter($event)"placeholder="Ex. Grave Reference Number" #input/>
            </mat-form-field>
          </div>
          <mat-label><b>Graves:</b></mat-label>
          <div class="table-table-container mat-elevation-z2"  tabindex="0">
           
            <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>

               <!-- Cemetery  Column -->
               <ng-container matColumnDef="id" sticky>
                <th mat-header-cell *matHeaderCellDef mat-sort-header> id </th>
                <td mat-cell *matCellDef="let grave">{{ grave.id }} </td>
              </ng-container>

              <!-- Cemetery  Column -->
              <ng-container matColumnDef="cemetery" sticky>
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cemetery Details </th>
                <td mat-cell *matCellDef="let grave">{{ grave.cemeteryName }} --- {{ grave.divisionName }} --- {{ grave.sectionName }} --- {{ grave.compartmentName }}</td>
              </ng-container>

              <!-- Division  Column -->
              <!-- <ng-container matColumnDef="division">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Division </th>
                <td mat-cell *matCellDef="let grave">{{ grave.divisionName }} </td>
              </ng-container> -->

              <!-- Division  Column -->
              <!-- <ng-container matColumnDef="section">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Section </th>
                <td mat-cell *matCellDef="let grave">{{ grave.sectionName }} </td>
              </ng-container> -->

              <!-- Compartment Column -->
              <!-- <ng-container matColumnDef="compartment">
                <th mat-header-cell *matHeaderCellDef mat-sort-header fxShow="true" fxHide.lt-md> Compartment </th>
                <td mat-cell *matCellDef="let grave" fxShow="true" fxHide.lt-md>{{ grave.compartmentName }}</td>
              </ng-container> -->

              <!-- Grave No  Column -->
              <ng-container matColumnDef="graveNo" sticky>
                <th mat-header-cell *matHeaderCellDef > Grave No </th>
                <td mat-cell *matCellDef="let grave">{{ grave.graveNo }} </td>
              </ng-container>

              <!-- Grave ref no Column -->
              <ng-container matColumnDef="referenceNumber">
                <th mat-header-cell *matHeaderCellDef > Ref No </th>
                <td mat-cell *matCellDef="let grave">{{ grave.referenceNumber }} </td>
              </ng-container>

              <!-- Grave type Column -->
              <ng-container matColumnDef="dhFileNo">
                <th mat-header-cell *matHeaderCellDef >Dh File No</th>
                <td mat-cell *matCellDef="let grave" >{{ grave.dhFileNo }}</td>
              </ng-container>

              <ng-container matColumnDef="isPrivateCtrl">
                <th mat-header-cell *matHeaderCellDef>Grave Type</th>
                <td mat-cell *matCellDef="let grave">
                  {{ grave.graveTypeId === 1 ? 'Public' : (grave.graveTypeId === 2 ? 'Private' : grave.graveTypeDescription) }}
                </td>
              </ng-container>

              <ng-container matColumnDef="isSealedCtrl">
                <th mat-header-cell *matHeaderCellDef   >Sealed </th>
                <td mat-cell *matCellDef="let grave" >{{ grave.isSealed}}</td>
              </ng-container>

              <ng-container matColumnDef="graveStatus">
                <th mat-header-cell *matHeaderCellDef   >Grave Status</th>
                <td mat-cell *matCellDef="let grave" >{{ getGraveStatusDescription(grave.statusId)}}</td>
              </ng-container>

              <ng-container matColumnDef="expand" stickyEnd>
                <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                <td mat-cell *matCellDef="let grave">
                  <button mat-icon-button aria-label="expand row"  (click)="expandCollapse(grave); $event.stopPropagation()" >
                    <ng-container *ngIf="expandedElement === grave">
                        <mat-icon>keyboard_arrow_up</mat-icon>
                    </ng-container>
                    <ng-container *ngIf="expandedElement !== grave">
                        <mat-icon>keyboard_arrow_down</mat-icon>
                    </ng-container>
                </button>
                </td>
              </ng-container>

                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
              <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let grave" [attr.colspan]="displayedColumnsWithExpand.length">
                  <div class="expand-detail" [@detailExpand] = "grave == expandedElement ? 'expanded' : 'collapsed' ">
                    <div class="expand-category">
                      <div class="expand-field">Remarks : &nbsp;<span>{{grave.remarks}}</span></div>
                     <!-- Third Owner : &nbsp;<span>{{grave.thirdOwner}}</span></div> -->
                    </div>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="action" stickyEnd>
                <th mat-header-cell *matHeaderCellDef>Action</th>
                <td mat-cell *matCellDef="let grave">
                  <button mat-icon-button color="primary" (click)="graveView(grave)" matTooltip="Grave Details">
                    <mat-icon>loupe</mat-icon>
                  </button>
                  <!--<button mat-icon-button color="primary" (click)="newCleaning(grave)" matTooltip="Cleaning">
                    <mat-icon>cleaning_services</mat-icon>
                  </button>-->
                  <button class="editbutton" (click)="updateGrave(grave)"  mat-icon-button  color="primary" matTooltip="Edit Grave" >
                    <mat-icon>mode</mat-icon>
                  </button>
                  <button mat-icon-button color="warn" (click)="deleteGrave(grave.id)"matTooltip="Delete Grave">
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsWithExpand"></tr>
              <tr mat-row *matRowDef="let grave; columns: displayedColumnsWithExpand;" class="expand-Inside"
                [class.example-expanded-row]="expandedElement != grave" ></tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" matRipple class="expand-Rowdetails"></tr>        

              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{ input.value }}"</td>
              </tr>
            </table>
          </div>
          <mat-paginator class="paginator" [pageSizeOptions]="[25, 50,100]" [length]="resultsLength" (page)="pageEvent($event)" aria-label="Select page"></mat-paginator>
          <div class="buttonnew">
            <button mat-raised-button (click)="addGrave()" color="primary"><mat-icon>add</mat-icon>
              New
            </button>
          </div>
        </form>
      </div>
    </div>
    </div>
