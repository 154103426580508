import { Component, Inject, OnInit } from '@angular/core';
import { IGraveLevelsDto } from '../models/GraveLevelsDto.model';
import { MatTableDataSource } from '@angular/material/table';
import { IGraveDto } from '../models/GraveDto.model';
import { GraveService } from '../services/grave.service';
import { FormBuilder } from '@angular/forms';
import { InternalCommunicationService } from '../services/internal-communication.service';
import { BurialPermitService } from '../services/burial-permit.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { IBurialPermitDto } from '../burials/models/BurialPermitDto.model';
import { catchError, of as observableOf} from 'rxjs';

@Component({
  selector: 'app-grave-levels',
  templateUrl: './grave-levels.component.html',
  styleUrls: ['./grave-levels.component.css']
})
export class GraveLevelsComponent implements OnInit {
  selectedLevel: IGraveLevelsDto | null = null;
  displayedColumns: string[] = ['levelId','levelDescription', 'graveLevelStatusDescription','select'];
  dataSource!: MatTableDataSource<IGraveLevelsDto>;
  graveNo! : number;
  grave! :IGraveDto;
  graveId! : number ;
  graveLevels! : IGraveLevelsDto[];
  isLoading: boolean = false;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<GraveLevelsComponent>,
    private graveService : GraveService,
    private _formBuilder: FormBuilder,
    private burialPermitService : BurialPermitService,
    private internalService : InternalCommunicationService,
    @Inject(MAT_DIALOG_DATA) public data: IGraveDto){  
      
      this.grave = data;
      this.graveId = this.grave.id;
      
    }
  ngOnInit(): void {
    this.refreshTableData();
     
  }

  refreshTableData() {
    this.fetchGraveLevels().subscribe(data => {
      if (data === null) {
        return;
      }
      this.dataSource = new MatTableDataSource(data);
    });
  } 



  fetchGraveLevels() {
    return this.graveService!.getGraveLevelsByGraveId(this.grave.id).pipe(catchError(() => observableOf(null)));
  }
  onCheckboxChange(event: any, level: IGraveLevelsDto) {
    if (event.checked) {      
      this.getLastBurial(this.grave.id).then((isOlderThanTwoYears: boolean) => {
        if (!isOlderThanTwoYears) {          
          this.dialog.open(ConfirmationModalComponent, {
            width: '350px',
            data: {
              title: 'Recent Burial Warning',
              message: 'Warning: The last burial in this grave occurred less than 2 years ago.',
              closeOnly: true 
            }
          });
        }  
        
        this.selectedLevel = level;
        this.dialogRef.close(this.selectedLevel);
        
      }).catch((error) => {
        console.error('Error retrieving the last burial:', error);
      });
    } else {
      this.selectedLevel = null; 
    }
  }
  

    isSelected(level: IGraveLevelsDto): boolean {
      return this.selectedLevel === level;
    }  
    onGraveDtoCloseClick(): void {
      this.dialogRef.close();
    }

    getGraveById(graveId : number){
      this.graveService.getGraveById(graveId).subscribe(
        (response : IGraveDto) =>{
          this.grave = response;

        }, (error) =>{
          console.error('something went wrong:' , error)

        })

    }

    getGraveLevelsByGraveId(graveId : number){
      this.isLoading = true;
      this.graveService.getGraveLevelsByGraveId(graveId).subscribe(
        (response : IGraveLevelsDto[]) => {
          this.graveLevels = response;
          this.isLoading = false;
          this.dataSource = new MatTableDataSource(this.graveLevels);
        }, (error) =>{
          console.error('something went wrong: ', error);  
        })
    }

    getLastBurial(graveId: number): Promise<boolean> {
      return new Promise((resolve, reject) => {
        this.burialPermitService.getLastBurialPermit(graveId).subscribe(
          (response: IBurialPermitDto) => {
            if (response && response.burialDate) {
              const burialDate = new Date(response.burialDate);
              const currentDate = new Date();
              const twoYearsAgo = new Date();
              twoYearsAgo.setFullYear(currentDate.getFullYear() - 2);
    
              
              resolve(burialDate < twoYearsAgo);
            } else {
              resolve(false); 
            }
          },
          (error) => {
            console.error('Something went wrong:', error);
            reject(error);
          }
        );
      });
    }


    

}
