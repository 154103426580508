import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Module } from 'src/app/models/module.model';
import { IResponseModal } from 'src/app/models/ResponseModal.model';
import { RoleDto } from 'src/app/models/RoleDto.model';
import { RoleModuleDto } from 'src/app/models/RoleModuleDto.model';
import { ResponsemodalComponent } from 'src/app/responsemodal/responsemodal.component';
import { ModuleService } from 'src/app/services/module.service';

@Component({
  selector: 'app-linkmodule',
  templateUrl: './linkmodule.component.html',
  styleUrls: ['./linkmodule.component.css']
})
export class LinkmoduleComponent implements OnInit, AfterViewInit {
  dataSource!: MatTableDataSource<Module>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  modules : Module[] = [];
  selectedModule: Module | null = null;
  anyCheckboxChecked = false;
  roleModuleId : number;
  roleModules : RoleModuleDto [] = [];
  displayedColumns: string[] = [
    'moduleID',
    'name',
    'description',   
    'select',
    ];
    resultsLength = 0;
    modulesSelected : Module [] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: RoleDto,
    private moduleService : ModuleService,
    public dialog: MatDialog,
    ){
      console.log('role module')
      console.log(data)
      this.roleModuleId = data.id;

    }
  ngAfterViewInit(): void {
    this.areAllSelected();
  }
  
  ngOnInit(): void {
    this.getModules();

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }  
  
    this.selectedModule = null;
  }

  toggleAllCheckboxes(checked: boolean) {
    this.modules.forEach(c => c.selected = checked);
    this.checkIfAnyCheckboxChecked();
  }
  checkIfAnyCheckboxChecked(): void {
    this.anyCheckboxChecked = this.modules.some(module => module.selected);    
  }
  areAllSelected(): boolean {    
    return this.modules.length > 0 && this.modules.every(item => item.selected);    
  }

  onCheckboxChange(module: Module){
    module.selected = !module.selected;
    this.checkIfAnyCheckboxChecked();
  }
  isSelected(module: Module): boolean {     
    return module.selected || false;
  }

  getModules(){
    this.moduleService.getModules().subscribe(
      (response : any) => {
        this.modules = response;
        this.dataSource = new MatTableDataSource(this.modules);       
        this.resultsLength = response.totalItems;

      }, (error) =>{
        console.error('something went wrong ', error)

      })
  }
  saveSelection(){
    
    this.modulesSelected = this.modules.filter(x => x.selected == true);
    console.log(this.modulesSelected)
    
    this.modulesSelected.forEach(module => {
        let newRoleModule: RoleModuleDto = {
            roleId: this.roleModuleId,
            moduleId: module.id,
            accessLevel : '',           
        };
        this.roleModules.push(newRoleModule);
    });
    

    this.moduleService.addModuleToRole(this.roleModules).subscribe(
      (response: IResponseModal) => {
        this.openResponseModal(response);

      },(error) =>{
        this.openResponseModal(error);

      })
  }
  openResponseModal(response : IResponseModal): void {
    const dialogRef = this.dialog.open(ResponsemodalComponent, {
      width: '250px',
      data : response,
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }



}
