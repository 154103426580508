import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/models/User.model';
import { EditusersComponent } from './editusers/editusers.component';
import { AddusersComponent } from './addusers/addusers.component';
import { MatPaginator } from '@angular/material/paginator';
import { UserService } from 'src/app/services/user.service';
import { error } from 'pdf-lib';


@Component({
  selector: 'app-viewusers',
  templateUrl: './viewusers.component.html',
  styleUrls: ['./viewusers.component.css']
})
export class ViewusersComponent implements OnInit {

  dataSource!: MatTableDataSource<User>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  resultsLength = 0;
  users!: User[];
  selectedUser: User | null = null;
 
  displayedColumns: string[] = [
    'id',
    'name',    
    'surname',     
    'email',    
    'roleName',    
    'actions'
  ];

  constructor(private dialog: MatDialog, private userService : UserService) {
    
  }

  ngOnInit(): void {
    this.getUsers();
    
    //this.resultsLength = this.users.data.length;
    //this.users.paginator = this.paginator; // Connect paginator to data source
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  
    this.selectedUser = null;
  }

 
  editUser(userID: number): void {
    console.log("Editing user ID: ", userID); // Debugging log

    const user = this.users.find(u => u.id === userID);
    if (user) {
      const dialogRef = this.dialog.open(EditusersComponent, {
        width: '800px',
        data: user
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          console.log('Edit dialog result:', result); 
          const index = this.users.findIndex(u => u.id === userID);
          if (index !== -1) {
            this.users[index] = { ...this.users[index], ...result };
            this.dataSource = new MatTableDataSource<User>(this.users);
            this.dataSource.paginator = this.paginator; 
          }
        }
      });
    }
  }
  

  addUser(): void {
    const dialogRef = this.dialog.open(AddusersComponent, {
      width: '800px'
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('Received new user:', result);
        this.users.push(result);
        this.dataSource = new MatTableDataSource<User>(this.users);
        this.dataSource.paginator = this.paginator;
        this.resultsLength = this.users.length;
      } else {
        console.log('Dialog closed without result');
      }
    });
  }
  

  getUsers(){
    this.userService.getUsers().subscribe(
      (response : User[]) =>{
        this.users = response;
        this.dataSource = new MatTableDataSource(this.users); 

      }, (error) => {
        console.error('something went wrong : ', error)

      })
  }
}
