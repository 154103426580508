<div>
    <!-- Title with Material Design Toolbar -->
    <mat-toolbar color="primary">
      <span>Preview: {{ fileName }}</span>
      <span class="spacer"></span>
      <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  
    <!-- Material Card for content display -->
    <mat-card>
      <mat-card-content>
        <!-- Display the PDF inside iframe if the file type is PDF -->
        <div *ngIf="fileType === 'application/pdf' && fileURL" class="content-container">
          <iframe [src]="fileURL" width="100%" height="500px" class="preview-iframe"></iframe>
        </div>
  
        <!-- Display image files like PNG, JPG, etc. -->
        <div *ngIf="fileType.startsWith('image/') && fileURL" class="content-container">
          <img [src]="fileURL" alt="{{ fileName }}" class="preview-image"/>
        </div>
  
        <!-- Download button for image files -->
        <div *ngIf="fileType.startsWith('image/') && fileDownloadURL" class="action-container">
          <a [href]="fileDownloadURL" download="{{ fileName }}" mat-raised-button color="primary">Download Image</a>
        </div>
  
        <!-- For other file types like docx, provide a download link -->
        <div *ngIf="fileType !== 'application/pdf' && !fileType.startsWith('image/') && fileDownloadURL" class="action-container">
          <p>This file type is not supported for preview. You can download it below:</p>
          <a [href]="fileDownloadURL" download="{{ fileName }}" mat-raised-button color="primary">Download {{ fileName }}</a>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  