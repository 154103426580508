<div class="container">
  <h1>Division Details</h1>
</div>
<div class="container">
  <div class="form">
    <div class="box">
      <app-menu-bar fxShow="true" fxHide.lt-md></app-menu-bar>
    </div>
  </div>
  <div class="form">
    <form [formGroup]="DivisionDetailsForm">
      <div class="box">
        <mat-form-field>
          <mat-label>Cemetery</mat-label>
          <mat-select formControlName="cemeteryCtrl" (selectionChange)="onCemeteryChange()">
            <mat-option *ngFor="let cemetery of cemeteries" [value]="cemetery.code">
              {{ cemetery.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-divider></mat-divider>
      <div class="box">
        <mat-form-field class="mat-form-field">
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Paola" #input/>
        </mat-form-field>
      </div>
       <!--TABLE-->
       <div class="box">
        <div class="table-table-container mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>
          <!-- ID Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef >ID</th>
            <td mat-cell *matCellDef="let divisionmain">{{divisionmain.id}}</td>
          </ng-container>

          <!-- Division Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef  >Division</th>
            <td mat-cell *matCellDef="let divisionmain"> {{ divisionmain.description }} </td>
          </ng-container>
      
          <!-- Action Column -->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let divisionmain">
              <button class="editbutton" (click)="editDivisionDialog(divisionmain)" mat-icon-button color="primary"> 
                <mat-icon>mode</mat-icon>
              </button>
              <button mat-icon-button color="warn">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
       </div>
       <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of graves" ></mat-paginator>
      <div class="buttonnew">
        <button mat-raised-button (click)="NewDivisionDialog()" color="primary"> New</button>
      </div>
    </form>
  </div>

</div>

