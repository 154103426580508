<!-- File upload section -->
<div *ngIf="isLoading" class="spinner-overlay">
    <mat-spinner></mat-spinner>
</div>
<mat-card class="upload-card">
    <mat-card-header>
      <mat-card-title>Upload File</mat-card-title>
    </mat-card-header>
  
    <mat-card-content>
      
      <div class="file-input-container">
        <input type="file" (change)="onFileSelected($event)" />
        <button mat-raised-button color="primary" (click)="uploadFile()" [disabled]="!selectedFile">Upload</button>
      </div>
    </mat-card-content>
  </mat-card>
  
  <!-- Table section -->
  <div class="table-container mat-elevation-z8">
    <div *ngIf="isLoading" class="spinner-overlay">
      <mat-spinner></mat-spinner>
    </div>
  
    <mat-card>
      <mat-card-content>
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort class="mat-elevation-z8">
          <!-- File name -->
          <ng-container matColumnDef="fileName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>File Name</th>
            <td mat-cell *matCellDef="let file">
              <span class="file-name" (click)="viewFileContent(file.fileName)">
                {{ file.fileName }}
              </span>
            </td>
          </ng-container>
  
          <!-- Uploaded date -->
          <ng-container matColumnDef="uploadedDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Uploaded Date</th>
            <td mat-cell *matCellDef="let file">{{ file.uploadedDate | date: 'dd/MM/yyyy' }}</td>
          </ng-container>
  
          <!-- Action buttons (delete) -->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let file">
              <button mat-icon-button color="warn" (click)="deleteFile(file.fileName)">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </mat-card-content>
    </mat-card>   
  </div>
  