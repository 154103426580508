import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { SiteHeaderComponent } from './site-header/site-header.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { MaterialModule } from './shared/material.module';
import { FormsModule, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule} from '@angular/material/paginator';
import { MatSortModule} from '@angular/material/sort';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTreeModule } from '@angular/material/tree';
import { MatDialogModule } from '@angular/material/dialog';
import { CemeteryRoutingModule } from './cemetery/cemetery-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MaintenanceRoutingModule } from './maintenance/maintenance-routing.module';
import { ResponsemodalComponent } from './responsemodal/responsemodal.component';
import { DatePipe } from '@angular/common';
import { UndertakerComponent } from './undertaker/undertaker.component';
import { SharedModule } from './shared/menubar.module';
import { UndertakerNewComponent } from './undertaker/undertaker-new/undertaker-new.component';
import { UndertakerEditComponent } from './undertaker/undertaker-edit/undertaker-edit.component';
import { ReportingComponent } from './reporting/reporting.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import { DisintermentComponent } from './reporting/disinterment/disinterment.component';
import { ConfirmpopupComponent } from './confirmpopup/confirmpopup.component';
import { CsvExportComponent } from './csv-export/csv-export.component';
// import { CdrComponent } from './cdr/cdr.component';
import { 
  MsalModule, 
  MsalInterceptor, 
  MsalService, 
  MSAL_INSTANCE, 
  MSAL_INTERCEPTOR_CONFIG, 
  MsalInterceptorConfiguration, 
  MsalGuardConfiguration, 
  MSAL_GUARD_CONFIG, 
  MsalGuard} from '@azure/msal-angular';
import { PublicClientApplication, InteractionType, BrowserCacheLocation, IPublicClientApplication } from '@azure/msal-browser';
import { AuthService } from './auth.service';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { AdminRoutingModule } from './admin/admin-routing.modules';
import { AdminModule } from './admin/admin.module';

import { FilePreviewModalComponent } from './file-preview-modal/file-preview-modal.component';
import { BurialsIssuedComponent } from './reporting/burials-issued/burials-issued.component';
import { BurialsReportComponent } from './reporting/burials/burials.component';
import { OdcComponent } from './reporting/odc/odc.component';
import { NewCleaningComponent } from './new-cleaning/new-cleaning.component';
import { OwnershipComponent } from './ownership/ownership.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { GraveLevelsComponent } from './grave-levels/grave-levels.component';



//for microsoft identity provider
/*export function MSALInstanceFactory(): PublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: 'f9cd97f9-8681-41cb-8d23-15206c371939',  
      authority: 'https://login.microsoftonline.com/9188040d-6c67-4c5b-b112-36a304b66dad',  
      redirectUri: 'https://localhost:4200',
      navigateToLoginRequestUrl: false 
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,  
      storeAuthStateInCookie: false,
    }
  }); 
}*/

export function MSALInstanceFactory(): PublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: '8e104739-6640-4bde-896c-73e01724135f',  
      authority: 'https://b2cdevgovmt.b2clogin.com/tfp/b2cdevgovmt.onmicrosoft.com/B2C_1A_DEV_OB_SHARED_CORPAAD_EXT_EID_EXT_SIGNIN',
      knownAuthorities: ['b2cdevgovmt.b2clogin.com'],
      redirectUri: 'https://cem-fe.azurewebsites.net/', //    https://cem-fe.azurewebsites.net/ https://localhost:4200'
      navigateToLoginRequestUrl: false 
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false,
    }
  });
}

export function initializeApp(msalService: MsalService): () => Promise<void> {
  return async () => {
    try {
      
      await msalService.instance.initialize();
      await msalService.instance.handleRedirectPromise(); 
    } catch (error) {
      console.error('Error during MSAL initialization:', error);
    }
  };
}


@NgModule({
  declarations: [
    AppComponent,    
    SiteHeaderComponent, 
    HomeComponent, 
    ResponsemodalComponent, 
    UndertakerComponent, UndertakerNewComponent, UndertakerEditComponent, ConfirmpopupComponent,  
    ReportingComponent,
    DisintermentComponent,
    CsvExportComponent,
    //CdrComponent,
    LandingPageComponent,
    FilePreviewModalComponent,
    BurialsIssuedComponent,
    BurialsReportComponent,
    OdcComponent,
    NewCleaningComponent,
    ConfirmationModalComponent,
    GraveLevelsComponent
    
    
    
  ],
  imports: [
    AdminModule,
    BrowserModule,
    AppRoutingModule, 
    BrowserModule,    
    BrowserAnimationsModule, 
    NgxMatTimepickerModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    CemeteryRoutingModule,
    MaintenanceRoutingModule,
    MatButtonModule,
    MatSidenavModule,
    MatTreeModule,
    MatDialogModule,
    HttpClientModule,
    SharedModule,
    MatSlideToggleModule,    
    MatDatepickerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    AdminRoutingModule,
    MsalModule.forRoot(
      MSALInstanceFactory(),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: ['https://b2cdevgovmt.onmicrosoft.com/Health-CemetryBurialSystem/user_impersonation']
        }
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          ['https://b2cdevgovmt.onmicrosoft.com/Health-CemetryBurialSystem', ['https://b2cdevgovmt.onmicrosoft.com/Health-CemetryBurialSystem/user_impersonation']]
        ])
      }
    )
  ], 
   providers: [
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [MsalService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
