import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ITableCountDto } from 'src/app/burials/models/ITableCountDto';
import { ICemeteryDto } from 'src/app/models/CemeteryDto.model';
import { ICompartmentDto } from 'src/app/models/CompartmentDto.model';
import { IDivisionDto } from 'src/app/models/DivisionDto.model';
import { ISectionDto } from 'src/app/models/SectionDto.model';
import { CemeteryService } from 'src/app/services/cemetery.service';
import { CompartmentService } from 'src/app/services/compartment.service';
import { DivisionService } from 'src/app/services/division.service';
import { InternalCommunicationService } from 'src/app/services/internal-communication.service';
import { SectionService } from 'src/app/services/section.service';

@Component({
  selector: 'app-compartment-edit',
  templateUrl: './compartment-edit.component.html',
  styleUrls: ['./compartment-edit.component.css']
})

export class CompartmentEditComponent implements OnInit{
 
  CompartmentEditForm!: FormGroup;
  divisions: IDivisionDto[] = [];
  cemeteries : ICemeteryDto[] = [];
  sections: ISectionDto[] = [];
  compartments :ICompartmentDto[] =[];
  
  selectedCemetery: ITableCountDto | null = null;
  selectedDivision: ITableCountDto | null = null;
  selectedSection: ITableCountDto | null = null;
  selectedCompartment: ITableCountDto | null = null;
 
  compartmentObj:ICompartmentDto ={
    id : 0,
    description:'',
    cemeteryId : 0,
    divisionId: 0,
    sectionId  : 0,
    graves: []
}

constructor(
  private cemeteryService : CemeteryService,
  private divisionService : DivisionService,
  private sectionService : SectionService,
  private compartmentService : CompartmentService,
  private _formBuilder: FormBuilder,
  @Inject(MAT_DIALOG_DATA) public data: any,
  private internalService : InternalCommunicationService)
  {
  this.compartmentObj = data;
  }
  ngOnInit() {
   
    this. CompartmentEditForm  = this._formBuilder.group({
      compartmentCtrl: [this.compartmentObj.id],
      compartmentNameCtrl: [this.compartmentObj.description],
    });
    this.subscribeChanges();
}

subscribeChanges() {
  this.CompartmentEditForm.get('compartmentCtrl')?.valueChanges.subscribe((value) => {     
    this.compartmentObj.id= value;
  });
  this.CompartmentEditForm .get('compartmentNameCtrl')?.valueChanges.subscribe((value) => {     
    this.compartmentObj.description= value;
  });
}

   onCompartmentUpdate(): void {  
    const id = +this.compartmentObj.id;
    this.compartmentService.updateCompartmentDetails(id, this.compartmentObj).subscribe(
      () => {        
        
        console.log('Compartment details');   
      },
      (error) => {
        console.error('Error occurred while updating compartment details:', error);        
      }
    )
    }
   
}