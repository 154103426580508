import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from 'src/app/models/User.model';
import { RoleDto } from 'src/app/models/RoleDto.model';  // Adjust import based on actual location
import { RoleService } from 'src/app/services/role.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-editusers',
  templateUrl: './editusers.component.html',
})
export class EditusersComponent implements OnInit {
  editUserForm: FormGroup;
  roles: RoleDto[] = [];

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditusersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: User,
    private roleService: RoleService,
    private userService: UserService
  ) {
    this.editUserForm = this.fb.group({
      //userID: [{ value: data.id, disabled: true }],  // Assuming userID should be readonly
      username: [{ value: data.name, disabled: true }],  // Assuming username is readonly
      name: [data.name, Validators.required],
      surname: [data.surname, Validators.required],
      idNo: [data.idNo, Validators.required],
      email: [data.email, [Validators.required, Validators.email]],
      roleID: [data.roleId, Validators.required],
      isActive: [data.isActive === 1, Validators.required],  // Assuming isActive is a boolean
      //createdOn: [{ value: data.createdon, disabled: true }]  // Assuming createdOn is readonly
    });
  }

  ngOnInit(): void {
    this.loadRoles();
  }

  loadRoles(): void {
    this.roleService.getAllRoles().subscribe({
      next: (roles: RoleDto[]) => {
        this.roles = roles;
      },
      error: (err) => {
        console.error('Error loading roles:', err);
      }
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    if (this.editUserForm.valid) {
      const formValue = this.editUserForm.getRawValue();
      const selectedRole = this.roles.find(role => role.id === formValue.roleID);
      
      const updatedUser: User = {
        ...this.data,  // Keep existing user data like ID, username, etc.
        roleId: formValue.roleID,
        roleName: selectedRole ? selectedRole.name : this.data.roleName,
        isActive: formValue.isActive ? 1 : 2
      };

      console.log('Updated user data:', updatedUser);

      this.userService.updateUser(updatedUser).subscribe({
        next: (updatedUser) => {
          console.log('User updated successfully:', updatedUser);
          this.dialogRef.close(updatedUser);
        },
        error: (err) => {
          console.error('Error updating user:', err);
        }
      });
    } else {
      console.log('Form is invalid:', this.editUserForm.errors);
    }
  }
}
