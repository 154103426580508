import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ILocalityDto } from 'src/app/models/LocalityDto.model';
import { IResponseModal } from 'src/app/models/ResponseModal.model';
import { IUndertakerDto } from 'src/app/models/UndertakerDto.model';
import { ResponsemodalComponent } from 'src/app/responsemodal/responsemodal.component';
import { LocalityService } from 'src/app/services/locality.service';
import { UndertakerService } from 'src/app/services/undertaker.service';


@Component({
  selector: 'app-undertaker-edit',
  templateUrl: './undertaker-edit.component.html',
  styleUrls: ['./undertaker-edit.component.css']
})
export class UndertakerEditComponent implements OnInit{
  UndertakerEditForm!: FormGroup;
  localities: ILocalityDto[]=[];
  undertakerObj :IUndertakerDto ={
    id: 0,
    code : 0,
    name : '',
    surname : '',
    telephone :'',
    address1: '',
    address2  :'',
    localityId :0,
    vatNo : '',
    }
  
  constructor(
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<UndertakerEditComponent>,
    private localityService: LocalityService,
    private undertakerService :UndertakerService,
    @Inject(MAT_DIALOG_DATA) public data: any) 
     
   {
    this.undertakerObj =data;
   }
   ngOnInit() {
    
    this.getAllLocalities();

    this.UndertakerEditForm = this._formBuilder.group({
      codeCtrl:[this.undertakerObj ? this.undertakerObj.code: '',[Validators.pattern('[0-9]*')]],
      vatNoCtrl: [this.undertakerObj ? this.undertakerObj.vatNo: '',[Validators.pattern('[0-9]*')]],
      nameCtrl: [this.undertakerObj ? this.undertakerObj.name: '', [Validators.required, Validators.pattern(/^[a-zA-Z,().'`" &\/\\-]*$/)]], 
      surnameCtrl: [this.undertakerObj ? this.undertakerObj.surname: '', [Validators.required, Validators.pattern(/^[a-zA-Z,().'`" &\/\\-]*$/)]], 
      localityCtrl: [ this.undertakerObj ? this.undertakerObj.localityId: '', [Validators.required, Validators.min(1)]],
      address1Ctrl:[this.undertakerObj ? this.undertakerObj.address1: '', [Validators.pattern(/^[a-zA-Z0-9,().'`" \/\\-]*$/)]],
      address2Ctrl:[this.undertakerObj ? this.undertakerObj.address2: '', [Validators.pattern(/^[a-zA-Z0-9,().'`" \/\\-]*$/)]],
      telephoneCtrl : [this.undertakerObj ? this.undertakerObj. telephone: '',[Validators.pattern('[0-9]*')]],
  });
  this.subscribeChanges();
  }
  subscribeChanges() {
    this.UndertakerEditForm.get('codeCtrl')?.valueChanges.subscribe((value) => {     
      this.undertakerObj.code= value;
    });
    this.UndertakerEditForm.get('vatNoCtrl')?.valueChanges.subscribe((value) => {     
      this.undertakerObj.vatNo= value;
    });
    this.UndertakerEditForm.get('nameCtrl')?.valueChanges.subscribe((value) => {     
      this.undertakerObj.name= value;
    });
    this.UndertakerEditForm.get('surnameCtrl')?.valueChanges.subscribe((value) => {     
      this.undertakerObj.surname= value;
    });
    this.UndertakerEditForm.get('localityCtrl')?.valueChanges.subscribe((value) => {     
      this.undertakerObj.localityId= value;
    });
    this.UndertakerEditForm.get('address1Ctrl')?.valueChanges.subscribe((value) => {     
      this.undertakerObj.address1 = value;
    });
    this.UndertakerEditForm.get('address2Ctrl')?.valueChanges.subscribe((value) => {     
      this.undertakerObj.address2= value;
    });
    this.UndertakerEditForm.get('telephoneCtrl')?.valueChanges.subscribe((value) => {     
      this.undertakerObj.telephone = value;
    });
  
  }

  getAllLocalities(){
    this.localityService.getAllLocalities().subscribe(
      (response: ILocalityDto[]) => {
        this.localities = response;  
      },
      (error) => {        
        console.error('Error adding localities: ', error);        
      }
      );
  }
  onUndertakerUpdate(): void {
    if (this.UndertakerEditForm.invalid) {
      this.UndertakerEditForm.markAllAsTouched(); // Show all errors
      return;
    }
    this.populateUpdateObject();
    const id =+ this.undertakerObj.id;
    this.undertakerService.updateUndertakerDetails(id, this.undertakerObj).subscribe(
      (response : IResponseModal) => {        
        this.openResponseModal(response);
        
        console.log('Deceased Details updated successfully');   
        this.dialogRef.close();
      },
      
      (error) => {
        console.error('Error occurred while updating deceased details:', error);        
      }
  )
   }

 openResponseModal(response : IResponseModal): void {
  const dialogRef = this.dialog.open(ResponsemodalComponent, {
    width: '250px',
    data : response,
    
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');
  });
}
populateUpdateObject(){

this.undertakerObj = {
      id:this.undertakerObj.id,
      code:this.undertakerObj.code,
      vatNo: this.undertakerObj.vatNo, 
      name: this.undertakerObj.name, 
      surname: this.undertakerObj.surname, 
      localityId: this.undertakerObj.localityId,
      address1:this.undertakerObj.address1,
      address2:this.undertakerObj.address2,
      telephone : this.undertakerObj. telephone,
};    
}
}
