<div class="tree-container">
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">    
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>      
          <a [routerLink]="[node.route]" mat-button [disabled]="isCurrentRoute(node.route)">{{node.name}}</a>       
      </mat-tree-node>   
      <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding class="parent_node">
        <button mat-icon-button matTreeNodeToggle
                [attr.aria-label]="'Toggle ' + node.name">
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        {{node.name}}
      </mat-tree-node>
    </mat-tree>
  </div>